import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { ThemeProps } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { CardLabelText, DarkerCardLabelText } from "facility-commons/components/emotion/cards";
import { NumericInput } from "facility-commons/components/Inputs/NumericInput";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useReceivingSKUQuantityScanFlow } from "./useReceivingSKUQuantityScanFlow";

const TopMarginContainer = styled.div<ThemeProps>(
  ({ theme }) => `
      margin-top: ${theme.spacing.S2};
      width: 100%;
  `
);

const TotalCountContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StorageReceivingSKUQuantity: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    scanError,
    caseQty,
    boxesQty,
    boxesRef,
    handleSKUQuantityChange,
    handleSKUBoxesChange,
    handleSubmit,
    hasWarning,
  } = useReceivingSKUQuantityScanFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <form onSubmit={handleSubmit}>
        <FacilityCard as="section">
          <DarkerCardLabelText>
            {formatMessage(STORAGE_RECEIVING_MESSAGES.unitAndBoxQuantitiesTitle)}
          </DarkerCardLabelText>
          <NumericInput
            autoFocus
            value={caseQty}
            warningMessage={hasWarning ? formatMessage(STORAGE_RECEIVING_MESSAGES.unexpctedCaseQuantity) : undefined}
            errorMessage={scanError}
            placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuQuantityInputUnitPlaceholder)}
            onChange={handleSKUQuantityChange}
          />

          <TopMarginContainer>
            <NumericInput
              errorMessage={scanError}
              onChange={handleSKUBoxesChange}
              placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuQuantityInputBoxesPlaceholder)}
              ref={boxesRef}
              value={boxesQty}
            />
            <TotalCountContainer>
              <CardLabelText>
                {formatMessage({ id: "storage.storageReceivingSkuQuantity.totalUnits", defaultMessage: "Total units" })}
              </CardLabelText>
              <DarkerCardLabelText>{((caseQty ?? 0) * (boxesQty ?? 0)).toString()}</DarkerCardLabelText>
            </TotalCountContainer>
          </TopMarginContainer>
        </FacilityCard>
      </form>
    </>
  );
};
