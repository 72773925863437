import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, Stack, ThemeProps } from "@deliverr/ui";
import { StorageReceivingPODetails } from "./StorageReceivingPODetails";
import { StorageTicketCenter } from "warehouse/ticket-center/common/components/StorageTicketCenter";

const StyledStack = styled(Stack)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  width: 100%;
`
);

export const StorageReceivingTicketCenter: React.FC = () => (
  <StyledStack>
    <StorageReceivingPODetails />
    <StorageTicketCenter />
  </StyledStack>
);
