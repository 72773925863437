import React, { useCallback } from "react";
import { createPackageCardLabels } from "./createPackageCardLabels";
import { composeFacilityCommonsButton } from "facility-commons/components/buttons/common/composeFacilityCommonsButton";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingSelectBoxSize } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { sum } from "lodash";
import { useRecoilValue } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";

const ClosePackageButton = composeFacilityCommonsButton(createPackageCardLabels.closePackageButton);

export const CreatePackageClosePackageButton: React.FC = () => {
  const { transition } = useWarehouseFlow();
  const { currentPackageDskuToQtyMap } = useRecoilValue(rsOutboundsStateAtom);
  const itemsAdded = sum(Object.values(currentPackageDskuToQtyMap ?? {}));
  const isDisabled = itemsAdded === 0;
  const onClick = useCallback(() => {
    transition({
      card: createOutboundsPackingSelectBoxSize({}),
    });
  }, [transition]);

  return (
    <ClosePackageButton
      block
      data-testid="outbounds-order-packing-close-package"
      loading={false}
      onClick={onClick}
      disabled={isDisabled}
    />
  );
};
