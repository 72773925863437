import * as React from "react";
import styled from "@emotion/styled";

import { ToastPosition, DEFAULT_TOAST_POSITION } from "./ToastUtils";

import "./toast-position.css";

interface Props {
  position?: ToastPosition;
}

type ToastListProps = React.PropsWithChildren<Props>;

const Toast = styled.div`
  position: fixed;
  z-index: 50;
`;

export const ToastContainer: React.FC<ToastListProps> = (props: ToastListProps) => {
  const { position, children } = props;

  return <Toast className={position || DEFAULT_TOAST_POSITION}>{children}</Toast>;
};
