import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { DefaultTheme, Stack, Text, ThemeProps, Title } from "@deliverr/ui";
import { useStorageReceivePOSelectionStep } from "./useStorageReceivePOSelectionStep";
import { CenteredWarehouseCard } from "warehouse/common/components";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageLinkCard } from "warehouse/receiving/components/cards/StorageLinkCard";
import { DateFormat, formatI18nDate } from "facility-commons";
import { LanguageCode } from "facility-commons/types";

const StyledStack = styled(Stack)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  width: 100%;
`
);

export const StorageReceivePOSelectionStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { poDetails, skus, linkMap } = useStorageReceivePOSelectionStep();
  const { arrivedAt, arrivalExpectedAt, numberOfSkus, receivingStartPalletCount } = poDetails;
  const numberOfBoxes = Object.values(skus || {}).reduce(
    (sum, val) => sum + Math.floor((val.expectedQty ?? 0) / (val.caseQty || 1)),
    0
  );
  const visibleDate = arrivedAt || arrivalExpectedAt;

  return (
    <StyledStack>
      <CenteredWarehouseCard>
        <Stack gap="S2">
          <Title displayAs="h3">
            {formatMessage(
              arrivedAt
                ? STORAGE_RECEIVING_MESSAGES.arrivedTitle
                : arrivalExpectedAt
                ? STORAGE_RECEIVING_MESSAGES.expectedToArriveTitle
                : STORAGE_RECEIVING_MESSAGES.pendingArrivalTitle
            )}
          </Title>
          {visibleDate ? (
            <Text>
              {formatI18nDate({
                date: new Date(visibleDate),
                dateFormat: DateFormat.ShortMonthDayYearTimeWithCommaSeparator,
                language: LanguageCode.ENGLISH,
              })}
            </Text>
          ) : null}
          <Text>{formatMessage(STORAGE_RECEIVING_MESSAGES.arrivalUnits, { numberOfSkus, numberOfBoxes })}</Text>
          <Text>{formatMessage(STORAGE_RECEIVING_MESSAGES.receivedPalletCount, { receivingStartPalletCount })}</Text>
        </Stack>
      </CenteredWarehouseCard>
      <StorageLinkCard linkMap={linkMap} />
    </StyledStack>
  );
};
