import { useIntl } from "react-intl";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { useAsyncFn } from "react-use";

export const useBoxTypeIdentificationModal = ({ onConfirm }: { onConfirm: () => Promise<void> }) => {
  const { formatMessage } = useIntl();

  const [{ loading }, handleSubmit] = useAsyncFn(async () => {
    await onConfirm();
  }, [onConfirm]);

  const buttonLabels = {
    Confirm: formatMessage(modalsText.confirm),
    Cancel: formatMessage(modalsText.cancel),
  };

  return { buttonLabels, loading, handleSubmit };
};
