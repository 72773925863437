import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import React from "react";
import { useIntl } from "react-intl";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { useShipmentCard } from "./useShipmentCard";
import { shipmentCardLabels } from "./shipmentCardLabels";

export const ShipmentCard = () => {
  const { shipmentId, shipmentError, handleChange, handleSubmit, loading } = useShipmentCard();
  const { formatMessage } = useIntl();

  return (
    <StackedInputCard
      errorMessage={shipmentError}
      illustration={<ScanBox />}
      illustrationTitle={formatMessage(shipmentCardLabels.scanShipmentText)}
      message={formatMessage(shipmentCardLabels.shipmentInstructionsText)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(shipmentCardLabels.shipmentID)}
      loading={loading}
      title={formatMessage(shipmentCardLabels.shipmentTitleText)}
      value={shipmentId}
      data-testid="shipment-card-input"
    />
  );
};
