import React from "react";
import { Button, ThemeProps } from "@deliverr/ui";
import { ModalTemplate, warningModalIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { ConfirmUnitsModalTextConditions, useConfirmUnitsCount } from "./useConfirmUnitsCount";
import { NumericInput } from "facility-commons/components";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { CdskuList } from "./CdskuList";
export interface ConfirmUnitsCountProps {
  onCancel: () => void;
  incorrectBoxCount: boolean;
  incorrectUnitCount: boolean;
  fewerUnits: boolean;
  fewerBoxes: boolean;
  boxesDelta: number;
  initialBoxCount: number;
  initialUnitCount: number;
  prevBoxCount?: number;
  prevUnitCount?: number;
  needsToReconfirm?: boolean;
  isUnexpectedSku?: boolean;
}

const Container = styled.div<ThemeProps>(`
      display:flex;
      flex-direction:column;
      text-align: left;
      width:100%
  `);

const ButtonsContainer = styled.div<ThemeProps>(`
      display:flex;
  `);

export const ConfirmUnitsCount: React.FC<ConfirmUnitsCountProps> = (props) => {
  const {
    incorrectBoxCount,
    incorrectUnitCount,
    onCancel,
    fewerBoxes,
    fewerUnits,
    boxesDelta,
    initialBoxCount,
    initialUnitCount,
    prevUnitCount,
    prevBoxCount,
    needsToReconfirm,
    isUnexpectedSku,
  } = props;

  const {
    boxQuantityError,
    boxQuantityRef,
    boxesQtyInput,
    unitsQtyInput,
    receivingError,
    disableContinueBtn,
    modalTitle,
    getModalTextCondition,
    unitQtyPlaceholder,
    boxQtyPlaceholder,
    backButtonText,
    confirmButtonText,
    handleBoxQuantityChange,
    handleUnitsQuantityChange,
    handleOnConfirmSubmission,
    expeditedBulkBoxLabels,
    isExpeditedBulkEligible,
  } = useConfirmUnitsCount({
    incorrectBoxCount,
    incorrectUnitCount,
    initialUnitCount,
    initialBoxCount,
    prevUnitCount,
    prevBoxCount,
    needsToReconfirm,
    isUnexpectedSku,
  });

  const justBoxesIncorrect = incorrectBoxCount && !incorrectUnitCount;
  const bothIncorrect = incorrectBoxCount && incorrectUnitCount;

  const getModalText = (modalTextConditions: ConfirmUnitsModalTextConditions) => {
    switch (modalTextConditions) {
      case ConfirmUnitsModalTextConditions.BOXES_AND_UNITS_INCORRECT_AND_NEEDS_RECONFIRM:
        return (
          <FormattedMessage
            id="warehouse.receiving.reconfirmQuantities"
            defaultMessage="The quantity of units and the number of boxes you entered do not match your last count. Please count again."
          />
        );
      case ConfirmUnitsModalTextConditions.BOXES_AND_UNITS_INCORRECT:
        return (
          <FormattedMessage
            id="warehouse.receiving.recountQuantities"
            defaultMessage="The quantities you entered are not what were expected. Please count again."
          />
        );
      case ConfirmUnitsModalTextConditions.BOXES_INCORRECT_AND_NEEDS_RECONFIRM:
        return (
          <FormattedMessage
            id="warehouse.receiving.reconfirmBoxCount"
            defaultMessage="The number of boxes you entered does not match your last count. Please count again."
          />
        );
      case ConfirmUnitsModalTextConditions.BOXES_INCORRECT:
        return (
          <FormattedMessage
            id="warehouse.receiving.differentBoxCountExpected"
            defaultMessage="You reported <bold>{boxesDelta} {moreOrLess} boxes</bold> than expected. Find these boxes and count again or receive boxes individually."
            values={{
              bold: (val) => <b>{val}</b>,
              moreOrLess: fewerBoxes ? "fewer" : "more",
              boxesDelta,
            }}
          />
        );
      case ConfirmUnitsModalTextConditions.UNITS_INCORRECT_AND_NEEDS_RECONFIRM:
        return (
          <FormattedMessage
            id="warehouse.receiving.reconfirmQuantity"
            defaultMessage="The quantity of units you entered does not match your last count. Please count again."
          />
        );
      case ConfirmUnitsModalTextConditions.UNITS_INCORRECT:
        return (
          <FormattedMessage
            id="warehouse.receiving.differentUnitQtyExpected"
            defaultMessage="The quantity of units you entered is {moreOrLess} than what we expected. Please count again."
            values={{
              moreOrLess: <b>{fewerBoxes || fewerUnits ? "less" : "more"} </b>,
            }}
          />
        );
      case ConfirmUnitsModalTextConditions.UNEXPECTED_SKU:
        return (
          <FormattedMessage
            id="warehouse.receiving.confirmUnexpectedSkuQty"
            defaultMessage="This is an unexpected SKU. Please confirm the quantity of unexpected units."
          />
        );
    }
  };

  return (
    <ModalTemplate title={modalTitle} text={getModalText(getModalTextCondition())} iconProps={warningModalIcon}>
      <Container>
        {justBoxesIncorrect || bothIncorrect ? (
          <CdskuList
            isExpeditedBulkEligible={isExpeditedBulkEligible}
            expeditedBulkBoxLabels={expeditedBulkBoxLabels}
          />
        ) : null}
        <>
          {(incorrectUnitCount || isUnexpectedSku) && (
            <NumericInput
              autoFocus
              value={unitsQtyInput}
              errorMessage={receivingError}
              hasError={Boolean(receivingError)}
              onChange={handleUnitsQuantityChange}
              placeholder={unitQtyPlaceholder}
              data-testid="confirm-units-count-units-input"
            />
          )}
          {incorrectBoxCount && (
            <NumericInput
              autoFocus={!incorrectUnitCount}
              errorMessage={boxQuantityError}
              hasError={Boolean(boxQuantityError)}
              onChange={handleBoxQuantityChange}
              placeholder={boxQtyPlaceholder}
              ref={boxQuantityRef}
              required
              value={boxesQtyInput}
              data-testid="confirm-units-count-boxes-input"
            />
          )}
        </>
        <ButtonsContainer>
          <WarehouseModalRightMarginButton block onClick={onCancel} secondary data-testid="cancel-confirm-count-button">
            {backButtonText}
          </WarehouseModalRightMarginButton>
          <Button
            block
            onClick={handleOnConfirmSubmission}
            disabled={disableContinueBtn}
            data-testid="confirm-count-button"
          >
            {confirmButtonText}
          </Button>
        </ButtonsContainer>
      </Container>
    </ModalTemplate>
  );
};
