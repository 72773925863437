import { atom } from "recoil";
import { DEFAULT_LANGUAGE } from "facility-commons/common";
import { LanguageCode } from "facility-commons/types";
import { UserStateKey } from "./UserStateKey";

export interface UserState {
  warehouseId: string;
  userName: string;
  email: string;
  isManager: boolean;
  isAdmin: boolean;
  isProblemSolver: boolean;
  isHub: boolean;
  isCrossdock: boolean;
  language: LanguageCode;
  isStorage?: boolean;
}

export const DEFAULT_USER_AUTH_STATE: UserState = {
  warehouseId: "",
  userName: "",
  email: "",
  isManager: false,
  isAdmin: false,
  isProblemSolver: false,
  isHub: false,
  isCrossdock: false,
  language: DEFAULT_LANGUAGE,
  isStorage: false,
};

export const userState = atom<UserState>({
  key: UserStateKey.USER,
  default: DEFAULT_USER_AUTH_STATE,
});

export const allWarehouseIdsState = atom<string[]>({
  key: UserStateKey.WAREHOUSE_IDS,
  default: [],
});
