import { DefaultTheme, IconV2, Text, mobileTheme, LocalizedMessage, ThemeProps, TextProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

import { UploadProgressBar } from "facility-commons/components/UploadProgressBar";
import { useUploadItem } from "./useUploadItem";
import { s3FileHandlerClient } from "facility-commons/base/Clients";
import { WarehouseFormLabelMandatory } from "facility-commons/components/emotion";

// the withComponent call overrides TextProps with generic label types,
// so we explicitly pass TextProps as a generic here to allow use to "appearance" and "size" while also getting the types for HtmlLabelElement ("htmlFor")
const UploadItemLabel = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S1};
  `
).withComponent("label");

// must be a label to trigger upload box
const UploadContainer = styled.label<ThemeProps>`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid 1px ${({ theme }) => theme.colors.NEUTRAL[80]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const SpacedProgressBar = styled(UploadProgressBar)`
  margin-top: 2rem;
`;

const IconText = styled(Text)<ThemeProps>`
  margin-top: ${({ theme }) => theme.spacing.S2};
`;

export type UnconnectedUploadItemProps = {
  value: string | null;
  uploading: boolean;
  uploadProgress: number;
  onInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export type UploadItemCustomProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "value"
> & {
  iconText?: LocalizedMessage;
  labelText?: LocalizedMessage;
  isMandatory?: boolean;
  onSuccessfulUpload?: (value: string) => void;
  onSuccessfulFileRetrieval?: (name: string, imgUrl: string) => void;
  acceptedFilesTypes?: string[];
};

export const UnconnectedUploadItem = React.forwardRef<
  HTMLInputElement,
  UploadItemCustomProps & UnconnectedUploadItemProps
>(
  (
    {
      iconText,
      id,
      labelText,
      isMandatory,
      name,
      value,
      uploading,
      uploadProgress,
      onInputFileChange,
      acceptedFilesTypes,
    },
    ref
  ) => {
    const acceptedFilesTypesStr = acceptedFilesTypes ? acceptedFilesTypes.join(",") : "image/*";
    return (
      <>
        {labelText && (
          <UploadItemLabel appearance="INFO" size="label" htmlFor={id}>
            {labelText} {isMandatory && <WarehouseFormLabelMandatory>*</WarehouseFormLabelMandatory>}
          </UploadItemLabel>
        )}
        <UploadContainer
          style={{
            backgroundImage: value && !uploading ? `url(${value})` : "",
          }}
        >
          {!uploading && isEmpty(value) && (
            <>
              <IconV2 color={mobileTheme.colors.BLUE[300]} size={"lg"} type={"camera"} />
              {iconText && <IconText appearance="INFO">{iconText}</IconText>}
            </>
          )}
          {uploading && <SpacedProgressBar progress={uploadProgress} />}

          {/* Hidden Input that stores file url, which is real */}
          <input type="hidden" name={name} ref={ref} />

          {/* Hidden fake Input field that stores temp file */}
          <HiddenInput accept={acceptedFilesTypesStr} onChange={onInputFileChange} type="file" id={id} />
        </UploadContainer>
      </>
    );
  }
);

export const UploadItem = React.forwardRef<HTMLInputElement, UploadItemCustomProps>(
  ({ iconText, id, labelText, isMandatory, name, onSuccessfulUpload }, ref) => {
    const { onInputFileChange, uploadProgress, uploading, value } = useUploadItem(
      { name, onSuccessfulUpload },
      s3FileHandlerClient
    );

    return (
      <UnconnectedUploadItem
        ref={ref}
        id={id}
        value={value}
        uploading={uploading}
        uploadProgress={uploadProgress}
        onInputFileChange={onInputFileChange}
        iconText={iconText}
        labelText={labelText}
        isMandatory={isMandatory}
        onSuccessfulUpload={onSuccessfulUpload}
      />
    );
  }
);
