import { useIntl } from "react-intl";
import { useAsyncFn, useMount } from "react-use";
import { commonConsolidationMessages } from "warehouse/receiving/content";
import { useState } from "react";
import { createDangerNotification, genericOnScannerInputChange, log, logStart, setProp } from "facility-commons/utils";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useRouter } from "facility-commons/hooks";
import { useReceivingFlow } from "warehouse/receiving/base";
import { isEmpty } from "lodash";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { COMMON_LABELS } from "../warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";
import { receiveFlowTypeState } from "warehouse/receiving/ReceivingState/ReceiveFlowTypeState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";

// const isValidConsolidationLabel = validateString(validConsolidation);

export const useConsolidationCard = () => {
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [consolidationLabelErr, setConsolidationLabelErr] = useState("");
  const [scanInput, setScanInput] = useState("");
  const setReceivingFlow = useSetRecoilState(receiveFlowTypeState);

  const { formatMessage } = useIntl();
  const { inboundClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const { push } = useRouter();
  const { handleUnknownError } = useReceivingFlow();
  const initializeReceivingData = useInitializeReceivingData();
  const { errorResponse, successResponse, addAutoCloseNotification } = useCommonFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { requestBatchId } = receivingData;

  useMount(() => {
    // We typically set the receive flow type on click in the receive type selector
    // But it's possible to be redirected to the consolidation receive flow when an ASN for a consolidation is scanned
    // in the ASN receive flow. In this case, we need to set the receive flow type here
    setReceivingFlow(ReceivingFlowType.CONSOLIDATION_RECEIVING);
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonConsolidationMessages.consolidationHeader)));
    if (isEmpty(requestBatchId)) {
      initializeReceivingData();
    }
  });

  const [{ loading }, submitScanInput] = useAsyncFn(async (scannedInput: string) => {
    if (!scannedInput) {
      errorResponse();
      return setConsolidationLabelErr(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
    }

    // We temporarily disabling this validation to allow for scanning of ASNs,
    // this to to unblock receiving for a new warehouse LAX1 2023-09-21
    // if (!isValidConsolidationLabel(scannedId)) {
    //   errorResponse();
    //   setReceivingData(setProp("consolidationLabel", undefined));
    //   return setConsolidationLabelErr(formatMessage(commonConsolidationMessages.invalidConsolidationLabelFormat));
    // }

    const ctx = logStart({ fn: "useConsolidationCard.submitScannedInput", scannedInput, warehouseId });

    try {
      const response = await inboundClient.consolidationValidateLabel(warehouseId, scannedInput);

      if (response.error) {
        errorResponse();
        const { message, payload } = response.error;
        const messageId = getErrorMessageId({ response: response.error });
        const formattedValues = payload && formatValues(payload);

        setConsolidationLabelErr(messageId ? formatMessage(messageId, formattedValues) : message);
        setReceivingData(setProp("consolidationLabel", undefined));
        setScanInput("");
      } else {
        const { consolidationLabel } = response.data!;
        setWarehouseAppState(setProp("pageSubtitle", consolidationLabel));
        setReceivingData(setProp("consolidationLabel", consolidationLabel));
        successResponse();
        push(ReceivingPath.SKU);
      }
    } catch (err: any) {
      setReceivingData(setProp("consolidationLabel", undefined));
      log({ ...ctx, error: err.response }, "Error validating label");
      handleUnknownError({ ...ctx, err }, new Error("Error validating label"));
      addAutoCloseNotification(createDangerNotification(formatMessage(COMMON_LABELS.UNEXPECTED_ERROR)));
    }
  });

  const updateScanInput = (newScanInput: string) => {
    setScanInput(newScanInput);
    setConsolidationLabelErr("");
  };

  const handleChange = genericOnScannerInputChange(scanInput, updateScanInput, submitScanInput, "upper");
  return {
    handleChange,
    submitScanInput,
    formatMessage,
    scanInput,
    loading,
    consolidationLabelErr,
  };
};
