import { NonComplianceClient, OutboundLoadBalancerClient, ProductClient } from "@deliverr/commons-clients";
import { InboundClient } from "@deliverr/legacy-inbound-client";
import { AuthEngine } from "@deliverr/commons-objects";

const commonHeaders = {
  "X-Request-Caller": process.env.REQUESTER_NAME ?? "warehouse-portal",
  "X-Direct-Caller": process.env.REQUESTER_NAME ?? "warehouse-portal",
};

export const nonComplianceClient = new NonComplianceClient({
  baseURL: process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const inboundClient = new InboundClient({
  baseURL: process.env.REACT_APP_INBOUND_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const outboundLoadBalancerClient = new OutboundLoadBalancerClient({
  baseURL: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_URL,
  basicAuthUser: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_AUTH_USER,
  basicAuthPassword: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_AUTH_PASSWORD,
  headers: commonHeaders,
});

export const productClient = new ProductClient({
  baseURL: process.env.REACT_APP_PRODUCT_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});
