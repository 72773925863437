export enum StorageFlowCardType {
  STORAGE_RECEIVING_PO_SCAN = "STORAGE_RECEIVING_PO_SCAN",
  STORAGE_RECEIVING_PALLET_COUNT = "STORAGE_RECEIVING_PALLET_COUNT",
  STORAGE_INBOUND_PO_SELECTION_STEP = "STORAGE_INBOUND_PO_SELECTION_STEP",
  STORAGE_REPORT_PROBLEM_SELECTION = "STORAGE_REPORT_PROBLEM_SELECTION",
  STORAGE_RECEIVING_SKU = "STORAGE_RECEIVING_SKU",
  STORAGE_RECEIVING_LOT_DETAILS = "STORAGE_RECEIVING_LOT_DETAILS",
  STORAGE_RECEIVING_SKU_QUANTITY = "STORAGE_RECEIVING_SKU_QUANTITY",
  STORAGE_RECEIVING_SKU_CASE_INFO = "STORAGE_RECEIVING_SKU_CASE_INFO",
  STORAGE_RECEIVING_SKU_CASE_PACK_SELECTION = "STORAGE_RECEIVING_SKU_CASE_PACK_SELECTION",
  STORAGE_RECEIVING_SKU_LP = "STORAGE_RECEIVING_SKU_LP",
  STORAGE_RECEIVING_SKU_LC = "STORAGE_RECEIVING_SKU_LC",
  STORAGE_RECEIVING_SKU_CONFIRMATION = "STORAGE_RECEIVING_SKU_CONFIRMATION",
  STORAGE_TICKET_NON_COMPLIANCE = "STORAGE_TICKET_NON_COMPLIANCE",
}
