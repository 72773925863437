import React from "react";
import { Notification, Box } from "@deliverr/ui";
import { Appearance } from "@deliverr/ui/lib-esm/shared";
import { commonBoxMessages } from "warehouse/receiving/content";
import { useIntl } from "react-intl";

export const HighRiskBoxWarningNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Box marginBottom={"S1"} as="section">
      <Notification
        appearance={Appearance.WARNING}
        content={{
          description: formatMessage(commonBoxMessages.highRiskBox),
        }}
      />
    </Box>
  );
};
