/**
 * File for accumulating frequently re-used strings, labels, and placeholders
 * to simplify renaming and refactoring. Effectively a "string table".
 */

import { defineMessages } from "react-intl";

export const PALLET_IDENTIFIER_PLACEHOLDER = "Pallet barcode";

export const WarehouseMessages = defineMessages({
  palletIdentifierPlaceholder: {
    id: "facilityCommons.palletIdentifierPlaceholder",
    defaultMessage: "Pallet barcode",
  },
  palletTitleText: {
    id: "facilityCommons.palletTitleText",
    defaultMessage: "Scan Pallet",
  },
  palletMessageText: {
    id: "facilityCommons.palletMessageText",
    defaultMessage: "Pallet barcodes are on yellow labels.",
  },
  boxOrPalletTitleText: {
    id: "facilityCommons.boxOrPalletTitleText",
    defaultMessage: "Scan Box or Pallet",
  },
  scanBoxLabelTitleText: {
    id: "facilityCommons.scanBoxLabelTitleText",
    defaultMessage: "Scan Box Label",
  },
  scanBoxLabelMessageText: {
    id: "facilityCommons.scanBoxLabelMessageText",
    defaultMessage: "Box labels can be tracking codes or CDSKUs",
  },
  scanBoxLabelErrorMessageText: {
    id: "facilityCommons.scanBoxLabelErrorMessageText",
    defaultMessage: "{entity} is not recognized. Contact Flexport Support.",
  },
  boxArrivalAsnWasScannedErrorMessageText: {
    id: "facilityCommons.boxArrivalAsnWasScannedErrorMessageText",
    defaultMessage: "{label} is a PO #. Scan a tracking code or CDSKU on the box.",
  },
  moveToProblemAreaLabel: {
    id: "facilityCommons.moveToProblemAreaLabel",
    defaultMessage: "Move this box to the Problem Area.",
  },
  palletHelpText: {
    id: "facilityCommons.palletHelpText",
    defaultMessage: "Starts with 'PID'",
  },
  palletFreightPalletMixHelpText: {
    id: "facilityCommons.palletFreightPalletMixHelpText",
    defaultMessage: "Starts with 'PID' or 'FPID'",
  },
  freightPalletHelpText: {
    id: "facilityCommons.freightPalletHelpText",
    defaultMessage: "Starts with 'FPID'",
  },
  boxHelpText: {
    id: "facilityCommons.boxHelpText",
    defaultMessage: "Starts with 'CD'",
  },
  boxOrPalletHelpText: {
    id: "facilityCommons.boxOrPalletHelpText",
    defaultMessage: "Starts with 'CD' or 'PID'",
  },
  palletsFreightPalletBoxMixHelpText: {
    id: "facilityCommons.palletsFreightPalletBoxMixHelpText",
    defaultMessage: "Starts with 'CD', 'PID', or 'FPID'",
  },
  boxInstructionsText: {
    id: "facilityCommons.boxInstructionsText",
    defaultMessage: "Scan or enter the CDSKU on the box contents label.",
  },
  boxBreakHelpText: {
    id: "facilityCommons.crossdock.common.boxBreakHelpText",
    defaultMessage: "Starts with 'BBS'",
  },
  boxBreakCountUnitsTitle: {
    id: "facilityCommons.crossdock.common.boxBreakCountUnits.title",
    defaultMessage: "Count Units",
  },
  boxBreakCountUnitsMessage: {
    id: "facilityCommons.crossdock.common.boxBreakCountUnits.message",
    defaultMessage: "Enter total units for {unitBarcode}",
  },
  boxBreakCountUnitsPlaceholder: {
    id: "facilityCommons.crossdock.common.boxBreakCountUnitsPlaceholder",
    defaultMessage: "Total Units",
  },
  boxTitleText: {
    id: "facilityCommons.boxTitleText",
    defaultMessage: "Scan Box",
  },
  boxScan: {
    id: "facilityCommons.scanABox",
    defaultMessage: "Scan a box",
  },
  boxIdentifierPlaceholder: {
    id: "facilityCommons.boxIdentifierPlaceholder",
    defaultMessage: "Box barcode",
  },
  chuteScanPlaceholder: {
    id: "facilityCommons.crossdock.common.chuteScanPlaceholder",
    defaultMessage: "Chute barcode",
  },
  cdsku: {
    id: "facilityCommons.cdsku",
    defaultMessage: "CDSKU",
  },
  lotNumber: {
    id: "warehouse.common.lotNumber",
    defaultMessage: "Lot number",
  },
  expirationDate: {
    id: "warehouse.common.expirationDate",
    defaultMessage: "Expiration date",
  },
  po: {
    id: "facilityCommons.po",
    defaultMessage: "PO #",
  },
  scanCdsku: {
    id: "facilityCommons.scanCdsku",
    defaultMessage: "Scan CDSKU",
  },
  scanPO: {
    id: "facilityCommons.scanAsn",
    defaultMessage: "Scan or enter PO #",
  },
  labeledPoNumber: {
    id: "facilityCommons.poNumLabel",
    defaultMessage: "PO # {poNum}",
  },
  unknown: { id: "facilityCommons.unknown", defaultMessage: "Unknown" },
  missing: { id: "facilityCommons.missing", defaultMessage: "Missing" },
  barcode: { id: "facilityCommons.barcode", defaultMessage: "Barcode" },
  email: { id: "facilityCommons.email", defaultMessage: "Email" },
  restricted: { id: "facilityCommons.restricted", defaultMessage: "Restricted" },
  yes: { id: "facilityCommons.yes", defaultMessage: "Yes" },
  no: { id: "facilityCommons.no", defaultMessage: "No" },
  scanBOLPRO: {
    id: "facilityCommons.scanBOLPRO",
    defaultMessage: "Scan or enter BOL# / PRO#",
  },
  view: { id: "facilityCommons.view", defaultMessage: "View" },
});
