import { ReactComponent as ScanSku } from "facility-commons/common/assets/scanSKU.svg";
import React from "react";
import { useIntl } from "react-intl";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { useCreatePackageCard } from "./useCreatePackageCard";
import { createPackageCardLabels } from "./createPackageCardLabels";
import { Text } from "@deliverr/ui";
import { FacilityCard } from "facility-commons";

export const CreatePackageCard = () => {
  const {
    skuBarcode,
    skuScanError,
    handleChange,
    handleSubmit,
    loading,
    itemsAdded,
    packageWeight,
  } = useCreatePackageCard();
  const { formatMessage } = useIntl();

  return (
    <>
      <StackedInputCard
        errorMessage={skuScanError}
        illustration={<ScanSku />}
        message={formatMessage(createPackageCardLabels.scanSkuInstructionsText)}
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={formatMessage(createPackageCardLabels.scanSkuBarcode)}
        loading={loading}
        title={formatMessage(createPackageCardLabels.scanSkuTitleText)}
        value={skuBarcode}
        data-testid="create-package-card-input"
      />
      <FacilityCard>
        <Text appearance="INFO" data-testid="qty-added-to-pkg">
          {formatMessage(createPackageCardLabels.qtyAddedToPackage, {
            itemsAdded: <b>{itemsAdded}</b>,
          })}
        </Text>
        <Text appearance="INFO" data-testid="pkg-wt">
          {formatMessage(createPackageCardLabels.packageWeight, {
            packageWeight: <b>{packageWeight}</b>,
            unit: Number(packageWeight),
          })}
        </Text>
      </FacilityCard>
    </>
  );
};
