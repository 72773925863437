import { isNil, noop } from "lodash/fp";
import { useState, useEffect } from "react";
import { useAutoFocus } from "facility-commons/hooks";
import { TextInputCardProps } from "./TextInputCardProps";
import { useScanner } from "facility-commons/hooks/useScanner";
import { useMountedState } from "react-use";

export function useTextInput({
  autoFocus,
  disabled = false,
  errorMessage,
  focusOnError = false,
  label,
  newest = true,
  placeholder,
  onChange = noop,
  onSubmit = noop,
  value,
}: TextInputCardProps) {
  // used to differentiate individual cards of the same type from one another
  const isEmptyValue = value.length === 0;
  const applyLabelMargin = !(isNil(label) && isNil(placeholder)) && isEmptyValue;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const inputEl = useAutoFocus(value, autoFocus);
  const isMounted = useMountedState();

  useScanner({ onChange, newest });

  const textInputOnChange: React.FormEventHandler<HTMLInputElement> = async (e) => {
    await onChange(e.currentTarget.value);
  };

  useEffect(() => {
    if (inputEl && focusOnError && errorMessage) {
      inputEl.current?.focus();
    }
  }, [focusOnError, errorMessage, inputEl]);

  const prevDefaultOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onSubmit(value);
    if (isMounted()) {
      setIsSubmitting(false);
    }
  };

  return {
    applyLabelMargin,
    disabled: disabled || isSubmitting,
    inputEl,
    isEmptyValue,
    handleChange: textInputOnChange,
    handleSubmit: prevDefaultOnSubmit,
  };
}
