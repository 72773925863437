import styled from "@emotion/styled";
import { Card, IconV2, IconV2Props, Stack, Title, Text, LocalizedMessage, ThemeProps, BoxReset } from "@deliverr/ui";
import React from "react";
import { useMount } from "react-use";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";

import { ModalOverlay } from "./ModalOverlay";
import { Property } from "csstype";
import { appSfx, SoundFx } from "facility-commons/common/sfx/SoundFx";

export interface ModalTemplateProps {
  title: LocalizedMessage;
  iconProps?: IconV2Props;
  text?: LocalizedMessage;
  content?: React.ReactNode;
  buttonLayout?: Property.FlexDirection;
  soundFx?: SoundFx; // optional sound effect to play on mount
  isContentFullWidth?: boolean;
  showExitButton?: boolean;
  onExitClick?: () => void;
}

const ModalStack = styled(Stack)`
  text-align: center;
`;

const ButtonContainer = styled.div<{ buttonLayout: ModalTemplateProps["buttonLayout"] }>(
  ({ buttonLayout }) => `
  display: flex;
  flex-direction: ${buttonLayout || "row"};
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
);

const StyledCard = styled(Card)<ThemeProps>(({ theme }) => {
  return `
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S3};
  padding: ${theme.spacing.S5};
  width: 100%;
  max-width:360px;
`;
});

const TitleContainer = styled(Stack)<{ showExitButton?: boolean }>(
  ({ showExitButton }) => `
  justify-content: ${showExitButton ? "flex-start" : "center"};
  align-items: center;
  width: 100%;
`
);

const ContentDiv = styled.div<{ isContentFullWidth?: boolean }>(
  ({ isContentFullWidth }) => `
  width: ${isContentFullWidth ? "100%" : "inherit"};
`
);

const ExitIconButton = styled.button<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE[300]};
`
);

export const ModalTemplate: React.FC<ModalTemplateProps> = ({
  iconProps,
  title,
  text,
  buttonLayout,
  content,
  children,
  soundFx,
  showExitButton,
  onExitClick,
  isContentFullWidth,
}) => {
  useMount(() => {
    if (soundFx) {
      appSfx[soundFx].play();
    }
  });

  return (
    <ModalOverlay>
      <StyledCard as="section" size="MD">
        <ModalStack center gap="S4">
          {iconProps && <IconV2 {...iconProps} />}
          <TitleContainer direction="HORIZONTAL" gap="S4" showExitButton={showExitButton}>
            {showExitButton && (
              <ExitIconButton onClick={onExitClick}>
                <IconV2 type="close" />
              </ExitIconButton>
            )}
            <Title as="h2" displayAs="h3" textAlign={TextAlign.center}>
              {title}
            </Title>
          </TitleContainer>
          {text && (
            <BoxReset marginBottom="S2">
              <Text textAlign={TextAlign.center} appearance="INFO">
                {text}
              </Text>
            </BoxReset>
          )}
          {content && <ContentDiv isContentFullWidth={isContentFullWidth}>{content}</ContentDiv>}
          <ButtonContainer buttonLayout={buttonLayout}>{children}</ButtonContainer>
        </ModalStack>
      </StyledCard>
    </ModalOverlay>
  );
};
