import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons/utils";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import {
  createBulkBoxScanCompletionNotification,
  createBulkBoxScanNotification,
  useReceivingFlow,
} from "warehouse/receiving/base";
import { bulkErrorMessages, bulkMessages } from "warehouse/receiving/content";
import { ReceivingPath } from "warehouse/receiving/routes";
import { isInvalidCdsku } from "warehouse/receiving/utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentSkuState, isMidBulkReceiveFlow, receivingState } from "warehouse/receiving/ReceivingState";
import { useAsyncFn, useMount } from "react-use";
import { v4 as uuid } from "uuid";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { ReceivingData } from "warehouse/receiving/ReceivingState/Types";
import { uniq } from "lodash";
import { commonMessages } from "facility-commons/labels";
import { userState } from "facility-commons/base/Auth/userState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { COMMON_LABELS } from "../warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";

export const useBulkScanCard = () => {
  const [currentCdsku, setCurrentCdsku] = useState("");
  const [receiveError, setReceiveError] = useState("");
  const [receivingData, setReceivingData] = useRecoilState(receivingState);

  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const isMidBulkReceive = useRecoilValue(isMidBulkReceiveFlow);
  const { quantity, cdsku } = useRecoilValue(currentSkuState);
  const { hideModal, showModal } = useWarehouseModal();
  const { handleUnknownError } = useReceivingFlow();
  const { warehouseId } = useRecoilValue(userState);

  const { inboundClient } = useClientsWithAuth();
  const { errorResponse, successResponse, addNotification } = useCommonFlow();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { cdskusToReceive, identicalBoxes, requestBatchId } = receivingData;
  const boxesScannedSoFar = cdskusToReceive.length;

  useEffect(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(bulkMessages.title)));
    setWarehouseAppState(
      setProp(
        "pageSubtitle",
        ` ${formatMessage(commonMessages.box, {
          boxesQty: identicalBoxes,
        })}`
      )
    );
  }, [quantity, setWarehouseAppState, identicalBoxes, formatMessage]);

  useEffect(() => {
    if (boxesScannedSoFar >= identicalBoxes) {
      addNotification(
        createBulkBoxScanCompletionNotification({
          boxCount: boxesScannedSoFar,
          totalUnits: boxesScannedSoFar * quantity,
          received: false,
        })
      );
      router.push(ReceivingPath.LOCATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxesScannedSoFar, identicalBoxes, router]);

  useMount(() => {
    // Since a request can be committed from this card, we initialize a new dedupKey everytime it's loaded
    setReceivingData(setProp("dedupKey", uuid()));
  });

  const [submitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      const ctx = logStart({ fn: "useBulkScan.handleSubmit", value, warehouseId, cdskusToReceive });

      const onSuccess = () => {
        successResponse();
        addNotification(createBulkBoxScanNotification(value));
        const newCdskusToReceive = uniq([...cdskusToReceive, value]);

        setReceivingData((state: ReceivingData) => ({
          ...state,
          cdskusToReceive: newCdskusToReceive,
        }));
        setCurrentCdsku("");
        if (isMidBulkReceive) {
          router.push(ReceivingPath.BULK);
        } else {
          router.push(ReceivingPath.LOCATION);
        }
      };

      if (isInvalidCdsku(value)) {
        setCurrentCdsku("");
        errorResponse();
        return setReceiveError(formatMessage(COMMON_LABELS.INVALID_CDSKU_ERROR));
      }
      if (!value.length) {
        errorResponse();
        return setReceiveError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      }

      // block duplicate CDSKU receives
      if (cdskusToReceive.includes(value)) {
        errorResponse();
        return setReceiveError(formatMessage(bulkErrorMessages.duplicateCdsku));
      } else {
        try {
          const response = await inboundClient.receivingAppValidateBoxAdditionToBulkBatch(
            warehouseId,
            cdsku,
            value,
            requestBatchId
          );
          if (response.error) {
            log({ ...ctx, response }, "CDSKU not valid for bulk receive");
            errorResponse();
            const { message, payload } = response.error;
            const messageId = getErrorMessageId({ response: response.error });
            const formattedValues = payload && formatValues(payload);
            setReceiveError(messageId ? formatMessage(messageId, formattedValues) : message);
          } else {
            log({ ...ctx, response }, "CDSKU validated successfully");
            return onSuccess();
          }
        } catch (e) {
          log({ ...ctx, e }, "error while validating CDSKU");
          handleUnknownError(ctx, e);
        }
      }
    },
    [cdskusToReceive, cdsku, warehouseId, requestBatchId, isMidBulkReceive]
  );

  const updateCdsku = (value: string) => {
    setCurrentCdsku(value);
    setReceiveError("");
  };

  const handleChange = genericOnScannerInputChange(currentCdsku, updateCdsku, handleSubmit, "upper");

  const showUpdateQuantityModal = () => {
    const hideQtyModal = () => hideModal(WarehouseModal.RECEIVING_UPDATE_BULK_QTY);

    showModal(WarehouseModal.RECEIVING_UPDATE_BULK_QTY, {
      onCancel: hideQtyModal,
      onConfirm: (value: number) => {
        setReceivingData(setProp("identicalBoxes", value));
        hideQtyModal();
      },
    });
  };

  // we show the user which box # they are scanning, not how many have been scanned
  const boxesScanned = boxesScannedSoFar + 1;
  const boxesToScan = identicalBoxes;

  // There appears to be a rare bug where this can happen, but we can't figure out why
  // We alert on this so we can get observability into how often this does or doesn't happen
  if (boxesScanned > boxesToScan) {
    log({ fn: "useBulkScanCard", receivingData, boxesScanned, boxesToScan }, "more boxesScanned than boxesToScan");
  }

  return {
    boxesScanned,
    boxesToScan,
    currentCdsku,
    handleChange,
    handleSubmit,
    receiveError,
    showUpdateQuantityModal,
    loading: submitState.loading,
  };
};
