import React from "react";
import { useIntl } from "react-intl";
import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { SoundFx } from "facility-commons/common/sfx";
import styled from "@emotion/styled";

interface RouteToPalletArrivalScanModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  palletLabel: string;
}

const ButtonContainer = styled(Grid)`
  width: 100%;
`;

export const RouteToPalletArrivalScanModal: React.FC<RouteToPalletArrivalScanModalProps> = ({
  onConfirm,
  onCancel,
  palletLabel,
}) => {
  const { formatMessage } = useIntl();
  const title = formatMessage(modalsText.routeToPalletArrivalScanTitle, { palletLabel });
  const message = formatMessage(modalsText.routeToPalletArrivalScanMessage);

  return (
    <ModalTemplate
      buttonLayout="column"
      title={title}
      text={message}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.ERROR}
    >
      <ButtonContainer gap="S3">
        <Button block data-testid="route-to-pallet-confirm-button" onClick={onConfirm}>
          {formatMessage(modalsText.confirm)}
        </Button>
        <Button block secondary type="button" data-testid="route-to-pallet-cancel-button" onClick={onCancel}>
          {formatMessage(modalsText.cancel)}
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
