import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { useAsyncFn, useLifecycles } from "react-use";

import { createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import {
  createReceivingLotInfo,
  createReceivingSKUCaseInfo,
  createReceivingSKUCasePackSelection,
  createReceivingSKULP,
  createReceivingSKUScan,
} from "../storageReceivingCardCreators";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { BLIND_INBOUND_SELLERID } from "../blindInboundsData";

export const useReceivingSKUQuantityScanFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const [scanError, setScanError] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const [caseQty, setCaseQty] = useState<number>(scanState.currentSkuDetails?.receivedCaseQty ?? 0);
  const [boxesQty, setBoxesQty] = useState<number>(scanState.currentSkuDetails?.boxesQty ?? 0);
  const { addFlowCard, hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { showModal } = useWarehouseModal();
  const boxesRef = useRef<HTMLInputElement>(null);
  const { productClient } = useClientsWithAuth();
  const acceptableSellerIdsForCasePackConfig: string[] = Object.values(BLIND_INBOUND_SELLERID);
  const isBlindInbound = acceptableSellerIdsForCasePackConfig.includes(scanState.sellerId);
  const [hasWarning, setWarning] = useState<boolean>(
    !!(
      scanState.currentSkuDetails?.expectedCaseQty &&
      scanState.currentSkuDetails?.expectedCaseQty !== scanState.currentSkuDetails?.receivedCaseQty
    )
  );

  const currentSKU = scanState.currentSKU!;
  const currentSKUDetails = scanState.currentSkuDetails;

  const determineNextScreenCard = (isNewChildProductToBeCreated: boolean) => {
    if (isBlindInbound) {
      return createReceivingSKUCasePackSelection({});
    }
    return isNewChildProductToBeCreated ? createReceivingSKUCaseInfo({}) : createReceivingSKULP({});
  };

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_QUANTITY_RECEIVING_BACK_BUTTON);
      showFlowButton(StorageFlowButtonType.STORAGE_CONTINUE_SKU_QUANTITY_RECEIVING);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [{ loading: backLoading }, handleBackClick] = useAsyncFn(() => {
    const handleBackReceiveSKUQtyScan = async () => {
      if (currentSKUDetails.isFefoEnabled || currentSKUDetails.isLotTrackingEnabled) {
        addFlowCard(createReceivingLotInfo({}));
      } else {
        addFlowCard(createReceivingSKUScan({}));
      }
    };
    return handleBackReceiveSKUQtyScan();
  });

  const [{ loading }, handleSubmit] = useAsyncFn((skuDetailsParam) => {
    const handleSubmitReceiveSkuQtyScan = async (skuDetails) => {
      const dsku = scanState.currentSKU;
      const ctx = { fn: "useReceivingSKUQuantityScanFlow.handleSubmitSKUQuantityScan", poNum: scanState.poNum, dsku };
      logStart(ctx);
      let isNewChildProductToBeCreated = false;

      if (!scanState.currentSkuDetails?.expectedCaseQty) {
        setScanState((state) => ({
          ...state,
          currentSkuDetails: {
            ...state.currentSkuDetails,
            expectedCaseQty: skuDetails.caseQty,
          },
        }));
      }

      if (!isBlindInbound && skuDetails.receivedCaseQty !== skuDetails.caseQty) {
        const parentDSKUDetails = await productClient.getUnifiedProducts(
          [skuDetails.packOf ? skuDetails.packOf : dsku],
          {
            includeProductCasePacks: true,
          }
        );
        const newChildProducts = parentDSKUDetails?.[
          skuDetails.packOf ? skuDetails.packOf : dsku
        ].productCasePacks?.filter((product) => product.quantity === skuDetails.receivedCaseQty);

        if (newChildProducts?.length && newChildProducts?.length > 0) {
          setScanState((state) => ({
            ...state,
            currentSKU: newChildProducts?.[0].dsku,
            currentSkuDetails: {
              ...state.currentSkuDetails,
              dsku: newChildProducts?.[0].dsku,
              caseQty: newChildProducts?.[0].quantity,
              width: newChildProducts?.[0].width,
              height: newChildProducts?.[0].height,
              length: newChildProducts?.[0].length,
              lengthUnit: newChildProducts?.[0].lengthUnit,
              weight: newChildProducts?.[0].weight,
              weightUnit: newChildProducts?.[0].weightUnit,
              barcodes: [...state.currentSkuDetails.barcodes, newChildProducts?.[0].dsku],
            },
          }));
        } else {
          isNewChildProductToBeCreated = true;
        }
      }

      try {
        transition([
          {
            // To be switched once warehouses are equipped with proper tools to measure case dims and weight
            card: determineNextScreenCard(isNewChildProductToBeCreated),
            notification: createSuccessNotification(
              formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanSuccessNotificationTitle)
            ),
            sfx: SoundFx.SUCCESS,
            flash: "SUCCESS",
            onTransition: () => {
              hideAllFlowButtons();
              if (isBlindInbound) {
                showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_PACK_SELECTION_BACK_BUTTON);
                showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_PACK_SELECTION_CONTINUE_BUTTON);
              } else if (isNewChildProductToBeCreated) {
                showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_INFO_RECEIVING_BACK_BUTTON);
                showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_INFO_RECEIVING_CONTINUE_BUTTON);
              } else {
                showFlowButton(StorageFlowButtonType.STORAGE_SKU_LP_RECEIVING_BACK_BUTTON);
              }
            },
          },
        ]);
      } catch (err: any) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err.message),
        });
      }
    };
    return handleSubmitReceiveSkuQtyScan(skuDetailsParam);
  });

  const handleSKUQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseQuantity = parseInt(e.target.value, 10);
    setCaseQty(caseQuantity);

    setWarning(caseQuantity !== (scanState.currentSkuDetails.expectedCaseQty || scanState.currentSkuDetails.caseQty));

    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        qty: caseQuantity * (boxesQty ?? 1),
        receivedCaseQty: caseQuantity,
      },
    }));
  };

  const handleSKUBoxesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfBoxes = parseInt(e.target.value, 10);
    setBoxesQty(numberOfBoxes);
    setScanState((state) => ({
      ...state,
      currentSkuDetails: { ...state.currentSkuDetails, qty: caseQty * (numberOfBoxes ?? 1), boxesQty: numberOfBoxes },
    }));
  };

  const handleClick = () => {
    setScanError("");
    handleSubmit(scanState.currentSkuDetails);
  };

  const isContinueButtonDisabled = !scanState.currentSkuDetails?.qty || !scanState.currentSkuDetails?.boxesQty;

  return {
    scanState,
    caseQty,
    boxesQty,
    boxesRef,
    scanError,
    currentSKU,
    isContinueButtonDisabled,
    handleSKUQuantityChange,
    handleSKUBoxesChange,
    handleSubmit,
    handleClick,
    handleBackClick,
    loading,
    backLoading,
    hasWarning,
  };
};
