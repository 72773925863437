import { Box, BoxProps, BoxReset, Button, ButtonProps, LocalizedMessage, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";

const ActionContainer = styled(Box)<ThemeProps>(
  ({ theme }) => `
  div:not(:first-of-type) {
    margin-top: ${theme.spacing.S2};    
  }
`
);

export interface DashboardActionConfigProps extends ButtonProps {
  /** status or boolean function that determines status required to show the action. If undefined, always show it */
  statusRequired?: string | ((status: string) => boolean);
  label: LocalizedMessage;
}
export interface DashboardDetailActionSectionProps extends BoxProps {
  status: string;
  /** Ordered list of action configurations */
  actions: DashboardActionConfigProps[];
}

export const DashboardDetailActionSection: React.FC<DashboardDetailActionSectionProps> = ({
  status,
  actions,
  ...boxProps
}) => {
  return (
    <ActionContainer {...boxProps}>
      {actions.map(({ statusRequired, label, ...buttonProps }, index) =>
        !statusRequired || (typeof statusRequired === "string" ? statusRequired === status : statusRequired(status)) ? (
          <BoxReset key={`${statusRequired}-action-${index}`} marginTop="S2" width="100%">
            <Button {...buttonProps}>{label}</Button>
          </BoxReset>
        ) : null
      )}
    </ActionContainer>
  );
};
