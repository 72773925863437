import { useEffect, useState } from "react";
import { useMount } from "react-use";

export const useSlider = (startValue, middleValue, onSliderChange, min, max) => {
  const [positiveSliderPercentage, setPositiveSliderPercentage] = useState(100);
  const [negativeSliderPercentage, setNegativeSliderPercentage] = useState(100);
  const [diff, setDiff] = useState(0);
  const [sliderValue, setSliderValue] = useState(startValue);

  useMount(() => {
    setDiff(startValue - middleValue);
    // Calculated to properly display red track highlighting when slider handle is set to a value less than the starting value
    setNegativeSliderPercentage(Math.ceil(((middleValue - min) / (max - min)) * 100));
  });

  const onChange = (value) => {
    setDiff(value - middleValue);
    setSliderValue(value);
  };

  useEffect(() => {
    // Calculated to properly display green track highlighting when slider handle is set to a value greater than the starting value
    setPositiveSliderPercentage(sliderValue < middleValue ? 0 : Math.ceil((diff / (sliderValue - min)) * 100));
    onSliderChange(sliderValue);
  }, [diff, sliderValue, middleValue, startValue, onSliderChange, min]);

  return {
    diff,
    negativeSliderPercentage,
    positiveSliderPercentage,
    sliderValue,
    onChange,
  };
};
