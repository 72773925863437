import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { Card, Title, NumberStepper, ThemeProps, LocalizedMessage } from "@deliverr/ui";

const StyledLabelsCountCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.S2};
    padding: ${theme.spacing.S5} ${theme.spacing.S4};
  `
);

export interface LabelsCountCardProps {
  labelCardText?: LocalizedMessage;
  labelCount: number;
  maxLabels: number;
  minLabels: number;
  setLabelCount: (newCount: number) => void;
}

export const LabelsCountCard: React.FC<LabelsCountCardProps> = ({
  labelCardText,
  labelCount,
  maxLabels,
  minLabels,
  setLabelCount,
}) => {
  return (
    <StyledLabelsCountCard size="SM">
      {labelCardText ? (
        labelCardText
      ) : (
        <Title as="h4" displayAs="h4">
          <FormattedMessage id="facilityCommons.cards.labelsCountCard.title" defaultMessage="Labels" />
        </Title>
      )}
      <NumberStepper value={labelCount} onChange={setLabelCount} min={minLabels} max={maxLabels} editableInput />
    </StyledLabelsCountCard>
  );
};
