import { defineMessages } from "react-intl";
import * as React from "react";

export const shipmentSummaryCardLabels = defineMessages({
  shipmentTitle: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.shipmentTitle",
    defaultMessage: "Shipment #{shipmentId}",
  },
  shipmentType: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.shipmentType",
    defaultMessage: "Shipping Type: {shipmentType} ",
  },
  shipmentMethod: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.shipmentMethod",
    defaultMessage: "Shipping Method: {shipmentMethod}",
  },
  retailer: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.retailer",
    defaultMessage: "Retailer: {retailer}",
  },
  totalItems: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.totalItems",
    defaultMessage: "Total items: {totalItems}",
  },
  pendingItems: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.pendingItems",
    defaultMessage: "Pending items: {pendingItems}",
  },
  packagesCreated: {
    id: "warehouse.rs-outbounds.shipmentSummaryCard.packagesCreated",
    defaultMessage: "Packages created: {numOfPackagesCreated}",
  },
  createPackageButton: {
    id: "warehouse.rs-outbounds.createPackageButton",
    defaultMessage: "Create Package",
  },
  reprintAllLabelsButton: {
    id: "warehouse.rs-outbounds.reprintAllLabelsButton",
    defaultMessage: "Reprint all labels",
  },
  scanNextShipmentButton: {
    id: "warehouse.rs-outbounds.scanNextShipmentButton",
    defaultMessage: "Scan next Shipment",
  },
});
