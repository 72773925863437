import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";

export const isConsolidationFlow = (receivingFlow?: ReceivingFlowType | null) =>
  Boolean(receivingFlow === ReceivingFlowType.CONSOLIDATION_RECEIVING);

export const isPalletReceiveFlow = (receivingFlow?: ReceivingFlowType | null) =>
  Boolean(receivingFlow === ReceivingFlowType.PALLET_RECEIVING);

export const isBoxReceiveFlow = (receivingFlow?: ReceivingFlowType | null) =>
  Boolean(receivingFlow === ReceivingFlowType.BOX_RECEIVING);

export const isReturnReceiveFlow = (receivingFlow?: ReceivingFlowType | null) =>
  Boolean(receivingFlow === ReceivingFlowType.RETURN_RECEIVING);
