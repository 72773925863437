import React from "react";

import { PaddedDivider, SectionContainer, CardLabelText } from "facility-commons/components/emotion";

interface CardRowProps {
  hideDivider?: boolean;
  label: string | JSX.Element;
  value: string | JSX.Element;
}

export const CardRow: React.FC<CardRowProps> = ({ hideDivider, label, value }) => (
  <>
    {!hideDivider && <PaddedDivider />}
    <SectionContainer>
      <CardLabelText>{label}</CardLabelText>
      {value}
    </SectionContainer>
  </>
);
