import React from "react";
import { FormattedMessage } from "react-intl";
import { Text } from "@deliverr/ui";
import { CardColumn } from "facility-commons/components/emotion/cards";
import { commonWarehouseMessages } from "warehouse/common/messages";

interface BoxInformationSectionProps {
  boxInformation: string;
}

export const BoxInformationSection: React.FC<BoxInformationSectionProps> = ({ boxInformation }) => (
  <CardColumn>
    <Text bold>
      <FormattedMessage {...commonWarehouseMessages.poOrTracking} />
    </Text>
    {boxInformation}
  </CardColumn>
);
