import { useIntl } from "react-intl";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { NCRoutes } from "warehouse/ticket-center/new/non-compliance/base/routes";
import { useRouter } from "facility-commons/hooks";
import { DateErrorType, isValidDate } from "facility-commons";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useState } from "react";
import { isEmpty } from "lodash";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { expirationDateMessages } from "../../content";

export const useExpirationDate = () => {
  const [expirationDateErrorMsg, setExpirationDateErrorMsg] = useState<string>("");
  const [expirationDateInputValue, setExpirationDateInputValue] = useState<string>("");
  const { formatMessage } = useIntl();
  const { updateCurrentProduct, useInitializeFefoNCProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { errorResponse } = useCommonFlow();
  const { hideModal, showModal } = useWarehouseModal();

  useInitializeFefoNCProduct({ initializeDsku: true });

  const updateExpirationDate = (e) => {
    const newExpirationDate = e.target.value;
    setExpirationDateErrorMsg("");
    setExpirationDateInputValue(newExpirationDate);
  };

  const onSubmit = () => {
    const dateValidation = isValidDate(expirationDateInputValue);
    const dateParts = dateValidation?.dateParts;
    const dateError = dateValidation.error;
    const formattedDate = dateParts && `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;

    if (dateError) {
      errorResponse();
      setExpirationDateInputValue("");

      switch (dateError) {
        case DateErrorType.INVALID_DATE:
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.incorrectExpirationDateFormat));
        case DateErrorType.INVALID_MONTH:
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidMonth));
        case DateErrorType.INVALID_DAY:
          const lastDateOfMonth = dateValidation.lastDateOfMonth;
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidDay, { lastDateOfMonth }));
      }
    } else {
      updateCurrentProduct("reportedExpiry", formattedDate);
      proceed();
    }
  };

  const proceed = () => push(NCRoutes.BCL_UPLOAD);

  const onSkip = () => {
    showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
      onConfirm: () => {
        updateCurrentProduct("reportedExpiry", null);
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
        proceed();
      },
      onCancel: () => {
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
      },
      title: formatMessage(expirationDateMessages.skipExpiryInputConfirmationModalTitle),
    });
  };

  const disableButton = isEmpty(expirationDateInputValue) || !isEmpty(expirationDateErrorMsg);

  return {
    formatMessage,
    expirationDateInputValue,
    expirationDateErrorMsg,
    updateExpirationDate,
    proceed,
    onSubmit,
    onSkip,
    disableButton,
  };
};
