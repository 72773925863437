import React from "react";
import { useIntl } from "react-intl";
import { BoxReset, Text, Title } from "@deliverr/ui";
import { FacilityCard } from "facility-commons";
import { STORAGE_LOT_INFO_MESSAGE, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useReceivingSKUConfirmFlow } from "./useReceivingSKUConfirmFlow";

export const StorageReceivingSKUConfirmation: React.FC = () => {
  const { formatMessage } = useIntl();
  const { scanState } = useReceivingSKUConfirmFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <FacilityCard>
        <BoxReset marginBottom="S4">
          <Title as="h2" displayAs="h4" data-testid="product-card-title">
            {formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmReceiveTitle)}
          </Title>
        </BoxReset>
        <Text bold>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmUnitsLabel)}</Text>
        <BoxReset marginBottom="S4">
          <Text>{scanState.currentSkuDetails?.qty}</Text>
        </BoxReset>

        <Text bold>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmBoxesLabel)}</Text>
        <BoxReset marginBottom="S4">
          <Text>
            {formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmReceiveBoxes, {
              boxesQty: scanState.currentSkuDetails?.boxesQty,
              caseQty: scanState.currentSkuDetails?.receivedCaseQty,
            })}
          </Text>
        </BoxReset>

        {scanState.currentSkuDetails?.isLotTrackingEnabled && (
          <>
            <Text bold>{formatMessage(STORAGE_LOT_INFO_MESSAGE.lotNumber)}</Text>
            <BoxReset marginBottom="S4">
              <Text>{scanState.currentSkuDetails?.lotNumber}</Text>
            </BoxReset>
          </>
        )}

        {scanState.currentSkuDetails?.isFefoEnabled && (
          <>
            <Text bold>{formatMessage(STORAGE_LOT_INFO_MESSAGE.expirationDate)}</Text>
            <BoxReset marginBottom="S4">
              <Text>{scanState.currentSkuDetails?.expirationDate}</Text>
            </BoxReset>
          </>
        )}

        <Text bold>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmLPBarcodeLabel)}</Text>
        <BoxReset marginBottom="S4">
          <Text>{scanState.currentSkuDetails?.LP}</Text>
        </BoxReset>

        <Text bold>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuConfirmLocationLabel)}</Text>
        <BoxReset marginBottom="S4">
          <Text>{scanState.currentSkuDetails?.location}</Text>
        </BoxReset>
      </FacilityCard>
    </>
  );
};
