import { IconV2, LocalizedMessage, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { ReactNode, useState } from "react";

export interface DashboardDetailExpansionBlockProps {
  title: LocalizedMessage;
  shouldBoldTitle?: boolean;
  disableExpansion?: boolean;
  defaultOpen?: boolean;
  addDivider?: boolean;
  addBottomPadding?: boolean;
  children: ReactNode | ReactNode[];
}

const Title = styled(Text)<ThemeProps>(
  ({ theme }) => `
    flex: 1;
    color: ${theme.colors.NEUTRAL["500"]};
  `
);

const ClickableHeader = styled.button<ThemeProps<{ disableExpansion?: boolean }>>(
  ({ theme, disableExpansion }) => `
    display: flex;
    cursor: ${disableExpansion ? "initial" : "pointer"};
    width: 100%;
    align-items: center;
    padding-bottom: ${theme.spacing.S1};
    border: none;
    outline: none;

    &:focus {
      outline: none;
    }
  `
);

const StyledIconV2 = styled(IconV2)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["300"]};
`
);

const Container = styled.div<ThemeProps<{ addDivider: boolean; addBottomPadding: boolean }>>(
  ({ theme, addDivider, addBottomPadding }) => `
    padding-top: ${theme.spacing.S4};
    padding-bottom: ${addBottomPadding ? theme.spacing.S4 : 0};
    border-bottom: ${addDivider ? `${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]}` : "none"};
  `
);

export const DashboardDetailExpansionBlock: React.FC<DashboardDetailExpansionBlockProps> = ({
  title,
  shouldBoldTitle = false,
  defaultOpen = false,
  disableExpansion = false,
  addDivider = false,
  addBottomPadding = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen || disableExpansion);
  const toggleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <Container addDivider={addDivider} addBottomPadding={addBottomPadding}>
      <ClickableHeader onClick={toggleOpen} disableExpansion={disableExpansion} disabled={disableExpansion}>
        {/* render as span to avoid invalid DOM nesting error- <p> cannot appear as a descendant of <p> */}
        <Title as="span" bold={shouldBoldTitle}>
          {title}
        </Title>
        {!disableExpansion && <StyledIconV2 type={isOpen ? "angle-up" : "angle-down"} />}
      </ClickableHeader>
      {isOpen && children}
    </Container>
  );
};
