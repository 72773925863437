import React from "react";
import { useIntl, MessageDescriptor } from "react-intl";
import { ButtonProps } from "@deliverr/ui";
import { LoadingButton } from "../LoadingButton";

export const composeFacilityCommonsButton: (buttonMessageDescriptor: MessageDescriptor) => React.FC<ButtonProps> = (
  buttonMessageDescriptor: MessageDescriptor
) => ({ onClick, ...otherProps }: ButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <LoadingButton onClick={onClick} {...otherProps}>
      {formatMessage(buttonMessageDescriptor)}
    </LoadingButton>
  );
};
