import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";
import { SoundFx } from "facility-commons/common/sfx";
import { Space } from "@deliverr/ui/lib-esm/shared";

interface ConsolidationUnexpectedSkuModalProps {
  consolidationLabel: string;
  onExit: () => void;
  onConfirm: () => void;
}

export const ConsolidationUnexpectedSku: React.FC<ConsolidationUnexpectedSkuModalProps> = ({
  consolidationLabel,
  onConfirm,
  onExit,
}) => {
  const { formatMessage } = useIntl();

  const title = formatMessage(modalsText.unexpectedSkuTitle);
  const text = (
    <FormattedMessage
      id="warehouse.receiving.consolidationUnexpectedSkuText"
      defaultMessage="Confirm that the Consolidation label you are receiving against is <bold>{consolidationLabel}</bold> before proceeding"
      values={{
        bold: (val) => <b>{val}</b>,
        consolidationLabel,
      }}
    />
  );

  return (
    <ModalTemplate buttonLayout="row" title={title} text={text} iconProps={dangerModalIconXl} soundFx={SoundFx.INFO}>
      <Grid fullWidth columns={"1fr 1fr"} gap={Space.S2}>
        <Button secondary onClick={onExit}>
          {formatMessage(modalsText.exit)}
        </Button>
        <Button onClick={onConfirm}>{formatMessage(modalsText.confirm)}</Button>
      </Grid>
    </ModalTemplate>
  );
};
