import { FlowCardType } from "facility-commons/flow/types";
import { SoundFx } from "facility-commons/common/sfx";
import {
  UnknownInputCardProps,
  ResponseCardProps,
  ErrorTextCardProps,
  UnknownErrorCardProps,
} from "facility-commons/flow/cards";
import { createFlowCard } from "./createFlowCard";

export const createUnknownInputCard = createFlowCard<UnknownInputCardProps>(FlowCardType.UNKNOWN_INPUT, SoundFx.ERROR);
export const createResponseCard = createFlowCard<ResponseCardProps>(FlowCardType.RESPONSE, SoundFx.ERROR);
export const createUnknownErrorCard = createFlowCard<UnknownErrorCardProps>(FlowCardType.UNKNOWN_ERROR, SoundFx.ERROR);
export const createErrorTextCard = createFlowCard<ErrorTextCardProps>(FlowCardType.ERROR_TEXT, SoundFx.ERROR);
