import {
  ProductMinimal,
  QcReturnItemMinimal,
  ReturnItemMinimal,
  ReturnStatus,
  ReturnType,
} from "@deliverr/returns-client";

import { ReturnReceiveStateKey } from "./ReturnReceiveStateKey";
import { atom } from "recoil";

export interface ReturnReceiveReturnOrderState {
  trackingCode: string;
  orderId: number | null;
  orderStatus: ReturnStatus | null;
  returnType: ReturnType | null;
  sellerId: string;
  expectedItems: ReturnItemMinimal[];
  expectedItemsProductDetails: { [dsku: string]: ProductMinimal };
  inspectedItems: QcReturnItemMinimal[];
  orderInspectionNotes: string;
}

const RETURN_RECEIVE_RETURN_ORDER_INITIAL_STATE: ReturnReceiveReturnOrderState = {
  trackingCode: "",
  orderId: null,
  orderStatus: null,
  returnType: null,
  sellerId: "",
  expectedItems: [],
  expectedItemsProductDetails: {},
  inspectedItems: [],
  orderInspectionNotes: "",
};
export const returnReceiveReturnOrderStateAtom = atom<ReturnReceiveReturnOrderState>({
  key: ReturnReceiveStateKey.RETURN_ORDER_STATE,
  default: RETURN_RECEIVE_RETURN_ORDER_INITIAL_STATE,
});
