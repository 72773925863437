import React from "react";
import { ThemeProps, Text } from "@deliverr/ui";
import styled from "@emotion/styled";

const FacilityIdContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: grid;
  place-items: center;
  background: ${theme.colors.YELLOW[400]};
  color: ${theme.colors.NEUTRAL["00"]};
  height: fit-content;
  padding-top: ${theme.spacing.S1};
  padding-bottom: ${theme.spacing.S1};
`
);

interface FacilityHeaderProps {
  warehouseId: string;
}

export const FacilityIdHeader: React.FC<FacilityHeaderProps> = ({ warehouseId }) => {
  return (
    <FacilityIdContainer>
      <Text bold size="caption">
        {warehouseId}
      </Text>
    </FacilityIdContainer>
  );
};
