import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { SoundFx } from "facility-commons/common/sfx";
import { STORAGE_ARRIVAL_SCAN_MESSAGES } from "warehouse/common/messages/storage/StorageArrivalScanMessages";
import { FACILITY_COMMONS_BUTTON_LABELS, dangerModalIconLg, ModalTemplate } from "facility-commons";
import { WarehouseModalProps, WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const StoragePromptModal: React.FC<WarehouseModalProps> = (modal) => {
  const { hideModal } = useWarehouseModal();

  return (
    <ModalTemplate
      soundFx={modal.soundFx ?? SoundFx.ERROR}
      title={modal.title ?? <FormattedMessage {...STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedTitle} />}
      text={modal.text ?? <FormattedMessage {...STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription} />}
      iconProps={modal.iconProps ?? dangerModalIconLg}
      buttonLayout="column"
    >
      <Button block onClick={() => hideModal(WarehouseModal.STORAGE_PROMPT_MODAL)} type="button">
        <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.backButton} />
      </Button>
    </ModalTemplate>
  );
};
