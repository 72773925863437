import { BoxReset } from "@deliverr/ui";
import { DateFormat } from "facility-commons/utils";
import { format } from "date-fns";
import React from "react";
import { useIntl } from "react-intl";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { BaseDashboardDetailCard, BaseDashboardDetailCardProps } from "./BaseDashboardDetailCard";

export interface DashboardDetailCardProps extends BaseDashboardDetailCardProps {
  createdAt: Date;
  placeholder?: JSX.Element;
}

export const DashboardDetailCard: React.FC<DashboardDetailCardProps> = ({
  children,
  createdAt,
  actions,
  placeholder,
}) => {
  const { formatMessage } = useIntl();

  const createHeader = () => {
    const config = {
      date: format(createdAt, DateFormat.ShortMonthDayTime),
    };

    return formatMessage(
      {
        id: "facilityCommons.dashboardDetailCard.header.createdAt",
        defaultMessage: "Created on {date}",
      },
      config
    );
  };

  return placeholder ? (
    <BoxReset height="100%">
      <FacilityCard>{placeholder}</FacilityCard>
    </BoxReset>
  ) : (
    <BaseDashboardDetailCard headerLabel={createHeader()} actions={actions}>
      {children}
    </BaseDashboardDetailCard>
  );
};
