import { LocalizedMessage, ThemeProps, Appearance, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

const TabButton = styled.button<ThemeProps<{ isActive: boolean }>>(
  ({ isActive, theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex: 1;
    border-bottom-width: 3px;
    border-bottom-color: ${isActive ? theme.colors.BLUE["300"] : theme.colors.NEUTRAL["00"]};
  `
);

const CountChip = styled(Text)<ThemeProps<{ isActive: boolean }>>(
  ({ theme, isActive }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    font-size: ${theme.font.size.F1};
    background-color: ${isActive ? theme.colors.RED["300"] : theme.colors.NEUTRAL["200"]};
    color: ${theme.colors.NEUTRAL["00"]};
    font-weight: ${theme.font.weight.BOLD};
    border-radius: ${theme.border.width.B2};
    margin-left: ${theme.spacing.S1};
  `
);

export interface TabConfig<TabType extends string> {
  label: LocalizedMessage;
  value: TabType;
  count?: number;
}

export interface TabProps<TabType extends string> extends TabConfig<TabType> {
  isActive: boolean;
  onClick: (value: TabType) => void;
}

export function Tab<TabType extends string>({ label, value, count, isActive, onClick }: TabProps<TabType>) {
  return (
    <TabButton isActive={isActive} aria-selected={isActive} onClick={() => onClick(value)} key={value}>
      <Text appearance={isActive ? undefined : Appearance.INFO}>{label}</Text>
      {count !== undefined && <CountChip isActive={isActive}>{count}</CountChip>}
    </TabButton>
  );
}
