import { Appearance, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  DashboardDetailExpansionBlock,
  DashboardDetailExpansionBlockProps,
} from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { OVERVIEW_CARD_LABELS } from "./OverviewCard.labels";

const WordBreakText = styled(Text)`
  word-break: break-word;
`;

export interface TicketCenterOverviewNotesSectionProps extends Pick<DashboardDetailExpansionBlockProps, "addDivider"> {
  notes?: string;
}

export const TicketCenterOverviewNotesSection: React.FC<TicketCenterOverviewNotesSectionProps> = ({
  notes,
  addDivider,
}) => {
  return !notes ? null : (
    <DashboardDetailExpansionBlock
      addBottomPadding
      addDivider={addDivider}
      disableExpansion
      title={<FormattedMessage {...OVERVIEW_CARD_LABELS.sectionHeaders.additionalDetails} />}
    >
      <WordBreakText appearance={Appearance.INFO}>{notes}</WordBreakText>
    </DashboardDetailExpansionBlock>
  );
};
