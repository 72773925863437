import styled from "@emotion/styled";
import { Stack } from "@deliverr/ui";
import React from "react";
import { FlowButtonsStateProps } from "./types";
import { useRecoilValue } from "recoil";
import { timerState } from "./flowState";

const Container = styled(Stack)`
  width: 100%;
  margin: 0;
`;

// disable buttons during transitions to prevent unexpected sequences of cards, missing state, etc
export const FlowVisibleButtons: React.FC<FlowButtonsStateProps> = ({ visibleButtons, buttonMap }) => {
  const { visible: isTransitioning } = useRecoilValue(timerState);

  return (
    <Container center>
      {visibleButtons.map((buttonType) => {
        const FlowButton = buttonMap[buttonType];
        return <FlowButton key={buttonType} disabled={isTransitioning} />;
      })}
    </Container>
  );
};
