import { NonComplianceCaseType, TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";

export const mockUseTicketImplementation = {
  showDeleteModal: () => {},
  handleDeleteCase: () => new Promise<void>((resolve) => setTimeout(resolve, 5)),
  closeOrReopenCase: () => new Promise<void>((resolve) => setTimeout(resolve, 5)),
  isError: false,
  isLoading: false,
  overviewData: {
    asnId: 1,
    createdAt: new Date(),
    id: 1,
    barcode: "asdasd",
    status: TicketCenterNonComplianceCaseStatus.TODO,
    pictures: ["sdfsdf"],
    type: NonComplianceCaseType.BARCODE_NOT_IN_SYSTEM,
  },
  receivingInfoData: {
    asnId: 1,
    createdAt: new Date(),
    id: 1,
    barcode: "asdasd",
    status: TicketCenterNonComplianceCaseStatus.TODO,
    pictures: ["sdfsdf"],
    type: NonComplianceCaseType.BARCODE_NOT_IN_SYSTEM,
    supportContacted: false,
  },
  handleThumbnailClick: () => {},
  isClosedTicket: false,
  hasCompletedDate: true,
  shouldDisplayOpenCloseButton: true,
  shouldDisplayDeleteButton: false,
};

export const mockUseCreatedTicketImplementation = {
  ...mockUseTicketImplementation,
  shouldDisplayOpenCloseButton: false,
  shouldDisplayDeleteButton: true,
  overviewData: {
    ...mockUseTicketImplementation.overviewData,
    status: TicketCenterNonComplianceCaseStatus.CREATED,
  },
};
