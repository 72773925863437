import React from "react";
import { Button } from "@deliverr/ui";
import { dangerModalIconXl, ModalTemplate } from "facility-commons";
import { modalsText } from "../modalsText";
import { useIntl } from "react-intl";

export interface InvalidLocationProps {
  onConfirm: () => void;
}

export const InvalidLocation: React.FC<InvalidLocationProps> = ({ onConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(modalsText.invalidLoc)}
      text={formatMessage(modalsText.stowDiffExp)}
      iconProps={dangerModalIconXl}
    >
      <Button block onClick={onConfirm} data-testid="invalid-loc-modal-confirm">
        {formatMessage(modalsText.tryAnotherLoc)}
      </Button>
    </ModalTemplate>
  );
};
