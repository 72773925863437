import React from "react";
import { Button, ButtonProps } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

import { useProceedToReviewButton } from "./useProceedToReviewButton";
import { useRecoilValue } from "recoil";
import { isMultiSkuState } from "warehouse/receiving/ReceivingState";

export const ProceedToReviewButton: React.FC<ButtonProps> = (props) => {
  const { receivedSkus, proceedToReview } = useProceedToReviewButton();
  const isMultiSku = useRecoilValue(isMultiSkuState);

  return receivedSkus.length && isMultiSku ? (
    <Button block onClick={proceedToReview} {...props}>
      <FormattedMessage id="warehouse.receiving.closeBox" defaultMessage="Close box" />
    </Button>
  ) : null;
};
