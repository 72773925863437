import React from "react";
import { ErrorTextCard } from "./ErrorTextCard";

export interface UnknownErrorCardProps {
  value: string;
  newest?: boolean;
}

export const UnknownErrorCard: React.FC<UnknownErrorCardProps> = ({ value, newest }) => (
  <ErrorTextCard
    value={value}
    title="Unknown Error"
    message="An unknown error has occurred. Please attempt to re-scan."
    newest={newest}
  />
);
