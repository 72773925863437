import {
  createReturnReceiveBarcodeScan,
  createReturnReceiveLocationScan,
  createReturnReceiveLotExpiryInput,
  createReturnReceiveRtmBoxScan,
  createReturnReceiveSortToDispositionValidLocation,
  createReturnReceiveTrackingCodeScan,
} from "../components/returnReceiveCardCreators";
import { ReturnStatus, Disposition } from "@deliverr/returns-client";
import { returnReceiveReturnOrderStateAtom } from "../state";
import { useRecoilValue } from "recoil";
import { useReturnReceiveFlow } from "../components/useReturnReceiveFlow";

export const useInspectionRouting = () => {
  const { nextFlowCard } = useReturnReceiveFlow();
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const { orderStatus } = returnOrderData;

  const routeAccordingToDisposition = (disposition: Disposition, isLotOrExpiryEnabled: boolean) => {
    if (disposition === Disposition.RESTOCK) {
      if (isLotOrExpiryEnabled) {
        return nextFlowCard(createReturnReceiveLotExpiryInput({}));
      }
      return nextFlowCard(createReturnReceiveLocationScan({}));
    } else if (disposition === Disposition.RTV) {
      return nextFlowCard(createReturnReceiveRtmBoxScan({}));
    } else {
      return nextFlowCard(createReturnReceiveSortToDispositionValidLocation({}));
    }
  };

  const inspectNextItemOrStartNextOrder = () => {
    if (orderStatus === ReturnStatus.PROCESSED) {
      nextFlowCard(createReturnReceiveTrackingCodeScan({}));
    } else {
      nextFlowCard(createReturnReceiveBarcodeScan({}));
    }
  };

  return { routeAccordingToDisposition, inspectNextItemOrStartNextOrder };
};
