import { Button, Grid, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ModalTemplate, questionModalIcon } from "facility-commons";
import { SoundFx } from "facility-commons/common/sfx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useStorageConfirmationModal } from "./useStorageConfirmationModal";
import { WarehouseModalProps } from "warehouse/modal";

const ButtonContainer = styled(Grid)<ThemeProps>`
  width: 100%;
`;

export const StoragePOCompletionModal: React.FC<WarehouseModalProps> = (modal) => {
  const { handleCancel, submitLoading, cancelLoading, handleSuccess } = useStorageConfirmationModal(modal);

  return (
    <ModalTemplate
      soundFx={modal.soundFx ?? SoundFx.SUCCESS}
      title={modal.title ?? <FormattedMessage {...STORAGE_RECEIVING_MESSAGES.markAsCompletedTitle} />}
      text={modal.text ?? <FormattedMessage {...STORAGE_RECEIVING_MESSAGES.markAsCompletedText} />}
      iconProps={modal.iconProps ?? questionModalIcon}
      buttonLayout="column"
    >
      <ButtonContainer gap="S3">
        <Button block onClick={handleCancel} type="button" loading={submitLoading} disabled={cancelLoading}>
          {<FormattedMessage {...STORAGE_RECEIVING_MESSAGES.scanAnotherSKU} />}
        </Button>
        <Button block secondary onClick={handleSuccess} type="button" loading={cancelLoading} disabled={submitLoading}>
          {<FormattedMessage {...STORAGE_RECEIVING_MESSAGES.markAsCompleted} />}
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
