import styled from "@emotion/styled";
import React from "react";
import { FlowCard, FlowCardProps } from "facility-commons/flow/cards";
import { FlowCardIcon } from "facility-commons/flow/cards/FlowCardIcon";
import { Input } from "@deliverr/ui";

interface Props extends FlowCardProps {
  topValue: string;
  bottomValue: string;
}

const LinkedContainer = styled.div`
  position: relative;
  & > *:first-child {
    margin-bottom: 10px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 35px;
  left: -18px;
`;

export const LinkedCard: React.FC<Props> = ({ topValue, bottomValue, ...flowCardProps }) => {
  return (
    <FlowCard {...flowCardProps}>
      <LinkedContainer>
        <Input value={topValue} disabled />
        <Input value={bottomValue} disabled />
        <IconContainer>
          <FlowCardIcon icon="link" accentColor="BLUE" />
        </IconContainer>
      </LinkedContainer>
    </FlowCard>
  );
};
