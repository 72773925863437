import { S3FileHandlerClient } from "@deliverr/commons-clients";
import { UploadItemCustomProps } from "facility-commons/components";
import { useUploadItem } from "facility-commons/components/UploadItem/useUploadItem";
import { useRecoilValue } from "recoil";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export function useNonComplianceUploadItem(
  { name, onSuccessfulUpload }: Pick<UploadItemCustomProps, "name" | "onSuccessfulUpload">,
  fileHandlerClient: S3FileHandlerClient
) {
  const { uploadedImages } = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();

  const onSuccessfulFileRetrieval = (key: string, imgUrl: string) => {
    updateCurrentProduct("uploadedImages", { ...uploadedImages, [key!]: imgUrl });
  };

  const { value, uploading, uploadProgress, onInputFileChange } = useUploadItem(
    { name, onSuccessfulUpload, onSuccessfulFileRetrieval },
    fileHandlerClient
  );

  return {
    value,
    uploading,
    uploadProgress,
    onInputFileChange,
  };
}
