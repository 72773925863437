import React from "react";
import { useIntl } from "react-intl";
import { BoxReset, Title } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { TextInputCard } from "facility-commons/flow";
import { DateInput } from "facility-commons/components/Inputs";
import { DateFormat } from "facility-commons/utils";
import { STORAGE_LOT_INFO_MESSAGE, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useStorageReceivingLotInfo } from "./useStorageReceivingLotInfo";

export const StorageReceivingLotInfo = () => {
  const {
    lotNumber,
    expirationDate,
    isFefoEnabled,
    isLotTrackingEnabled,
    onLotChange,
    onExpirationDateChange,
  } = useStorageReceivingLotInfo();
  const { formatMessage } = useIntl();

  return (
    <>
      <StorageReceivingPODetails />
      <FacilityCard as="section">
        <BoxReset marginBottom="S2">
          <Title as="h2" displayAs="h4">
            {formatMessage(STORAGE_RECEIVING_MESSAGES.lotInfoTitle)}
          </Title>
        </BoxReset>

        {isLotTrackingEnabled && (
          <TextInputCard
            newest
            onChange={onLotChange}
            placeholder={formatMessage(STORAGE_LOT_INFO_MESSAGE.lotNumber)}
            value={lotNumber ?? ""}
            embedded
            data-testid="lot-number-input"
          />
        )}

        {isFefoEnabled && (
          <DateInput
            value={expirationDate ?? ""}
            mask={DateFormat.LongDate}
            onChange={onExpirationDateChange}
            placeholderText={formatMessage(STORAGE_LOT_INFO_MESSAGE.expirationDate)}
            helpText={formatMessage(STORAGE_LOT_INFO_MESSAGE.expirationDateInputHelpText)}
          />
        )}
      </FacilityCard>
    </>
  );
};
