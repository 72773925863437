import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

interface ReturnReceivePrintBarcodeErrorModalProps {
  errorString: string;
}

export const ReturnReceivePrintBarcodeErrorModal: React.FC<ReturnReceivePrintBarcodeErrorModalProps> = ({
  errorString,
}) => {
  const { formatMessage } = useIntl();
  const { hideModal } = useWarehouseModal();

  const title = formatMessage(modalsText.returnReceivePrintBarcodeErrorTitle);

  return (
    <ModalTemplate title={title} text={errorString} iconProps={dangerModalIconLg} soundFx={SoundFx.ERROR}>
      <Button
        block
        onClick={() => {
          hideModal(WarehouseModal.RETURN_RECEIVE_RTM_PRINT_BARCODE_ERROR);
        }}
        secondary
      >
        {formatMessage(modalsText.back)}
      </Button>
    </ModalTemplate>
  );
};
