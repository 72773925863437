import { InspectionNotesType } from "../../types/InspectionNotesType";
import { useIntl } from "react-intl";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useInspectionNotesButton = (noteType: InspectionNotesType) => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const addReturnOrderNote = formatMessage(commonReturnReceiveMessage.addReturnOrderNote);
  const addItemInspectionNote = formatMessage(commonReturnReceiveMessage.addItemInspectionNote);
  const buttonText = noteType === InspectionNotesType.RETURN_ORDER ? addReturnOrderNote : addItemInspectionNote;

  const showNotesModal = () => {
    showModal(WarehouseModal.RETURN_RECEIVE_INSPECTION_NOTES, { noteType });
  };

  return { buttonText, showNotesModal };
};
