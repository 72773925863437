import React from "react";
import { ProductCard } from "./ProductCard";
import { CurrentSkuPill, PositionedPillContainer } from "../../pills";
import { useProductCardExpanded } from "./useProductCardExpanded";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

export const ProductCardExpanded = () => {
  const {
    lotNumber,
    expirationDate,
    asnId,
    isReceiveWithoutCdsku,
    barcode,
    description,
    dsku,
    cdsku,
    isBoxReceive,
  } = useProductCardExpanded();

  return (
    <FacilityCard as="section">
      <ProductCard
        embedded
        description={description}
        dsku={dsku}
        barcode={barcode}
        cdsku={cdsku}
        lotNumber={lotNumber}
        expirationDate={expirationDate}
        asnId={asnId?.toString()}
      >
        {!isReceiveWithoutCdsku && isBoxReceive && (
          <PositionedPillContainer>
            <CurrentSkuPill />
          </PositionedPillContainer>
        )}
      </ProductCard>
    </FacilityCard>
  );
};
