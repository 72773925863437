import * as React from "react";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { useTrackingCodeScan } from "./useTrackingCodeScan";

export const TrackingCodeScan: React.FC = () => {
  const {
    trackingCode,
    isSubmitting,
    trackingCodeError,
    messages,
    handleChange,
    handleTrackingCodeSubmit,
  } = useTrackingCodeScan();
  return (
    <StackedInputCard
      illustration={<ScanBox />}
      illustrationTitle={messages.illustrationTitle}
      message={messages.message}
      newest
      onChange={handleChange}
      autoFocus
      onSubmit={handleTrackingCodeSubmit}
      placeholder={messages.placeholder}
      loading={isSubmitting}
      title={messages.title}
      value={trackingCode}
      errorMessage={trackingCodeError}
    />
  );
};
