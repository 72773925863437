import React from "react";
import { useReceivingImageUploadCard } from "./useReceivingImageUploadCard";
import { FacilityCard, TooltipWrapper, UploadItem } from "facility-commons";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormSubTitle,
  WarehouseFormTooltip,
} from "facility-commons/components/emotion";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "@deliverr/ui";
import { FormProvider } from "react-hook-form";

export const ReceivingImageUploadCard: React.FC = () => {
  const {
    messages,
    imagesToUpload,
    disableButton,
    errors,
    formMethods,
    register,
    submitImages,
  } = useReceivingImageUploadCard();
  return (
    <FacilityCard as="section">
      <FormProvider {...formMethods}>
        <WarehouseForm onSubmit={submitImages}>
          <WarehouseFormSubTitle as="h3" displayAs="h4">
            {messages.uploadImages}
          </WarehouseFormSubTitle>
          <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
            {imagesToUpload.map((imageToUpload, i) => (
              <TooltipWrapper key={`${imageToUpload}-${i}`}>
                <UploadItem
                  {...register(imageToUpload, {
                    required: messages.required,
                  })}
                  id={imageToUpload}
                  labelText={messages[imageToUpload]}
                  isMandatory
                />
                <WarehouseFormTooltip active={!!errors[imageToUpload]}>
                  <ErrorMessage errors={errors} name={imageToUpload} />
                </WarehouseFormTooltip>
              </TooltipWrapper>
            ))}
          </WarehouseFormImageGrid>
          <Button block disabled={disableButton} type="submit">
            {messages.confirm}
          </Button>
        </WarehouseForm>
      </FormProvider>
    </FacilityCard>
  );
};
