import React from "react";
import { Box, Divider, Text } from "@deliverr/ui";
import { FacilityCard } from "facility-commons";
import { StyledStack } from "../StyledStack";
import { usePendingItemsCard } from "./usePendingItemsCard";
import { useIntl } from "react-intl";
import { pendingItemsCardLabel } from "./pendingItemsCardLabel";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PendingItemsCard = () => {
  const { pendingItemsMap } = usePendingItemsCard();
  const { formatMessage } = useIntl();

  return (
    <FacilityCard as="section" style={{ height: "100%" }}>
      <StyledStack gap={"S3"}>
        {pendingItemsMap &&
          Object.entries(pendingItemsMap).map(([key, { qty, msku }], index, array) => (
            <>
              <StyledContainer>
                <Box>
                  <Text bold>{key}</Text>
                  <Text>{msku}</Text>
                </Box>
                <Text>{formatMessage(pendingItemsCardLabel.qtyText, { qty })}</Text>
              </StyledContainer>
              {index !== array.length - 1 && <Divider />}
            </>
          ))}
      </StyledStack>
    </FacilityCard>
  );
};
