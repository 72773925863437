import { useRecoilValue } from "recoil";
import { log } from "facility-commons/utils";
import testLabelZpls from "./testLabels.json";
import { insertLabelOffset } from "./insertLabelOffset";
import { insertLabelWidth } from "./insertLabelWidth";
import { connectedPrinterState, dpiState, horizontalOffsetState } from "facility-commons/printer";
import { DeliverrError } from "@deliverr/commons-objects";
import { FacilityErrorCode } from "facility-commons/base";

export function usePrintZpl() {
  const connectedPrinter = useRecoilValue(connectedPrinterState);
  const dpi = useRecoilValue(dpiState);
  const horizontalOffset = useRecoilValue(horizontalOffsetState);

  function applyZplLabelAdjustments(zpl: string): string {
    const zplWithOffset = insertLabelOffset(zpl, horizontalOffset);
    return insertLabelWidth(zplWithOffset, dpi);
  }

  async function printZpl(zpl: string): Promise<void> {
    const ctx = { fn: "printZpl", dpi, horizontalOffset };

    return new Promise((resolve) => {
      log({ ...ctx, connectedPrinter }, "connected printer");
      if (!connectedPrinter) {
        log(ctx, "no connected printer, showing printer warning");
        throw new DeliverrError({ code: FacilityErrorCode.NO_PRINTER_CONNECTED });
      } else {
        log(ctx, "printer connected, printing label");
        connectedPrinter.printRawString(applyZplLabelAdjustments(zpl), {}, () => resolve());
      }
    });
  }

  // prints a test label for printer connection verification and label settings testing
  async function printTestLabelZpl(): Promise<void> {
    log({ fn: "printTestLabelZpl" }, "printing test label");
    const testLabelZpl = testLabelZpls[dpi];
    return printZpl(testLabelZpl);
  }

  return {
    printZpl,
    printTestLabelZpl,
  };
}
