import { defineMessages } from "react-intl";

export const emailMessages = defineMessages({
  emailHelpText: {
    id: "warehouse.nonCompliance.emailHelpText",
    defaultMessage: "If blank, updates will automatically be sent to {defaultEmail}.",
  },
  emailTitle: {
    id: "warehouse.nonCompliance.emailTitle",
    defaultMessage: "Email for ticket updates",
  },
});
