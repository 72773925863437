import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { sum, sumBy } from "lodash";
import { RSOutboundsFlowButtonType } from "warehouse/common/flow/types/RSOutboundsFlowButtonType";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { log, logStart, setProp } from "facility-commons/utils";
import { shipmentSummaryCardLabels } from "./shipmentSummaryCardLabels";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { fetchWholesaleShipmentDetails } from "./fetchWholesaleShipmentDetails";
import { createOutboundsPendingItemsCard } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";

const RETAILER = "retailer";

export const useShipmentSummaryCard = () => {
  const { shipmentDetails, currentShipmentId } = useRecoilValue(rsOutboundsStateAtom);
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { resetNotifications } = useCommonFlow();
  const { hideAllFlowButtons, showFlowButtons } = useWarehouseFlow();
  const { formatMessage } = useIntl();
  const { productClient } = useClientsWithAuth();
  const updateState = useSetRecoilState(rsOutboundsStateAtom);
  const { transition } = useWarehouseFlow();

  useLifecycles(
    () => {
      setWarehouseAppState(
        setProp(
          "pageSubtitle",
          formatMessage(shipmentSummaryCardLabels.shipmentTitle, { shipmentId: currentShipmentId })
        )
      );

      hideAllFlowButtons();
      const timeoutId = setTimeout(() => {
        resetNotifications();
        clearTimeout(timeoutId);
      }, 2000);
      showFlowButtons(
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_CREATE_PACKAGE,
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_REPRINT_LABELS,
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_SCAN_NEXT_SHIPMENT
      );
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const { totalItems, numOfPackagesCreated, totalPendingItems } = useRecoilValue(shipmentItemsSelector);
  const showPendingItemsArrow = totalPendingItems > 0;
  const shipmentType = shipmentDetails?.shipmentType;
  const shipmentMethod = shipmentDetails?.carrierInfo?.shippingMethod;
  const retailer = shipmentDetails?.externalAttributes?.find((attribute) => attribute.attributeName === RETAILER)
    ?.attributeValue;

  useEffect(() => {
    const fetchDetails = async () => {
      const shipmentItems = shipmentDetails?.shipmentItems ?? [];
      const dskuArray = shipmentItems.map((item) => item.dsku).filter(Boolean);
      const {
        shipmentItemsDetails,
        unifiedProductDetails,
        mapParentDskuToCasePackDsku,
      } = await fetchWholesaleShipmentDetails(dskuArray, productClient);
      updateState((state) => ({
        ...state,
        shipmentItemsDetails,
        unifiedProductDetails,
        mapParentDskuToCasePackDsku,
      }));
    };
    fetchDetails();
  }, [productClient, shipmentDetails, updateState]);

  const viewPendingItems = () => {
    transition({ card: createOutboundsPendingItemsCard({}) });
  };

  return {
    totalItems,
    numOfPackagesCreated,
    pendingItems: totalPendingItems,
    shipmentId: currentShipmentId,
    showPendingItemsArrow,
    shipmentType,
    shipmentMethod,
    retailer,
    viewPendingItems,
  };
};
