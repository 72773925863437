import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid, LocalizedMessage } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface UnexpectedSkusModalProps {
  errorMsg: LocalizedMessage;
  onContinue: () => void;
}

export const UnexpectedSkus: React.FC<UnexpectedSkusModalProps> = ({ errorMsg, onContinue }) => {
  const { formatMessage } = useIntl();

  const title = formatMessage(modalsText.unexpectedSkuTitle);

  return (
    <ModalTemplate buttonLayout="row" title={title} text={errorMsg} iconProps={dangerModalIconXl}>
      <Grid>
        <Button block onClick={onContinue}>
          {formatMessage(modalsText.receiveAnotherSku)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
