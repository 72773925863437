import React from "react";
import { Appearance, NotificationProps, Text, ThemeProps } from "@deliverr/ui";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "@emotion/styled";
import { getExpirationDateFormattedString } from "warehouse/receiving/utils/getExpirationDateFormattedString";
import { format } from "date-fns";
import { DateFormat } from "facility-commons/utils";
import { ReceivingFlowType } from "../ReceivingState/Types";

interface CommitSuccessNotificationProps {
  barcode: string;
  dsku: string;
  quantity: number;
  loc: string;
  completeMultiSku?: boolean;
  boxCount?: number;
  expirationDate?: string;
  lotNumber?: string;
}

const StyledNotificationContainer = styled.div<ThemeProps>`
  font-size: ${({ theme }) => theme.font.size.F2};
`;

const StyledText = styled(Text)<ThemeProps>`
  margin-left: ${({ theme }) => theme.spacing.S1};
`;

export const createCommitSuccessNotification = (props: CommitSuccessNotificationProps): NotificationProps => {
  const Notification = ({
    dsku,
    barcode,
    quantity,
    loc,
    completeMultiSku,
    boxCount,
    expirationDate,
    lotNumber,
  }: CommitSuccessNotificationProps) => {
    const { formatMessage } = useIntl();
    const labelMaps = {
      [formatMessage({ id: "warehouse.receiving.notifications.sku", defaultMessage: "SKU" })]: dsku,
      [formatMessage({ id: "warehouse.receiving.notifications.barcode", defaultMessage: "Barcode" })]: barcode,
      [formatMessage({ id: "warehouse.receiving.notifications.quantity", defaultMessage: "Qty" })]: boxCount ? (
        <FormattedMessage
          id="warehouse.receiving.notifications.quantityWithBoxes"
          defaultMessage="{boxCount} boxes, {totalUnits} units total"
          values={{ boxCount, totalUnits: quantity }}
        />
      ) : (
        <FormattedMessage
          id="warehouse.receiving.notifications.unitsOnly"
          defaultMessage="{totalUnits} units"
          values={{ totalUnits: quantity }}
        />
      ),
      [formatMessage({ id: "warehouse.receiving.notifications.location", defaultMessage: "Loc" })]: loc,
    };

    if (expirationDate) {
      labelMaps[
        formatMessage({
          id: "warehouse.receiving.notifications.expirationDate",
          defaultMessage: "Exp",
        })
      ] = format(new Date(getExpirationDateFormattedString(expirationDate)), DateFormat.ShortMonthDayYear);
    }

    if (lotNumber) {
      labelMaps[
        formatMessage({
          id: "warehouse.receiving.notifications.lotNumber",
          defaultMessage: "LOT",
        })
      ] = lotNumber;
    }

    return (
      <StyledNotificationContainer data-testid="commit-success-notification-container">
        {completeMultiSku ? (
          <Text bold size="label" data-testid="commit-success-successfulMultiSku">
            <FormattedMessage
              id="warehouse.receiving.notifications.successfulMultiSku"
              defaultMessage="Successfully received multi-SKU box."
            />
          </Text>
        ) : null}
        {Object.entries(labelMaps).map(([label, val]) => (
          <div key={label}>
            <Text as="span" bold size="label">
              {label}:
            </Text>
            <StyledText as="span" size="label" data-testid={`commit-success-${label}`}>
              {val}
            </StyledText>
          </div>
        ))}
      </StyledNotificationContainer>
    );
  };

  return { appearance: Appearance.SUCCESS, children: <Notification {...props} /> };
};

export const createBulkBoxScanNotification = (bulkBoxCdsku: string): NotificationProps => ({
  appearance: Appearance.SUCCESS,
  children: (
    <StyledNotificationContainer>
      <Text as="span" size="label">
        <FormattedMessage id="warehouse.receiving.notifications.box" defaultMessage="Box" />:
      </Text>{" "}
      <Text as="span" size="label" data-testid="bulk-box-scan-notification">
        {bulkBoxCdsku}{" "}
        <Text as="span" size="label" bold>
          <FormattedMessage id="warehouse.receiving.notifications.scanned" defaultMessage="scanned" />
        </Text>
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createBulkBoxScanCompletionNotification = ({
  boxCount,
  totalUnits,
  received,
}: {
  boxCount: number;
  totalUnits: number;
  received: boolean;
}): NotificationProps => ({
  appearance: Appearance.SUCCESS,
  children: (
    <StyledNotificationContainer>
      <Text bold size="label" data-testid="bulk-box-scan-complete">
        {received ? (
          <FormattedMessage
            id="warehouse.receiving.notifications.successfulReceiveBulkReceive"
            defaultMessage="Successfully received identical boxes."
          />
        ) : (
          <FormattedMessage
            id="warehouse.receiving.notifications.successfulScanBulkReceive"
            defaultMessage="Successfully scanned identical boxes."
          />
        )}
      </Text>
      <Text size="label">
        <Text as="span" bold size="label">
          <FormattedMessage id="warehouse.receiving.notifications.quantity" defaultMessage="Qty" />:
        </Text>{" "}
        <Text as="span" size="label" data-testid="bulk-box-scan-complete-units">
          {boxCount ? (
            <FormattedMessage
              id="warehouse.receiving.notifications.quantityWithBoxes"
              defaultMessage="{boxCount} boxes, {totalUnits} units total"
              values={{ boxCount, totalUnits }}
            />
          ) : (
            <FormattedMessage
              id="warehouse.receiving.notifications.unitsOnly"
              defaultMessage="{totalUnits} units"
              values={{ totalUnits }}
            />
          )}
        </Text>
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createUnexpectedSkuNotification = (): NotificationProps => ({
  appearance: Appearance.DEFAULT,
  children: (
    <StyledNotificationContainer>
      <Text bold size="label">
        <FormattedMessage
          id="warehouse.receiving.notifications.unexpectedSku"
          defaultMessage="Unexpected SKU (Ineligible for bulk-receive)"
        />
      </Text>
      <Text size="label" data-testid="unexpected-sku-notification-description">
        <FormattedMessage
          id="warehouse.receiving.notifications.unexpectedSKUDescription"
          defaultMessage="Deliverr will automatically resolve this when you complete your receive."
        />
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createOverReceiveNotification = (): NotificationProps => ({
  appearance: Appearance.DANGER,
  children: (
    <StyledNotificationContainer>
      <Text bold size="label">
        <FormattedMessage id="warehouse.receiving.notifications.overReceiveTitle" defaultMessage="Over Receive!" />
      </Text>
      <Text size="label" data-testid="over-receive-notification-description">
        <FormattedMessage
          id="warehouse.receiving.notifications.overReceiveText"
          defaultMessage="Receiving more units than expected."
        />
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createUnderReceiveNotification = (): NotificationProps => ({
  appearance: Appearance.DANGER,
  children: (
    <StyledNotificationContainer>
      <Text bold size="label">
        <FormattedMessage id="warehouse.receiving.notifications.underReceiveTitle" defaultMessage="Under Receive!" />
      </Text>
      <Text size="label" data-testid="under-receive-notification-description">
        <FormattedMessage
          id="warehouse.receiving.notifications.underReceiveText"
          defaultMessage="Receiving less units than expected."
        />
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createConsolidationUnexpectedSkuNotification = (): NotificationProps => ({
  appearance: Appearance.DANGER,
  children: (
    <StyledNotificationContainer>
      <Text bold size="label" data-testid="consolidation-unexpected-sku-notification-description">
        <FormattedMessage
          id="warehouse.receiving.notifications.consolidationUnexpectedSkuDescription"
          defaultMessage="Unexpected SKU, please double check with manager that you're receiving against the correct Consolidation label and PO."
        />
      </Text>
    </StyledNotificationContainer>
  ),
});

export const createReceiveRestartedNotification = (receiveFlow: ReceivingFlowType): NotificationProps => {
  let testId: string;
  let message: React.ReactNode;

  switch (receiveFlow) {
    case ReceivingFlowType.BOX_RECEIVING:
      testId = "box-receive-restarted-notification-description";
      message = (
        <FormattedMessage
          id="warehouse.receiving.notifications.boxReceiveRestartedCardDescription"
          defaultMessage="Box receive restarted. Do not navigate using the browser's back button."
        />
      );
      break;
    case ReceivingFlowType.PALLET_RECEIVING:
      testId = "pallet-receive-restarted-notification-description";
      message = (
        <FormattedMessage
          id="warehouse.receiving.notifications.palletReceiveRestartedCardDescription"
          defaultMessage="Pallet receive restarted. Do not navigate using the browser's back button."
        />
      );
      break;
    case ReceivingFlowType.CONSOLIDATION_RECEIVING:
      testId = "consolidation-receive-restarted-notification-description";
      message = (
        <FormattedMessage
          id="warehouse.receiving.notifications.consolidationReceiveRestartedCardDescription"
          defaultMessage="Consolidation receive restarted. Do not navigate using the browser's back button."
        />
      );
      break;
    default:
      testId = "receive-restarted-notification-description";
      message = (
        <FormattedMessage
          id="warehouse.receiving.notifications.receiveRestartedCardDescription"
          defaultMessage="Receive restarted. Do not navigate using the browser's back button."
        />
      );
  }

  return {
    appearance: Appearance.DANGER,
    children: (
      <StyledNotificationContainer>
        <Text bold size="label" data-testid={testId}>
          {message}
        </Text>
      </StyledNotificationContainer>
    ),
  };
};

export const createClosedReturnOrderSuccess = (trackingCode: string) => ({
  appearance: Appearance.SUCCESS,
  children: (
    <StyledNotificationContainer>
      <Text size="label" data-testid="closed-return-order-notification-description">
        <FormattedMessage
          id="warehouse.receiving.returnReceive.notifications.closedReturnOrderDescription"
          defaultMessage="Successfully closed return order"
        />
      </Text>
      <Text bold size="label">
        {trackingCode}
      </Text>
    </StyledNotificationContainer>
  ),
});
