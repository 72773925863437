import React from "react";
import { FacilityCard } from "facility-commons";
import { usePalletAnalyticsV2BoxSummary } from "./usePalletAnalyticsV2BoxSummary";
import { BoxConfigSummary } from "warehouse/receiving/ReceivingState/Types";
import { Text, Box, Grid, Divider, ThemeProps, IconV2 } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { GroupedAndFormattedBoxData } from "./usePalletAnalyticsV2BoxSummary";
import styled from "@emotion/styled";

interface PalletAnalyticsV2BoxSummaryProps {
  boxConfig: BoxConfigSummary;
}

interface ReceivedRowDataProps {
  receives: GroupedAndFormattedBoxData["receives"];
}

// We should be using theme coloring, but there isn't a theme color
// that matches the icon colors.
const StyledTextGreen = styled(Text)<ThemeProps>`
  color: #008000;
`;
const StyledTextRed = styled(Text)<ThemeProps>`
  color: #ff0000;
`;
export const CenteredIcon = styled(IconV2)<ThemeProps>`
  margin-bottom: auto;
  margin-top: auto;
  margin-right: ${({ theme }) => `${theme.spacing.S2}`};
`;

// We should be using theme spacing, but I couldn't find a theme spacing combination
// to get the alignment needed.
const LabelContainer = styled(Box)`
  padding-top: 1px;
`;

const ReceivedVsExpectedQtyContainer = styled(Box)`
  padding-bottom: 1px;
`;

const IconAndQtyContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const ReceivedRowData: React.FC<ReceivedRowDataProps> = ({ receives }) => {
  const RowIcon: React.FC<{ receivedQty: number; expectedQty: number }> = ({ receivedQty, expectedQty }) => {
    return receivedQty < expectedQty ? (
      <CenteredIcon type="exclamation-circle" color="red" size="small" />
    ) : receivedQty > expectedQty ? (
      <CenteredIcon type="plus" color="green" size="small" />
    ) : null;
  };

  const ReceivedQtyText: React.FC<{ receivedQty: number; expectedQty: number }> = ({ receivedQty, expectedQty }) => {
    return receivedQty < expectedQty ? (
      <StyledTextRed as="span" bold>
        {receivedQty}
      </StyledTextRed>
    ) : receivedQty > expectedQty ? (
      <StyledTextGreen as="span" bold>
        {receivedQty}
      </StyledTextGreen>
    ) : (
      <Text as="span">{receivedQty}</Text>
    );
  };

  return (
    <Box>
      {receives.map((receive, index) => {
        const { label, expectedQty, receivedQty } = receive;
        return (
          <Grid columns=".5fr .5fr" key={`${label}-${index}`}>
            <LabelContainer>
              <Text as="span">{label} </Text>
            </LabelContainer>
            <IconAndQtyContainer>
              <RowIcon receivedQty={receivedQty} expectedQty={expectedQty} />
              <ReceivedVsExpectedQtyContainer>
                <ReceivedQtyText receivedQty={receivedQty} expectedQty={expectedQty} />
                <Text as="span"> / </Text>
                <Text as="span" bold>
                  {expectedQty}
                </Text>
              </ReceivedVsExpectedQtyContainer>
            </IconAndQtyContainer>
          </Grid>
        );
      })}
    </Box>
  );
};

const ReceivingGroupSummary: React.FC<{
  groupByLabel: string;
  groupDetails: string;
  innerGroupingHeader: string;
  receivesHeader: string;
  receives: GroupedAndFormattedBoxData["receives"];
  isLastElement: boolean;
  index: number;
}> = ({ groupByLabel, groupDetails, innerGroupingHeader, receivesHeader, receives, isLastElement, index }) => {
  const GroupHeaders: React.FC<{ leftColumnHeader: string; rightColumnHeader: string }> = ({
    leftColumnHeader,
    rightColumnHeader,
  }) => {
    return (
      <Box marginBottom="S1">
        <Grid columns=".5fr .5fr">
          <Text bold>{leftColumnHeader}</Text>
          <Text bold textAlign={TextAlign.end}>
            {rightColumnHeader}
          </Text>
        </Grid>
      </Box>
    );
  };

  return (
    <Box marginBottom={!isLastElement ? "S2" : "S0"} key={`${groupByLabel}-${index}`}>
      <Box>
        <Text bold>{groupByLabel}</Text>
        <Text size="label" appearance="INFO">
          {groupDetails}
        </Text>
      </Box>
      <Box marginTop="S1">
        <GroupHeaders leftColumnHeader={innerGroupingHeader} rightColumnHeader={receivesHeader} />
        <ReceivedRowData receives={receives} />
      </Box>
      {!isLastElement ? (
        <Box marginTop="S2">
          <Divider />
        </Box>
      ) : null}
    </Box>
  );
};

export const PalletAnalyticsV2BoxSummary: React.FC<PalletAnalyticsV2BoxSummaryProps> = ({ boxConfig }) => {
  const { boxSummaries, cdskuHeader, dskuHeader, receivedVsExpectedHeader } = usePalletAnalyticsV2BoxSummary(boxConfig);

  return (
    <FacilityCard>
      {boxSummaries.map((boxSummary, index) => {
        const { groupByLabel, groupDetails, receives } = boxSummary;
        const isLastElement = index === boxSummaries.length - 1;
        const innerGroupingHeader = boxConfig === BoxConfigSummary.BULK ? cdskuHeader : dskuHeader;
        return (
          <ReceivingGroupSummary
            key={index}
            groupByLabel={groupByLabel}
            groupDetails={groupDetails}
            innerGroupingHeader={innerGroupingHeader}
            receivesHeader={receivedVsExpectedHeader}
            receives={receives}
            isLastElement={isLastElement}
            index={index}
          />
        );
      })}
    </FacilityCard>
  );
};
