import React from "react";
import { Appearance, BoxReset, Button, Notification, ThemeProps, Checkbox } from "@deliverr/ui";
import styled from "@emotion/styled";
import { CardRow } from "facility-commons/components/CardRow";
import { NumericInput } from "facility-commons/components/Inputs/NumericInput";
import { ProductSummaryCard } from "../ProductCard";
import { useQuantityCardV2 } from "./useQuantityCardV2";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { DarkerCardLabelText } from "facility-commons/components/emotion";
import { commonBoxMessages, quantityMessages } from "warehouse/receiving/content";
import { LoadingSpinner } from "facility-commons/components";
import { Box } from "@deliverr/ui/lib-esm/Box";
import { commonMessages } from "facility-commons/labels";
import { ReceivedSkus } from "../LocationCardV2/ReceivedSkus";
import { HighRiskBoxWarningNotification } from "../../HighRiskBoxWarningNotification/HighRiskBoxWarningNotification";

const TopMarginButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S4};
  `
);
const TopMarginContainer = styled.div<ThemeProps>(
  ({ theme }) => `
      margin-top: ${theme.spacing.S2};
  `
);

const StyledDiv = styled.div`
  label {
    white-space: normal;
  }
`;

export const QuantityCardV2 = () => {
  const {
    barcode,
    boxQuantityError,
    boxQuantityRef,
    quantityRef,
    description,
    disableContinue,
    formatMessage,
    handleBoxQuantityChange,
    handleUnitsQuantityChange,
    handleSubmit,
    handleAdditionalFefoLotUnits,
    showBoxQuantityInput,
    isPalletReceive,
    isBoxReceive,
    receivingError,
    boxesQtyInput,
    unitsQtyInput,
    loading,
    previouslyReceivedQuantity,
    previousReceives,
    cardMessage,
    isMultiFefo,
    receiveMoreUnitsFromBox,
    disableUnitQtyInput,
    isHighRiskBox,
  } = useQuantityCardV2();

  return (
    <>
      {!!isHighRiskBox && <HighRiskBoxWarningNotification />}
      {previouslyReceivedQuantity > 0 && isBoxReceive && (
        <Box marginBottom={"S2"} as="section">
          <Notification
            appearance={Appearance.DANGER}
            content={{
              description: formatMessage(commonBoxMessages.previouslyReceived),
            }}
          />
        </Box>
      )}
      <BoxReset marginBottom="S4">
        <FacilityCard as="section">
          <ProductSummaryCard barcode={barcode} description={description} />
        </FacilityCard>
      </BoxReset>
      <form onSubmit={handleSubmit}>
        <FacilityCard as="section">
          <DarkerCardLabelText>
            {isMultiFefo
              ? cardMessage
              : formatMessage(
                  showBoxQuantityInput ? quantityMessages.unitAndBoxQuantitiesTitle : quantityMessages.unitQuantityTitle
                )}
          </DarkerCardLabelText>
          {previouslyReceivedQuantity > 0 && <ReceivedSkus previousReceives={previousReceives} />}
          {/* UNIT QUANTITY */}
          <NumericInput
            autoFocus
            ref={quantityRef}
            value={unitsQtyInput}
            errorMessage={receivingError}
            hasError={Boolean(receivingError)}
            placeholder={
              showBoxQuantityInput
                ? formatMessage(quantityMessages.quantityPlaceholder)
                : formatMessage(quantityMessages.quantityPlaceholderSingleBox)
            }
            onChange={handleUnitsQuantityChange}
            data-testid="quantity-card-units-input"
            disabled={disableUnitQtyInput}
          />

          {/* bulk receive is only for single sku boxes */}
          {showBoxQuantityInput && (
            <TopMarginContainer>
              <NumericInput
                errorMessage={boxQuantityError}
                hasError={Boolean(boxQuantityError)}
                onChange={handleBoxQuantityChange}
                placeholder={
                  isPalletReceive
                    ? formatMessage(quantityMessages.boxesOnPallet)
                    : formatMessage(quantityMessages.boxesPlaceholder)
                }
                ref={boxQuantityRef}
                value={boxesQtyInput}
                data-testid="quantity-card-boxes-input"
              />
              <CardRow hideDivider label="Total units" value={(boxesQtyInput * unitsQtyInput).toString()} />
            </TopMarginContainer>
          )}
          {isMultiFefo && (
            <StyledDiv>
              <Checkbox
                onChange={handleAdditionalFefoLotUnits}
                label={formatMessage(quantityMessages.otherFefoLotUnitsHelpText)}
                labelStyles={"grid-gap: 1.4rem;"}
                checked={receiveMoreUnitsFromBox}
              ></Checkbox>
            </StyledDiv>
          )}
        </FacilityCard>
        <TopMarginButton
          block
          disabled={disableContinue || Boolean(receivingError) || Boolean(boxQuantityError)}
          type="submit"
          data-testid="submit-qty-btn"
        >
          {formatMessage(commonMessages.continue)}
        </TopMarginButton>
      </form>
      <LoadingSpinner loading={loading} size="2x" />
    </>
  );
};
