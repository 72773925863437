import {
  CrossdockClient,
  InventoryClient,
  NonComplianceClient,
  OutboundLoadBalancerClient,
  ProductClient,
  S3FileHandlerClient,
  ShippingLabelClient,
  TransportationClient,
} from "@deliverr/commons-clients";

import { AuthEngine } from "@deliverr/core-auth";
import { CrossdockClient as CrossdockClientV2 } from "@deliverr/crossdock-service-client";
import { InboundClient } from "@deliverr/legacy-inbound-client";
import { AuthEngine as LegacyAuthEngine } from "@deliverr/commons-objects";
import { NonComplianceClient as NonComplianceClientV2 } from "@deliverr/non-compliance-client";
import { PrepClient } from "@deliverr/prep-service-client";
import { ReturnsClient } from "@deliverr/returns-client";
import { StorageInboundsClient } from "./StorageInboundsClient";
import { WarehousePublicClient } from "@deliverr/warehouse-client";
import { WholesaleClient } from "@deliverr/wholesale-client";
import { WotClient } from "@deliverr/wot-client";
import { logError } from "facility-commons/utils";

export interface ClientApiWithTokenFn {
  setTokenFn: (tokenFn: () => Promise<string>) => void;
}

export interface ClientApiLegacy {
  setTokenFn: (tokenFn: () => Promise<string>) => void;
  withAccessToken: (token: string) => void;
  addBeforeEach: (fn: () => Promise<void>) => void;
}

export type ClientApi = ClientApiWithTokenFn | ClientApiLegacy;

export const setTokenFunctionInClients = (clientsArray: ClientApi[], accessToken: Promise<string>) => {
  clientsArray.forEach((client: ClientApi) => {
    if ((client as any).withAccessToken) {
      const clientCasted = client as ClientApiLegacy;
      clientCasted.addBeforeEach(async () => {
        const token = await accessToken;
        clientCasted.withAccessToken(token);
      });
    } else if ((client as any).setTokenFn) {
      const clientCasted = client as ClientApiWithTokenFn;
      clientCasted.setTokenFn(() => accessToken);
    } else {
      logError(
        { fn: "setTokenFunctionInClients", client },
        "Unexpected error with client type contract. Client does not have a setTokenFn or withAccessToken method."
      );
    }
  });
};

/**
 * TODO: each app should define its own clients, but for now, aggregating them for use here
 * with `useClientsWithAuth` hook will at least remove dependencies on apps.
 * Follow-up ticket: https://app.asana.com/0/1203367179337450/1203798308506677
 */

const commonHeaders = {
  "X-Request-Caller": process.env.REQUESTER_NAME ?? "warehouse-portal",
  "X-Direct-Caller": process.env.REQUESTER_NAME ?? "warehouse-portal",
};

export const s3FileHandlerClient = new S3FileHandlerClient(
  {
    baseURL: process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
    authEngine: LegacyAuthEngine.AUTH0,
  },
  {
    headers: commonHeaders,
  }
);

export const warehousePublicClient = new WarehousePublicClient({
  baseURL: process.env.REACT_APP_WAREHOUSE_SERVICE_URL,
  authEngine: LegacyAuthEngine.AUTH0,
  headers: commonHeaders,
});

export const crossdockClientV2 = new CrossdockClientV2({
  baseURL: process.env.CROSSDOCK_SERVICE_URL ?? process.env.REACT_APP_CROSSDOCK_SERVICE_URL,
  authEngine: LegacyAuthEngine.AUTH0,
  headers: commonHeaders,
});

export const crossdockClient = new CrossdockClient({
  baseURL: process.env.REACT_APP_CROSSDOCK_SERVICE_URL,
  authEngine: LegacyAuthEngine.AUTH0,
  headers: commonHeaders,
});

export const shippingClient = new ShippingLabelClient({
  baseURL: "https://fulfillment.deliverr.com/",
  headers: commonHeaders,
}); // must be prod for DHL manifest

export const returnsClient = new ReturnsClient({
  baseURL: process.env.REACT_APP_RETURN_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const storageClient = new StorageInboundsClient({
  baseURL: process.env.REACT_APP_STORAGE_SERVICE_URL,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
} as any);

export const transportationClient = new TransportationClient(
  {
    baseURL: process.env.REACT_APP_TRANSPORTATION_SERVICE_URL,
    headers: commonHeaders,
  },
  // Set service URL to empty string so that the config doesn't override the baseURL
  "",
  process.env.REACT_APP_TRANSPORTATION_BASIC_AUTH_USER,
  process.env.REACT_APP_TRANSPORTATION_BASIC_AUTH_PASSWORD
);

export const nonComplianceClient = new NonComplianceClient({
  baseURL: process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const inboundClient = new InboundClient({
  baseURL: process.env.REACT_APP_INBOUND_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const outboundLoadBalancerClient = new OutboundLoadBalancerClient({
  baseURL: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_URL,
  basicAuthUser: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_AUTH_USER,
  basicAuthPassword: process.env.REACT_APP_OUTBOUND_LOAD_BALANCER_AUTH_PASSWORD,
  headers: commonHeaders,
});

export const productClient = new ProductClient({
  baseURL: process.env.REACT_APP_PRODUCT_SERVICE_URL!,
  authEngine: LegacyAuthEngine.AUTH0,
  headers: commonHeaders,
});

export const prepClient = new PrepClient({
  baseURL: process.env.REACT_APP_PREP_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const inventoryClient = new InventoryClient({
  baseURL: process.env.REACT_APP_INVENTORY_SERVICE_URL,
  auth: {
    username: process.env.REACT_APP_INVENTORY_SERVICE_BASIC_AUTH_USER!,
    password: process.env.REACT_APP_INVENTORY_SERVICE_BASIC_AUTH_PASSWORD!,
  },
  headers: commonHeaders,
});

export const wotClient = new WotClient({
  baseURL: process.env.REACT_APP_WAREHOUSE_BOT_SERVICE_URL,
  headers: commonHeaders,
});

export const nonComplianceClientV2 = new NonComplianceClientV2({
  baseURL: process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});

export const wholesaleClient = new WholesaleClient({
  baseURL: process.env.REACT_APP_WHOLESALE_SERVICE_URL!,
  authEngine: AuthEngine.AUTH0,
  headers: commonHeaders,
});
