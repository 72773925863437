export enum HazmatFormField {
  BARCODE = "barcode",
}

export enum HazmatFormValidationErrorMessages {
  BARCODE_REQUIRED = "DSKU is required",
  BARCODE_INVALID = "DSKU is invalid",
  BARCODE_FORMAT = "Invalid barcode it must be DSKU",
  UNKNOWN = "Something went wrong, please check the DSKU and try again.",
}
