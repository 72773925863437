import { useIntl } from "react-intl";
import { WarehouseMessages } from "facility-commons";
import { useAsyncFn } from "react-use";
import { genericOnScannerInputChange, log, logStart, logError } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";
import { useWarehouseModal } from "warehouse/modal";
import { WarehouseModal } from "warehouse/modal/WarehouseModalMap";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useRouter } from "facility-commons/hooks";
import { ArrivalScannerRoutes } from "warehouse/routes";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import {
  createBoxArrivalScannerCard,
  createBoxTypeIdentificationCard,
  createBoxArrivalScanNotificationCard,
} from "warehouse/common/flow/warehouseFlowCardCreators";
import {
  BoxArrivalScanEntity,
  BoxArrivalScanResponseData,
  ValidationError,
  ShippingPlanDispersalMethod,
} from "@deliverr/legacy-inbound-client";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { arrivalScanMessages } from "warehouse/scan-arrival/arrivalScanMessage";
import { getTrackingCodeFromBarcode } from "@deliverr/commons-utils";
import { debounce } from "lodash";

const sortableEntities = [
  BoxArrivalScanEntity.CDSKU,
  BoxArrivalScanEntity.INBOUND_PACKAGE_TRACKING_CODE,
  BoxArrivalScanEntity.RETURNS_PACKAGE_TRACKING_CODE,
  BoxArrivalScanEntity.UNDELIVERABLE_PACKAGE_TRACKING_CODE,
];

export const useBoxArrivalScannerCard = () => {
  const { formatMessage } = useIntl();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const [currentScanValue, setCurrentScanValue] = useState("");
  const [boxArrivalScanError, setBoxArrivalScanError] = useState("");
  const { inboundClient } = useClientsWithAuth();
  const { push } = useRouter();
  const { hideAllModals, showModal } = useWarehouseModal();
  const { successResponse, infoResponse, errorResponse, nextFlowCard, addFlowCard } = useWarehouseFlow();

  const [{ loading }, handleSubmit] = useAsyncFn(async (barcodeInput: string) => {
    setBoxArrivalScanError("");

    if (!barcodeInput.length) {
      errorResponse();
      return setBoxArrivalScanError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
    }

    // USPS, FEDEX, and DHL have different formats for how their tracking code is saved within their barcodes
    // this function attempts to extract the tracking code from the barcode
    // if the input doesn't match any of the known formats, it will return the original value
    const maybeTrackingCode = getTrackingCodeFromBarcode(barcodeInput);

    const ctx = logStart({ fn: "useBoxArrivalScannerCard.handleSubmit", scanned: maybeTrackingCode, facilityId });

    const onError = (error: ValidationError) => {
      log({ ...ctx, error }, "error on arrival scan");
      const { message, payload } = error!;
      errorResponse();
      setCurrentScanValue("");
      const messageId = getErrorMessageId({ response: error! });
      const formattedValues = payload && formatValues(payload);
      return setBoxArrivalScanError(messageId ? formatMessage(messageId, formattedValues) : message);
    };

    const getArrivalScanNotificationBySortableEntity = (
      entity: Omit<BoxArrivalScanEntity, "PALLET_LABEL" | "ASN_LABEL" | "UNKNOWN">,
      label: string
    ) => {
      // default message, theoretically should never be used
      let message: string = formatMessage(arrivalScanMessages.sortBoxToRackMessage, { label });
      switch (entity) {
        case BoxArrivalScanEntity.CDSKU:
        case BoxArrivalScanEntity.INBOUND_PACKAGE_TRACKING_CODE:
          message = formatMessage(modalsText.boxSortReceiveMessage, { label });
          break;
        case BoxArrivalScanEntity.RETURNS_PACKAGE_TRACKING_CODE:
          message = formatMessage(modalsText.boxSortReturnsMessage, { label });
          break;
        case BoxArrivalScanEntity.UNDELIVERABLE_PACKAGE_TRACKING_CODE:
          message = formatMessage(modalsText.boxSortUndeliveredMessage, { label });
          break;
      }
      return message;
    };

    const onSuccess = async (data: BoxArrivalScanResponseData, label: string) => {
      log({ ...ctx, ...data }, "successful box arrival scan");
      const { entity, arrivalScanId } = data;
      if (sortableEntities.includes(entity)) {
        if (entity === BoxArrivalScanEntity.CDSKU || entity === BoxArrivalScanEntity.INBOUND_PACKAGE_TRACKING_CODE) {
          try {
            let dispersalMethod: ShippingPlanDispersalMethod;
            if (entity === BoxArrivalScanEntity.CDSKU) {
              const response = await inboundClient.getPackageDispersalMethodByCdsku(label, facilityId);
              dispersalMethod = response.data!.dispersalMethod;
            } else {
              const response = await inboundClient.getPackageDispersalMethodByTrackingNumber(label, facilityId);
              dispersalMethod = response.data!.dispersalMethod;
            }
            const isDirectPackage = dispersalMethod === ShippingPlanDispersalMethod.DIRECT;
            const isCrossDockPackage = [
              ShippingPlanDispersalMethod.DISTRIBUTION,
              ShippingPlanDispersalMethod.FORWARDING,
            ].includes(dispersalMethod);
            const facilitySortingMessage = isDirectPackage
              ? formatMessage(arrivalScanMessages.sortBoxToFCReceiving, { label })
              : isCrossDockPackage
              ? formatMessage(arrivalScanMessages.sortBoxToCDSorting, { label })
              : formatMessage(modalsText.boxSortReceiveMessage, { label });
            return showModal(WarehouseModal.SORT_BOX_TO_FACILITY_AREA, {
              label,
              dispersalMethod,
              onConfirm: () => {
                successResponse();
                setCurrentScanValue("");
                hideAllModals();
                addFlowCard(
                  createBoxArrivalScanNotificationCard({
                    message: facilitySortingMessage,
                    accentColor: "GREEN",
                    icon: "check-circle",
                  })
                );
                addFlowCard(createBoxArrivalScannerCard({}));
              },
            });
          } catch (err: any) {
            // if we can't determine if package is for FC or for CD
            // fall through and show generic message to sort box to rack for receiving
            logError({ ...ctx }, err);
          }
        }
        showModal(WarehouseModal.SORT_BOX_TO_RACK, {
          label: maybeTrackingCode,
          packageType: entity,
          onConfirm: () => {
            successResponse();
            setCurrentScanValue("");
            hideAllModals();
            addFlowCard(
              createBoxArrivalScanNotificationCard({
                message: getArrivalScanNotificationBySortableEntity(entity, maybeTrackingCode),
                accentColor: "GREEN",
                icon: "check-circle",
              })
            );
            addFlowCard(createBoxArrivalScannerCard({}));
          },
        });
      } else if (entity === BoxArrivalScanEntity.PALLET_LABEL) {
        showModal(WarehouseModal.ROUTE_TO_PALLET_ARRIVAL_SCAN, {
          palletLabel: maybeTrackingCode,
          onConfirm: () => {
            setCurrentScanValue("");
            hideAllModals();
            push(ArrivalScannerRoutes.PALLET);
          },
          onCancel: () => {
            setCurrentScanValue("");
            hideAllModals();
            nextFlowCard(createBoxArrivalScannerCard({}));
          },
        });
      } else if (entity === BoxArrivalScanEntity.ASN_LABEL) {
        errorResponse();
        setCurrentScanValue("");
        setBoxArrivalScanError(
          formatMessage(WarehouseMessages.boxArrivalAsnWasScannedErrorMessageText, { label: maybeTrackingCode })
        );
      } else if (entity === BoxArrivalScanEntity.UNKNOWN) {
        infoResponse();
        nextFlowCard(createBoxTypeIdentificationCard({ arrivalScanId, scannedLabel: maybeTrackingCode }));
      } else {
        // this shouldn't happen but catch-all just in case
        errorResponse();
        setCurrentScanValue("");
        setBoxArrivalScanError(formatMessage(WarehouseMessages.scanBoxLabelErrorMessageText, { entity }));
      }
    };

    try {
      const response = await inboundClient.processBoxArrivalScan(maybeTrackingCode, facilityId);
      if (response.error) {
        onError(response.error);
      } else {
        await onSuccess(response.data!, maybeTrackingCode);
      }
    } catch (error: any) {
      logError(ctx, error);
      errorResponse();
      setBoxArrivalScanError(formatMessage(COMMON_LABELS.UNEXPECTED_ERROR));
    }
  }, []);

  const updateBoxScanLabel = (newScanValue: string) => {
    setBoxArrivalScanError("");
    setCurrentScanValue(newScanValue);
  };

  const debouncedHandleSubmit = debounce(handleSubmit, 500) as (barcodeInput: string) => Promise<any>;

  const handleChange = genericOnScannerInputChange(currentScanValue, updateBoxScanLabel, debouncedHandleSubmit);

  const title = formatMessage(WarehouseMessages.scanBoxLabelTitleText);
  const message = formatMessage(WarehouseMessages.scanBoxLabelMessageText);
  const placeholder = formatMessage(WarehouseMessages.barcode);

  return {
    title,
    message,
    placeholder,
    currentScanValue,
    handleSubmit: debouncedHandleSubmit,
    handleChange,
    loading,
    boxArrivalScanError,
  };
};
