import { defineMessages, MessageDescriptor } from "react-intl";

export enum RetryLimitExceededErrorMessageKey {
  RETRY_LIMIT_EXCEEDED = "RETRY_LIMIT_EXCEEDED",
}

export const RETRY_LIMIT_ERROR_BY_ERROR_CODE: Record<
  RetryLimitExceededErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [RetryLimitExceededErrorMessageKey.RETRY_LIMIT_EXCEEDED]: {
    id: "warehouse.receiving.retryLimitExceededError",
    defaultMessage: "Exceeded retry limit for {functionName}",
  },
});
