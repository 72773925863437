import { FlowButtonMap } from "facility-commons/flow/types";
import { RSOutboundsFlowButtonType } from "../types/RSOutboundsFlowButtonType";
import {
  ShipmentSummaryCreatePackageButton,
  ShipmentSummaryReprintAllLabelsButton,
  ShipmentSummaryScanNextShipmentButton,
} from "warehouse/rs-outbounds/components/ShipmentSummaryCard";
import {
  CreatePackageClosePackageButton,
  CreatePackageDiscardButton,
} from "warehouse/rs-outbounds/components/CreatePackageCard";
import {
  SelectBoxSizeCardBackButton,
  SelectBoxSizeCardContinueButton,
} from "warehouse/rs-outbounds/components/SelectBoxSizeCard";
import { PendingItemsCardBackButton } from "warehouse/rs-outbounds/components/PendingItemsCard";

export const rsOutboundsFlowButtonMap: FlowButtonMap<RSOutboundsFlowButtonType> = {
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_CREATE_PACKAGE]: ShipmentSummaryCreatePackageButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_REPRINT_LABELS]: ShipmentSummaryReprintAllLabelsButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_SCAN_NEXT_SHIPMENT]: ShipmentSummaryScanNextShipmentButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_CLOSE_PACKAGE]: CreatePackageClosePackageButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_SELECT_BOX_SIZE_CONTINUE]: SelectBoxSizeCardContinueButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_SELECT_BOX_SIZE_BACK]: SelectBoxSizeCardBackButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_DISCARD_PACKAGE]: CreatePackageDiscardButton,
  [RSOutboundsFlowButtonType.RS_OUTBOUNDS_PENDING_ITEMS_BACK]: PendingItemsCardBackButton,
};
