import { createFlowCard } from "facility-commons/flow";
import { RSOutboundsFlowCardType } from "warehouse/common/flow/types/RSOutboundsFlowCardType";

export const createOutboundsPackingShipmentScan = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SHIPMENT_SCAN
);
export const createOutboundsPackingShipmentSummary = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SHIPMENT_SUMMARY
);
export const createOutboundsPackingCreatePackage = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_CREATE_PACKAGE
);
export const createOutboundsPackingSelectBoxSize = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SELECT_BOX_SIZE
);
export const createOutboundsPackingPrintLabels = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_PRINT_LABELS
);
export const createOutboundsPendingItemsCard = createFlowCard(
  RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_PENDING_ITEMS
);
