import { receivingBarcodeScanButtons } from "../utils";
import { useSetRecoilState } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";
import { setProp } from "facility-commons/utils";

export const useReceivingButtons = () => {
  const setReceivingButtons = useSetRecoilState(receivingState);

  const resetReceivingButtons = () => setReceivingButtons(setProp("receivingButtons", []));

  const generateBarcodeScanButtons = () =>
    setReceivingButtons(setProp("receivingButtons", receivingBarcodeScanButtons));

  return {
    generateBarcodeScanButtons,
    resetReceivingButtons,
  };
};
