import { selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { userState } from "facility-commons/base/Auth/userState";

export const getIsElevatedUser = ({ get }) => {
  const { isAdmin, isManager, isProblemSolver } = get(userState);

  return isAdmin || isManager || isProblemSolver;
};
export const isElevatedUser = selector({
  key: WarehouseStateKey.IS_ELEVATED_USER,
  get: getIsElevatedUser,
});
