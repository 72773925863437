import React from "react";
import styled from "@emotion/styled";
import { Box, Switch } from "@deliverr/ui";
import { useIntl } from "react-intl";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { DarkerCardLabelText } from "facility-commons/components/emotion/cards";
import { NumericInput } from "facility-commons/components/Inputs/NumericInput";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useReceivingPalletCountFlow } from "./useReceivingPalletCountFlow";

const Card = styled(FacilityCard)`
  height: auto !important; // Override Parent Container's property
`;

export const StorageReceivingPalletCount: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    scanError,
    palletCount,
    isPalletized,
    handlePalletCountChange,
    handleSubmit,
    handleChange,
  } = useReceivingPalletCountFlow();

  return (
    <form onSubmit={handleSubmit}>
      <Card as="section">
        <Switch
          label={formatMessage(STORAGE_RECEIVING_MESSAGES.checkIfContainerPalletized)}
          checked={isPalletized}
          onChange={handleChange}
        />
        {isPalletized && (
          <Box paddingTop="S2">
            <DarkerCardLabelText>
              {formatMessage(STORAGE_RECEIVING_MESSAGES.receivingPalletCountTitle)}
            </DarkerCardLabelText>
            <NumericInput
              autoFocus
              value={palletCount}
              errorMessage={scanError}
              placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.receivingPalletCountPlaceholder)}
              onChange={handlePalletCountChange}
            />
          </Box>
        )}
      </Card>
    </form>
  );
};
