import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { LoadingSpinner } from "facility-commons/components";

export interface DashboardProps {
  embeddedDashboardUrl: string;
  dashboardTitle?: string;
  loading: boolean;
  error: string;
}

export interface WarehouseEmbeddedDashboardProps {
  title?: string;
}

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const WarehouseEmbeddedDashboard: React.FC<WarehouseEmbeddedDashboardProps> = ({ title }) => {
  // Passing props via Link component rather than re-fetching
  const { embeddedDashboardUrl, dashboardTitle, loading, error } = useLocation<DashboardProps>().state;

  if (loading) {
    return <LoadingSpinner loading />;
  }

  if (error) {
    return (
      <div>
        <FormattedMessage
          id="warehouse.embeddedDashboard.error"
          defaultMessage="A server error has occurred. Please try again."
        />
      </div>
    );
  }

  return <StyledIframe src={embeddedDashboardUrl} title={dashboardTitle ?? title}></StyledIframe>;
};
