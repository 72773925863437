import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";
import { CardProps, DefaultTheme, Title, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";

import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { asnResponseState, CdskuScan } from "warehouse/common/components/CdskuScan";
import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";

const DarkCard = styled(FacilityCard)<CardProps, DefaultTheme>(
  ({ theme }) => `
  max-width: ${MAX_CARD_WIDTH};
  background-color: ${theme.colors.NEUTRAL[400]};
  color: ${theme.colors.NEUTRAL["00"]};
  margin-bottom: ${theme.spacing.S4};
  `
);

export const PONumberLookup: React.FC = () => {
  const asn = useRecoilValue(asnResponseState);

  // Modal message cannot be passed in as a FormattedMessage component because
  // it renders before the parameter gets evaluated
  const modalMessage = (asn: ReceivingAsn) => ({
    id: "warehouse.poNumberLookup.message",
    defaultMessage: `Receive <boldtext>${asn?.cdsku}</boldtext> against <boldtext>PO# ${asn?.asnId}.</boldtext>`,
  });

  return (
    <>
      {asn?.asnId && (
        <DarkCard as="section">
          <Text>
            <FormattedMessage
              id="warehouse.poNumberLookup.cdsku"
              defaultMessage="Receive {cdsku} on"
              values={{ cdsku: asn?.cdsku }}
            />
          </Text>
          <Title>
            <FormattedMessage
              id="warehouse.poNumberLookup.poNumber"
              defaultMessage="PO # {po}"
              values={{ po: asn?.asnId }}
            />
          </Title>
        </DarkCard>
      )}
      <CdskuScan
        title={
          <FormattedMessage
            id="warehouse.poNumberLookup.title"
            defaultMessage="Scan {another} CDSKU"
            values={{
              another: asn ? (
                <FormattedMessage id="warehouse.poNumberLookup.alternateTitle" defaultMessage="Another" />
              ) : (
                ""
              ),
            }}
          />
        }
        instructions={{
          id: "warehouse.poNumberLookup.instructions",
          defaultMessage: "Scan or enter the CDSKU on the box contents label to get its PO #",
        }}
        errorMessage="Please try again."
        modalMessage={modalMessage}
      />
    </>
  );
};
