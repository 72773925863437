import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons/utils";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useMount } from "react-use";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import {
  bulkReceiveState,
  cdskuState,
  currentSkuState,
  receiveFlowTypeState,
  receivingState,
} from "warehouse/receiving/ReceivingState";
import { ReceivingData } from "warehouse/receiving/ReceivingState/Types";
import { useReceivingFlow } from "warehouse/receiving/base";
import { bulkMessages } from "warehouse/receiving/content";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { isInvalidCdsku } from "warehouse/receiving/utils";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";
import { COMMON_LABELS } from "../warehouse.labels";

export const useCdskuCard = () => {
  const [cdskuError, setCdskuError] = useState("");
  const [receivingData, setReceivingData] = useRecoilState<ReceivingData>(receivingState);
  const [cdskuData, setCdskuData] = useRecoilState(cdskuState);
  const { warehouseId } = useRecoilValue(userState);
  const { handleUnknownError, updateCurrentSku } = useReceivingFlow();
  const setBulkReceiveState = useSetRecoilState(bulkReceiveState);
  const { asnId, requestBatchId } = receivingData;
  const initializeReceivingData = useInitializeReceivingData();
  const { push } = useRouter();
  const [{ problemSolverFlowEnabled }, setWarehouseAppState] = useRecoilState(warehouseAppState);
  const { inboundClient } = useClientsWithAuth();
  const { errorResponse, successResponse, resetNotifications } = useCommonFlow();
  const { showModal, hideAllModals } = useWarehouseModal();

  const { formatMessage } = useIntl();
  const receivingFlow = useRecoilValue(receiveFlowTypeState);

  const { cdsku } = useRecoilValue(currentSkuState);

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(bulkMessages.title)));
    initializeReceivingData();
    setWarehouseAppState(setProp("pageSubtitle", ""));
  });

  const hideModalAndResetReceivingState = () => {
    hideAllModals();
    initializeReceivingData();
  };

  const [submitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      const ctx = logStart({ fn: "useCdskuCard.handleSubmit", value, warehouseId, cdskuError });

      setCdskuError("");

      if (!value.length) {
        errorResponse();
        return setCdskuError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      }

      if (isInvalidCdsku(value)) {
        // must be 'CD' + 9 alphanumeric characters.
        updateCurrentSku("cdsku", ""); // necessary so we can listen for the re-scan
        errorResponse();
        return setCdskuError(formatMessage(COMMON_LABELS.INVALID_CDSKU_ERROR));
      }

      const onError = (response) => {
        log({ ...ctx, response }, "error when validating CDSKU");
        const { message, payload } = response.error;

        errorResponse(() => updateCurrentSku("cdsku", ""));
        const messageId = getErrorMessageId({ response: response.error });
        const formattedValues = payload && formatValues(payload);
        return setCdskuError(messageId ? formatMessage(messageId, formattedValues) : message);
      };

      const onSuccess = (response) => {
        log({ ...ctx, ...response }, "validated cdsku");
        const {
          asnId: poNumber,
          isAsnCompleted,
          numSkus,
          wasReceived,
          isBulkEligible,
          isExpeditedBulkEligible,
          numIdenticalBoxes,
          numUnitsPerBox,
          expeditedBulkBoxLabels,
          numIdenticalNonReceivedBoxes,
          isHighRisk,
        } = response.data;
        setReceivingData(setProp("expectedSkus", numSkus ?? 0));
        setReceivingData(setProp("wasReceived", wasReceived ?? false));
        setReceivingData(setProp("expectedNumOfBoxes", numIdenticalBoxes));
        setReceivingData(setProp("numIdenticalNonReceivedBoxes", numIdenticalNonReceivedBoxes));
        setBulkReceiveState({
          isBulkEligible,
          isExpeditedBulkEligible,
          numIdenticalBoxes,
          numUnitsPerBox,
          expeditedBulkBoxLabels,
        });

        setCdskuData(setProp("isHighRisk", isHighRisk));
        setCdskuData(setProp("expectedNumUnitsInBox", numUnitsPerBox));

        if (isAsnCompleted && poNumber) {
          errorResponse();
          log({ ...ctx, response }, "ASN Id has already been received");
          return showModal(WarehouseModal.CLOSED_PALLET, {
            asnId: poNumber,
            onCancel: hideModalAndResetReceivingState,
          });
        }

        resetNotifications();
        successResponse();
        push(ReceivingPath.SKU);
        updateCurrentSku("cdsku", value);
      };

      try {
        const response = await inboundClient.receivingAppValidatePackage(warehouseId, value, requestBatchId);
        if (response.error) {
          onError(response);
        } else {
          if (response.data?.numSkus === 1 && response.data?.numUnitsPerBox === 1 && response.data.areAllSkusSIOC) {
            showModal(WarehouseModal.SINGLE_SKU_SIOC, {
              onConfirm: () => {
                hideAllModals();
                onSuccess(response);
              },
            });
          } else {
            onSuccess(response);
          }
        }
      } catch (error) {
        updateCurrentSku("cdsku", "");
        handleUnknownError(ctx, error);
      }
    },
    [warehouseId, requestBatchId, asnId, receivingFlow, cdskuData]
  );

  const updateCdsku = (value: string) => updateCurrentSku("cdsku", value);

  const handleChange = genericOnScannerInputChange(cdsku, updateCdsku, handleSubmit, "upper");

  const handleOnSkipStep = () => {
    updateCurrentSku("cdsku", "");
    push(ReceivingPath.PO);
  };

  return {
    cdsku,
    cdskuError,
    handleChange,
    handleSubmit,
    loading: submitState.loading,
    handleOnSkipStep,
    problemSolverFlowEnabled,
  };
};
