import React from "react";

import { Flow } from "facility-commons/flow";
import { warehouseFlowCardsState } from "./warehouseFlowState";
import { warehouseFlowCardMap } from "./maps/warehouseFlowCardMap";

export const BoxArrivalScanFlow: React.FC = () => {
  return (
    <Flow cardsState={warehouseFlowCardsState} cardMap={warehouseFlowCardMap} visibleButtons={[]} buttonMap={{}} />
  );
};
