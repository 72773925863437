import React from "react";
import { Radio } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { BoxReset, Button, Title, Stack } from "@deliverr/ui";
import { commonReceivingMessages } from "warehouse/receiving/content";

import { useAdditionalUnitsConfirmationCard } from "./useAdditionalUnitsConfirmationCard";
import { WarehouseMessages } from "facility-commons/utils";

export const AdditionalUnitsConfirmationCard = () => {
  const {
    ReceiveMoreUnits,
    disableButton,
    formatMessage,
    handleOptionChange,
    onConfirm,
  } = useAdditionalUnitsConfirmationCard();

  return (
    <>
      <FacilityCard as="section">
        <BoxReset marginBottom="S4">
          <Title as="h2" displayAs="h4">
            {formatMessage(commonReceivingMessages.receiveMoreUnits)}
          </Title>
        </BoxReset>

        {
          <>
            <BoxReset>
              <Stack>
                <Radio
                  label={formatMessage(WarehouseMessages.yes)}
                  name="receiveMoreUnitsConfirm"
                  value={ReceiveMoreUnits.YES}
                  onChange={handleOptionChange}
                ></Radio>
                <Radio
                  label={formatMessage(WarehouseMessages.no)}
                  name="receiveMoreUnitsConfirm"
                  value={ReceiveMoreUnits.NO}
                  onChange={handleOptionChange}
                ></Radio>
              </Stack>
            </BoxReset>
          </>
        }
      </FacilityCard>
      <BoxReset marginTop="S4">
        <Button block onClick={onConfirm} disabled={disableButton}>
          {formatMessage(commonReceivingMessages.continue)}
        </Button>
      </BoxReset>
    </>
  );
};
