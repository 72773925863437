import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { defineMessages } from "react-intl";

export const TICKET_LIST_TABS_LABELS = defineMessages({
  [TicketCenterNonComplianceCaseStatus.TODO]: {
    id: "warehouse.ticketCenter.ticketListTabs.todo",
    defaultMessage: "To do",
  },
  [TicketCenterNonComplianceCaseStatus.CREATED]: {
    id: "warehouse.ticketCenter.ticketListTabs.created",
    defaultMessage: "Pending",
  },
  [TicketCenterNonComplianceCaseStatus.CLOSED]: {
    id: "warehouse.ticketCenter.ticketListTabs.complete",
    defaultMessage: "Complete",
  },
});
