import React from "react";
import { Grid, Text } from "@deliverr/ui";
import { commonMessages } from "facility-commons/labels";
import { FormattedMessage } from "react-intl";
import { quantityMessages } from "../../../content";

interface QuantitySectionProps {
  quantityPerBox: number;
  numberOfBoxes: number;
}

export const QuantitySection: React.FC<QuantitySectionProps> = ({ quantityPerBox, numberOfBoxes }) => (
  <>
    <Text bold>
      <FormattedMessage {...commonMessages.quantities} />
    </Text>
    <Grid columns="1fr auto">
      <Grid gap="S2">
        <Text>
          <FormattedMessage {...quantityMessages.unitsPlaceholder} />
        </Text>
        <Text>
          <FormattedMessage {...quantityMessages.numberOfNc} />
        </Text>
        <Text>
          <FormattedMessage {...quantityMessages.totalUnits} />
        </Text>
      </Grid>
      <Grid gap="S2">
        <Text>{quantityPerBox}</Text>
        <Text>{numberOfBoxes}</Text>
        <Text>{numberOfBoxes * quantityPerBox}</Text>
      </Grid>
    </Grid>
  </>
);
