import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, IconV2, IconV2Types, LocalizedMessage, Title } from "@deliverr/ui";

export type FlashType = "SUCCESS" | "DANGER" | "DEFAULT" | undefined;

enum FlashTypes {
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
  DEFAULT = "DEFAULT",
}

interface Flash {
  type: FlashType;
  icon?: IconV2Types;
  text?: LocalizedMessage;
}

interface FlashProps {
  flash: Flash;
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  type: FlashType;
}

export const StyledFlash = styled.div<Props, DefaultTheme>`
  position: absolute;
  &:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 200ms ease-in-out, border-color 10ms ease-in-out;
    border: 6px solid transparent;
    background-color: transparent;
    pointer-events: none;
    z-index: ${({ theme }) => theme.zindex.Z5};
    ${({ type, theme }) => {
      if (!type) {
        return ``;
      }

      // Chan be either an appearance or color type
      const flashType = FlashTypes[type];
      const colorType = theme.config.colorByAppearance[flashType] ?? (theme.colors[flashType] ? flashType : undefined);
      const color = colorType ? theme.colors[colorType][300] : "transparent";
      return `
        border-color: ${color};
        background-color: ${color};
      `;
    }}
  }
`;

const StyledContent = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: ${theme.zindex.Z6};
  align-items: center;
`
);

const StyledTitle = styled(Title)(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["00"]};

  // Odd sizing in the Figma mockup
  font-size: 3.75rem;
  line-height: 4.5rem;
`
);

export const Flash: React.FC<FlashProps> = ({ flash }) => {
  return (
    <StyledFlash type={flash.type}>
      <StyledContent>
        {flash.icon && <IconV2 type={flash.icon} size="8x" color="white" />}
        {flash.text && <StyledTitle>{flash.text}</StyledTitle>}
      </StyledContent>
    </StyledFlash>
  );
};
