import React from "react";
import { Stack, Text } from "@deliverr/ui";
import styled from "@emotion/styled";

const TextStack = styled(Stack)`
  max-width: 128px;
`;

const EllipsisText = styled(Text)`
  max-width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export interface SkuLineItemProps {
  /** A single barcode or multiple barcodes separated by @field delimiter when specified */
  barcode: string;
  description?: string;
  /** A string used to split multiple barcodes contained in one string */
  delimiter?: string;
}

export const SkuLineItem: React.FC<SkuLineItemProps> = ({ barcode, description, delimiter }) => {
  const barcodes = delimiter ? barcode.split(delimiter) : [barcode];

  return (
    <TextStack gap="S1">
      <div>
        {barcodes.map((code) => (
          <Text bold key={`barcode-${code}`} data-testid="sku-line-item-barcode">
            {code}
          </Text>
        ))}
      </div>
      {description && (
        <EllipsisText appearance="INFO" size="caption">
          {description}
        </EllipsisText>
      )}
    </TextStack>
  );
};
