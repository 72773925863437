/* eslint-disable complexity */
import { TicketCenterOtherNonComplianceResponse } from "@deliverr/commons-clients";
import { WarehouseMessages } from "facility-commons/utils";
import { useIntl } from "react-intl";
import { DashboardDetailInfoListItem } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList/DashboardDetailInfoListItem";
import { OVERVIEW_CARD_LABELS } from "../OverviewCard.labels";
import { OTHER_TICKET_OVERVIEW_CARD_LABELS } from "./OtherTicketOverviewCard.labels";

export interface UseOtherTicketOverviewCardProps {
  ticketDetails?: TicketCenterOtherNonComplianceResponse;
}

export const useOtherTicketOverviewCard = ({ ticketDetails }: UseOtherTicketOverviewCardProps) => {
  const { formatMessage } = useIntl();
  const quantityTitle = formatMessage(OVERVIEW_CARD_LABELS.sectionHeaders.quantities);

  // return "defaults" if ticketDetails haven't loaded
  if (!ticketDetails) {
    return {
      quantityTitle,
      initialInfoList: [],
      quantityList: [],
      otherDetailsList: [],
    };
  }

  const initialInfoList: DashboardDetailInfoListItem[] = [
    {
      id: "poTracking",
      displayValue: formatMessage(OTHER_TICKET_OVERVIEW_CARD_LABELS.poTracking),
      value: ticketDetails.asnId ?? formatMessage(WarehouseMessages.unknown),
    },
    {
      id: "CDSKU",
      displayValue: formatMessage(WarehouseMessages.cdsku),
      value: ticketDetails.cdsku ?? formatMessage(WarehouseMessages.unknown),
    },
    {
      id: "Barcode",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.barcode),
      value: ticketDetails.barcode ?? formatMessage(WarehouseMessages.unknown),
    },
  ];

  // units per box should be undefined if either value is missing
  const numUnitsPerBox: number | undefined =
    ticketDetails.quantity === undefined || !ticketDetails.numberOfBoxes
      ? undefined
      : ticketDetails.quantity / ticketDetails.numberOfBoxes;

  const quantityList: DashboardDetailInfoListItem[] = [
    {
      id: "numUnitsPerBox",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.unitsPerBox),
      value: numUnitsPerBox || formatMessage(OTHER_TICKET_OVERVIEW_CARD_LABELS.unknownNumber),
    },
    {
      id: "numNcBoxes",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.nonCompliantBoxes),
      value: ticketDetails.numberOfBoxes || formatMessage(OTHER_TICKET_OVERVIEW_CARD_LABELS.unknownNumber),
    },
    {
      id: "numTotalUnits",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.totalUnits),
      value: ticketDetails?.quantity || formatMessage(OTHER_TICKET_OVERVIEW_CARD_LABELS.unknownNumber),
    },
  ];

  const otherDetailsList: DashboardDetailInfoListItem[] = [
    {
      id: "isRestricted",
      displayValue: formatMessage(WarehouseMessages.restricted),
      value: ticketDetails.isRestricted ? formatMessage(WarehouseMessages.yes) : formatMessage(WarehouseMessages.no),
    },
  ];

  // only show email if it's been provided
  if (ticketDetails.warehouseContactEmail) {
    otherDetailsList.push({
      id: "email",
      displayValue: formatMessage(WarehouseMessages.email),
      value: ticketDetails.warehouseContactEmail ?? formatMessage(WarehouseMessages.unknown),
    });
  }

  return {
    initialInfoList,
    quantityTitle,
    quantityList,
    otherDetailsList,
  };
};
