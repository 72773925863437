import { SetterOrUpdater } from "recoil";
import { trim, toUpper } from "lodash/fp";
import { ScanResponse, ScanResponseType } from "@deliverr/commons-clients";

const SCAN_INPUT_LENGTH_THRESHOLD = 2;

// a scan is inputted instantaneously one character a time
// a user typing in a cdsku is very unlikely to enter more than 2-3 chars before the debounce
// so we consider a difference in new value and old value >= 5 to be a scan input
export function isScanInput(newValue: string, oldValue: string): boolean {
  return newValue.length - oldValue.length >= SCAN_INPUT_LENGTH_THRESHOLD;
}

// upper = convert to uppercase
// preserve = dont change case
export type CaseFormat = "upper" | "preserve";

const trimAndFormat = (value: string, format: CaseFormat) => {
  const trimmedValue = trim(value);
  return format === "upper" ? toUpper(trimmedValue) : trimmedValue;
};

// format, update, and submit if scan
export function onScannerInputChange(
  oldValue: string,
  update: SetterOrUpdater<string>,
  submit: (newValue: string) => void,
  caseFormat: CaseFormat = "preserve"
) {
  return async (newValue: string) => {
    const formattedValue = trimAndFormat(newValue, caseFormat);

    update(formattedValue);
    if (isScanInput(formattedValue, oldValue)) {
      await submit(formattedValue);
    }
  };
}

export function genericOnScannerInputChange(
  oldValue: string,
  update: (newValue: string) => void,
  submit: (newValue: string) => Promise<any>,
  caseFormat: CaseFormat = "preserve"
) {
  return async (newValue: string) => {
    const formattedValue = trimAndFormat(newValue, caseFormat);
    update(formattedValue);

    if (isScanInput(formattedValue, oldValue)) {
      await submit(formattedValue);
    }
  };
}

// Full FedEx tracking codes are 34 digits, but only the last 12 are useful to us.
// https://deliverr.slack.com/archives/G010V8MB8UA/p1597772270026200?thread_ts=1597365895.047900&cid=G010V8MB8UA
export function sliceFedExTrackingCode(trackingCode: string): string {
  return trackingCode.slice(-12);
}

export const getSortToWarehouseProps = (scanResponse: ScanResponse) => {
  switch (scanResponse.action) {
    case ScanResponseType.SORT_TO_PALLET_BUILD_STATION:
      return { destinationWarehouseId: scanResponse.palletBuildStationBarcode };
    case ScanResponseType.SORT_TO_WAREHOUSE:
      return { destinationWarehouseId: scanResponse.transferToWarehouseId };
    default:
      return {};
  }
};
