import React from "react";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { Box } from "@deliverr/ui/lib-esm/Box";
import { Appearance, BoxReset, Button, Notification, ThemeProps } from "@deliverr/ui";
import { useLocationCardV2 } from "./useLocationCardV2";
import styled from "@emotion/styled";
import { DarkerCardLabelText } from "facility-commons/components/emotion";
import { TextInputCard } from "facility-commons/flow";
import { ReceivedSkus } from "./ReceivedSkus";

export const IconContainer = styled.button<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE[300]};
`
);
export const LocationCardV2 = () => {
  const {
    handleChange,
    loading,
    locationInput,
    receivingError,
    previouslyReceivedQuantity,
    previousReceives,
    submitButtonText,
    title,
    enterLocation,
    disableConfirmBtn,
    disableLocationInput,
    isSettingDefaultLocation,
    showRecommendedReceiveZone,
    zoneRecommendationMsg,
    submitReceive,
    setDefaultLocation,
  } = useLocationCardV2();

  return (
    <>
      {showRecommendedReceiveZone && !isSettingDefaultLocation && (
        <BoxReset>
          <Notification
            appearance={Appearance.PRIMARY}
            content={{
              description: zoneRecommendationMsg,
            }}
          />
        </BoxReset>
      )}
      <FacilityCard as="section">
        <Box marginTop={0}>
          <DarkerCardLabelText>{title}</DarkerCardLabelText>
          <TextInputCard
            errorMessage={receivingError}
            newest
            onChange={handleChange}
            onSubmit={isSettingDefaultLocation ? setDefaultLocation : submitReceive}
            placeholder={{
              id: "warehouse.receiving.stowLocationPlaceholder",
              defaultMessage: "Receive location",
            }}
            title={enterLocation}
            value={locationInput}
            disabled={disableLocationInput}
            embedded
            data-testid="location-card-input"
          >
            {previouslyReceivedQuantity > 0 && <ReceivedSkus previousReceives={previousReceives} />}
          </TextInputCard>
        </Box>
      </FacilityCard>
      <Box marginTop="S4">
        <Button
          block
          disabled={disableConfirmBtn}
          onClick={isSettingDefaultLocation ? setDefaultLocation : submitReceive}
          data-testid="submit-receive-btn"
          loading={loading}
        >
          {submitButtonText}
        </Button>
      </Box>
    </>
  );
};
