import { MonthDayYearRegex } from "./dateUtils";
import { lastDayOfMonth } from "date-fns";

export enum DateErrorType {
  INVALID_DATE = "INVALID_DATE",
  INVALID_DAY = " INVALID_DAY",
  INVALID_MONTH = "INVALID_MONTH",
  INVALID_YEAR = "INVALID_YEAR",
}

interface DateErrorResponse {
  error: DateErrorType | undefined;
  dateParts?: string[];
  lastDateOfMonth?: number;
}

export const isValidDate = (
  date: string,
  yearValidationDetails: { minYear: number; maxYear: number } | null = null
): DateErrorResponse => {
  let error;

  if (!MonthDayYearRegex.test(date)) {
    error = DateErrorType.INVALID_DATE;
    return { error };
  }

  const dateParts = date.split("/");
  // date parts are parsed to integers to allow for numerical comparison
  const month = parseInt(dateParts[0]);
  const day = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);
  const lastDateOfMonth = lastDayOfMonth(new Date(year, month - 1)).getDate();

  if (month <= 0 || month > 12) {
    error = DateErrorType.INVALID_MONTH;
  }

  // subtract 1 from the month value because the months are 0 indexed when working with Date object
  if (day <= 0 || day > lastDateOfMonth) {
    error = DateErrorType.INVALID_DAY;
  }

  // prevent entries of years that are most likely typos
  if (yearValidationDetails) {
    const { minYear, maxYear } = yearValidationDetails;
    if (year < minYear || year > maxYear) {
      error = DateErrorType.INVALID_YEAR;
    }
  }

  return { error, dateParts, lastDateOfMonth };
};
