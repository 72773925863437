import React from "react";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { useReturnBoxScan } from "./useReturnBoxScan";
import { ItemDetails } from "../ItemDetails";
import { PointerAnchor } from "facility-commons/components/emotion";

export const ReturnBoxScan: React.FC = () => {
  const {
    messages,
    barcode,
    dsku,
    itemName,
    returnsLocation,
    boxBarcodeError,
    handleChange,
    addItemToRTMBox,
    openNewRTMBox,
  } = useReturnBoxScan();
  return (
    <>
      <ItemDetails dsku={dsku} scannedBarcode={barcode} itemName={itemName} />
      <StackedInputCard
        illustration={<ScanBox />}
        illustrationTitle={messages.returnBoxScanTitle}
        title={messages.returnBoxScanInstructions}
        newest
        onChange={handleChange}
        onSubmit={addItemToRTMBox}
        autoFocus
        secondary
        placeholder={messages.returnBoxScanInputPlaceholder}
        value={returnsLocation}
        errorMessage={boxBarcodeError}
      >
        <PointerAnchor onClick={openNewRTMBox}>{messages.createNewRtmBox}</PointerAnchor>
      </StackedInputCard>
    </>
  );
};
