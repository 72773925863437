import React from "react";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Appearance, Button, Grid, Text } from "@deliverr/ui";
import { SoundFx } from "facility-commons/common/sfx";
import { useReturnReceiveCloseReturnOrderModal } from "./useReturnReceiveCloseReturnOrderModal";

export const ReturnReceiveCloseReturnOrderModal: React.FC = () => {
  const { messages, closeReturnOrderError, handleCloseReturnOrder, onCancel } = useReturnReceiveCloseReturnOrderModal();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={messages.title}
      text={messages.text}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
    >
      <Grid gap="S2" fullWidth>
        <Text appearance={Appearance.DANGER} size={"label"}>
          {closeReturnOrderError}
        </Text>
        <Button block data-testid="close-return-order-confirm-btn" onClick={handleCloseReturnOrder}>
          {messages.confirm}
        </Button>
        <Button block onClick={onCancel} secondary>
          {messages.back}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
