import React from "react";
import { HazmatInformation } from "@deliverr/commons-clients/lib/product/HazmatInformation";
import { Anchor, Grid, Input, Title } from "@deliverr/ui";

import { DSKULength } from "facility-commons/utils/config";
import { useHazmat } from "./useHazmat";
import { HazmatFormField } from "./HazmatForm.types";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { FlowCardTitleContainer } from "facility-commons/flow/cards/FlowCard";
import { FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const HazmatFiles: React.FC<{ hazmat?: Partial<HazmatInformation> }> = ({ hazmat }) => {
  if (!hazmat) {
    return null;
  }
  if (!hazmat.sdsUri && !hazmat.testingDocumentUri) {
    return <p>No files found for this dsku</p>;
  }
  return (
    <Grid>
      <p>Status: {hazmat.status}</p>
      {hazmat.sdsUri && (
        <Anchor href={`${process.env.REACT_APP_STATIC_ASSETS}${hazmat?.sdsUri}`}>View MSDS Document</Anchor>
      )}
      {hazmat.testingDocumentUri && (
        <Anchor href={`${process.env.REACT_APP_STATIC_ASSETS}${hazmat?.testingDocumentUri}`}>
          View UN Test Summary
        </Anchor>
      )}
    </Grid>
  );
};

const Form = Grid.withComponent("form");

export const Hazmat: React.FC = () => {
  const { formHandler, register, errors, hazmat } = useHazmat();
  return (
    <FacilityCard>
      <Grid gap="S4">
        <FormProvider {...formHandler}>
          <Form gap="S4">
            <FlowCardTitleContainer>
              <Title as="h3" displayAs="h3" style={{ marginLeft: ".5rem" }}>
                Scan or type DSKU
              </Title>
            </FlowCardTitleContainer>
            <Input
              {...register[HazmatFormField.BARCODE]}
              minLength={DSKULength}
              maxLength={DSKULength}
              placeholder="HAZMAT barcode"
              hasError={!!errors[HazmatFormField.BARCODE]}
              errorMessage={<ErrorMessage errors={errors} name={HazmatFormField.BARCODE} />}
            />
          </Form>
        </FormProvider>
        <HazmatFiles hazmat={hazmat} />
      </Grid>
    </FacilityCard>
  );
};
