import { defineMessages, MessageDescriptor } from "react-intl";

export enum LabelLimitErrorErrorMessageKey {
  LABEL_LIMIT = "LABEL_LIMIT",
}

export const LABEL_LIMIT_ERROR_BY_ERROR_CODE: Record<
  LabelLimitErrorErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [LabelLimitErrorErrorMessageKey.LABEL_LIMIT]: {
    id: "warehouse.receiving.labelLimitError",
    defaultMessage: "Cannot reserve fewer than 1 or more than {limit} labels.",
  },
});
