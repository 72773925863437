import React from "react";
import { Button, DefaultTheme, IconV2, Text, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useParams } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { WarehousePortalRoutesNames } from "warehouse/routes";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { useLifecycles } from "react-use";
import { useIntl } from "react-intl";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { setProp } from "facility-commons/utils";

const InfoText = styled(Text)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
`
);

const StyledLink = styled(Link)<LinkProps, DefaultTheme>(
  ({ theme }) => `
  display: block;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing.S5};
  }
`
);

const StyledSuccessContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 32rem;
  padding-bottom: ${theme.spacing.S5};
  padding-left: ${theme.spacing.S5};
  padding-right: ${theme.spacing.S5};
  text-align: center;
`
);

const IconContainer = styled.div`
  background-color: #00c89a;
  border-radius: 100%;
  margin-bottom: 1.25rem;
  padding: 1rem;
`;

const CaseNumberContainer = styled.div`
  background-color: #def8f2;
  color: #00c89a;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
  padding: 0.75rem 2.75rem;
`;

export const NewTicketSuccess: React.FC = () => {
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const { formatMessage } = useIntl();

  const title = formatMessage({
    id: "warehouse.newTicketSuccess.title",
    defaultMessage: "Non-Compliance",
  });

  useLifecycles(
    () => {
      setWarehouseAppState(setProp("pageTitle", title));
    },
    () => {
      setWarehouseAppState(setProp("pageTitle", ""));
    }
  );

  return (
    <FacilityCard>
      <StyledSuccessContainer>
        <Title as="h3" displayAs="h3">
          Submission Success
        </Title>

        <InfoText appearance="INFO">Your ticket was successfully submitted.</InfoText>

        <IconContainer>
          <IconV2 inverse={true} type="check" size={"3x"} transform={"shrink-2"} />
        </IconContainer>

        <CaseNumberContainer>CASE # {caseNumber}</CaseNumberContainer>

        <InfoText appearance="INFO">
          Your ticket will be sent to Deliverr Support and you will be notified via email for next steps on how to
          proceed.
        </InfoText>

        <StyledLink to={`/warehouse/${WarehousePortalRoutesNames.TICKET_CENTER}`}>
          <Button block>Home</Button>
        </StyledLink>

        <StyledLink to="/warehouse/tickets/new">
          <Button block secondary>
            New Ticket
          </Button>
        </StyledLink>
      </StyledSuccessContainer>
    </FacilityCard>
  );
};
