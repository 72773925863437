import { Button } from "@deliverr/ui";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ModalTemplate, warningModalIcon } from "facility-commons";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

export interface DeleteTicketModalProps {
  handleDeleteCase: () => any;
}

export const DeleteTicketModal: React.FC<DeleteTicketModalProps> = ({ handleDeleteCase }) => {
  const { hideModal } = useWarehouseModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="warehouse.deleteTicketModal.title" defaultMessage="Delete ticket" />}
      text={
        <FormattedMessage
          id="warehouse.deleteTicketModal.text"
          defaultMessage="Deleting this ticket will remove it from Deliverr's queue and it will not be resolved."
        />
      }
      iconProps={warningModalIcon}
    >
      <Button secondary onClick={() => hideModal(WarehouseModal.DELETE_TICKET)}>
        <FormattedMessage id="warehouse.deleteTicketModal.keepTicketButton" defaultMessage="Keep ticket" />
      </Button>
      <Button appearance="DANGER" onClick={handleDeleteCase}>
        <FormattedMessage id="warehouse.deleteTicketModal.deleteTicketButton" defaultMessage="Delete ticket" />
      </Button>
    </ModalTemplate>
  );
};
