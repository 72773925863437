import {
  TicketCenterMissingBarcodeCaseResponse,
  TicketCenterMissingBarcodeReceivingInfo,
  TicketCenterOtherNonComplianceResponse,
  TicketCenterUnknownBarcodeCaseResponse,
} from "@deliverr/commons-clients";
import { BarcodePrintData, LabelLayoutType } from "@deliverr/legacy-inbound-client";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { initiateDownloadFromUrl, LocalizedMessage, toast } from "@deliverr/ui";
import { userState } from "facility-commons/base/Auth/userState";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { createDangerNotification, log, logError, logger, logStart, setProp } from "facility-commons/utils";
import { isNil } from "lodash";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { WarehouseDynamicRoutes } from "warehouse/routes";
import { TicketDetailResponse } from "../types";
import { getMultipleS3Files } from "facility-commons";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { nonComplianceClient } from "facility-commons/base/Clients";
import { TicketCenterInvalidLotExpiryCaseResponse } from "@deliverr/commons-clients";

type Endpoint = (caseId: number) => Promise<void>;

const deleteTicketByType: Partial<Record<NonComplianceType, Endpoint>> = {
  [NonComplianceType.UNKNOWN_BARCODE]: nonComplianceClient.markCaseInactive,
  [NonComplianceType.MISSING_BARCODE]: nonComplianceClient.markMissingBarcodeCaseInactive,
  [NonComplianceType.INVALID_LOT_EXPIRY]: nonComplianceClient.markInvalidLotExpiredCaseAsCancelled,
  [NonComplianceType.OTHER]: nonComplianceClient.markOtherNonComplianceInactive,
};

const rollbackCloseByType: Partial<Record<NonComplianceType, Endpoint>> = {
  [NonComplianceType.UNKNOWN_BARCODE]: nonComplianceClient.rollbackCompletedByWarehousev2,
  [NonComplianceType.MISSING_BARCODE]: nonComplianceClient.rollbackMissingBarcodeCaseCompletion,
  [NonComplianceType.INVALID_LOT_EXPIRY]: nonComplianceClient.rollbackCompletedInvalidLotExpiredCase,
  [NonComplianceType.OTHER]: nonComplianceClient.rollbackOtherNonComplianceCaseCompletion,
};

const markClosedByType: Partial<Record<NonComplianceType, Endpoint>> = {
  [NonComplianceType.UNKNOWN_BARCODE]: nonComplianceClient.markCaseAsCompletedByWarehousev2,
  [NonComplianceType.MISSING_BARCODE]: nonComplianceClient.completeMissingBarcodeCase,
  [NonComplianceType.INVALID_LOT_EXPIRY]: nonComplianceClient.markInvalidLotExpiredCaseAsCompleted,
  [NonComplianceType.OTHER]: nonComplianceClient.completeOtherNonComplianceCase,
};

interface TicketDetailsAction {
  fn: string;
  action: Endpoint;
  errorMessage: LocalizedMessage;
  successMessage: LocalizedMessage;
  onComplete?: () => void;
}

export const useTicketDetails = <T extends TicketDetailResponse>() => {
  const { formatMessage } = useIntl();
  const { ticketId, ticketType } = useParams<{ ticketId: string; ticketType: NonComplianceType }>();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { warehouseId } = useRecoilValue(userState);
  const { history, match, push } = useRouter();
  const { showModal, hideModal } = useWarehouseModal();
  const { inboundClient, productClient } = useClientsWithAuth();
  const { addAutoCloseNotification, errorResponse } = useCommonFlow();

  const { value: ticketDetails, loading } = useAsync<() => Promise<T>>(async () => {
    const parsedTicketId = parseInt(ticketId, 10);
    const ticketDetail = await nonComplianceClient.getSingleTicketCenterCase(ticketType, warehouseId, parsedTicketId);

    const imagesToDownload = Object.fromEntries(ticketDetail.pictures.map((ticket, index) => [index, ticket]));
    const imagesUrls = await getMultipleS3Files({ [ticketDetail.id]: imagesToDownload }).catch(() => {
      errorResponse();
      addAutoCloseNotification(
        createDangerNotification(
          formatMessage({
            id: "nonCompliance.error.gettingImg",
            defaultMessage: "Error while getting your images. Please try again.",
          })
        )
      );
    });

    return { ...ticketDetail, pictures: Object.values(imagesUrls[ticketDetail.id]) } as T;
  }, []);

  const ticketDetailsAction = async ({ fn, action, errorMessage, successMessage, onComplete }: TicketDetailsAction) => {
    const ctx = logStart({ fn, ticketId, ticketType });
    try {
      log(ctx, `starting ${fn}`, { ticketId });
      await action(parseInt(ticketId, 10));
      log(ctx, `successfully completed ${fn}`, { ticketId });
      toast.success(successMessage);
    } catch (err: any) {
      logError(ctx, new Error(err));
      toast.error(errorMessage);
    } finally {
      if (onComplete) {
        onComplete();
      }

      history.goBack();
    }
  };

  const downloadBarcodes = async () => {
    const ctx = logStart({
      fn: "missingBarcodeResolutionCard.downloadBarcodes",
      value: ticketDetails,
    });

    try {
      switch (ticketDetails?.type) {
        case NonComplianceType.MISSING_BARCODE:
        case NonComplianceType.UNKNOWN_BARCODE:
          const ticket = ticketDetails as
            | TicketCenterMissingBarcodeCaseResponse
            | TicketCenterUnknownBarcodeCaseResponse;
          const receivingInfo = ticket.receivingInfo!;
          if (isNil(receivingInfo) || isNil(receivingInfo.dsku)) {
            logError(ctx, new Error("Failed to download barcodes. Ticket details are receiving info."));
            toast.error(
              formatMessage({
                id: "warehouse.missingBarcodeResolutionCard.downloadBarcodes.receivingInfoEmpty",
                defaultMessage: "Failed to download barcodes. Ticket details are receiving info.",
              })
            );
            return;
          }

          const product = await productClient.getProductAuth0(receivingInfo.dsku!);
          if (isNil(product)) {
            logError(ctx, new Error("Failed to download barcodes. Could not fetch the product."));
            toast.error(
              formatMessage({
                id: "warehouse.missingBarcodeResolutionCard.downloadBarcodes.failedProductFetch",
                defaultMessage: "Failed to download barcodes. Could not fetch the product.",
              })
            );
            return;
          }

          const barcode = (receivingInfo as TicketCenterMissingBarcodeReceivingInfo).barcode ?? receivingInfo.dsku;
          const barcodeConfig: BarcodePrintData[] = [
            {
              barcode,
              name: product.name,
              qty: ticketDetails!.quantity!,
            },
          ];

          const pdf = await inboundClient.downloadBarcodesPdf(LabelLayoutType.AVERY_30_UP, barcodeConfig);
          const fileName = `${barcode}.pdf`;
          initiateDownloadFromUrl(URL.createObjectURL(new Blob([pdf], { type: "application/pdf" })), fileName);
          log(ctx, "downloaded barcodes");
          return;
        case NonComplianceType.OTHER:
        case NonComplianceType.INVALID_LOT_EXPIRY:
          const errorMessage = formatMessage(
            {
              id: "warehouse.barcodes.cannotDownload",
              defaultMessage: "Cannot download barcodes for {ticketType} tickets",
            },
            { ticketType: ticketDetails.type }
          );

          logger.warn(ctx, errorMessage);
          toast.error(errorMessage);
          return;
        case undefined:
        default:
          logger.warn(ctx, "Ticket or ticket type is undefined");
          return;
      }
    } catch (err: any) {
      logError(ctx, new Error(err));
      toast.error(
        formatMessage({
          id: "warehouse.missingBarcodeResolutionCard.downloadBarcodes.generalError",
          defaultMessage: "Failed to download barcodes",
        })
      );
    }
  };

  const rollbackClose = async () => {
    const rollbackAction = async () => {
      const rollbackEndpoint = rollbackCloseByType[ticketType]!.bind(nonComplianceClient);
      await rollbackEndpoint(parseInt(ticketId, 10));
      const todoCases = await nonComplianceClient.getTicketCenterCasesByWarehouseIdTodoStatus(warehouseId);
      setWarehouseAppState(setProp("todoCount", todoCases?.length ?? 0));
    };

    const config: TicketDetailsAction = {
      fn: "useTicketDetails.rollbackClose",
      action: rollbackAction,
      errorMessage: formatMessage({
        id: "warehouse.rollbackClose.errorToast",
        defaultMessage: "Failed to roll back this ticket",
      }),
      successMessage: formatMessage(
        {
          id: "warehouse.rollbackClose.successToast",
          defaultMessage: "Case #{ticketId} has been reopened",
        },
        { ticketId }
      ),
    };

    await ticketDetailsAction(config);
  };

  const viewSupportTicket = () => {
    const ctx = { fn: "useTicketDetails.viewSupportTicket", ticketId, ticketType };
    if (!ticketDetails) {
      logger.warn(ctx, "Ticket details are undefined");
      return;
    }

    switch (ticketType) {
      case NonComplianceType.MISSING_BARCODE:
      case NonComplianceType.UNKNOWN_BARCODE:
        const barcodeTicket = ticketDetails as
          | TicketCenterUnknownBarcodeCaseResponse
          | TicketCenterMissingBarcodeCaseResponse;
        if (barcodeTicket.receivingInfo?.ticketUrl) {
          window.open(barcodeTicket.receivingInfo.ticketUrl, "_blank");
        }
        return;
      case NonComplianceType.INVALID_LOT_EXPIRY:
        const lotFefoTicket = ticketDetails as TicketCenterInvalidLotExpiryCaseResponse;
        if (lotFefoTicket.ticketUrl) {
          window.open(lotFefoTicket.ticketUrl, "_blank");
        }
        return;
      case NonComplianceType.OTHER:
        const otherTicket = ticketDetails as TicketCenterOtherNonComplianceResponse;
        if (otherTicket.ticketUrl) {
          window.open(otherTicket.ticketUrl, "_blank");
        }
        return;
      default:
        const errorMessage = "Ticket type is not supported";
        toast.error(errorMessage);
        logError(ctx, new Error(errorMessage));
        return;
    }
  };

  const markClosed = async () => {
    const markedCloseAction = async () => {
      const markClosedEndpoint = markClosedByType[ticketType]!.bind(nonComplianceClient);
      await markClosedEndpoint(parseInt(ticketId, 10));
    };

    const config: TicketDetailsAction = {
      fn: "useTicketDetails.markClosed",
      action: markedCloseAction,
      errorMessage: formatMessage({
        id: "warehouse.markClosed.errorToast",
        defaultMessage: "Failed to mark this ticket closed",
      }),
      successMessage: formatMessage(
        {
          id: "warehouse.markClosed.successToast",
          defaultMessage: "Case #{ticketId} has been marked closed",
        },
        { ticketId }
      ),
    };

    await ticketDetailsAction(config);
  };

  const handleThumbnailClick = (event: React.MouseEvent<HTMLImageElement>) =>
    showModal(WarehouseModal.TICKET_CENTER_THUMBNAIL, { link: event.currentTarget.getAttribute("src") });

  const deleteCase = async () => {
    const deleteCaseAction = async () => {
      const deleteEndpoint = deleteTicketByType[ticketType]!.bind(nonComplianceClient);
      await deleteEndpoint(parseInt(ticketId, 10));
    };

    const config: TicketDetailsAction = {
      fn: "useTicketDetails.handleDeleteCase",
      action: deleteCaseAction,
      errorMessage: formatMessage({
        id: "warehouse.deleteTicketModal.errorToast",
        defaultMessage: "Failed to delete this ticket",
      }),
      successMessage: formatMessage(
        {
          id: "warehouse.deleteTicketModal.successToast",
          defaultMessage: "Case #{ticketId} has been deleted.",
        },
        {
          ticketId,
        }
      ),
      onComplete: () => hideModal(WarehouseModal.DELETE_TICKET),
    };

    await ticketDetailsAction(config);
  };

  const showDeleteModal = () => {
    showModal(WarehouseModal.DELETE_TICKET, { ticketId, handleDeleteCase: deleteCase });
  };

  const routeToUnsolvableDetails = () => {
    push(WarehouseDynamicRoutes.TICKET_UNSOLVABLE_DETAILS.parse(match.params));
  };

  return {
    ticketDetails,
    loading,
    showDeleteModal,
    rollbackClose,
    markClosed,
    handleThumbnailClick,
    viewSupportTicket,
    downloadBarcodes,
    routeToUnsolvableDetails,
  };
};
