import { defineMessages } from "react-intl";

export const boxMessages = defineMessages({
  cdskuUnknown: {
    id: "warehouse.nonCompliance.cdskuUnknown",
    defaultMessage: "Can't find the CDSKU?",
  },
  poUnknown: {
    id: "warehouse.nonCompliance.poUnknown",
    defaultMessage: "Can't find the PO #?",
  },
  cdskuUnknownProblemSolver: {
    id: "warehouse.nonCompliance.cdskuUnknownProblemSolver",
    defaultMessage: "If there is no CDSKU or it's illegible, set the box aside for a problem solver.",
  },
});
