import { useIntl } from "react-intl";
import { modalsText } from "../modalsText";
import { returnReceiveReturnOrderStateAtom } from "warehouse/receiving/ReturnReceive/state";
import { useRecoilValue } from "recoil";
import { sumBy } from "lodash";
import { useAsyncFn } from "react-use";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useReturnReceiveFlow } from "warehouse/receiving/ReturnReceive/components/useReturnReceiveFlow";
import { createReturnReceiveTrackingCodeScan } from "warehouse/receiving/ReturnReceive/components/returnReceiveCardCreators";
import { useState } from "react";
import { useWarehouseModal } from "warehouse/modal";
import { log, logStart } from "facility-commons";
import { createClosedReturnOrderSuccess } from "warehouse/receiving/base";

export const useReturnReceiveCloseReturnOrderModal = () => {
  const { formatMessage } = useIntl();
  const { successResponse, errorResponse, addNotification } = useCommonFlow();
  const { hideAllModals } = useWarehouseModal();
  const { nextFlowCard } = useReturnReceiveFlow();
  const { returnsClient } = useClientsWithAuth();
  const [closeReturnOrderError, setCloseReturnOrderError] = useState<string>("");

  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);

  const { trackingCode, orderId, expectedItems, inspectedItems } = returnOrderData;

  const notAllExpectedItemsInspected =
    sumBy(expectedItems, (item) => item.quantity) > sumBy(inspectedItems, (item) => item.quantity || 0);

  const [submitState, handleCloseReturnOrder] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "useReturnReceiveCloseReturnOrderModal.handleCloseReturnOrder", orderId });
    try {
      await returnsClient.markReturnProcessed(orderId!);
      successResponse();
      addNotification(createClosedReturnOrderSuccess(trackingCode));
      hideAllModals();
      nextFlowCard(createReturnReceiveTrackingCodeScan({}));
    } catch (error: any) {
      log({ ...ctx, error }, "error closing return order");
      errorResponse();
      setCloseReturnOrderError(String(error.message));
    }
  }, [orderId]);

  const onCancel = () => {
    hideAllModals();
  };

  const messages = {
    title: notAllExpectedItemsInspected
      ? formatMessage(modalsText.moreUnitsExpected)
      : formatMessage(modalsText.returnReceiveCloseReturnOrderTitle),
    text: notAllExpectedItemsInspected
      ? formatMessage(modalsText.returnReceiveNotAllExpectedItemsInspected, { trackingCode })
      : formatMessage(modalsText.returnReceiveTrackingCode, { trackingCode }),
    confirm: formatMessage(modalsText.confirm),
    back: formatMessage(modalsText.back),
  };

  return {
    messages,
    submitting: submitState.loading,
    closeReturnOrderError,
    handleCloseReturnOrder,
    onCancel,
  };
};
