import React from "react";
import { useIntl } from "react-intl";

import { TextInputCard } from "facility-commons/flow/cards/TextInputCard";
import { useArrivalScanner } from "../../useArrivalScanner";
import { WarehouseMessages } from "facility-commons/utils";

export interface ArrivalScannerCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const ArrivalScannerCard: React.FC<ArrivalScannerCardProps> = ({ disabled, newest }) => {
  const { scanInput, errorMessage, handleInputChange, submitArrivalScan, loading } = useArrivalScanner();
  const { formatMessage } = useIntl();
  const onSubmit = () => {
    submitArrivalScan(scanInput, errorMessage);
  };

  return (
    <TextInputCard
      onSubmit={onSubmit}
      newest={newest}
      title={formatMessage(WarehouseMessages.palletTitleText)}
      message={formatMessage(WarehouseMessages.palletMessageText)}
      onChange={handleInputChange}
      value={scanInput}
      placeholder={formatMessage(WarehouseMessages.barcode)}
      disabled={disabled}
      loading={loading}
      helpText={formatMessage(WarehouseMessages.palletHelpText)}
      size="sm"
    />
  );
};
