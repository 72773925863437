import React from "react";
import { TicketCenterNonComplianceCaseStatus, TicketCenterOtherNonComplianceResponse } from "@deliverr/commons-clients";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { CenteredSpinner } from "./CenteredSpinner";
import { Box } from "@deliverr/ui";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { ZendeskCard } from "./ZendeskCard";
import { ResolutionCardActionSection } from "./ResolutionCardActionSection";
import { TicketCardPlaceholder } from "warehouse/ticket-center/common/components/TicketCardPlaceholder";

export const OtherTicketResolutionCard: React.FC = () => {
  const { loading, ticketDetails, viewSupportTicket, markClosed, rollbackClose, downloadBarcodes } = useTicketDetails<
    TicketCenterOtherNonComplianceResponse
  >();

  // While we should always have a ticketUrl in production, we need to handle the case where
  // it's not available in testing data or staging. Also due to typings in the client.
  const showNoDetailsPlaceholder =
    ticketDetails?.status === TicketCenterNonComplianceCaseStatus.CREATED || !ticketDetails?.ticketUrl;
  return loading || !ticketDetails ? (
    <CenteredSpinner loading />
  ) : (
    <Box height="100%">
      <DashboardDetailCard
        createdAt={new Date(ticketDetails.createdAt)}
        placeholder={showNoDetailsPlaceholder ? <TicketCardPlaceholder /> : undefined}
      >
        {ticketDetails.ticketUrl && <ZendeskCard zendeskUrl={ticketDetails.ticketUrl} />}
      </DashboardDetailCard>
      <ResolutionCardActionSection
        isTicketUrlEmpty={!ticketDetails.ticketUrl}
        viewSupportTicket={viewSupportTicket}
        shouldShowPrintBarcodesButton={false}
        downloadBarcodes={downloadBarcodes}
        markClosed={markClosed}
        rollbackClose={rollbackClose}
        ticketDetails={ticketDetails}
        // We don't have the info in the back-end at the moment to determine this,
        // so we will assume it can always be re-opened - Matthew, 6/3/22
        canBeReopened
      />
    </Box>
  );
};
