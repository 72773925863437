import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";

import { NCRoutes } from "../../base/routes";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { genericOnScannerInputChange, logStart } from "facility-commons/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useRecoilValue } from "recoil";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const useStorageLocationCard = () => {
  const [locationError, setLocationError] = useState<string>("");
  const { location = "" } = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();

  const updateLocation = (locationValue: string) => {
    setLocationError("");
    updateCurrentProduct("location", locationValue);
  };

  const proceedToFollowUp = () => push(NCRoutes.DETAILS);

  const [{ loading }, handleSubmit] = useAsyncFn(async (locationValue: string) => {
    logStart({ fn: "useStorageLocationCard.handleSubmit", location: locationValue });

    if (!locationValue) {
      errorResponse();
      return setLocationError(formatMessage(commonWarehouseMessages.fieldRequired));
    }

    successResponse();
    proceedToFollowUp();
  });

  const handleChange = genericOnScannerInputChange(location, updateLocation, handleSubmit);

  return {
    location,
    locationError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
  };
};
