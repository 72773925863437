import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { setProp } from "facility-commons";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useAsyncFn } from "react-use";
import { useRecoilState } from "recoil";
import { InspectionNotesType } from "warehouse/receiving/ReturnReceive/types/InspectionNotesType";
import { useIntl } from "react-intl";
import { modalsText } from "../modalsText";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "warehouse/receiving/ReturnReceive/state";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";

export const useReturnReceiveInspectionNotesModal = (noteType: InspectionNotesType) => {
  const { formatMessage } = useIntl();
  const { hideModal } = useWarehouseModal();
  const { errorResponse, successResponse } = useCommonFlow();
  const [returnOrderData, setReturnOrderData] = useRecoilState(returnReceiveReturnOrderStateAtom);
  const [itemInspectionData, setItemInspectionData] = useRecoilState(returnReceiveItemInspectionStateAtom);
  const { returnsClient } = useClientsWithAuth();
  const { orderInspectionNotes, orderId } = returnOrderData;
  const { itemInspectionNotes } = itemInspectionData;

  const isAddingReturnOrderNotes = noteType === InspectionNotesType.RETURN_ORDER;
  const [inspectionNotesInput, setInspectionNotesInput] = useState<string>(
    isAddingReturnOrderNotes ? orderInspectionNotes : itemInspectionNotes
  );
  const [inspectionNotesError, setInspectionNotesError] = useState<string>("");

  const trimmedInspectionNotes = inspectionNotesInput.trim();

  const handleInspectionNoteInputChange = (notesInput: string) => {
    setInspectionNotesError("");
    setInspectionNotesInput(notesInput);
  };

  const [submitState, submitReturnOrderNotes] = useAsyncFn(async () => {
    try {
      await returnsClient.saveReturnInspectionNotes(orderId!.toString(), trimmedInspectionNotes);
      setReturnOrderData(setProp("orderInspectionNotes", trimmedInspectionNotes));
      successResponse();
      hideModal(WarehouseModal.RETURN_RECEIVE_INSPECTION_NOTES);
    } catch (error) {
      errorResponse();
      setInspectionNotesError(formatMessage(modalsText.returnReceiveNotesSubmissionError, { error: String(error) }));
    }
  }, [orderId, inspectionNotesInput]);

  const onSubmitInspectionNotes = () => {
    setInspectionNotesError("");
    if (isAddingReturnOrderNotes) {
      submitReturnOrderNotes();
    } else {
      // item inspection notes will be submitted at the end of the flow
      setItemInspectionData(setProp("itemInspectionNotes", trimmedInspectionNotes));
      successResponse();
      hideModal(WarehouseModal.RETURN_RECEIVE_INSPECTION_NOTES);
    }
  };

  const onCloseModal = () => {
    setInspectionNotesError("");
    hideModal(WarehouseModal.RETURN_RECEIVE_INSPECTION_NOTES);
  };

  const modalMessages = {
    title: formatMessage(modalsText.returnReceiveInspectionNotesModalTitle),
    inputPlaceholder:
      noteType === InspectionNotesType.RETURN_ORDER
        ? formatMessage(modalsText.returnReceiveOrderInspectionNotesInputPlaceholder)
        : formatMessage(modalsText.returnReceiveItemInspectionNotesInputPlaceholder),
    closeButton: formatMessage(modalsText.close),
    submitButton: formatMessage(modalsText.submit),
  };
  return {
    modalMessages,
    inspectionNotesError,
    inspectionNotesInput,
    isSubmitLoading: isAddingReturnOrderNotes ? submitState.loading : false,
    isSubmitDisabled: isEmpty(trimmedInspectionNotes) || !isEmpty(inspectionNotesError) || submitState.loading,
    handleInspectionNoteInputChange,
    onSubmitInspectionNotes,
    onCloseModal,
  };
};
