import { UnconnectedUploadItem } from "facility-commons/components";
import React from "react";
import { useWotFileUploader } from "./useWotFileUploader";
import { FileMetaDataDTO } from "@deliverr/wot-client";

export const WotUploadItem = (props: { fileMetaData: FileMetaDataDTO }) => {
  const { onInputFileChange, uploading } = useWotFileUploader(props);

  return (
    <UnconnectedUploadItem
      uploading={uploading}
      onInputFileChange={onInputFileChange}
      acceptedFilesTypes={props.fileMetaData.allowedFileTypes}
      uploadProgress={50}
      value={""}
    />
  );
};
