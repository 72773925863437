/* eslint-disable complexity */
import { ResolutionCardActions, ResolutionCardActionSection } from "../ResolutionCardActionSection";
import {
  TicketCenterMissingBarcodeCaseResponse,
  TicketCenterNonComplianceCaseStatus,
  TicketCenterUnknownBarcodeCaseResponse,
} from "@deliverr/commons-clients";
import { Text, ThemeProps, BoxReset } from "@deliverr/ui";
import { Box } from "@deliverr/ui/lib-esm/Box";
import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";
import { PaddedDivider } from "facility-commons/components/emotion";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { DashboardDetailExpansionBlock } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { DashboardDetailInfoList } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList";
import { DashboardDetailInfoListItem } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList/DashboardDetailInfoListItem";
import { UnsolvableTicketSection } from "../../UnsolvableTicketSection";
import { CenteredSpinner } from "../CenteredSpinner";
import { ZendeskCard } from "../ZendeskCard";
import { useBarcodeCaseResolutionCardLabels } from "./useBarcodeCaseResolutionCardLabels";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { TicketCardPlaceholder } from "warehouse/ticket-center/common/components/TicketCardPlaceholder";

const OrderedList = styled.ol<ThemeProps>(
  ({ theme }) => `
    padding-left: ${theme.spacing.S4};
    list-style: auto;
    font-size: ${theme.font.size.F3};
    color: ${theme.colors.NEUTRAL[300]};
  `
);

interface BarcodeCaseResolutionCardProps extends ResolutionCardActions {
  ticketDetails?: TicketCenterMissingBarcodeCaseResponse | TicketCenterUnknownBarcodeCaseResponse;
  loading?: boolean;
  barcode?: string;
  handleThumbnailClick: (event: React.MouseEvent<HTMLImageElement>) => void;
  routeToUnsolvableDetails: () => void;
}

export const BarcodeCaseResolutionCard: React.FC<BarcodeCaseResolutionCardProps> = ({
  ticketDetails,
  loading,
  rollbackClose,
  markClosed,
  viewSupportTicket,
  downloadBarcodes,
  barcode,
  routeToUnsolvableDetails,
  shouldShowPrintBarcodesButton,
}) => {
  const labels = useBarcodeCaseResolutionCardLabels();

  // Yes, ticketDetails will always be empty when we're loading.
  // This is definitely repetitive. But, this tells vscode that ticketDetails
  // will always be defined below, so we don't have to worry about the undefined
  // option.
  if (loading || !ticketDetails) {
    return <CenteredSpinner loading />;
  }

  const listItems: DashboardDetailInfoListItem[] = [
    {
      id: "Receiving PO #",
      displayValue: labels.listItems.po,
      value: ticketDetails.receivingInfo?.asnId ?? "",
    },
    {
      id: "Barcode",
      displayValue: labels.listItems.barcode,
      value: barcode ?? "",
    },
    {
      id: "Quantity",
      displayValue: labels.listItems.quantity,
      value: ticketDetails?.quantity ?? 0,
    },
  ];

  if (ticketDetails.status === TicketCenterNonComplianceCaseStatus.TODO && ticketDetails.dueAt) {
    const dueAt = new Date(ticketDetails.dueAt);
    listItems.push({
      id: "Receive by",
      displayValue: labels.receiveDetails.receiveBy,
      value: format(dueAt, "MMM dd, yyyy"),
      hasError: new Date().getTime() > dueAt.getTime(),
    });
  }

  if (ticketDetails.status === TicketCenterNonComplianceCaseStatus.CLOSED && ticketDetails.completedAt) {
    const completedAt = new Date(ticketDetails.completedAt);
    listItems.push({
      id: "Received on",
      displayValue: labels.receiveDetails.receivedOn,
      value: format(completedAt, "MMM dd, yyyy"),
    });
  }

  const isTicketUrlEmpty = !ticketDetails.receivingInfo?.ticketUrl;

  return (
    <Box height="100%">
      <DashboardDetailCard
        createdAt={new Date(ticketDetails.createdAt)}
        placeholder={
          ticketDetails.status === TicketCenterNonComplianceCaseStatus.CREATED ? <TicketCardPlaceholder /> : undefined
        }
      >
        {!isTicketUrlEmpty && <ZendeskCard zendeskUrl={ticketDetails.receivingInfo!.ticketUrl!} />}
        {isTicketUrlEmpty && (
          <>
            <DashboardDetailInfoList
              addDivider
              items={listItems}
              removeLastDivider={ticketDetails.status !== TicketCenterNonComplianceCaseStatus.TODO}
            />
            {ticketDetails.status === TicketCenterNonComplianceCaseStatus.TODO && (
              <>
                {ticketDetails.type === NonComplianceType.UNKNOWN_BARCODE ? (
                  <BoxReset marginTop="S4">
                    <Text size="body">{labels.resolution.unknownBarcodeResolution}</Text>
                  </BoxReset>
                ) : (
                  <DashboardDetailExpansionBlock defaultOpen title={labels.instructions.instructionsHeader}>
                    <OrderedList>
                      <li>{labels.instructions.printNewLabels}</li>
                      <li>{labels.instructions.relabelProducts}</li>
                      <li>{labels.instructions.receiveSku}</li>
                    </OrderedList>
                  </DashboardDetailExpansionBlock>
                )}

                <PaddedDivider />
                <UnsolvableTicketSection onClickUnsolvable={routeToUnsolvableDetails} />
              </>
            )}
          </>
        )}
      </DashboardDetailCard>
      <ResolutionCardActionSection
        isTicketUrlEmpty={isTicketUrlEmpty}
        viewSupportTicket={viewSupportTicket}
        shouldShowPrintBarcodesButton={shouldShowPrintBarcodesButton}
        downloadBarcodes={downloadBarcodes}
        markClosed={markClosed}
        rollbackClose={rollbackClose}
        ticketDetails={ticketDetails}
      />
    </Box>
  );
};
