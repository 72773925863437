import React from "react";
import styled from "@emotion/styled";
import { LocalizedMessage, Stack, Text } from "@deliverr/ui";
import { TextAlign, ThemeProps } from "@deliverr/ui/lib-esm/shared";
import { LinkButtonV2 } from "../buttons";
import { FormattedMessage } from "react-intl";
import { PAGE_NOT_FOUND_LABELS } from "./PageNotFound.labels";

interface PageNotFoundProps {
  title: LocalizedMessage;
  description: LocalizedMessage;
  linkTo: string;
  linkText: LocalizedMessage;
}

const defaultProps: PageNotFoundProps = {
  title: <FormattedMessage {...PAGE_NOT_FOUND_LABELS.title} />,
  description: <FormattedMessage {...PAGE_NOT_FOUND_LABELS.description} />,
  linkTo: "/",
  linkText: <FormattedMessage {...PAGE_NOT_FOUND_LABELS.linkText} />,
};

const StyledStack = styled(Stack)<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
`
);

export const PageNotFound: React.FC<PageNotFoundProps> = ({ title, description, linkTo, linkText }) => (
  <StyledStack direction="VERTICAL" center>
    <Text size="h2">{title}</Text>
    {description && (
      <Text textAlign={TextAlign.center} appearance="INFO">
        {description}
      </Text>
    )}
    <LinkButtonV2 to={linkTo}>{linkText}</LinkButtonV2>
  </StyledStack>
);

PageNotFound.defaultProps = defaultProps;
