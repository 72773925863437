import React from "react";
import { TextInputCard } from "facility-commons/flow/cards/TextInputCard";
import { useBoxArrivalScannerCard } from "./useBoxArrivalScannerCard";

export const BoxArrivalScannerCard: React.FC = () => {
  const {
    title,
    message,
    placeholder,
    currentScanValue,
    handleSubmit,
    handleChange,
    loading,
    boxArrivalScanError,
  } = useBoxArrivalScannerCard();

  return (
    <TextInputCard
      onSubmit={handleSubmit}
      title={title}
      message={message}
      onChange={handleChange}
      value={currentScanValue}
      placeholder={placeholder}
      loading={loading}
      errorMessage={boxArrivalScanError}
      size="sm"
    />
  );
};
