import { Button } from "@deliverr/ui";
import React from "react";
import { FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";

import { LoadingSpinner, TooltipWrapper } from "facility-commons/components";
import { maxBarcodeLength } from "facility-commons/utils/config";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormInput,
  WarehouseFormLabel,
  WarehouseFormOtherDetails,
  WarehouseFormTextArea,
  WarehouseFormSubTitle,
  WarehouseFormTooltip,
  WarehouseFormInputGroup,
  WarehouseFormPhotoGridTitle,
  WarehouseFormText,
  WarehouseFormRadioButton,
  WarehouseFormRadioButtonGroup,
  WarehouseFormRadioStack,
  WarehouseFormRadioTitle,
} from "facility-commons/components/emotion";
import { OrderPickingField } from "./OrderPickingForm.types";
import { useOrderPickingForm } from "./useOrderPickingForm";
import { useIntl } from "react-intl";
import { ticketFormContent } from "facility-commons/flow/forms/ticketFormContent";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";

export const OrderPickingForm: React.FC = () => {
  const {
    errors,
    formHandler,
    onFormSubmit,
    preventSubmit,
    register,
    warehouseEmailAsyncState,
  } = useOrderPickingForm();
  const { formatMessage } = useIntl();

  return (
    <FacilityCard>
      <FormProvider {...formHandler}>
        <WarehouseForm onSubmit={onFormSubmit}>
          <WarehouseFormSubTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.subtitle)}
          </WarehouseFormSubTitle>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={OrderPickingField.BARCODE}>
              {formatMessage(ticketFormContent.barcodeRequired)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OrderPickingField.BARCODE]}
              id={OrderPickingField.BARCODE}
              maxLength={maxBarcodeLength}
              placeholder={formatMessage(ticketFormContent.barcodePlaceholder)}
              hasError={!!errors[OrderPickingField.BARCODE]}
              errorMessage={<ErrorMessage errors={errors} name={OrderPickingField.BARCODE} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormRadioStack direction="VERTICAL">
            <WarehouseFormRadioTitle appearance="INFO">
              {formatMessage(ticketFormContent.damageType)}
            </WarehouseFormRadioTitle>
            <WarehouseFormRadioButtonGroup>
              <WarehouseFormRadioButton
                {...register[OrderPickingField.DAMAGE_TYPE]}
                id={`${OrderPickingField.DAMAGE_TYPE}-damaged`}
                data-testid="damaged-radio"
                type="radio"
                value={DamageType.DAMAGED}
                required
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${OrderPickingField.DAMAGE_TYPE}-damaged`}>
                {formatMessage(ticketFormContent.productDamaged)}
              </WarehouseFormLabel>

              <WarehouseFormRadioButton
                {...register[OrderPickingField.DAMAGE_TYPE]}
                id={`${OrderPickingField.DAMAGE_TYPE}-expired`}
                data-testid="expired-radio"
                type="radio"
                value={DamageType.EXPIRED}
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${OrderPickingField.DAMAGE_TYPE}-expired`}>
                {formatMessage(ticketFormContent.productExpired)}
              </WarehouseFormLabel>
            </WarehouseFormRadioButtonGroup>
          </WarehouseFormRadioStack>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={OrderPickingField.QUANTITY}>
              {formatMessage(ticketFormContent.damagedQuantityRequired)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OrderPickingField.QUANTITY]}
              id={OrderPickingField.QUANTITY}
              numbersOnly
              placeholder={formatMessage(ticketFormContent.quantityPlaceholder)}
              hasError={!!errors[OrderPickingField.QUANTITY]}
              errorMessage={<ErrorMessage errors={errors} name={OrderPickingField.QUANTITY} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormRadioStack direction="VERTICAL">
            <WarehouseFormRadioTitle appearance="INFO">
              {formatMessage(ticketFormContent.disposedRequired)}
            </WarehouseFormRadioTitle>
            <WarehouseFormRadioButtonGroup>
              <WarehouseFormRadioButton
                {...register[OrderPickingField.DISPOSED]}
                id={`${OrderPickingField.DISPOSED}-yes`}
                data-testid="disposed-yes-radio"
                type="radio"
                value="1"
                required
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${OrderPickingField.DISPOSED}-yes`}>
                {formatMessage(ticketFormContent.yes)}
              </WarehouseFormLabel>

              <WarehouseFormRadioButton
                {...register[OrderPickingField.DISPOSED]}
                id={`${OrderPickingField.DISPOSED}-no`}
                data-testid="disposed-no-radio"
                type="radio"
                value="0"
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${OrderPickingField.DISPOSED}-no`}>
                {formatMessage(ticketFormContent.no)}
              </WarehouseFormLabel>
            </WarehouseFormRadioButtonGroup>
          </WarehouseFormRadioStack>

          <WarehouseFormPhotoGridTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.photosSubtitle)}
          </WarehouseFormPhotoGridTitle>

          <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessage)}</WarehouseFormText>

          <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OrderPickingField.DAMAGED_PRODUCT_PHOTO]}
                id={OrderPickingField.DAMAGED_PRODUCT_PHOTO}
                labelText={formatMessage(ticketFormContent.damagedPhotoRequired)}
              />
              <WarehouseFormTooltip active={!!errors[OrderPickingField.DAMAGED_PRODUCT_PHOTO]}>
                <ErrorMessage errors={errors} name={OrderPickingField.DAMAGED_PRODUCT_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OrderPickingField.ADDITIONAL_PHOTO]}
                id={OrderPickingField.ADDITIONAL_PHOTO}
                labelText={formatMessage(ticketFormContent.additionalPhoto)}
              />
              <WarehouseFormTooltip active={Boolean(errors[OrderPickingField.ADDITIONAL_PHOTO])}>
                <ErrorMessage errors={errors} name={OrderPickingField.ADDITIONAL_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>
          </WarehouseFormImageGrid>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={OrderPickingField.OTHER_DETAILS}>
              {formatMessage(ticketFormContent.damagedAdditionalDetails)}
            </WarehouseFormLabel>
            <WarehouseFormTextArea
              {...register[OrderPickingField.OTHER_DETAILS]}
              id={OrderPickingField.OTHER_DETAILS}
              placeholder={formatMessage(ticketFormContent.damagedAdditionalDetailsPlaceholder)}
            />
          </WarehouseFormOtherDetails>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={OrderPickingField.EMAIL}>
              {formatMessage(ticketFormContent.email)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              id={OrderPickingField.EMAIL}
              {...register[OrderPickingField.EMAIL]}
              helpText={
                warehouseEmailAsyncState.loading
                  ? formatMessage(ticketFormContent.emailLoadingMessage)
                  : formatMessage(ticketFormContent.emailMessage, { warehouseEmail: warehouseEmailAsyncState.value! })
              }
              hasError={!!errors[OrderPickingField.EMAIL]}
              disabled={warehouseEmailAsyncState.loading}
              errorMessage={<ErrorMessage errors={errors} name={OrderPickingField.EMAIL} />}
            />
          </WarehouseFormOtherDetails>

          <Button disabled={preventSubmit} block type="submit">
            <LoadingSpinner loading={preventSubmit} size="lg">
              {formatMessage(ticketFormContent.submit)}
            </LoadingSpinner>
          </Button>
        </WarehouseForm>
      </FormProvider>
    </FacilityCard>
  );
};
