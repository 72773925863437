import { defineMessages } from "react-intl";

export const createPackageCardLabels = defineMessages({
  scanSkuTitleText: {
    id: "warehouse.rs-outbounds.scanSku",
    defaultMessage: "Scan item barcode",
  },
  scanSkuInstructionsText: {
    id: "warehouse.rs-outbounds.scanSkuInstructionsText",
    defaultMessage: "Scan or enter the item's barcode/DSKU being added to package",
  },
  scanSkuBarcode: {
    id: "warehouse.rs-outbounds.scanSkuBarcode",
    defaultMessage: "Item Barcode",
  },
  skuNotInShipment: {
    id: "warehouse.rs-outbounds.skuNotInShipment",
    defaultMessage: "Product does not belong to shipment",
  },
  skuAddedSuccessNotificationTitle: {
    id: "warehouse.rs-outbounds.skuAddedSuccessNotification.title",
    defaultMessage: "Item added",
  },
  closePackageButton: {
    id: "warehouse.rs-outbounds.closePackageButton",
    defaultMessage: "Close Package",
  },
  qtyAddedToPackage: {
    id: "warehouse.rs-outbounds.qtyAddedToPackage",
    defaultMessage: "Qty added to package: {itemsAdded}",
  },
  packageWeight: {
    id: "warehouse.rs-outbounds.packageWeight",
    defaultMessage: "Package weight: {packageWeight} {unit, plural, one {lb} other {lbs}}",
  },
  allUnitsPacked: {
    id: "warehouse.rs-outbounds.allUnitsPacked",
    defaultMessage: "All units packed for this item",
  },
  discardPackageButton: {
    id: "warehouse.rs-outbounds.discardPackageButton",
    defaultMessage: "Discard Package",
  },
});
