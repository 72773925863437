export enum FacilityStateKey {
  MODALS = "FACILITY_MODALS",
  VISIBLE_MODAL = "FACILITY_VISIBLE_MODAL",
  PRINTERS = "FACILITY_PRINTERS",
  CONNECTED_PRINTER = "FACILITY_CONNECTED_PRINTER",
  IS_SEARCHING_PRINTERS = "FACILITY_IS_SEARCHING_PRINTERS",
  PRINTER_DPI = "FACILITY_PRINTER_DPI",
  PRINTER_HORIZONTAL_OFFSET = "FACILITY_PRINTER_HORIZONTAL_OFFSET",
  IS_CONNECTING_TO_PRINTER = "FACILITY_IS_CONNECTING_TO_PRINTER",
}
