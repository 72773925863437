import React from "react";

import { NcCardStack, SkipStep } from "../../components";
import { useLotExpiryUpload } from "./useLotExpiryUpload";
import { commonUploadMessages } from "facility-commons/labels";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";

export const LotExpiryUpload: React.FC = () => {
  const { imageUploaded, formatMessage, onFileChange, proceed, register } = useLotExpiryUpload();

  return (
    <NcCardStack
      continueDisabled={!imageUploaded}
      continueOnClick={proceed}
      title={formatMessage(commonUploadMessages.takeAPhotoLotExpiryTitle)}
    >
      <NonComplianceUploadItem
        iconText={formatMessage(commonUploadMessages.takePhoto)}
        id="lotExpiry-photo"
        {...register}
        onSuccessfulUpload={onFileChange}
      />
      <SkipStep message={formatMessage(commonUploadMessages.barcodeMissing)} onSkip={proceed} />
    </NcCardStack>
  );
};
