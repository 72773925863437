import { defineMessages, MessageDescriptor } from "react-intl";

export enum InvalidQuantityErrorMessageKey {
  MAX_QUANTITY_EXCEEDED = "MAX_QUANTITY_EXCEEDED",
  POSITIVE_VALUE = "POSITIVE_VALUE",
  PRIOR_RECEIVE = "PRIOR_RECEIVE",
  MISSING_QUANTITY = "MISSING_QUANTITY",
}

export const INVALID_QUANTITY_ERROR_BY_ERROR_CODE: Record<
  InvalidQuantityErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [InvalidQuantityErrorMessageKey.MAX_QUANTITY_EXCEEDED]: {
    id: "warehouse.receiving.maxUnitsQtyErr",
    defaultMessage: "Quantity must be less than 6,000.",
  },
  [InvalidQuantityErrorMessageKey.POSITIVE_VALUE]: {
    id: "warehouse.receiving.invalidQty",
    defaultMessage: "Quantity must be greater than 0.",
  },
  [InvalidQuantityErrorMessageKey.PRIOR_RECEIVE]: {
    id: "warehouse.consolidation.priorReceivable",
    defaultMessage: "Quantity must be greater than what has already been received.",
  },
  [InvalidQuantityErrorMessageKey.MISSING_QUANTITY]: {
    id: "warehouse.consolidation.missingQtyError",
    defaultMessage: "Quantity is missing",
  },
});
