import { useRouteMatch, useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { userState } from "facility-commons/base/Auth/userState";

export function useRouter() {
  const match = useRouteMatch();
  const history = useHistory();
  const { warehouseId } = useRecoilValue(userState);

  return {
    match,
    history,
    warehouseId,
  };
}
