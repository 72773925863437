import { useIntl } from "react-intl";
import { useAsyncFn, useMount } from "react-use";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { commonMessages } from "facility-commons/labels";
import { commonTicketMessages } from "../../content/common";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { createSuccessNotification, log, logStart, setProp } from "facility-commons/utils";
import { WarehousePortalRoutes } from "warehouse/routes";
import { userState } from "facility-commons/base/Auth/userState";
import {
  NonComplianceIncidentSubmissionResponse,
  NonComplianceResolutionMethod,
} from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { v4 as uuid } from "uuid";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { UnexpectedSkuProps } from "warehouse/ticket-center/new/non-compliance/modals/UnexpectedSku";
import {
  currentProductLotFefoDetailsState,
  currentProductState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { receivingMisMatchState } from "warehouse/receiving/ReceivingState";

export const useNcReviewCard = () => {
  const { email: defaultEmail, warehouseId, isStorage } = useRecoilValue(userState);
  const { nonComplianceClient } = useClientsWithAuth();
  const currentProduct = useRecoilValue(currentProductState);
  const { isLotEnabled, isFefoEnabled } = useRecoilValue(currentProductLotFefoDetailsState);
  const resetReceivingMisMatchState = useResetRecoilState(receivingMisMatchState);
  const resetLotFefoDetailsState = useResetRecoilState(currentProductLotFefoDetailsState);
  const { handleNonComplianceError } = useNonComplianceFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { addNotification, resetNotifications, successResponse } = useCommonFlow();
  const { showModal, hideModal } = useWarehouseModal();
  const { push } = useRouter();
  const { formatMessage } = useIntl();

  const { uploadedImages } = currentProduct;

  // Since RS sites do not have any CDSKUs, we will use a placeholder CDSKU to prevent all tickets from being auto-categorized as MISSING_CDSKU
  const reserveStorageCdskuPlaceholder = "CDRESERVE00";
  const submissionRequest = isStorage
    ? { warehouseId, ...currentProduct, cdsku: reserveStorageCdskuPlaceholder }
    : { warehouseId, ...currentProduct };

  useMount(() =>
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonTicketMessages.nonComplianceReviewTitle)))
  );

  const hideModalAndRoute = () => {
    hideModal(WarehouseModal.NC_UNEXPECTED_SKU);
    push(WarehousePortalRoutes.NEW_TICKET);
  };

  const handleSuccessfulIncidentSubmission = (response: NonComplianceIncidentSubmissionResponse, ctx: any) => {
    // Reset lot/fefo NC related data
    resetReceivingMisMatchState();
    resetLotFefoDetailsState();
    if (response.id) {
      successResponse();
      addNotification({
        ...createSuccessNotification(formatMessage(commonTicketMessages.caseCreated, { caseId: response.id })),
        onClose: resetNotifications,
      });
      push(isStorage ? WarehousePortalRoutes.RECEIVING : WarehousePortalRoutes.NEW_TICKET);
    } else {
      // should never happen in theory - but we should know if it ever does
      handleNonComplianceError({ ...ctx, response }, new Error("Received ticket submit response without an id"));
    }
  };

  const resubmitIncident = async () => {
    const newIncidentId = uuid();
    const ctx = { ...submissionRequest, fn: "useNcReviewCard.resubmitUnexpectedSku", newIncidentId };
    log(ctx, "Resubmitting incident");
    try {
      const response = await nonComplianceClient.nonComplianceIncidentSubmission({
        ...submissionRequest,
        incidentId: newIncidentId,
      });

      log(ctx, "Resubmit incident complete");
      hideModal(WarehouseModal.NC_UNEXPECTED_SKU);
      handleSuccessfulIncidentSubmission(response, ctx);
    } catch (err) {
      handleNonComplianceError(ctx, err);
    }
  };

  const [{ loading }, submitTicket] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "useNcReviewCard.submitTicket", ...submissionRequest });
    try {
      const response = await nonComplianceClient.nonComplianceIncidentSubmission(submissionRequest);
      log({ ...ctx, response }, "Submit non-compliance ticket response");

      if (response.nonComplianceResolutionMethod === NonComplianceResolutionMethod.GENERATE_UNEXPECTED_SKU_ASN) {
        const modalProps: UnexpectedSkuProps = {
          onContinue: resubmitIncident,
          onResolution: hideModalAndRoute,
          generatedAsn: response.resolutionDetails!.asnId,
        };

        showModal(WarehouseModal.NC_UNEXPECTED_SKU, modalProps);
      } else {
        handleSuccessfulIncidentSubmission(response, ctx);
      }
    } catch (err) {
      handleNonComplianceError(ctx, err);
    }
  });

  const getPhotos = () => {
    const photosArray: string[] = [];
    if (uploadedImages?.boxContentPicture) {
      photosArray.push(uploadedImages?.boxContentPicture);
    }
    if (uploadedImages?.boxContentPicture) {
      photosArray.push(uploadedImages?.barcodePicture);
    }
    if (uploadedImages?.boxContentPicture) {
      photosArray.push(uploadedImages?.lotExpiryPicture);
    }
    if (uploadedImages.frontPicture) {
      photosArray.push(uploadedImages.frontPicture);
    }
    if (uploadedImages.backPicture) {
      photosArray.push(uploadedImages.backPicture);
    }
    return photosArray;
  };

  return {
    formatMessage,
    ...currentProduct,
    additionalDetails: currentProduct.notes || formatMessage({ id: "none", defaultMessage: "None" }),
    barcode: currentProduct.barcode || formatMessage(commonMessages.unknown),
    boxInformation: currentProduct.trackingCode || formatMessage(commonMessages.unknown),
    email: currentProduct.warehouseContactEmail || defaultEmail || "",
    isRestricted: currentProduct.isRestricted ? formatMessage(commonMessages.yes) : formatMessage(commonMessages.no),
    loading,
    // show the photos in the same order they were uploaded
    photos: getPhotos(),
    submitTicket,
    isLotEnabled,
    isFefoEnabled,
  };
};
