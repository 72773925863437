import { ProblemSolverContext } from "@deliverr/ui-facility";

export function useRouteToProblemSolverPortal(contexts: ProblemSolverContext[]) {
  const getParams = () => {
    const params = new URLSearchParams();
    contexts.forEach((context) => {
      params.append("problemSolverContext", context);
    });
    return params.toString();
  };

  const routeToNewIssue = () => {
    window.location.assign(`${process.env.REACT_APP_PROBLEM_SOLVER_PORTAL_URL}/issues/create?${getParams()}`);
  };

  const routeToIssueDashboard = () => {
    window.location.assign(`${process.env.REACT_APP_PROBLEM_SOLVER_PORTAL_URL}/issues?${getParams()}`);
  };

  return {
    routeToNewIssue,
    routeToIssueDashboard,
  };
}
