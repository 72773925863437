import { receivingState } from "./ReceivingState";
import { selector } from "recoil";
import { receiveFlowTypeState } from "./ReceiveFlowTypeState";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { isBoxReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";

export const getNumOfBoxesExpected = ({ get }) => {
  const flowType = get(receiveFlowTypeState);
  const isBoxReceive = isBoxReceiveFlow(flowType);
  const { numIdenticalNonReceivedBoxes, expectedNumOfBoxes } = get(receivingState);

  return isBoxReceive ? numIdenticalNonReceivedBoxes : expectedNumOfBoxes;
};

export const numOfBoxesExpectedState = selector({
  key: WarehouseStateKey.NUM_OF_BOXES_EXPECTED,
  get: getNumOfBoxesExpected,
});
