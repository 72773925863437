import { defineMessages, MessageDescriptor } from "react-intl";

export enum AsnDefinedErrorMessageKey {
  ASN_DEFINED = "ASN_DEFINED",
}

export const ASN_DEFINED_ERROR_BY_ERROR_CODE: Record<AsnDefinedErrorMessageKey, MessageDescriptor> = defineMessages({
  [AsnDefinedErrorMessageKey.ASN_DEFINED]: {
    id: "warehouse.asn.asnDefinedError",
    defaultMessage: "ASN {asnLabel} already exists at {warehouseId}",
  },
});
