import { isZebraScanner, isEbBrowser } from "facility-commons/utils/zebraUtils";
import { log } from "facility-commons/utils/logger";
import { enableZebraScanner } from "facility-commons/flow/zebraScanner";
import { AppName } from "facility-commons/types";

export function initializeZebraApp(appName: string, renderApp: () => void) {
  if (isZebraScanner && (appName !== AppName.WP || isEbBrowser())) {
    // causes a prompt if loaded on mobile browsers that aren't enterprise browser
    const head = document.getElementsByTagName("head")[0];
    const js = document.createElement("script");
    js.type = "text/javascript";
    js.src = "https://static.deliverr.com/scan/ebapi-modules.js";
    js.onload = () => {
      log({ fn: "onload" }, `loaded ebapi-modules for ${appName}`);
      enableZebraScanner();
      renderApp();
    };
    head.appendChild(js);
  } else {
    renderApp();
  }
}
