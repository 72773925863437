import React from "react";
import { useIntl } from "react-intl";
import {
  AutoresolveNonComplianceModal,
  AutoresolveNonComplianceModalProps,
} from "warehouse/ticket-center/new/non-compliance/modals/AutoresolveNonComplianceModal/AutoresolveNonComplianceModal";

export type UnexpectedSkuProps = Omit<AutoresolveNonComplianceModalProps, "title" | "instructions" | "continueMessage">;
export const UnexpectedSku: React.FC<UnexpectedSkuProps> = (props) => {
  const { formatMessage } = useIntl();
  return (
    <AutoresolveNonComplianceModal
      title={formatMessage({
        id: "warehouse.unexpectedBarcodeModal.unexpectedBarcode",
        defaultMessage: "Unexpected Barcode",
      })}
      instructions={formatMessage({
        id: "warehouse.unexpectedBarcodeModal.instructions",
        defaultMessage: "A new PO # has been created for this SKU. Receive this box against",
      })}
      continueMessage={formatMessage({
        id: "warehouse.unexpectedBarcodeModal.continue",
        defaultMessage: "Send to Deliverr Support",
      })}
      {...props}
    />
  );
};
