import * as React from "react";
import cn from "classnames";
import { Button, IconV2, DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";

import "./toast-position.css";
import { ToastInfo, DEFAULT_TOAST_POSITION } from "./ToastUtils";

export interface ToastProps extends ToastInfo {
  closeToast?: () => void;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

const Gradient = styled.div`
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-image: linear-gradient(91deg, #648bff, #00c89a 100%);
  height: 6px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 50;

  &:active {
    outline: none;
    color: black;
  }
`;

const ToastContent = styled.div`
  background-color: white;
  pointer-events: auto;
  width: 275px;
  box-shadow: 0 20px 48px -24px rgba(2, 23, 44, 0.2), 0 0 3px -1px rgba(2, 23, 44, 0.2);
  border-radius: 4px;
  overflow: hidden;
  padding: 1.5rem;
`;

const ToastText = styled.p<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.BOLD};
  margin-bottom: 1.25rem;
`
);

export const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  const { message, buttonLabel, onButtonClick, position: _position, closeable, closeToast } = props;
  const position = _position || DEFAULT_TOAST_POSITION;

  function handleClose() {
    if (closeToast) {
      closeToast();
    }
  }

  return (
    <Container className={cn(position)}>
      <Gradient />
      {closeable && closeToast && (
        <CloseButton onClick={handleClose}>
          <IconV2 type="close" color="gray" />
        </CloseButton>
      )}
      <ToastContent>
        <ToastText>{message}</ToastText>
        {buttonLabel && onButtonClick && (
          <Button block onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        )}
      </ToastContent>
    </Container>
  );
};
