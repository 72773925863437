import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "facility-commons/flow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useReceivingSKULCScanFlow } from "./useReceivingSKULCScanFlow";

export const StorageReceivingSKULC: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locationValue, handleChange, handleSubmit } = useReceivingSKULCScanFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <TextInputCard
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLCPlaceholder)}
        title={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLCTitle)}
        message={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLCDescription)}
        value={locationValue ?? ""}
      />
    </>
  );
};
