import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { NonComplianceError, NonComplianceErrorCodes, SharedErrorCodes } from "@deliverr/non-compliance-client";
import { logError } from "facility-commons";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { formatValues } from "warehouse/receiving/utils/getErrorMsg";
import { toast } from "@deliverr/ui";

const NON_COMPLIANCE_ERROR_TO_TRANSLATION_MAP: Record<NonComplianceErrorCodes, MessageDescriptor> = defineMessages({
  [NonComplianceErrorCodes.BARCODE_NOT_FOUND]: {
    id: "issues.error.barcodeNotFound",
    defaultMessage: "Barcode {barcode} not found. Please scan a valid barcode.",
  },
  [NonComplianceErrorCodes.CONTAINER_ALREADY_EXISTS]: {
    id: "issues.error.containerAlreadyExists",
    defaultMessage: "Container {entityId} already exists. Please scan a different container.",
  },
  [NonComplianceErrorCodes.CONTAINER_NOT_FOUND]: {
    id: "issues.error.containerNotFound",
    defaultMessage: "Container {id} not found. Please scan a valid container.",
  },
  [NonComplianceErrorCodes.ENTITY_ALREADY_EXISTS]: {
    id: "issues.error.entityAlreadyExists",
    defaultMessage: "Entity {entityId} already exists. Please try again.",
  },
  [NonComplianceErrorCodes.ENTITY_NOT_FOUND]: {
    id: "issues.error.entityNotFound",
    defaultMessage: "Entity {id} not found. Please try again.",
  },
  [NonComplianceErrorCodes.FAILED_TO_GET_WAREHOUSE]: {
    id: "issues.error.failedToGetWarehouse",
    defaultMessage: "Failed to get warehouse. Please try again.",
  },
  [NonComplianceErrorCodes.FAILED_TO_GET_INVENTORY_BY_LOCATION]: {
    id: "issues.error.failedToGetInventoryByLocation",
    defaultMessage:
      "Failed to get inventory for location {location} at {facilityId} for {dsku}. Please ensure the units have been moved to this location and try again.",
  },
  [NonComplianceErrorCodes.FAILED_BARCODE_DSKU_LINKING]: {
    id: "issues.error.failedBarcodeDskuLinking",
    defaultMessage: "Failed to link barcode {barcode} to dsku {dsku}. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.FAILED_TO_DETERMINE_SELLER]: {
    id: "issues.error.failedToDetermineSeller",
    defaultMessage: "Failed to determine seller ID. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.INVALID_ISSUE_STATUS]: {
    id: "issues.error.invalidIssueStatus",
    defaultMessage:
      "Invalid transition from {currentStatus} to {expectedStatus}. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.INVALID_UNIT_DISPOSITION]: {
    id: "issues.error.invalidUnitDisposition",
    defaultMessage:
      "Issue disposition is not valid for completion: {disposition}. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ALREADY_EXISTS]: {
    id: "issues.error.issueAlreadyExists",
    defaultMessage: "Issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_CONTAINER_ALREADY_EXISTS]: {
    id: "issues.error.issueContainerAlreadyExists",
    defaultMessage: "Issue container already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_CONTAINER_NOT_FOUND]: {
    id: "issues.error.issueContainerNotFound",
    defaultMessage:
      "Issue container {issueContainerId} for issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ENTITY_ALREADY_EXISTS]: {
    id: "issues.error.issueEntityAlreadyExists",
    defaultMessage: "Issue entity already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ENTITY_NOT_FOUND]: {
    id: "issues.error.issueEntityNotFound",
    defaultMessage:
      "Issue entity {issueEntityId} for issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_CONTEXT_ALREADY_EXISTS]: {
    id: "issues.error.issueContextAlreadyExists",
    defaultMessage: "Issue context already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_DAMAGED_PRODUCT_ALREADY_EXISTS]: {
    id: "issues.error.issueDamagedProductAlreadyExists",
    defaultMessage: "Damaged product issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_DAMAGED_PRODUCT_EXTENDED_ALREADY_EXISTS]: {
    id: "issues.error.issueDamagedProductExtendedAlreadyExists",
    defaultMessage: "Extended damaged product issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_DAMAGED_PRODUCT_NOT_FOUND]: {
    id: "issues.error.issueDamagedProductNotFound",
    defaultMessage: "Damaged product issue not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_DAMAGED_PRODUCT_EXTENDED_NOT_FOUND]: {
    id: "issues.error.issueDamagedProductExtendedNotFound",
    defaultMessage: "Extended damaged product issue not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_DAMAGED_PRODUCT_MULTIPLE_CONTAINERS]: {
    id: "issues.error.issueDamagedProductMultipleContainers",
    defaultMessage: "Damaged product issue has multiple containers. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_EXPIRED_PRODUCT_NOT_FOUND]: {
    id: "issues.error.issueExpiredProductNotFound",
    defaultMessage: "Expired product issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ILLEGIBLE_EXPIRATION_NOT_FOUND]: {
    id: "issues.error.issueIllegibleExpirationNotFound",
    defaultMessage: "Illegible expiration issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ILLEGIBLE_LOT_NOT_FOUND]: {
    id: "issues.error.issueIllegibleLotNotFound",
    defaultMessage: "Illegible lot issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_MISSING_BARCODE_ALREADY_EXISTS]: {
    id: "issues.error.issueMissingBarcodeAlreadyExists",
    defaultMessage: "Missing barcode issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_MISSING_BARCODE_NOT_FOUND]: {
    id: "issues.error.issueMissingBarcodeNotFound",
    defaultMessage: "Missing barcode issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OTHER_NOT_FOUND]: {
    id: "issues.error.issueOtherNotFound",
    defaultMessage: "Other issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OUTBOUND_LABEL_ALREADY_EXISTS]: {
    id: "issues.error.issueOutboundLabelAlreadyExists",
    defaultMessage: "Outbound label issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OUTBOUND_LABEL_NOT_FOUND]: {
    id: "issues.error.issueOutboundLabelNotFound",
    defaultMessage: "Outbound label issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_SHORT_SHIP_ALREADY_EXISTS]: {
    id: "issues.error.issueShortShipAlreadyExists",
    defaultMessage: "Short ship issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_SHORT_SHIP_NOT_FOUND]: {
    id: "issues.error.issueShortShipNotFound",
    defaultMessage: "Short ship issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_UNEXPECTED_SKU_ALREADY_EXISTS]: {
    id: "issues.error.issueUnexpectedSkuAlreadyExists",
    defaultMessage: "Unexpected SKU issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_UNEXPECTED_SKU_NOT_FOUND]: {
    id: "issues.error.issueUnexpectedSkuNotFound",
    defaultMessage: "Unexpected SKU issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OVER_RECEIVE_ALREADY_EXISTS]: {
    id: "issues.error.issueOverReceiveAlreadyExists",
    defaultMessage: "Over receive issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OVER_RECEIVE_NOT_FOUND]: {
    id: "issues.error.issueOverReceiveNotFound",
    defaultMessage: "Over receive issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_NOT_FOUND]: {
    id: "issues.error.issueNotFound",
    defaultMessage: "Issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_TYPE_NOT_FOUND]: {
    id: "issues.error.issueTypeNotFound",
    defaultMessage: "Issue type {issueType} not found. Please contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_UNKNOWN_BARCODE_ALREADY_EXISTS]: {
    id: "issues.error.issueUnknownBarcodeAlreadyExists",
    defaultMessage: "Unknown barcode issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_UNKNOWN_BARCODE_NOT_FOUND]: {
    id: "issues.error.issueUnknownBarcodeNotFound",
    defaultMessage: "Unknown barcode issue {issueId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ILLEGIBLE_LOT_ALREADY_EXISTS]: {
    id: "issues.error.issueIllegibleLotAlreadyExists",
    defaultMessage: "Illegible lot issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ILLEGIBLE_EXPIRATION_ALREADY_EXISTS]: {
    id: "issues.error.issueIllegibleExpirationAlreadyExists",
    defaultMessage: "Illegible expiration issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_EXPIRED_PRODUCT_ALREADY_EXISTS]: {
    id: "issues.error.issueExpiredProductAlreadyExists",
    defaultMessage: "Expired product issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_OTHER_ALREADY_EXISTS]: {
    id: "issues.error.issueOtherAlreadyExists",
    defaultMessage: "Other issue already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ISSUE_ZENDESK_TICKET_ALREADY_EXISTS]: {
    id: "issues.error.issueZendeskTicketAlreadyExists",
    defaultMessage: "Zendesk ticket already exists. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.PACKAGE_NOT_FOUND]: {
    id: "issues.error.packageNotFound",
    defaultMessage: "Package {cdsku} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.PREP_JOB_NOT_FOUND]: {
    id: "issues.error.prepJobNotFound",
    defaultMessage: "Prep Job not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.PRODUCT_NOT_FOUND]: {
    id: "issues.error.productNotFound",
    defaultMessage: "Product {dsku} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.REPLENISHMENT_SHIPMENT_NOT_FOUND]: {
    id: "issues.error.replenishmentShipmentNotFound",
    defaultMessage: "Replenishment shipment {shipmentId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.SELLER_MISMATCH]: {
    id: "issues.error.sellerMismatch",
    defaultMessage: "The sellerId is different between entity and dsku.",
  },
  [NonComplianceErrorCodes.UNEXPECTED_ISSUE_EVENT_PAYLOAD]: {
    id: "issues.error.unexpectedIssueEventPayload",
    defaultMessage: "Unexpected issue event payload. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.WAREHOUSE_NOT_FOUND]: {
    id: "issues.error.warehouseNotFound",
    defaultMessage: "Warehouse {facilityId} not found. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ZENDESK_BUILDER_NOT_IMPLEMENTED]: {
    id: "issues.error.zendeskBuilderNotImplemented",
    defaultMessage: "Zendesk builder not implemented. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ZENDESK_TICKET_CREATION_FAILED]: {
    id: "issues.error.zendeskTicketCreationFailed",
    defaultMessage: "Zendesk ticket creation failed. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.ZENDESK_TICKET_UPDATE_FAILED]: {
    id: "issues.error.zendeskTicketUpdateFailed",
    defaultMessage: "Zendesk ticket update failed. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.BARCODE_NOT_LINKED_TO_DSKU]: {
    id: "issues.error.barcodeNotLinkedToDsku",
    defaultMessage: "Barcode {barcode} is not linked to {dsku}. Please try again or contact engineering.",
  },
  [NonComplianceErrorCodes.RECORD_ALREADY_EXISTS]: {
    id: "issues.error.recordAlreadyExists",
    defaultMessage: "{identifier}: {issueId} record already exists.",
  },
  [NonComplianceErrorCodes.RECORD_NOT_FOUND]: {
    id: "issues.error.recordNotFound",
    defaultMessage: "Issue {issueId} not found. Please try again or contact engineering.",
  },
});

export function useHandleNonComplianceError() {
  const { errorResponse } = useCommonFlow();
  const { formatMessage } = useIntl();

  const getNonComplianceErrorMessage = (error: NonComplianceError) => {
    // This will check for validation errors from the tsoa controller and map each failed key to a string that
    // shows it's value and the error message
    if (error.code === SharedErrorCodes.INVALID_INPUT) {
      // map over the object.values of error.payload and return a string like `value: message`
      // error.payload has a message and a value key
      return `${(Object.values(error.payload) as { message: string; value: string }[])
        .map((value) => `${value.value}: ${value.message}`)
        .join(", ")}
        requestId: ${
          // @ts-ignore
          error.requestId
        }`;
    }
    // This handles the known non_compliance errors with translations
    if (NonComplianceErrorCodes[error.code as NonComplianceErrorCodes]) {
      const formattedValues = error.payload && formatValues(error.payload);
      return NonComplianceErrorCodes[error.code as NonComplianceErrorCodes]
        ? formatMessage(NON_COMPLIANCE_ERROR_TO_TRANSLATION_MAP[error.code as NonComplianceErrorCodes], formattedValues)
        : error.message;
    }
    return error.message || "Unknown error";
  };

  const handleNoncomplianceError = (ctx: any, error: any, resetFn?: () => void) => {
    logError(ctx, error);
    errorResponse(resetFn);
    if (error.isAxiosError) {
      const errorCode = error?.response?.data?.code;
      if (NonComplianceErrorCodes[errorCode] || SharedErrorCodes[errorCode]) {
        return toast.error(getNonComplianceErrorMessage(error?.response?.data));
      }
    }

    toast.error(error?.payload?.message ?? error?.message);
  };

  return { handleNoncomplianceError };
}
