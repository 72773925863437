import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState } from "recoil";
import { createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKUConfirm, createReceivingSKULP } from "../storageReceivingCardCreators";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import {
  isForwardingStagingLocation,
  isFBABookingType,
  isPrepLocation,
  isPrepBookingType,
} from "warehouse/receiving/utils";
import { FORWARDING_STAGING_LOCATION } from "warehouse/common/messages/storage/StorageForwarding.const";
import { PREP_LOCATION } from "warehouse/common/messages/storage/StoragePrep.const";

export const useReceivingSKULCScanFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const [locationValue, setLocation] = useState<string>(scanState.currentSkuDetails?.location ?? "");
  const { addFlowCard, hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { showModal } = useWarehouseModal();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_LC_RECEIVING_BACK_BUTTON);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const handleChange = (location) => {
    setLocation(location);
  };

  const handleSubmit = (location: string) => {
    const ctx = { fn: "useReceivingSKULCScanFlow.handleSubmitLCScan", location };
    if (isPrepBookingType(scanState.poDetails.prepOption)) {
      if (!isPrepLocation(location)) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_RECEIVING_MESSAGES.invalidPrepLocationWarningModalTitle),
          text: formatMessage(STORAGE_RECEIVING_MESSAGES.invalidPrepLocationWarningModalText, {
            prepLocationPrefix: PREP_LOCATION,
          }),
        });
        return;
      }
    } else if (isFBABookingType(scanState.poDetails.bookingType)) {
      if (!isForwardingStagingLocation(location)) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_RECEIVING_MESSAGES.fbaInvalidForwardingLocationWarningModalTitle),
          text: formatMessage(STORAGE_RECEIVING_MESSAGES.fbaInvalidForwardingLocationWarningModalText, {
            forwardingStagingLocationPrefix: FORWARDING_STAGING_LOCATION,
          }),
        });
        return;
      }
    }
    logStart(ctx);

    setScanState((state) => {
      const updatedState = { ...state };
      updatedState.currentSkuDetails = { ...state.currentSkuDetails, location };
      return updatedState;
    });

    handleChange(location);
    transition([
      {
        card: createReceivingSKUConfirm({}),
        notification: createSuccessNotification(
          formatMessage(STORAGE_RECEIVING_MESSAGES.skuReceivedSuccessNotificationTitle)
        ),
        sfx: SoundFx.SUCCESS,
        flash: "SUCCESS",
        onTransition: () => {
          hideAllFlowButtons();
          showFlowButton(StorageFlowButtonType.STORAGE_SKU_CONFIRM_RECEIVING_BACK_BUTTON);
          showFlowButton(StorageFlowButtonType.STORAGE_CONFIRM_RECEIVE);
        },
      },
    ]);
  };

  const [{ loading: backLoading }, handleBackClick] = useAsyncFn(async () => {
    const handleBackReceiveSKUQtyScan = async () => {
      addFlowCard(createReceivingSKULP({}));
    };
    return handleBackReceiveSKUQtyScan();
  });

  return {
    locationValue,
    handleChange,
    handleSubmit,
    handleBackClick,
    backLoading,
  };
};
