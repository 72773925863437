import React, { useState } from "react";
import { useMountedState } from "react-use";

export function useLoadingButton(onClick: React.MouseEventHandler<HTMLButtonElement>) {
  const [loading, setLoading] = useState<boolean>(false);

  const isMounted = useMountedState();
  const onClickWithLoading: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (!onClick) {
      return;
    }

    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await onClick(e);
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return { loading, onClickWithLoading };
}
