import React from "react";
import { useIntl } from "react-intl";
import { PromptModal, warningModalIconLg } from "facility-commons";
import { outboundModalLabels } from "../outboundModalLabels";

export interface OutboundDiscardPackageModalProps {
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

export const OutboundDiscardPackageModal: React.FC<OutboundDiscardPackageModalProps> = ({
  onCancelClick,
  onConfirmClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <PromptModal
      title={formatMessage(outboundModalLabels.discardPackageTitle)}
      text={formatMessage(outboundModalLabels.discardPackageDescription)}
      iconProps={warningModalIconLg}
      cancelButtonLabel={formatMessage(outboundModalLabels.back)}
      onCancelClick={onCancelClick}
      confirmButtonLabel={formatMessage(outboundModalLabels.continue)}
      onConfirmClick={onConfirmClick}
    />
  );
};
