import { defineMessages, MessageDescriptor } from "react-intl";

export enum LotTrackingFefoErrorMessageKey {
  LOTS_NOT_SUPPORTED = "LOTS_NOT_SUPPORTED",
  NO_PRODUCT_LOT_TRACKING = "NO_PRODUCT_LOT_TRACKING",
  EXPIRATION_NOT_SUPPORTED = "EXPIRATION_NOT_SUPPORTED",
  NO_PRODUCT_EXPIRATION_TRACKING = "NO_PRODUCT_EXPIRATION_TRACKING",
  LOTS_EXPIRATION_NOT_SUPPORTED = "LOTS_EXPIRATION_NOT_SUPPORTED",
  MULTIPLE_DSKU_EXPIRATION = "MULTIPLE_DSKU_EXPIRATION",
  MULTIPLE_DSKU_LOTS = "MULTIPLE_DSKU_LOTS",
  MISSING_EXPIRATION_DATE = "MISSING_EXPIRATION_DATE",
  MISSING_LOT_NUMBER = "MISSING_LOT_NUMBER",
  MISSING_FEFO_LOT_VALUES = "MISSING_FEFO_LOT_VALUES",
  INVALID_EXPIRATION_DATE = "INVALID_EXPIRATION_DATE",
  MULTI_LOCATION_RECEIVE = "MULTI_LOCATION_RECEIVE",
}

export const LOT_TRACKING_FEFO_ERROR_BY_ERROR_CODE: Record<
  LotTrackingFefoErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [LotTrackingFefoErrorMessageKey.LOTS_NOT_SUPPORTED]: {
    id: "warehouse.receiving.LotNotSupported",
    defaultMessage:
      "Your warehouse is not enabled to handle lot numbers. Please contact your Deliverr operations manager.",
  },
  [LotTrackingFefoErrorMessageKey.NO_PRODUCT_LOT_TRACKING]: {
    id: "warehouse.receiving.noProductLotTracking",
    defaultMessage: "This product is not lot tracking enabled. You can not receive with a lot number.",
  },
  [LotTrackingFefoErrorMessageKey.EXPIRATION_NOT_SUPPORTED]: {
    id: "warehouse.receiving.expNotSupported",
    defaultMessage:
      "Your warehouse is not enabled to handle expiration dates. Please contact your Deliverr operations manager.",
  },
  [LotTrackingFefoErrorMessageKey.NO_PRODUCT_EXPIRATION_TRACKING]: {
    id: "warehouse.receiving.noProductExpTracking",
    defaultMessage: "This product is not expiry tracking enabled. You can not receive with an expiration date.",
  },
  [LotTrackingFefoErrorMessageKey.LOTS_EXPIRATION_NOT_SUPPORTED]: {
    id: "warehouse.receiving.lotExpNotSupported",
    defaultMessage:
      "Your warehouse is not enabled to handle expiration dates or lot numbers. Please contact your Deliverr operations manager.",
  },
  [LotTrackingFefoErrorMessageKey.MULTIPLE_DSKU_EXPIRATION]: {
    id: "warehouse.receiving.multipleDSKUExp",
    defaultMessage:
      "Cannot receive box with the same items having different expiration dates. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.MULTIPLE_DSKU_LOTS]: {
    id: "warehouse.receiving.multipleDSKULot",
    defaultMessage:
      "Cannot receive box with the same items having different lot numbers. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.MISSING_EXPIRATION_DATE]: {
    id: "warehouse.receiving.missingExpDate",
    defaultMessage:
      "Cannot receive box as it is missing an expected expiration date. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.MISSING_LOT_NUMBER]: {
    id: "warehouse.receiving.missingLotNumber",
    defaultMessage:
      "Cannot receive box as it is missing an expected lot number. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.MISSING_FEFO_LOT_VALUES]: {
    id: "warehouse.receiving.missingLotFefoValues",
    defaultMessage:
      "Cannot receive box as it is missing an expected expiration date AND lot number. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.INVALID_EXPIRATION_DATE]: {
    id: "warehouse.receiving.invalidExpDate",
    defaultMessage:
      "Cannot receive box as it has an invalid expected expiration date. Please create a non-compliance ticket.",
  },
  [LotTrackingFefoErrorMessageKey.MULTI_LOCATION_RECEIVE]: {
    id: "warehouse.location.multiLocReceiveError",
    defaultMessage:
      "Cannot receive box with products that have expiration dates or lot numbers to multiple locations simultaneously. Please receive to one location at a time.",
  },
});
