import { atom } from "recoil";
import { ReturnReceiveStateKey } from "./ReturnReceiveStateKey";
import { RETURN_RECEIVE_DEFAULT_QUANTITY } from "../constants";

export interface ReturnReceiveReceivingDataState {
  dedupKey: string;
  requestBatchId: string;
  location: string;
  quantity: number;
  expirationDate: string | null;
  lotNumber: string | null;
}

export const RETURN_RECEIVE_RECEIVING_DATA_INITIAL_STATE: ReturnReceiveReceivingDataState = {
  dedupKey: "",
  requestBatchId: "",
  location: "",
  quantity: RETURN_RECEIVE_DEFAULT_QUANTITY,
  expirationDate: null,
  lotNumber: null,
};
export const returnReceiveReceivingDataStateAtom = atom<ReturnReceiveReceivingDataState>({
  key: ReturnReceiveStateKey.RECEIVING_DATA_STATE,
  default: RETURN_RECEIVE_RECEIVING_DATA_INITIAL_STATE,
});
