import { keyframes } from "@emotion/core";

const slideInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
`;

export const animation = {
  slideInLeft,
  fadeIn,
};
