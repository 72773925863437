import { Anchor, BoxReset, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { last } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

interface ZendeskCardProps {
  zendeskUrl: string;
}

const StyledAnchor = styled(Anchor)<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F3};  
  `
);

export const ZendeskCard: React.FC<ZendeskCardProps> = ({ zendeskUrl }) => {
  const ticketId = last(zendeskUrl.split("/"));
  const { formatMessage } = useIntl();
  return (
    <>
      <BoxReset marginBottom="S2">
        <Text>
          {formatMessage({
            id: "warehouse.ticketCenter.zendeskCard.viewDetails",
            defaultMessage: "View details and reply to support in",
          })}
        </Text>
      </BoxReset>
      <StyledAnchor href={zendeskUrl}>
        {formatMessage(
          {
            id: "warehouse.ticketCenter.zendeskCard.zendeskTicket",
            defaultMessage: "Zendesk ticket #{ticketId}",
          },
          { ticketId }
        )}
      </StyledAnchor>
    </>
  );
};
