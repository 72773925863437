import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { StorageNonCompliantProduct } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const INITIAL_PRODUCT: StorageNonCompliantProduct & { reportedExpiry?: Date } = {
  backPicture: "",
  frontPicture: "",
  incidentId: "",
  isRestricted: false,
  numberOfBoxes: 1,
  quantityPerBox: 1,
  uploadedImages: {},
};

export const currentProductState = atom<StorageNonCompliantProduct & { reportedExpiry?: Date }>({
  key: WarehouseStateKey.CURRENT_PRODUCT,
  default: INITIAL_PRODUCT,
});
