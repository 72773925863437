// formatDuration(12) --> "0:12"
export function formatDuration(seconds: number): string {
  if (seconds >= 60 || seconds <= 0) {
    throw new Error("INVALID_SECONDS");
  } else if (seconds < 10) {
    return "0:0" + seconds;
  } else {
    return "0:" + seconds;
  }
}
