export enum StorageErrorCodes {
  ASN_NOT_FOUND = "ASN_NOT_FOUND",
  BARCODE_NOT_FOUND = "BARCODE_NOT_FOUND",
  UNUSABLE_LOCATION = "UNUSABLE_LOCATION",
  MISSING_LOCATION = "MISSING_LOCATION",
  BARCODE_NOT_ON_ASN = "BARCODE_NOT_ON_ASN",
  POSITIVE_VALUE = "POSITIVE_VALUE",
  DATABASE_ERROR = "DATABASE_ERROR",
  NOT_FOUND = "NOT_FOUND",
  BAD_REQUEST = "BAD_REQUEST",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  PRODUCT_DETAIL_FETCH_FAILED = "PRODUCT_DETAIL_FETCH_FAILED",
}
