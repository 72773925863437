import { NavbarProps } from "@deliverr/ui";
import { useRouter } from "facility-commons/hooks";
import { NavigationConfiguration } from "../../types";

export interface FacilityHeaderProps<RouteEnum extends string, BaseRouteEnum extends string = RouteEnum>
  extends NavbarProps,
    NavigationConfiguration<RouteEnum, BaseRouteEnum> {
  /** The active tab component to show -- if any */
  tabControl?: React.ReactNode;
  /** Hides the shadow under the navbar -- used to be backwards compatible with WarehouseHeader */
  hideNavbarShadow?: boolean;
  /** allows app specific back button logic */
  customOnBackButtonClick?: () => void;
}

export const useFacilityHeader = <RouteEnum extends string, BaseRouteEnum extends string>({
  rootPages,
  title,
  subtitle,
  routeTitles,
  routeSubtitles,
  basePath,
}: FacilityHeaderProps<RouteEnum, BaseRouteEnum>) => {
  const { history, pathname, locationPathname } = useRouter();

  const currentPath = locationPathname.split("/").slice(1);
  const currentPage = currentPath[currentPath.length - 1];

  // determine if the current page is a root / main page in the app.
  // prepend the base path if it exists.
  const isRootPage = rootPages.map((path) => (basePath ? basePath + path : path)).includes(pathname as BaseRouteEnum);
  const shouldShowBackButton = !isRootPage;

  const handleClickBackButton = () => history.goBack();

  // Allow caller to override title / subtitle with explicitly defined values
  // TODO: remove the "/" fallback once we have a better way to handle this
  const displayedTitle = title || (routeTitles[currentPage] ?? routeTitles["/" + currentPage] ?? "");
  const displayedSubtitle = subtitle || (routeSubtitles[currentPage] ?? routeSubtitles["/" + currentPage] ?? "");

  return {
    isRootPage,
    currentPath,
    currentPage,
    shouldShowBackButton,
    handleClickBackButton,
    displayedTitle,
    displayedSubtitle,
  };
};
