import React from "react";
import { Text } from "@deliverr/ui";

// Put this react-intl values prop to markup i18 text
// Example: <FormattedMessage id="facilityCommons.id" defaultMessage="lorem <boldtext>ipsum</boldtext> dolor sit amet" values={richText({ other: values })}/>
export const richText = (values: Record<string, React.ReactNode>) => ({
  ...values,
  boldtext: (text: string) => (
    <Text as="span" bold>
      {text}
    </Text>
  ),
});
