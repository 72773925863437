import { ProductCasePackData } from "@deliverr/commons-clients";
import { LengthUnit } from "@deliverr/freight-client";
import { UNIT_SYMBOLS } from "facility-commons/utils/unitSymbols";

export const getCaseConfigString = (config: Partial<ProductCasePackData>) => {
  return `${config.weight} ${config.weightUnit} . ${config.length} ${
    UNIT_SYMBOLS[config.lengthUnit ?? LengthUnit.In]
  } x ${config.width} ${UNIT_SYMBOLS[config.lengthUnit ?? LengthUnit.In]} x ${config.height} ${
    UNIT_SYMBOLS[config.lengthUnit ?? LengthUnit.In]
  }`;
};
