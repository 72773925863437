import React from "react";
import { useIntl } from "react-intl";
import { MAX_TEXTAREA_CHARS } from "warehouse/common/utils";
import { TextAreaPage } from "warehouse/ticket-center/components/TextAreaPage/TextAreaPage";
import { ADDITIONAL_DETAILS_LABELS } from "../../content/details";
import { RestrictedProductCard } from "./RestrictedProductCard";
import { useAdditionalCaseDetailsPage } from "./useAdditionalCaseDetailsPage";

export const AdditionalCaseDetailsPage: React.FC = () => {
  const { isRestricted, notes, goToNextPage, toggleRestricted, updateDetails } = useAdditionalCaseDetailsPage();
  const { formatMessage } = useIntl();

  return (
    <TextAreaPage
      textAreaValue={notes}
      onChange={updateDetails}
      continueOnClick={goToNextPage}
      sibling={<RestrictedProductCard isRestricted={isRestricted} toggleRestricted={toggleRestricted} />}
      placeholder={formatMessage(ADDITIONAL_DETAILS_LABELS.detailsPlaceholder)}
      title={formatMessage(ADDITIONAL_DETAILS_LABELS.provideDetailsTitle)}
      charLimit={MAX_TEXTAREA_CHARS}
    />
  );
};
