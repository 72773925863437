import { useState } from "react";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { log, logStart, printPdf } from "facility-commons/utils";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { useRecoilValue } from "recoil";
import { userState } from "facility-commons/base/Auth/userState";

export function useStoragePrintSSCCLabels() {
  const { successResponse, handleUnknownError, errorResponse } = useWarehouseFlow();
  const { warehouseId } = useRecoilValue(userState);
  const { storageClient } = useClientsWithAuth();
  const [poNum, setPONum] = useState<number>();
  const [sscc, setSSCC] = useState<string>();
  const [locationId, setLocationId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const updatePONum = (eventValue: React.ChangeEvent<HTMLInputElement>) => {
    setPONum(Number(eventValue.target.value));
  };

  const updateSSCC = (eventValue: React.ChangeEvent<HTMLInputElement>) => {
    setSSCC(eventValue.target.value);
  };

  const updateLocationId = (eventValue: React.ChangeEvent<HTMLInputElement>) => {
    setLocationId(eventValue.target.value);
  };

  const requestSSCCLabels = async (): Promise<void> => {
    const ctx = logStart({ fn: "requestSSCCLabels", poNum, sscc, locationId });

    try {
      if (!poNum || !sscc || !locationId) {
        return;
      }
      setLoading(true);
      const response = await storageClient.getStorageLPNContentLabel(String(poNum), warehouseId, sscc, locationId);
      printPdf(new Blob([response], { type: "application/pdf" }));
      log(ctx, "generated sscc labels PDF");
      successResponse();
      setPONum(undefined);
      setLocationId("");
      setSSCC("");
    } catch (error) {
      handleUnknownError(ctx, error);
      errorResponse();
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestSSCCLabels();
  };

  const printDisabled = loading || !poNum || Number.isNaN(poNum) || !sscc || !locationId;

  return {
    requestSSCCLabels,
    poNum,
    locationId,
    sscc,
    updateLocationId,
    updateSSCC,
    updatePONum,
    loading,
    printDisabled,
    onSubmit,
  };
}
