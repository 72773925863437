import * as React from "react";
import { Text } from "@deliverr/ui";
import styled from "@emotion/styled";

const CharLimitCounterText = styled(Text)`
  text-align: right;
`;

export interface CharLimitCounterProps {
  charLimit: number;
  currentCharCount: number;
}

export const CharLimitCounter: React.FC<CharLimitCounterProps> = ({ charLimit, currentCharCount }) => {
  return (
    <CharLimitCounterText appearance="INFO" size="caption">{`${currentCharCount}/${charLimit}`}</CharLimitCounterText>
  );
};
