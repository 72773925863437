import React from "react";
import { shipmentSummaryCardLabels } from "./shipmentSummaryCardLabels";
import { composeFacilityCommonsButton } from "facility-commons/components/buttons/common/composeFacilityCommonsButton";
import { useRecoilValue } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { convertBase64StringToBlob } from "facility-commons/utils/convertBase64StringToBlob";
import { log, logStart, printPdf } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useShipmentSummaryReprintAllLabelsButton } from "./useShipmentSummaryReprintAllLabelsButton";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";

const ReprintLabelsButton = composeFacilityCommonsButton(shipmentSummaryCardLabels.reprintAllLabelsButton);

export const ShipmentSummaryReprintAllLabelsButton: React.FC = () => {
  const { reprintAllLabels } = useShipmentSummaryReprintAllLabelsButton();
  const { numOfPackagesCreated } = useRecoilValue(shipmentItemsSelector);
  const isDisabled = numOfPackagesCreated === 0;

  return (
    <ReprintLabelsButton
      block
      data-testid="outbounds-order-packing-reprint-all-labels"
      loading={false}
      onClick={reprintAllLabels}
      disabled={isDisabled}
    />
  );
};
