import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useRef } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useUnmount } from "react-use";
import { useSetRecoilState } from "recoil";
import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { setProp } from "facility-commons/utils";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehousePortalRoutes } from "warehouse/routes";
import { OutboundOptions } from "./components/OutboundOptions";
import { OutboundsPath } from "./routes";
import { RSOutboundsPackingFlow } from "./RSOutboundsPackingFlow";

export const OutboundsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: ${MAX_CARD_WIDTH};
  width: 100%;
  height: 100%;
`;

export const CardContainer = styled.section<Record<string, unknown>, DefaultTheme>`
  flex-grow: 1;
  width: 100%;

  // reduce space between cards and buttons container on larger devices
  @media (min-width: ${({ theme }) => theme.breakpoints.MD}) {
    flex-grow: 0;
    margin-bottom: 1rem;
  }
`;

export const Outbounds: React.FC = () => {
  const { path } = useRouteMatch();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { resetNotifications } = useCommonFlow();
  const containerRef = useRef<HTMLDivElement>(null);

  useUnmount(() => {
    setWarehouseAppState(setProp("pageTitle", ""));
    setWarehouseAppState(setProp("pageSubtitle", ""));
    resetNotifications();
  });

  const appendToPath = (subPath) => {
    return `${path}/${subPath}`;
  };

  return (
    <OutboundsContainer ref={containerRef}>
      <CardContainer>
        <Switch>
          <Route path={appendToPath(OutboundsPath.ORDER_PACKING)} component={RSOutboundsPackingFlow} />

          <Route exact path={path} render={() => <OutboundOptions />} />
          <Redirect to={WarehousePortalRoutes.OUTBOUNDS} />
        </Switch>
      </CardContainer>
    </OutboundsContainer>
  );
};
