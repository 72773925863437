import React from "react";
import { Route } from "react-router-dom";

import { WarehousePortalRoutes } from "warehouse/routes";
import { NewTicketSuccess, TicketCenterDashboard, TicketDetails } from "./components";
import { CreateTicketTypeSelector } from "./new";
import { UnsolvableDetailsPage } from "./new/non-compliance/cards/UnsolvableDetails";

export const TicketCenterRoutes = [
  <Route
    exact
    key={WarehousePortalRoutes.TICKET}
    path={WarehousePortalRoutes.TICKET}
    render={() => <TicketDetails />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.TICKET_UNSOLVABLE_DETAILS}
    path={WarehousePortalRoutes.TICKET_UNSOLVABLE_DETAILS}
    render={() => <UnsolvableDetailsPage />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.SUCCESS}
    path={WarehousePortalRoutes.SUCCESS}
    render={() => <NewTicketSuccess />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.NEW_TICKET}
    path={WarehousePortalRoutes.NEW_TICKET}
    render={() => <CreateTicketTypeSelector />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.TICKET_CENTER}
    path={WarehousePortalRoutes.TICKET_CENTER}
    render={() => <TicketCenterDashboard />}
  />,
];
