import UIfx, { config } from "uifx";

import successSound from "facility-commons/common/assets/soundEffects/success.mp3";
import infoSound from "facility-commons/common/assets/soundEffects/info.mp3";
import errorSound from "facility-commons/common/assets/soundEffects/error.mp3";
import fastErrorSound from "facility-commons/common/assets/soundEffects/fast-error.mp3";
import unexpectedQtySound from "facility-commons/common/assets/soundEffects/unexpected-quantity.wav";

// SFX source: https://uisoundkit.appsounds.pro/

export enum SoundFx {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  ERROR = "ERROR",
  ERROR_FAST = "ERROR_FAST",
  UNEXPECTED_QUANTITY = "UNEXPECTED_QUANTITY",
}

const defaultSfxConfig: config = {
  volume: 1,
  throttleMs: 100,
};

export const appSfx: Record<SoundFx, UIfx> = {
  [SoundFx.SUCCESS]: new UIfx(successSound, defaultSfxConfig),
  [SoundFx.INFO]: new UIfx(infoSound, defaultSfxConfig),
  [SoundFx.ERROR]: new UIfx(errorSound, defaultSfxConfig),
  [SoundFx.ERROR_FAST]: new UIfx(fastErrorSound, defaultSfxConfig),
  [SoundFx.UNEXPECTED_QUANTITY]: new UIfx(unexpectedQtySound, defaultSfxConfig),
};
