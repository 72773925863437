import React from "react";
import { Text } from "@deliverr/ui";
import { CardColumn } from "facility-commons/components/emotion/cards";
import { FormattedMessage } from "react-intl";
import { restrictedMessages } from "../../../content";

interface RestrictedProductSectionProps {
  isRestricted: string;
}

export const RestrictedProductSection: React.FC<RestrictedProductSectionProps> = ({ isRestricted }) => (
  <CardColumn>
    <Text bold>
      <FormattedMessage {...restrictedMessages.restrictedProduct} />
    </Text>
    {isRestricted}
  </CardColumn>
);
