import { Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PointerAnchor } from "facility-commons/components/emotion";
import { UNSOLVABLE_TICKET_SECTION_LABELS } from "./UnsolvableTicketSection.labels";

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["300"]};
`
);

export interface UnsolvableTicketSectionProps {
  onClickUnsolvable: () => void;
}

export const UnsolvableTicketSection: React.FC<UnsolvableTicketSectionProps> = ({ onClickUnsolvable }) => {
  return (
    <div>
      <StyledText size="body">
        <FormattedMessage {...UNSOLVABLE_TICKET_SECTION_LABELS.prompt} />
      </StyledText>
      <PointerAnchor onClick={onClickUnsolvable}>
        <FormattedMessage {...UNSOLVABLE_TICKET_SECTION_LABELS.button} />
      </PointerAnchor>
    </div>
  );
};
