import { FACILITY_THEME } from "facility-commons/theme";

export type AccentColor = "GRAY" | "GREEN" | "BLUE" | "RED" | "YELLOW";

const accentColorMap: Record<AccentColor, string> = {
  GRAY: FACILITY_THEME.colors.NEUTRAL[80],
  GREEN: FACILITY_THEME.colors.GREEN[300],
  BLUE: FACILITY_THEME.colors.BLUE[300],
  RED: FACILITY_THEME.colors.RED[300],
  YELLOW: FACILITY_THEME.colors.YELLOW[300],
};

export function getAccentColorValue(accentColor: AccentColor): string {
  return accentColorMap[accentColor];
}
