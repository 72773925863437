import { logStart } from "facility-commons";
import { storageClient } from "facility-commons/base/Clients";

export const markPOComplete = async (poNum: string, warehouseId: string): Promise<void> => {
  const ctx = { fn: "markPOComplete", poNum };
  logStart(ctx);
  try {
    await storageClient.markPOCompletion(poNum, warehouseId);
  } catch (err: any) {
    throw err;
  }
};
