import { DefaultTheme, Stack, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

export const StyledStack = styled(Stack)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  // styling warehouseCards based on position to make them stacked.
  a {
    margin: 0;
    div {
      border-radius: 0;
    }
    :first-of-type div {
      border-radius: ${theme.border.radius.R5} ${theme.border.radius.R5}  0 0;
    }
    :last-of-type div {
      border-bottom-left-radius:${theme.border.radius.R5};
      border-bottom-right-radius: ${theme.border.radius.R5};
    }
    :not(:last-of-type) div {
      border-bottom: 0;
    }
  }
`
);
