import { useRecoilState } from "recoil";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { useAsyncFn, useLifecycles } from "react-use";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { createDangerNotification, DateErrorType, isValidDate, logStart } from "facility-commons";
import { STORAGE_LOT_INFO_MESSAGE } from "warehouse/common/messages/storage";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKUScan } from "../storageReceivingCardCreators";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const MAX_LOT_VALUE_INPUT_LENGTH = 20;

export const useStorageReceivingLotInfo = () => {
  const { resetNotifications, errorResponse } = useCommonFlow();
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const { lotNumber, expirationDate } = scanState.currentSkuDetails;
  const currentSKU = scanState.currentSKU;
  const isLotTrackingEnabled = scanState.skus?.[currentSKU]?.isLotTrackingEnabled;
  const isFefoEnabled = scanState.skus?.[currentSKU]?.isFefoEnabled;
  const { addFlowCard, hideAllFlowButtons, showFlowButton, addAutoCloseNotification } = useWarehouseFlow();
  const { formatMessage } = useIntl();
  const { hideModal, showModal } = useWarehouseModal();

  const showErrorNotification = (errorMsg: string) => {
    addAutoCloseNotification(createDangerNotification(errorMsg));
  };

  const setLotNumber = (lotNumberValue?: string) => {
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        lotNumber: lotNumberValue,
      },
    }));
  };

  const setExpirationDate = (expirationDateValue?: string) => {
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        expirationDate: expirationDateValue,
      },
    }));
  };

  const setFormattedExpirationDate = (formattedDate?: string) => {
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        formattedExpirationDate: formattedDate,
      },
    }));
  };

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_LOT_INFO_RECEIVING_BACK_BUTTON);
      showFlowButton(StorageFlowButtonType.STORAGE_LOT_INFO_RECEIVING_CONTINUE_BUTTON);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  // for lot input characters we allow alphanumeric, -, _, /, and spaces
  const lotInputRegex = /^[a-zA-Z0-9-_\ \/]*$/;
  const onLotChange = (value) => {
    // allow only alphanumeric entries
    if (!lotInputRegex.test(value)) {
      return;
    }
    setLotNumber(value);
  };

  interface DateInput {
    target: { value: string };
  }

  const onExpirationDateChange = ({ target: { value } }: DateInput) => {
    if (!value) {
      return setExpirationDate("");
    }
    setExpirationDate(value);
  };

  const validateExpirationDate = (expirationDateValue?: string) => {
    // expiration date input validations
    if (!expirationDateValue) {
      showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.missingExpirationDate));
      return false;
    }
    const dateValidation = isValidDate(expirationDateValue);
    const dateParts = dateValidation?.dateParts;

    // though the input placeholder date format is MM/DD/YYYY,
    // the actual format saved in the backend is YYYY-MM-DD.
    const formattedDate = dateParts && `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;

    if (dateValidation.error) {
      switch (dateValidation.error) {
        case DateErrorType.INVALID_DATE:
          errorResponse();
          setExpirationDate("");
          showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.incorrectExpirationDateFormat));
          return false;
        case DateErrorType.INVALID_MONTH:
          errorResponse();
          setExpirationDate("");
          showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.invalidMonth));
          return false;
        case DateErrorType.INVALID_DAY:
          errorResponse();
          setExpirationDate("");
          showErrorNotification(
            formatMessage(STORAGE_LOT_INFO_MESSAGE.invalidDay, { lastDateOfMonth: dateValidation.lastDateOfMonth })
          );
          return false;
        default:
          setFormattedExpirationDate(formattedDate);
          return true;
      }
    } else {
      setFormattedExpirationDate(formattedDate);
    }

    // we allow for receives of expired products but we prompt app user to create NC ticket after completing the receive
    if (formattedDate) {
      const enteredDate = new Date(formattedDate);
      const currentDate = new Date();
      if (enteredDate <= currentDate) {
        errorResponse();
        showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.expiredProductsReceived));
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const [{ loading: backLoading }, handleBackClick] = useAsyncFn(() => {
    const handleBackReceiveLotInfo = async () => {
      addFlowCard(createReceivingSKUScan({}));
    };
    return handleBackReceiveLotInfo();
  });

  const [{ loading }, handleSubmit] = useAsyncFn(async (lotNumberValue?: string, expirationDateValue?: string) => {
    const ctx = { fn: "useStorageReceivingLotInfo.handleSubmitLotInfo", lotNumberValue, expirationDateValue };
    logStart(ctx);
    if (isLotTrackingEnabled) {
      if (!lotNumberValue) {
        showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.missingLotEntry));
        return;
      } else if (lotNumberValue?.length > MAX_LOT_VALUE_INPUT_LENGTH) {
        setLotNumber("");
        showErrorNotification(
          formatMessage(STORAGE_LOT_INFO_MESSAGE.exceededMaxCharacterCount, {
            characterLimit: MAX_LOT_VALUE_INPUT_LENGTH,
          })
        );
        return;
      } else if (!lotInputRegex.test(lotNumberValue)) {
        setLotNumber("");
        showErrorNotification(formatMessage(STORAGE_LOT_INFO_MESSAGE.invalidLotInput));
        return;
      }
    }

    if (isFefoEnabled) {
      if (!validateExpirationDate(expirationDateValue)) {
        return;
      }
    }

    showModal(WarehouseModal.CONFIRM_LOT_EXPIRY, {
      requireLotInput: isLotTrackingEnabled,
      requireExpiryInput: isFefoEnabled,
      previousLotInput: lotNumberValue,
      previousExpiryInput: expirationDateValue,
      onCancel: () => {
        hideModal(WarehouseModal.CONFIRM_LOT_EXPIRY);
      },
    });
    // clear the input fields so user must look at physical product for lot/expiry info
    setLotNumber("");
    setExpirationDate("");
    return;
  });

  const handleClick = () => {
    handleSubmit(lotNumber, expirationDate);
  };

  const disableContinueButton =
    (isLotTrackingEnabled && isEmpty(lotNumber)) || (isFefoEnabled && isEmpty(expirationDate));

  return {
    lotNumber,
    expirationDate,
    disableContinueButton,
    isLotTrackingEnabled,
    isFefoEnabled,
    onLotChange,
    onExpirationDateChange,
    backLoading,
    handleBackClick,
    loading,
    handleClick,
    handleSubmit,
  };
};
