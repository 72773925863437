export enum StorageFlowButtonType {
  STORAGE_CONTINUE_PALLET_COUNT_RECEIVING = "STORAGE_CONTINUE_PALLET_COUNT_RECEIVING",
  STORAGE_PO_DONE_RECEIVING = "STORAGE_PO_DONE_RECEIVING",
  STORAGE_CONTINUE_SKU_QUANTITY_RECEIVING = "STORAGE_CONTINUE_SKU_QUANTITY_RECEIVING",
  STORAGE_SKU_QUANTITY_RECEIVING_BACK_BUTTON = "STORAGE_SKU_QUANTITY_RECEIVING_BACK_BUTTON",
  STORAGE_SKU_CASE_INFO_RECEIVING_CONTINUE_BUTTON = "STORAGE_SKU_CASE_INFO_RECEIVING_CONTINUE_BUTTON",
  STORAGE_SKU_CASE_INFO_RECEIVING_BACK_BUTTON = "STORAGE_SKU_CASE_INFO_RECEIVING_BACK_BUTTON",
  STORAGE_SKU_CASE_PACK_SELECTION_CONTINUE_BUTTON = "STORAGE_SKU_CASE_PACK_SELECTION_CONTINUE_BUTTON",
  STORAGE_SKU_CASE_PACK_SELECTION_BACK_BUTTON = "STORAGE_SKU_CASE_PACK_SELECTION_BACK_BUTTON",
  STORAGE_LOT_INFO_RECEIVING_BACK_BUTTON = "STORAGE_LOT_INFO_RECEIVING_BACK_BUTTON",
  STORAGE_LOT_INFO_RECEIVING_CONTINUE_BUTTON = "STORAGE_LOT_INFO_RECEIVING_CONTINUE_BUTTON",
  STORAGE_SKU_CONFIRM_RECEIVING_BACK_BUTTON = "STORAGE_SKU_CONFIRM_RECEIVING_BACK_BUTTON",
  STORAGE_SKU_LP_RECEIVING_BACK_BUTTON = "STORAGE_SKU_LP_RECEIVING_BACK_BUTTON",
  STORAGE_SKU_LC_RECEIVING_BACK_BUTTON = "STORAGE_SKU_LC_RECEIVING_BACK_BUTTON",
  STORAGE_CONFIRM_RECEIVE = "STORAGE_CONFIRM_RECEIVE",
}
