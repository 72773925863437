export enum RSOutboundsFlowButtonType {
  RS_OUTBOUNDS_CREATE_PACKAGE = "RS_OUTBOUNDS_CONTINUE_PALLET_COUNT_RECEIVING",
  RS_OUTBOUNDS_REPRINT_LABELS = "RS_OUTBOUNDS_REPRINT_LABELS",
  RS_OUTBOUNDS_SCAN_NEXT_SHIPMENT = "RS_OUTBOUNDS_SCAN_NEXT_SHIPMENT",
  RS_OUTBOUNDS_CLOSE_PACKAGE = "RS_OUTBOUNDS_CLOSE_PACKAGE",
  RS_OUTBOUNDS_SELECT_BOX_SIZE_CONTINUE = "RS_OUTBOUNDS_SELECT_BOX_SIZE_CONTINUE",
  RS_OUTBOUNDS_SELECT_BOX_SIZE_BACK = "RS_OUTBOUNDS_SELECT_BOX_SIZE_BACK",
  RS_OUTBOUNDS_DISCARD_PACKAGE = "RS_OUTBOUNDS_DISCARD_PACKAGE",
  RS_OUTBOUNDS_PENDING_ITEMS_BACK = "RS_OUTBOUNDS_PENDING_ITEMS_BACK",
}
