import { Button, ThemeProps } from "@deliverr/ui";
import { ModalTemplate } from "facility-commons";
import React from "react";
import { Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useReturnReceiveItemInspectionStandardsModal } from "./useReturnReceiveItemInspectionStandardsModal";

const StyledListItem = styled.li<ThemeProps>(
  ({ theme }) => `
  font-family: ${theme.font.family.STANDARD};
  font-size: ${theme.font.size.F3};
  font-weight: ${theme.font.weight.REGULAR};
  line-height: ${theme.font.lineHeight.LH3};
  letter-spacing: 0px;
  text-align: left;
  list-style: disc !important;
  margin-left: ${theme.spacing.S4};
`
);

export const ReturnReceiveItemInspectionStandardsModal: React.FC = () => {
  const { inspectionSOPName, inspectionSteps, messages, onClose } = useReturnReceiveItemInspectionStandardsModal();
  return (
    <ModalTemplate
      data-testid="return-receive-item-inspection-standards-modal"
      title={messages.title}
      buttonLayout="column"
      content={
        <>
          <Text bold>{inspectionSOPName}</Text>
          <ul>
            {inspectionSteps.map((step, index) => (
              <StyledListItem key={`return.receive.item.inspectionStep.${index}`}>{step}</StyledListItem>
            ))}
          </ul>
        </>
      }
    >
      <Button data-testid="return.receive.item.inspection.close.button" block onClick={onClose} type="button">
        {messages.close}
      </Button>
    </ModalTemplate>
  );
};
