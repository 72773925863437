import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";
import { setProp, TabConfig } from "facility-commons";
import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { TICKET_LIST_TABS_LABELS } from "./TicketListTabs.labels";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useResetNCFilterState } from "warehouse/ticket-center/common/hooks";

export const useTicketListTabs = () => {
  const { formatMessage } = useIntl();
  const resetFilterState = useResetNCFilterState();
  const { showModal } = useWarehouseModal();

  const [{ currentTicketStatusDisplayed, todoCount, ticketList }, setTicketCenterState] = useRecoilState(
    ticketCenterState
  );

  const ticketListTabs: TabConfig<TicketCenterNonComplianceCaseStatus>[] = Object.entries(TICKET_LIST_TABS_LABELS).map(
    ([tabKey, tabDescriptor]) => {
      return {
        value: tabKey as TicketCenterNonComplianceCaseStatus,
        label: formatMessage(tabDescriptor),
        count: tabKey === TicketCenterNonComplianceCaseStatus.TODO ? todoCount : undefined,
      };
    }
  );
  const onTabSwitch = (newTab: TicketCenterNonComplianceCaseStatus) => {
    resetFilterState();
    setTicketCenterState(setProp("currentTicketStatusDisplayed", newTab));
  };
  const openFilterMenu = () => {
    showModal(WarehouseModal.FILTER_MENU, {});
  };
  return {
    currentTicketStatusDisplayed,
    formatMessage,
    ticketListTabs,
    ticketList,
    openFilterMenu,
    onTabSwitch,
  };
};
