import React from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";
import { useTicketDetailPage } from "./useTicketDetailPage";

const ComponentContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    flex: 1 1 0;
    margin-bottom: ${theme.spacing.S3};
  `
);

export const TicketDetails: React.FC = () => {
  const { TicketComponent } = useTicketDetailPage();

  return (
    <ComponentContainer>
      <TicketComponent />
    </ComponentContainer>
  );
};
