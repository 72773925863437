import React from "react";
import ReactDOM from "react-dom";

import { WarehouseApp } from "warehouse/App";
import { initializeZebraApp } from "facility-commons/base/initializeZebraApp";
import "facility-commons/index.css";
import { AppName } from "facility-commons/types";
import { initFullStory } from "facility-commons/base/fullstory";
import { initDataDog } from "facility-commons/utils/thirdParty/DataDog/DataDog";

function renderApp() {
  ReactDOM.render(<WarehouseApp />, document.getElementById("root"));
}

function initializeReceivingApp() {
  initFullStory();
  initDataDog();
  initializeZebraApp(AppName.WP, renderApp);
}

initializeReceivingApp();
