import { useForm } from "react-hook-form";
import { useAsync } from "react-use";
import { useState } from "react";
import { hasLowerCase, validDSKU } from "facility-commons/utils/config";
import { HazmatInformation } from "@deliverr/commons-clients/lib/product/HazmatInformation";
import { HazmatFormField } from "./HazmatForm.types";
import { validationConfig, HazmatFormValidationMap } from "./HazmatFormConfig";
import { useClientsWithAuth } from "facility-commons/hooks/auth";

export const useHazmat = () => {
  const { productClient } = useClientsWithAuth();
  const [hazmat, setHazmat] = useState<HazmatInformation | undefined | Record<string, unknown>>();
  const formHandler = useForm({
    mode: "onBlur",
  });

  const watchBarcode: string = formHandler.watch(HazmatFormField.BARCODE);

  // Triggers when barcode is scanned or typed in.
  // This will uppercase the text and when it's a valid DSKU, it will look up hazmat information.
  useAsync(async () => {
    if (watchBarcode && hasLowerCase.test(watchBarcode)) {
      formHandler.setValue(HazmatFormField.BARCODE, watchBarcode.toUpperCase());
    } else {
      setHazmat(undefined);
    }

    if (watchBarcode.match(validDSKU)) {
      try {
        const res = await productClient.getProductAuth0(watchBarcode, {
          includeHazmatInformation: true,
        });
        const hazmatInformation = res?.hazmatInformation;
        setHazmat(hazmatInformation);
      } catch (err) {
        setHazmat(undefined);
        formHandler.setError(...HazmatFormValidationMap.unknown);
      }
    }
  }, [watchBarcode]);

  return {
    formHandler,
    register: {
      [HazmatFormField.BARCODE]: formHandler.register(
        HazmatFormField.BARCODE,
        validationConfig[HazmatFormField.BARCODE]
      ),
    },
    errors: formHandler.formState.errors,
    hazmat,
  };
};
