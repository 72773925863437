import { useCallback, useEffect, useState } from "react";
import { useAsync, useAsyncFn, useUnmount } from "react-use";
import { toast } from "@deliverr/ui";
import { useRecoilValue } from "recoil";
import { warehouseDataState } from "warehouse/base/warehouseIdState";
import { outboundLoadBalancerClient } from "warehouse/clients";
import { WarehouseCapacity } from "@deliverr/commons-clients/lib/outbound-load-balancer/WarehouseCapacity";
import { outboundCapacityLimitsConfig } from "./OutboundCapacityConfig";
import { log, logError, logStart } from "facility-commons/utils";
import { useIntl } from "react-intl";
import { SUBMIT_CAPACITY_TOASTS } from "./OutboundCapacity.labels";
import { userState } from "facility-commons/base/Auth/userState";
import { UserRoleType } from "warehouse/common/types";

export const useOutboundCapacity = () => {
  const { warehouseId, isAdmin } = useRecoilValue(userState);
  const warehouseData = useRecoilValue(warehouseDataState)!;
  const [capacityData, setCapacityData] = useState<WarehouseCapacity>();
  const [initialCapacity, setInitialCapacity] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [sliderValue, setSliderValue] = useState<number>();
  const [cutoffTime, setCutoffTime] = useState<string>("");
  const [diff, setDiff] = useState<number>(0);
  let shipDateRefreshTimeoutId: NodeJS.Timeout;
  const [userRole, setUserRole] = useState<UserRoleType>(UserRoleType.FC);

  const { formatMessage } = useIntl();

  useUnmount(() => {
    clearTimeout(shipDateRefreshTimeoutId);
  });

  useEffect(() => {
    if (isAdmin) {
      setUserRole(UserRoleType.ADMIN);
    }
  }, [isAdmin]);

  const fetchCapacity = useCallback(async () => {
    const ctx = logStart({ fn: "fetchCapacity" });
    setIsLoading(true);
    clearTimeout(shipDateRefreshTimeoutId);
    if (!!warehouseId && warehouseData) {
      try {
        log({ ...ctx, warehouseId }, "fetching active capacity data");
        const activeOutboundCapacities = await outboundLoadBalancerClient.getActiveCapacities(warehouseId!);
        log({ ...ctx, warehouseId, capacityResponse: activeOutboundCapacities }, "active capacity data fetched");

        // the first capacity refers to the capacity for the current day per Braden
        const outboundCapacityToday = activeOutboundCapacities?.[0];
        setCapacityData(outboundCapacityToday);
        setError(undefined);
        const date = new Date(outboundCapacityToday!.shipDate);

        // app needs to refresh if the warehouse's shipDate cutoff time has elapsed and this page is still open
        const refreshTime = date.getTime() - Date.now();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        shipDateRefreshTimeoutId = setTimeout(() => {
          fetchCapacity();
        }, refreshTime);

        setCutoffTime(date.toLocaleString("en-US", { hour: "numeric", hour12: true }));

        setDiff(outboundCapacityToday.unitLimit - outboundCapacityToday.originalUnitLimit);
        setInitialCapacity(outboundCapacityToday.originalUnitLimit);
        setSliderValue(outboundCapacityToday.unitLimit);
      } catch (err) {
        setError(err as any);
        logError(ctx, err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [warehouseId, warehouseData]);

  useAsync(async () => {
    await fetchCapacity();
  }, [warehouseId, warehouseData]);

  const onSliderChange = (value: number) => {
    setSliderValue(value);
    setDiff(value - initialCapacity!);
  };

  const [submitCapacityState, handleSubmitCapacity] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "handleSubmitCapacity" });
    try {
      log(ctx, "update warehouse capacity", { warehouseId, sliderValue });
      await outboundLoadBalancerClient.updateCapacity(warehouseId, sliderValue!);
      // reset capacity on slider with new value without fetching from endpoint
      setCapacityData({
        ...capacityData!,
        unitLimit: sliderValue!,
      });
      log(ctx, "warehouse capacity updated", { warehouseId, sliderValue });
      toast.success(
        formatMessage(diff > 0 ? SUBMIT_CAPACITY_TOASTS.successIncreased : SUBMIT_CAPACITY_TOASTS.successDecreased, {
          difference: Math.abs(diff),
        })
      );
      return;
    } catch (err) {
      toast.error(formatMessage(SUBMIT_CAPACITY_TOASTS.error));
      logError(ctx, err);
    }
    return;
  }, [diff, warehouseId, sliderValue, formatMessage]);

  return {
    isLoading,
    isSaving: submitCapacityState.loading,
    isError: Boolean(error),
    initialCapacity,
    capacityData,
    cutoffTime,
    onSliderChange,
    sliderValue,
    handleSubmitCapacity,
    min: initialCapacity! - outboundCapacityLimitsConfig[userRole].decrease * initialCapacity! ?? 0,
    max: initialCapacity! + outboundCapacityLimitsConfig[userRole].increase * initialCapacity! ?? 0,
    diff,
  };
};
