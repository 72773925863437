import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useLifecycles } from "react-use";
import { useResetRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { NCRoutes } from "./base/routes";
import { useRouter } from "facility-commons/hooks";
import { commonTicketMessages } from "./content";
import { receivingMisMatchState, isRerouteFromReceivingState } from "warehouse/receiving/ReceivingState";
import {
  currentProductState,
  currentProductLotFefoDetailsState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { setProp } from "facility-commons/utils";

export const useNonCompliance = () => {
  const {
    match: { path },
    push,
  } = useRouter();
  const form = useForm({ mode: "onBlur" });
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();
  const isMismatch = useRecoilValue(receivingMisMatchState);

  const { incidentId } = useRecoilValue(currentProductState);
  const resetCurrentProduct = useResetRecoilState(currentProductState);
  const resetReceivingMisMatchState = useResetRecoilState(receivingMisMatchState);
  const resetLotFefoDetailsState = useResetRecoilState(currentProductLotFefoDetailsState);
  const resetIsRerouteFromReceivingState = useResetRecoilState(isRerouteFromReceivingState);

  useLifecycles(
    () => {
      setWarehouseAppState(setProp("pageTitle", formatMessage(commonTicketMessages.nonComplianceTicketTitle)));
    },
    () => {
      resetCurrentProduct();
      resetReceivingMisMatchState();
      resetLotFefoDetailsState();
      resetIsRerouteFromReceivingState();
      setWarehouseAppState(setProp("pageTitle", ""));
      setWarehouseAppState(setProp("pageSubtitle", ""));
    }
  );

  // redirect back to step one (CDSKU) if the user refreshes or enters flow at a later step
  // incidentId is established on mount of the CDSKU card page
  useEffect(() => {
    // This useEffect in certain routing cases adds an extra /non-compliance/cdsku path to the history stack
    // This requires needing to click the back button twice to get back to the actual previous path.

    if (!incidentId && !isMismatch) {
      push(NCRoutes.CDSKU);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentId, push]);

  return { form, path, isMismatch };
};
