import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { Tabs } from "facility-commons";
import * as React from "react";
import { IconV2, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useTicketListTabs } from "./useTicketListTabs";

const TabContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  //matching styles for tabs
    display: flex;
    background-color: white;
    min-height: 52px; 
    width: 100%;
    margin: 0;
    justify-content: space-around;
    background-color: ${theme.colors.NEUTRAL["00"]};
    font-size: ${theme.font.size.F4};
    box-shadow: ${theme.shadow.S1};
    z-index: ${theme.zindex.Z2}; 
    margin-top: -${theme.spacing.S4};
    justify-content: flex-start;
    align-items: flex-end;

    div {
      flex: auto;
      box-shadow: none;
      max-width: 90%;
    } 
  `
);
const FilterButton = styled.button<ThemeProps<{ disabled?: boolean }>>(
  ({ theme, disabled }) => `
    border-left: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    width: 10%;
    margin-bottom: ${theme.spacing.S4};
    outline: 0;
    color: ${disabled ? theme.colors.NEUTRAL["300"] : theme.colors.NEUTRAL["500"]};
    cursor:${disabled && "not-allowed"};
     :focus {
        outline: 0;
     }
  `
);

export const TicketListTabs: React.FC = () => {
  const { currentTicketStatusDisplayed, ticketListTabs, ticketList, openFilterMenu, onTabSwitch } = useTicketListTabs();

  return (
    <TabContainer>
      <Tabs<TicketCenterNonComplianceCaseStatus>
        activeTab={currentTicketStatusDisplayed}
        onClick={onTabSwitch}
        tabs={ticketListTabs}
      />
      <FilterButton onClick={openFilterMenu} disabled={!ticketList.length}>
        <IconV2 type="filter" />
      </FilterButton>
    </TabContainer>
  );
};
