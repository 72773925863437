import { defineMessages } from "react-intl";

export const commonTicketMessages = defineMessages({
  alreadyResolved: {
    id: "warehouse.nonCompliance.alreadyResolved",
    defaultMessage: "Incident already resolved. Please try again later or contact Deliverr.",
  },
  caseCreated: {
    id: "warehouse.nonCompliance.caseCreated",
    defaultMessage: "Non-compliance case #{caseId} was created.",
  },
  nonComplianceReviewTitle: {
    id: "warehouse.nonCompliance.reviewNavTitle",
    defaultMessage: "Non-Compliance Review",
  },
  nonComplianceTicketTitle: {
    id: "warehouse.nonCompliance.ticketTitle",
    defaultMessage: "Non-Compliance Ticket",
  },
  expirationDateInputHelpText: {
    id: "warehouse.nonCompliance.expirationDateInputHelpText",
    defaultMessage:
      "MM/DD/YYYY. Use last day of the month for the day if the expiration date does not have a day value.",
  },
});
