import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";
import { InternalMenuLink, InternalMenuLinkProps } from "./InternalMenuLink";
import { ExternalMenuLink, ExternalMenuLinkProps } from "./ExternalMenuLink";

export const StyledMenuListItem = styled.li<React.HTMLAttributes<HTMLLIElement>, DefaultTheme>(
  ({ theme }) => `
  color: black;
  width: 100%;
  
  &:not(:last-of-type) {
    border-bottom-width: 1px;  
  }
  
  & > * {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: ${theme.border.radius.R0};
    font-weight: ${theme.font.weight.BOLD};
    
  }
  `
);

export interface BaseMenuLinkProps<LinkNames extends string = string> {
  text: JSX.Element | string;
  id: LinkNames;
  props?: any;
}

export type MenuLinkConfig<LinkNames extends string = string> =
  | InternalMenuLinkProps<LinkNames>
  | ExternalMenuLinkProps<LinkNames>;

export interface MenuLinkProps<LinkNames extends string = string> {
  link: MenuLinkConfig<LinkNames>;
}

export function MenuLink<LinkNames extends string = string>({ link }: MenuLinkProps<LinkNames>) {
  return (
    <StyledMenuListItem>
      {"route" in link ? <InternalMenuLink link={link} /> : <ExternalMenuLink link={link} />}
    </StyledMenuListItem>
  );
}
