export const minASNLength = 5;
export const maxASNLength = 8; // adding conservative max length to be safe
export const minBarcodeLength = 10;
export const maxBarcodeLength = 20;
export const minCDSKULength = 11;
export const maxCDSKULength = 11;
export const DSKULength = 11;

/* @deprecated */
export const validASNPattern = /^\d{5,8}$/;
export const validPositiveIntegerPattern = /^\+?\d+$/;
export const validProdASNPattern = /^\d{6,8}$/;
export const validBarcodePattern = /^(D\w{10}|^B\w{9}$|^X00\w{7}|\d{12,14})$/;
export const hasLowerCase = /[a-z]/;
export const validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// DSKU: D\w{10}
// FNSKU: ^B\w{9}$|^X00\w{7}
// UPC, EAN, GTIN-14: \d{12,14}

// Begins with CD
// 11 characters total
export const validCDSKU = /^CD[A-Z0-9]{9}$/;
export const validDSKU = /^D\w{10}$/;
export const validConsolidation = /^CID.+$/;
export const validLotInput = /^[a-zA-Z0-9-_\ \/]*$/;

// Logger constants
export const ebCategoryInfo = "CD_SCANNER_INFO";
export const ebCategoryError = "CD_SCANNER_ERROR";

export const validGaylord = /^SG[A-Z0-9]{9}$/;
