import React from "react";

import { Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";

import { FlowVisibleNotifications } from "facility-commons/flow/FlowVisibleNotifications";
import { notificationsState } from "facility-commons/flow/flowState";
import { DamagedProductRoutes } from "./new/forms/DamagedProduct";
import { TicketCenterRoutes } from "./TicketCenterRoutes";
import { FullStack } from "facility-commons/components/emotion";
import { NonComplianceRoutes } from "./new/NonComplianceRoutes";
import { ProblemSolverContext, useRouter } from "@deliverr/ui-facility";
import { userState } from "facility-commons/base/Auth/userState";
import { useRouteToProblemSolverPortal } from "warehouse/common/hooks/useRouteToProblemSolverPortal";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";

const CardContainer = styled.section<ThemeProps>`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
`;

export const TicketCenter: React.FC = () => {
  const router = useRouter();
  const notifications = useRecoilValue(notificationsState);
  const { isStorage } = useRecoilValue(userState);
  const { routeToIssueDashboard } = useRouteToProblemSolverPortal(
    isStorage
      ? [ProblemSolverContext.RESERVE_STORAGE_OUTBOUNDS]
      : [ProblemSolverContext.RECEIVING, ProblemSolverContext.INVENTORY]
  );
  const { problemSolverFlowEnabled } = useRecoilValue(warehouseAppState);

  // RS sites only have one issue type, OUTBOUND_LABEL, so they're technically fully enabled for their issues
  // as long as problem solver is enabled
  // but they still need a way to track other problems and they currently do this via creating tickets
  // we can remove the isStorage check when IssueType.OTHER is also enabled for RS sites.
  if (router.pathname.includes("new") && problemSolverFlowEnabled && !isStorage) {
    routeToIssueDashboard();
  }

  return (
    <FullStack center>
      <CardContainer>
        <Switch>
          {NonComplianceRoutes}
          {DamagedProductRoutes}
          {TicketCenterRoutes}
        </Switch>
      </CardContainer>
      <FlowVisibleNotifications notifications={notifications.slice(-1)} />
    </FullStack>
  );
};
