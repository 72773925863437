import React from "react";
import styled from "@emotion/styled";
import { ThemeProps, Divider, Stack } from "@deliverr/ui";

import { LoadingButton } from "facility-commons";
import { useNcReviewCard } from "./useNcReviewCard";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { commonWarehouseMessages } from "warehouse/common/messages";
import {
  BarcodeSection,
  AdditionalDetailsSection,
  FefoSection,
  QuantitySection,
  RestrictedProductSection,
  BoxInformationSection,
  PhotosSection,
  EmailSection,
} from "./components";

const StyledDivider = styled(Divider)<ThemeProps>`
  border-color: ${({ theme }) => theme.colors.NEUTRAL[60]};
`;

export const NcReviewCard: React.FC = () => {
  const {
    additionalDetails,
    barcode,
    boxInformation,
    cdsku,
    email,
    reportedLot,
    reportedExpiry,
    formatMessage,
    isRestricted,
    numberOfBoxes,
    photos,
    quantityPerBox,
    submitTicket,
    loading,
    isLotEnabled,
    isFefoEnabled,
  } = useNcReviewCard();

  return (
    <Stack>
      <FacilityCard>
        <Stack>
          <BarcodeSection barcode={barcode} />

          <FefoSection
            isLotEnabled={isLotEnabled}
            isFefoEnabled={isFefoEnabled}
            reportedLot={reportedLot}
            reportedExpiry={reportedExpiry}
          />

          {!cdsku && (
            /* we only collect tracking info when a CDSKU is not available */
            <>
              <BoxInformationSection boxInformation={boxInformation} />
              <StyledDivider />
            </>
          )}

          <PhotosSection photos={photos} />
          <StyledDivider />

          <QuantitySection quantityPerBox={quantityPerBox} numberOfBoxes={numberOfBoxes} />
          <StyledDivider />

          <AdditionalDetailsSection additionalDetails={additionalDetails} />
          <StyledDivider />

          <RestrictedProductSection isRestricted={isRestricted} />
          <StyledDivider />

          <EmailSection email={email} />
        </Stack>
      </FacilityCard>

      <LoadingButton block data-testid="submitNcCase-btn" onClick={submitTicket} disabled={loading}>
        {formatMessage(commonWarehouseMessages.submitTicket)}
      </LoadingButton>
    </Stack>
  );
};
