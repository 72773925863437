import { useRouter } from "facility-commons/hooks";
import React from "react";
import { useRecoilValue } from "recoil";
import { NCRoutes } from "../../base/routes";
import { currentProductState } from "../../NonComplianceState";
import { useNonComplianceFlow } from "../../base/useNonComplianceFlow";

export const useAdditionalCaseDetailsPage = () => {
  const { notes, isRestricted } = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { push } = useRouter();

  const updateDetails = (input: string) => {
    updateCurrentProduct("notes", input);
  };

  const toggleRestricted = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCurrentProduct("isRestricted", event.currentTarget.checked);
  };

  const goToNextPage = () => push(NCRoutes.EMAIL);

  return {
    notes: notes ?? "",
    isRestricted,
    goToNextPage,
    toggleRestricted,
    updateDetails,
  };
};
