import { Container } from "@deliverr/commons-objects";
import { RSOutboundsBoxSize } from "warehouse/rs-outbounds/base/RSOutboundsBoxSize";

export const BoxSizeToDimsMap: Record<RSOutboundsBoxSize, Container> = {
  [RSOutboundsBoxSize.SIZE_ONE]: {
    length: 14,
    width: 10,
    height: 5,
    lengthUnit: "in",
    weight: 0.5376,
    weightUnit: "lb",
  },
  [RSOutboundsBoxSize.SIZE_TWO]: {
    length: 26,
    width: 16,
    height: 10,
    lengthUnit: "in",
    weight: 1.586,
    weightUnit: "lb",
  },
  [RSOutboundsBoxSize.SIZE_THREE]: {
    length: 26,
    width: 16,
    height: 19,
    lengthUnit: "in",
    weight: 2.092,
    weightUnit: "lb",
  },
  [RSOutboundsBoxSize.SIZE_FOUR]: {
    length: 27,
    width: 26,
    height: 13,
    lengthUnit: "in",
    weight: 3.348,
    weightUnit: "lb",
  },
};
