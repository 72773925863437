import React from "react";

import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { useStoragePOCard } from "./useStoragePOCard";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { SkipStep } from "../../components";
import { STORAGE_MESSAGES } from "warehouse/common/messages/storage";

export const StoragePOCard: React.FC = () => {
  const {
    trackingCode,
    poError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  } = useStoragePOCard();

  return (
    <StackedInputCard
      errorMessage={poError}
      illustration={<OpenBox />}
      loading={loading}
      message={formatMessage(STORAGE_MESSAGES.scanPO)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(STORAGE_MESSAGES.poNumPlaceholder)}
      secondary
      title={formatMessage(STORAGE_MESSAGES.scanPO)}
      value={trackingCode}
    >
      <SkipStep message={formatMessage(STORAGE_MESSAGES.missingPO)} onSkip={proceedToFollowUp} />
    </StackedInputCard>
  );
};
