import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { SoundFx } from "facility-commons/common/sfx";

interface TextProps {
  title: string;
  text?: string;
}

type GenericWarningConfirmationModalProps = TextProps &
  (
    | {
        onCancel: () => void;
        onConfirm: () => void;
      }
    | {
        onCancel?: never;
        onConfirm: () => void;
      }
    | {
        onCancel: () => void;
        onConfirm?: never;
      }
  );

export const GenericWarningConfirmationModal: React.FC<GenericWarningConfirmationModalProps> = ({
  onCancel,
  onConfirm,
  title,
  text,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={title}
      text={text ?? ""}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
    >
      <Grid gap="S2" fullWidth>
        {onConfirm && (
          <Button block data-testid="generic-warning-confirm-btn" onClick={onConfirm}>
            {formatMessage(modalsText.confirm)}
          </Button>
        )}
        {onCancel && (
          <Button block onClick={onCancel} secondary>
            {formatMessage(modalsText.back)}
          </Button>
        )}
      </Grid>
    </ModalTemplate>
  );
};
