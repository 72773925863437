import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";

import { CountBadge } from "warehouse/common/components";

export interface ChoiceChipProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  count?: number;
  text?: string;
}

const StyledInput = styled.input<ChoiceChipProps, DefaultTheme>(({ theme }) => {
  return `
    appearance: none;
    cursor: pointer;

    // SELECTOR FOR WRAPPER AROUND RADIO BUTTON
    &:checked + div {
      color: ${theme.colors.NEUTRAL[500]};
      border: 1px solid ${theme.colors.NEUTRAL[400]};
    }

    // SELECTOR FOR COUNT BADGE
    &:checked + div > div {
      background-color: ${theme.colors.BLUE[500]};
    }

    // SELECTOR FOR WRAPPER AROUND RADIO BUTTON
    &:disabled + div {
      background-color: ${theme.colors.NEUTRAL[40]};
      color: ${theme.colors.NEUTRAL[200]};
    }

    // SELECTOR FOR COUNT BADGE
    &:disabled + div > div {
      background-color: ${theme.colors.NEUTRAL[100]};
    }
  `;
});

const Wrapper = styled.div<Record<string, unknown>, DefaultTheme>(({ theme }) => {
  return `
    align-items: center;
    background-color: ${theme.colors.NEUTRAL["00"]};
    border: 1px solid ${theme.colors.NEUTRAL[100]};
    border-radius: 24px;
    color: ${theme.colors.NEUTRAL[200]};
    display: inline-flex;
    font-size: ${theme.font.size.F3};
    font-weight: ${theme.font.weight.BOLD};
    height: 36px;
    justify-content: center;
    line-height: ${theme.font.lineHeight.LH2};
    padding: 5px 16px;
`;
});

const CursorLabel = styled.label<Record<string, unknown>, DefaultTheme>`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.S3};

  @media (max-width: 360px) {
    margin-right: ${({ theme }) => theme.spacing.S2};
  }

  @media (max-width: 320px) {
    margin-right: ${({ theme }) => theme.spacing.S1};
  }
`;

export const ChoiceChip: React.FC<ChoiceChipProps> = (props) => {
  const { count, text, onClick } = props;
  const showCount = count && count > 0;

  return (
    <CursorLabel>
      <StyledInput onClick={onClick} type="radio" {...props} />
      <Wrapper>
        {text}
        {showCount ? <CountBadge>{count}</CountBadge> : null}
      </Wrapper>
    </CursorLabel>
  );
};
