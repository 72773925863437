import { logStart } from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

export interface QuantityWarningModalProps {
  expectedQuantity: number;
  inputtedQuantity: number;
  previouslyReceivedQuantity: number;
  proceedWithSubmit: (value: number) => Promise<void>;
}

export const useQuantityWarningModal = (props: QuantityWarningModalProps) => {
  const { inputtedQuantity, proceedWithSubmit } = props;

  const { hideModal } = useWarehouseModal();

  const hideQuantityWarningModal = () => hideModal(WarehouseModal.INCORRECT_UNIT_QTY);

  const handleModalClickContinue = async () => {
    const ctx = { fn: "QuantityWarningModal.handleModalClickContinue", props };
    logStart(ctx);

    await proceedWithSubmit(inputtedQuantity);
    hideQuantityWarningModal();
  };

  return {
    handleModalClickContinue,
    hideQuantityWarningModal,
  };
};
