import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { SkuReceivingDetails } from "./Types";

/** the SKU that is currently being received, resets on each receive in multisku mode */
export const INITIAL_SKU_STATE: SkuReceivingDetails = {
  cdsku: "",
  dsku: "",
  description: "",
  expectedQuantity: 0,
  previouslyReceivedQuantity: 0,
  quantity: 0,
  barcode: "",
  location: "",
  // default to true because SKU_STATE is used for all receiving modes but
  // isSkuOnBox is only relevant for box receives
  isSkuOnBox: true,
};

export const currentSkuState = atom<SkuReceivingDetails>({
  key: WarehouseStateKey.CURRENT_SKU,
  default: INITIAL_SKU_STATE,
});
