import { useRecoilState } from "recoil";
import { defaultLocationState } from "warehouse/receiving/ReceivingState";
import { setProp } from "facility-commons/utils";

export const useDefaultLocationToggle = () => {
  const [{ isDefaultLocationEnabled }, setEnableDefaultLocation] = useRecoilState(defaultLocationState);

  const onToggle = () => {
    setEnableDefaultLocation(setProp("isDefaultLocationEnabled", !isDefaultLocationEnabled));
  };

  return {
    isDefaultLocationEnabled,
    onToggle,
  };
};
