import { FlowCardMap } from "facility-commons/flow/types/FlowMaps";
import { ReturnReceiveFlowCardType } from "../types/ReturnReceiveFlowCardType";
import { TrackingCodeScan } from "../components/TrackingCodeScan";
import { BarcodeScan } from "../components/BarcodeScan";
import { ItemInspectionCard } from "../components/ItemInspectionCard";
import { LocationScan } from "../components/LocationScan";
import { LotExpiryInput } from "../components/LotExpiryInput";
import { SortToLocationCard } from "../components/SortToLocationCard";
import { ReturnBoxScan } from "../components/ReturnBoxScan";

export const returnReceiveFlowCardMap: FlowCardMap = {
  [ReturnReceiveFlowCardType.TRACKING_CODE_SCAN]: TrackingCodeScan,
  [ReturnReceiveFlowCardType.BARCODE_CODE_SCAN]: BarcodeScan,
  [ReturnReceiveFlowCardType.LOT_EXPIRY_INPUT]: LotExpiryInput,
  [ReturnReceiveFlowCardType.ITEM_INSPECTION]: ItemInspectionCard,
  [ReturnReceiveFlowCardType.LOCATION_SCAN]: LocationScan,
  [ReturnReceiveFlowCardType.RTM_BOX_SCAN]: ReturnBoxScan,
  [ReturnReceiveFlowCardType.SORT_TO_DISPOSITION_VALID_LOCATION]: SortToLocationCard,
};
