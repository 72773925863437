import React from "react";
import { DefaultTheme, Input } from "@deliverr/ui";
import styled from "@emotion/styled";

import { isEbBrowser, isZebraScanner } from "facility-commons/utils";
import { FlowCard } from "../FlowCard";
import { useTextInput } from "./useTextInput";
import { LoadingSpinner } from "facility-commons/components";
import { TextInputCardProps } from "./TextInputCardProps";
import { useIntlString } from "facility-commons/intl/useIntlString";
import { CardColumn, CardLabelText } from "facility-commons/components/emotion";

// Placeholder moves to label after a value is entered, causing a visual jump.
// This allows us to avoid that visual jump.
const InputContainer = styled.div<
  { applyLabelMargin: boolean } & Pick<TextInputCardProps, "hideNumericalArrowControls" | "rightAligned">,
  DefaultTheme
>(
  ({ applyLabelMargin, hideNumericalArrowControls, rightAligned, theme }) => `
    ${applyLabelMargin ? `margin-top: ${theme.spacing.S4};` : ""}
    ${
      hideNumericalArrowControls
        ? `
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  `
        : ""
    }
    ${
      rightAligned
        ? `
      input {
        padding: ${theme.spacing.S3} ${theme.spacing.S4} ;
        text-align: end;
        ::placeholder {
          text-align: start;
        }
       }`
        : ""
    }
  `
);

const MessageContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
  `
);

export const TextInputCard: React.FC<TextInputCardProps> = (props) => {
  const {
    newest,
    embedded = false,
    value,
    loading,
    errorMessage,
    label,
    helpText,
    placeholder = "",
    fieldTitle,
    name,
    type,
    hideNumericalArrowControls,
    rightAligned,
    // flowCardProps
    icon,
    textIcon,
    title = "",
    accentColor,
    pillProps,
    topChildren,
    centerAlign,
    size,
    message,
    className,
    // otherProps
    onChange,
    onSubmit,
    children,
    ...otherProps
  } = props;
  const { applyLabelMargin, disabled, inputEl, isEmptyValue, handleChange, handleSubmit } = useTextInput(props);
  const intlString = useIntlString();
  const formattedPlaceholder = intlString(placeholder);
  const [readOnly, setReadOnly] = React.useState(true);

  // a workaround to stop the soft keyboard from popping up when comp renders while still keeping the field focused so that scanning works.
  React.useEffect(() => {
    const timer = setTimeout(() => setReadOnly(false), 50);
    return () => clearTimeout(timer);
  }, []);

  const cardContent = (
    <>
      {!loading ? (
        <InputContainer
          applyLabelMargin={applyLabelMargin}
          hideNumericalArrowControls={hideNumericalArrowControls}
          rightAligned={rightAligned}
        >
          <Input
            name={name}
            ref={isZebraScanner ? undefined : inputEl}
            value={value}
            placeholder={formattedPlaceholder}
            errorMessage={errorMessage}
            hasError={Boolean(errorMessage)}
            label={isEmptyValue ? undefined : label || formattedPlaceholder}
            helpText={errorMessage ? undefined : helpText}
            type={type ?? "text"}
            {...otherProps}
            disabled={disabled}
            onChange={handleChange}
            autoFocus={!isEbBrowser()}
            readOnly={readOnly}
          />
        </InputContainer>
      ) : (
        <LoadingSpinner loading={loading} size="2x" />
      )}
      {props.children}
    </>
  );

  const maybeCardColumn = embedded ? (
    <CardColumn>
      {fieldTitle && <CardLabelText>{fieldTitle}</CardLabelText>}
      {message && <MessageContainer>{message}</MessageContainer>}
      {cardContent}
    </CardColumn>
  ) : (
    <FlowCard
      {...{ className, message, icon, textIcon, title, accentColor, pillProps, topChildren, centerAlign, size }}
    >
      {cardContent}
    </FlowCard>
  );

  return onSubmit ? <form onSubmit={handleSubmit}>{maybeCardColumn}</form> : maybeCardColumn;
};
