import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";

export const Page = styled.div<Record<string, unknown>, DefaultTheme>`
  height: 100vh;
  background: ${({ theme }) => theme.colors.NEUTRAL[40]};
`;

export const PageContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageInner = styled.div<Record<string, unknown>, DefaultTheme>`
  padding: ${({ theme }) => theme.spacing.S3};
  flex: 1;
`;
