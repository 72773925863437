import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { TextInputCard } from "facility-commons/flow";
import { useReceivingSKUScanFlow } from "./useReceivingSKUScanFlow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";

const StyledTextInputCard = styled(TextInputCard)`
  height: auto !important;
`;

export const StorageReceivingSKUScan: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    currentSKU,
    scanError,
    handleSKUChange,
    handleSubmit,
    handleDamagedExpiredClick,
    loading,
  } = useReceivingSKUScanFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <StyledTextInputCard
        errorMessage={scanError}
        newest
        onChange={handleSKUChange}
        onSubmit={handleSubmit}
        placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanPlaceholder)}
        loading={loading}
        title={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanTitle)}
        message={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanDescription)}
        value={currentSKU ?? ""}
      >
        <SkipStep
          hideSeparator
          onSkip={handleDamagedExpiredClick}
          message={formatMessage(STORAGE_RECEIVING_MESSAGES.damagedOrExpiredSKU)}
          skipText={formatMessage(STORAGE_RECEIVING_MESSAGES.createTicket)}
        ></SkipStep>
      </StyledTextInputCard>
    </>
  );
};
