import { logStart, log, logError } from "facility-commons/utils";
import { s3FileHandlerClient } from "facility-commons/base/Clients";

export const getS3File = async (img: string | undefined | null) => {
  const ctx = logStart({
    fn: "getS3File",
    img,
  });
  if (img) {
    try {
      return s3FileHandlerClient.getS3File(img);
    } catch (err) {
      logError(ctx, err);
      throw err;
    }
  } else {
    log(ctx, "No image url provided to download");
  }
};

export const getMultipleS3Files = async (imagesPerTicket: {
  [ticketId: string]: { [imgKey: string]: string };
}): Promise<{ [key: string]: { [key: string]: string } }> => {
  const ctx = logStart({
    fn: "getMultipleS3Files",
    imagesPerTicket,
  });
  try {
    return s3FileHandlerClient.batchDownload(imagesPerTicket);
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
};
