import * as React from "react";
import { Button, ThemeProps, Text, Appearance } from "@deliverr/ui";
import { CharLimitTextArea } from "facility-commons/components";
import styled from "@emotion/styled";
import { ModalTemplate } from "facility-commons";
import { InspectionNotesType } from "warehouse/receiving/ReturnReceive/types/InspectionNotesType";
import { useReturnReceiveInspectionNotesModal } from "./useReturnReceiveInspectionNotesModal";
import { MAX_RETURNS_INSPECTION_NOTES_LENGTH } from "warehouse/receiving/ReturnReceive/constants";

const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;

  & > button {
    margin-top: ${theme.spacing.S3}; 
  } 
  `
);

interface ReturnReceiveInspectionNotesModalProps {
  noteType: InspectionNotesType;
}

export const ReturnReceiveInspectionNotesModal: React.FC<ReturnReceiveInspectionNotesModalProps> = ({ noteType }) => {
  const {
    modalMessages,
    inspectionNotesInput,
    inspectionNotesError,
    isSubmitLoading,
    isSubmitDisabled,
    handleInspectionNoteInputChange,
    onSubmitInspectionNotes,
    onCloseModal,
  } = useReturnReceiveInspectionNotesModal(noteType);

  return (
    <ModalTemplate title={modalMessages.title} buttonLayout="column">
      <StyledDiv>
        <CharLimitTextArea
          textAreaValue={inspectionNotesInput}
          charLimit={MAX_RETURNS_INSPECTION_NOTES_LENGTH}
          onChange={handleInspectionNoteInputChange}
          placeholder={modalMessages.inputPlaceholder}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
        <Text appearance={Appearance.DANGER} size={"label"}>
          {inspectionNotesError}
        </Text>
        <Button
          loading={isSubmitLoading}
          block
          disabled={isSubmitDisabled}
          data-testid={"return-receive-notes-modal-submit-button"}
          onClick={onSubmitInspectionNotes}
        >
          {modalMessages.submitButton}
        </Button>
        <Button onClick={onCloseModal} block secondary data-testid={"return-receive-notes-modal-close-button"}>
          {modalMessages.closeButton}
        </Button>
      </StyledDiv>
    </ModalTemplate>
  );
};
