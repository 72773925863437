import InputMask from "react-input-mask";
import { ThemeProps, Text, BoxReset, IconV2 } from "@deliverr/ui";
import React from "react";
import styled from "@emotion/styled";
import { LocalizedMessage } from "@deliverr/ui";

const StyledInput = styled.div<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F3};
  margin-top: ${theme.spacing.S3};
  height: ${theme.spacing.S8};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R3};
  padding: ${theme.spacing.S3} ${theme.spacing.S8} ${theme.spacing.S3} ${theme.spacing.S4};
  input {
    outline: none;
  }
`
);

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.RED[400]};
  display: flex;
  align-items: center;
  line-height: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S2};
}
`
);

const StyledIcon = styled(IconV2)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
}
`
);

interface Props {
  value: string;
  mask: string;
  onChange: (value: any) => void;
  placeholderText?: string;
  errorMessage?: LocalizedMessage;
  helpText?: LocalizedMessage;
  inputTestId?: string;
  disabled?: boolean;
}

export const DateInput: React.FC<Props> = ({
  value,
  mask,
  onChange,
  placeholderText,
  errorMessage,
  helpText,
  inputTestId,
  disabled,
}) => {
  const MONTH_DAY_YEAR_DIGIT_VALUES = {
    m: "[0-1]",
    M: "[0-9]",
    d: "[0-3]",
    D: "[0-9]",
    Y: "[0-9]",
  };

  return (
    <>
      <StyledInput>
        <InputMask
          mask={mask}
          value={value}
          onChange={onChange}
          formatChars={MONTH_DAY_YEAR_DIGIT_VALUES}
          placeholder={placeholderText}
          disabled={disabled}
          data-testid={inputTestId || "expiration-date-input"}
        />
      </StyledInput>
      {errorMessage ? (
        <StyledText size="label" as="div">
          <StyledIcon type="exclamation-circle" size="md"></StyledIcon>
          {errorMessage}
        </StyledText>
      ) : null}
      <BoxReset marginTop="S2">
        <Text appearance="INFO" size="label">
          {helpText}
        </Text>
      </BoxReset>
    </>
  );
};
