import { genericOnScannerInputChange } from "facility-commons";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useReceivingFlow } from "warehouse/receiving/base";
import { getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";

export const useGetProductZone = () => {
  const [scannedValue, setScannedValue] = useState("");
  const [zoneName, setZoneName] = useState<string | null>("");
  const [scannedValueError, setScannedValueError] = useState("");
  const { errorResponse, successResponse } = useCommonFlow();
  const { formatMessage } = useIntl();
  const { inboundClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const { handleUnknownError } = useReceivingFlow();

  function onScannedValueChange(newScannedValue: string) {
    setScannedValue(newScannedValue);
    setScannedValueError("");
    setZoneName("");
  }

  const [{ loading }, submitScannedValue] = useAsyncFn(async (scannedValue: string) => {
    const ctx = {
      fn: "useGetProductZone.submitScannedValue",
      scannedValue,
    };
    if (!scannedValue) {
      errorResponse();
      return setScannedValueError(formatMessage(commonWarehouseMessages.fieldRequired));
    }
    try {
      const response = await inboundClient.getZoneByProductBarcode({ barcode: scannedValue, facilityId: warehouseId });
      if (response.data || response.data === null) {
        const newZoneName = response.data ? response.data.zoneName : null;
        successResponse();
        setZoneName(newZoneName);
      } else if (response.error) {
        const { message } = response.error;

        const messageId = getErrorMessageId({ response: response.error });
        setScannedValueError(messageId ? formatMessage(messageId, { barcode: scannedValue }) : message);
      } else {
        handleUnknownError({ ctx, response }, "Unexpected error getting zone by product barcode");
      }
    } catch (err) {
      handleUnknownError({ ...ctx, err }, err);
    }
  });

  const handleInputChange = genericOnScannerInputChange(
    scannedValue,
    onScannedValueChange,
    submitScannedValue,
    "upper"
  );

  return { scannedValue, scannedValueError, handleInputChange, loading, submitScannedValue, zoneName };
};
