import { defineMessages } from "react-intl";

export const palletAnalyticsV2Messages = defineMessages({
  multiSKUBoxes: {
    id: "warehouse.palletAnalytics.multiSKUBoxes",
    defaultMessage: "Multi SKU Boxes",
  },
  singleSKUBoxes: {
    id: "warehouse.palletAnalytics.singleSKUBoxes",
    defaultMessage: "Single SKU Boxes",
  },
  bulkBoxes: {
    id: "warehouse.palletAnalytics.bulkBoxes",
    defaultMessage: "Bulk Boxes",
  },
  noLabeledBoxesOnPallet: {
    id: "warehouse.palletAnalytics.noLabeledBoxesOnPallet",
    defaultMessage: "No labeled boxes on pallet",
  },
  palletAnalyticsV2: {
    id: "warehouse.receiving.palletAnalyticsV2",
    defaultMessage: "Pallet Analytics",
  },
  pidTitle: {
    id: "warehouse.palletAnalytics.pidTitle",
    defaultMessage: "Scan Pallet ID",
  },
  pidPlaceholder: {
    id: "warehouse.palletAnalytics.pidPlaceholder",
    defaultMessage: "Pallet ID",
  },
  palletAnalyticsV2Message: {
    id: "warehouse.palletAnalytics.palletAnalyticsV2Message",
    defaultMessage: "Scan or enter a Pallet ID to get the receiving details and analytics",
  },
  boxesReceived: {
    id: "warehouse.palletAnalytics.boxesReceived",
    defaultMessage: "Boxes received:",
  },
  numBoxes: {
    id: "warehouse.palletAnalytics.numBoxes",
    defaultMessage: "{numBoxes, plural, =1 {# box} other {# boxes}}",
  },
  missingUnits: {
    id: "warehouse.palletAnalytics.missingUnits",
    defaultMessage: "Missing units",
  },
  extraUnits: {
    id: "warehouse.palletAnalytics.extraUnits",
    defaultMessage: "Extra units",
  },
  numBoxAndUnitsPerBox: {
    id: "warehouse.palletAnalytics.numBoxAndUnitsPerBox",
    defaultMessage: "{numBoxes, plural, =1 {# box} other {# boxes}} - {unitsPerBox} expected units/box",
  },
  SKUs: {
    id: "warehouse.palletAnalytics.SKUs",
    defaultMessage: "{numSkus, plural, =1 {# SKU} other {# SKUs}}",
  },
  DSKU: {
    id: "warehouse.palletAnalytics.DSKU",
    defaultMessage: "DSKU",
  },
  CDSKU: {
    id: "warehouse.palletAnalytics.CDSKU",
    defaultMessage: "CDSKU",
  },
  receivedVsExpected: {
    id: "warehouse.palletAnalytics.receivedVsExpected",
    defaultMessage: "Received / Expected",
  },
});
