import { useRecoilValue } from "recoil";
import { useAsyncFn } from "react-use";
import { initiateDownloadFromUrl } from "@deliverr/ui/lib-utils/download";

import { shippingClient } from "facility-commons/base/Clients";
import { warehouseDataState } from "warehouse/base/warehouseIdState";
import { log, logError } from "facility-commons/utils";
import { formatManifestDownloadUrl } from "../utils";

export function useDhlManifestAccount() {
  const warehouseData = useRecoilValue(warehouseDataState);

  const [dhlManifestPdf, fetchDhlManifestPdf] = useAsyncFn(
    async (warehouseId) => {
      const ctx = { fn: "fetchDhlManifestPdf", warehouseId };
      if (warehouseData?.dhlManifestAccount) {
        try {
          const response = await shippingClient.generateDhlManifest(warehouseData?.dhlManifestAccount);
          const { url, fileName } = formatManifestDownloadUrl(response, warehouseId);
          initiateDownloadFromUrl(url, fileName);
          log(ctx, "fetched DHL manifest");
        } catch (error) {
          logError(ctx, error);
        }
      }
    },
    [warehouseData]
  );

  return {
    isLoading: dhlManifestPdf.loading,
    downloadDhlManifestPdf: fetchDhlManifestPdf,
    dhlManifestAccountNumber: warehouseData?.dhlManifestAccount ?? "",
    error: dhlManifestPdf.error,
  };
}
