import { useIntl } from "react-intl";
import { useRouter } from "facility-commons/hooks";
import { NCRoutes } from "warehouse/ticket-center/new/non-compliance/base/routes";
import { WarehousePortalRoutes } from "warehouse/routes";
import { useSetRecoilState } from "recoil";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { setProp } from "facility-commons/utils";
import { linkMapConfig } from "../../types";
import { ticketFormContent } from "../../../new/ticketFormContent";

export function useStorageTicketCenter() {
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const setCurrentProduct = useSetRecoilState(currentProductState);

  const goToDetails = () => push(`${WarehousePortalRoutes.STORAGE_NON_COMPLIANCE_FLOW}/${NCRoutes.DETAILS}`);

  const linkMap: linkMapConfig = [
    {
      title: formatMessage(ticketFormContent.repalletizeMultiSKU),
      onClick: () => {
        setCurrentProduct(setProp("notes", formatMessage(ticketFormContent.repalletizeMultiSKU)));
        goToDetails();
      },
    },
    {
      title: formatMessage(ticketFormContent.repalletizeOversized),
      onClick: () => {
        setCurrentProduct(setProp("notes", formatMessage(ticketFormContent.repalletizeOversized)));
        goToDetails();
      },
    },
  ];

  const title = formatMessage(ticketFormContent.specifyProblem);

  return {
    title,
    linkMap,
  };
}
