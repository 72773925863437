import { FACILITY_COMMONS_BUTTON_LABELS, WarehouseMessages } from "facility-commons";
import {
  createBoxArrivalScanNotificationCard,
  createBoxArrivalScannerCard,
} from "warehouse/common/flow/warehouseFlowCardCreators";

import { BoxArrivalScanEntity } from "@deliverr/legacy-inbound-client";
import { WarehouseModal } from "warehouse/modal/WarehouseModalMap";
import { arrivalScanMessages } from "warehouse/scan-arrival/arrivalScanMessage";
import { log } from "facility-commons/utils";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { useWarehouseModal } from "warehouse/modal";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";

export const useBoxTypeIdentificationCard = ({
  scannedLabel,
  arrivalScanId,
}: {
  scannedLabel: string;
  arrivalScanId: number;
}) => {
  const { formatMessage } = useIntl();
  const { successResponse, errorResponse, infoResponse, nextFlowCard, addFlowCard } = useWarehouseFlow();
  const { hideAllModals, showModal } = useWarehouseModal();
  const { warehouseId } = useRecoilValue(userState);
  const { inboundClient, returnsClient } = useClientsWithAuth();
  const { returnsEnabled } = useRecoilValue(warehouseAppState);

  const onCancel = () => {
    nextFlowCard(createBoxArrivalScannerCard({}));
  };

  const onCdskuSelect = () => {
    showModal(WarehouseModal.BOX_TYPE_IDENTIFICATION, {
      title: formatMessage(arrivalScanMessages.cdskuBoxTypeModalTitle),
      message: formatMessage(arrivalScanMessages.cdskuBoxTypeModalMessage),
      onConfirm: async () => {
        hideAllModals();
        infoResponse();
        addFlowCard(
          createBoxArrivalScanNotificationCard({
            message: formatMessage(arrivalScanMessages.scanCdskuNotificationMessage, { label: scannedLabel }),
            accentColor: "YELLOW",
            icon: "exclamation-circle",
          })
        );
        addFlowCard(createBoxArrivalScannerCard({}));
      },
      onCancel: () => {
        hideAllModals();
      },
    });
  };

  const routeToNextCardOnUndeliverredPackageSelect = () => {
    hideAllModals();
    addFlowCard(
      createBoxArrivalScanNotificationCard({
        message: formatMessage(modalsText.boxSortUndeliveredMessage, { label: scannedLabel }),
        accentColor: "GREEN",
        icon: "check-circle",
      })
    );
    addFlowCard(createBoxArrivalScannerCard({}));
  };

  // don't block sorting of box if fail to update inbound table with box type
  const markBoxTypeAsUndeliverredPackage = async () => {
    const ctx = {
      fn: "useBoxTypeIdentificationCard.markBoxTypeAsUndeliverredPackage",
      arrivalScanId,
      scannedLabel,
      warehouseId,
    };
    const errorLogString = "error updating box as RTS package type";
    try {
      const results = await inboundClient.updateBoxArrivalScanEntity(
        arrivalScanId,
        BoxArrivalScanEntity.UNDELIVERABLE_PACKAGE_TRACKING_CODE,
        warehouseId
      );

      if (results.error) {
        log({ ...ctx, results }, errorLogString);
      }
    } catch (error) {
      log({ ...ctx, error }, errorLogString);
    }
  };

  const onUndeliverredPackageSelect = () => {
    showModal(WarehouseModal.BOX_TYPE_IDENTIFICATION, {
      title: formatMessage(arrivalScanMessages.undeliverredBoxTypeModalTitle),
      onConfirm: async () => {
        await markBoxTypeAsUndeliverredPackage();
        successResponse();
        routeToNextCardOnUndeliverredPackageSelect();
      },
      onCancel: () => {
        hideAllModals();
      },
    });
  };

  // don't block sorting of box if fail to update inbound table with box type
  const markBoxTypeAsReturnsOrderPackage = async () => {
    const ctx = {
      fn: "useBoxTypeIdentificationCard.markBoxTypeAsReturnsOrderPackage",
      arrivalScanId,
      scannedLabel,
      warehouseId,
    };
    const errorLogString = "error updating box as returns order package type";
    try {
      const results = await inboundClient.updateBoxArrivalScanEntity(
        arrivalScanId,
        BoxArrivalScanEntity.RETURNS_PACKAGE_TRACKING_CODE,
        warehouseId
      );

      if (results.error) {
        log({ ...ctx, results }, errorLogString);
      }
    } catch (error) {
      log({ ...ctx, error }, errorLogString);
    }
  };

  const routeToNextCardOnBlindReturnsCreation = ({
    isblindReturnCreationSuccessful,
  }: {
    isblindReturnCreationSuccessful: boolean;
  }) => {
    hideAllModals();
    if (isblindReturnCreationSuccessful) {
      addFlowCard(
        createBoxArrivalScanNotificationCard({
          message: formatMessage(arrivalScanMessages.blindReturnCreationSuccess, { trackingCode: scannedLabel }),
          accentColor: "GREEN",
          icon: "check-circle",
        })
      );
    } else {
      addFlowCard(
        createBoxArrivalScanNotificationCard({
          message: formatMessage(arrivalScanMessages.blindReturnCreationFailed, { trackingCode: scannedLabel }),
          accentColor: "RED",
          icon: "exclamation-circle",
        })
      );
    }

    addFlowCard(createBoxArrivalScannerCard({}));
  };

  const onReturnedPackageSelect = () => {
    showModal(WarehouseModal.BOX_TYPE_IDENTIFICATION, {
      title: formatMessage(arrivalScanMessages.returnedBoxTypeModalTitle),
      message: formatMessage(arrivalScanMessages.returnedBoxTypeModalMessage, { trackingCode: scannedLabel }),
      onConfirm: async () => {
        try {
          const response = await returnsClient.createUnclaimedBlindReturn({
            trackingNumber: scannedLabel,
            facilityCode: warehouseId,
          });
          if (!response.value) {
            throw new Error("no return order was created");
          }
          await markBoxTypeAsReturnsOrderPackage();
          successResponse();
          routeToNextCardOnBlindReturnsCreation({ isblindReturnCreationSuccessful: true });
        } catch (error) {
          log({ scannedLabel, warehouseId, error }, "failed to create blind return");
          errorResponse();
          routeToNextCardOnBlindReturnsCreation({ isblindReturnCreationSuccessful: false });
        }
      },
      onCancel: () => {
        hideAllModals();
      },
    });
  };

  const routeToNextCardOnUnidentifiedBoxConfirmation = () => {
    hideAllModals();
    addFlowCard(
      createBoxArrivalScanNotificationCard({
        message: formatMessage(arrivalScanMessages.unableToIdentifyBoxTypeMessage, { label: scannedLabel }),
        accentColor: "YELLOW",
        icon: "exclamation-circle",
      })
    );
    addFlowCard(createBoxArrivalScannerCard({}));
  };

  const onUnsureOfPackageTypeSelect = () => {
    showModal(WarehouseModal.BOX_TYPE_IDENTIFICATION, {
      title: formatMessage(arrivalScanMessages.unableToIdentifyBoxTypeTitle),
      message: formatMessage(arrivalScanMessages.unableToIdentifyBoxTypeMessage, { label: scannedLabel }),
      onConfirm: async () => {
        infoResponse();
        routeToNextCardOnUnidentifiedBoxConfirmation();
      },
      onCancel: () => {
        hideAllModals();
      },
    });
  };

  const title = formatMessage(arrivalScanMessages.boxIdentificationTitle);
  const helpText = formatMessage(arrivalScanMessages.boxIdentificationHelpText);

  const buttonLabels = {
    CDSKU: formatMessage(WarehouseMessages.cdsku),
    undeliverredPackage: formatMessage(arrivalScanMessages.undeliverredPackage),
    returnedPackage: formatMessage(arrivalScanMessages.returnedPackage),
    imNotSure: formatMessage(arrivalScanMessages.imNotSure),
    Cancel: formatMessage(FACILITY_COMMONS_BUTTON_LABELS.cancelButton),
  };

  return {
    title,
    helpText,
    buttonLabels,
    returnsEnabled,
    onCancel,
    onCdskuSelect,
    onUndeliverredPackageSelect,
    onReturnedPackageSelect,
    onUnsureOfPackageTypeSelect,
  };
};
