export enum WarehouseStateKey {
  FLOW_CARDS = "WAREHOUSE_FLOW_CARDS",
  SAME_ACTIVE_FLOW_CARD = "WAREHOUSE_SAME_ACTIVE_FLOW_CARD",
  FLOW_BUTTONS = "WAREHOUSE_FLOW_BUTTONS",
  FLOW_BUTTONS_VISIBLE = "WAREHOUSE_FLOW_BUTTONS_VISIBLE",
  WAREHOUSE_ID = "WAREHOUSE_WAREHOUSE_ID",
  ADMIN_STATE = "WAREHOUSE_ADMIN_STATE",
  MANAGER_STATE = "MANAGER_STATE",
  WAREHOUSE_DATA = "WAREHOUSE_WAREHOUSE_DATA",
  USER = "WAREHOUSE_USER",
  CLIENT_ID = "WAREHOUSE_CLIENT_ID",
  MODALS = "WAREHOUSE_MODALS",
  VISIBLE_MODAL = "WAREHOUSE_VISIBLE_MODAL",
  BOX_ARRIVAL_SCAN = "WAREHOUSE_BOX_ARRIVAL_SCAN",
  ARRIVAL_SCAN_INPUT = "WAREHOUSE_ARRIVAL_SCAN_INPUT",
  ARRIVAL_SCAN_REROUTED_ASN = "WAREHOUSE_ARRIVAL_SCAN_REROUTED_ASN",
  NC_CDSKU = "NON_COMPLIANCE_CDSKU",
  ASN_RESPONSE = "ASN_RESPONSE",
  RECEIVING_FLOW_TYPE = "RECEIVING_FLOW_TYPE",
  CURRENT_CDSKU = "CURRENT_CDSKU",
  CURRENT_SKU = "CURRENT_SKU",
  RECEIVING_DATA = "RECEIVING_DATA",
  IS_MID_FLOW = "IS_MID_FLOW",
  IS_MULTI_SKU = "IS_MULTI_SKU",
  BULK_RECEIVE = "BULK_RECEIVE",
  PALLET_BULK_RECEIVE = "PALLET_BULK_RECEIVE",
  IS_EXPEDITED_FLOW_ELIGIBLE = "IS_EXPEDITED_FLOW_ELIGIBLE",
  IS_BULK_RECEIVE = "IS_BULK_RECEIVE",
  RECEIVING_MISMATCH = "RECEIVING_MISMATCH",
  IS_REROUTE_FROM_RECEIVING = "IS_REROUTE_FROM_RECEIVING",
  CURRENT_PRODUCT = "CURRENT_PRODUCT",
  NC_PRODUCT_LOT_FEFO_DETAILS = "NC_PRODUCT_LOT_FEFO_DETAILS",
  TICKER_CENTER_DATA = "TICKER_CENTER_DATA",
  ISSUE_CENTER_DATA = "ISSUE_CENTER_DATA",
  WAREHOUSE_APP_DATA = "WAREHOUSE_APP_DATA",
  IS_MID_BULK_RECEIVE_FLOW = "IS_MID_BULK_RECEIVE_FLOW",
  IS_DEFAULT_LOCATION_RECEIVE = "IS_DEFAULT_LOCATION_RECEIVE",
  DEFAULT_LOCATION_DATA = "DEFAULT_LOCATION_DATA",
  NUM_OF_BOXES_EXPECTED = "NUM_OF_BOXES_EXPECTED",
  STORAGE_ARRIVAL_SCAN = "STORAGE_ARRIVAL_SCAN",
  STORAGE_RECEIVE = "STORAGE_RECEIVE",
  FILTER_CATEGORIES = "FILTER_CATEGORIES",
  STORAGE_IS_CURRENT_SKU_NOT_AVAILABLE = "STORAGE_IS_CURRENT_SKU_NOT_AVAILABLE",
  PALLET_ANALYTICS_V2 = "PALLET_ANALYTICS_V2",
  IS_MISSING_LOT_OR_EXPIRY_CONSOLIDATION_RECEIVE = "IS_MISSING_LOT_OR_EXPIRY_CONSOLIDATION_RECEIVE",
  IS_ELEVATED_USER = "IS_ELEVATED_USER",
  RS_OUTBOUNDS = "RS_OUTBOUNDS",
}
