import { defineMessages, MessageDescriptor } from "react-intl";

export enum MissingInputErrorErrorMessageKey {
  MISSING_INPUT = "MISSING_INPUT",
}

export const MISSING_INPUT_ERROR_BY_ERROR_CODE: Record<
  MissingInputErrorErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [MissingInputErrorErrorMessageKey.MISSING_INPUT]: {
    id: "warehouse.receiving.missingInputError",
    defaultMessage: "Request cannot be completed because required inputs are missing.",
  },
});
