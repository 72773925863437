import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";

import { hasLowerCase } from "facility-commons/utils/config";
import { OrderPickingField } from "./OrderPickingForm.types";
import { orderPickingValidationConfig as validation, orderPickingNcServiceErrorMap } from "./OrderPickingFormConfig";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { composeUseWarehouseEmailState } from "facility-commons/base/warehouseEmailState";
import { userState } from "facility-commons/base/Auth/userState";
import { useWarehouseDamagedProductSuccessModal } from "warehouse/ticket-center/common/hooks/useWarehouseDamagedProductSuccessModal";

function parseServiceErrors(error: Partial<Error> & Partial<{ subcode: string }>) {
  if (error.subcode && orderPickingNcServiceErrorMap[error.subcode]) {
    return orderPickingNcServiceErrorMap[error.subcode];
  }

  return orderPickingNcServiceErrorMap.unknown;
}

export const useOrderPickingForm = () => {
  const { nonComplianceClient, warehousePublicClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const useWarehouseEmailState = composeUseWarehouseEmailState(warehousePublicClient);
  const warehouseEmailAsyncState = useWarehouseEmailState(warehouseId);

  const { showSuccessModal } = useWarehouseDamagedProductSuccessModal();

  const formHandler = useForm({
    mode: "onBlur",
  });

  const watchBarcode: string = formHandler.watch(OrderPickingField.BARCODE);

  // watch barcode input value, and convert any lowercase characters to upper
  useEffect(() => {
    if (watchBarcode && hasLowerCase.test(watchBarcode)) {
      formHandler.setValue(OrderPickingField.BARCODE, watchBarcode.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBarcode]);

  const [submitState, submitData] = useAsyncFn((data) => {
    return nonComplianceClient.createSelfServePickingDamage(data);
  });

  // show modal on successful damage case submission + error handling
  useEffect(() => {
    if (!submitState.loading && submitState?.value?.id) {
      showSuccessModal(submitState.value.id, true);
    }

    if (submitState.error) {
      formHandler.setError(...parseServiceErrors(submitState.error));
    }
  }, [submitState, showSuccessModal, formHandler]);

  const onFormValues = async (values: FieldValues) => {
    const requestData: Record<string, unknown> = { warehouseId };

    Object.entries(values).forEach(([fieldKey, fieldValue]) => {
      if (fieldValue !== undefined && fieldValue !== "") {
        requestData[fieldKey] = fieldValue;
      }
    });

    requestData.isDisposed = Boolean(Number(values.isDisposed));
    requestData.boxDamaged = false;

    submitData(requestData);
  };

  const onFormSubmit = formHandler.handleSubmit(onFormValues);

  return {
    errors: formHandler.formState.errors,
    formHandler,
    preventSubmit: submitState.loading,
    onFormSubmit,
    warehouseEmailAsyncState,
    register: {
      [OrderPickingField.DAMAGED_PRODUCT_PHOTO]: formHandler.register(
        OrderPickingField.DAMAGED_PRODUCT_PHOTO,
        validation[OrderPickingField.DAMAGED_PRODUCT_PHOTO]
      ),
      [OrderPickingField.ADDITIONAL_PHOTO]: formHandler.register(
        OrderPickingField.ADDITIONAL_PHOTO,
        validation[OrderPickingField.ADDITIONAL_PHOTO]
      ),
      [OrderPickingField.BARCODE]: formHandler.register(
        OrderPickingField.BARCODE,
        validation[OrderPickingField.BARCODE]
      ),
      [OrderPickingField.DAMAGE_TYPE]: formHandler.register(OrderPickingField.DAMAGE_TYPE),
      [OrderPickingField.QUANTITY]: formHandler.register(
        OrderPickingField.QUANTITY,
        validation[OrderPickingField.QUANTITY]
      ),
      [OrderPickingField.DISPOSED]: formHandler.register(OrderPickingField.DISPOSED),
      [OrderPickingField.OTHER_DETAILS]: formHandler.register(
        OrderPickingField.OTHER_DETAILS,
        validation[OrderPickingField.OTHER_DETAILS]
      ),
      [OrderPickingField.EMAIL]: formHandler.register(OrderPickingField.EMAIL, validation[OrderPickingField.EMAIL]),
    },
  };
};
