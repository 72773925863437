import * as React from "react";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { WAREHOUSE_AUTH_AUDIENCE } from "./audience";
import { useRouter } from "facility-commons/hooks";
import { formRedirectUrl } from "./AuthUtils";

interface AuthProviderProps {
  app?: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ app = "", children }) => {
  const domain = process.env.REACT_APP_CUSTOM_AUTH0_LOGIN_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const router = useRouter();

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (appNameState) => {
    const route = appNameState?.returnTo?.split("/").slice(2).join("/"); // avoid duplicate "/scan" in redirect
    router.push(route ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      redirectUri={formRedirectUrl([app])}
      audience={WAREHOUSE_AUTH_AUDIENCE}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
