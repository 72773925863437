import { useState } from "react";
import { useAsyncFn } from "react-use";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { toast } from "@deliverr/ui";
import { log, logStart } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { StorageErrorCodes } from "warehouse/common/messages/storage/StorageErrorCodes";
import { STORAGE_ARRIVAL_SCAN_MESSAGES } from "warehouse/common/messages/storage";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { SUCCESS_TOAST_AUTO_CLOSE, isFBABookingType, isPrepBookingType } from "warehouse/receiving/utils";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { storageInboundStateAtom } from "./StorageArrivalScanState";
import { FORWARDING_STAGING_LOCATION } from "warehouse/common/messages/storage/StorageForwarding.const";
import { infoModalIcon, warningModalCircleIcon } from "facility-commons";
import { BookingType, StorageInboundResponse } from "@deliverr/storage-client";

export const useStorageArrivalScan = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const { warehouseId } = useRecoilValue(userState);
  const { storageClient } = useClientsWithAuth();
  const { playSfx, emitFlash } = useCommonFlow();
  const [scanError, setScanError] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundStateAtom);

  const showPrepOrForwardingModal = (
    prepOption: StorageInboundResponse["prepOption"] | undefined,
    bookingType: BookingType | undefined,
    asnId: string,
    alreadyScanned: boolean = false
  ) => {
    if (isPrepBookingType(prepOption)) {
      showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
        title: formatMessage(
          alreadyScanned
            ? STORAGE_ARRIVAL_SCAN_MESSAGES.requiresPrepAsnIdAlreadyScannedTitle
            : STORAGE_ARRIVAL_SCAN_MESSAGES.requiresPrepTitle
        ),
        text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.prepLocationText, {
          asnId,
        }),
        soundFx: alreadyScanned ? SoundFx.INFO : SoundFx.SUCCESS,
        iconProps: alreadyScanned ? warningModalCircleIcon : infoModalIcon,
      });
    } else if (isFBABookingType(bookingType)) {
      const title = alreadyScanned
        ? STORAGE_ARRIVAL_SCAN_MESSAGES.fbaForwardingLocationAsnIdAlreadyScannedTitle
        : STORAGE_ARRIVAL_SCAN_MESSAGES.fbaForwardingLocationTitle;
      showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
        title: formatMessage(title, {
          location: FORWARDING_STAGING_LOCATION,
        }),
        text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.fbaForwardingLocationText, {
          asnId,
        }),
        soundFx: alreadyScanned ? SoundFx.INFO : SoundFx.SUCCESS,
        iconProps: alreadyScanned ? warningModalCircleIcon : infoModalIcon,
      });
    }
  };

  const [submitState, handleSubmit] = useAsyncFn(async (asnIdParam: string) => {
    const handleSubmitArrivalScan = async (value: string) => {
      const ctx = { fn: "useStorageArrivalScan.handleSubmitArrivalScan" };
      log(ctx, "submitting arrival timestamp", { asnId: value });
      try {
        const { arrivedAt, asnId, bookingType, prepOption } = await storageClient.setShipmentStatus(value, warehouseId);
        setScanState({ asnId, arrivedAt });
        if (isPrepBookingType(prepOption) || isFBABookingType(bookingType)) {
          showPrepOrForwardingModal(prepOption, bookingType, value);
        } else {
          playSfx(SoundFx.SUCCESS);
          emitFlash("SUCCESS");
          toast.success(
            formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.successNotificationDescription, {
              asnId,
            }),
            {
              autoClose: SUCCESS_TOAST_AUTO_CLOSE,
              toastId: "storageArrivalScanSuccess",
            }
          );
        }
      } catch (err: any) {
        if (err.response) {
          const res = err.response.data?.error;
          const message = res?.message?.replace(/asn/gi, "PO #");
          switch (res?.code) {
            case StorageErrorCodes.BAD_REQUEST:
              const isAlreadyScanned = message.includes("already scanned");
              if (isAlreadyScanned) {
                try {
                  // check if its forwarding or Prep inbound
                  const { bookingType, prepOption } = await storageClient.getStorageInboundByAsnId(value, warehouseId);
                  if (isPrepBookingType(prepOption) || isFBABookingType(bookingType)) {
                    showPrepOrForwardingModal(prepOption, bookingType, value, true);
                  } else {
                    // if neither prep or forwarding, then we just let the user know arrival was already scanned
                    showModal(WarehouseModal.STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL, {
                      title: res?.name,
                      text: message,
                    });
                  }
                } catch (err: any) {
                  // if we are unable to get the booking or prep type, we also default to letting user know
                  // arrival was already scanned
                  showModal(WarehouseModal.STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL, {
                    title: res?.name,
                    text: message,
                  });
                  return;
                }
              } else {
                showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
                  title: res?.name,
                  text: message,
                });
              }
              return;
            case StorageErrorCodes.DATABASE_ERROR:
            case StorageErrorCodes.NOT_FOUND:
            default: {
              showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
                title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
                text: message,
              });
              return;
            }
          }
        } else {
          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.contactSupportDescription),
          });
        }
      } finally {
        setScanState({});
      }
    };

    logStart({ fn: "useStorageArrivalScan.handleSubmit", asnId: asnIdParam, warehouseId, scanError });
    setScanState({ asnId: asnIdParam });
    return handleSubmitArrivalScan(asnIdParam);
  });

  const handleSubmitClick = () => {
    setScanError("");
    handleSubmit(scanState.asnId ?? "");
  };

  const handleChange = (value) => {
    setScanState((state) => ({ ...state, asnId: value }));
  };

  return { scanState, scanError, handleChange, handleSubmit, handleSubmitClick, loading: submitState.loading };
};
