import axios from "axios";
import { trimAll, getRemoteAddress } from "@deliverr/ui";
import { logError, log } from "facility-commons/utils";

declare const VERSION: string;

export default async function checkNewVersionService() {
  try {
    const { data: version } = await axios.get<string>(`${getRemoteAddress(window.location.href)}/version.txt`, {
      headers: {
        "Cache-Control": "max-age=0, no-cache, no-store, must-revalidate",
        "Content-Type": "text/plain",
      },
      responseType: "text",
    });

    // VERSION is substituted in at runtime using a custom webpackPlugin. See config-overrides.js
    const curVersion = trimAll(String(VERSION));
    const remoteVersion = trimAll(String(version));

    if (!curVersion) {
      logError({ fn: "checkNewVersionService" }, new Error("Current application version not set."));
      return false;
    }

    if (!remoteVersion) {
      logError({ fn: "checkNewVersionService" }, new Error("Remote application version not found / retrieved."));
      return false;
    }

    if (curVersion !== remoteVersion) {
      log({ fn: "checkNewVersionService" }, `Version mismatch! Current:${curVersion} Remote:${remoteVersion}`);
    }
    // if git version is only number, axios treats as number
    return curVersion !== remoteVersion;
  } catch (e) {
    logError({ fn: "checkNewVersionService" }, e);
    return;
  }
}
