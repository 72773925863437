import React from "react";
import { Anchor, Text, Title } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled-base";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { IllustrationCard } from "warehouse/common/components";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { TextInputCard } from "facility-commons/flow";
import { useBulkScanCard } from "./useBulkScanCard";
import { cdskuMessages } from "warehouse/receiving/content";

const FlatTopInputCard = styled(TextInputCard)`
  border-radius: 0px 0px 8px 8px;
`;

export const BulkScanCard: React.FC = () => {
  const {
    boxesScanned,
    boxesToScan,
    currentCdsku,
    handleChange,
    handleSubmit,
    receiveError,
    loading,
    showUpdateQuantityModal,
  } = useBulkScanCard();

  return (
    <>
      <IllustrationCard>
        <ScanBox />
        <Title displayAs="h4" data-testid="bulk-scan-boxes-to-go" textAlign={TextAlign.center}>
          <FormattedMessage
            id="warehouse.receiving.boxesToGo"
            defaultMessage="Box {boxesScanned} of {boxesToScan}"
            values={{ boxesScanned, boxesToScan }}
          />
        </Title>
        <Text textAlign={TextAlign.center}>
          <FormattedMessage
            id="warehouse.receiving.miscounted"
            defaultMessage="Miscounted? <a>Update</a>"
            values={{
              a: (content) => <Anchor onClick={showUpdateQuantityModal}>{content}</Anchor>,
            }}
          />
        </Text>
      </IllustrationCard>
      <FlatTopInputCard
        errorMessage={receiveError}
        helpText={cdskuMessages.helpText}
        message={cdskuMessages.message}
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={{ id: "warehouse.cdsku", defaultMessage: "CDSKU" }}
        title={cdskuMessages.title}
        value={currentCdsku}
        loading={loading}
      />
    </>
  );
};
