import { ThemeProps, Text } from "@deliverr/ui";
import styled from "@emotion/styled";

export const GroupHeader = styled(Text)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["400"]};
    font-size: ${theme.font.size.F3};
    margin-bottom: ${theme.spacing.S2};
    font-weight: ${theme.font.weight.BOLD};
`
);
