import { NonComplianceErrors } from "@deliverr/commons-clients";
import { LogContext } from "@deliverr/ui-logging";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";

import { createDangerNotification, logError, setProp } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { errorMessages } from "warehouse/common/errorMessages";
import { commonTicketMessages } from "warehouse/ticket-center/new/non-compliance/content";
import {
  INITIAL_PRODUCT,
  currentProductState,
  StorageNonCompliantProduct,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { receivingMisMatchState, isRerouteFromReceivingState } from "warehouse/receiving/ReceivingState";
import { currentSkuState } from "warehouse/receiving/ReceivingState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useMount } from "react-use";

export const useNonComplianceFlow = () => {
  const { addAutoCloseNotification, errorResponse, handleUnknownError } = useCommonFlow();
  const { formatMessage } = useIntl();
  const [currentProduct, setCurrentProduct] = useRecoilState(currentProductState);
  const [isRerouteFromReceiving, setIsRerouteFromReceiving] = useRecoilState(isRerouteFromReceivingState);

  const updateCurrentProduct = (key: keyof StorageNonCompliantProduct, value: any) =>
    setCurrentProduct((product) => setProp(key, value)(product));

  const initializeNcProduct = () => {
    setCurrentProduct({ ...INITIAL_PRODUCT });
    setCurrentProduct((product) => setProp("incidentId", uuid())(product));
  };

  const useInitializeFefoNCProduct = ({ initializeDsku = false }: { initializeDsku: boolean }) => {
    const isMismatch = useRecoilValue(receivingMisMatchState);
    const setWarehouseAppState = useSetRecoilState(warehouseAppState);
    const { cdsku, dsku } = useRecoilValue(currentSkuState);

    useMount(() => {
      // if a mismatch happened during receiving Fefo, we route users to NC and pre-populate cdsku and dsku
      if (isMismatch && isRerouteFromReceiving) {
        initializeNcProduct();
        updateCurrentProduct("cdsku", cdsku);
        if (initializeDsku) {
          updateCurrentProduct("barcode", dsku);
        }
        setWarehouseAppState(setProp("pageSubtitle", cdsku));
        setIsRerouteFromReceiving(false);
      }
    });
  };

  const handleNonComplianceError = (ctx: LogContext, err: any) => {
    if (err.subcode === NonComplianceErrors.INCIDENT_ALREADY_RESOLVED) {
      logError({ ...ctx, err }, new Error("Incident already resolved"));
      errorResponse();
      addAutoCloseNotification(createDangerNotification(formatMessage(commonTicketMessages.alreadyResolved)));
    } else {
      handleUnknownError(
        { ...ctx, err },
        {
          message: formatMessage(errorMessages.unknownError),
        }
      );
    }
  };

  return {
    handleNonComplianceError,
    initializeNcProduct,
    useInitializeFefoNCProduct,
    updateCurrentProduct,
    currentProduct,
  };
};
