export enum ROLES {
  WAREHOUSE_MANAGER_ROLE = "warehouse-manager",
  WAREHOUSE_ADMIN_ROLE = "warehouse-support",
  PROBLEM_SOLVER_ROLE = "warehouse-problem-solver",
}

export enum AUTH_LOCAL_STORAGE_KEYS {
  WAREHOUSE_ID = "warehouseId",
  USERNAME = "username",
}

export enum COOKIE_KEYS {
  WAREHOUSE_ID = "warehouseId",
  EMAIL = "email",
  DEFAULT_STOW_LOCATION = "defaultStowLocation",
  LANGUAGE = "LANGUAGE",
}
