import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";

export const TextButton = styled.button<ThemeProps>(
  ({ theme }) => `
  border: none;
  color: ${theme.colors.BLUE[300]};
  font-family: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F3};
  text-align: left;

  &:focus {
    outline: none;
  }
`
);
