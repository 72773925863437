import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletReceiveErrorMessageKey {
  ASN_MISMATCH = "ASN_MISMATCH",
  ASN_NOT_FORWARDING = "ASN_NOT_FORWARDING",
  TOO_MANY_BOXES = "TOO_MANY_BOXES",
  REPEATED_RECEIVES = "REPEATED_RECEIVES",
  TOO_MANY_RECEIVES_IN_BATCH = "TOO_MANY_RECEIVES_IN_BATCH",
  MIXED_CONFIGURATIONS = "MIXED_CONFIGURATIONS",
  WAREHOUSE_MISMATCH = "WAREHOUSE_MISMATCH",
  INVALID_BULK_PALLET_ITEMS = "INVALID_BULK_PALLET_ITEMS",
  PALLET_NOT_FOUND = "PALLET_NOT_FOUND",
  ASN_NOT_FOUND = "ASN_NOT_FOUND",
  DSKU_NOT_ON_PALLET = "DSKU_NOT_ON_PALLET",
}

export const PALLET_RECEIVE_ERROR_BY_ERROR_CODE: Record<
  PalletReceiveErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletReceiveErrorMessageKey.ASN_MISMATCH]: {
    id: "warehouse.pallet.asnMismatch",
    defaultMessage: "This CDSKU does not belong in this pallet. To receive this box, use Box Receive.",
  },
  [PalletReceiveErrorMessageKey.ASN_NOT_FORWARDING]: {
    id: "warehouse.pallet.asnNotFWDing",
    defaultMessage: "Only CD pallets (yellow label) can be pallet received. Break pallet and receive boxes.",
  },
  [PalletReceiveErrorMessageKey.TOO_MANY_BOXES]: {
    id: "warehouse.pallet.tooManyBoxes",
    defaultMessage: "You have too many boxes for this pallet. Break pallet and receive boxes.",
  },
  [PalletReceiveErrorMessageKey.REPEATED_RECEIVES]: {
    id: "warehouse.pallet.repeatedReceive",
    defaultMessage: "Boxes on this pallet have already been received. Break pallet and receive boxes.",
  },
  [PalletReceiveErrorMessageKey.TOO_MANY_RECEIVES_IN_BATCH]: {
    id: "warehouse.pallet.tooManyReceivesInBatch",
    defaultMessage: "Pallet should only be received once in the same batch.",
  },
  [PalletReceiveErrorMessageKey.MIXED_CONFIGURATIONS]: {
    id: "warehouse.pallet.mixedConfigs",
    defaultMessage:
      "This pallet cannot be received because it has multiple box configurations. Break pallet and receive boxes",
  },
  [PalletReceiveErrorMessageKey.WAREHOUSE_MISMATCH]: {
    id: "warehouse.pallet.warehouseMismatch",
    defaultMessage: "Misrouted pallet. Scan pallet using the Unloading tool to generate a new PO for receiving.",
  },
  [PalletReceiveErrorMessageKey.INVALID_BULK_PALLET_ITEMS]: {
    id: "warehouse.pallet.invalidItems",
    defaultMessage: "Pallet contents are invalid.  Please contact your operations manager.",
  },
  [PalletReceiveErrorMessageKey.PALLET_NOT_FOUND]: {
    id: "warehouse.pallet.palletNotFound",
    defaultMessage: "Pallet not found.Please contact you operations manager.",
  },
  [PalletReceiveErrorMessageKey.ASN_NOT_FOUND]: {
    id: "warehouse.pallet.asnNotFound",
    defaultMessage: "Pallet is not associated with a PO #. Please create a non-compliance ticket.",
  },
  [PalletReceiveErrorMessageKey.DSKU_NOT_ON_PALLET]: {
    id: "warehouse.sku.dskuNotOnPallet",
    defaultMessage: "Scanned DSKU was not found on the pallet.",
  },
});
