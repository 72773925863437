import React from "react";

import { Dashboard } from "facility-commons/components/Dashboard/Dashboard";
import { ListViewCard, ListViewCardProps } from "../ListViewCard";
import { TicketListEmptyCard } from "../TicketListEmptyCard";
import { useTicketCenterDashboard } from "./useTicketCenterDashboard";

export const TicketCenterDashboard: React.FC = () => {
  const { isLoading, ticketList, currentTicketStatusDisplayed } = useTicketCenterDashboard();
  return (
    <Dashboard<ListViewCardProps>
      keyFn={({ type, id }) => `ticket-${type}-${id}`}
      items={ticketList}
      ItemRenderer={ListViewCard}
      isLoading={isLoading}
      emptyDisplay={<TicketListEmptyCard currentStatus={currentTicketStatusDisplayed} />}
    />
  );
};
