import { s3FileHandlerClient } from "facility-commons/base/Clients";
import { UploadItemCustomProps, UnconnectedUploadItem } from "facility-commons/components";
import React from "react";
import { useNonComplianceUploadItem } from "./useNonComplianceUploadItem";

export const NonComplianceUploadItem = React.forwardRef<HTMLInputElement, UploadItemCustomProps>(
  ({ iconText, id, labelText, name, onSuccessfulUpload }, ref) => {
    const { onInputFileChange, uploadProgress, uploading, value } = useNonComplianceUploadItem(
      { name, onSuccessfulUpload },
      s3FileHandlerClient
    );

    return (
      <UnconnectedUploadItem
        ref={ref}
        id={id}
        value={value}
        uploading={uploading}
        uploadProgress={uploadProgress}
        onInputFileChange={onInputFileChange}
        iconText={iconText}
        labelText={labelText}
        onSuccessfulUpload={onSuccessfulUpload}
      />
    );
  }
);
