import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { usePrintZpl } from "facility-commons/utils/zpl/usePrintZpl";
import { isZebraScanner, log, logStart, printPdf } from "facility-commons/utils";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useBarcodePrint = () => {
  const { showModal, hideAllModals } = useWarehouseModal();
  const { returnsClient } = useClientsWithAuth();
  const { successResponse } = useCommonFlow();
  const { printZpl } = usePrintZpl();

  const printBarcode = async (barcode: string, customText = "", flashAndSoundOnSuccess = false) => {
    try {
      const ctx = logStart({ fn: "printReturnTagLabel" });

      if (isZebraScanner) {
        const zpl = await returnsClient.printBarcodeZpl({
          barcode,
          customText,
          labelFormat: "ZPL",
        });

        log(ctx, "retrieved returnTag label zpl");
        printZpl(zpl);
      } else {
        const pdf = await returnsClient.printBarcodePdf({
          barcode,
          customText,
          labelFormat: "PDF",
        });

        log(ctx, "retrieved returnTag label pdf");
        printPdf(new Blob([pdf], { type: "application/pdf" }));
      }

      log(ctx, "printed returnTag label");
      if (flashAndSoundOnSuccess) {
        successResponse();
      }
    } catch (error) {
      log({ fn: "useBarcodePrint.PrintBarcode", error }, "error printing return receive barcode");
      hideAllModals(); // need this in case this modal is opened from another modal
      showModal(WarehouseModal.RETURN_RECEIVE_RTM_PRINT_BARCODE_ERROR, { errorString: String(error) });
      return;
    }
  };

  return {
    printBarcode,
  };
};
