import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { SoundFx } from "facility-commons/common/sfx";
import { useUnexpectedReceiveAttemptModal } from "./useUnexpectedReceiveAttemptModal";

export const UnexpectedReceiveAttemptModal: React.FC = () => {
  const { messages, loading, recordUnexpectedReceiveAttempt } = useUnexpectedReceiveAttemptModal();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={messages.title}
      text={messages.text}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
    >
      <Grid gap="S2" fullWidth>
        <Button
          block
          data-testid="generic-warning-confirm-btn"
          loading={loading}
          onClick={recordUnexpectedReceiveAttempt}
        >
          {messages.confirm}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
