import { WarehouseCapability } from "@deliverr/business-types";
import { WarehousePublicClient } from "@deliverr/warehouse-client";
import { AppName } from "facility-commons/types";
import { log, logError } from "facility-commons/utils";
import { difference } from "lodash";

const ARCHIVED_CROSSDOCK_IDS = ["GPA_COM", "SFW_SFS", "HUB_FON", "HUB_EDI", "PBD_DUL"];

export const getWarehouseIdsByApp = async (
  appName: AppName,
  warehousePublicClient: WarehousePublicClient
): Promise<string[]> => {
  const ctx = { fn: "getWarehouseIdsByApp", appName };
  let warehouseIds: string[] = [];
  let crossdockIds: string[] = [];
  let prepIds: string[] = [];
  const warehouseIdsMap = new Map<WarehouseCapability, boolean>();
  warehouseIdsMap.set(WarehouseCapability.ARCHIVED, false);

  try {
    warehouseIds = (await warehousePublicClient.getWarehousesByCapabilitiesMap(warehouseIdsMap)) ?? [];
    crossdockIds = (await warehousePublicClient.getWarehousesByCapabilities([WarehouseCapability.IS_CROSS_DOCK])) ?? [];
    prepIds = (await warehousePublicClient.getWarehousesByCapabilities([WarehouseCapability.IS_PREP])) ?? [];

    log({ ctx, warehouseIdsMap, warehouseIds, crossdockIds, prepIds }, "retrieved warehouse ids");
  } catch (err) {
    logError({ fn: "getWarehouseIds" }, err);
  } finally {
    // If user is in WP app, return all FCs (all warehouse Ids excluding crossdock)
    // else if user is in CD app, return all CDs
    if (appName === AppName.WP) {
      return difference(warehouseIds, crossdockIds);
    } else {
      // This is a temporary change to help Prep launch with a short timeline at storage facilities.
      // Eventually, we  will remove this in favor of a better solution: https://shopifylogistics.atlassian.net/browse/PREP-3645
      if (appName === AppName.PR) {
        return prepIds;
      }
      return difference(crossdockIds, ARCHIVED_CROSSDOCK_IDS);
    }
  }
};
