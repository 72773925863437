import React from "react";
import { Input, Stack, Title } from "@deliverr/ui";

import { ContinueButton } from "warehouse/common/components";
import { useLotNumber } from "./useLotNumber";
import { lotNumberMessages } from "warehouse/ticket-center/new/non-compliance/content";
import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";
import { FacilityCard, WarehouseMessages } from "facility-commons";

export const LotNumber: React.FC = () => {
  const { formatMessage, reportedLot, disableButton, proceed, onSkip, updateLotNumber } = useLotNumber();

  return (
    <Stack>
      <FacilityCard>
        <Stack>
          <Title displayAs="h3">{formatMessage(lotNumberMessages.lotCardTitle)}</Title>
          <Input
            onChange={updateLotNumber}
            placeholder={formatMessage(WarehouseMessages.lotNumber)}
            value={reportedLot}
          />
          <SkipStep message={formatMessage(lotNumberMessages.unreadableLot)} onSkip={onSkip} hideSeparator />
        </Stack>
      </FacilityCard>
      <ContinueButton block disabled={disableButton} onClick={proceed} />
    </Stack>
  );
};
