import { log, logStart, printPdf } from "facility-commons";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { convertBase64StringToBlob } from "facility-commons/utils/convertBase64StringToBlob";
import { useRecoilValue } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";

export const useShipmentSummaryReprintAllLabelsButton = () => {
  const { wholesaleClient } = useClientsWithAuth();
  const { currentShipmentId } = useRecoilValue(rsOutboundsStateAtom);
  const { errorResponse } = useCommonFlow();
  const ctx = logStart({ fn: "useShipmentSummaryReprintAllLabelsButton.reprintAllLabels" });
  const reprintAllLabels = async () => {
    try {
      const caseLabel = await wholesaleClient.printCaseLabelPdf(currentShipmentId);
      if (caseLabel) {
        const pdfBlob = convertBase64StringToBlob(caseLabel.data.contents);
        printPdf(pdfBlob);
      }
    } catch (error) {
      log({ ...ctx }, "error when getting case labels");
      errorResponse();
    }
  };

  return {
    reprintAllLabels,
  };
};
