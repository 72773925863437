import { defineMessages } from "react-intl";
import { TicketTabs } from "../../TicketDetails/TicketTabs";

export const TICKET_DETAIL_TABS_LABELS = defineMessages({
  [TicketTabs.OVERVIEW]: {
    id: "warehouse.ticketCenter.ticketDetailsTabs.overview",
    defaultMessage: "Overview",
  },
  [TicketTabs.RESOLUTION]: {
    id: "warehouse.ticketCenter.ticketDetailsTabs.resolution",
    defaultMessage: "Resolution",
  },
});
