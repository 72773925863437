import { BoxReset, LocalizedMessage, Stack, Text, ThemeProps } from "@deliverr/ui";
import { Appearance } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

export interface BaseDashboardDetailCardProps {
  /** Explicit override of the default "Created on <date>" header */
  headerLabel?: LocalizedMessage;
  actions?: ReactNode;
}

const HeaderText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F2};
    margin-bottom: ${theme.spacing.S4};
  `
);

export const BaseDashboardDetailCard: React.FC<React.PropsWithChildren<BaseDashboardDetailCardProps>> = ({
  children,
  headerLabel,
  actions,
}) => {
  const Header = headerLabel ? (
    typeof headerLabel === "string" ? (
      <HeaderText appearance={Appearance.INFO}>{headerLabel}</HeaderText>
    ) : (
      <>{headerLabel}</>
    )
  ) : undefined;

  return (
    <div>
      <FacilityCard header={Header}>{children}</FacilityCard>
      <BoxReset marginTop="S4">
        <Stack gap="S4">{actions}</Stack>
      </BoxReset>
    </div>
  );
};
