import React from "react";
import { Link } from "react-router-dom";
import { ThemeProps, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { ReceivingPath } from "warehouse/receiving/routes";
import { richText } from "facility-commons/intl/richText";
interface SkuSummaryCardProps {
  expectedSkus: number;
  numSkus: number;
  receivedUnits: number;
}

const SummaryCard = styled(FacilityCard)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
    padding-top: ${theme.spacing.S4};
    padding-bottom: ${theme.spacing.S4};
    display: flex;
    justify-content: space-between;
  `
);

const StyledLink = Text.withComponent(Link);

export const SkuSummaryCard: React.FC<SkuSummaryCardProps> = ({ expectedSkus, numSkus, receivedUnits }) => {
  const displayCard = expectedSkus > 1 && numSkus > 0;

  return displayCard ? (
    <SummaryCard as="section">
      <Text data-testid="SkuSummaryCard.message">
        <FormattedMessage
          id="warehouse.receiving.skusReceivedMessage"
          defaultMessage="<boldtext>Received</boldtext> {numSkus, plural, =1 {# SKU} other {# SKUs}}, {receivedUnits, plural, =1 {# unit} other {# units}}"
          values={richText({ numSkus, receivedUnits })}
        />
      </Text>
      <StyledLink appearance="DEFAULT" bold to={ReceivingPath.REVIEW_PRODUCTS}>
        <FormattedMessage id="warehouse.receiving.viewAllSkus" defaultMessage="View all" />
      </StyledLink>
    </SummaryCard>
  ) : null;
};
