import { log, logError, logStart } from "facility-commons";
import { userState } from "facility-commons/base/Auth/userState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { currentSkuState } from "warehouse/receiving/ReceivingState";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import { useRouter } from "facility-commons/hooks";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useWarehouseModal } from "warehouse/modal";

export const useUnexpectedReceiveAttemptModal = () => {
  const { inboundClient } = useClientsWithAuth();
  const { formatMessage } = useIntl();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const { cdsku, dsku, expectedQuantity, previouslyReceivedQuantity, quantity } = useRecoilValue(currentSkuState);
  const router = useRouter();
  const { successResponse } = useCommonFlow();
  const { hideAllModals } = useWarehouseModal();

  const attemptedReceiveQty = quantity > 0 ? quantity : null;

  const handlePostSubmission = () => {
    hideAllModals();
    successResponse();
    router.push(ReceivingPath.CDSKU);
  };

  // Make a best-effort to record the unexpected receive attempt
  // But don't block the operator from moving onto the next receive if recording fails
  const [submitState, recordUnexpectedReceiveAttempt] = useAsyncFn(async () => {
    const ctx = {
      fn: "useUnexpectedReceiveAttemptModal.recordUnexpectedReceiveAttempt",
      cdsku,
      dsku,
      attemptedReceiveQty,
      facilityId,
    };
    logStart(ctx);

    try {
      const res = await inboundClient.recordUnexpectedReceiveAttempt({ cdsku, dsku, attemptedReceiveQty, facilityId });
      if (res.error) {
        logError(ctx, res.error);
      }
      log(ctx, "Unexpected receive attempt recorded");
    } catch (error) {
      logError(ctx, error);
    } finally {
      handlePostSubmission();
    }
  }, [cdsku, dsku, attemptedReceiveQty, facilityId]);

  const unexpectedReceive = expectedQuantity === 0;
  const overReceive = expectedQuantity < quantity + previouslyReceivedQuantity;

  const messages = {
    title: unexpectedReceive
      ? formatMessage(modalsText.unexpectedSkuTitle)
      : overReceive
      ? formatMessage(modalsText.overReceive)
      : formatMessage(modalsText.underReceive),
    text: formatMessage(modalsText.moveToProblemSolverArea),
    confirm: formatMessage(modalsText.confirm),
  };

  return { messages, loading: submitState.loading, recordUnexpectedReceiveAttempt };
};
