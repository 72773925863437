import React from "react";
import { useReturnReceiveFlow } from "./useReturnReceiveFlow";
import { ReturnReceiveFlow } from "./ReturnReceiveFlow";
import { createReturnReceiveTrackingCodeScan } from "./returnReceiveCardCreators";
import { useInitializeReturnReceiveStates } from "../hooks";

export const ReturnReceiveCard: React.FC = () => {
  const { resetFlow } = useReturnReceiveFlow();
  const { initializeAllReturnReceiveStates } = useInitializeReturnReceiveStates();

  const onUnmount = () => {
    initializeAllReturnReceiveStates();
    resetFlow();
  };

  return <ReturnReceiveFlow onMountCardData={createReturnReceiveTrackingCodeScan({})} onUnmount={onUnmount} />;
};
