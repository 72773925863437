import { defineMessages } from "react-intl";

export const STORAGE_ARRIVAL_SCAN_MESSAGES = defineMessages({
  boxTitle: {
    id: "storage.arrivalScan.boxTitle",
    defaultMessage: "Enter PO or Flex ID",
  },
  boxDescription: {
    id: "storage.arrivalScan.boxDescription",
    defaultMessage: "Enter the shipment's PO, ASN, or FlexId to record its arrival.",
  },
  boxPlaceholder: {
    id: "storage.arrivalScan.placeholder",
    defaultMessage: "PO, ASN, or FlexId",
  },
  scanErrorUnrecognisedTitle: {
    id: "storage.arrivalScan.error.unrecognisedTitle",
    defaultMessage: "Shipment not recognized",
  },
  contactSupportDescription: {
    id: "storage.arrivalScan.error.contactSupportDescription",
    defaultMessage: "If the error persists, contact support.",
  },
  scanErrorUnrecognisedDescription: {
    id: "storage.arrivalScan.error.unrecognisedDescription",
    defaultMessage: "Please try again.",
  },
  scanErrorAlreadyScannedTitle: {
    id: "storage.arrivalScan.error.alreadyScanned.title",
    defaultMessage: "Arrival already recorded",
  },
  scanErrorAlreadyScannedDescription: {
    id: "storage.arrivalScan.error.alreadyScanned.description",
    defaultMessage: "Arrival was recorded for PO # {asnId} {time}.",
  },
  scanErrorAlreadyScannedActionText: {
    id: "storage.arrivalScan.error.alreadyScanned.actionText",
    defaultMessage: "Mark as not arrived",
  },
  successNotificationTitle: {
    id: "storage.arrivalScan.success.title",
    defaultMessage: `Arrival recorded`,
  },
  successNotificationDescription: {
    id: "storage.arrivalScan.success.description",
    defaultMessage: `Arrival recorded for PO # {asnId}`,
  },
  errorEmptyValue: {
    id: "storage.arrivalScan.error.emptyValue",
    defaultMessage: "Value cannot be empty",
  },
  fbaForwardingLocationTitle: {
    id: "storage.arrivalScan.fbaBookingType.title",
    defaultMessage: "Arrival recorded for {location} location",
  },
  fbaForwardingLocationAsnIdAlreadyScannedTitle: {
    id: "storage.arrivalScan.fbaForwardingLocationAsnIdAlreadyScanned.title",
    defaultMessage: "Arrival already recorded for {location} location",
  },
  fbaForwardingLocationText: {
    id: "storage.arrivalScan.fbaBookingType.text",
    defaultMessage: "Receive {asnId} and move to forwarding location",
  },
  requiresPrepTitle: {
    id: "storage.arrivalScan.requiresPrep.title",
    defaultMessage: "Requires Prep",
  },
  requiresPrepAsnIdAlreadyScannedTitle: {
    id: "storage.arrivalScan.requiresPrepAsnIdAlreadyScanned.title",
    defaultMessage: "Arrival already recorded and requires prep",
  },
  prepLocationText: {
    id: "storage.arrivalScan.requiresPrep.text",
    defaultMessage: "Receive {asnId} and move to prep location",
  },
});
