import { isBefore, isEqual } from "date-fns";
import { DateErrorType, isValidDate } from "facility-commons/utils";
import { validLotInput } from "facility-commons/utils/config";

export enum LotInputValidationResult {
  MISSING_INPUT = "MISSING_INPUT",
  EXCEEDED_MAX_CHARACTER_COUNT = "EXCEEDED_MAX_CHARACTER_COUNT",
  INVALID_INPUT = "INVALID_INPUT",
  VALID = "VALID",
}

export enum ExpiryInputValidationResult {
  MISSING_INPUT = "MISSING_INPUT",
  INVALID_DATE = "INVALID_DATE",
  INVALID_YEAR = "INVALID_YEAR",
  INVALID_MONTH = "INVALID_MONTH",
  INVALID_DAY = "INVALID_DAY",
  EXPIRED_PRODUCT = "EXPIRED_PRODUCT",
  VALID = "VALID",
}

export const getLotInputValidationResults = (
  lotInput: string | undefined,
  maxLotInputLength: number
): LotInputValidationResult => {
  if (!lotInput) {
    return LotInputValidationResult.MISSING_INPUT;
  } else if (lotInput.length > maxLotInputLength) {
    return LotInputValidationResult.EXCEEDED_MAX_CHARACTER_COUNT;
  } else if (!validLotInput.test(lotInput)) {
    return LotInputValidationResult.INVALID_INPUT;
  }
  return LotInputValidationResult.VALID;
};

export const getExpiryInputValidationResults = (
  expiryInput: string | undefined,
  minYear: number,
  maxYear: number
): { result: ExpiryInputValidationResult; formattedDate?: string; lastDateOfMonth?: number } => {
  if (!expiryInput) {
    return { result: ExpiryInputValidationResult.MISSING_INPUT };
  }
  const dateValidation = isValidDate(expiryInput, {
    minYear,
    maxYear,
  });
  const dateParts = dateValidation?.dateParts;

  // though the input placeholder date format is MM/DD/YYYY,
  // the actual format saved in the backend is YYYY-MM-DD.
  const formattedDate = dateParts && `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;

  if (dateValidation.error) {
    switch (dateValidation.error) {
      case DateErrorType.INVALID_DATE:
        return { result: ExpiryInputValidationResult.INVALID_DATE };
      case DateErrorType.INVALID_YEAR:
        return { result: ExpiryInputValidationResult.INVALID_YEAR };
      case DateErrorType.INVALID_MONTH:
        return { result: ExpiryInputValidationResult.INVALID_MONTH };
      case DateErrorType.INVALID_DAY:
        return { result: ExpiryInputValidationResult.INVALID_DAY, lastDateOfMonth: dateValidation.lastDateOfMonth };
    }
  } else if (isBefore(new Date(formattedDate!), new Date()) || isEqual(new Date(formattedDate!), new Date())) {
    return { result: ExpiryInputValidationResult.EXPIRED_PRODUCT };
  }
  return { result: ExpiryInputValidationResult.VALID, formattedDate };
};
