import React from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import { Button, Stack } from "@deliverr/ui";
import { FACILITY_COMMONS_BUTTON_LABELS, ModalTemplate, warningModalCircleIcon } from "facility-commons";
import { SoundFx } from "facility-commons/common/sfx";
import { DateFormat } from "facility-commons/utils";
import { WarehouseModalProps, WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { STORAGE_ARRIVAL_SCAN_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundStateAtom } from "./StorageArrivalScanState";

export const StyledActionWrapper = styled(Stack)`
  width: 100%;
`;

export const StorageArrivalExistingShipmentWarnModal: React.FC<WarehouseModalProps> = (modal) => {
  const { hideModal } = useWarehouseModal();
  const [arrivalScanState] = useRecoilState(storageInboundStateAtom);

  return (
    <ModalTemplate
      soundFx={SoundFx.UNEXPECTED_QUANTITY}
      title={<FormattedMessage {...STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorAlreadyScannedTitle} />}
      text={
        modal.text ?? (
          <FormattedMessage
            {...STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorAlreadyScannedDescription}
            values={{
              asnId: arrivalScanState.asnId,
              time: arrivalScanState.arrivedAt ? format(arrivalScanState.arrivedAt, DateFormat.DateAtTime) : "",
            }}
          />
        )
      }
      iconProps={warningModalCircleIcon}
      buttonLayout="column"
    >
      <Button
        block
        onClick={() => hideModal(WarehouseModal.STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL)}
        type="button"
      >
        <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.backButton} />
      </Button>
    </ModalTemplate>
  );
};
