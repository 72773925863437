import { defaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

type ProgressBarType = "SOLID" | "STRIPED";

type ProgressBarColor = "BLUE" | "GRAY" | "LIGHT_BLUE";

const colorMap: Record<ProgressBarColor, string> = {
  BLUE: defaultTheme.colors.BLUE[300],
  GRAY: defaultTheme.colors.NEUTRAL[100],
  LIGHT_BLUE: defaultTheme.colors.BLUE[100],
};

// optional attributes included when using the striped style progress bar
// https://css-tricks.com/css3-progress-bars/
const getStripedProgressBarAttributes = (color: string) => `{
  content: "";
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    ${color} 25%, 
    transparent 25%, 
    transparent 50%, 
    ${color} 50%, 
    ${color} 75%, 
    transparent 75%, 
    transparent
  );
  background-size: 15px 15px;
  overflow: hidden;
}`;

const StyledDiv = styled.div<ThemeProps<{ type: ProgressBarType }>>(
  ({ theme }) => `
    background: ${theme.colors.NEUTRAL[80]};
    border-radius: ${theme.border.radius.R3};
    height: 6px;
    overflow: hidden;
    width: 100%;
  `
);

const StyledSpan = styled.span<ThemeProps<{ type: ProgressBarType; width: number; color: string }>>(
  ({ color, width, theme, type }) => `
    display: block;
    height: 100%;
    border: ${
      type === "STRIPED" ? `${theme.border.type} ${theme.colors.NEUTRAL[100]} ${theme.border.width.B1}` : "none"
    };
    border-top-left-radius: ${theme.border.radius.R3};
    border-bottom-left-radius: ${theme.border.radius.R3};
    border-top-right-radius: ${width < 100 ? 0 : theme.border.radius.R3};
    border-bottom-right-radius: ${width < 100 ? 0 : theme.border.radius.R3};
    background-color: ${type === "SOLID" ? color : theme.colors.NEUTRAL["00"]};
    overflow: hidden;
    width: ${width}%;
    position: relative;

    &:after {
      ${type === "STRIPED" ? getStripedProgressBarAttributes(color) : undefined}
    }
  `
);

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  barStyle: ProgressBarType;
  /** the color of the filled section when SOLID or the striped color when STRIPED- default is BLUE */
  barColor?: ProgressBarColor;
  /** percentage width that the progress line should fill, ex. 50 for halfway fill. (0-100) */
  progressPercent: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  barStyle,
  barColor = "BLUE",
  className,
  progressPercent,
}) => {
  return (
    <StyledDiv className={className} type={barStyle}>
      <StyledSpan type={barStyle} width={progressPercent} color={colorMap[barColor]} />
    </StyledDiv>
  );
};
