import React from "react";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { commonLotMessages } from "warehouse/receiving/content";
import { useLotCard } from "./useLotCard";
import { WarehouseMessages } from "facility-commons/utils";

export const LotCard: React.FC = () => {
  const { lotError, formatMessage, lotNumber, handleChange, submitLotNumber } = useLotCard();

  return (
    <StackedInputCard
      errorMessage={lotError}
      illustration={<ScanBox />}
      illustrationTitle={formatMessage(WarehouseMessages.boxScan)}
      message={formatMessage(commonLotMessages.lotInstructions)}
      onChange={handleChange}
      onSubmit={submitLotNumber}
      placeholder={formatMessage(commonLotMessages.lotNumber)}
      title={formatMessage(commonLotMessages.lotTitle)}
      value={lotNumber}
    />
  );
};
