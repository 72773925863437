import { defineMessages } from "react-intl";

export const lotNumberMessages = defineMessages({
  unreadableLot: {
    id: "warehouse.nonCompliance.unreadableLot",
    defaultMessage: "Can’t read lot number?",
  },
  lotCardTitle: {
    id: "warehouse.nonCompliance.lotTitle",
    defaultMessage: "Input lot number",
  },
  skipLotInputConfirmationModalTitle: {
    id: "warehouse.nonCompliance.skipLotInputConfirmationModalTitle",
    defaultMessage: "Skip lot number input?",
  },
});
