import {
  maxASNLength,
  maxBarcodeLength,
  maxCDSKULength,
  minASNLength,
  minBarcodeLength,
  minCDSKULength,
  validASNPattern,
  validBarcodePattern,
  validCDSKU,
  validEmailPattern,
  validPositiveIntegerPattern,
} from "facility-commons/utils/config";
import { DamagedProductField, DamagedProductValidationErrorMessage } from "./DamagedProductForm.types";
import { NumberValidation, StringValidation } from "../types/validation";
import { NonComplianceErrors } from "@deliverr/commons-clients";
import { ErrorOption } from "react-hook-form";

export const damagedProductValidationConfig: Record<string, StringValidation | NumberValidation> = {
  [DamagedProductField.ASN]: {
    required: DamagedProductValidationErrorMessage.ASN_REQUIRED,
    minLength: {
      value: minASNLength,
      message: DamagedProductValidationErrorMessage.ASN_FORMAT,
    },
    maxLength: {
      value: maxASNLength,
      message: DamagedProductValidationErrorMessage.ASN_FORMAT,
    },
    pattern: {
      value: validASNPattern,
      message: DamagedProductValidationErrorMessage.ASN_FORMAT,
    },
  },
  [DamagedProductField.BARCODE]: {
    required: DamagedProductValidationErrorMessage.BARCODE_REQUIRED,
    minLength: {
      value: minBarcodeLength,
      message: DamagedProductValidationErrorMessage.BARCODE_FORMAT,
    },
    maxLength: {
      value: maxBarcodeLength,
      message: DamagedProductValidationErrorMessage.BARCODE_FORMAT,
    },
    pattern: {
      value: validBarcodePattern,
      message: DamagedProductValidationErrorMessage.BARCODE_FORMAT,
    },
  },
  [DamagedProductField.CDSKU]: {
    required: DamagedProductValidationErrorMessage.CDSKU_REQUIRED,
    minLength: {
      value: minCDSKULength,
      message: DamagedProductValidationErrorMessage.CDSKU_FORMAT,
    },
    maxLength: {
      value: maxCDSKULength,
      message: DamagedProductValidationErrorMessage.CDSKU_FORMAT,
    },
    pattern: {
      value: validCDSKU,
      message: DamagedProductValidationErrorMessage.CDSKU_FORMAT,
    },
  },
  [DamagedProductField.QUANTITY]: {
    required: DamagedProductValidationErrorMessage.QTY_REQUIRED,
    min: {
      value: 1,
      message: DamagedProductValidationErrorMessage.QTY_TOO_SMALL,
    },
    pattern: {
      value: validPositiveIntegerPattern,
      message: DamagedProductValidationErrorMessage.QTY_INVALID,
    },
  },
  [DamagedProductField.DAMAGED_PRODUCT_PHOTO]: {
    required: DamagedProductValidationErrorMessage.DAMAGED_PRODUCT_PHOTO_REQUIRED,
  },
  [DamagedProductField.DAMAGED_EXTERIOR_PHOTO]: {
    required: DamagedProductValidationErrorMessage.DAMAGED_EXTERIOR_PHOTO_REQUIRED,
  },
  [DamagedProductField.EMAIL]: {
    pattern: {
      value: validEmailPattern,
      message: DamagedProductValidationErrorMessage.EMAIL_INVALID,
    },
  },
};

type SetErrorArgs = [string, ErrorOption];

// see function args in https://react-hook-form.com/api#setError
export const damagedProductNcServiceErrorMap: { [error: string]: SetErrorArgs } = {
  [NonComplianceErrors.ASN_INVALID]: [
    DamagedProductField.ASN,
    { type: "string", message: DamagedProductValidationErrorMessage[NonComplianceErrors.ASN_INVALID] },
  ],
  [NonComplianceErrors.BARCODE_INVALID]: [
    DamagedProductField.BARCODE,
    { type: "string", message: DamagedProductValidationErrorMessage[NonComplianceErrors.BARCODE_INVALID] },
  ],
  [NonComplianceErrors.CDSKU_INVALID]: [
    DamagedProductField.CDSKU,
    { type: "string", message: DamagedProductValidationErrorMessage[NonComplianceErrors.CDSKU_INVALID] },
  ],
  [NonComplianceErrors.EMAIL_INVALID]: [
    DamagedProductField.EMAIL,
    { type: "string", message: DamagedProductValidationErrorMessage[NonComplianceErrors.EMAIL_INVALID] },
  ],
  unknown: [DamagedProductField.BARCODE, { type: "string", message: DamagedProductValidationErrorMessage.UNKNOWN }],
};
