import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { useRouter } from "facility-commons/hooks";
import { NCRoutes } from "../../base/routes";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const useStorageCaseUploadCard = () => {
  const formHandler = useFormContext();
  const currentProduct = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();

  const proceed = () => push(NCRoutes.BCL_UPLOAD);
  const onBackFileChange = (fileName: string) => updateCurrentProduct("backPicture", fileName);
  const onFrontFileChange = (fileName: string) => updateCurrentProduct("frontPicture", fileName);

  return {
    backPictureRegister: formHandler.register("backPicture"),
    formatMessage,
    frontPictureRegister: formHandler.register("frontPicture"),
    allImagesUploaded: !!currentProduct.frontPicture && !!currentProduct.backPicture,
    onBackFileChange,
    onFrontFileChange,
    proceed,
  };
};
