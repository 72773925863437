import { S3FileHandlerClient } from "@deliverr/commons-clients";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { createDangerNotification, log, logStart } from "facility-commons/utils";
import { getUploadPercentage } from "facility-commons/utils/mathUtils";
import { getS3File } from "facility-commons/utils/getS3Files";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { UploadItemCustomProps } from "./UploadItem";

const UPLOAD_LIMIT = 20000000; // 20MB

enum UploadItemErrorMessage {
  UPLOAD_SIZE = "Image must be less than 20mb",
  UPLOAD_SERVICE_ERROR = "Error uploading image, please try again",
}

export function useUploadItem(
  {
    name,
    onSuccessfulUpload,
    onSuccessfulFileRetrieval,
  }: Pick<UploadItemCustomProps, "name" | "onSuccessfulUpload" | "onSuccessfulFileRetrieval">,
  fileHandlerClient: S3FileHandlerClient
) {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setIsUploading] = useState<boolean>(false);
  const [uploadedImageDictionary, setUploadedImageDictionary] = useState<Record<string, string>>({});
  const [downloadedImg, setDownloadedImg] = useState<string | null>(null);
  const { addAutoCloseNotification, errorResponse } = useCommonFlow();
  const { formatMessage } = useIntl();

  const {
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
  } = useFormContext();

  const value = watch(name!);

  const onUploadProgress = useCallback(
    (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = getUploadPercentage(progressEvent.loaded, progressEvent.total);
      setUploadProgress(percentCompleted);
    },
    [setUploadProgress]
  );

  const onInputFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      const ctx = logStart({
        fn: "onInputFileChange",
        files,
      });
      if (files?.length) {
        // we cannot destruct cause FileList is not instance of Array
        const fileToUpload = files[0];
        const field = name!;

        if (errors[field]) {
          clearErrors(field);
        }

        if (fileToUpload.size > UPLOAD_LIMIT) {
          setError(field, { type: "string", message: UploadItemErrorMessage.UPLOAD_SIZE });

          return;
        }

        setIsUploading(true);

        const file = await fileHandlerClient.uploadFile(fileToUpload, onUploadProgress).catch((e) => {
          log(ctx, "upload error", { e });
          setError(field, { type: "string", message: UploadItemErrorMessage.UPLOAD_SERVICE_ERROR });
          addAutoCloseNotification(createDangerNotification(UploadItemErrorMessage.UPLOAD_SERVICE_ERROR));
        });

        setIsUploading(false);
        setValue(name!, file);
        if (file) {
          onSuccessfulUpload?.(file);
        }
      }
    },
    [
      addAutoCloseNotification,
      clearErrors,
      errors,
      fileHandlerClient,
      name,
      onSuccessfulUpload,
      setError,
      setValue,
      onUploadProgress,
    ]
  );

  useEffect(() => {
    // UploadItem is a controlled input and its ref still having value when form is reset
    // see: https://react-hook-form.com/api/#reset
    if (isEmpty(value) && !isEmpty(getValues()[name!])) {
      setValue(name!, "");
    } else {
      if (name! in uploadedImageDictionary && value === uploadedImageDictionary[name!]) {
        setDownloadedImg(uploadedImageDictionary[name!]);
      } else {
        getS3File(value)
          .then((img) => {
            setDownloadedImg(img);
            setUploadedImageDictionary((imgs) => ({ ...imgs, [name!]: img }));
            onSuccessfulFileRetrieval?.(name!, img);
          })
          .catch(() => {
            errorResponse();
            addAutoCloseNotification(
              createDangerNotification(
                formatMessage({
                  id: "facilityCommons.uploadItem.error.gettingImg",
                  defaultMessage: "Error while getting your image. Please try again.",
                })
              )
            );
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setValue, name, getValues]);

  return {
    value: downloadedImg,
    uploadedImageDictionary,
    uploading,
    uploadProgress,
    onInputFileChange,
    getValues,
    setValue,
  };
}
