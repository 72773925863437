import React from "react";
import { Route } from "react-router-dom";

import { WarehousePortalRoutes } from "warehouse/routes";
import { NonComplianceTypeSelector } from "./non-compliance";
import { BarcodeNotInWMSForm } from "./forms/BarcodeNotInWMS";
import { NonComplianceCdskuScan } from "./non-compliance/NonComplianceCdskuScan/NonComplianceCdskuScan";
import { OtherNonComplianceForm } from "./forms/OtherNonCompliance";
import { NonCompliance } from "./non-compliance/NonCompliance";

import { StorageNonCompliance } from "./non-compliance/storage";

export const NonComplianceRoutes = [
  <Route
    exact
    key={WarehousePortalRoutes.NC_BARCODE_TICKET}
    path={WarehousePortalRoutes.NC_BARCODE_TICKET}
    render={() => <BarcodeNotInWMSForm />}
  />,
  <Route
    exact
    key={WarehousePortalRoutes.NC_OTHER_TICKET}
    path={WarehousePortalRoutes.NC_OTHER_TICKET}
    render={() => <OtherNonComplianceForm />}
  />,
  <Route
    exact
    key={WarehousePortalRoutes.NON_COMPLIANCE_TYPE}
    path={WarehousePortalRoutes.NON_COMPLIANCE_TYPE}
    render={() => <NonComplianceTypeSelector />}
  />,
  <Route
    exact
    key={WarehousePortalRoutes.NON_COMPLIANCE_SCAN}
    path={WarehousePortalRoutes.NON_COMPLIANCE_SCAN}
    render={() => <NonComplianceCdskuScan />}
  />,
  // storage NC Flows
  <Route
    key={WarehousePortalRoutes.STORAGE_NON_COMPLIANCE_FLOW}
    path={WarehousePortalRoutes.STORAGE_NON_COMPLIANCE_FLOW}
    render={() => <StorageNonCompliance />}
  />,
  // entry point to new NC scan flow
  <Route
    key={WarehousePortalRoutes.NON_COMPLIANCE}
    path={WarehousePortalRoutes.NON_COMPLIANCE}
    render={() => <NonCompliance />}
  />,
];
