import { defineMessages, MessageDescriptor } from "react-intl";

export enum AsnReceiveErrorMessageKey {
  ASN_NOT_FOUND = "ASN_NOT_FOUND",
  ASN_IS_CROSSDOCK = "ASN_IS_CROSSDOCK",
  ASN_IS_FOR_CONSOLIDATION = "ASN_IS_FOR_CONSOLIDATION",
  ASN_WAREHOUSE_MISMATCH = "ASN_WAREHOUSE_MISMATCH",
  FEFO_LOT_RESTRICTED = "FEFO_LOT_RESTRICTED",
}

export const ASN_RECEIVE_ERROR_BY_ERROR_CODE: Record<AsnReceiveErrorMessageKey, MessageDescriptor> = defineMessages({
  [AsnReceiveErrorMessageKey.ASN_NOT_FOUND]: {
    id: "warehouse.PO.asnNotFound",
    defaultMessage: "This PO # does not exist within our records. Please contact Deliverr.",
  },
  [AsnReceiveErrorMessageKey.ASN_IS_CROSSDOCK]: {
    id: "warehouse.PO.asnIsCD",
    defaultMessage: "This PO # cannot be received at this warehouse. Please contact Deliverr.",
  },
  [AsnReceiveErrorMessageKey.ASN_IS_FOR_CONSOLIDATION]: {
    id: "warehouse.PO.asnForConsolidation",
    defaultMessage: "Receive this box as part of a consolidation.",
  },
  [AsnReceiveErrorMessageKey.ASN_WAREHOUSE_MISMATCH]: {
    id: "warehouse.PO.warehouseMismatch",
    defaultMessage: "This PO # cannot be received at this warehouse. Please contact Deliverr..",
  },
  [AsnReceiveErrorMessageKey.FEFO_LOT_RESTRICTED]: {
    id: "warehouse.sku.fefoLotRestricted",
    defaultMessage:
      "Items with expiration dates or LOT numbers cannot be received without a CDSKU. Please return to the CDSKU page and scan a CDSKU, or contact Deliverr support.",
  },
});
