import React from "react";
import { MessageDescriptor } from "react-intl";
import { useUnmount } from "react-use";

import { TextInputCard } from "facility-commons/flow";
import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";
import { useCdskuScan } from "./useCdskuScan";
import { useIntlString } from "facility-commons/intl/useIntlString";
import { useResetRecoilState } from "recoil";
import { asnResponseState } from "./AsnResponseState";
import { WarehouseMessages } from "facility-commons/utils";

export interface CdskuScanProps {
  handleCdskuScanSuccess?: (asn: ReceivingAsn) => void;
  instructions: string | MessageDescriptor;
  errorMessage?: string;
  title?: string | JSX.Element;
  modalMessage: (asn: ReceivingAsn) => MessageDescriptor;
}

export const CdskuScan: React.FC<CdskuScanProps> = ({
  handleCdskuScanSuccess,
  instructions,
  title,
  errorMessage,
  modalMessage,
  children,
}) => {
  const intlString = useIntlString();
  const resetAsn = useResetRecoilState(asnResponseState);
  const { cdsku, cdskuError, handleChange, handleSubmit, loading } = useCdskuScan(
    modalMessage,
    errorMessage,
    handleCdskuScanSuccess
  );
  const CdskuScanTitle = intlString(WarehouseMessages.boxTitleText);

  useUnmount(() => {
    resetAsn();
  });

  return (
    <div>
      <TextInputCard
        errorMessage={cdskuError}
        helpText={intlString(WarehouseMessages.boxHelpText)}
        message={intlString(instructions)}
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={intlString({
          id: "warehouse.cdskuScan.placeholder",
          defaultMessage: "Enter CDSKU",
        })}
        loading={loading}
        title={title ?? CdskuScanTitle}
        value={cdsku}
      />
      {children}
    </div>
  );
};
