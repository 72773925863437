import { defineMessages } from "react-intl";

export const restrictedMessages = defineMessages({
  isRestrictedTitle: {
    id: "warehouse.nonCompliance.isRestricted.title",
    defaultMessage: "Is this a restricted product?",
  },
  restrictedInstructions: {
    id: "warehouse.nonCompliance.instructions",
    defaultMessage: "Restricted products depend on your facility, such as HAZMAT items.",
  },
  restrictedProduct: {
    id: "warehouse.nonCompliance.restrictedProduct",
    defaultMessage: "Restricted product",
  },
});
