import React from "react";
import { Appearance, DefaultTheme, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { commonMessages } from "facility-commons/labels";

const ReceivedSkusContainer = styled.div<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  margin-top:${theme.spacing.S1};
`
);

const StyledList = styled.ul<ThemeProps, DefaultTheme>(
  ({ theme }) => `
    list-style-type: disc;
    list-style-position: inside;
    color: ${theme.colors.NEUTRAL[500]};
    font-size: ${theme.font.size.F3};
  `
);
const StyledListItem = styled.span<ThemeProps, DefaultTheme>(
  ({ theme }) => `
      position: relative;
      left: -${theme.spacing.S2};
  `
);

export const ReceivedSkus = ({ previousReceives }) => {
  const { formatMessage } = useIntl();

  return (
    <ReceivedSkusContainer>
      <Text bold appearance={Appearance.DANGER}>
        {formatMessage(commonReceivingMessages.receivedSkus)}
      </Text>
      <StyledList>
        {previousReceives?.map(({ receivedQty, location }) => (
          <li key={`${receivedQty}-${location}`}>
            <StyledListItem>
              {!location ? (
                formatMessage(commonMessages.unit, {
                  unitsQty: receivedQty,
                })
              ) : (
                <FormattedPlural
                  one={
                    <FormattedMessage
                      id="warehouse.receiving.receivedSku"
                      defaultMessage="1 unit in {location}"
                      values={{ location }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id="warehouse.receiving.receivedSkus"
                      defaultMessage={"{receivedQty} units in {location}"}
                      values={{ receivedQty, location }}
                    />
                  }
                  value={receivedQty}
                />
              )}
            </StyledListItem>
          </li>
        ))}
      </StyledList>
    </ReceivedSkusContainer>
  );
};
