import { Text, Card, BoxReset } from "@deliverr/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TextInputCard } from "facility-commons/flow";
import { useGetProductZone } from "./useGetProductZone";
import { isNull } from "lodash/fp";

export const GetProductZone: React.FC = () => {
  const {
    loading,
    scannedValue,
    scannedValueError,
    handleInputChange,
    submitScannedValue,
    zoneName,
  } = useGetProductZone();

  return (
    <>
      <TextInputCard
        errorMessage={scannedValueError}
        loading={loading}
        message={
          <FormattedMessage
            id="warehouse.receiving.getProductZoneMessage"
            defaultMessage="Scan a SKU barcode or enter it here"
          />
        }
        newest
        onChange={handleInputChange}
        onSubmit={submitScannedValue}
        placeholder={{ id: "warehouse.barcode", defaultMessage: "Barcode" }}
        title={<FormattedMessage id="warehouse.receiving.skuCardTitle" defaultMessage="Scan SKU barcode" />}
        value={scannedValue}
        data-testid="get-product-zone-input"
      />
      {zoneName || isNull(zoneName) ? (
        <BoxReset marginTop="S4">
          <Card>
            {zoneName ? (
              <>
                <Text bold>
                  <FormattedMessage id="warehouse.receiving.zoneName" defaultMessage="Zone name" />
                </Text>
                {zoneName}
              </>
            ) : (
              <Text>
                <FormattedMessage id="warehouse.receiving.zoneNotFound" defaultMessage="No zone assigned/found" />
              </Text>
            )}
          </Card>
        </BoxReset>
      ) : null}
    </>
  );
};
