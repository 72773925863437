import { StorageClient, StorageInboundResponse } from "@deliverr/storage-client";

export class StorageInboundsClient extends StorageClient {
  public async setShipmentStatus(asnId: string, storageWarehouseId: string): Promise<StorageInboundResponse> {
    const url = `/storage/ops/inbounds/${asnId}/arrived`;
    const result = await this.axios
      .request({
        url,
        method: "PUT",
        headers: { "x-warehouse-id": storageWarehouseId },
      })
      .then((res) => res.data);
    return result;
  }

  public async getStoragePOWorklistLabel(asnId: string, storageWarehouseId: string): Promise<BlobPart> {
    const url = `/storage/ops/inbounds/print-worklist/${asnId}`;
    const result = await this.axios
      .request({
        url,
        method: "GET",
        responseType: "blob",
        headers: { "x-warehouse-id": storageWarehouseId },
      })
      .then((res) => res.data);
    return result;
  }

  public async getStorageLPNContentLabel(
    asnId: string,
    storageWarehouseId: string,
    lpn: string,
    lc: string
  ): Promise<BlobPart> {
    const url = `/storage/ops/inbounds/print-sscc/${asnId}`;
    const result = await this.axios
      .request({
        url,
        params: { lc, sscc: lpn },
        method: "GET",
        responseType: "blob",
        headers: { "x-warehouse-id": storageWarehouseId },
      })
      .then((res) => res.data);
    return result;
  }

  public async updatePalletInfo(
    receivingStartPalletCount: number,
    asnId: string,
    storageWarehouseId: string
  ): Promise<StorageInboundResponse> {
    const url = `/storage/ops/inbounds/${asnId}/rebuilt-pallet-info`;
    const result = await this.axios
      .request({
        url,
        method: "PUT",
        data: { receivingStartPalletCount },
        headers: { "x-warehouse-id": storageWarehouseId },
      })
      .then((res) => res.data);
    return result;
  }

  public async markPOCompletion(scanId: string, storageWarehouseId: string): Promise<StorageInboundResponse> {
    const url = `/storage/ops/inbounds/${scanId}/complete`;
    const result = await this.axios
      .request({
        url,
        method: "PUT",
        headers: { "x-warehouse-id": storageWarehouseId },
      })
      .then((res) => res.data);
    return result;
  }
}
