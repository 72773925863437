import { useRecoilValue } from "recoil";
import { currentSkuState, receiveFlowTypeState, receivingState } from "warehouse/receiving/ReceivingState";
import { isBoxReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";

export const useProductCardExpanded = () => {
  const { lotNumber, expirationDate, asnId, isReceiveWithoutCdsku } = useRecoilValue(receivingState);
  const { barcode, description, dsku, cdsku } = useRecoilValue(currentSkuState);
  const receivingFlowType = useRecoilValue(receiveFlowTypeState);
  const isBoxReceive = isBoxReceiveFlow(receivingFlowType);

  return {
    lotNumber,
    expirationDate,
    asnId,
    isReceiveWithoutCdsku,
    barcode,
    description,
    dsku,
    cdsku,
    isBoxReceive,
  };
};
