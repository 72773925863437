import { receivingState } from "./ReceivingState";
import { selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { receiveFlowTypeState } from "./ReceiveFlowTypeState";
import { isConsolidationFlow } from "../utils/receivingFlowType";

export const getIsMissingLotOrExpiryConsolidationReceive = ({ get }) => {
  const { isProductMissingLot, isProductMissingExpirationDate } = get(receivingState);
  const receivingFlow = get(receiveFlowTypeState);

  return isConsolidationFlow(receivingFlow) && (isProductMissingLot || isProductMissingExpirationDate);
};
export const isMissingLotOrExpiryConsolidationReceive = selector({
  key: WarehouseStateKey.IS_MISSING_LOT_OR_EXPIRY_CONSOLIDATION_RECEIVE,
  get: getIsMissingLotOrExpiryConsolidationReceive,
});
