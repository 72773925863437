import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Message, MessageList } from "react-chatbot-ui";
import { Balloon } from "./Balloon";

interface Props {
  messageList: MessageList;
}

const StyledChatContainer = styled.section`
  flex: 1;
  background-color: #f2f2f2;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: #aaaaaa;
  }
`;

export const ChatContainer = (props: Props) => {
  const messageList: MessageList = props.messageList;

  const lastBalloon = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lastBalloon.current?.scrollIntoView({ behavior: "auto" });
  }, [messageList]);

  const onLoadHandler = (setOnLoaded: Function) => {
    lastBalloon.current?.scrollIntoView({ behavior: "auto" });
    setOnLoaded(true);
  };

  return (
    <StyledChatContainer>
      {messageList.map((each: Message) => (
        <Balloon
          key={each.mid}
          type={each.type}
          text={each.text}
          button={each.button}
          ref={lastBalloon}
          onLoadHandler={onLoadHandler}
          url={each.image}
        />
      ))}
    </StyledChatContainer>
  );
};
