// Input field names, affects service call on backend
// implements NonComplianceCaseRequest interface fields
export enum BarcodeNotInWMSField {
  ASN = "asnId",
  BACK_PHOTO = "backPicture",
  BARCODE = "barcode",
  BARCODE_PHOTO = "barcodePicture",
  BOXLABEL_PHOTO = "boxContentPicture",
  CDSKU = "cdsku",
  EMAIL = "warehouseContactEmail",
  FRONT_PHOTO = "frontPicture",
  QUANTITY = "quantity",
}

export enum BarcodeNotInWMSValidationErrorMessages {
  ASN_REQUIRED = "PO # is required",
  ASN_FORMAT = "PO # has invalid length",
  ASN_INVALID = "PO # doesn't exist", // used to re-map error message from commons-clients
  UNKNOWN = "Unknown error submitting case, please try again later.",
  CDSKU_REQUIRED = "CDSKU is required",
  CDSKU_INVALID = "CDSKU is invalid",
  CDSKU_FORMAT = 'Please scan a valid CDSKU that starts with "CD"',
  BARCODE_REQUIRED = "Barcode is required",
  BARCODE_INVALID = "Barcode is invalid", // used to re-map error message from commons-clients
  BARCODE_FORMAT = "Invalid barcode it must be DSKU, or UPC/EAN or FNSKU",
  FRONT_PHOTO_REQUIRED = "Front photo is required",
  BACK_PHOTO_REQUIRED = "Back photo is required",
  QTY_REQUIRED = "Quantity is required",
  EMAIL_INVALID = "Please enter a valid email address",
}
