import React from "react";
import styled from "@emotion/styled";
import { Divider, Stack, ThemeProps } from "@deliverr/ui";

import { LoadingButton } from "facility-commons";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useNcReviewCard } from "../NcReviewCard/useNcReviewCard";
import {
  BarcodeSection,
  EmailSection,
  FefoSection,
  QuantitySection,
  RestrictedProductSection,
  AdditionalDetailsSection,
  BoxInformationSection,
  PhotosSection,
} from "../NcReviewCard/components";

const StyledDivider = styled(Divider)<ThemeProps>`
  border-color: ${({ theme }) => theme.colors.NEUTRAL[60]};
`;

export const StorageNcReviewCard: React.FC = () => {
  const {
    additionalDetails,
    barcode,
    boxInformation,
    email,
    reportedLot,
    reportedExpiry,
    formatMessage,
    isRestricted,
    numberOfBoxes,
    photos,
    quantityPerBox,
    submitTicket,
    loading,
    isLotEnabled,
    isFefoEnabled,
  } = useNcReviewCard();

  return (
    <Stack>
      <FacilityCard>
        <Stack>
          {barcode && <BarcodeSection barcode={barcode} />}

          {isLotEnabled && isFefoEnabled && (
            <FefoSection isLotEnabled isFefoEnabled reportedLot={reportedLot} reportedExpiry={reportedExpiry} />
          )}

          <BoxInformationSection boxInformation={boxInformation} />
          <StyledDivider />

          {photos.length > 0 && (
            <>
              <PhotosSection photos={photos} />
              <StyledDivider />
            </>
          )}

          {quantityPerBox && numberOfBoxes > 1 && (
            <>
              <QuantitySection quantityPerBox={quantityPerBox} numberOfBoxes={numberOfBoxes} />
              <StyledDivider />
            </>
          )}

          {additionalDetails && (
            <>
              <AdditionalDetailsSection additionalDetails={additionalDetails} />
              <StyledDivider />
            </>
          )}

          {isRestricted && (
            <>
              <RestrictedProductSection isRestricted={isRestricted} />
              <StyledDivider />
            </>
          )}

          <EmailSection email={email} />
        </Stack>
      </FacilityCard>

      <LoadingButton block data-testid="submitNcCase-btn" onClick={submitTicket} disabled={loading}>
        {formatMessage(commonWarehouseMessages.submitTicket)}
      </LoadingButton>
    </Stack>
  );
};
