import { useForm, FieldValues } from "react-hook-form";
import { AsnType, NonComplianceCaseRequest } from "@deliverr/commons-clients/lib/non-compliance";
import { useAsyncFn } from "react-use";
import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { minASNLength, hasLowerCase } from "facility-commons/utils/config";
import { validationConfig, nonComplianceServiceValidationMap } from "./BarcodeNotInWMSFormConfig";
import { BarcodeNotInWMSField } from "./BarcodeNotInWMSForm.types";
import { WarehousePortalRoutesNames } from "warehouse/routes";
import { useRouter } from "facility-commons/hooks";
import { warehouseEmailState } from "facility-commons/base/warehouseEmailState";
import { nonComplianceCdskuState } from "../../non-compliance/nonComplianceCdskuState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { userState } from "facility-commons/base/Auth/userState";

export function parseServiceErrors(error: Partial<Error> & Partial<{ subcode: string }>) {
  if (error.subcode && nonComplianceServiceValidationMap[error.subcode]) {
    return nonComplianceServiceValidationMap[error.subcode];
  }

  return nonComplianceServiceValidationMap.unknown;
}

export function useBarcodeNotInWMSForm() {
  const { nonComplianceClient } = useClientsWithAuth();
  const { match, history } = useRouter();
  const { warehouseId } = useRecoilValue(userState);
  const preScannedCdsku = useRecoilValue(nonComplianceCdskuState);
  const resetPreScannedCdsku = useResetRecoilState(nonComplianceCdskuState);
  const formHandler = useForm({
    mode: "onBlur",
  });
  const watchASN: string = formHandler.watch(BarcodeNotInWMSField.ASN);
  const watchBarcode: string = formHandler.watch(BarcodeNotInWMSField.BARCODE);
  const watchCDSKU: string = formHandler.watch(BarcodeNotInWMSField.CDSKU);

  const [showCDSKU, setShowCDSKU] = useState<boolean>(false);

  const [asnTypeState, getASNType] = useAsyncFn(
    async (asn: string): Promise<AsnType> => {
      // getASNType typings accepts number and uses it in a URL.
      // makes sense to keep it a string intead of converting it to a number to avoid NAN.
      const { type } = await nonComplianceClient.getASNType(asn as any);

      return type;
    }
  );

  const [submitState, submitData] = useAsyncFn((data: NonComplianceCaseRequest) => {
    return nonComplianceClient.createNonComplianceCasev2(data);
  });

  useEffect(() => {
    const hasASNLength = watchASN?.length >= minASNLength;

    if (hasASNLength) {
      getASNType(watchASN);
    } else if (showCDSKU) {
      setShowCDSKU(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchASN]);

  useEffect(() => {
    if (watchBarcode && hasLowerCase.test(watchBarcode)) {
      formHandler.setValue(BarcodeNotInWMSField.BARCODE, watchBarcode.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBarcode]);

  useEffect(() => {
    if (preScannedCdsku) {
      formHandler.setValue(BarcodeNotInWMSField.CDSKU, preScannedCdsku.toUpperCase());
    } else if (watchCDSKU && hasLowerCase.test(watchCDSKU)) {
      formHandler.setValue(BarcodeNotInWMSField.CDSKU, watchCDSKU.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCDSKU, preScannedCdsku]);

  useEffect(() => {
    if (!asnTypeState.loading && (asnTypeState?.value === AsnType.TRANSFER) !== showCDSKU) {
      setShowCDSKU(!showCDSKU);
    }

    if (asnTypeState.error) {
      formHandler.setError(...parseServiceErrors(asnTypeState.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asnTypeState]);

  useEffect(() => {
    if (!submitState.loading && submitState?.value?.caseId) {
      history.push(`${match.url}/${WarehousePortalRoutesNames.SUCCESS}/${submitState.value.caseId}`);
    }

    if (submitState.error) {
      formHandler.setError(...parseServiceErrors(submitState.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitState, history, match.url, formHandler.setError]);

  const onFormValues = async (values: FieldValues) => {
    const requestData = {
      warehouseId,
      ...values,
    } as NonComplianceCaseRequest;

    submitData(requestData);
    resetPreScannedCdsku();
  };

  const onFormSubmit = formHandler.handleSubmit(onFormValues);

  const warehouseEmail = useRecoilValue(warehouseEmailState).warehouseEmail!;

  return {
    preScannedCdsku,
    asnTypeState,
    errors: formHandler.formState.errors,
    formHandler,
    preventSubmit: submitState.loading,
    onFormSubmit,
    showCDSKU,
    warehouseEmail,
    register: {
      // we don't loop/reduce to keep type checks safer
      [BarcodeNotInWMSField.ASN]: formHandler.register(
        BarcodeNotInWMSField.ASN,
        validationConfig[BarcodeNotInWMSField.ASN]
      ),
      [BarcodeNotInWMSField.BARCODE]: formHandler.register(
        BarcodeNotInWMSField.BARCODE,
        validationConfig[BarcodeNotInWMSField.BARCODE]
      ),
      [BarcodeNotInWMSField.CDSKU]: formHandler.register(
        BarcodeNotInWMSField.CDSKU,
        showCDSKU ? validationConfig[BarcodeNotInWMSField.CDSKU] : {}
      ),
      [BarcodeNotInWMSField.FRONT_PHOTO]: formHandler.register(
        BarcodeNotInWMSField.FRONT_PHOTO,
        validationConfig[BarcodeNotInWMSField.FRONT_PHOTO]
      ),
      [BarcodeNotInWMSField.BARCODE_PHOTO]: formHandler.register(
        BarcodeNotInWMSField.BARCODE_PHOTO,
        validationConfig[BarcodeNotInWMSField.BARCODE_PHOTO]
      ),
      [BarcodeNotInWMSField.BACK_PHOTO]: formHandler.register(
        BarcodeNotInWMSField.BACK_PHOTO,
        validationConfig[BarcodeNotInWMSField.BACK_PHOTO]
      ),
      [BarcodeNotInWMSField.BOXLABEL_PHOTO]: formHandler.register(
        BarcodeNotInWMSField.BOXLABEL_PHOTO,
        validationConfig[BarcodeNotInWMSField.BOXLABEL_PHOTO]
      ),
      [BarcodeNotInWMSField.QUANTITY]: formHandler.register(
        BarcodeNotInWMSField.QUANTITY,
        validationConfig[BarcodeNotInWMSField.QUANTITY]
      ),
      [BarcodeNotInWMSField.EMAIL]: formHandler.register(
        BarcodeNotInWMSField.EMAIL,
        validationConfig[BarcodeNotInWMSField.EMAIL]
      ),
    },
  };
}
