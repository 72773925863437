import { useMemo } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router";

export function useRouter<T extends Record<string, string>>() {
  const match = useRouteMatch<T>();
  const history = useHistory();
  const locationResult = useLocation();

  return useMemo(() => {
    return {
      push: history.push,
      pathname: window.location.pathname,
      match,
      history,
      goBack: history.goBack,
      locationPathname: locationResult.pathname,
    };
  }, [match, history, locationResult.pathname]);
}
