import React from "react";
import styled from "@emotion/styled";

import { MenuLink, MenuLinkConfig } from "./MenuLink";
import { DefaultTheme } from "@deliverr/ui";

export const MenuContainer = styled.ul<React.HTMLAttributes<HTMLUListElement>, DefaultTheme>(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  border: ${theme.border.width.B1} solid;
  border-color: ${theme.colors.NEUTRAL["80"]};
  border-radius: ${theme.border.radius.R5};

  & li:first-of-type > * {
    border-top-left-radius: ${theme.border.radius.R5};
    border-top-right-radius: ${theme.border.radius.R5};
  }

  & li:last-of-type > * {
    border-bottom-left-radius: ${theme.border.radius.R5};
    border-bottom-right-radius: ${theme.border.radius.R5};
  }
`
);

export interface LinksMenuProps<LinkNames extends string> {
  links: MenuLinkConfig<LinkNames>[];
}

export function LinksMenu<LinkNames extends string>({ links }: LinksMenuProps<LinkNames>) {
  return (
    <MenuContainer>
      {links.map((link, i) => (
        <MenuLink key={`link-menu-${i}`} link={link}></MenuLink>
      ))}
    </MenuContainer>
  );
}
