import { Button, Box, IconV2, ThemeProps } from "@deliverr/ui";
import * as React from "react";
import styled from "@emotion/styled";
import { InspectionNotesType } from "../../types/InspectionNotesType";
import { useInspectionNotesButton } from "./useInspectionNotesButton";

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  border-color: ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R5};
  font-size: ${theme.font.size.F2};
  color: ${theme.colors.NEUTRAL[500]};
  `
);

const StyledDiv = styled.div(`
  display: flex;
  justify-content: space-between;
  `);

interface InspectionNotesButtonProps {
  noteType: InspectionNotesType;
}

export const InspectionNotesButton: React.FC<InspectionNotesButtonProps> = ({ noteType }) => {
  const { buttonText, showNotesModal } = useInspectionNotesButton(noteType);

  return (
    <Box marginTop="S2" marginBottom="S2">
      <StyledButton block secondary data-testid={"return-receive-inspection-notes-button"} onClick={showNotesModal}>
        <StyledDiv>
          {buttonText}
          <IconV2 type="angle-right" />
        </StyledDiv>
      </StyledButton>
    </Box>
  );
};
