import { useState } from "react";
import { useAsyncFn, useMount } from "react-use";
import { useIntl } from "react-intl";
import { NonComplianceResolutionMethod } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { useResetRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";

import { NCRoutes } from "../../base/routes";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { trackingMessages } from "../../content/tracking";
import { handleCdskuValidation } from "warehouse/common/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { WarehousePortalRoutes } from "warehouse/routes";
import { userState } from "facility-commons/base/Auth/userState";
import { MisRoutedBoxProps } from "warehouse/ticket-center/new/non-compliance/modals/MisroutedBox";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import {
  currentProductState,
  currentProductLotFefoDetailsState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { receivingMisMatchState } from "warehouse/receiving/ReceivingState";

export const useNcCdskuCard = () => {
  const [cdskuError, setCdskuError] = useState<string>("");

  const { push } = useRouter();
  const { cdsku = "", incidentId } = useRecoilValue(currentProductState);
  const { showModal, hideModal } = useWarehouseModal();
  const { errorResponse, successResponse } = useCommonFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();
  const { nonComplianceClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const resetCurrentProduct = useResetRecoilState(currentProductState);
  const { handleNonComplianceError, initializeNcProduct, updateCurrentProduct } = useNonComplianceFlow();
  const resetReceivingMisMatchState = useResetRecoilState(receivingMisMatchState);
  const resetLotFefoDetailsState = useResetRecoilState(currentProductLotFefoDetailsState);

  useMount(() => {
    setWarehouseAppState(setProp("pageSubtitle", ""));
    // Reset lot/fefo NC related data
    resetReceivingMisMatchState();
    resetLotFefoDetailsState();
    initializeNcProduct();
  });

  const onValidationFail = () => errorResponse(() => updateCurrentProduct("cdsku", ""));

  const updateCdsku = (newCdsku: string) => {
    setCdskuError("");
    updateCurrentProduct("cdsku", newCdsku);
  };

  const routeToTrackingStep = () => {
    // for when a box content label is not scannable or present
    setWarehouseAppState(setProp("pageSubtitle", formatMessage(trackingMessages.noCdskuSubtitle)));
    push(NCRoutes.TRACKING);
  };

  const hideModalAndRoute = (route: string) => {
    hideModal(WarehouseModal.NC_MISROUTED_CDSKU);
    push(route);
  };

  /*
    a misroute is auto resolved on BE so we need to generate a new incidentId to avoid
    any "incident already resolved" errors from appearing. then we need to resubmit box scan and barcode
    scans with the new uuid (incidentId) if the user wants to proceed with ticket creation even after reroute
  */
  const resubmitReroutedCdsku = async (scannedCdsku: string) => {
    const ctx = logStart({ fn: "useNcCdskuCard.resubmitReroutedCdsku", scannedCdsku, warehouseId });
    try {
      const newIncidentId = uuid();
      updateCurrentProduct("incidentId", newIncidentId);

      log({ ...ctx, newIncidentId }, "Resubmitting box scan");
      const res = await nonComplianceClient.nonComplianceIncidentScanPackage(newIncidentId, scannedCdsku, warehouseId);
      log({ ...ctx, newIncidentId, res }, "Resubmit box scan complete");

      setWarehouseAppState(setProp("pageSubtitle", scannedCdsku));
      hideModalAndRoute(NCRoutes.BARCODE);
    } catch (error) {
      hideModal(WarehouseModal.NC_MISROUTED_CDSKU);
      handleNonComplianceError(ctx, error);
    }
  };

  const [{ loading }, handleSubmit] = useAsyncFn(
    async (newCdsku: string) => {
      const ctx = logStart({ fn: "useNcCdskuCard.handleSubmit", newCdsku });

      // block submission and show an error if invalid cdsku
      if (handleCdskuValidation(newCdsku, formatMessage, setCdskuError, onValidationFail)) {
        return;
      }

      try {
        const response = await nonComplianceClient.nonComplianceIncidentScanPackage(incidentId, newCdsku, warehouseId);
        log({ ...ctx, response }, "CDSKU scan response");

        // show the user a modal that allows them to proceed with ticket creation or use the provided ASN to receive a misroute
        if (response.nonComplianceResolutionMethod === NonComplianceResolutionMethod.REROUTE_CDSKU) {
          errorResponse(); // sfx + flash

          const modalProps: MisRoutedBoxProps = {
            onContinue: () => resubmitReroutedCdsku(newCdsku),
            onResolution: () => {
              /*
                clear all existing values (incidentId, and cdsku) before redirecting back to create ticket page
              */
              resetCurrentProduct();
              hideModalAndRoute(WarehousePortalRoutes.NEW_TICKET);
            },
            generatedAsn: response.resolutionDetails!.asnId,
          };

          showModal(WarehouseModal.NC_MISROUTED_CDSKU, modalProps);
        } else {
          successResponse();
          setWarehouseAppState(setProp("pageSubtitle", newCdsku));
          push(NCRoutes.BARCODE);
        }
      } catch (err) {
        handleNonComplianceError(ctx, err);
      }
    },
    [incidentId, warehouseId]
  );

  const handleChange = genericOnScannerInputChange(cdsku, updateCdsku, handleSubmit, "upper");

  return { cdsku, cdskuError, formatMessage, handleChange, handleSubmit, loading, routeToTrackingStep };
};
