import { useState } from "react";
import { useMount } from "react-use";
import { commonPalletMessages } from "warehouse/receiving/content";
import { useIntl } from "react-intl";
import { useRecoilState, useSetRecoilState } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";
import { genericOnScannerInputChange, isValidCdsku, setProp } from "facility-commons/utils";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { COMMON_LABELS } from "../warehouse.labels";

export const useLotCard = () => {
  const [lotError, setLotError] = useState("");

  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const { formatMessage } = useIntl();
  const { errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);

  const { lotNumber } = receivingData;

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonPalletMessages.receivePalletContent)));
  });

  const submitLotNumber = async (value: string) => {
    if (!value.length) {
      errorResponse();
      return setLotError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
    } else if (isValidCdsku(value)) {
      errorResponse();
      updateLotNumber("");
      return setLotError(
        formatMessage(
          {
            id: "warehouse.receiving.inputIsCdskuNotLot",
            defaultMessage: "{value} is a CDSKU. Scan the product's lot number.",
          },
          { value }
        )
      );
    }
    successResponse();
    push(ReceivingPath.SKU);
  };

  const updateLotNumber = (value: string) => setReceivingData(setProp("lotNumber", value));

  const handleChange = genericOnScannerInputChange(lotNumber || "", updateLotNumber, submitLotNumber, "upper");

  return {
    lotError,
    formatMessage,
    lotNumber: lotNumber || "",
    handleChange,
    submitLotNumber,
  };
};
