import React from "react";
import { FormattedMessage } from "react-intl";
import { Text } from "@deliverr/ui";
import { CardColumn } from "facility-commons/components/emotion/cards";
import { commonMessages } from "facility-commons/labels";

interface BarcodeSectionProps {
  barcode: string;
}

export const BarcodeSection: React.FC<BarcodeSectionProps> = ({ barcode }) => (
  <CardColumn>
    <Text bold>
      <FormattedMessage {...commonMessages.barcode} />
    </Text>
    {barcode}
  </CardColumn>
);
