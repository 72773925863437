import { defineMessages } from "react-intl";

export const outboundModalLabels = defineMessages({
  continue: {
    id: "warehouse.rs-outbounds.outboundModalLabels.continue",
    defaultMessage: "Continue",
  },
  back: {
    id: "warehouse.rs-outbounds.outboundModalLabels.back",
    defaultMessage: "Back",
  },
  itemPendingTitle: {
    id: "warehouse.rs-outbounds.outboundModalLabels.itemPending",
    defaultMessage: "{totalPendingItems} items pending",
  },
  itemPendingDescription: {
    id: "warehouse.rs-outbounds.outboundModalLabels.itemPendingDescription",
    defaultMessage: "There are {totalPendingItems} items yet to be packed for this order. Do you want to continue?",
  },
  discardPackageTitle: {
    id: "warehouse.rs-outbounds.outboundModalLabels.discardPackage",
    defaultMessage: "Discard Package",
  },
  discardPackageDescription: {
    id: "warehouse.rs-outbounds.outboundModalLabels.discardPackageDescription",
    defaultMessage: "Are you sure you want to discard current package? All units will be removed from the package.",
  },
});
