import { LotFefoDetails } from "./types";
import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

export const LOT_FEFO_DETAILS_INITIAL_STATE: LotFefoDetails = {
  isLotEnabled: false,
  isFefoEnabled: false,
};

export const currentProductLotFefoDetailsState = atom<LotFefoDetails>({
  key: WarehouseStateKey.NC_PRODUCT_LOT_FEFO_DETAILS,
  default: LOT_FEFO_DETAILS_INITIAL_STATE,
});
