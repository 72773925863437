import { useSetRecoilState } from "recoil";

import { log } from "facility-commons/utils/logger";
import { warehouseEmailState } from "./warehouseEmailState";
import { getWarehouseEmail } from "facility-commons/utils/warehouseEmailUtils";
import { WarehousePublicClient } from "@deliverr/warehouse-client";
import { useAsync } from "react-use";

export const composeUseWarehouseEmailState = (warehousePublicClient: WarehousePublicClient) => (
  warehouseId: string
) => {
  const setWarehouseIdState = useSetRecoilState(warehouseEmailState);
  const warehouseEmailAsyncState = useAsync(async () => {
    const warehouseEmail = await getWarehouseEmail(warehousePublicClient, warehouseId!);
    log({ fn: "useWarehouseEmailState", warehouseEmail }, "Retrieved warehouse email");
    setWarehouseIdState({
      warehouseEmail,
    });

    return warehouseEmail;
  });

  return warehouseEmailAsyncState;
};
