import {
  NonComplianceClient,
  TicketCenterListViewResponse,
  TicketCenterNonComplianceCaseStatus,
} from "@deliverr/commons-clients";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { userState } from "facility-commons/base/Auth/userState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { logError, setProp } from "facility-commons/utils";
import { useState } from "react";
import { useAsync } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dateOrUndefined } from "warehouse/common/utils";
import { ListViewCardProps } from "../ListViewCard";
import { getMultipleS3Files } from "facility-commons";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";

const ticketTypeMap: Record<
  TicketCenterNonComplianceCaseStatus,
  (warehouse: string, nonComplianceClient: NonComplianceClient) => Promise<TicketCenterListViewResponse[]>
> = {
  [TicketCenterNonComplianceCaseStatus.CLOSED]: async (warehouse: string, nonComplianceClient: NonComplianceClient) =>
    nonComplianceClient.getTicketCenterCasesByWarehouseIdClosedStatus(warehouse),
  [TicketCenterNonComplianceCaseStatus.CREATED]: async (warehouse: string, nonComplianceClient: NonComplianceClient) =>
    nonComplianceClient.getTicketCenterCasesByWarehouseIdCreatedStatus(warehouse),
  [TicketCenterNonComplianceCaseStatus.TODO]: async (warehouse: string, nonComplianceClient: NonComplianceClient) =>
    nonComplianceClient.getTicketCenterCasesByWarehouseIdTodoStatus(warehouse),
};

const transformTicketResponseToListViewCard = (ticket: TicketCenterListViewResponse): ListViewCardProps | undefined => {
  switch (ticket.type) {
    case NonComplianceType.MISSING_BARCODE:
    case NonComplianceType.UNKNOWN_BARCODE:
    case NonComplianceType.INVALID_LOT_EXPIRY:
    case NonComplianceType.OTHER:
      return {
        asnId: ticket.asnId!,
        completedDate: dateOrUndefined(ticket.completedAt),
        createdDate: new Date(ticket.createdAt),
        dueDate: dateOrUndefined(ticket.dueAt),
        id: ticket.id,
        displayId: ticket.displayId,
        status: ticket.status,
        type: ticket.type,
        thumbnail: ticket.thumbnail,
      };
    case NonComplianceType.UNEXPECTED_SKU:
    default:
      logError(
        { fn: "transformTicketResponseToListViewCard", ticketId: ticket.id },
        new Error(`Unhandled ticket type: ${ticket.type}`)
      );
      return undefined;
  }
};

const isPopulatedTicket = (ticket: ListViewCardProps | undefined): ticket is ListViewCardProps => Boolean(ticket);

export const useTicketList = (currentTicketStatus: TicketCenterNonComplianceCaseStatus) => {
  const { nonComplianceClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const [error, setError] = useState(null);
  const setTicketCenterState = useSetRecoilState(ticketCenterState);
  const { loading } = useAsync(async () => {
    try {
      const res = await ticketTypeMap[currentTicketStatus](warehouseId, nonComplianceClient);

      // for updating of To Do Item badge in app bar
      if (currentTicketStatus === TicketCenterNonComplianceCaseStatus.TODO) {
        setTicketCenterState(setProp("todoCount", res.length));
      }
      const imagesToDownload = Object.fromEntries(
        res.map((ticket) => [ticket.id, { thumbnail: ticket.thumbnail ?? "" }])
      );
      const imagesUrls = await getMultipleS3Files(imagesToDownload);
      const ticketsList = res.map((ticket) =>
        transformTicketResponseToListViewCard({ ...ticket, thumbnail: imagesUrls[ticket.id].thumbnail })
      );
      const ticketList = ticketsList.filter(isPopulatedTicket);
      setTicketCenterState(setProp("ticketList", ticketList));
    } catch (err: any) {
      setError(err);
    }
  }, [currentTicketStatus, warehouseId]);

  return {
    isError: error,
    isLoading: loading,
  };
};
