import React from "react";
import { ModalTemplate, questionModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "warehouse/receiving/components/modals/modalsText";
import styled from "@emotion/styled";
import { useBoxTypeIdentificationModal } from "./useBoxTypeIdentificationModal";

interface BoxTypeIdentificationModal {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  title: string;
  message?: string;
}

const ButtonContainer = styled(Grid)`
  width: 100%;
`;

export const BoxTypeIdentificationModal: React.FC<BoxTypeIdentificationModal> = ({
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const { buttonLabels, loading, handleSubmit } = useBoxTypeIdentificationModal({ onConfirm });

  return (
    <ModalTemplate buttonLayout="column" title={title} text={message} iconProps={questionModalIconLg}>
      <ButtonContainer gap="S3">
        <Button block data-testid="box-type-confirm-button" loading={loading} onClick={handleSubmit}>
          {buttonLabels.Confirm}
        </Button>
        <Button
          block
          secondary
          type="button"
          data-testid="box-type-cancel-button"
          disabled={loading}
          onClick={onCancel}
        >
          {buttonLabels.Cancel}
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
