import { BoxReset, Button, Card, Grid, IconV2, RadioTileGroup, Stack, ThemeProps, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ModalOverlay } from "facility-commons";
import { isMobile } from "facility-commons/utils/isMobile";
import React from "react";
import { modalsText } from "../modalsText";
import { useFilterModal } from "./useFilterModal";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    height: 100%;
    min-width: ${isMobile ? "100%" : "60%"};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    border-radius: ${theme.border.radius.R5};
    box-shadow: ${theme.shadow.S3};
    padding: ${theme.spacing.S5};
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0;

    label {
      text-transform: capitalize;
      border-color: ${theme.colors.NEUTRAL["00"]};
    }
  `
);
const TitleContainer = styled(Stack)`
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ExitIconButton = styled.button<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE[300]};
`
);
const ButtonContainer = styled.span`
  height: 100%;

  div {
    height: 100%;
  }
`;
export const FilterModal: React.FC = () => {
  const { options, filter, formatMessage, ticketsCount, handleFilterChange, closeMenu, onSubmit } = useFilterModal();

  return (
    <ModalOverlay>
      <StyledCard as="section" size="MD">
        <TitleContainer direction="HORIZONTAL" gap="S4">
          <ExitIconButton onClick={closeMenu}>
            <IconV2 type="close" />
          </ExitIconButton>
          <Title as="h4" displayAs="h4">
            {formatMessage(modalsText.filterMenuTitle)}
          </Title>
        </TitleContainer>
        <BoxReset marginTop="S5">
          <Title as="h5" displayAs="h5">
            {formatMessage(modalsText.filterMenuHeader)}
          </Title>
        </BoxReset>
        <BoxReset marginTop="S2">
          <RadioTileGroup
            name="noncompliance.types.radioGroup"
            currentValue={filter}
            block
            onChange={handleFilterChange}
            direction="VERTICAL"
            options={options}
          />
        </BoxReset>
        <ButtonContainer>
          <Grid columns="1fr 1fr" gap="S2" alignItems="end">
            <Button onClick={closeMenu} secondary data-testid="cancel-btn-filter-tickets-menu">
              {formatMessage(modalsText.cancel)}
            </Button>
            <Button onClick={onSubmit} data-testid="confirm-btn-filter-tickets-menu">
              {formatMessage(modalsText.showTicketBtn, { ticketsCount })}
            </Button>
          </Grid>
        </ButtonContainer>
      </StyledCard>
    </ModalOverlay>
  );
};
