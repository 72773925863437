import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "facility-commons/flow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useReceivingSKULPScanFlow } from "./useReceivingSKULPScanFlow";

export const StorageReceivingSKULP: React.FC = () => {
  const { formatMessage } = useIntl();
  const { licensePlate, scanError, setLicensePlate, handleSubmit, loading } = useReceivingSKULPScanFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <TextInputCard
        errorMessage={scanError}
        newest
        onChange={setLicensePlate}
        onSubmit={handleSubmit}
        placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPPlaceholder)}
        loading={loading}
        title={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPTitle)}
        message={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPDescription)}
        value={licensePlate ?? ""}
      />
    </>
  );
};
