import React from "react";

import { Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import { CenteredWarehouseCard } from "warehouse/common/components";
import { MenuSection, PaddedLinkButton, StyledButtonContainer } from "facility-commons/components/emotion";
import { WarehousePortalRoutesNames } from "warehouse/routes";

const StyledTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
`
);

export const NonComplianceTypeSelector: React.FC = () => {
  return (
    <CenteredWarehouseCard>
      <MenuSection>
        <StyledTitle as="h3" displayAs="h3">
          <FormattedMessage
            id="warehouse.nonComplianceTypeSelector.title"
            defaultMessage="What type of non-compliance?"
          />
        </StyledTitle>
        <StyledButtonContainer>
          <PaddedLinkButton to={`${WarehousePortalRoutesNames.BARCODE}`}>
            <FormattedMessage
              id="warehouse.nonComplianceTypeSelector.barcodeNotInWmsButton"
              defaultMessage="Barcode not in WMS"
            />
          </PaddedLinkButton>
          <PaddedLinkButton to={`${WarehousePortalRoutesNames.OTHER}`}>
            <FormattedMessage id="other" defaultMessage="Other" />
          </PaddedLinkButton>
        </StyledButtonContainer>
      </MenuSection>
    </CenteredWarehouseCard>
  );
};
