import React from "react";
import { Stack } from "@deliverr/ui";
import styled from "@emotion/styled";
import { receivingButtonsMap } from "warehouse/receiving/maps";
import { useRecoilValue } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";

const Container = styled(Stack)`
  width: 100%;
  margin: 0;
`;

export const ReceivingButtonsContainer = () => {
  const { receivingButtons } = useRecoilValue(receivingState);

  return (
    <Container center>
      {receivingButtons.map((buttonType, i) => {
        const ReceivingButton = receivingButtonsMap[buttonType];
        return <ReceivingButton key={`receiving-button-${buttonType}-${i}`} />;
      })}
    </Container>
  );
};
