import { produce } from "immer";
import { createPackageCardLabels } from "./createPackageCardLabels";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { genericOnScannerInputChange, log, logStart } from "facility-commons/utils";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { RSOutboundsFlowButtonType } from "warehouse/common/flow/types/RSOutboundsFlowButtonType";
import { Product } from "@deliverr/commons-clients";
import { sum } from "lodash";
import { SoundFx } from "facility-commons/common/sfx";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";

export const useCreatePackageCard = () => {
  const [skuScanError, setSkuScanError] = useState("");
  const [skuBarcode, setSkuBarcode] = useState("");
  const [scanState, setScanState] = useRecoilState(rsOutboundsStateAtom);
  const { emitFlash, playSfx, errorResponse } = useCommonFlow();
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const { hideAllFlowButtons, showFlowButtons } = useWarehouseFlow();
  const itemsAdded = sum(Object.values(scanState.currentPackageDskuToQtyMap ?? {}));
  const { shipmentItemsDetails, unifiedProductDetails, mapParentDskuToCasePackDsku } = scanState;
  const { pendingItemsMap } = useRecoilValue(shipmentItemsSelector);

  useLifecycles(
    () => {
      hideAllFlowButtons();
      const timeoutId = setTimeout(() => {
        resetNotifications();
        clearTimeout(timeoutId);
      }, 2000);
      showFlowButtons(
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_CLOSE_PACKAGE,
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_DISCARD_PACKAGE
      );
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [submitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      const upperCaseValue = value.toUpperCase();
      const ctx = logStart({ fn: "useCreatePackageCard.handleSubmit", value, skuScanError });

      const onError = () => {
        log({ ...ctx }, "error when adding SKU to package");

        setSkuScanError(formatMessage(createPackageCardLabels.skuNotInShipment));
        errorResponse();
      };

      const onSuccess = (product: Product) => {
        if (pendingItemsMap[product.dsku]?.qty > 0) {
          log({ ...ctx, ...product }, "successfully added SKU to package");
          const newState = produce(scanState, (draft) => {
            draft.currentPackageDskuToQtyMap[product.dsku] = (draft.currentPackageDskuToQtyMap[product.dsku] ?? 0) + 1;
          });
          setScanState(newState);
          emitFlash("SUCCESS");
          playSfx(SoundFx.SUCCESS);
          setSkuBarcode("");
        } else {
          setSkuScanError(formatMessage(createPackageCardLabels.allUnitsPacked));
          errorResponse();
        }
      };

      setSkuScanError("");

      if (!upperCaseValue?.length) {
        setSkuScanError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
        errorResponse();
        return;
      }

      try {
        let foundDSKU = false;
        if (shipmentItemsDetails?.[upperCaseValue]) {
          onSuccess(shipmentItemsDetails?.[upperCaseValue]);
        } else {
          for (const dsku in unifiedProductDetails) {
            if (unifiedProductDetails[dsku]?.barcodes?.includes(upperCaseValue)) {
              foundDSKU = true;
              const key = unifiedProductDetails[dsku].packOf ? dsku : mapParentDskuToCasePackDsku?.[dsku] ?? dsku;
              onSuccess(unifiedProductDetails[key]);
              break;
            }
          }
          if (!foundDSKU) {
            onError();
          }
        }
      } catch (error) {
        log({ ...ctx, error }, "error when scanning item barcode");
        onError();
      }
    },
    [scanState, setScanState, formatMessage, errorResponse, setSkuScanError]
  );

  const packageWeight = sum(
    Object.keys(scanState.currentPackageDskuToQtyMap).map((dsku) => {
      const product = unifiedProductDetails?.[dsku];
      const quantity = scanState.currentPackageDskuToQtyMap[dsku] ?? 0;
      return (product?.weight ?? 0) * quantity;
    })
  ).toFixed(2);
  const updateSku = (upperCaseValue: string) => setSkuBarcode(upperCaseValue);

  const handleChange = genericOnScannerInputChange(skuBarcode, updateSku, handleSubmit, "upper");

  return {
    skuBarcode,
    skuScanError,
    handleChange,
    handleSubmit,
    loading: submitState.loading,
    itemsAdded,
    packageWeight,
  };
};
