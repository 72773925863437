import { NotificationProps, NotificationAppearance } from "@deliverr/ui";

export function createNotificationCreator(appearance: NotificationAppearance) {
  return (description: string | JSX.Element, append?: JSX.Element): NotificationProps => ({
    appearance,
    content: {
      description,
    },
    append,
  });
}

export const createSuccessNotification = createNotificationCreator("SUCCESS");
export const createInfoNotification = createNotificationCreator("INFO");
export const createDangerNotification = createNotificationCreator("DANGER");
