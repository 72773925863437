import { useIntl } from "react-intl";
import { modalsText } from "../modalsText";
import { commonMessages } from "facility-commons/labels";

export interface IncorrectBoxQtyModalProps {
  fewerBoxes: boolean;
  boxesQty: number;
}

export const useIncorrectBoxQtyModal = (props: IncorrectBoxQtyModalProps) => {
  const { formatMessage } = useIntl();
  const { fewerBoxes, boxesQty } = props;

  const box = formatMessage(commonMessages.box, {
    boxesQty,
  });

  const title = fewerBoxes ? formatMessage(modalsText.fewerBoxesTitle) : formatMessage(modalsText.moreBoxesTitle);

  const buttonText = fewerBoxes ? formatMessage(modalsText.continue) : formatMessage(modalsText.receiveBoxes);

  const text = fewerBoxes ? formatMessage(modalsText.fewerBoxesText, { box }) : formatMessage(modalsText.moreBoxesText);

  return {
    title,
    buttonText,
    text,
    formatMessage,
  };
};
