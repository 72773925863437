import React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultTheme, Title, Text, ThemeProps, Notification, Box } from "@deliverr/ui";
import { Appearance, TextAlign } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import { TextInputCard } from "facility-commons/flow";
import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { useSkuCard } from "./useSkuCard";
import { CurrentSkuPill, PositionedPillContainer } from "../../pills";
import { SkuSummaryCard } from "../../SkuSummaryCard";
import { IllustrationCard } from "warehouse/common/components";
import { commonBoxMessages } from "warehouse/receiving/content";
import { HighRiskBoxWarningNotification } from "../../HighRiskBoxWarningNotification/HighRiskBoxWarningNotification";

const RelativeInputCard = styled(TextInputCard)<{ showIllustration?: boolean }, DefaultTheme>(
  ({ theme }) => `
  ${({ showIllustration }) =>
    showIllustration ? `border-radius: 0px 0px ${theme.border.radius.R5} ${theme.border.radius.R5}` : ""};
  position: relative;
  border-color: ${theme.colors.NEUTRAL[80]};
  border-top: 0;
`
);

const PositionedPill = styled(PositionedPillContainer)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
    margin: 0;
    top: ${theme.spacing.S5};
    right: ${theme.spacing.S5};
  `
);

const FlatBottomCard = styled(IllustrationCard)<ThemeProps, DefaultTheme>`
  background-color: ${({ theme }) => theme.colors.NEUTRAL[400]};
  border-color: ${({ theme }) => theme.colors.NEUTRAL[80]};
`;

const StyledText = styled(Text)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  text-align: center;
  color: ${theme.colors.NEUTRAL["100"]};
`
);

const StyledTitle = styled(Title)(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["00"]};
  `
);

export const SkuCard = () => {
  const {
    asnId,
    barcode,
    expectedSkus,
    handleChange,
    handleSubmit,
    loading,
    receivedUnits,
    skuError,
    skusReceivedCount,
    cdsku,
    isConsolidation,
    isCDSKUFlow,
    isMultiSku,
    isHighRiskBox,
    isReceiveWithoutCdsku,
    wasReceived,
    receiveCount,
    expectedNumUnitsInBox,
    formatMessage,
  } = useSkuCard();

  return (
    <>
      {wasReceived && skusReceivedCount === 0 && (
        <Box marginBottom={"S1"} as="section">
          <Notification
            appearance={Appearance.DANGER}
            content={{
              description: formatMessage(commonBoxMessages.previouslyReceived),
            }}
          />
        </Box>
      )}
      {!!isHighRiskBox && <HighRiskBoxWarningNotification />}
      {!!isHighRiskBox && !wasReceived && (
        <Box marginBottom={"S1"} as="section">
          <Notification
            appearance={Appearance.PRIMARY}
            content={{
              description: formatMessage(commonBoxMessages.expectedQtyVsReceivedQty, {
                expectedQty: expectedNumUnitsInBox,
                receivedQty: receiveCount,
              }),
            }}
          />
        </Box>
      )}
      {!isHighRiskBox && (
        <SkuSummaryCard expectedSkus={expectedSkus} numSkus={skusReceivedCount} receivedUnits={receivedUnits} />
      )}
      {/* Only show this illustration on the initial SKU scan for a box */}
      {(!skusReceivedCount || !isMultiSku) && (
        <FlatBottomCard>
          <OpenBox />
          <StyledTitle as="h3" displayAs="h3" textAlign={TextAlign.center}>
            {isConsolidation ? (
              <FormattedMessage id="warehouse.skuCard.receiveNextUnits" defaultMessage="Receive next SKU" />
            ) : (
              <FormattedMessage id="warehouse.cdskuCard.open" defaultMessage="Open this box" />
            )}
          </StyledTitle>
          <StyledText>
            {isReceiveWithoutCdsku ? (
              <FormattedMessage
                id="warehouse.receiving.skuCardTitleNoCdsku"
                defaultMessage={"PO # {asnId}"}
                values={{ asnId }}
              />
            ) : (
              cdsku
            )}
          </StyledText>
        </FlatBottomCard>
      )}
      <RelativeInputCard
        errorMessage={skuError}
        loading={loading}
        message={
          <FormattedMessage
            id="warehouse.receiving.skuCardMessage"
            defaultMessage="Open the box and scan a SKU barcode or enter it here"
          />
        }
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={{ id: "warehouse.barcode", defaultMessage: "Barcode" }}
        showIllustration={!skusReceivedCount}
        title={<FormattedMessage id="warehouse.receiving.skuCardTitle" defaultMessage="Scan SKU barcode" />}
        value={barcode}
        data-testid="sku-card-input"
      >
        {!isReceiveWithoutCdsku && isCDSKUFlow && !isHighRiskBox && (
          <PositionedPill>
            <CurrentSkuPill />
          </PositionedPill>
        )}
      </RelativeInputCard>
    </>
  );
};
