export type ToastPosition = "bottom-left" | "bottom-right" | "top-left" | "top-right";

// Position defaults to bottom-left to mimic the VersionCheck from seller-portal
export const DEFAULT_TOAST_POSITION: ToastPosition = "bottom-left";

export interface ToastInfo {
  id?: number;
  message: string;
  position?: ToastPosition;
  buttonLabel?: string;
  onButtonClick?: () => void;
  closeable?: boolean;
}
