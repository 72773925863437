import { useRecoilState } from "recoil";
import { ticketCenterState, TC_INITIAL_STATE } from "../ticketCenterState";

export const useResetNCFilterState = () => {
  const [tcState, setTCState] = useRecoilState(ticketCenterState);

  return () => {
    setTCState({
      ...tcState,
      filteredTicketList: TC_INITIAL_STATE.filteredTicketList,
      filter: TC_INITIAL_STATE.filter,
    });
  };
};
