import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";

import { NCRoutes } from "../../base/routes";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import {
  createDangerNotification,
  createSuccessNotification,
  genericOnScannerInputChange,
  log,
  logStart,
} from "facility-commons/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useRecoilValue } from "recoil";
import { userState } from "facility-commons/base/Auth/userState";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { storageInboundReceivingStateAtom } from "warehouse/receiving/storage/StorageReceivingState";

export const useStorageBarcodeCard = () => {
  const [barcodeError, setBarcodeError] = useState<string>("");
  const { barcode = "", trackingCode, incidentId } = useRecoilValue(currentProductState);
  const { poDetails } = useRecoilValue(storageInboundReceivingStateAtom);
  const { handleNonComplianceError, updateCurrentProduct } = useNonComplianceFlow();
  const { addAutoCloseNotification, errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { inboundClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);

  const updateBarcode = (newSku: string) => {
    setBarcodeError("");
    updateCurrentProduct("barcode", newSku);
  };

  const proceedToFollowUp = () => push(NCRoutes.BARCODE_UPLOAD);

  const [{ loading }, handleSubmit] = useAsyncFn(async (barcodeValue: string) => {
    const ctx = logStart({ fn: "useStorageBarcodeCard.handleSubmit", barcode: barcodeValue });

    if (!barcodeValue.length) {
      errorResponse();
      return setBarcodeError(formatMessage(commonWarehouseMessages.fieldRequired));
    }

    try {
      const { searchTerms } = poDetails;
      let dsku = "";
      Object.keys(searchTerms).forEach((val) => {
        if (searchTerms[val].includes(barcodeValue)) {
          dsku = val;
          return;
        }
      });
      const response = await inboundClient.asnReceiveValidateBarcode(
        warehouseId,
        trackingCode!.toString(),
        dsku,
        incidentId!
      );
      updateCurrentProduct("currentDSKU", dsku);
      if (response.error) {
        if (response.error.code === "InvalidBarcodeError") {
          log({ ...ctx, response }, "Barcode scan InvalidBarcodeError response");
          errorResponse();
          addAutoCloseNotification(
            createDangerNotification(
              formatMessage(
                {
                  id: "storage.nonCompliance.barcodeError",
                  defaultMessage: `{barcodeValue} does not belong to this PO. Enter correct Barcode or Skip this step.`,
                },
                { barcodeValue }
              )
            )
          );
        }
      } else {
        successResponse();
        proceedToFollowUp();
        createSuccessNotification(formatMessage(commonWarehouseMessages.barcodeExisting));
      }
    } catch (err) {
      handleNonComplianceError(ctx, err);
    }
  });

  const handleChange = genericOnScannerInputChange(barcode, updateBarcode, handleSubmit, "upper");

  return {
    barcode,
    barcodeError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  };
};
