import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

import { Disposition } from "@deliverr/returns-client";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import { log } from "facility-commons";
import { modalsText } from "../modalsText";
import { returnReceiveItemInspectionStateAtom } from "warehouse/receiving/ReturnReceive/state";
import { useAsyncFn } from "react-use";
import { useBarcodePrint } from "warehouse/receiving/ReturnReceive/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import { userState } from "facility-commons/base/Auth/userState";

export const useReturnReceiveCreateRTMBoxModal = () => {
  const { formatMessage } = useIntl();
  const { returnsClient } = useClientsWithAuth();
  const { errorResponse } = useCommonFlow();
  const { hideModal } = useWarehouseModal();
  const { printBarcode } = useBarcodePrint();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const itemData = useRecoilValue(returnReceiveItemInspectionStateAtom);
  const [boxCreationError, setBoxCreationError] = useState<string | undefined>();
  const [rtmBoxBarcode, setRTMBoxBarcode] = useState<string | undefined>();

  const { sellerId } = itemData;

  const barcodeLabelText = `${Disposition.RTV}-${sellerId}`;

  const [reprintBarcodeState, reprintBarcode] = useAsyncFn(async () => {
    if (!rtmBoxBarcode) {
      errorResponse();
      setBoxCreationError(formatMessage(commonReturnReceiveMessage.noRTMBoxBarcode));
      return;
    }
    await printBarcode(rtmBoxBarcode!, barcodeLabelText, true);
  }, [rtmBoxBarcode]);

  const [submitState, createRTMBox] = useAsyncFn(async () => {
    setBoxCreationError(undefined);
    try {
      const response = await returnsClient.createEmptyPackage({
        qcFacilityCode: facilityId,
        boxDisposition: Disposition.RTV,
        sellerId,
      });

      const { boxBarcode } = response.value;
      if (!boxBarcode) {
        throw new Error(formatMessage(commonReturnReceiveMessage.unableToCreateRTMBoxBarcode));
      }
      await printBarcode(boxBarcode, barcodeLabelText, true);
      setRTMBoxBarcode(boxBarcode);
    } catch (error) {
      log({ error }, "error creating new RTM box");
      errorResponse();
      setBoxCreationError(String(error));
    }
  }, []);

  const onCancel = () => {
    hideModal(WarehouseModal.RETURN_RECEIVE_RTM_BOX_CREATION);
  };

  const messages = {
    createNewBoxTitle: formatMessage(commonReturnReceiveMessage.createNewRTMBox),
    boxCreatedTitle: formatMessage(commonReturnReceiveMessage.boxCreated),
    confirm: formatMessage(modalsText.confirm),
    createAnotherBox: formatMessage(commonReturnReceiveMessage.createAnotherBox),
    reprintBoxBarcode: formatMessage(commonReturnReceiveMessage.reprintBoxBarcode),
    cancel: formatMessage(modalsText.cancel),
  };

  return {
    messages,
    rtmBoxBarcode,
    boxCreationError,
    loading: submitState.loading || reprintBarcodeState.loading,
    createRTMBox,
    reprintBarcode,
    onCancel,
  };
};
