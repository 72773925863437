import { DefaultTheme, NavButton } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

import { useIntl } from "react-intl";
import { WarehouseDynamicRoutes } from "warehouse/routes";
import { WarehousePaths, useAppBar } from "./useAppBar";

const StyledAppBar = styled.div<React.HTMLAttributes<HTMLDivElement>, DefaultTheme>(({ children, theme }) => {
  const numberOfLinks = React.Children.toArray(children).filter(Boolean).length;

  return `
    background: ${theme.colors.NEUTRAL["00"]};
    border-top: 1px solid ${theme.colors.NEUTRAL[100]};
    display: grid;
    font-weight: ${theme.font.weight.BOLD};
    height: 59px;
    grid-template-columns: repeat(${numberOfLinks}, 1fr);
    justify-content: space-evenly;
    margin: auto;
    padding-bottom: 5px;
    z-index: 99;
    width: 100%;
  `;
});

export const AppBar: React.FC = () => {
  const {
    isSamePath,
    navigateWithButton,
    todoCount,
    shouldShowCreateTicket,
    shouldShowNewTicketCenter,
    shouldShowOldTicketCenter,
    userCanAccessProblemSolverFlow,
    problemSolverFlowEnabled,
    isRSOutboundsAppEnabled,
    routeToIssueDashboard,
    routeToNewIssue,
  } = useAppBar();
  const { formatMessage } = useIntl();

  return (
    <StyledAppBar>
      <NavButton
        iconType="truck"
        isActive={isSamePath(WarehousePaths.scanner)}
        onClick={() => navigateWithButton(WarehouseDynamicRoutes.SCANNER)}
        text={formatMessage({ id: "warehouse.appBar.unloading", defaultMessage: "Unloading" })}
      />
      <NavButton
        iconType="package"
        data-testid="receiving-nav-button"
        isActive={isSamePath(WarehousePaths.receiving)}
        onClick={() => navigateWithButton(WarehouseDynamicRoutes.RECEIVING)}
        text={formatMessage({ id: "warehouse.appBar.receiving", defaultMessage: "Receiving" })}
      />
      {isRSOutboundsAppEnabled && (
        <NavButton
          iconType="gift"
          data-testid="outbounds-nav-button"
          isActive={isSamePath(WarehousePaths.outbounds)}
          onClick={() => navigateWithButton(WarehouseDynamicRoutes.OUTBOUNDS)}
          text={formatMessage({ id: "warehouse.appBar.rs-outbounds", defaultMessage: "Outbounds" })}
        />
      )}
      {shouldShowCreateTicket && (
        <NavButton
          iconType="plus-circle"
          onClick={() => {
            problemSolverFlowEnabled ? routeToNewIssue() : navigateWithButton(WarehouseDynamicRoutes.NEW_TICKET);
          }}
          text={
            problemSolverFlowEnabled
              ? formatMessage({
                  id: "warehouse.appBar.createIssue",
                  defaultMessage: "Create Issue",
                })
              : formatMessage({ id: "warehouse.appBar.createTicket", defaultMessage: "Create Ticket" })
          }
        />
      )}
      {shouldShowNewTicketCenter ? (
        <NavButton
          iconType="clipboard"
          isActive={isSamePath(WarehousePaths.issueCenter)}
          onClick={() => routeToIssueDashboard()}
          text={formatMessage({
            id: "warehouse.appBar.new",
            defaultMessage: "New",
          })}
        />
      ) : null}
      {shouldShowOldTicketCenter ? (
        <NavButton
          count={todoCount}
          iconType="clipboard"
          isActive={isSamePath(WarehousePaths.ticketCenter)}
          onClick={() => navigateWithButton(WarehouseDynamicRoutes.TICKET_CENTER)}
          text={
            userCanAccessProblemSolverFlow
              ? formatMessage({
                  id: "warehouse.appBar.old",
                  defaultMessage: "Old",
                })
              : formatMessage({ id: "warehouse.appBar.tickets", defaultMessage: "Tickets" })
          }
        />
      ) : null}
      <NavButton
        iconType="ellipsis-h"
        isActive={isSamePath(WarehousePaths.more) || isSamePath(WarehousePaths.hazmat)}
        onClick={() => navigateWithButton(WarehouseDynamicRoutes.MORE)}
        text={formatMessage({ id: "warehouse.appBar.more", defaultMessage: "More" })}
      />
    </StyledAppBar>
  );
};
