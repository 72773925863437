import { useIntl } from "react-intl";
import { IntlString } from "./IntlString";

// accept a string or react-intl message and get back that string or i18 translation
export function useIntlString() {
  const { formatMessage } = useIntl();

  return (intlStr: string | IntlString) =>
    typeof intlStr === "string" ? intlStr : formatMessage(intlStr, intlStr.values ?? {});
}
