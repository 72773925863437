const palletIdRegExp = /^\d+$/;
const palletLabelIdRegExp = /^PID[A-Z0-9]{7}$/;
export const freightPalletLabelIdRegExp = /^FPID[A-Z0-9]{1,}$/;

function validateInput(regex: RegExp) {
  return (input: string) => Boolean(input.match(regex));
}

export const validatePalletId = validateInput(palletIdRegExp);
export const validatePalletLabelId = validateInput(palletLabelIdRegExp);
export const validateFreightPalletLabelId = validateInput(freightPalletLabelIdRegExp);

export const validateLoadingDockPalletLabelScan = (input: string) =>
  validatePalletId(input) || validatePalletLabelId(input) || validateFreightPalletLabelId(input);
