import { defineMessages } from "react-intl";

export const arrivalScanMessages = defineMessages({
  pallets: {
    id: "warehouse.arrivalScan.pallets",
    defaultMessage: "Pallets",
  },
  palletArrivalScanTitle: {
    id: "warehouse.arrivalScan.palletArrivalScanTitle",
    defaultMessage: "Pallet Arrival Scan",
  },
  palletArrivalScanDescription: {
    id: "warehouse.arrivalScan.palletArrivalScanDescription",
    defaultMessage: "Scan pallets to mark as arrived",
  },
  boxes: {
    id: "warehouse.arrivalScan.boxes",
    defaultMessage: "Boxes",
  },
  boxArrivalScanTitle: {
    id: "warehouse.arrivalScan.boxArrivalScanTitle",
    defaultMessage: "Box Arrival Scan",
  },
  boxArrivalScanDescription: {
    id: "warehouse.arrivalScan.boxArrivalScanDescription",
    defaultMessage: "Scan individual boxes for sorting",
  },
  boxIdentificationTitle: {
    id: "warehouse.arrivalScan.boxIdentificationTitle",
    defaultMessage: "Unknown Box Type",
  },
  boxIdentificationHelpText: {
    id: "warehouse.arrivalScan.boxIdentificationHelpText",
    defaultMessage: "Confirm box type by visually identifying the type of label on the box",
  },
  undeliverredPackage: {
    id: "warehouse.arrivalScan.undeliverredPackage",
    defaultMessage: "Undeliverred (RTS) Package",
  },
  returnedPackage: {
    id: "warehouse.arrivalScan.returnedPackage",
    defaultMessage: "Returns Order Package",
  },
  imNotSure: {
    id: "warehouse.arrivalScan.imNotSure",
    defaultMessage: "I'm not sure",
  },
  cdskuBoxTypeModalTitle: {
    id: "warehouse.arrivalScan.cdskuBoxTypeModalTitle",
    defaultMessage: "Box has a CDSKU label?",
  },
  cdskuBoxTypeModalMessage: {
    id: "warehouse.arrivalScan.cdskuBoxTypeModalMessage",
    defaultMessage: `Scan the label beginning with "CD" on the box arrival scan screen.`,
  },
  undeliverredBoxTypeModalTitle: {
    id: "warehouse.arrivalScan.undeliverredBoxTypeModalTitle",
    defaultMessage: "Box has a Return To Sender (RTS) label?",
  },
  returnedBoxTypeModalTitle: {
    id: "warehouse.arrivalScan.returnedBoxTypeModalTitle",
    defaultMessage: "Box is a Returns Order Package?",
  },
  returnedBoxTypeModalMessage: {
    id: "warehouse.arrivalScan.returnedBoxTypeModalMessage",
    defaultMessage: "Tracking code {trackingCode} will be used to create a Blind Return Order.",
  },
  scanCdskuNotificationMessage: {
    id: "warehouse.arrivalScan.scanCdskuNotificationMessage",
    defaultMessage: `Scan the CDSKU label on the box with label {label}`,
  },
  blindReturnCreationSuccess: {
    id: "warehouse.arrivalScan.blindReturnCreationSuccess",
    defaultMessage:
      "Blind return order created with tracking code {trackingCode}. Sort box to a rack for processing returns.",
  },
  blindReturnCreationFailed: {
    id: "warehouse.arrivalScan.blindReturnCreationFailed",
    defaultMessage:
      "Blind return creation failed for tracking code {trackingCode}. Try again or contact the Operational Manager.",
  },
  unableToIdentifyBoxTypeTitle: {
    id: "warehouse.arrivalScan.unableToIdentifyBoxType",
    defaultMessage: "Unable to identify box type?",
  },
  unableToIdentifyBoxTypeMessage: {
    id: "warehouse.arrivalScan.unableToIdentifyBoxTypeMessage",
    defaultMessage: "Contact the Operational Manager or set aside box with label {label} for non-compliance.",
  },
  sortBoxToRackMessage: {
    id: "warehouse.arrivalScan.sortBoxToRackMessage",
    defaultMessage: "Sort box with label {label} to appropriate rack.",
  },
  sortBoxToFCReceiving: {
    id: "warehouse.arrivalScan.sortBoxToFCReceiving",
    defaultMessage: "Sort box with label {label} to the fulfillment area for FC receiving.",
  },
  sortBoxToCDSorting: {
    id: "warehouse.arrivalScan.sortBoxToCDSorting",
    defaultMessage: "Sort box with label {label} to the crossdock area for Panda scanning.",
  },
});
