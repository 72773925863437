import React from "react";
import styled from "@emotion/styled";
import { ToastContainerProps } from "react-toastify";
import { DefaultTheme, ToastContainer } from "@deliverr/ui";

import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { DashboardList, DashboardListProps } from "./DashboardList";

const DashboardContainer = styled.div<Record<string, unknown>, DefaultTheme>`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_CARD_WIDTH};
  width: 100%;

  @media (max-width: 320px) {
    padding: ${({ theme }) => `${theme.spacing.S4} ${theme.spacing.S1}`};
  }
`;

// Need to add these styles to override position, width and font family
// Note: Taken from the original TicketCenterDashboard.tsx
const RaisedStyledToast = styled(ToastContainer)<ToastContainerProps, DefaultTheme>(
  ({ theme }) => `
  > .Toastify__toast {
    bottom: ${theme.spacing.S9};
    font-family: ${theme.font.family.STANDARD};

    @media (max-width: ${theme.breakpoints.SM}) {
      width: 100%;
    }
  }
`
);

export interface DashboardProps<ItemType extends Record<string, any>> extends DashboardListProps<ItemType> {
  /** determine if toasts should be displayed in a Dashboard-specific toast container. Defaults to true */
  shouldHandleToastsLocally?: boolean;
}

export function Dashboard<ItemType extends Record<string, any>>({
  shouldHandleToastsLocally = true,
  ...props
}: DashboardProps<ItemType>) {
  return (
    <DashboardContainer>
      <DashboardList {...props} />
      {shouldHandleToastsLocally && <RaisedStyledToast />}
    </DashboardContainer>
  );
}
