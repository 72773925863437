import { IconV2 } from "@deliverr/ui";
import * as React from "react";
import { FACILITY_HEADER_ICON_BUTTON_SIZE } from "./FacilityHeader.constants";
import { FacilityHeaderIconContainer } from "./FacilityHeaderIconContainer";

export interface FacilityHeaderCloseButtonProps {
  onClose: () => void;
}

export const FacilityHeaderCloseButton: React.FC<FacilityHeaderCloseButtonProps> = ({ onClose }) => (
  <FacilityHeaderIconContainer onClick={onClose}>
    <IconV2 type="close" size={FACILITY_HEADER_ICON_BUTTON_SIZE} />
  </FacilityHeaderIconContainer>
);
