import React from "react";
import { useIntl } from "react-intl";
import {
  AutoresolveNonComplianceModal,
  AutoresolveNonComplianceModalProps,
} from "warehouse/ticket-center/new/non-compliance/modals/AutoresolveNonComplianceModal/AutoresolveNonComplianceModal";

export type MisRoutedBoxProps = Omit<AutoresolveNonComplianceModalProps, "title" | "instructions" | "continueMessage">;
export const MisroutedBox: React.FC<MisRoutedBoxProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <AutoresolveNonComplianceModal
      title={formatMessage({
        id: "warehouse.misroutedModal.misroutedBox",
        defaultMessage: "Misrouted Box",
      })}
      instructions={formatMessage({
        id: "warehouse.misroutedModal.instructions",
        defaultMessage: "Receive this box against",
      })}
      continueMessage={formatMessage({
        id: "warehouse.misroutedModal.continue",
        defaultMessage: "Continue creating ticket",
      })}
      {...props}
    />
  );
};
