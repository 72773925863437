import React, { useState } from "react";
import { MessageList, TextInput } from "react-chatbot-ui";
import { ButtonInput } from "react-chatbot-ui/dist/shared/models";
import { useMount } from "react-use";
import { FileMetaDataDTO, QuestionType, WotQuestionDTO } from "@deliverr/wot-client";
import { ChatBotUI } from "./lib/ChatBotUI";
import { useRecoilValue } from "recoil";
import { useClientsWithAuth } from "../../../../facility-commons/hooks/auth";
import { userState } from "../../../../facility-commons/base/Auth/userState";
import { createMsgWithButton, createMsgWithText } from "./CreateMsgUtilsFunctions";

const LOADING_TEXT = "...";

export const ChatbotCard = () => {
  const [sessionId, setSessionId] = useState(`chatSession_${Math.random()}`);
  const { wotClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const [questionId, setQuestionId] = useState("INIT");
  const [messageList, setMessageList] = useState<MessageList>([]);
  const [inputBox, setInputBox] = useState(false);
  const [file, setFile] = useState<FileMetaDataDTO | undefined>();
  const [inputType, setInputType] = useState("text");
  const [buttonsPage, setButtonsPage] = useState(0);
  const [isOptionsIds, setIsOptionsIds] = useState<boolean>(false);

  const addHumanMessage = (text: string, options?: { replaceLast?: boolean }) => {
    setMessageList((prevMessageList) => [
      ...(options?.replaceLast ? prevMessageList.slice(0, prevMessageList.length - 1) : prevMessageList),
      createMsgWithText("right", text),
    ]);
  };

  const addBotMessageText = (question: WotQuestionDTO) => {
    setMessageList((prevMessageList) => [
      ...prevMessageList.filter((msg) => msg.text !== LOADING_TEXT),
      createMsgWithText("left", question.questionText),
    ]);
  };

  const addBotMessageMulti = (question: WotQuestionDTO) => {
    const buttons = Object.entries(question.multicoreAnswers!).map(([value, name]) => ({ name, value }));
    setMessageList((prevMessageList) => [
      ...prevMessageList.filter((msg) => msg.text !== LOADING_TEXT),
      createMsgWithText("left", question.questionText),
      createMsgWithButton(buttons),
    ]);
  };

  const addBotMessageLoading = () => {
    setMessageList((prevMessageList) => [...prevMessageList, createMsgWithText("left", "...")]);
  };

  const addBotMessage = {
    [QuestionType.TEXT]: addBotMessageText,
    [QuestionType.NUMERIC]: addBotMessageText,
    [QuestionType.MULTI_CHOICE]: addBotMessageMulti,
    [QuestionType.FILE_UPLOAD]: addBotMessageText,
  };

  const nextQuestion = async (payload: string) => {
    addBotMessageLoading();
    const question = (
      await wotClient.addAnswer(
        {
          sessionId,
          questionId,
          answer: payload.split("url")[0], // in case of answer that includes url, we don't want to save it in the wot
        },
        warehouseId
      )
    ).data;
    if (question.questionKey === "FLOW_TYPE") {
      setSessionId(`chatSession_${Math.random()}`);
    }
    setQuestionId(question.questionKey);
    setInputBox(
      question.questionType !== QuestionType.MULTI_CHOICE && question.questionType !== QuestionType.FILE_UPLOAD
    );
    setInputType(question.questionType === QuestionType.NUMERIC ? "number" : "text");
    setFile(
      question.questionType === QuestionType.FILE_UPLOAD && question.fileMetadata ? question.fileMetadata : undefined
    );
    setIsOptionsIds(!!question.optionsAreIds);
    addBotMessage[question.questionType]?.(question);
  };

  const sendCallback = async ({ payload }: TextInput) => {
    addHumanMessage(payload);
    await nextQuestion(payload);
  };

  const buttonCallback = async ({ payload }: ButtonInput) => {
    addHumanMessage(payload.name, { replaceLast: true });
    await nextQuestion(payload.value);
  };

  const config = {
    height: "100%",
    width: "100%",
    sendCallback,
    buttonCallback,
  };

  useMount(async () => {
    await nextQuestion("");
  });

  const onPrev = () => setButtonsPage((val) => Math.max(0, val - 1));
  const onNext = () =>
    setButtonsPage((val) =>
      Math.min(val + 1, Math.floor(((messageList?.[messageList.length - 1]?.button?.length ?? 1) - 1) / 10))
    );

  return (
    <ChatBotUI
      config={config}
      messageList={messageList}
      inputBox={inputBox}
      inputType={inputType}
      file={file}
      buttonsPage={buttonsPage}
      onPrev={onPrev}
      onNext={onNext}
      isOptionsIds={isOptionsIds}
    />
  );
};
