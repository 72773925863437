import styled from "@emotion/styled";
import React from "react";
import { Notification, Stack } from "@deliverr/ui";
import { FlowNotificationsState } from "facility-commons/flow";
import { FACILITY_THEME } from "facility-commons/theme";

interface Props {
  notifications: FlowNotificationsState;
}

const Container = styled(Stack)`
  width: 100%;
  margin: 0;
`;

const AnimatedNotification = styled(Notification)`
  animation: ${FACILITY_THEME.animation.fadeIn} 300ms ease-in;
  word-break: break-word;
`;

export const FlowVisibleNotifications: React.FC<Props> = ({ notifications }) => {
  return (
    <Container center>
      {notifications.map((notificationProps, i) => (
        <AnimatedNotification key={i} {...notificationProps} />
      ))}
    </Container>
  );
};
