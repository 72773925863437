import { selector } from "recoil";
import { sum, sumBy } from "lodash";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";

export const shipmentItemsSelector = selector({
  key: "shipmentItemsSelector",
  get: ({ get }) => {
    const rsOutboundsState = get(rsOutboundsStateAtom);
    const {
      shipmentDetails,
      shipmentItemsDetails,
      unifiedProductDetails,
      currentPackageDskuToQtyMap,
    } = rsOutboundsState;
    const totalItems = sumBy(shipmentDetails?.shipmentItems, (item) => item.qty);
    const numOfPackagesCreated = shipmentDetails?.packages?.length ?? 0;
    const numOfItemsPacked =
      shipmentDetails?.packages?.reduce((acc, pack) => acc + sum(Object.values(pack.dskuToQtyMap ?? {})), 0) ?? 0;
    const totalPendingItems = totalItems - numOfItemsPacked;

    const itemsQtyMap: Record<string, number> = {};
    shipmentDetails?.shipmentItems?.forEach((item) => {
      itemsQtyMap[item.dsku] = item.qty;
    });
    const itemsPacked: Record<string, number> = {};
    shipmentDetails?.packages?.forEach((pack) => {
      Object.keys(pack.dskuToQtyMap ?? {}).forEach((dsku) => {
        itemsPacked[dsku] = (itemsPacked[dsku] ?? 0) + (pack.dskuToQtyMap?.[dsku] ?? 0);
      });
    });
    const pendingItemsMap: Record<string, { qty: number; msku: string }> = {};
    Object.keys(shipmentItemsDetails ?? {}).forEach((dsku) => {
      if (itemsQtyMap[dsku] - (itemsPacked[dsku] ?? 0) > 0) {
        pendingItemsMap[dsku] = {
          qty: itemsQtyMap[dsku] - (itemsPacked[dsku] ?? 0) - (currentPackageDskuToQtyMap[dsku] ?? 0),
          msku:
            shipmentItemsDetails?.[dsku]?.msku ||
            unifiedProductDetails?.[shipmentItemsDetails?.[dsku]?.packOf ?? ""]?.msku ||
            "",
        };
      }
    });
    return {
      totalItems,
      numOfPackagesCreated,
      numOfItemsPacked,
      totalPendingItems,
      pendingItemsMap,
    };
  },
});
