import { Button, ButtonProps } from "@deliverr/ui";
import styled, { StyledComponent } from "@emotion/styled";
import { ReactNode } from "react";

export const FixedWidthButton = styled(Button)<{ width: string }>(
  ({ width }) => `
  width: ${width};
`
);

export const generateFixedWidthButton = (
  button: React.FC,
  width: string
): StyledComponent<
  ButtonProps & {
    children?: ReactNode;
  },
  Record<string, unknown>,
  Record<string, unknown>
> =>
  styled(button)`
    width: ${width};
  `;
