export enum DamagedProductField {
  ADDITIONAL_PHOTO1 = "additionalPhoto1",
  ADDITIONAL_PHOTO2 = "additionalPhoto2",
  ASN = "asnId",
  BARCODE = "barcode",
  STORAGE_LOCATION = "location",
  CDSKU = "cdsku",
  DAMAGED_EXTERIOR_PHOTO = "damagedExteriorPhoto",
  DAMAGED_PRODUCT_PHOTO = "damagedProductPhoto",
  DISPOSED = "isDisposed",
  OTHER_DETAILS = "additionalDetails",
  QUANTITY = "damagedQty",
  EMAIL = "warehouseContactEmail",
  DAMAGE_TYPE = "damageType",
  BOX_DAMAGED = "boxDamaged",
  UNITS_PER_CASE = "unitsPerCase",
  CASE_QUANTITY = "caseQty",
}

export enum DamagedProductValidationErrorMessage {
  ASN_REQUIRED = "PO # is required",
  ASN_FORMAT = "PO # has invalid length",
  ASN_INVALID = "PO # doesn't exist", // used to re-map error message from commons-clients
  BARCODE_REQUIRED = "Barcode is required",
  BARCODE_INVALID = "Barcode is invalid", // used to re-map error message from commons-clients
  BARCODE_FORMAT = "Invalid barcode it must be DSKU, or UPC/EAN or FNSKU",
  CDSKU_REQUIRED = "Box ID is required",
  CDSKU_INVALID = "Box ID is invalid",
  CDSKU_FORMAT = 'Please scan a valid Box ID that starts with "CD"',
  DAMAGED_PRODUCT_PHOTO_REQUIRED = "Damaged Item(s) photo is required",
  DAMAGED_EXTERIOR_PHOTO_REQUIRED = "Damaged Exterior Photo is required",
  QTY_REQUIRED = "Quantity is required",
  QTY_INVALID = "Quantity must be a number",
  QTY_TOO_SMALL = "Quantity must be greater than 0",
  UNKNOWN = "Unknown error submitting case, please try again later.",
  EMAIL_INVALID = "Please enter a valid email address",
}
