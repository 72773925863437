import { ButtonProps, DefaultTheme, StyledButton } from "@deliverr/ui";
import styled from "@emotion/styled";

export const StyledMenuLink = styled(StyledButton)<ButtonProps, DefaultTheme>(
  ({ theme }) => `
  
  color: ${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.REGULAR};
  font-size: ${theme.font.size.F3};

  & > svg {
    color: ${theme.colors.NEUTRAL[300]};
  }

  & > svg:first-of-type {
    /* icon left of link label */
    width: ${theme.iconSize.I6};
  }
  & > svg:last-of-type {
    /* chevron right arrow */
    margin-left: auto;
  }
  &,
  &:active {
    background-color: ${theme.colors.NEUTRAL["00"]};
    border: none;
  }
  &:active,
  &:focus {
    box-shadow: none;
  }
  &:hover,
  &:focus {
    background-color: ${theme.colors.NEUTRAL["40"]};
    border-color: ${theme.colors.NEUTRAL["80"]};
  }
`
);
