import React from "react";
import { ContinueButton } from "warehouse/common/components";
import { useExpirationDate } from "./useExpirationDate";
import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";
import { Stack, Title } from "@deliverr/ui";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { DateFormat, DateInput, FacilityCard } from "facility-commons";
import { commonTicketMessages, expirationDateMessages } from "warehouse/ticket-center/new/non-compliance/content";

export const ExpirationDate: React.FC = () => {
  const {
    formatMessage,
    expirationDateInputValue,
    expirationDateErrorMsg,
    updateExpirationDate,
    onSubmit,
    onSkip,
    disableButton,
  } = useExpirationDate();

  return (
    <Stack>
      <FacilityCard>
        <Stack>
          <Title displayAs="h3">{formatMessage(expirationDateMessages.expirationDateTitle)}</Title>
          <DateInput
            value={expirationDateInputValue}
            mask={DateFormat.LongDate}
            onChange={updateExpirationDate}
            placeholderText={formatMessage(commonReceivingMessages.expirationDate)}
            helpText={formatMessage(commonTicketMessages.expirationDateInputHelpText)}
            errorMessage={expirationDateErrorMsg}
          />
          <SkipStep
            message={formatMessage(expirationDateMessages.unreadableExpirationDate)}
            onSkip={onSkip}
            hideSeparator
          />
        </Stack>
      </FacilityCard>
      <ContinueButton block disabled={disableButton} onClick={onSubmit} />
    </Stack>
  );
};
