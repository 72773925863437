import { defineMessages } from "react-intl";

export const commonWarehouseMessages = defineMessages({
  cdskuScanned: {
    id: "cdskuScanned",
    defaultMessage: "{cdsku} is a CDSKU. Scan a product barcode, or go back to scan this CDSKU.",
  },
  cdskuScannedAsnReceive: {
    id: "cdskuScannedAsnReceive",
    defaultMessage: "{cdsku} is a CDSKU. Scan a PO #, or go back to scan this CDSKU.",
  },
  fieldRequired: {
    id: "fieldRequired",
    defaultMessage: "This field is required.",
  },
  poOrTracking: {
    id: "poOrTracking",
    defaultMessage: "PO # or Tracking #",
  },
  submitTicket: { id: "submitTicket", defaultMessage: "Submit ticket" },
  exceededMaxCharacterCount: {
    id: "exceedMaxCharacterCount",
    defaultMessage: "Input must be {characterLimit} or less characters.",
  },
  createNonComplianceTicket: {
    id: "createNonComplianceTicket",
    defaultMessage: "Create a non-compliance ticket",
  },
  barcodeExisting: {
    id: "createNonComplianceTicket.existingBarcode",
    defaultMessage: "Barcode exists for this PO. Continue with Receiving",
  },
});
