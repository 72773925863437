import { defineMessages } from "react-intl";

export const OVERVIEW_CARD_LABELS = {
  listItems: defineMessages({
    po: {
      id: "warehouse.ticketCenter.ticketDetails.po",
      defaultMessage: "Receiving PO #",
    },
    barcode: {
      id: "warehouse.ticketCenter.ticketDetails.barcode",
      defaultMessage: "Barcode",
    },
    lotNumber: {
      id: "warehouse.ticketCenter.ticketDetails.lotNumber",
      defaultMessage: "Lot Number",
    },
    expirationDate: {
      id: "warehouse.ticketCenter.ticketDetails.expirationDate",
      defaultMessage: "Expiration Date",
    },
  }),
  sectionHeaders: defineMessages({
    quantities: {
      id: "warehouse.ticketCenter.ticketDetails.sectionHeaders.quantities",
      defaultMessage: "Quantities",
    },
    additionalDetails: {
      id: "warehouse.ticketCenter.ticketDetails.sectionHeaders.additionalDetails",
      defaultMessage: "Additional details",
    },
  }),
  quantityList: defineMessages({
    unitsPerBox: {
      id: "warehouse.ticketCenter.ticketDetails.unitsPerBox",
      defaultMessage: "Number of units per box",
    },
    nonCompliantBoxes: {
      id: "warehouse.ticketCenter.ticketDetails.nonCompliantBoxes",
      defaultMessage: "Number of non-compliant boxes",
    },
    totalUnits: {
      id: "warehouse.ticketCenter.ticketDetails.totalUnits",
      defaultMessage: "Total units",
    },
  }),
  actions: defineMessages({
    deleteTicket: {
      id: "warehouse.ticketCenter.ticketDetails.actions.deleteTicket",
      defaultMessage: "Delete ticket",
    },
  }),
};
