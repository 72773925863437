import { Stack, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { Tab, TabConfig } from "./Tab";

const TabContainer = styled(Stack)<ThemeProps>(
  ({ theme }) => `
    height: 52px;
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
    justify-content: space-around;
    background-color: ${theme.colors.NEUTRAL["00"]};
    font-size: ${theme.font.size.F4};
    box-shadow: ${theme.shadow.S1};
    z-index: ${theme.zindex.Z2}; // to make sure it's on top of the top page header
    margin-top: -${theme.spacing.S4};
  `
);

interface TabsProps<TabType extends string> {
  id?: string;
  tabs: TabConfig<TabType>[];
  activeTab: string;
  onClick: (value: TabType) => void;
}

export function Tabs<TabType extends string>({ tabs, activeTab, onClick, id }: TabsProps<TabType>) {
  return (
    <TabContainer id={id} data-testid={id ?? "tab-container"} direction="HORIZONTAL">
      {tabs.map((config) => (
        <Tab<TabType>
          {...config}
          key={`${config.value}-tab`}
          isActive={config.value === activeTab}
          onClick={() => onClick(config.value)}
        />
      ))}
    </TabContainer>
  );
}
