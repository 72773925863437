export enum NCRoutes {
  CDSKU = "cdsku",
  PO_NUM = "po-num",
  STORAGE = "storage",
  STORAGE_LOCATION = "storage-location",
  CASE_UPLOAD = "case-upload",
  TRACKING = "tracking",
  BARCODE = "barcode",
  BCL_UPLOAD = "box-contents-upload",
  BARCODE_UPLOAD = "barcode-upload",
  PRODUCT_UPLOAD = "product-upload",
  QUANTITY = "quantity",
  DETAILS = "details",
  EMAIL = "email",
  REVIEW = "review",
  STORAGE_REVIEW = "storage-review",
  LOT = "lot",
  EXPIRATION_DATE = "expiration-date",
  LOT_EXPIRY_UPLOAD = "lot-expiry-upload",
}
