import { IconV2Types, NotificationProps } from "@deliverr/ui";
import { LogContext } from "@deliverr/ui-logging";
import { FlashType } from "facility-commons/components/Flash";
import { getUnknownErrorNotification } from "facility-commons/flow/notifications/getUnknownErrorNotification";
import { SoundFx } from "facility-commons/common/sfx";
import { addItem, removeAtIndex } from "facility-commons/utils/immutabilityUtils";
import { logError } from "facility-commons/utils/logger";
import { useSoundEffect } from "facility-commons/hooks/useSoundEffect";
import { useSetRecoilState } from "recoil";
import { flashState, notificationsState, timerState } from "./flowState";

export function useCommonFlow() {
  const setTimerState = useSetRecoilState(timerState);
  const setNotificationsState = useSetRecoilState(notificationsState);
  const setFlash = useSetRecoilState(flashState);

  const { playSfx, maybePlaySfx } = useSoundEffect();

  function showTimer(seconds: number): void {
    setTimerState({ visible: true, seconds });
  }

  function hideTimer(): void {
    setTimerState({ visible: false, seconds: 0 });
  }

  function addNotification(notificationProps: NotificationProps): void {
    setNotificationsState(addItem(notificationProps));
  }

  function clearNotification(indexNotification: number): void {
    setNotificationsState(removeAtIndex(indexNotification));
  }

  function resetNotifications(): void {
    setNotificationsState([]);
  }

  function addAutoCloseNotification(notificationProps: NotificationProps, autoClose: number = 5000): void {
    setNotificationsState(addItem(notificationProps));
    const timeoutId = window.setTimeout(() => {
      resetNotifications();
      window.clearTimeout(timeoutId);
    }, autoClose);
  }

  function emitFlash(type: FlashType, icon?: IconV2Types, text?: string): void {
    setFlash({ type, icon, text });
    const timeoutId = window.setTimeout(() => {
      setFlash({ type: undefined, icon: undefined, text: undefined });
      window.clearTimeout(timeoutId);
    }, 200);
  }

  function inputResponse(sfx: SoundFx, flashType: FlashType, iconType?: IconV2Types, text?: string) {
    return (resetValue?: () => void): void => {
      playSfx(sfx);
      emitFlash(flashType, iconType, text);
      resetValue?.();
    };
  }

  const successResponse = inputResponse(SoundFx.SUCCESS, "SUCCESS");
  const infoResponse = inputResponse(SoundFx.INFO, "DEFAULT");
  const errorResponse = inputResponse(SoundFx.ERROR, "DANGER");

  function handleUnknownError(ctx: LogContext, error: any, resetFn?: () => void): void {
    logError(ctx, error);
    errorResponse(resetFn);
    addAutoCloseNotification(getUnknownErrorNotification(error?.payload?.message ?? error?.message));
  }

  return {
    showTimer,
    hideTimer,
    addNotification,
    clearNotification,
    resetNotifications,
    addAutoCloseNotification,
    handleUnknownError,
    playSfx,
    maybePlaySfx,
    emitFlash,
    successResponse,
    infoResponse,
    errorResponse,
    customResponse: inputResponse,
  };
}
