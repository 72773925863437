import React from "react";
import styled from "@emotion/styled";
import { useLifecycles } from "react-use";

import { ModalOverlay } from "facility-commons";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

interface TicketCenterThumbnailModalProps {
  link?: string;
}

const OverlayImage = styled.img`
  max-width: 330px;
  width: 100%;
`;

export const TicketCenterThumbnailModal: React.FC<TicketCenterThumbnailModalProps> = ({ link }) => {
  const { hideModal } = useWarehouseModal();

  const closeThumbnailModal = () => hideModal(WarehouseModal.TICKET_CENTER_THUMBNAIL);

  useLifecycles(
    () =>
      (document.onkeydown = (event) => {
        if (event.key === "Escape") {
          closeThumbnailModal();
        }
      }),
    () => (document.onkeydown = null)
  );

  return (
    <ModalOverlay onClick={() => closeThumbnailModal()}>
      <OverlayImage alt="case thumbnail photo" onClick={(e) => e.stopPropagation()} src={link} />
    </ModalOverlay>
  );
};
