import { defineMessages } from "react-intl";

export const RESOLUTION_CARD_ACTION_SECTION = defineMessages({
  viewSupportTicket: {
    id: "warehouse.ticketCenter.ticketDetails.viewSupportTicket",
    defaultMessage: "View support ticket",
  },
  printBarcodes: {
    id: "warehouse.ticketCenter.ticketDetails.printBarcodes",
    defaultMessage: "Print barcodes",
  },
  closeTicket: {
    id: "warehouse.ticketCenter.ticketDetails.closeTicket",
    defaultMessage: "Close ticket",
  },
  reopenTicket: {
    id: "warehouse.ticketCenter.ticketDetails.reopenTicket",
    defaultMessage: "Reopen ticket",
  },
});
