import { useIntl } from "react-intl";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { commonLotMessages, commonReceivingMessages, commonReturnReceiveMessage } from "warehouse/receiving/content";
import { returnReceiveItemInspectionStateAtom, returnReceiveReceivingDataStateAtom } from "../../state";
import { useState } from "react";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { validLotInput } from "facility-commons/utils/config";
import { isEmpty } from "lodash";
import { MonthDayYearRegex, setProp } from "facility-commons";
import {
  ExpiryInputValidationResult,
  LotInputValidationResult,
  MAXIMUM_EXPIRE_YEAR,
  MAX_LOT_VALUE_INPUT_LENGTH,
  MINIMUM_EXPIRE_YEAR,
  getExpiryInputValidationResults,
  getLotInputValidationResults,
} from "warehouse/receiving/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useReturnReceiveFlow } from "../useReturnReceiveFlow";
import { createReturnReceiveItemInspection, createReturnReceiveLocationScan } from "../returnReceiveCardCreators";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useLotExpiryInput = () => {
  const { formatMessage } = useIntl();
  const { successResponse, errorResponse } = useCommonFlow();
  const { showModal, hideModal } = useWarehouseModal();
  const { nextFlowCard } = useReturnReceiveFlow();
  const itemInspectionData = useRecoilValue(returnReceiveItemInspectionStateAtom);
  const setReturnReceivingData = useSetRecoilState(returnReceiveReceivingDataStateAtom);

  const [lotNumberInputValue, setLotNumberInputValue] = useState<string>("");
  const [expirationDateInputValue, setExpirationDateInputValue] = useState<string>("");
  const [lotErrorMsg, setLotErrorMsg] = useState<string>("");
  const [expirationDateErrorMsg, setExpirationDateErrorMsg] = useState<string>("");

  const { isLotTrackingEnabled, isFefoEnabled } = itemInspectionData;

  const onLotChange = (value) => {
    // allow only alphanumeric entries
    if (!validLotInput.test(value)) {
      return;
    }
    setLotErrorMsg("");
    setLotNumberInputValue(value);
  };

  const onLotInputError = () => {
    errorResponse();
    setLotNumberInputValue("");
  };

  const onExpirationDateChange = ({ target: { value } }) => {
    if (!value) {
      return setExpirationDateInputValue("");
    }
    setExpirationDateErrorMsg("");
    setExpirationDateInputValue(value);
  };

  const onExpiryInputError = () => {
    errorResponse();
    setExpirationDateInputValue("");
  };

  const onConfirm = () => {
    // If a sku requires an expiration date or lot input from the receiver then we need to validate those inputs
    // It is possible for a sku to require an expiration date input, a LOT input, or BOTH
    // lot input validations
    if (isLotTrackingEnabled) {
      const lotValidationResults = getLotInputValidationResults(lotNumberInputValue, MAX_LOT_VALUE_INPUT_LENGTH);
      switch (lotValidationResults) {
        case LotInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setLotErrorMsg(formatMessage(commonLotMessages.missingLotInput));
        case LotInputValidationResult.EXCEEDED_MAX_CHARACTER_COUNT:
          onLotInputError();
          return setLotErrorMsg(
            formatMessage(commonWarehouseMessages.exceededMaxCharacterCount, {
              characterLimit: MAX_LOT_VALUE_INPUT_LENGTH,
            })
          );
        case LotInputValidationResult.INVALID_INPUT:
          onLotInputError();
          return setLotErrorMsg(formatMessage(commonLotMessages.invalidLotInput));
        case LotInputValidationResult.VALID:
          setReturnReceivingData(setProp("lotNumber", lotNumberInputValue));
          break;
      }
    }

    // expiration date input validations
    if (isFefoEnabled) {
      const { result: expiryValidationResults, formattedDate, lastDateOfMonth } = getExpiryInputValidationResults(
        expirationDateInputValue,
        MINIMUM_EXPIRE_YEAR,
        MAXIMUM_EXPIRE_YEAR
      );
      switch (expiryValidationResults) {
        case ExpiryInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.missingExpirationDateInput));
        case ExpiryInputValidationResult.INVALID_DATE:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.incorrectExpirationDateFormat));
        case ExpiryInputValidationResult.INVALID_YEAR:
          onExpiryInputError();
          return setExpirationDateErrorMsg(
            formatMessage(commonReceivingMessages.invalidYear, {
              minYear: MINIMUM_EXPIRE_YEAR,
              maxYear: MAXIMUM_EXPIRE_YEAR,
            })
          );
        case ExpiryInputValidationResult.INVALID_MONTH:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidMonth));
        case ExpiryInputValidationResult.INVALID_DAY:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidDay, { lastDateOfMonth }));
        case ExpiryInputValidationResult.EXPIRED_PRODUCT:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.expiredProduct));
        case ExpiryInputValidationResult.VALID:
          setReturnReceivingData(setProp("expirationDate", formattedDate));
          break;
      }
    }

    // double confirmation
    showModal(WarehouseModal.CONFIRM_LOT_EXPIRY, {
      requireLotInput: isLotTrackingEnabled,
      requireExpiryInput: isFefoEnabled,
      previousLotInput: lotNumberInputValue,
      previousExpiryInput: expirationDateInputValue,
      onCancel: () => {
        hideModal(WarehouseModal.CONFIRM_LOT_EXPIRY);
      },
    });
    // clear the input fields so user must look at physical product for lot/expiry info
    setLotNumberInputValue("");
    setExpirationDateInputValue("");
  };

  const missingLotExpiryText =
    isLotTrackingEnabled && isFefoEnabled
      ? formatMessage(commonReturnReceiveMessage.missingLotOrExpiration)
      : isLotTrackingEnabled
      ? formatMessage(commonReturnReceiveMessage.missingLot)
      : formatMessage(commonReturnReceiveMessage.missingExpirationDate);

  const onMissingLotOrExpirationDate = () => {
    showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
      title: missingLotExpiryText,
      text: formatMessage(commonReturnReceiveMessage.cannotRestockItemWithOutAllProductDetails),
      onConfirm: () => {
        successResponse();
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
        nextFlowCard(createReturnReceiveItemInspection({}));
      },
      onCancel: () => {
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
      },
    });
  };

  const messages = {
    title: formatMessage(commonReceivingMessages.inputProductDetails),
    lotInputPlaceholder: formatMessage(commonLotMessages.lotNumber),
    expirationDateInputPlaceholder: formatMessage(commonReceivingMessages.expirationDate),
    expirationdateHelpText: formatMessage(commonReceivingMessages.expirationDateInputHelpText),
    confirmButtonText: formatMessage(commonReceivingMessages.confirm),
    missingLotExpiryText,
  };

  const disableButton =
    (isLotTrackingEnabled && isEmpty(lotNumberInputValue)) ||
    (isFefoEnabled && !MonthDayYearRegex.test(expirationDateInputValue)) ||
    !isEmpty(lotErrorMsg) ||
    !isEmpty(expirationDateErrorMsg);

  return {
    isLotTrackingEnabled,
    isFefoEnabled,
    lotNumberInputValue,
    expirationDateInputValue,
    lotErrorMsg,
    expirationDateErrorMsg,
    messages,
    disableButton,
    onLotChange,
    onExpirationDateChange,
    onMissingLotOrExpirationDate,
    onConfirm,
  };
};
