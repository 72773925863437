import * as React from "react";
import { useItemInspectionCard } from "./useItemInspectionCard";
import { Divider, Stack, Text, Button, Radio, Checkbox, BoxReset } from "@deliverr/ui";
import { FlowCard } from "facility-commons/flow";
import { PointerAnchor } from "facility-commons/components/emotion";
import { InspectionNotesButton } from "../InspectionNotesButton";
import { InspectionNotesType } from "../../types/InspectionNotesType";
import { ItemDetails } from "../ItemDetails";

export const ItemInspectionCard: React.FC = () => {
  const {
    barcode,
    dsku,
    itemName,
    inspectionRules,
    selectedCondition,
    messages,
    onConditionSelect,
    onViewStandardsClick,
    onSubmit,
  } = useItemInspectionCard();
  const conditions = Object.keys(inspectionRules);
  return (
    <>
      <ItemDetails dsku={dsku} scannedBarcode={barcode} itemName={itemName} />
      <BoxReset marginBottom="S2">
        <FlowCard title={messages.inspectionCardTitle}>
          {conditions.map((condition) => (
            <Radio
              type="radio"
              key={condition}
              value={condition}
              label={condition}
              onChange={() => {
                onConditionSelect(condition);
              }}
              checked={condition === selectedCondition}
            />
          ))}
          <Divider />
          <PointerAnchor onClick={onViewStandardsClick}>{messages.viewInspectionStandards}</PointerAnchor>
        </FlowCard>
      </BoxReset>
      <InspectionNotesButton noteType={InspectionNotesType.ITEM} />
      <Button block disabled={!selectedCondition} onClick={onSubmit} data-testid="submit-item-condition-button">
        {messages.submitButtonText}
      </Button>
    </>
  );
};
