import { atom } from "recoil";
import { WarehouseEmailStateKey } from "./WarehouseEmailStateKey";

interface WarehouseEmailState {
  warehouseEmail?: string;
}

export const warehouseEmailState = atom<WarehouseEmailState>({
  key: WarehouseEmailStateKey.WAREHOUSE_EMAIL,
  default: {},
});
