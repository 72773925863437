import React from "react";

import { ProductCard } from "warehouse/receiving/components/cards/ProductCard";
import { PositionedPillContainer, SkuPill } from "../pills";
import { useRecoilValue } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";

export const ReviewProducts = () => {
  const { expectedSkus, receivedSkus } = useRecoilValue(receivingState);

  return (
    <>
      {receivedSkus.map((product, i) => (
        <ProductCard topMargin={i !== 0} key={`sku-received-${product.dsku}`} {...product}>
          <PositionedPillContainer>
            <SkuPill skuNumber={i + 1} expectedSkus={expectedSkus} />
          </PositionedPillContainer>
        </ProductCard>
      ))}
    </>
  );
};
