import { useIntl } from "react-intl";
import { useEffect } from "react";
import { useRouter } from "warehouse/common/hooks";
import { useSetRecoilState } from "recoil";
import { setProp } from "facility-commons/utils";
import { useMount } from "react-use";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useLanguage } from "warehouse/base/useLanguage";
import { useWarehouseModal } from "warehouse/modal";
import { commonOutboundMessages } from "warehouse/rs-outbounds/content/commonOutboundsMessages";
import { OutboundsPath } from "warehouse/rs-outbounds/routes";

export const useOutboundOptions = () => {
  const { formatMessage } = useIntl();
  const { match } = useRouter();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { hideAllModals } = useWarehouseModal();
  const { language } = useLanguage();

  const { resetNotifications } = useCommonFlow();

  useEffect(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonOutboundMessages.outbounds)));
  }, [language, setWarehouseAppState, formatMessage]);

  useMount(() => {
    hideAllModals();
    setWarehouseAppState(setProp("pageSubtitle", ""));
    resetNotifications();
  });

  const orderPackaging = {
    description: formatMessage(commonOutboundMessages.orderPackagingDesc),
    title: formatMessage(commonOutboundMessages.orderPackaging),
    link: `${match.path}/${OutboundsPath.ORDER_PACKING}`,
  };

  return {
    orderPackaging,
  };
};
