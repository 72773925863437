import { useSetRecoilState, useResetRecoilState, RecoilState } from "recoil";
import { setProp, removeProp } from "facility-commons/utils/immutabilityUtils";
import { ModalGenericProps, ModalsState } from "./ModalTypes";

export function composeUseModal(modalsState: RecoilState<ModalsState>) {
  return function <T extends string>() {
    const setVisibleModals = useSetRecoilState(modalsState);
    const resetVisibleModals = useResetRecoilState(modalsState);

    function showModal<P extends ModalGenericProps>(modalType: T, props: P): void {
      setVisibleModals(setProp<T, ModalGenericProps>(modalType, props));
    }

    function hideModal(modalType: T): void {
      setVisibleModals(removeProp(modalType));
    }

    return {
      showModal,
      hideModal,
      hideAllModals: resetVisibleModals,
    };
  };
}
