import {
  ReturnReceiveItemInspectionState,
  returnReceiveItemInspectionStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "../../state";
import { ReturnStatus, ReturnType } from "@deliverr/returns-client";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { commonReceivingMessages, commonReturnReceiveMessage } from "warehouse/receiving/content";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useAsyncFn, useMount } from "react-use";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { UNKNOWN_ITEM } from "../../constants";
import { createReturnReceiveItemInspection } from "../returnReceiveCardCreators";
import { sumBy } from "lodash";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useInitializeReturnReceiveStates } from "../../hooks";
import { useIntl } from "react-intl";
import { useReturnReceiveFlow } from "../useReturnReceiveFlow";
import { useState } from "react";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";

export const useBarcodeScan = () => {
  const { formatMessage } = useIntl();
  const { initializeItemInspectionState } = useInitializeReturnReceiveStates();
  const { nextFlowCard } = useReturnReceiveFlow();
  const { showModal, hideModal } = useWarehouseModal();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { errorResponse, successResponse, resetNotifications } = useCommonFlow();
  const { returnsClient } = useClientsWithAuth();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const { orderId, orderStatus, trackingCode, returnType, expectedItems, inspectedItems } = useRecoilValue(
    returnReceiveReturnOrderStateAtom
  );
  const [itemInspectionState, setItemInspectionState] = useRecoilState<ReturnReceiveItemInspectionState>(
    returnReceiveItemInspectionStateAtom
  );
  const { barcode } = itemInspectionState;
  const [barcodeError, setBarcodeError] = useState("");

  const numExpectedItems = sumBy(expectedItems, "quantity");
  const numItemsInspected = sumBy(inspectedItems, "quantity");
  const numItemsLeftToInspect = Math.max(numExpectedItems - numItemsInspected, 0);

  useMount(() => {
    setWarehouseAppState(setProp("pageSubtitle", trackingCode));
    initializeItemInspectionState();
  });

  const showExpectedItemsModal = () => {
    showModal(WarehouseModal.RETURN_RECEIVE_EXPECTED_ITEMS, {});
  };

  const showCloseReturnOrderModal = () => {
    showModal(WarehouseModal.RETURN_RECEIVE_CLOSE_RETURN_ORDER, {});
  };

  const onMissingBarcodeClick = () => {
    showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
      title: formatMessage(commonReceivingMessages.missingBarcodePrompt),
      onConfirm: () => {
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
        setItemInspectionState(setProp("barcode", ""));
        handleBarcodeSubmit(UNKNOWN_ITEM);
      },
      onCancel: () => {
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
      },
    });
  };

  const [submitState, handleBarcodeSubmit] = useAsyncFn(
    async (barcodeInput: string) => {
      setBarcodeError("");

      if (!barcodeInput.length) {
        errorResponse();
        return setBarcodeError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      }

      const ctx = logStart({
        fn: "useBarcodeScan.handleBarcodeSubmit",
        barcodeInput,
        facilityId,
        orderId,
        returnType,
      });

      const onSuccess = () => {
        successResponse();
        resetNotifications();
        nextFlowCard(createReturnReceiveItemInspection({}));
      };

      try {
        const { value } = await returnsClient.startReturnItemInspection(orderId!, barcodeInput, facilityId);

        const { product, inspectionConfig, unexpectedItem } = value;
        const { dsku, name, sellerId, isLotTrackingEnabled, isFefoEnabled } = product;
        const { sop, rules } = inspectionConfig;

        setItemInspectionState((currentState) => ({
          ...currentState,
          dsku,
          itemName: name,
          sellerId,
          isLotTrackingEnabled,
          isFefoEnabled,
          isUnexpectedItem: unexpectedItem,
          inspectionSOPName: sop.name,
          inspectionSteps: sop.inspectionSteps,
          inspectionRules: rules,
        }));

        if (unexpectedItem && barcodeInput !== UNKNOWN_ITEM && returnType !== ReturnType.BLIND) {
          return showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
            title: formatMessage(commonReturnReceiveMessage.unexpectedItemWarningTitle),
            text: formatMessage(commonReturnReceiveMessage.unexpectedItemWarningText, { barcode: barcodeInput }),
            onConfirm: () => {
              hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
              onSuccess();
            },
            onCancel: () => {
              setItemInspectionState(setProp("barcode", ""));
              hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
            },
          });
        }

        onSuccess();
      } catch (error: any) {
        if (error.code === "NOT_FOUND") {
          return showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
            title: formatMessage(commonReturnReceiveMessage.unknownItemTitle),
            text: formatMessage(commonReturnReceiveMessage.unknownItemText, { barcode: barcodeInput }),
            onConfirm: () => {
              hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
              handleBarcodeSubmit(UNKNOWN_ITEM);
            },
            onCancel: () => {
              hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
              setItemInspectionState(setProp("barcode", ""));
            },
          });
        }
        log({ ...ctx, error }, "return receive barcode scan error");
        errorResponse();
        setItemInspectionState(setProp("barcode", ""));
        setBarcodeError(error.message);
      }
    },
    [barcode, facilityId, orderId]
  );

  const updateBarcode = (barcode: string) => {
    setBarcodeError("");
    setItemInspectionState(setProp("barcode", barcode));
  };

  const handleChange = genericOnScannerInputChange(barcode, updateBarcode, handleBarcodeSubmit);

  const messages = {
    illustrationTitle: formatMessage(commonReturnReceiveMessage.barcodeScanIllustrationTitle),
    message: formatMessage(commonReturnReceiveMessage.barcodeScanMessage),
    placeholder: formatMessage(commonReturnReceiveMessage.barcodeScanPlaceholder),
    title: formatMessage(commonReturnReceiveMessage.barcodeScanTitle),
    numItemsInspected: formatMessage(commonReturnReceiveMessage.numItemsInspected, { numItemsInspected }),
    numItemsLeftToInspect: formatMessage(commonReturnReceiveMessage.numItemsLeftToInspect, { numItemsLeftToInspect }),
    missingBarcodePrompt: formatMessage(commonReceivingMessages.missingBarcodePrompt),
    closeReturnOrder: formatMessage(commonReturnReceiveMessage.closeReturnOrder),
  };

  const showCloseReturnOrderButton = orderStatus !== ReturnStatus.PROCESSED && numItemsInspected > 0;

  return {
    barcode,
    isSubmitting: submitState.loading,
    barcodeError,
    messages,
    returnType,
    numItemsLeftToInspect,
    showCloseReturnOrderButton,
    showExpectedItemsModal,
    showCloseReturnOrderModal,
    onMissingBarcodeClick,
    handleChange,
    handleBarcodeSubmit,
  };
};
