import { useIntl } from "react-intl";
import { modalsText } from "../modalsText";
import { commonMessages } from "facility-commons/labels";

export interface IncorrectBoxQtyModalProps {
  boxesQty: number;
  fewerBoxes: boolean;
  unitsQty: number;
}

export const useIncorrectBoxAndUnitQtyModal = (props: IncorrectBoxQtyModalProps) => {
  const { formatMessage } = useIntl();
  const { boxesQty, fewerBoxes, unitsQty } = props;

  const box = formatMessage(commonMessages.box, {
    boxesQty,
  });

  const unit = formatMessage(commonMessages.unit, {
    unitsQty,
  });

  const title = fewerBoxes
    ? formatMessage(modalsText.incorrectUnitAndFewerBoxesCountTitle)
    : formatMessage(modalsText.incorrectUnitAndMoreBoxesCountTitle);

  const text = fewerBoxes
    ? formatMessage(modalsText.incorrectUnitAndFewerBoxesCountText, { box, unit })
    : formatMessage(modalsText.moreBoxesText);

  const buttonText = fewerBoxes ? formatMessage(modalsText.continue) : formatMessage(modalsText.receiveBoxes);

  return {
    title,
    text,
    buttonText,
    formatMessage,
  };
};
