import React, { forwardRef } from "react";
import { Box, BoxProps, DefaultTheme, IconV2, Input, InputProps, LocalizedMessage, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { warningModalIconSm } from "facility-commons";

export interface NumericInputProps extends InputProps {
  warningMessage?: LocalizedMessage;
}

// Placeholder moves to label after a value is entered, causing a visual jump.
// This allows us to avoid that visual jump.
const InputContainer = styled.div<{ applyLabelMargin: boolean }, DefaultTheme>(
  ({ applyLabelMargin, theme }) => `
  ${applyLabelMargin ? "margin-top: 11px;" : ""}
  margin-bottom: ${theme.spacing.S4}
`
);

// move input values to the end, but keep placeholder/label left aligned
const RightAlignedInput = styled(Input)<ThemeProps<{ hasWarning: boolean }>>(
  ({ hasWarning, theme }) => `
  padding: ${theme.spacing.S3} ${theme.spacing.S4};
  text-align: end;
  ${hasWarning ? `border: ${theme.border.width.B1} solid ${theme.colors.YELLOW["300"]};` : ""}

  ::placeholder {
    text-align: start;
  }
  `
);

const RightAlignedInputLabel = styled.label<ThemeProps>(
  ({ theme }) => `
color: ${theme.colors.YELLOW["300"]};
`
);

const WarningBox = styled(Box)<ThemeProps<BoxProps>>(
  ({ theme }) => `
    color: ${theme.colors.YELLOW["300"]};
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.S2};
    font-size: ${theme.font.size.F2}
  `
);

export const NumericInput: React.FC<NumericInputProps & React.RefAttributes<HTMLInputElement>> = forwardRef(
  ({ onChange, placeholder, warningMessage, value, ...props }, ref) => {
    const onChangeNumberOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = Number(event.target.value);
      if (!isNaN(eventValue)) {
        // allow where ever this component is rendered to handle the event
        onChange?.(event);
      }
    };

    return (
      <InputContainer applyLabelMargin={value === 0}>
        <RightAlignedInput
          {...props}
          inputMode="numeric"
          label={
            !value ? undefined : !!warningMessage ? (
              <RightAlignedInputLabel>{placeholder}</RightAlignedInputLabel>
            ) : (
              placeholder
            )
          }
          onChange={onChangeNumberOnly}
          placeholder={placeholder}
          ref={ref}
          value={value || ""} // hide zero value
          hasWarning={!!warningMessage}
        />
        {warningMessage && (
          <WarningBox>
            <IconV2 {...warningModalIconSm} />
            {warningMessage}
          </WarningBox>
        )}
      </InputContainer>
    );
  }
);
