import { useRecoilValue } from "recoil";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";
import { useLifecycles } from "react-use";
import { RSOutboundsFlowButtonType } from "warehouse/common/flow/types/RSOutboundsFlowButtonType";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";

export const usePendingItemsCard = () => {
  const { pendingItemsMap } = useRecoilValue(shipmentItemsSelector);
  const { hideAllFlowButtons, showFlowButtons } = useWarehouseFlow();
  const { resetNotifications } = useCommonFlow();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButtons(RSOutboundsFlowButtonType.RS_OUTBOUNDS_PENDING_ITEMS_BACK);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  return {
    pendingItemsMap,
  };
};
