import { Button, Pill, ThemeProps, Text, LocalizedMessage } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { dangerModalIconXl, ModalTemplate } from "facility-commons";
import { FormattedMessage } from "react-intl";
import { PointerAnchor } from "facility-commons/components/emotion";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";

export interface AutoresolveNonComplianceModalProps {
  title: LocalizedMessage;
  instructions: LocalizedMessage;
  continueMessage: LocalizedMessage;
  onContinue: () => Promise<void>; // continue with ticket creation
  onResolution: () => void; // PO# provided fixed the issue
  generatedAsn: string | number;
}

const MarginText = styled(Text)<ThemeProps>`
  margin-bottom: ${({ theme }) => theme.spacing.S2};
`;

const MarginButton = styled(Button)<ThemeProps>`
  margin-top: ${({ theme }) => theme.spacing.S5};
  margin-bottom: ${({ theme }) => theme.spacing.S4};
`;

const PaddedPill = styled(Pill)<ThemeProps>`
  letter-spacing: normal;
  padding: ${({ theme }) => `${theme.spacing.S1} ${theme.spacing.S2}`};
`;

export const AutoresolveNonComplianceModal: React.FC<AutoresolveNonComplianceModalProps> = ({
  title,
  instructions,
  continueMessage,
  onContinue,
  onResolution,
  generatedAsn,
}) => {
  return (
    <ModalTemplate
      iconProps={dangerModalIconXl}
      title={title}
      content={
        <>
          <MarginText textAlign={TextAlign.center}>{instructions}</MarginText>
          <PaddedPill color="GRAY">
            <Text bold>
              <FormattedMessage
                id="warehouse.autoresolveNonComplianceModal.po"
                defaultMessage="PO {number} {generatedAsn}"
                values={{ number: "#", generatedAsn }}
              />
            </Text>
          </PaddedPill>
        </>
      }
    >
      <div>
        <MarginButton block onClick={onResolution}>
          <FormattedMessage
            id="warehouse.autoresolveNonComplianceModal.solved"
            defaultMessage="This solved my problem"
          />
        </MarginButton>
        <PointerAnchor onClick={onContinue}>{continueMessage}</PointerAnchor>
      </div>
    </ModalTemplate>
  );
};
