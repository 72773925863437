import { logStart } from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

export interface SkusWarningModalProps {
  expectedSkus: number;
  receivedSkus: number;
  proceedToReview: () => void;
}

export const useSkusWarningModal = (props: SkusWarningModalProps) => {
  const { expectedSkus, receivedSkus, proceedToReview } = props;

  const { hideModal } = useWarehouseModal();

  const hideSkusWarningModal = () => hideModal(WarehouseModal.RECEIVING_UNEXPECTED_SKUS);

  const handleModalClickContinue = async () => {
    const ctx = { fn: "SkusWarningModal.handleModalClickContinue", props };
    logStart(ctx);

    proceedToReview();
    hideSkusWarningModal();
  };

  const skusMissing = expectedSkus - receivedSkus;

  return {
    handleModalClickContinue,
    hideSkusWarningModal,
    skusMissing,
  };
};
