import { createFlowCard } from "facility-commons/flow";
import { ReturnReceiveFlowCardType } from "../types/ReturnReceiveFlowCardType";

export const createReturnReceiveTrackingCodeScan = createFlowCard<{}>(ReturnReceiveFlowCardType.TRACKING_CODE_SCAN);

export const createReturnReceiveBarcodeScan = createFlowCard<{}>(ReturnReceiveFlowCardType.BARCODE_CODE_SCAN);

export const createReturnReceiveLotExpiryInput = createFlowCard<{}>(ReturnReceiveFlowCardType.LOT_EXPIRY_INPUT);

export const createReturnReceiveItemInspection = createFlowCard<{}>(ReturnReceiveFlowCardType.ITEM_INSPECTION);

export const createReturnReceiveLocationScan = createFlowCard<{}>(ReturnReceiveFlowCardType.LOCATION_SCAN);

export const createReturnReceiveRtmBoxScan = createFlowCard<{}>(ReturnReceiveFlowCardType.RTM_BOX_SCAN);

export const createReturnReceiveSortToDispositionValidLocation = createFlowCard<{}>(
  ReturnReceiveFlowCardType.SORT_TO_DISPOSITION_VALID_LOCATION
);
