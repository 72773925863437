import { useRouter } from "facility-commons/hooks";
import { ArrivalScannerRoutes } from "warehouse/routes";
import { useIntl } from "react-intl";
import { arrivalScanMessages } from "../arrivalScanMessage";

export const useArrivalScannerTypeSelectorCard = () => {
  const { formatMessage } = useIntl();
  const { match } = useRouter();

  const palletArrivalScan = {
    title: formatMessage(arrivalScanMessages.pallets),
    description: formatMessage(arrivalScanMessages.palletArrivalScanDescription),
    link: `${match.path}/${ArrivalScannerRoutes.PALLET}`,
  };

  const boxArrivalScan = {
    title: formatMessage(arrivalScanMessages.boxes),
    description: formatMessage(arrivalScanMessages.boxArrivalScanDescription),
    link: `${match.path}/${ArrivalScannerRoutes.BOX}`,
  };

  return { palletArrivalScan, boxArrivalScan };
};
