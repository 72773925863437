import React from "react";
import styled from "@emotion/styled";
import { FACILITY_THEME } from "facility-commons/theme";
import { DefaultTheme, IconV2 } from "@deliverr/ui";
import { formatDuration } from "facility-commons/utils/formatDuration";

interface Props {
  seconds: number;
}

const TimerContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  margin-bottom: ${FACILITY_THEME.spacing.S1};
  position: absolute;
  top: ${theme.spacing.S4};
  left: ${theme.spacing.S4};
`
);

const TimerText = styled.span`
  margin-left: ${FACILITY_THEME.spacing.S1};
  color: ${FACILITY_THEME.colors.NEUTRAL[200]};
`;

export const FlowCardTimer: React.FC<Props> = ({ seconds }) => (
  <TimerContainer>
    <div className="fa-layers fa-fw">
      <IconV2 type="loading" color={FACILITY_THEME.colors.BLUE[300]} fixedWidth spin />
    </div>
    <TimerText>{formatDuration(seconds)}</TimerText>
  </TimerContainer>
);
