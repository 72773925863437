import { useEffect, useRef } from "react";

// forces focus to an input element on init and after clear
export function useAutoFocus(inputValue: string, autoFocus: boolean = true) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputValue.length === 0 && autoFocus) {
      // bit of a hack since we lose focus during print pdf
      setTimeout(() => ref?.current?.focus(), 250);
    }
  }, [inputValue, autoFocus]);
  return ref;
}
