import { IconV2Props } from "@deliverr/ui";
import { getAccentColorValue } from "facility-commons/flow/AccentColor";

export const warningModalIcon: IconV2Props = {
  type: "exclamation-triangle",
  color: getAccentColorValue("YELLOW"),
  size: "2x",
};

export const warningModalIconSm: IconV2Props = {
  type: "exclamation-triangle",
  color: getAccentColorValue("YELLOW"),
  size: "medium",
};

export const warningModalIconLg: IconV2Props = {
  type: "exclamation-triangle",
  color: getAccentColorValue("YELLOW"),
  size: "3x",
};

export const successModalIcon: IconV2Props = {
  type: "check-circle",
  color: getAccentColorValue("GREEN"),
  size: "2x",
};

export const successModalIconLg: IconV2Props = {
  type: "check-circle",
  color: getAccentColorValue("GREEN"),
  size: "3x",
};

export const infoModalIcon: IconV2Props = {
  type: "info-circle",
  color: getAccentColorValue("BLUE"),
  size: "3x",
};

export const warningModalCircleIcon: IconV2Props = {
  type: "exclamation-circle",
  color: getAccentColorValue("YELLOW"),
  size: "3x",
};

export const questionModalIcon: IconV2Props = {
  type: "question-circle",
  color: getAccentColorValue("BLUE"),
  size: "2x",
};

export const questionModalIconLg: IconV2Props = {
  type: "question-circle",
  color: getAccentColorValue("BLUE"),
  size: "3x",
};

export const dangerModalIcon: IconV2Props = {
  type: "exclamation-circle",
  color: getAccentColorValue("RED"),
  size: "2x",
};

export const dangerModalIconLg: IconV2Props = {
  type: "exclamation-circle",
  color: getAccentColorValue("RED"),
  size: "3x",
};

export const dangerModalIconXl: IconV2Props = {
  type: "exclamation-circle",
  color: getAccentColorValue("RED"),
  size: "4x",
};

export const loadingModalIconXl: IconV2Props = {
  type: "loading",
  color: getAccentColorValue("BLUE"),
  size: "4x",
  spin: true,
};
