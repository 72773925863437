import { Button } from "@deliverr/ui";
import React from "react";
import { FormProvider, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useIntl } from "react-intl";

import { LoadingSpinner, TooltipWrapper } from "facility-commons/components";
import { maxCDSKULength } from "facility-commons/utils/config";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormInput,
  WarehouseFormSubTitle,
  WarehouseFormTooltip,
  WarehouseFormLabel,
  WarehouseFormInputGroup,
  WarehouseFormText,
  WarehouseFormPhotoGridTitle,
  WarehouseFormOtherDetails,
  WarehouseFormTextArea,
  WarehouseFormSelect,
} from "facility-commons/components/emotion";
import { OtherNonComplianceField } from "./OtherNonComplianceForm.types";
import { useOtherNonComplianceForm } from "./useOtherNonComplianceForm";
import { ticketFormContent } from "../../ticketFormContent";
import { validationConfig } from "./OtherNonComplianceFormConfig";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";

export const OtherNonComplianceForm: React.FC = () => {
  const {
    preScannedCdsku,
    errors,
    formHandler,
    nonComplianceReasonOptions,
    onFormSubmit,
    preventSubmit,
    register,
    warehouseEmail,
  } = useOtherNonComplianceForm();
  const { formatMessage } = useIntl();
  const { control } = formHandler;

  return (
    <FormProvider {...formHandler}>
      <FacilityCard>
        <WarehouseForm onSubmit={onFormSubmit}>
          <WarehouseFormSubTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.subtitle)}
          </WarehouseFormSubTitle>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.TRACKING_CODE}>
              {formatMessage(ticketFormContent.trackingCode)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OtherNonComplianceField.TRACKING_CODE]}
              id={OtherNonComplianceField.TRACKING_CODE}
              placeholder={formatMessage(ticketFormContent.trackingCodePlaceholder)}
              hasError={!!errors[OtherNonComplianceField.TRACKING_CODE]}
              errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.TRACKING_CODE} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.BARCODE}>
              {formatMessage(ticketFormContent.barcode)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OtherNonComplianceField.BARCODE]}
              id={OtherNonComplianceField.BARCODE}
              placeholder={formatMessage(ticketFormContent.barcodePlaceholder)}
              hasError={!!errors[OtherNonComplianceField.BARCODE]}
              errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.BARCODE} />}
            />
          </WarehouseFormInputGroup>

          {preScannedCdsku && (
            <WarehouseFormInputGroup>
              <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.CDSKU}>
                {formatMessage(ticketFormContent.cdsku)}
              </WarehouseFormLabel>
              <WarehouseFormInput
                {...register[OtherNonComplianceField.CDSKU]}
                placeholder={formatMessage(ticketFormContent.cdskuPlaceholder)}
                id={OtherNonComplianceField.CDSKU}
                maxLength={maxCDSKULength}
                hasError={!!errors[OtherNonComplianceField.CDSKU]}
                errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.CDSKU} />}
                readOnly={!!preScannedCdsku}
              />
            </WarehouseFormInputGroup>
          )}

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.QUANTITY}>
              {formatMessage(ticketFormContent.nonCompliantQuantity)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OtherNonComplianceField.QUANTITY]}
              id={OtherNonComplianceField.QUANTITY}
              type="number"
              placeholder={formatMessage(ticketFormContent.quantityPlaceholder)}
              hasError={!!errors[OtherNonComplianceField.QUANTITY]}
              errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.QUANTITY} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO">{formatMessage(ticketFormContent.reasonRequired)}</WarehouseFormLabel>

            <Controller
              name={OtherNonComplianceField.REASON}
              control={control}
              render={({ field: { value, onChange, onBlur, name, ref } }) => (
                <WarehouseFormSelect
                  id={OtherNonComplianceField.REASON}
                  placeholder={formatMessage(ticketFormContent.reasonPlaceholder)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  forwardRef={ref}
                  options={nonComplianceReasonOptions}
                  rules={validationConfig[OtherNonComplianceField.REASON]}
                  hasError={!!errors[OtherNonComplianceField.REASON]}
                  errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.REASON} />}
                />
              )}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.ADDITIONAL_DETAILS}>
              {formatMessage(ticketFormContent.ncAdditionalDetails)}
            </WarehouseFormLabel>
            <WarehouseFormTextArea
              {...register[OtherNonComplianceField.ADDITIONAL_DETAILS]}
              id={OtherNonComplianceField.ADDITIONAL_DETAILS}
              placeholder={formatMessage(ticketFormContent.ncAdditionalDetailsPlaceholder)}
            />
          </WarehouseFormOtherDetails>

          <WarehouseFormPhotoGridTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.photosSubtitle)}
          </WarehouseFormPhotoGridTitle>

          <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessage)}</WarehouseFormText>

          <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OtherNonComplianceField.FRONT_PHOTO]}
                labelText={formatMessage(ticketFormContent.frontPhoto)}
                id={OtherNonComplianceField.FRONT_PHOTO}
              />
              <WarehouseFormTooltip active={Boolean(errors[OtherNonComplianceField.FRONT_PHOTO])}>
                <ErrorMessage errors={errors} name={OtherNonComplianceField.FRONT_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OtherNonComplianceField.BACK_PHOTO]}
                labelText={formatMessage(ticketFormContent.backPhoto)}
                id={OtherNonComplianceField.BACK_PHOTO}
              />
              <WarehouseFormTooltip active={Boolean(errors[OtherNonComplianceField.BACK_PHOTO])}>
                <ErrorMessage errors={errors} name={OtherNonComplianceField.BACK_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OtherNonComplianceField.BARCODE_PHOTO]}
                labelText={formatMessage(ticketFormContent.barcodePhoto)}
                id={OtherNonComplianceField.BARCODE_PHOTO}
              />
              <WarehouseFormTooltip active={Boolean(errors[OtherNonComplianceField.BARCODE_PHOTO])}>
                <ErrorMessage errors={errors} name={OtherNonComplianceField.BARCODE_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[OtherNonComplianceField.BOXLABEL_PHOTO]}
                labelText={formatMessage(ticketFormContent.boxContentPhoto)}
                id={OtherNonComplianceField.BOXLABEL_PHOTO}
              />
              <WarehouseFormTooltip active={Boolean(errors[OtherNonComplianceField.BOXLABEL_PHOTO])}>
                <ErrorMessage errors={errors} name={OtherNonComplianceField.BOXLABEL_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>
          </WarehouseFormImageGrid>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={OtherNonComplianceField.EMAIL}>
              {formatMessage(ticketFormContent.email)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[OtherNonComplianceField.EMAIL]}
              id={OtherNonComplianceField.EMAIL}
              helpText={formatMessage(ticketFormContent.emailMessage, { warehouseEmail })}
              hasError={!!errors[OtherNonComplianceField.EMAIL]}
              errorMessage={<ErrorMessage errors={errors} name={OtherNonComplianceField.EMAIL} />}
            />
          </WarehouseFormOtherDetails>

          <Button block type="submit" disabled={preventSubmit}>
            <LoadingSpinner loading={preventSubmit} size="lg">
              {formatMessage(ticketFormContent.submit)}
            </LoadingSpinner>
          </Button>
        </WarehouseForm>
      </FacilityCard>
    </FormProvider>
  );
};
