import React, { useEffect, useState } from "react";
import { Location } from "history";
import { Prompt } from "react-router-dom";

import { useWarehouseModal, WarehouseModal } from "../../../modal";
import { useRouter } from "facility-commons/hooks";
import { useRecoilValue } from "recoil";
import { isMidFlowState } from "warehouse/receiving/ReceivingState";

/*
  This components purpose is to use routers Prompt component to block exit transition from
  the Receiving app. By returning false to the message prop, we block transition and use the same handler func
  to show our modal. The next location the user was going to is captured in the event handler
  then used to redirect the user there once they confirm they are okay with leaving receiving (and losing data)
*/
export const RouteGuard: React.FC = () => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);
  const router = useRouter();

  const { hideModal, showModal } = useWarehouseModal();

  const isMidFlow = useRecoilValue(isMidFlowState);
  // once the user has confirmed exit navigation redirect them to wherever they were going
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      router.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, router, lastLocation]);

  const closeWarningModal = () => hideModal(WarehouseModal.RECEIVING_EXIT_APP_WARNING);

  const handleConfirmNavigationClick = () => {
    closeWarningModal();
    setConfirmedNavigation(true);
  };

  const showWarningModal = () =>
    showModal(WarehouseModal.RECEIVING_EXIT_APP_WARNING, {
      onCancel: closeWarningModal,
      onConfirm: handleConfirmNavigationClick,
    });

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && !nextLocation.pathname.includes("receiving")) {
      showWarningModal();
      setLastLocation(nextLocation);
      return false; // prevents browser prompt from appearing + allows usage of modal + blocks route transition
    }
    return true; // allow route transition to happen after confirmation
  };

  return <Prompt when={isMidFlow} message={handleBlockedNavigation} />;
};
