import { defineMessages, MessageDescriptor } from "react-intl";

export enum ExistingAssociationErrorMessageKey {
  EXISTING_ASSOCIATION = "EXISTING_ASSOCIATION",
}

export const EXISTING_ASSOCIATION_ERROR_BY_ERROR_CODE: Record<
  ExistingAssociationErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [ExistingAssociationErrorMessageKey.EXISTING_ASSOCIATION]: {
    id: "warehouse.receiving.existingAssociationError",
    defaultMessage: `The following containers have already been associated with another {containerType}: {containers}.`,
  },
});
