import { MenuLinkConfig } from "facility-commons/components/LinksMenu";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { MoreLinksNames } from "./MoreLinks";
import { MoreRoutesNames } from "./MoreRoutes";
import { use3PLEmbeddedDashboard } from "./ThreePLEmbeddedDashboard";
import { MetabaseWarehouseDashboard } from "@deliverr/legacy-inbound-client/lib/MetabaseWarehouseDashboard";

export const useMoreDashboards = (warehouseId: string) => {
  const { formatMessage } = useIntl();
  const {
    embeddedDashboardUrl: opsDashboardUrl,
    embeddedDashboardLoading: opsDashboardLoading,
    embeddedDashboardError: opsDashboardError,
  } = use3PLEmbeddedDashboard(warehouseId!, MetabaseWarehouseDashboard.DAILY_OPS);

  const {
    embeddedDashboardUrl: forecastingDashboardUrl,
    embeddedDashboardLoading: forecastingDashboardLoading,
    embeddedDashboardError: forecastingDashboardError,
  } = use3PLEmbeddedDashboard(warehouseId!, MetabaseWarehouseDashboard.FORECASTING);

  const {
    embeddedDashboardUrl: operatingMetricsDashboardUrl,
    embeddedDashboardLoading: operatingMetricsDashboardLoading,
    embeddedDashboardError: operatingMetricsDashboardError,
  } = use3PLEmbeddedDashboard(warehouseId!, MetabaseWarehouseDashboard.OPERATING_METRICS);

  const dashboardLinks = useMemo(() => {
    const links: MenuLinkConfig<MoreLinksNames>[] = [];
    if (opsDashboardUrl) {
      links.push({
        text: (
          <FormattedMessage
            id="warehouse.moreMenu.dailyOperationsDashboard"
            defaultMessage="Daily Operations Dashboard"
          />
        ),
        route: MoreRoutesNames.DAILY_OPS_DASHBOARD,
        props: {
          embeddedDashboardUrl: opsDashboardUrl,
          dashboardTitle: formatMessage({
            id: "warehouse.moreMenu.dailyOperationsDashboard",
            defaultMessage: "Daily Operations Dashboard",
          }),
          error: opsDashboardLoading,
          loading: opsDashboardError,
        },
        id: MoreLinksNames.DASHBOARD,
      });
    }
    if (forecastingDashboardUrl) {
      links.push({
        text: (
          <FormattedMessage
            id="warehouse.moreMenu.forecastingPlanningDashboard"
            defaultMessage="Forecasting & Planning Dashboard"
          />
        ),
        route: MoreRoutesNames.FORECASTING_PLANNING_DASHBOARD,
        props: {
          embeddedDashboardUrl: forecastingDashboardUrl,
          dashboardTitle: formatMessage({
            id: "warehouse.moreMenu.forecastingPlanningDashboard",
            defaultMessage: "Forecasting & Planning Dashboard",
          }),
          error: forecastingDashboardLoading,
          loading: forecastingDashboardError,
        },
        id: MoreLinksNames.DASHBOARD,
      });
    }
    if (operatingMetricsDashboardUrl) {
      links.push({
        text: (
          <FormattedMessage
            id="warehouse.moreMenu.operatingMetricsDashboard"
            defaultMessage="Operating Metrics Dashboard"
          />
        ),
        route: MoreRoutesNames.OPS_METRICS_DASHBOARD,
        props: {
          embeddedDashboardUrl: operatingMetricsDashboardUrl,
          dashboardTitle: formatMessage({
            id: "warehouse.moreMenu.operatingMetricsDashboard",
            defaultMessage: "Operating Metrics Dashboard",
          }),
          error: operatingMetricsDashboardLoading,
          loading: operatingMetricsDashboardError,
        },
        id: MoreLinksNames.DASHBOARD,
      });
    }
    return links;
  }, [
    opsDashboardUrl,
    opsDashboardLoading,
    opsDashboardError,
    forecastingDashboardUrl,
    forecastingDashboardLoading,
    forecastingDashboardError,
    operatingMetricsDashboardUrl,
    operatingMetricsDashboardLoading,
    operatingMetricsDashboardError,
    formatMessage,
  ]);

  return {
    dashboardLinks,
  };
};
