import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { last } from "lodash";
import { useRecoilValue } from "recoil";
import React from "react";
import { FlowCardsStateProps } from "./types/FlowState";
import { FLOW_CARD_CONTAINER_SECTION_WIDTH } from "./styleValues";

const FlowCardContainer = styled.div<Record<string, unknown>, DefaultTheme>`
  width: 100%;
  max-width: 30rem;
  & section {
    height: 254px;
    width: ${FLOW_CARD_CONTAINER_SECTION_WIDTH};
  }
  & .noDefinedHeight {
    height: auto;
  }
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.S3};
  }
`;

export const FlowVisibleCardsV2: React.FC<FlowCardsStateProps> = ({
  cardsState,
  cardMap,
  showMultipleCards = false,
}) => {
  const flowCards = useRecoilValue(cardsState);

  if (flowCards.length < 1) {
    return null;
  }

  const renderMultipleFlowCards = () => {
    return flowCards.map(({ props, type }, index) => {
      const FlowCard = cardMap[type];

      return <FlowCard key={index} {...props} />;
    });
  };

  const renderSingleFlowCard = () => {
    const flowCard = last(flowCards);
    const { props, type } = flowCard!;
    const FlowCard = cardMap[type];

    return <FlowCard {...props} newest={true} key={flowCard} />;
  };

  return (
    <FlowCardContainer>{showMultipleCards ? renderMultipleFlowCards() : renderSingleFlowCard()}</FlowCardContainer>
  );
};
