import styled from "@emotion/styled";
import React from "react";
import { FlowVisibleCardsV2 } from "./FlowVisibleCardsV2";
import { FlowVisibleButtons } from "./FlowVisibleButtons";
import { FlowStateProps } from "./types/FlowState";
import { flowCardMap } from "./maps/flowCardMap";
import { flowButtonMap } from "./maps/flowButtonMap";
import { FlowCardTimer } from "./FlowCardTimer";
import { useRecoilValue } from "recoil";
import { FlowVisibleNotifications } from "./FlowVisibleNotifications";
import { notificationsState, timerState } from "./flowState";

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FlowContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: max-content;
`;

const CardContainer = styled.div`
  position: relative;
`;

const BottomContainer = styled.div`
  width: 100%;
`;

export const FlowV2: React.FC<FlowStateProps> = (props) => {
  const { buttonMap, cardMap, cardsState, showMultipleCards, visibleButtons } = props;

  const { visible, seconds } = useRecoilValue(timerState);
  const notifications = useRecoilValue(notificationsState);
  // Merge application-level maps with base-level
  const combinedCardMap = { ...flowCardMap, ...cardMap };
  const combinedButtonMap = { ...flowButtonMap, ...buttonMap };

  return (
    <CenterContainer>
      <FlowContainer>
        <CardContainer>
          <FlowVisibleCardsV2 cardsState={cardsState} cardMap={combinedCardMap} showMultipleCards={showMultipleCards} />
          {visible && <FlowCardTimer seconds={seconds} />}
        </CardContainer>
        <BottomContainer>
          <FlowVisibleNotifications notifications={notifications.slice(-1)} />
          <FlowVisibleButtons visibleButtons={visibleButtons} buttonMap={combinedButtonMap} />
        </BottomContainer>
      </FlowContainer>
    </CenterContainer>
  );
};
