import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Stack } from "@deliverr/ui";
import { TextInputCard } from "facility-commons/flow";
import { FACILITY_COMMONS_BUTTON_LABELS } from "facility-commons";
import { FLOW_CARD_CONTAINER_SECTION_WIDTH } from "facility-commons/flow/styleValues";
import { STORAGE_ARRIVAL_SCAN_MESSAGES } from "warehouse/common/messages/storage";
import { useStorageArrivalScan } from "./useStorageArrivalScan";

const StyledPage = styled(Box)`
  height: 100%;
`;

const StyledCard = styled(Stack)`
  width: ${FLOW_CARD_CONTAINER_SECTION_WIDTH};
  justify-content: space-between;
  height: 100%;
`;

export const StorageArrivalScanCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const poAsnScanTitle = formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.boxTitle);
  const { scanState, scanError, handleChange, handleSubmit, handleSubmitClick, loading } = useStorageArrivalScan();

  return (
    <StyledPage>
      <StyledCard>
        <TextInputCard
          errorMessage={scanError}
          message={formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.boxDescription)}
          newest
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeholder={formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.boxPlaceholder)}
          loading={loading}
          title={poAsnScanTitle}
          value={scanState.asnId ?? ""}
        />
        <Button block onClick={handleSubmitClick} type="button">
          <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.submitButton} />
        </Button>
      </StyledCard>
    </StyledPage>
  );
};
