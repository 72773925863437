import React from "react";
import { useMount } from "react-use";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash/fp";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { StorageFlowV2 } from "warehouse/common/flow/StorageFlowV2";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createReceivingPOScan } from "./storageReceivingCardCreators";
import { DEFAULT_STORAGE_RECEIVE_STATE, storageInboundReceivingStateAtom } from "./StorageReceivingState";

export const StorageReceivingFlow: React.FC = () => {
  const { resetFlow } = useWarehouseFlow();
  const resetRTSState = useResetRecoilState(storageInboundReceivingStateAtom);
  const setReceivingState = useSetRecoilState(storageInboundReceivingStateAtom);

  const onUnmount = () => {
    resetRTSState();
    resetFlow();
  };

  useMount(() => {
    setReceivingState({
      ...cloneDeep(DEFAULT_STORAGE_RECEIVE_STATE),
      requestBatchId: uuid(),
      dedupKey: uuid(),
    });
  });

  return <StorageFlowV2 onMountCardData={createReceivingPOScan({})} onUnmount={onUnmount} />;
};
