import { defineMessages, MessageDescriptor } from "react-intl";

export enum PackageItemReceiveNotFoundErrorErrorMessageKey {
  PACKAGE_ITEM_RECEIVE_NOT_FOUND = "PACKAGE_ITEM_RECEIVE_NOT_FOUND",
}

export const PACKAGE_ITEM_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  PackageItemReceiveNotFoundErrorErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PackageItemReceiveNotFoundErrorErrorMessageKey.PACKAGE_ITEM_RECEIVE_NOT_FOUND]: {
    id: "warehouse.receiving.packageItemNotFOund",
    defaultMessage: "Could not find package receive information for requestId {requestId}",
  },
});
