import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface ConfirmExpiredProductReceiveModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmExpiredProductReceiveModal: React.FC<ConfirmExpiredProductReceiveModalProps> = ({
  onCancel,
  onConfirm,
}) => {
  const { formatMessage } = useIntl();

  const title = formatMessage(modalsText.confirmExpiredProductReceiveTitle);
  const message = formatMessage(modalsText.confirmExpiredProductReceiveText);

  return (
    <ModalTemplate buttonLayout="row" title={title} text={message} iconProps={warningModalIconLg}>
      <Grid gap="S2" fullWidth>
        <Button block data-testid="expired-sku-confirm-btn" onClick={onConfirm}>
          {formatMessage(modalsText.confirm)}
        </Button>
        <Button block onClick={onCancel} secondary>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
