import { useRouter } from "facility-commons/hooks";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { currentSkuState, receiveFlowTypeState } from "warehouse/receiving/ReceivingState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { isBoxReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";
import { ArrivalScannerRoutes, WarehousePortalRoutes, WarehousePortalRoutesNames } from "warehouse/routes";
import { arrivalScanMessages } from "warehouse/scan-arrival/arrivalScanMessage";
import { navbarText } from "./navBarText";
import { userState } from "facility-commons/base/Auth/userState";

// eslint-disable-next-line complexity
export const useWarehouseHeader = () => {
  const { history, pathname, push } = useRouter();
  const { pageTitle, pageSubtitle } = useRecoilValue(warehouseAppState);
  const currentPath = useLocation().pathname.split("/").slice(1); // [WAREHOUSE_CODE, 'non-compliance' or 'barcode' or 'damaged']
  const currentPage = currentPath[currentPath.length - 1]; // 'non-compliance' 'tickets' or 'barcode' or 'damaged'
  const { hideModal, showModal } = useWarehouseModal();
  const [receivingFlowType, setReceivingFlowType] = useRecoilState(receiveFlowTypeState);
  const initializeReceivingData = useInitializeReceivingData();
  const isBoxReceive = isBoxReceiveFlow(receivingFlowType);
  const { formatMessage } = useIntl();
  const { problemSolverFlowEnabled } = useRecoilValue(warehouseAppState);
  const { cdsku } = useRecoilValue(currentSkuState);
  const { isStorage } = useRecoilValue(userState);

  // These routes / partial routes should be excluded from any tabs
  const shouldHideTabs = pathname.includes(WarehousePortalRoutesNames.TICKET_CENTER_UNSOLVABLE_DETAILS);

  // Show the Overview or Resolution tabs for "detail" pages
  const showTicketDetailsTabs = !shouldHideTabs && pathname.startsWith(WarehousePortalRoutes.TICKET_BASE);

  // Show the tabs for switching between ticket lists by status (i.e. TODO)
  const showTicketListTabs =
    !shouldHideTabs &&
    !showTicketDetailsTabs &&
    pathname.startsWith(WarehousePortalRoutes.TICKET_CENTER) &&
    !pathname.startsWith(WarehousePortalRoutes.NEW_TICKET);

  // we need to check if curr page is CDSKU, it could be second page in pallet or first page of box receiving
  const showChangeMode =
    pathname === WarehousePortalRoutes.RECEIVING_PALLET ||
    pathname === WarehousePortalRoutes.CONSOLIDATION_PALLET ||
    (pathname === WarehousePortalRoutes.RECEIVING_CDSKU && isBoxReceive) ||
    pathname === WarehousePortalRoutes.RECEIVING_RETURN_ORDER;

  const isNewTicketRoute = currentPath[currentPath.length - 1] === WarehousePortalRoutesNames.NEW_TICKET;

  /**
   * Close icon should show up if -
   * - any page other than the first that will either be pallet or cdsku
   * - the current pathname is the new ticket route
   */
  const showCloseIcon =
    (!showChangeMode &&
      currentPath.length > 2 &&
      currentPath.includes(WarehousePortalRoutesNames.RECEIVING) &&
      !currentPath.includes(ReceivingPath.RECEIVING_DASHBOARD)) ||
    isNewTicketRoute;

  /**
   * This should show up while a user is doing receiving actions
   * but only for warehouses that are problem-solver-flow enabled
   * and when there is sufficient context to report an issue on a container
   */
  const shouldShowReportIssue =
    problemSolverFlowEnabled &&
    receivingFlowType === ReceivingFlowType.BOX_RECEIVING &&
    cdsku &&
    !currentPath.includes(ReceivingPath.REPORT_ISSUE) &&
    (showChangeMode || showCloseIcon) &&
    !isNewTicketRoute;

  // If we are in the ticket center we should show a "Create ticket" anchor
  const showCreateTicket =
    (pathname === WarehousePortalRoutes.TICKET_CENTER && !problemSolverFlowEnabled) ||
    (pathname === WarehousePortalRoutes.TICKET_CENTER && isStorage);

  const onChangeMode = () => {
    initializeReceivingData();
    setReceivingFlowType(null);
    history.push(WarehousePortalRoutes.RECEIVING);
  };
  const closeWarningModal = () => hideModal(WarehouseModal.RECEIVING_EXIT_APP_WARNING);

  const handleConfirmNavigationClick = () => {
    onChangeMode();
    closeWarningModal();
  };

  /**
   * onExit handler differs based on the route.
   * - If the route isn't the new ticket route, show modal
   * - If the route is the new ticket route, go back
   */
  const onExit = () =>
    !isNewTicketRoute
      ? showModal(WarehouseModal.RECEIVING_EXIT_APP_WARNING, {
          onCancel: closeWarningModal,
          onConfirm: handleConfirmNavigationClick,
        })
      : history.goBack();

  const onCreateTicket = () => history.push(WarehousePortalRoutes.NEW_TICKET);

  const warehouseAppBackButtonOnClick = () => {
    if (pathname === WarehousePortalRoutes.RECEIVING_SKU) {
      if (receivingFlowType === ReceivingFlowType.BOX_RECEIVING) {
        push(WarehousePortalRoutes.RECEIVING_CDSKU);
      } else if (receivingFlowType === ReceivingFlowType.CONSOLIDATION_RECEIVING) {
        push(WarehousePortalRoutes.CONSOLIDATION_PALLET);
      }
    } else {
      history.goBack();
    }
  };

  const onReportIssue = () => history.push(WarehousePortalRoutes.REPORT_ISSUE);

  const NavbarTitles: Record<string, string> = {
    [WarehousePortalRoutesNames.BARCODE]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.OTHER]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.DAMAGED_PRODUCT]: formatMessage(navbarText.damagedItem),
    [WarehousePortalRoutesNames.DAMAGE_PICKING]: formatMessage(navbarText.damagedItem),
    [WarehousePortalRoutesNames.DAMAGE_RECEIVING]: formatMessage(navbarText.damagedItem),
    [WarehousePortalRoutesNames.NEW_TICKET]: formatMessage(navbarText.createTicket),
    [WarehousePortalRoutesNames.NON_COMPLIANCE]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.NON_COMPLIANCE_SCAN]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.NON_COMPLIANCE_TYPE]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.STORAGE_NON_COMPLIANCE_FLOW]: formatMessage(navbarText.nonCompliance),
    [WarehousePortalRoutesNames.HAZMAT]: formatMessage(navbarText.hazmatLookup),
    [WarehousePortalRoutesNames.RECEIVING]: formatMessage(navbarText.receiving),
    [WarehousePortalRoutesNames.OUTBOUNDS]: formatMessage(navbarText.outbounds),
    [WarehousePortalRoutesNames.SCANNER]: formatMessage(navbarText.arrivalScan),
    [ArrivalScannerRoutes.PALLET]: formatMessage(arrivalScanMessages.palletArrivalScanTitle),
    [ArrivalScannerRoutes.BOX]: formatMessage(arrivalScanMessages.boxArrivalScanTitle),
    [WarehousePortalRoutesNames.TICKET_CENTER]: formatMessage(navbarText.tickets),
    [WarehousePortalRoutesNames.MORE]: formatMessage(navbarText.moreOptions),
    [WarehousePortalRoutesNames.DOWNLOAD_DHL_MANIFEST]: formatMessage(navbarText.DHLManifest),
    [WarehousePortalRoutesNames.OUTBOUND_CAPACITY]: formatMessage(navbarText.outboundCapacity),
    [WarehousePortalRoutesNames.RECEIVING_DASHBOARD]: formatMessage(commonReceivingMessages.receivingDashboard),
    [WarehousePortalRoutesNames.PO_NUMBER_LOOKUP]: formatMessage(navbarText.poLookup),
    [WarehousePortalRoutesNames.OPS_METRICS_DASHBOARD]: formatMessage(navbarText.opsMetricsDashboard),
    [WarehousePortalRoutesNames.STORAGE_OPS_METRICS_DASHBOARD]: formatMessage(navbarText.storageOpsMetricsDashboard),
    [WarehousePortalRoutesNames.DAILY_OPS_DASHBOARD]: formatMessage(navbarText.dailyOpsDashboard),
    [WarehousePortalRoutesNames.FORECASTING_PLANNING_DASHBOARD]: formatMessage(navbarText.forecastingPlanningDashboard),
    [WarehousePortalRoutesNames.GET_PRODUCT_ZONE]: formatMessage(navbarText.getProductZone),
  };

  const NavbarSubtitles: Record<string, string> = {
    [WarehousePortalRoutesNames.BARCODE]: formatMessage(navbarText.barcodeNotInWMS),
    [WarehousePortalRoutesNames.OTHER]: formatMessage(navbarText.other),
    [WarehousePortalRoutesNames.DAMAGE_PICKING]: formatMessage(navbarText.orderPicking),
    [WarehousePortalRoutesNames.DAMAGE_RECEIVING]: formatMessage(navbarText.receiving),
  };

  return {
    pageTitle,
    pageSubtitle,
    currentPath,
    currentPage,
    showTicketDetailsTabs,
    showTicketListTabs,
    NavbarTitles,
    NavbarSubtitles,
    history,
    showChangeMode,
    onChangeMode,
    showCloseIcon,
    onExit,
    showCreateTicket,
    onCreateTicket,
    warehouseAppBackButtonOnClick,
    shouldShowReportIssue,
    onReportIssue,
  };
};
