import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { TicketTabs } from "warehouse/ticket-center/components/TicketDetails/TicketTabs";
import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { ListViewCardProps } from "warehouse/ticket-center/components";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";

type FilterOptions = "ALL" | NonComplianceType;

export interface TicketCenterState {
  todoCount: number;
  currentTicketStatusDisplayed: TicketCenterNonComplianceCaseStatus;
  ticketTab: TicketTabs;
  ticketList: ListViewCardProps[];
  filteredTicketList: ListViewCardProps[];
  filter: FilterOptions;
}

export const TC_INITIAL_STATE: TicketCenterState = {
  currentTicketStatusDisplayed: TicketCenterNonComplianceCaseStatus.TODO,
  ticketTab: TicketTabs.OVERVIEW,
  todoCount: 0,
  ticketList: [],
  filteredTicketList: [],
  filter: "ALL",
};

export const ticketCenterState = atom<TicketCenterState>({
  key: WarehouseStateKey.TICKER_CENTER_DATA,
  default: TC_INITIAL_STATE,
});
