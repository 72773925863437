import styled from "@emotion/styled";
import React from "react";
import { FlowVisibleCards } from "./FlowVisibleCards";
import { FlowVisibleButtons } from "./FlowVisibleButtons";
import { FlowStateProps } from "./types/FlowState";
import { flowCardMap } from "./maps/flowCardMap";
import { flowButtonMap } from "./maps/flowButtonMap";
import { FlowVisibleNotifications } from "./FlowVisibleNotifications";
import { useRecoilValue } from "recoil";
import { notificationsState } from "./flowState";

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Flow: React.FC<FlowStateProps> = ({
  cardsState,
  cardMap,
  visibleButtons,
  buttonMap,
  showNotifications = true,
}) => {
  // Merge application-level maps with base-level
  const combinedCardMap = { ...flowCardMap, ...cardMap };
  const combinedButtonMap = { ...flowButtonMap, ...buttonMap };

  const notifications = useRecoilValue(notificationsState);

  return (
    <CenterContainer>
      <Section>
        <FlowVisibleCards cardsState={cardsState} cardMap={combinedCardMap} />
        <FlowVisibleButtons visibleButtons={visibleButtons} buttonMap={combinedButtonMap} />
        {showNotifications && <FlowVisibleNotifications notifications={notifications.slice(-1)} />}
      </Section>
    </CenterContainer>
  );
};
