import { format, formatDistanceToNowStrict, isPast, isValid } from "date-fns";
import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { isNil } from "lodash";

export const TICKET_CENTER_DATE_TIME = "MMM d, yyyy h:mm a "; // Jun 12 10:00 AM/PM
export const TICKET_CENTER_SHORT_DATE = "MMM d"; // Jun 12
export const WAREHOUSE_SLA_DUE_DATE = 2; // days

export interface ListViewDates {
  createdDate?: Date;
  completedDate?: Date;
  dueDate?: Date;
}

/**
 * This utility converts a nullable object that could be either a Date object
 * or a date string. This makes it easy to consistently handle API responses
 * containing type: Date?
 *
 * This will return undefined if the nullable object is undefined. It will return
 * a Date object whether the incoming date is a date string or Date object already.
 */
export const dateOrUndefined = (date?: string | Date): Date | undefined => (isNil(date) ? undefined : new Date(date));

// Used to convert system times from BE to correct format: Jun 12, 2020 10:00 AM/PM
export const convertDateForTicketCenter = (date: Date | number | undefined) => {
  if (!date || !isValid(new Date(date))) {
    return "";
  }
  return format(new Date(date), TICKET_CENTER_DATE_TIME);
};

/* generates contextual string stating how far away the given due date is from today
ex: "5 days, 9 hours, etc" */
export const generateContextualDate = (date: Date | number | undefined) =>
  date ? formatDistanceToNowStrict(new Date(date)) : null;

export const checkIfReceiveIsLate = (date: Date | number | undefined) => (date ? isPast(new Date(date)) : false);

// Drops timestamp and year from date (ex: Jun 19)
export const generateShortDate = (date: Date | number | undefined) =>
  date ? format(date, TICKET_CENTER_SHORT_DATE) : null;

export const generateListViewDate = (
  { createdDate, completedDate, dueDate }: ListViewDates,
  ticketStatus: TicketCenterNonComplianceCaseStatus
) => {
  // some todo cases do not have a dueDate defined (legacy)
  if (ticketStatus === TicketCenterNonComplianceCaseStatus.TODO && dueDate) {
    const dateFormatDueDate = new Date(dueDate!);

    return isPast(dateFormatDueDate)
      ? `${generateContextualDate(dateFormatDueDate)} late` // "2 weeks late" or "Due in 1 day"
      : `Due in ${generateContextualDate(dateFormatDueDate)}`;
  } else if (ticketStatus === TicketCenterNonComplianceCaseStatus.CREATED && createdDate) {
    return isValid(new Date(createdDate)) ? `Created on ${generateShortDate(new Date(createdDate))}` : "";
  } else if (ticketStatus === TicketCenterNonComplianceCaseStatus.CLOSED && completedDate) {
    return isValid(new Date(completedDate)) ? `Closed on ${generateShortDate(new Date(completedDate))}` : "";
  } else {
    // covers edge case of a case not having a valid date
    return "";
  }
};

export const generateReceivingInfoDate = (
  ticketStatus: TicketCenterNonComplianceCaseStatus,
  dueDate?: Date | number,
  completedDate?: Date | number
) => {
  if (ticketStatus === TicketCenterNonComplianceCaseStatus.TODO && dueDate) {
    const dateFormatDueDate = new Date(dueDate);

    return isPast(dateFormatDueDate)
      ? `${generateContextualDate(dateFormatDueDate)} late` // "2 weeks late" or "Due in 1 day"
      : `Due in ${generateContextualDate(dateFormatDueDate)}`;
  } else if (ticketStatus === TicketCenterNonComplianceCaseStatus.CLOSED && completedDate) {
    return `${generateShortDate(new Date(completedDate))}`;
  } else {
    // covers edge case of a TODO not having a dueDate (dueAt from api res)
    return "";
  }
};
