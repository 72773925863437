import React from "react";
import styled from "@emotion/styled";
import { mobileTheme, DefaultTheme } from "@deliverr/ui";

export interface TooltipWrapperProps {
  className?: string;
}

export interface TooltipProps {
  active: boolean;
  className?: string;
}

const WrapperContainer = styled.div`
  position: relative;
`;

const TooltipContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  min-width: 100px;
  transform: translate(-50%, 0);
  z-index: 50;
  background-color: ${theme.colors.RED[300]};
  color: ${theme.colors.NEUTRAL["00"]};
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: ${theme.border.radius.R4};
  padding: 0.25rem 1rem;
  right: 0;
  box-shadow: ${theme.shadow.S1};
`
);

const PositionedSvg = styled.svg<Record<string, unknown>, DefaultTheme>`
  position: absolute;
  height: 0.5rem;
  width: 100%;
  left: 0;
  bottom: 100%;
  transform: scale(-1);
`;

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ children, className }) => {
  return <WrapperContainer className={className}>{React.Children.toArray(children)}</WrapperContainer>;
};

export const Tooltip: React.FC<TooltipProps> = ({ children, active, className }) => {
  return active ? (
    <TooltipContainer className={className}>
      <PositionedSvg x="0" y="0" viewBox="0 0 255 255">
        <path d="M0 0L127.5 127.5 255 0z" fill={mobileTheme.colors.RED[300]}></path>
      </PositionedSvg>
      {React.Children.toArray(children)}
    </TooltipContainer>
  ) : null;
};
