import React from "react";
import { FacilityCard } from "facility-commons";
import { Title, Text, Stack, Button, BoxReset } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useBoxTypeIdentificationCard } from "./useBoxTypeIdentificationCard";

export interface BoxTypeIdentificationCardProps {
  scannedLabel: string;
  arrivalScanId: number;
}

const StyledButton = styled(Button)`
  width: 80%;
`;

export const BoxTypeIdentificationCard: React.FC<BoxTypeIdentificationCardProps> = ({
  scannedLabel,
  arrivalScanId,
}) => {
  const {
    title,
    helpText,
    buttonLabels,
    returnsEnabled,
    onCancel,
    onCdskuSelect,
    onUndeliverredPackageSelect,
    onReturnedPackageSelect,
    onUnsureOfPackageTypeSelect,
  } = useBoxTypeIdentificationCard({
    scannedLabel,
    arrivalScanId,
  });
  return (
    <FacilityCard>
      <Title displayAs="h4">{title}</Title>
      <Text>{helpText}</Text>
      <BoxReset marginTop="S1">
        <Stack center gap="S4">
          <StyledButton onClick={onCdskuSelect}>{buttonLabels.CDSKU}</StyledButton>
          {returnsEnabled && (
            <>
              <StyledButton onClick={onUndeliverredPackageSelect}>{buttonLabels.undeliverredPackage}</StyledButton>
              <StyledButton onClick={onReturnedPackageSelect}>{buttonLabels.returnedPackage}</StyledButton>
            </>
          )}

          <StyledButton onClick={onUnsureOfPackageTypeSelect}>{buttonLabels.imNotSure}</StyledButton>
          <StyledButton secondary onClick={onCancel}>
            {buttonLabels.Cancel}
          </StyledButton>
        </Stack>
      </BoxReset>
    </FacilityCard>
  );
};
