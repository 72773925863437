import { defineMessages } from "react-intl";

export const LIST_VIEW_CARD_LABELS = defineMessages({
  title: {
    id: "warehouse.ticketCenter.listViewCard.title",
    defaultMessage: "Tickets",
  },
  displayId: {
    id: "warehouse.ticketCenter.listViewCard.displayId",
    defaultMessage: "#{displayId}",
  },
});
