import React from "react";
import { FlowCard, TextInputCard } from "facility-commons/flow";
import { useLocationScan } from "./useLocationScan";

export const LocationScan: React.FC = () => {
  const { messages, location, submissionError, disableInput, handleChange, submitReturnReceive } = useLocationScan();
  return (
    <FlowCard title={messages.cardTitle}>
      <TextInputCard
        errorMessage={submissionError}
        newest
        onChange={handleChange}
        onSubmit={submitReturnReceive}
        placeholder={messages.inputPlaceHolder}
        value={location}
        disabled={disableInput}
        embedded
        data-testid="return-receive-location-card-input"
      />
    </FlowCard>
  );
};
