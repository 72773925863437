import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { defineMessages, MessageDescriptor } from "react-intl";

export const NonComplianceTypeMessages: Record<NonComplianceType, MessageDescriptor> = defineMessages({
  [NonComplianceType.MISSING_BARCODE]: {
    id: "warehouse.ticketCenter.ticketType.missingBarcode",
    defaultMessage: "missing barcode",
  },
  [NonComplianceType.UNKNOWN_BARCODE]: {
    id: "warehouse.ticketCenter.ticketType.unknownBarcode",
    defaultMessage: "unknown barcode",
  },
  [NonComplianceType.UNEXPECTED_SKU]: {
    id: "warehouse.ticketCenter.ticketType.unexpectedSku",
    defaultMessage: "unexpected barcode",
  },
  // 02/08/23 - Added by Justin Griffin temporarily to fix the build. Should be corrected by someone knowledgeable.
  [NonComplianceType.UNEXPECTED_CDSKU]: {
    id: "warehouse.ticketCenter.ticketType.unexpectedCdsku",
    defaultMessage: "unexpected barcode",
  },
  [NonComplianceType.OTHER]: {
    id: "warehouse.ticketCenter.ticketType.other",
    defaultMessage: "uncategorized",
  },
  [NonComplianceType.INVALID_LOT_EXPIRY]: {
    id: "warehouse.ticketCenter.ticketType.invalidLotFefo",
    defaultMessage: "Ineligible Lot Fefo",
  },
  [NonComplianceType.DAMAGED_PRODUCT]: {
    id: "warehouse.ticketCenter.ticketType.damages",
    defaultMessage: "Damages",
  },
  [NonComplianceType.EXPIRED_PRODUCT]: {
    id: "warehouse.ticketCenter.ticketType.expired",
    defaultMessage: "Expired",
  },
});
