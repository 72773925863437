import React from "react";
import { Button } from "@deliverr/ui";
import { modalsText } from "../modalsText";
import { dangerModalIcon, ModalTemplate } from "facility-commons";
import { useIntl } from "react-intl";

export interface FailedReceiveModalProps {
  onConfirm: () => void;
}

export const FailedReceiveModal: React.FC<FailedReceiveModalProps> = ({ onConfirm }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalTemplate
      title={formatMessage(modalsText.canNotProceed)}
      text={formatMessage(modalsText.failedReceiveText)}
      iconProps={dangerModalIcon}
    >
      <Button block onClick={onConfirm}>
        {formatMessage(modalsText.receiveInLogiwa)}
      </Button>
    </ModalTemplate>
  );
};
