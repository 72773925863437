import { useSetRecoilState } from "recoil";

import { useRouter } from "facility-commons/hooks";
import { WarehousePortalRoutes } from "warehouse/routes";
import { nonComplianceCdskuState } from "../nonComplianceCdskuState";
import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";

export const useNonComplianceCdskuScan = () => {
  const router = useRouter();
  const setNonComplianceCdskuState = useSetRecoilState(nonComplianceCdskuState);

  const handleSubmitNonComplianceCdskuScanSuccess = (asn: ReceivingAsn) => {
    setNonComplianceCdskuState(asn.cdsku);
    router.push(WarehousePortalRoutes.NON_COMPLIANCE_TYPE);
  };

  const handleNoCdsku = () => {
    setNonComplianceCdskuState(null);
    router.push(WarehousePortalRoutes.NC_OTHER_TICKET);
  };

  return {
    handleSubmitNonComplianceCdskuScanSuccess,
    handleNoCdsku,
  };
};
