import { defineMessages, MessageDescriptor } from "react-intl";

export enum BulkReceiveErrorMessageKey {
  DIFFERENT_EXPECTED_QUANTITY = "DIFFERENT_EXPECTED_QUANTITY",
  DIFFERENT_SKU = "DIFFERENT_SKU",
  TOO_MANY_SKUS = "TOO_MANY_SKUS",
  DUPLICATE_SCAN = "DUPLICATE_SCAN",
  PRIOR_RECEIVE = "PRIOR_RECEIVE",
  MULTIPLE_ASN = "MULTIPLE_ASN",
  NO_SCANNED_CDSKUS = "NO_SCANNED_CDSKUS",
  MISSING_BOXES = "MISSING_BOXES",
  HIGH_RISK_PACKAGE = "HIGH_RISK_PACKAGE",
  CAPTURE_LOT_EXPIRY_PACKAGE = "CAPTURE_LOT_EXPIRY_PACKAGE",
}

export const BULK_RECEIVE_ERROR_BY_ERROR_CODE: Record<BulkReceiveErrorMessageKey, MessageDescriptor> = defineMessages({
  [BulkReceiveErrorMessageKey.DIFFERENT_EXPECTED_QUANTITY]: {
    id: "warehouse.bulk.diffQTYOrSKU",
    defaultMessage:
      "This CDSKU does not match what you are bulk receiving. Receive this box separately and update the bulk quantity.",
  },
  [BulkReceiveErrorMessageKey.DIFFERENT_SKU]: {
    id: "warehouse.bulk.diffQTYOrSKU",
    defaultMessage:
      "This CDSKU does not match what you are bulk receiving. Receive this box separately and update the bulk quantity.",
  },
  [BulkReceiveErrorMessageKey.TOO_MANY_SKUS]: {
    id: "warehouse.bulk.tooManuSkus",
    defaultMessage:
      "Only boxes with one SKU are eligible for bulk receive. Receive multi-sku boxes separately and update the bulk quantity.",
  },
  [BulkReceiveErrorMessageKey.DUPLICATE_SCAN]: {
    id: "warehouse.bulk.duplicateScan",
    defaultMessage: "This box is already part of the bulk receive. Scan a new box.",
  },
  [BulkReceiveErrorMessageKey.PRIOR_RECEIVE]: {
    id: "warehouse.bulk.priorReceive",
    defaultMessage: "This box has already been received in the past and cannot be part of a bulk receive.",
  },
  [BulkReceiveErrorMessageKey.MULTIPLE_ASN]: {
    id: "warehouse.bulk.multipleASN",
    defaultMessage:
      "This CDSKU is not on the same PO # as what you are bulk receiving. Receive this box separately and update the bulk quantity.",
  },
  [BulkReceiveErrorMessageKey.NO_SCANNED_CDSKUS]: {
    id: "warehouse.bulk.noScannedCdsku",
    defaultMessage: "No boxes were scanned. Please scan at least one box to start a bulk receive.",
  },
  [BulkReceiveErrorMessageKey.MISSING_BOXES]: {
    id: "warehouse.bulk.missingBoxes",
    defaultMessage:
      "The number of boxes scanned does not match the submitted count of identical boxes. Please scan all identical boxes or update the count of identical boxes.",
  },
  [BulkReceiveErrorMessageKey.HIGH_RISK_PACKAGE]: {
    id: "warehouse.bulk.highRiskPackage",
    defaultMessage: "This box is marked as high risk for errors during receiving. Receive this box separately.",
  },
  [BulkReceiveErrorMessageKey.CAPTURE_LOT_EXPIRY_PACKAGE]: {
    id: "warehouse.bulk.captureLotExpiryPackage",
    defaultMessage: "This box is marked for capturing lot and expiry of its contents. Receive this box separately.",
  },
});
