/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilValue } from "recoil";
import { isFeatureOn } from "@deliverr/ui-facility";
import { PrepFeature } from "./PrepFeature";
import { prepFeatureClientState } from "./prepFeatureClientState";

export const usePrepFeature = () => {
  const { isClientInitialized } = useRecoilValue(prepFeatureClientState);

  return {
    isLocationsEnabled: isClientInitialized && isFeatureOn(PrepFeature.LOCATIONS_ENABLED),
    isCombosEnabled: isClientInitialized && isFeatureOn(PrepFeature.COMBOS_ENABLED),
  };
};
