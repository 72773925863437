import { useAsync } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TrafficType, initSplitClient } from "facility-commons";
import { userState } from "facility-commons/base/Auth/userState";
import { nonComplianceClient } from "facility-commons/base/Clients";
import { composeUseWarehouseEmailState } from "facility-commons/base/warehouseEmailState/composeUseWarehouseEmailState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { logError, setProp } from "facility-commons/utils";
import { warehouseAppState } from "./base/warehouseAppDataState";
import { useGetWarehouseData } from "./common/hooks";

export const useWarehouse = () => {
  const { warehouseId } = useRecoilValue(userState);
  const setWarehouseData = useSetRecoilState(warehouseAppState);
  const { warehousePublicClient, inboundClient } = useClientsWithAuth();
  const useWarehouseEmailState = composeUseWarehouseEmailState(warehousePublicClient);

  useWarehouseEmailState(warehouseId);
  useGetWarehouseData(warehouseId);

  const { loading } = useAsync(async () => {
    const ctx = { fn: "useWarehouse" };

    if (warehouseId) {
      // awaiting this causes a forever loading state if the request fails which blocks app render
      initSplitClient(warehouseId, TrafficType.WAREHOUSE);
      try {
        const warehouseMetadataResponse = await inboundClient.receivingAppGetMetadata(warehouseId);
        setWarehouseData(setProp("returnsEnabled", !!warehouseMetadataResponse.data?.returnsEnabled));
        const problemSolverFlowEnabled = !!warehouseMetadataResponse.data?.problemSolverFlowEnabled;
        setWarehouseData(setProp("problemSolverFlowEnabled", problemSolverFlowEnabled));

        // If a warehouse is using the problem solver flow, they will not be using the ticket center and so we don't need to make this call
        if (!problemSolverFlowEnabled) {
          const todoTicketsResponse = await nonComplianceClient.getTicketCenterCasesByWarehouseIdTodoStatus(
            warehouseId
          );
          setWarehouseData(setProp("todoCount", todoTicketsResponse.length));
        }
      } catch (error) {
        logError({ ...ctx, error }, new Error("error initializing warehouse"));
      }
    }
  }, [setWarehouseData, warehouseId]);

  return { loading };
};
