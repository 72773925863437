import { defineMessages } from "react-intl";

export const STORAGE_MESSAGES = defineMessages({
  isNonCompliance: {
    id: "warehouse.storage.nonCompliance.messages.nonCompliance",
    defaultMessage: "Received an unexpected / unknown Barcode?",
  },
  createNCTicket: {
    id: "warehouse.storage.nonCompliance.messages.createNCTicket",
    defaultMessage: "Create a Non Compliance Ticket",
  },
  scanPO: {
    id: "warehouse.storage.nonCompliance.messages.scanPO",
    defaultMessage: "Scan PO #",
  },
  poNumPlaceholder: {
    id: "warehouse.storage.nonCompliance.messages.poNumPlaceholder",
    defaultMessage: "PO #",
  },
  missingPO: {
    id: "warehouse.storage.nonCompliance.messages.missingPO",
    defaultMessage: "Can't find the PO #?",
  },
  ncLocationInstruction: {
    id: "warehouse.storage.nonCompliance.messages.ncLocationInstruction",
    defaultMessage: "Specify Location where the cases will be kept.",
  },
  ncLocationPlaceholder: {
    id: "warehouse.storage.nonCompliance.messages.ncLocationPlaceholder",
    defaultMessage: "E.g. DMG",
  },
  ncLocationTitle: {
    id: "warehouse.storage.nonCompliance.messages.ncLocationTitle",
    defaultMessage: "Enter Location",
  },
  storageInstructions: {
    id: "warehouse.storage.nonCompliance.instructions",
    defaultMessage: "Scan the barcode on the non-compliant case.",
  },
});
