import { WarehousePortalRoutes } from "warehouse/routes";

// these are the main entry points to different flows of warehouse - they have no previous step so need no back button
export const WAREHOUSE_PORTAL_ROOT_PAGES = [
  WarehousePortalRoutes.NEW_TICKET,
  WarehousePortalRoutes.SCANNER,
  WarehousePortalRoutes.TICKET_CENTER,
  WarehousePortalRoutes.MORE,
  WarehousePortalRoutes.OUTBOUNDS,
  WarehousePortalRoutes.RECEIVING,
  WarehousePortalRoutes.RECEIVING_CDSKU,
  WarehousePortalRoutes.RECEIVING_PALLET,
  WarehousePortalRoutes.CONSOLIDATION_PALLET,
];
