import { ThemeProps, ToastContainer } from "@deliverr/ui";
import styled from "@emotion/styled";
import { DEFAULT_LANGUAGE, isFeatureOn } from "facility-commons";
import { VersionCheck } from "facility-commons/base";
import { userState } from "facility-commons/base/Auth/userState";
import { LoadingSpinner } from "facility-commons/components";
import { Flash } from "facility-commons/components/Flash";
import { flashState } from "facility-commons/flow/flowState";
import { useStorageUserState } from "facility-commons/hooks/auth/useStorageUserState";
import { useUserState } from "facility-commons/hooks/auth/useUserState";
import { AppName } from "facility-commons/types";
import React from "react";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router";
import { useRecoilValue } from "recoil";
import { WarehouseFeature } from "./base/WarehouseFeature";
import { useLanguage } from "./base/useLanguage";
import { AppBar, WarehouseHeader } from "./common/components";
import { WarehouseModals } from "./modal";
import { MoreCenter } from "./more";
import { ReceivingDashboard } from "./more/ReceivingDashboard";
import { Receiving } from "./receiving";
import { ReceivingPath } from "./receiving/routes";
import { StorageReceivingFlow } from "./receiving/storage/StorageReceivingFlow";
import { ArrivalScannerRoutes, WarehousePortalRoutesNames } from "./routes";
import { BoxArrivalScan, PalletArrivalScan } from "./scan-arrival";
import { ArrivalScannerTypeSelectorCard } from "./scan-arrival/cards/pallet";
import { StorageArrivalScanCard } from "./scan-arrival/storage/StorageArrivalScanCard";
import { TicketCenter } from "./ticket-center";
import { useWarehouse } from "./useWarehouse";
import { Outbounds } from "./rs-outbounds";
import { FacilityIdHeader } from "facility-commons/components/FacilityIdContainer";

const WarehouseContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

const StyledMain = styled.main<ThemeProps>`
  align-items: center;
  background: ${({ theme }) => theme.colors.NEUTRAL[40]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  width: 100%;
  overflow: auto;
`;

export const Warehouse: React.FC = () => {
  const { match } = useUserState(AppName.WP);
  const { warehouseId } = useRecoilValue(userState);
  const flash = useRecoilValue(flashState);
  const { loading } = useWarehouse();
  const { isStorage } = useStorageUserState(warehouseId);
  const { language, messages } = useLanguage();

  return (
    <IntlProvider defaultLocale={DEFAULT_LANGUAGE} locale={language} messages={messages}>
      <WarehouseContainer>
        <FacilityIdHeader warehouseId={warehouseId} />
        <WarehouseHeader />
        <StyledMain>
          {loading ? (
            <LoadingSpinner loading />
          ) : (
            <>
              <Switch>
                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.RECEIVING}/${ReceivingPath.RECEIVING_DASHBOARD}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.RECEIVING}/${ReceivingPath.RECEIVING_DASHBOARD}`}
                  render={() => <ReceivingDashboard />}
                />
                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.RECEIVING}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.RECEIVING}`}
                  render={() => (isStorage ? <StorageReceivingFlow /> : <Receiving />)}
                />
                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.OUTBOUNDS}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.OUTBOUNDS}`}
                  render={() => <Outbounds />}
                />
                <Route
                  exact
                  key={`${match.path}/${WarehousePortalRoutesNames.SCANNER}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.SCANNER}`}
                  render={() =>
                    isStorage ? (
                      <StorageArrivalScanCard />
                    ) : isFeatureOn(WarehouseFeature.SHOW_BOX_ARRIVAL_SCAN) ? (
                      <ArrivalScannerTypeSelectorCard />
                    ) : (
                      <PalletArrivalScan />
                    )
                  }
                />
                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.SCANNER}/${ArrivalScannerRoutes.PALLET}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.SCANNER}/${ArrivalScannerRoutes.PALLET}`}
                  render={() => <PalletArrivalScan />}
                />
                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.SCANNER}/${ArrivalScannerRoutes.BOX}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.SCANNER}/${ArrivalScannerRoutes.BOX}`}
                  render={() => <BoxArrivalScan />}
                />

                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.TICKET_CENTER}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.TICKET_CENTER}`}
                  render={() => <TicketCenter />}
                />

                <Route
                  key={`${match.path}/${WarehousePortalRoutesNames.MORE}`}
                  path={`${match.path}/${WarehousePortalRoutesNames.MORE}`}
                  render={() => <MoreCenter />}
                />

                <Route>
                  <Redirect to={`${match.url}/${WarehousePortalRoutesNames.SCANNER}`} />
                </Route>
              </Switch>
              <Flash flash={flash} />
            </>
          )}
        </StyledMain>
        <AppBar />
        <VersionCheck />
        <WarehouseModals />
      </WarehouseContainer>
      <ToastContainer position="bottom-center" toastStyle={{ maxWidth: "95vw", margin: "3rem 0" }} />
    </IntlProvider>
  );
};
