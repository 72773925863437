import { selector } from "recoil";
import { receivingState } from "./ReceivingState";
import { currentSkuState } from "./CurrentSkuState";
import { bulkReceiveState } from "./BulkReceiveState";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

// if isExpeditedBulkEligible is true and user enters same qty and number of boxes as expected (returned from BE),
// they'll be eligible for expedited bulk flow.
const getIsExpeditedFlowEligible = ({ get }) => {
  const { isExpeditedBulkEligible, numIdenticalBoxes, numUnitsPerBox } = get(bulkReceiveState);
  const { identicalBoxes } = get(receivingState);
  const { quantity } = get(currentSkuState);

  return (
    isExpeditedBulkEligible &&
    identicalBoxes === numIdenticalBoxes &&
    quantity === numUnitsPerBox &&
    numIdenticalBoxes > 1
  );
};

export const isExpeditedFlowEligibleState = selector<boolean>({
  key: WarehouseStateKey.IS_EXPEDITED_FLOW_ELIGIBLE,
  get: getIsExpeditedFlowEligible,
});

// A bulk receive that's not eligible for expedited flow.
const getIsBulkReceive = ({ get }) => {
  const isExpeditedBulkFlowEligible = get(isExpeditedFlowEligibleState);
  const { isBulkEligible, numIdenticalBoxes } = get(bulkReceiveState);
  const { identicalBoxes, expectedFefoLotPairs } = get(receivingState);
  const isMultiFefo = expectedFefoLotPairs && expectedFefoLotPairs.length > 1;

  return isBulkEligible && !isExpeditedBulkFlowEligible && numIdenticalBoxes > 1 && identicalBoxes > 1 && !isMultiFefo;
};

export const isBulkReceiveState = selector<boolean>({
  key: WarehouseStateKey.IS_BULK_RECEIVE,
  get: getIsBulkReceive,
});
