import { EntityType } from "@deliverr/non-compliance-client";
import { createDangerNotification, createSuccessNotification } from "@deliverr/ui-facility";
import { log } from "facility-commons";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { currentSkuState, palletReceiveState, receiveFlowTypeState } from "warehouse/receiving/ReceivingState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { WarehousePortalRoutes } from "warehouse/routes";
import { problemSolverFlowMessages } from "../../content";
import { useHandleNonComplianceError } from "problem-solver/hooks/useHandleNonComplianceError";
import { userState } from "../../../../../facility-commons/base/Auth/userState";

export const useReportBoxIssue = () => {
  const router = useRouter();
  const { addNotification, successResponse, errorResponse } = useCommonFlow();
  const { warehouseId } = useRecoilValue(userState);
  const receiveFlowType = useRecoilValue(receiveFlowTypeState);
  const { cdsku } = useRecoilValue(currentSkuState);
  const { palletLabel } = useRecoilValue(palletReceiveState);
  const { formatMessage } = useIntl();
  const { nonComplianceClientV2 } = useClientsWithAuth();
  const { handleNoncomplianceError } = useHandleNonComplianceError();

  const entityType = receiveFlowType === ReceivingFlowType.BOX_RECEIVING ? EntityType.BOX : null;
  const entityId = receiveFlowType === ReceivingFlowType.BOX_RECEIVING ? cdsku : null;

  const ctx = {
    fn: "useReportBoxIssue",
    receiveFlowType,
    cdsku,
    palletLabel,
    entityType,
  };

  const onCancel = () => {
    router.goBack();
  };

  const [{ loading }, confirmReportBoxIssue] = useAsyncFn(async () => {
    if (!entityId) {
      errorResponse();
      addNotification(
        createDangerNotification(formatMessage(problemSolverFlowMessages.reportBoxIssueMissingContainerLabelError))
      );
      return;
    }

    if (!entityType) {
      errorResponse();
      addNotification(
        createDangerNotification(formatMessage(problemSolverFlowMessages.reportBoxIssueMissingContainerTypeError))
      );
      return;
    }
    log(ctx, "submitting container issue");

    try {
      await nonComplianceClientV2.recognizeEntity({
        entityId,
        entityType,
        facilityId: warehouseId,
      });

      addNotification(createSuccessNotification(formatMessage(problemSolverFlowMessages.reportBoxIssueSuccessMessage)));
      successResponse();

      if (receiveFlowType === ReceivingFlowType.BOX_RECEIVING) {
        router.push(WarehousePortalRoutes.RECEIVING_CDSKU);
      } else {
        router.push(WarehousePortalRoutes.RECEIVING);
      }
    } catch (error: any) {
      handleNoncomplianceError(ctx, error);
    }
  });

  return {
    boxLabel: entityId,
    entityType,
    onCancel,
    submittingIssue: loading,
    confirmReportBoxIssue,
  };
};
