import styled from "@emotion/styled";
import { IconV2, IconV2Types } from "@deliverr/ui";
import React from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { AccentColor, getAccentColorValue } from "facility-commons/flow/AccentColor";
import { FACILITY_THEME } from "facility-commons/theme";

export interface FlowCardIconProps {
  textIcon?: string;
  icon?: IconV2Types;
  accentColor?: AccentColor;
  size?: SizeProp;
}

const IconContainer = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
    background: ${backgroundColor};
    padding-left: 2px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const SymbolIcon = styled(IconV2)`
  margin-left: -2px;
`;

const TextIcon = styled.span`
  margin-left: -2px;
  color: ${FACILITY_THEME.colors.NEUTRAL["00"]};
  font-weight: ${FACILITY_THEME.font.weight.BOLD};
`;

export const FlowCardIcon: React.FC<FlowCardIconProps> = ({ textIcon, icon, accentColor = "GRAY", size = "sm" }) => {
  const accentColorValue = getAccentColorValue(accentColor);
  return (
    <IconContainer backgroundColor={accentColorValue}>
      {icon && <SymbolIcon type={icon} inverse={true} size={size} fixedWidth />}
      {textIcon && <TextIcon>{textIcon}</TextIcon>}
    </IconContainer>
  );
};
