import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface ContactDeliverrModalProps {
  onCancel: () => void;
  onContinue: () => void;
  title: string;
  message: string;
}

export const ContactDeliverrModal: React.FC<ContactDeliverrModalProps> = ({ onCancel, title, message }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate buttonLayout="row" title={title} text={message} iconProps={dangerModalIconXl}>
      <Grid columns="1fr" fullWidth>
        <Button block onClick={onCancel}>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
