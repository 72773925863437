import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { v4 as uuid } from "uuid";

import { NCRoutes } from "../../base/routes";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { genericOnScannerInputChange, log, logStart } from "facility-commons/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "facility-commons/base/Auth/userState";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { fetchStoragePODetails } from "warehouse/common/modes/fetchStoragePODetails";
import { storageInboundReceivingStateAtom } from "warehouse/receiving/storage/StorageReceivingState";
import { useWarehouseModal } from "warehouse/modal";

export const useStoragePOCard = () => {
  const [poError, setPOError] = useState<string>("");
  const { showModal } = useWarehouseModal();
  const { trackingCode = "", incidentId } = useRecoilValue(currentProductState);
  const { handleNonComplianceError, updateCurrentProduct } = useNonComplianceFlow();
  const { errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { warehouseId } = useRecoilValue(userState);
  const setScanState = useSetRecoilState(storageInboundReceivingStateAtom);

  const updatePONum = (updatedPO: string) => {
    setPOError("");
    updateCurrentProduct("trackingCode", updatedPO);
  };

  const proceedToFollowUp = () => push(NCRoutes.BARCODE);

  const [{ loading }, handleSubmit] = useAsyncFn(
    async (poNumber: string) => {
      const ctx = logStart({ fn: "useStoragePOCard.handleSubmit", poNumber });

      if (!poNumber.length) {
        errorResponse();
        return setPOError(formatMessage(commonWarehouseMessages.fieldRequired));
      }

      try {
        const {
          asnId,
          palletCount,
          itemsCount,
          sellerId,
          searchTerms,
          productDetails,
          bookingID,
          bookingType,
        } = await fetchStoragePODetails(warehouseId, poNumber, incidentId, showModal, formatMessage);
        updateCurrentProduct("sellerId", sellerId);
        if (productDetails?.error) {
          throw productDetails;
        }
        setScanState((state) => {
          const updatedState = { ...state };
          updatedState.poNum = asnId;
          updatedState.poDetails = {
            palletCount,
            numberOfSkus: itemsCount,
            numberOfBoxes: 0,
            searchTerms,
            bookingID,
            bookingType,
          };
          updatedState.skus = productDetails || {};
          updatedState.scannedSKUs = 0;
          return updatedState;
        });
        log({ ...ctx, productDetails }, "PO scan response");
        successResponse();
        proceedToFollowUp();
      } catch (err) {
        handleNonComplianceError(ctx, err);
      }
    },
    [incidentId]
  );

  const handleChange = genericOnScannerInputChange(trackingCode, updatePONum, handleSubmit, "upper");

  useEffect(() => {
    if (!incidentId) {
      updateCurrentProduct("incidentId", uuid());
    }
  }, [incidentId, updateCurrentProduct]);

  return {
    trackingCode,
    poError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  };
};
