import React, { useState } from "react";
import { Grid, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { NumericInput } from "facility-commons/components/Inputs/NumericInput";
import { CancelButton, UpdateButton, ModalTemplate } from "facility-commons";
import { modalsText } from "../modalsText";

interface UpdateBulkQuantityModalProps {
  onCancel: () => void;
  onConfirm: (value: number) => void;
}

const ButtonContainer = styled(Grid)<ThemeProps>`
  margin-top: ${({ theme }) => theme.spacing.S5};
  width: 100%;
`;

const ModalContainer = styled.div<ThemeProps>(({ theme }) => {
  return `
  padding: 0 ${theme.spacing.S2};
  width: 100%;
`;
});

export const UpdateBulkQuantityModal: React.FC<UpdateBulkQuantityModalProps> = ({ onCancel, onConfirm }) => {
  const [newBoxQuantity, setNewBoxQuantity] = useState<number>(0);

  const { formatMessage } = useIntl();
  const identicalBoxes = formatMessage(modalsText.identicalBoxCount);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (value <= 0) {
      return;
    }
    setNewBoxQuantity(+e.target.value);
  };
  const handleConfirm = () => onConfirm(newBoxQuantity);

  return (
    <ModalTemplate
      title={formatMessage(modalsText.countBoxes)}
      content={
        <ModalContainer>
          <NumericInput
            onChange={handleChange}
            label={identicalBoxes}
            placeholder={identicalBoxes}
            type="number"
            value={newBoxQuantity}
          />
          <ButtonContainer columns="1fr 1fr" gap="S3">
            <CancelButton onClick={onCancel} secondary />
            <UpdateButton onClick={handleConfirm} />
          </ButtonContainer>
        </ModalContainer>
      }
    />
  );
};
