import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";

import { createDangerNotification, createSuccessNotification, logStart } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useRouter } from "facility-commons/hooks";
import { WarehousePortalRoutes } from "warehouse/routes";
import { StorageErrorCodes } from "warehouse/common/messages/storage/StorageErrorCodes";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import {
  createReceivingLotInfo,
  createReceivingPOScan,
  createReceivingSKUQuantity,
} from "../storageReceivingCardCreators";
import { fetchDskusDetail } from "warehouse/common/modes/fetchStoragePODetails";
import { successModalIconLg, warningModalIconLg } from "facility-commons";
import { markPOComplete } from "warehouse/common/modes/markPOComplete";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { isFBABookingType } from "warehouse/receiving/utils";

export const useReceivingSKUScanFlow = () => {
  const { formatMessage } = useIntl();
  const { warehouseId } = useRecoilValue(userState);
  const { resetNotifications, addAutoCloseNotification } = useCommonFlow();
  const [scanError, setScanError] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const [currentSKU, setCurrentSKU] = useState<string>(scanState.currentSKU ?? "");
  const { hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { inboundClient } = useClientsWithAuth();
  const { showModal, hideAllModals } = useWarehouseModal();
  const { push } = useRouter();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      if (isFBABookingType(scanState.poDetails?.bookingType)) {
        showFlowButton(StorageFlowButtonType.STORAGE_PO_DONE_RECEIVING);
      }
      const timeoutId = setTimeout(() => {
        resetNotifications();
        clearTimeout(timeoutId);
      }, 2000);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const handleNonComplianceClick = () => push(WarehousePortalRoutes.STORAGE_NON_COMPLIANCE_FLOW);

  const handleError = async (fn, sku) => {
    try {
      await fn();
    } catch (err: any) {
      if (err?.response) {
        const { subcode, data } = err.response;
        let text = "";
        switch (subcode) {
          case StorageErrorCodes.BARCODE_NOT_ON_ASN:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.barcodeNotInASN, {
              code: sku,
            });
            break;
          default:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.unrecognisedDescriptionText, {
              error: String(data?.message),
            });
            break;
        }
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text,
        });
      } else if (err?.error) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err.error?.message),
          entity: "non-compliance",
          onSubmit: handleNonComplianceClick,
        });
      }
      setCurrentSKU("");
    }
  };

  const [{ loading }, handleSubmit] = useAsyncFn(async (skuValue: string) => {
    const proceed = (sku, response) => {
      setScanState((state) => ({
        ...state,
        currentSKU: sku,
        locationReceivedQty: response.data,
        poDetails: {
          ...state.poDetails,
          totalExpectedQuantity: response.data.expectedQty,
          totalReceivedQuantity: response.data.receivedQty,
        },
        currentSkuDetails: { ...state.currentSkuDetails, ...state.skus?.[sku] },
      })); // Update SKU
      const isNotFefoAndLotTracking =
        !scanState.skus?.[sku]?.isFefoEnabled && !scanState.skus?.[sku]?.isLotTrackingEnabled;
      const card = isNotFefoAndLotTracking ? createReceivingSKUQuantity({}) : createReceivingLotInfo({});
      transition([
        {
          card,
          notification: createSuccessNotification(
            formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanSuccessNotificationTitle)
          ),
          onTransition: () => {
            hideAllFlowButtons();
            if (isNotFefoAndLotTracking) {
              showFlowButton(StorageFlowButtonType.STORAGE_SKU_QUANTITY_RECEIVING_BACK_BUTTON);
              showFlowButton(StorageFlowButtonType.STORAGE_CONTINUE_SKU_QUANTITY_RECEIVING);
            } else {
              showFlowButton(StorageFlowButtonType.STORAGE_LOT_INFO_RECEIVING_BACK_BUTTON);
              showFlowButton(StorageFlowButtonType.STORAGE_LOT_INFO_RECEIVING_CONTINUE_BUTTON);
            }
          },
          sfx: SoundFx.SUCCESS,
          flash: "SUCCESS",
        },
      ]);
    };

    const onConfirmReceiveUnexpected = (response) => {
      return async () => {
        const sku = response.data!.dsku;
        const newState = { ...scanState };
        await handleError(async () => {
          const { productAtps, productDetails, searchTerms } = await fetchDskusDetail([sku]);
          newState.poDetails.numberOfSkus = (newState.poDetails.numberOfSkus ?? 0) + 1;
          newState.poDetails.searchTerms = { ...newState.poDetails.searchTerms, ...searchTerms };
          newState.skus = { ...newState.skus, ...productDetails };
          newState.dskusATP = { ...newState.dskusATP, ...productAtps };
          setScanState(newState);
          proceed(sku, response);
        }, sku);
      };
    };

    const handleSubmitReceiveSKUScan = async (sku) => {
      const ctx = { fn: "useReceivingSKUScanFlow.handleSubmitReceiveSKUScan", poNum: scanState.poNum, sku: skuValue };
      logStart(ctx);
      await handleError(async () => {
        if (!skuValue) {
          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.errorEmptyValue),
          });
          setCurrentSKU("");
        } else {
          const { searchTerms } = scanState.poDetails;
          Object.keys(searchTerms).forEach((val) => {
            if (searchTerms[val].includes(skuValue)) {
              sku = val;
            }
          });
          if (!sku) {
            sku = skuValue;
          }
          const response = await inboundClient.asnReceiveValidateBarcodeV2(
            warehouseId,
            scanState.poNum!.toString(),
            sku,
            scanState.requestBatchId!
          );
          if (response.error) {
            throw response;
          }
          if (!scanState.skus?.[sku] && response.data?.dsku) {
            showModal(WarehouseModal.STORAGE_CONFIRMATION_MODAL, {
              onSubmit: onConfirmReceiveUnexpected(response),
              onCancel: () => setCurrentSKU(""),
              title: formatMessage(STORAGE_RECEIVING_MESSAGES.unexpectedSkuConfirmationModalTitle),
              text: formatMessage(STORAGE_RECEIVING_MESSAGES.unexpectedSkuConfirmationModalText, {
                sku,
                poNum: scanState.poNum,
                bold: (val) => <b>{val}</b>,
              }),
              iconProps: warningModalIconLg,
            });
          } else {
            proceed(sku, response);
          }
        }
      }, sku);
    };

    return handleSubmitReceiveSKUScan(scanState.currentSKU);
  });

  const handleClick = () => {
    setScanError("");
    handleSubmit(scanState.currentSKU ?? "");
  };

  const handleSKUChange = (skuValue) => {
    setCurrentSKU(skuValue);
  };

  const handleDamagedExpiredClick = () => {
    push(WarehousePortalRoutes.STORAGE_DAMAGED_EXPIRED_CASE);
  };

  const handlePOCompletion = async (poNum) => {
    try {
      await markPOComplete(poNum, warehouseId);
      transition([
        {
          card: createReceivingPOScan({}),
          notification: createSuccessNotification(formatMessage(STORAGE_RECEIVING_MESSAGES.poCompletionEventReceived)),
          sfx: SoundFx.SUCCESS,
          flash: "SUCCESS",
        },
      ]);
      hideAllModals();
    } catch (err) {
      addAutoCloseNotification(
        createDangerNotification(formatMessage(STORAGE_RECEIVING_MESSAGES.poCompletionEventFailed))
      );
    }
  };

  const handleSKUScan = () => {
    hideAllModals();
  };

  const handleDoneClick = () => {
    const { poDetails, currentSkuDetails } = scanState;
    if (
      (poDetails?.totalReceivedQuantity ?? 0) >=
      (poDetails?.totalExpectedQuantity ?? 0) + (currentSkuDetails?.qty ?? 0)
    ) {
      showModal(WarehouseModal.STORAGE_RECEIVING_MARK_PO_COMPLETE, {
        soundFx: SoundFx.INFO,
        iconProps: successModalIconLg,
        onSubmit: () => handlePOCompletion(scanState.poNum!),
        onCancel: () => handleSKUScan(),
      });
    } else {
      showModal(WarehouseModal.STORAGE_RECEIVING_MARK_PO_COMPLETE, {
        soundFx: SoundFx.INFO,
        iconProps: warningModalIconLg,
        title: formatMessage(STORAGE_RECEIVING_MESSAGES.markPOCompletedWarningTitle),
        text: formatMessage(STORAGE_RECEIVING_MESSAGES.markPOCompletedWarningText, {
          units: (poDetails.totalExpectedQuantity ?? 0) - (poDetails?.totalReceivedQuantity ?? 0),
        }),
        onCancel: () => handleSKUScan(),
        onSubmit: () => handlePOCompletion(scanState.poNum!),
      });
    }
  };

  return {
    scanState,
    scanError,
    currentSKU,
    handleSKUChange,
    handleSubmit,
    handleClick,
    handleDoneClick,
    handleDamagedExpiredClick,
    loading,
  };
};
