import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { initSplitClient, TrafficType } from "@deliverr/ui-facility";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { setProp } from "@deliverr/ui-facility/lib-facility/utils";
import { prepFeatureClientState } from "./prepFeatureClientState";

export const usePrepFeatureClient = () => {
  const { warehouseId } = useRecoilValue(userState);
  const setFeatureValue = useSetRecoilState(prepFeatureClientState);

  const initializeSplitClient = useCallback(async () => {
    await initSplitClient(warehouseId, TrafficType.WAREHOUSE);
    setFeatureValue(setProp("isClientInitialized", true));
  }, [setFeatureValue, warehouseId]);

  useEffect(() => {
    if (warehouseId) {
      initializeSplitClient();
    }
  }, [initializeSplitClient, warehouseId]);
};
