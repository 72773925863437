import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { Box, Grid, Radio, Text, ThemeProps } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { DarkerCardLabelText } from "facility-commons/components/emotion/cards";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useStorageReceivingSKUCasePackSelectionFlow } from "./useStorageReceivingSKUCasePackSelectionFlow";
import { BLIND_INBOUND_PACK_CONFIGURATION } from "../blindInboundsData";
import { getCaseConfigString } from "warehouse/receiving/utils/getCaseConfigString";

const SKUCasePackSelectionWrapper = styled.form`
  height: auto;

  section {
    height: auto;
  }
`;

const TextHolder = styled.div`
  overflow: hidden;
`;

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${theme.colors.NEUTRAL["500"]};
`
);

const RadioItemWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
`
);

export const StorageReceivingSKUCasePackSelection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { scanState, handleSelectionChange } = useStorageReceivingSKUCasePackSelectionFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <SKUCasePackSelectionWrapper>
        <FacilityCard as="section">
          <Box paddingBottom={"S3"}>
            <DarkerCardLabelText>
              {formatMessage(STORAGE_RECEIVING_MESSAGES.caseConfigSelectionTitle)}
            </DarkerCardLabelText>
          </Box>
          {BLIND_INBOUND_PACK_CONFIGURATION[scanState.sellerId]?.map((config) => (
            <RadioItemWrapper key={config.packName}>
              <Radio
                checked={scanState.selectedPackConfig?.packName === config.packName}
                label={
                  <Grid columns="2fr 1fr" alignItems="center" gap="S1">
                    <TextHolder>
                      <StyledText>{config.packName}</StyledText>
                      <Text appearance="INFO" size="caption">
                        {getCaseConfigString(config)}
                      </Text>
                    </TextHolder>
                  </Grid>
                }
                onChange={() => handleSelectionChange(config)}
              />
            </RadioItemWrapper>
          ))}
        </FacilityCard>
      </SKUCasePackSelectionWrapper>
    </>
  );
};
