import React from "react";
import { FormProvider } from "react-hook-form";
import { Redirect, Route, Switch } from "react-router";

import { NCRoutes } from "./base/routes";
import {
  AdditionalCaseDetailsPage,
  BarcodeCard,
  BarcodeUpload,
  BclUpload,
  BoxInformationCard,
  EmailCard,
  ExpirationDate,
  LotExpiryUpload,
  LotNumber,
  NcCdskuCard,
  NcQuantityCard,
  NcReviewCard,
  ProductUploadCard,
} from "./cards";
import { useNonCompliance } from "./useNonCompliance";

export const NonCompliance: React.FC = () => {
  const { form, path } = useNonCompliance();

  return (
    <FormProvider {...form}>
      <Switch>
        <Route exact path={`${path}/${NCRoutes.CDSKU}`} render={() => <NcCdskuCard />} />
        <Route exact path={`${path}/${NCRoutes.TRACKING}`} render={() => <BoxInformationCard />} />
        <Route exact path={`${path}/${NCRoutes.BARCODE}`} render={() => <BarcodeCard />} />
        <Route exact path={`${path}/${NCRoutes.LOT}`} render={() => <LotNumber />} />
        <Route exact path={`${path}/${NCRoutes.EXPIRATION_DATE}`} render={() => <ExpirationDate />} />
        <Route exact path={`${path}/${NCRoutes.BCL_UPLOAD}`} render={() => <BclUpload />} />
        <Route exact path={`${path}/${NCRoutes.LOT_EXPIRY_UPLOAD}`} render={() => <LotExpiryUpload />} />
        <Route exact path={`${path}/${NCRoutes.BARCODE_UPLOAD}`} render={() => <BarcodeUpload />} />
        <Route exact path={`${path}/${NCRoutes.PRODUCT_UPLOAD}`} render={() => <ProductUploadCard />} />
        <Route exact path={`${path}/${NCRoutes.QUANTITY}`} render={() => <NcQuantityCard />} />
        <Route exact path={`${path}/${NCRoutes.DETAILS}`} render={() => <AdditionalCaseDetailsPage />} />
        <Route exact path={`${path}/${NCRoutes.EMAIL}`} render={() => <EmailCard />} />
        <Route exact path={`${path}/${NCRoutes.REVIEW}`} render={() => <NcReviewCard />} />

        <Redirect push to={`${path}/${NCRoutes.CDSKU}`} />
      </Switch>
    </FormProvider>
  );
};
