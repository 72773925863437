import { getEnvStringList } from "@deliverr/commons-utils";

const ZEBRA_DEVICES = getEnvStringList("REACT_ZEBRA_DEVICES", [
  "TC70x",
  "TC75x",
  "TC51",
  "TC57",
  "WT6000",
  "TC72",
  "TC26",
  "TC21",
]);

export const isZebraScanner = ZEBRA_DEVICES.some((device) => navigator.userAgent.includes(device));

// checking for the "native" API that EB provides to know if the user is using EB.
export const isEbBrowser = () => "__rhoNativeApi" in window;
