import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { Input, ThemeProps } from "@deliverr/ui";
import { NumericInput } from "facility-commons/components";
import { FlowCard } from "facility-commons/flow";
import { LoadingButton, WarehouseMessages } from "facility-commons";
import { STORAGE_PRINT_SSCC_LABELS_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useStoragePrintSSCCLabels } from "./useStoragePrintSSCCLabels";

const CenteredDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  white-space: pre-line;
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);

export const StoragePrintSSCCLabels: React.FC = () => {
  const {
    locationId,
    poNum,
    requestSSCCLabels,
    sscc,
    updateLocationId,
    updatePONum,
    updateSSCC,
    loading,
    printDisabled,
    onSubmit,
  } = useStoragePrintSSCCLabels();
  const { formatMessage } = useIntl();

  return (
    <FlowCard title={formatMessage(STORAGE_PRINT_SSCC_LABELS_MESSAGES.printSSCCLabels)} icon="print" accentColor="BLUE">
      <form onSubmit={onSubmit}>
        <CenteredDiv>{formatMessage(WarehouseMessages.scanPO)}</CenteredDiv>
        <NumericInput
          value={poNum}
          placeholder={formatMessage(WarehouseMessages.po)}
          onChange={updatePONum}
          disabled={loading}
          data-testid="po-num-input"
        />
        <CenteredDiv>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPTitle)}</CenteredDiv>
        <Input
          value={sscc}
          placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPPlaceholder)}
          onChange={updateSSCC}
          disabled={loading}
          data-testid="sscc-input"
        />
        <CenteredDiv>{formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLCTitle)}</CenteredDiv>
        <Input
          value={locationId}
          placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLCPlaceholder)}
          onChange={updateLocationId}
          disabled={loading}
          data-testid="location-input"
        />
      </form>

      <LoadingButton
        type="button"
        onClick={requestSSCCLabels}
        disabled={printDisabled}
        data-testid="print-sscc-labels-button"
      >
        {formatMessage(STORAGE_PRINT_SSCC_LABELS_MESSAGES.printSSCCLabels)}
      </LoadingButton>
    </FlowCard>
  );
};
