import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface UnexpectedFefoLotModalProps {
  onCancel: () => void;
  onContinue: () => void;
}

export const UnexpectedFefoLotModal: React.FC<UnexpectedFefoLotModalProps> = ({ onCancel, onContinue }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalTemplate
      buttonLayout="row"
      title={formatMessage(modalsText.expirationLotMismatchTitle)}
      text={formatMessage(modalsText.expirationLotMismatchText)}
      iconProps={warningModalIconLg}
    >
      <Grid columns="1fr" gap="S1" fullWidth>
        <Button block onClick={onContinue}>
          {formatMessage(modalsText.createTicketText)}
        </Button>
        <Button block secondary onClick={onCancel}>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
