import { Button, DefaultTheme, Divider } from "@deliverr/ui";
import styled from "@emotion/styled";
import { MAX_CARD_WIDTH } from "../components.const";

export const PaddedDivider = styled(Divider)`
  border-bottom: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const FlexContainer = styled.section`
  display: flex;
`;

export const LeftAlignedContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SectionContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

export const TwoColumnGrid = styled.section`
  display: grid;
  grid-template-columns: 70% 30%;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

// lighter colored text used for labels in first container
export const LabelText = styled.span<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: inline;
  font-family: ${theme.font.family.STANDARD};
  font-weight: ${theme.font.weight.REGULAR};
  font-size: ${theme.font.size.F1};
`
);

export const CardLabelText = styled(LabelText)<Record<string, unknown>, DefaultTheme>`
  color: ${({ theme }) => theme.colors.NEUTRAL[300]};
  font-size: ${({ theme }) => theme.font.size.F3};
`;

export const DarkerCardLabelText = styled(LabelText)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color:${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F3};
  `
);

// wrap around thumbnail to prevent distortion resulting from fixed width/height on img
export const ThumbnailContainer = styled.div`
  display: inline-block;
  border-radius: 4px;
  height: 59px;
  width: 59px;
`;

export const Thumbnail = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export const MissingLinkThumbnail = Thumbnail.withComponent("div");

export const StyledButton = styled(Button)`
  max-width: ${MAX_CARD_WIDTH};
`;
