import * as React from "react";
import { Textarea } from "@deliverr/ui";
import styled from "@emotion/styled";
import { CharLimitCounter } from "./CharLimitCounter";
import { useCharLimitTextArea } from "./useCharLimitTextArea";
import { useCharLimitValidator } from "./useCharLimitValidator";

const StyledTextArea = styled(Textarea)`
  min-height: 150px;
`;

export interface CharLimitTextAreaProps {
  textAreaValue: string;
  onChange: (text: string) => void;
  charLimit: number;
  placeholder?: string;
  getHasError?: (input: string) => boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
}

export const CharLimitTextArea: React.FC<CharLimitTextAreaProps> = ({
  charLimit,
  textAreaValue: text,
  placeholder,
  getHasError,
  onChange,
  onKeyDown,
}) => {
  const { handleTextChange } = useCharLimitTextArea({ onChange });
  const { getIsInputInvalid } = useCharLimitValidator({ getHasError, charLimit });

  return (
    <>
      <StyledTextArea
        hasError={getIsInputInvalid(text)}
        onChange={handleTextChange}
        placeholder={placeholder}
        value={text}
        onKeyDown={onKeyDown}
        maxLength={charLimit}
      />
      <CharLimitCounter charLimit={charLimit} currentCharCount={text.length} />
    </>
  );
};
