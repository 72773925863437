import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, ButtonProps, DefaultTheme } from "@deliverr/ui";
import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";

import { useNonComplianceCdskuScan } from "./useNonComplianceCdskuScan";
import { CdskuScan } from "warehouse/common/components/CdskuScan";
import styled from "@emotion/styled";
import { WarehouseMessages } from "facility-commons/utils";

const PositionedButton = styled(Button)<ButtonProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  `
);

export const NonComplianceCdskuScan: React.FC = () => {
  const { handleSubmitNonComplianceCdskuScanSuccess, handleNoCdsku } = useNonComplianceCdskuScan();
  const { formatMessage } = useIntl();

  // Modal message cannot be passed in as a FormattedMessage component because
  // it renders before the parameter gets evaluated
  const modalMessage = (asn: ReceivingAsn) => ({
    id: "warehouse.poNumberLookup.message",
    defaultMessage: `Clearly label box with <boldtext>PO # ${asn?.asnId}</boldtext> to help your receiving team. This box is now receivable.`,
  });

  return (
    <CdskuScan
      handleCdskuScanSuccess={handleSubmitNonComplianceCdskuScanSuccess}
      instructions={formatMessage(WarehouseMessages.boxInstructionsText)}
      errorMessage="Please try again or select 'There is no CDSKU'."
      modalMessage={modalMessage}
    >
      <PositionedButton block onClick={handleNoCdsku}>
        <FormattedMessage id="warehouse.nonComplianceCdskuScan.button" defaultMessage="There is no CDSKU" />
      </PositionedButton>
    </CdskuScan>
  );
};
