import { format, Locale } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { DateFormat } from "facility-commons/utils";
import { LanguageCode } from "facility-commons/types";

const LANGUAGE_CODE_TO_LOCALE_MAP: Record<LanguageCode, Locale> = {
  [LanguageCode.ENGLISH]: enUS,
  [LanguageCode.SPANISH]: es,
};

interface FormatI18nDateParams {
  date: Date;
  dateFormat: DateFormat;
  language: LanguageCode;
}

export const formatI18nDate = ({ date, dateFormat, language }: FormatI18nDateParams): string => {
  const locale = LANGUAGE_CODE_TO_LOCALE_MAP[language];
  if (!locale) {
    return format(date, dateFormat);
  }
  return format(date, dateFormat, {
    locale,
  });
};
