import React from "react";
import { Button } from "@deliverr/ui";
import { modalsText } from "../modalsText";
import { dangerModalIcon, ModalTemplate, warningModalIconLg } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { useIncorrectBoxAndUnitQtyModal } from "./useIncorrectBoxAndUnitQtyModal";

export interface IncorrectBoxAndUnitQtyModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  boxesQty: number;
  fewerBoxes: boolean;
  unitsQty: number;
}

export const IncorrectBoxAndUnitQtyModal: React.FC<IncorrectBoxAndUnitQtyModalProps> = ({
  onCancel,
  onConfirm,
  boxesQty,
  fewerBoxes,
  unitsQty,
}) => {
  const { title, text, buttonText, formatMessage } = useIncorrectBoxAndUnitQtyModal({ boxesQty, fewerBoxes, unitsQty });
  return (
    <ModalTemplate
      title={title}
      text={text}
      iconProps={fewerBoxes ? warningModalIconLg : dangerModalIcon}
      data-testid="incorrect-unit-and-box-modal"
    >
      <WarehouseModalRightMarginButton
        block
        onClick={onCancel}
        secondary
        data-testid="incorrect-unit-and-box-modal-cancel"
      >
        {formatMessage(modalsText.back)}
      </WarehouseModalRightMarginButton>
      <Button block onClick={onConfirm}>
        {buttonText}
      </Button>
    </ModalTemplate>
  );
};
