import React, { useCallback } from "react";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingPrintLabels } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { ContinueButton } from "facility-commons";

export const SelectBoxSizeCardContinueButton: React.FC = () => {
  const { transition } = useWarehouseFlow();
  const onClick = useCallback(() => {
    transition({
      card: createOutboundsPackingPrintLabels({}),
    });
  }, [transition]);
  return (
    <ContinueButton
      block
      data-testid="outbounds-order-packing-select-box-size-continue"
      loading={false}
      onClick={onClick}
    />
  );
};
