import React, { forwardRef, useMemo } from "react";
import styled, { css } from "styled-components";
import { Message } from "react-chatbot-ui";
import { Text } from "./Text";
import { Button } from "./Button";
import { ExternalMenuLink } from "../../../../../facility-commons/components/LinksMenu";

const More = ({ page, onPrev, onNext }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-around", color: "black" }}>
      <div style={{ color: "blue", cursor: "pointer" }} onClick={onPrev}>
        &lt; Prev
      </div>
      <div>{page + 1}</div>
      <div style={{ color: "blue", cursor: "pointer" }} onClick={onNext}>
        Next &gt;
      </div>
    </div>
  );
};

interface StyleProps {
  type: Message["type"];
}

interface Props extends StyleProps {
  text?: Message["text"];
  button?: Message["button"];
  onLoadHandler: Function;
  boldEndings?: boolean;
  hasMore?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  buttonsPage?: number;
  url?: string;
}

const content = css`
  border: 1px solid transparent;
  border-radius: 0.5em;
  padding: 0.4em 0.5em;

  width: max-content;
`;

const StyledContent = styled.div`
  text-align: left;
  max-width: 70%;
  ${content}
`;

const StyledButtons = styled.div`
  max-width: 100%;
  ${content}
`;

const StyledBalloonContainer = styled.article<StyleProps>`
  margin: 0.5em;
  color: #f2f2f2;

  ${(props) =>
    props.type === "left" &&
    css`
      & ${StyledContent} {
        background-color: #567ace;
      }
      & ${StyledButtons} button {
        background-color: #567ace;
      }
    `}
  ${(props) =>
    props.type === "right" &&
    css`
      text-align: -webkit-right;

      & ${StyledContent} {
        background-color: #ffffff;
        color: #1a1a6e;
      }

      & ${StyledButtons} button {
        font-size: 80%;
        background-color: #315702;
        &:hover {
          background-color: #63b000;
        }
      }
    `}
`;

export const Balloon = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { type, text, button, hasMore, buttonsPage, onPrev, onNext, boldEndings, url } = props;
  return useMemo(
    () => (
      <StyledBalloonContainer type={type} ref={ref}>
        {url && (
          <StyledContent>
            <ExternalMenuLink link={{ url, text }} />
          </StyledContent>
        )}
        {!url && text && <StyledContent>{!url && <Text text={text} />}</StyledContent>}
        {button && (
          <StyledButtons>
            <Button button={button} boldEndings={boldEndings} />
          </StyledButtons>
        )}
        {hasMore && <More page={buttonsPage} onPrev={onPrev!} onNext={onNext!} />}
      </StyledBalloonContainer>
    ),
    [ref, type, text, button]
  ); // eslint-disable-line react-hooks/exhaustive-deps
});
