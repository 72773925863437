import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { receiveFlowTypeState, receivingState } from "warehouse/receiving/ReceivingState";
import { commonLotMessages, commonReceivingMessages } from "warehouse/receiving/content";
import { modalsText } from "../modalsText";
import { useIntl } from "react-intl";
import { DateInput } from "../../cards/ConfirmationCard/useConfirmationCard";
import { setProp } from "facility-commons/utils";
import { isEmpty } from "lodash";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { MAX_LOT_VALUE_INPUT_LENGTH, MINIMUM_EXPIRE_YEAR, MAXIMUM_EXPIRE_YEAR } from "warehouse/receiving/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useRouter } from "facility-commons/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { MonthDayYearRegex } from "facility-commons/utils";
import { validLotInput } from "facility-commons/utils/config";
import {
  LotInputValidationResult,
  getLotInputValidationResults,
  ExpiryInputValidationResult,
  getExpiryInputValidationResults,
} from "warehouse/receiving/utils";
import { extractNumbersFromDateMask } from "warehouse/receiving/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { storageInboundReceivingStateAtom } from "warehouse/receiving/storage/StorageReceivingState";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createReceivingSKUQuantity } from "warehouse/receiving/storage/storageReceivingCardCreators";
import { isReturnReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";
import { returnReceiveReceivingDataStateAtom } from "warehouse/receiving/ReturnReceive/state";
import { useReturnReceiveFlow } from "warehouse/receiving/ReturnReceive/components/useReturnReceiveFlow";
import { createReturnReceiveLocationScan } from "warehouse/receiving/ReturnReceive/components/returnReceiveCardCreators";

export const useConfirmLotExpiryModal = ({
  requireLotInput,
  requireExpiryInput,
  previousLotInput,
  previousExpiryInput,
  needsToReconfirm,
}) => {
  const { formatMessage } = useIntl();
  const [lotInput, setLotInput] = useState<string>("");
  const [expiryInput, setExpiryInput] = useState<string>("");
  const [lotInputError, setLotInputError] = useState<string>("");
  const [expiryInputError, setExpiryInputError] = useState<string>("");
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const { hideModal, showModal } = useWarehouseModal();
  const { errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const { expectedExpirationDate, expectedLot } = receivingData;

  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const isReturnReceive = isReturnReceiveFlow(receivingFlow);
  const setReturnReceivingData = useSetRecoilState(returnReceiveReceivingDataStateAtom);
  const { nextFlowCard } = useReturnReceiveFlow();

  // For RS app
  const { transition } = useWarehouseFlow();
  const { isStorage } = useRecoilValue(userState);
  const setStorageInboundState = useSetRecoilState(storageInboundReceivingStateAtom);

  const onLotInputChange = (value) => {
    // allow only alphanumeric entries
    if (!validLotInput.test(value)) {
      return;
    }

    // prevent copy and paste
    if (value.length - lotInput.length >= 2) {
      setLotInput("");
      setLotInputError(formatMessage(commonReceivingMessages.doNotCopyAndPaste));
      return;
    }

    setLotInputError("");
    setLotInput(value);
  };

  const onLotInputError = () => {
    errorResponse();
    setLotInput("");
  };

  const onExpiryInputChange = ({ target: { value } }: DateInput) => {
    // this is a hack to prevent copy and paste
    // because of the input mask, the value starts literally as "__/__/____"
    // and gets filled with numbers as the user types ex, "12/1_/____"
    // so we need to remove the underscores, backslashes, and spaces before we can compare lengths
    // this hack is not perfect because the cursor still moves along the input field with each paste attempt.
    const newExpiryInputNumbersOnly = extractNumbersFromDateMask(value);
    const oldExpiryInputNumbersOnly = extractNumbersFromDateMask(expiryInput);
    if (newExpiryInputNumbersOnly.length - oldExpiryInputNumbersOnly.length >= 2) {
      setExpiryInput("");
      setExpiryInputError(formatMessage(commonReceivingMessages.doNotCopyAndPaste));
      return;
    }

    if (!value) {
      return setExpiryInput("");
    }
    setExpiryInputError("");
    setExpiryInput(value);
  };

  const onExpiryInputError = () => {
    errorResponse();
    setExpiryInput("");
  };

  const handleOnConfirmSubmission = () => {
    let requireLotReconfirmation = false;
    if (requireLotInput) {
      const lotValidationResults = getLotInputValidationResults(lotInput, MAX_LOT_VALUE_INPUT_LENGTH);
      switch (lotValidationResults) {
        case LotInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setLotInputError(formatMessage(commonLotMessages.missingLotInput));
        case LotInputValidationResult.EXCEEDED_MAX_CHARACTER_COUNT:
          onLotInputError();
          return setLotInputError(
            formatMessage(commonWarehouseMessages.exceededMaxCharacterCount, {
              characterLimit: MAX_LOT_VALUE_INPUT_LENGTH,
            })
          );
        case LotInputValidationResult.INVALID_INPUT:
          onLotInputError();
          return setLotInputError(formatMessage(commonLotMessages.invalidLotInput));
        case LotInputValidationResult.VALID:
          const lotInputIsDoubleConfirmed = previousLotInput === lotInput;
          const lotInputMatchesExpectedLot = expectedLot && expectedLot.toLowerCase() === lotInput.toLowerCase();
          if (lotInputIsDoubleConfirmed || lotInputMatchesExpectedLot) {
            if (isStorage) {
              setStorageInboundState((state) => ({
                ...state,
                currentSkuDetails: {
                  ...state.currentSkuDetails,
                  lotNumber: lotInput,
                },
              }));
            } else if (isReturnReceive) {
              setReturnReceivingData(setProp("lotNumber", lotInput));
            } else {
              setReceivingData(setProp("lotNumber", lotInput));
            }
          } else {
            requireLotReconfirmation = true;
          }
          break;
      }
    }

    let requireExpiryReconfirmation = false;
    if (requireExpiryInput) {
      const { result: expiryValidationResults, formattedDate, lastDateOfMonth } = getExpiryInputValidationResults(
        expiryInput,
        MINIMUM_EXPIRE_YEAR,
        MAXIMUM_EXPIRE_YEAR
      );
      switch (expiryValidationResults) {
        case ExpiryInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setExpiryInputError(formatMessage(commonReceivingMessages.missingExpirationDateInput));
        case ExpiryInputValidationResult.INVALID_DATE:
          onExpiryInputError();
          return setExpiryInputError(formatMessage(commonReceivingMessages.incorrectExpirationDateFormat));
        case ExpiryInputValidationResult.INVALID_YEAR:
          onExpiryInputError();
          return setExpiryInputError(
            formatMessage(commonReceivingMessages.invalidYear, {
              minYear: MINIMUM_EXPIRE_YEAR,
              maxYear: MAXIMUM_EXPIRE_YEAR,
            })
          );
        case ExpiryInputValidationResult.INVALID_MONTH:
          onExpiryInputError();
          return setExpiryInputError(formatMessage(commonReceivingMessages.invalidMonth));
        case ExpiryInputValidationResult.INVALID_DAY:
          onExpiryInputError();
          return setExpiryInputError(formatMessage(commonReceivingMessages.invalidDay, { lastDateOfMonth }));
        case ExpiryInputValidationResult.EXPIRED_PRODUCT:
          onExpiryInputError();
          return setExpiryInputError(formatMessage(commonReceivingMessages.expiredProduct));
        case ExpiryInputValidationResult.VALID:
          const expiryInputIsDoubleConfirmed = previousExpiryInput === expiryInput;
          const expiryInputMatchesExpectedLot = expectedExpirationDate && expectedExpirationDate === formattedDate;
          if (expiryInputIsDoubleConfirmed || expiryInputMatchesExpectedLot) {
            if (isStorage) {
              setStorageInboundState((state) => ({
                ...state,
                currentSkuDetails: {
                  ...state.currentSkuDetails,
                  expirationDate: expiryInput,
                  formattedExpirationDate: formattedDate,
                },
              }));
            } else if (isReturnReceive) {
              setReturnReceivingData(setProp("expirationDate", formattedDate));
            } else {
              setReceivingData(setProp("expirationDate", formattedDate));
            }
          } else {
            requireExpiryReconfirmation = true;
          }
          break;
      }
    }

    if (requireLotReconfirmation || requireExpiryReconfirmation) {
      errorResponse();
      showModal(WarehouseModal.CONFIRM_LOT_EXPIRY, {
        requireLotInput: requireLotReconfirmation,
        requireExpiryInput: requireExpiryReconfirmation,
        previousLotInput: lotInput,
        previousExpiryInput: expiryInput,
        needsToReconfirm: true,
        onCancel: () => {
          hideModal(WarehouseModal.CONFIRM_LOT_EXPIRY);
        },
      });
      setLotInput("");
      setExpiryInput("");
      return;
    }
    hideModal(WarehouseModal.CONFIRM_LOT_EXPIRY);
    successResponse();

    if (isStorage) {
      transition([
        {
          card: createReceivingSKUQuantity({}),
        },
      ]);
    } else if (isReturnReceive) {
      nextFlowCard(createReturnReceiveLocationScan({}));
    } else {
      push(ReceivingPath.QUANTITY);
    }
  };

  const lotAndExpiryInput = requireLotInput && requireExpiryInput;
  const lotInputOnly = requireLotInput && !requireExpiryInput;

  const modalTitle = needsToReconfirm
    ? formatMessage(
        lotAndExpiryInput
          ? modalsText.reconfirmLotAndExpiryTitle
          : lotInputOnly
          ? modalsText.reconfirmLotTitle
          : modalsText.reconfirmExpiryTitle
      )
    : formatMessage(
        lotAndExpiryInput
          ? modalsText.confirmLotAndExpiryTitle
          : lotInputOnly
          ? modalsText.confirmLotTitle
          : modalsText.confirmExpiryTitle
      );

  const modalText = needsToReconfirm
    ? formatMessage(
        lotAndExpiryInput
          ? modalsText.reconfirmLotAndExpiryText
          : lotInputOnly
          ? modalsText.reconfirmLotText
          : modalsText.reconfirmExpiryText
      )
    : undefined;

  const lotInputPlaceHolder = formatMessage(commonLotMessages.lotNumber);
  const expiryInputPlaceHolder = formatMessage(commonReceivingMessages.expirationDate);
  const expiryHelpText = formatMessage(commonReceivingMessages.expirationDateInputHelpText);

  const backButtonText = formatMessage(modalsText.back);
  const confirmButtonText = formatMessage(modalsText.confirm);

  const disableConfirmButton =
    (requireLotInput && !lotInput) ||
    (requireExpiryInput && !MonthDayYearRegex.test(expiryInput)) ||
    !isEmpty(lotInputError) ||
    !isEmpty(expiryInputError);

  return {
    modalTitle,
    modalText,
    lotInputPlaceHolder,
    expiryInputPlaceHolder,
    expiryHelpText,
    lotInput,
    expiryInput,
    lotInputError,
    expiryInputError,
    onLotInputChange,
    onExpiryInputChange,
    backButtonText,
    confirmButtonText,
    disableConfirmButton,
    handleOnConfirmSubmission,
  };
};
