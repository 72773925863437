import { CharLimitTextAreaProps } from "./CharLimitTextArea";

export const useCharLimitValidator = ({
  getHasError,
  charLimit,
}: Pick<CharLimitTextAreaProps, "getHasError" | "charLimit">) => {
  const getIsInputInvalid = (input: string): boolean => {
    const isOverCharacterLimit = input.length > charLimit;
    return isOverCharacterLimit || Boolean(getHasError?.(input));
  };

  return {
    getIsInputInvalid,
  };
};
