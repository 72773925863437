import { selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { ticketCenterState } from "./ticketCenterState";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";

const getFilterCategoriesList = ({ get }): NonComplianceType[] => {
  const ticketList = get(ticketCenterState);
  return [...new Set(ticketList.ticketList.map((item) => item.type))] as NonComplianceType[];
};

export const NcFilterCategoriesState = selector({
  key: WarehouseStateKey.FILTER_CATEGORIES,
  get: getFilterCategoriesList,
});
