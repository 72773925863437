import { useSetRecoilState } from "recoil";
import { currentSkuState, INITIAL_SKU_STATE } from "warehouse/receiving/ReceivingState";
import { SkuReceivingDetails } from "warehouse/receiving/ReceivingState/Types";

// clear everything but the cdsku in receiving - used for multi-sku receiving
export const useClearSkuData = (clearCDSKU = false) => {
  const setCurrentSku = useSetRecoilState(currentSkuState);
  return () =>
    setCurrentSku((state: SkuReceivingDetails) => ({ ...INITIAL_SKU_STATE, cdsku: clearCDSKU ? "" : state.cdsku }));
};

// clear everything but the cdsku in receiving - used for multi-sku receiving
