import { defineMessages, MessageDescriptor } from "react-intl";

export enum InvalidBarcodeErrorMessageKey {
  BARCODE_NOT_FOUND = "BARCODE_NOT_FOUND",
  BARCODE_NOT_ON_PACKAGE = "BARCODE_NOT_ON_PACKAGE",
  BARCODE_NOT_ON_ASN = "BARCODE_NOT_ON_ASN",
  BARCODE_SELLER_MISMATCH = "BARCODE_SELLER_MISMATCH",
}

export const INVALID_BARCODE_ERROR_LABELS_BY_ERROR_CODE: Record<
  InvalidBarcodeErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [InvalidBarcodeErrorMessageKey.BARCODE_NOT_FOUND]: {
    id: "warehouse.sku.barcodeNotFound",
    defaultMessage: "{barcode} was not found in the WMS. Try again or create a non-compliance ticket.",
  },
  [InvalidBarcodeErrorMessageKey.BARCODE_NOT_ON_PACKAGE]: {
    id: "warehouse.sku.barcodeNotOnPkg",
    defaultMessage: "{barcode} is not expected in this box. Please create a barcode not in WMS non-compliance ticket.",
  },
  [InvalidBarcodeErrorMessageKey.BARCODE_NOT_ON_ASN]: {
    id: "warehouse.sku.barcodeNotOnASN",
    defaultMessage: "{barcode} is not expected for this PO. Please create a barcode not in WMS non-compliance ticket",
  },
  [InvalidBarcodeErrorMessageKey.BARCODE_SELLER_MISMATCH]: {
    id: "warehouse.sku.barcodeSellerMismatch",
    defaultMessage: "{barcode} does not belong to the merchant. Please create a non-compliance ticket.",
  },
});
