import styled from "@emotion/styled";

import React from "react";

import { Page } from "facility-commons/components";
import { MissingWarehouseId } from "facility-commons/MissingWarehouseId";
import { WarehouseHeader } from "../WarehouseHeader";
import { ThemeProps } from "@deliverr/ui";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3};
`
);

export const WarehouseNotFound: React.FC = () => {
  return (
    <Page>
      <WarehouseHeader />
      <Container>
        <MissingWarehouseId />
      </Container>
    </Page>
  );
};
