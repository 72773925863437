import React, { useCallback } from "react";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingCreatePackage } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { BackButton } from "facility-commons";

export const SelectBoxSizeCardBackButton: React.FC = () => {
  const { transition } = useWarehouseFlow();
  const onClick = useCallback(() => {
    transition({
      card: createOutboundsPackingCreatePackage({}),
    });
  }, [transition]);
  return (
    <BackButton block data-testid="outbounds-order-packing-select-box-size-back" loading={false} onClick={onClick} />
  );
};
