import { defineMessages } from "react-intl";

export const quantityMessages = defineMessages({
  countUnitsTitle: {
    id: "warehouse.nonCompliance.countUnitsTitle",
    defaultMessage: "Count non-compliant units",
  },
  countUnitsInstructions: {
    id: "warehouse.nonCompliance.countUnitsInstructions",
    defaultMessage: "Enter the number of units and boxes with this non-compliance issue.",
  },
  boxesPlaceholder: {
    id: "warehouse.nonCompliance.boxesPlaceholder",
    defaultMessage: "Number of boxes",
  },
  unitsPlaceholder: {
    id: "warehouse.nonCompliance.unitsPlaceholder",
    defaultMessage: "Number of units per box",
  },
  numberOfNc: {
    id: "warehouse.nonCompliance.ncBoxes",
    defaultMessage: "Number of non-compliant boxes",
  },
  totalUnits: {
    id: "warehouse.nonCompliance.totalUnits",
    defaultMessage: "Total units",
  },
});
