import { Text, ThemeProps } from "@deliverr/ui";
import { Appearance } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import * as React from "react";
import { DashboardDetailInfoListItem } from "./DashboardDetailInfoListItem";

export interface DashboardDetailInfoListProps {
  addDivider?: boolean;
  /**
   * Removes the border-bottom on the last list item
   */
  removeLastDivider?: boolean;
  items: DashboardDetailInfoListItem[];
  shouldWrapDisplayValue?: boolean;
  shouldHeightBeAuto?: boolean;
}

const ListItem = styled.li<
  ThemeProps<{ addDivider?: boolean; removeLastDivider?: boolean; shouldHeightBeAuto?: boolean }>
>(
  ({ theme, addDivider, removeLastDivider, shouldHeightBeAuto }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${shouldHeightBeAuto ? "auto" : "44px"};
    border-bottom: ${addDivider ? `${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]}` : "none"};

    ${
      removeLastDivider
        ? `
        &:last-of-type {
          border-bottom: none;
        }"`
        : ""
    }
  `
);

const DisplayValue = styled(Text)<ThemeProps<{ shouldWrapDisplayValue?: boolean }>>(
  ({ theme, shouldWrapDisplayValue }) => `
    color: ${theme.colors.NEUTRAL["500"]};
    margin-right: ${theme.spacing.S6};
    white-space: ${shouldWrapDisplayValue ? "normal" : "nowrap"};
  `
);

const Value = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DashboardDetailInfoList: React.FC<DashboardDetailInfoListProps> = ({
  items,
  addDivider,
  removeLastDivider,
  shouldWrapDisplayValue,
  shouldHeightBeAuto,
}) => {
  return (
    <ul>
      {items.map(({ value, displayValue, id, hasError }) => (
        <ListItem
          key={id}
          addDivider={addDivider}
          removeLastDivider={removeLastDivider}
          shouldHeightBeAuto={shouldHeightBeAuto}
        >
          <DisplayValue shouldWrapDisplayValue>{displayValue}</DisplayValue>
          {/* render as span to avoid invalid DOM nesting error- <div> cannot appear as a descendant of <p> */}
          <Value appearance={hasError ? Appearance.DANGER : Appearance.INFO} as="span">
            {value}
          </Value>
        </ListItem>
      ))}
    </ul>
  );
};
