import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

export interface WarehouseAppDataState {
  pageTitle: string;
  pageSubtitle: string;
  problemSolverFlowEnabled: boolean;
  returnsEnabled: boolean;
}

const INITIAL_STATE: WarehouseAppDataState = {
  pageTitle: "",
  pageSubtitle: "",
  problemSolverFlowEnabled: false,
  returnsEnabled: false,
};

export const warehouseAppState = atom<WarehouseAppDataState>({
  key: WarehouseStateKey.WAREHOUSE_APP_DATA,
  default: INITIAL_STATE,
});
