import styled from "@emotion/styled";

import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { Card, ThemeProps } from "@deliverr/ui";

export const FacilityCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL["00"]};
  border:${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: none;
  max-width: ${MAX_CARD_WIDTH};
  padding: ${theme.spacing.S5};
  width: 100%;
`
);
