import { FACILITY_THEME } from "facility-commons/theme";
import { IconV2, IconV2Types, Stack, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { AccentColor, getAccentColorValue } from "../AccentColor";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

export interface ResponseCardProps {
  message: string | JSX.Element;
  accentColor: AccentColor;
  icon?: IconV2Types;
}

const ResponseCardContainer = styled(FacilityCard)<{ accentColor: AccentColor }>(
  ({ accentColor, theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${getAccentColorValue(accentColor)};
  color: ${FACILITY_THEME.colors.NEUTRAL["00"]};
`
);

const CenterTitle = styled(Title)`
  text-align: center;
`;

export const ResponseCard: React.FC<ResponseCardProps> = ({ message, accentColor, icon }) => (
  <ResponseCardContainer as="section" size="SM" accentColor={accentColor} data-testid="response-card-container">
    <Stack center>
      <IconV2 type={icon ?? "copy"} size="2x" />
      <CenterTitle as="h2" displayAs="h4" data-testid="response-card-message">
        {message}
      </CenterTitle>
    </Stack>
  </ResponseCardContainer>
);
