import { useIntl } from "react-intl";
import { commonReceivingMessages, commonReturnReceiveMessage } from "warehouse/receiving/content";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReceivingDataStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "../../state";
import { useRecoilValue } from "recoil";
import { useAsyncFn } from "react-use";
import { log, logStart } from "facility-commons";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useState } from "react";
import { useInspectionRouting, useSubmitInspectionResults } from "../../hooks";
import { WarehouseFeature } from "warehouse/base/WarehouseFeature";
import { isFeatureOn } from "facility-commons";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useSortToLocationCard = () => {
  const { formatMessage } = useIntl();
  const { successResponse, errorResponse } = useCommonFlow();
  const { showModal } = useWarehouseModal();
  const { inspectNextItemOrStartNextOrder } = useInspectionRouting();
  const { submitInspectionResults } = useSubmitInspectionResults();
  const imageCaptureEnabled = isFeatureOn(WarehouseFeature.ENABLE_IMAGE_CAPTURE_FOR_RETURN_RECEIVE);
  const [submissionError, setSubmissionError] = useState<string>("");
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const itemInspectionData = useRecoilValue(returnReceiveItemInspectionStateAtom);
  const returnReceiveData = useRecoilValue(returnReceiveReceivingDataStateAtom);
  const { disposition } = itemInspectionData;

  const [submitState, completeItemInspection] = useAsyncFn(async () => {
    const ctx = logStart({
      fn: "useSortToLocationCard.completeItemInspection",
      returnOrderData,
      itemInspectionData,
      returnReceiveData,
    });

    try {
      await submitInspectionResults({ receiveLocation: null, returnsLocation: null });
      successResponse();
      inspectNextItemOrStartNextOrder();
    } catch (error: any) {
      log({ ...ctx, error }, "error completing item inspection");
      errorResponse();
      setSubmissionError(String(error.message));
    }
  }, [returnOrderData, itemInspectionData, returnReceiveData]);

  const messages = {
    sortToLocation: formatMessage(commonReturnReceiveMessage.sortTolocation),
    itemDisposition: formatMessage(commonReturnReceiveMessage.itemDisposition, { disposition }),
    confirmButtonText: formatMessage(commonReceivingMessages.confirm),
    uploadPhotosOfItem: formatMessage(commonReturnReceiveMessage.uploadPhotosOfItem),
    takePhoto: formatMessage(commonReturnReceiveMessage.takePhoto),
  };

  const onImageUploadError = (errorMessage: string) => {
    errorResponse();
    setSubmissionError(errorMessage);
  };

  const openCamera = () => {
    showModal(WarehouseModal.RETURN_RECEIVE_CAPTURE_ITEM_IMAGE, {});
  };

  return {
    messages,
    submissionError,
    imageCaptureEnabled,
    loading: submitState.loading,
    openCamera,
    completeItemInspection,
    onImageUploadError,
  };
};
