import { NonComplianceErrors } from "@deliverr/commons-clients";
import { ErrorOption } from "react-hook-form";

import {
  minASNLength,
  maxASNLength,
  validASNPattern,
  minBarcodeLength,
  maxBarcodeLength,
  validBarcodePattern,
  validCDSKU,
  maxCDSKULength,
  validEmailPattern,
} from "facility-commons/utils/config";
import { BarcodeNotInWMSField, BarcodeNotInWMSValidationErrorMessages } from "./BarcodeNotInWMSForm.types";

export const validationConfig = {
  [BarcodeNotInWMSField.ASN]: {
    required: BarcodeNotInWMSValidationErrorMessages.ASN_REQUIRED,
    minLength: {
      value: minASNLength,
      message: BarcodeNotInWMSValidationErrorMessages.ASN_FORMAT,
    },
    maxLength: {
      value: maxASNLength,
      message: BarcodeNotInWMSValidationErrorMessages.ASN_FORMAT,
    },
    pattern: {
      value: validASNPattern,
      message: BarcodeNotInWMSValidationErrorMessages.ASN_FORMAT,
    },
  },
  [BarcodeNotInWMSField.BARCODE]: {
    required: BarcodeNotInWMSValidationErrorMessages.BARCODE_REQUIRED,
    minLength: {
      value: minBarcodeLength,
      message: BarcodeNotInWMSValidationErrorMessages.BARCODE_FORMAT,
    },
    maxLength: {
      value: maxBarcodeLength,
      message: BarcodeNotInWMSValidationErrorMessages.BARCODE_FORMAT,
    },
    pattern: {
      value: validBarcodePattern,
      message: BarcodeNotInWMSValidationErrorMessages.BARCODE_FORMAT,
    },
  },
  [BarcodeNotInWMSField.CDSKU]: {
    required: BarcodeNotInWMSValidationErrorMessages.CDSKU_REQUIRED,
    minLength: {
      value: maxCDSKULength,
      message: BarcodeNotInWMSValidationErrorMessages.CDSKU_FORMAT,
    },
    maxLength: {
      value: maxCDSKULength,
      message: BarcodeNotInWMSValidationErrorMessages.CDSKU_FORMAT,
    },
    pattern: {
      value: validCDSKU,
      message: BarcodeNotInWMSValidationErrorMessages.CDSKU_FORMAT,
    },
  },
  [BarcodeNotInWMSField.FRONT_PHOTO]: {
    required: BarcodeNotInWMSValidationErrorMessages.FRONT_PHOTO_REQUIRED,
  },
  [BarcodeNotInWMSField.BACK_PHOTO]: {
    required: BarcodeNotInWMSValidationErrorMessages.BACK_PHOTO_REQUIRED,
  },
  [BarcodeNotInWMSField.QUANTITY]: {
    required: BarcodeNotInWMSValidationErrorMessages.QTY_REQUIRED,
  },
  [BarcodeNotInWMSField.EMAIL]: {
    pattern: {
      value: validEmailPattern,
      message: BarcodeNotInWMSValidationErrorMessages.EMAIL_INVALID,
    },
  },
};

type SetErrorArgs = [string, ErrorOption];

// see function args in https://react-hook-form.com/api#setError
export const nonComplianceServiceValidationMap: { [error: string]: SetErrorArgs } = {
  [NonComplianceErrors.ASN_INVALID]: [
    BarcodeNotInWMSField.ASN,
    { type: "string", message: BarcodeNotInWMSValidationErrorMessages[NonComplianceErrors.ASN_INVALID] },
  ],
  [NonComplianceErrors.CDSKU_INVALID]: [
    BarcodeNotInWMSField.CDSKU,
    { type: "string", message: BarcodeNotInWMSValidationErrorMessages[NonComplianceErrors.CDSKU_INVALID] },
  ],
  [NonComplianceErrors.BARCODE_INVALID]: [
    BarcodeNotInWMSField.BARCODE,
    { type: "string", message: BarcodeNotInWMSValidationErrorMessages[NonComplianceErrors.BARCODE_INVALID] },
  ],
  unknown: [BarcodeNotInWMSField.ASN, { type: "string", message: BarcodeNotInWMSValidationErrorMessages.UNKNOWN }],
};
