import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { ThemeProps } from "@deliverr/ui";
import { TextInputCard } from "facility-commons/flow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useReceivingPOScanFlow } from "./useReceivingPOScanFlow";

const StyledFlowCard = styled(TextInputCard)<ThemeProps>(
  ({ theme }) => `
  height: auto !important;

  input {
    padding-right: ${theme.spacing.S6};
  }
`
);

export const StorageReceivingPOScan: React.FC = () => {
  const { formatMessage } = useIntl();
  const { poNumber, scanError, handleChange, handleSubmit, loading } = useReceivingPOScanFlow();

  return (
    <StyledFlowCard
      errorMessage={scanError}
      message={formatMessage(STORAGE_RECEIVING_MESSAGES.poScanDescription)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.poScanPlaceholder)}
      loading={loading}
      title={formatMessage(STORAGE_RECEIVING_MESSAGES.poScanTitle)}
      value={poNumber ?? ""}
    />
  );
};
