import React from "react";
import { TicketCenterUnknownBarcodeCaseResponse } from "@deliverr/commons-clients";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { BarcodeCaseResolutionCard } from "./BarcodeCaseResolutionCard";

export const UnknownBarcodeResolutionCard: React.FC = () => {
  const props = useTicketDetails<TicketCenterUnknownBarcodeCaseResponse>();

  return <BarcodeCaseResolutionCard {...props} barcode={props.ticketDetails?.barcode} />;
};
