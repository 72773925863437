import { StyledButton, ButtonProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

type ButtonPropKeys = keyof ButtonProps;
const excludedBooleanProps: ButtonPropKeys[] = ["secondary", "block", "asLink"];
const ButtonExcludedProps = styled(StyledButton, {
  // this prevents boolean props from being passed down to withComponent
  shouldForwardProp: (prop) => !excludedBooleanProps.includes(prop as ButtonPropKeys),
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LinkButtonV2 = ButtonExcludedProps.withComponent(Link);
