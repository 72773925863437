import { defineMessages, MessageDescriptor } from "react-intl";

export enum ProductNotInNetworkErrorMessageKey {
  PRODUCT_NOT_IN_NETWORK = "PRODUCT_NOT_IN_NETWORK",
}

export const PRODUCT_NOT_IN_NETWORK_ERROR_BY_ERROR_CODE: Record<
  ProductNotInNetworkErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [ProductNotInNetworkErrorMessageKey.PRODUCT_NOT_IN_NETWORK]: {
    id: "warehouse.receiving.productNotInNetworkError",
    defaultMessage: "Product with barcode {barcode} was not found in the network.",
  },
});
