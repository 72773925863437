export enum OrderPickingField {
  ADDITIONAL_PHOTO = "additionalPhoto1",
  BARCODE = "barcode",
  DAMAGED_PRODUCT_PHOTO = "damagedProductPhoto",
  DISPOSED = "isDisposed",
  EMAIL = "warehouseContactEmail",
  OTHER_DETAILS = "additionalDetails",
  QUANTITY = "damagedQty",
  DAMAGE_TYPE = "damageType",
}

export enum OrderPickingValidationErrorMessage {
  BARCODE_REQUIRED = "Barcode is required",
  BARCODE_INVALID = "Barcode is invalid", // used to re-map error message from commons-clients
  BARCODE_FORMAT = "Invalid barcode it must be DSKU, or UPC/EAN or FNSKU",
  DAMAGED_PRODUCT_PHOTO_REQUIRED = "Damaged Item(s) photo is required",
  QTY_REQUIRED = "Quantity is required",
  UNKNOWN = "Unknown error submitting case, please try again later.",
  EMAIL_INVALID = "Please enter a valid email address",
}
