import { defineMessages, MessageDescriptor } from "react-intl";

export enum DifferentSkusErrorMessageKey {
  TOO_MANY_SKUS_ON_PALLET = "TOO_MANY_SKUS_ON_PALLET",
  TOO_MANY_SKUS_ON_PACKAGE = "TOO_MANY_SKUS_ON_PACKAGE",
}

export const DIFFERENT_SKU_ERROR_BY_ERROR_CODE: Record<
  DifferentSkusErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [DifferentSkusErrorMessageKey.TOO_MANY_SKUS_ON_PALLET]: {
    id: "warehouse.pallet.tooManySkus",
    defaultMessage:
      "This pallet cannot be received because it has multiple SKUs or box configurations. Break pallet and receive boxes.",
  },
  [DifferentSkusErrorMessageKey.TOO_MANY_SKUS_ON_PACKAGE]: {
    id: "warehouse.package.tooManySkus",
    defaultMessage: "This package cannot be received because it has multiple SKUs.",
  },
});
