import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { setProp } from "facility-commons/utils";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLifecycles } from "react-use";
import { useRecoilState, useSetRecoilState } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";
import { LIST_VIEW_CARD_LABELS } from "../ListViewCard/ListViewCard.labels";
import { TicketTabs } from "../TicketDetails/TicketTabs";
import { useTicketList } from "./useTicketList";

export const useTicketCenterDashboard = () => {
  const [{ currentTicketStatusDisplayed, ticketList = [], filteredTicketList }, setTicketCenterState] = useRecoilState(
    ticketCenterState
  );
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();
  const { isLoading } = useTicketList(currentTicketStatusDisplayed);
  useLifecycles(
    () => {
      setWarehouseAppState(setProp("pageTitle", formatMessage(LIST_VIEW_CARD_LABELS.title)));
    },
    () => {
      setWarehouseAppState(setProp("pageTitle", undefined));
    }
  );

  useEffect(() => {
    setTicketCenterState(
      setProp(
        "ticketTab",
        currentTicketStatusDisplayed === TicketCenterNonComplianceCaseStatus.TODO
          ? TicketTabs.RESOLUTION
          : TicketTabs.OVERVIEW
      )
    );
  }, [currentTicketStatusDisplayed, setTicketCenterState]);

  return {
    isLoading,
    ticketList: filteredTicketList.length ? filteredTicketList : ticketList,
    currentTicketStatusDisplayed,
  };
};
