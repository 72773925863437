import React from "react";
import { BoxReset, IconV2, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { TextButton } from "facility-commons/components/TextButton";
import { useCdskuList } from "./useCdskuList";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin-top: -${theme.spacing.S4};
`
);

const CdskuContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  max-height: 210px;
  overflow-y: scroll;
  padding: ${theme.spacing.S4};
  padding-bottom: 0px;
  border: 1px solid ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R4};
  margin-bottom: ${theme.spacing.S3};
`
);

const Cdsku = styled.div<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  color: ${theme.colors.NEUTRAL[400]};
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.S4}
`
);

const Dots = styled.div<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[100]};
  flex-shrink: 2;
`
);

interface CdskuListProps {
  expeditedBulkBoxLabels: string[];
  isExpeditedBulkEligible: boolean;
}
export const CdskuList: React.FC<CdskuListProps> = ({ expeditedBulkBoxLabels, isExpeditedBulkEligible }) => {
  const { showAllCdskus, toggleList } = useCdskuList();

  if (!isExpeditedBulkEligible || expeditedBulkBoxLabels.length === 0) {
    return null;
  }

  return (
    <Container>
      <TextButton onClick={toggleList}>
        <BoxReset marginBottom="S2">
          <Text size="label">
            View all CDSKUS <IconV2 type={showAllCdskus ? "angle-up" : "angle-down"} />
          </Text>
        </BoxReset>
      </TextButton>
      {showAllCdskus ? (
        <CdskuContainer>
          {expeditedBulkBoxLabels.map((label) => (
            <Cdsku key={label}>
              <div>{label}</div>
              <Dots>...............................</Dots>
              <div>
                <strong>{label.slice(-3)}</strong>
              </div>
            </Cdsku>
          ))}
        </CdskuContainer>
      ) : null}
    </Container>
  );
};
