import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { Text, ThemeProps } from "@deliverr/ui";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash/fp";
import { FlowCard } from "facility-commons/flow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundReceivingStateAtom } from "./StorageReceivingState";
import { FORWARDING_STAGING_LOCATION } from "warehouse/common/messages/storage/StorageForwarding.const";
import { isFBABookingType } from "../utils";

const StyledFlowCard = styled(FlowCard)<ThemeProps>(
  ({ theme }) => `
    height: auto !important;
    padding-top: ${theme.spacing.S4};
    padding-bottom: ${theme.spacing.S4};

    p {
      margin: 0;
    }
  `
);

const StyledFlowCardContent = styled.p<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["300"]};
  `
);

export const StorageReceivingPODetails: React.FC = () => {
  const { formatMessage } = useIntl();
  const [scanState] = useRecoilState(storageInboundReceivingStateAtom);

  return (
    <>
      <StyledFlowCard
        message={
          scanState.currentSkuDetails?.barcode || scanState.currentSkuDetails?.barcodes
            ? `${scanState.currentSkuDetails?.barcode || scanState.currentSkuDetails?.barcodes?.[0]} - ${
                scanState.currentSkuDetails?.name
              }`
            : ""
        }
      >
        <Text>
          {isFBABookingType(scanState.poDetails.bookingType)
            ? formatMessage(STORAGE_RECEIVING_MESSAGES.flexIdTitle, {
                flexID: scanState.poDetails?.bookingID,
                forwardingLocation: FORWARDING_STAGING_LOCATION,
              })
            : formatMessage(STORAGE_RECEIVING_MESSAGES.poNumberDetailsTitle, { poNum: scanState.poNum })}
        </Text>
        <StyledFlowCardContent>
          {formatMessage(STORAGE_RECEIVING_MESSAGES.poDetailsDescription, {
            totalSKUs: scanState.poDetails?.numberOfSkus ?? 1,
          })}
        </StyledFlowCardContent>
        {!isEmpty(scanState.currentSkuDetails) && (
          <>
            {!!scanState.locationReceivedQty?.receivedQty && (
              <StyledFlowCardContent>
                {formatMessage(STORAGE_RECEIVING_MESSAGES.poDetailsReceivedDescription, {
                  receivedUnits: scanState.locationReceivedQty?.receivedQty ?? 0,
                })}
              </StyledFlowCardContent>
            )}
            {scanState.currentSkuDetails?.qty && (
              <StyledFlowCardContent>
                {formatMessage(STORAGE_RECEIVING_MESSAGES.poDetailsCurrentReceivingUnitsDescription, {
                  currentReceivingUnits: scanState.currentSkuDetails?.qty ?? 0,
                })}
              </StyledFlowCardContent>
            )}
          </>
        )}
      </StyledFlowCard>
    </>
  );
};
