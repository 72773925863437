import { Box } from "@deliverr/ui";
import { LoadingSpinner } from "facility-commons/components";
import * as React from "react";

export interface DashboardListProps<ItemType extends Record<string, any>> {
  items: ItemType[];
  ItemRenderer: React.ComponentType<ItemType>;
  /** A function to generate a unique key for a given item */
  keyFn: (item: ItemType) => string;
  /** An optional element to show if there are no items */
  emptyDisplay?: JSX.Element;
  isLoading?: boolean;
}

export function DashboardList<ItemType extends Record<string, any>>({
  items,
  ItemRenderer,
  isLoading,
  emptyDisplay,
  keyFn,
}: DashboardListProps<ItemType>) {
  if (isLoading) {
    return (
      <Box marginTop="S4">
        <LoadingSpinner loading />
      </Box>
    );
  }

  if (emptyDisplay && items.length === 0) {
    return emptyDisplay;
  }

  return (
    <ul>
      {items.map((item) => (
        <li key={keyFn(item)}>
          <ItemRenderer {...item} />
        </li>
      ))}
    </ul>
  );
}
