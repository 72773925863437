import { useRecoilState, useSetRecoilState } from "recoil";
import { isUndefined } from "lodash/fp";
import { Printer, PrinterStatus, PrinterSearchOptions } from "./ZebraTypes";
import { log, logError, logStart } from "facility-commons/utils";
import {
  getLastConnectedPrinterId,
  getLastUsedHorizontalOffset,
  getLastUsedDpi,
  setLastConnectedPrinterId,
  getLastConnectedPrinterAddress,
  setLastConnectedPrinterAddress,
} from "./PrinterZebraStorage";
import {
  printersState,
  connectedPrinterState,
  isSearchingPrintersState,
  dpiState,
  horizontalOffsetState,
  isConnectingToPrinterState,
} from "./PrinterZebraState";
import { NETWORK_PRINTERS_SEARCH_OPTIONS, getSearchAtAddressPrinterSearchOptions } from "./printerSearchConfig";

export function usePrinterZebra(printerSearchOptions: PrinterSearchOptions = NETWORK_PRINTERS_SEARCH_OPTIONS) {
  const [printers, setPrinters] = useRecoilState(printersState);
  const [connectedPrinter, setConnectedPrinter] = useRecoilState(connectedPrinterState);
  const setIsSearchingPrinters = useSetRecoilState(isSearchingPrintersState);
  const setDpi = useSetRecoilState(dpiState);
  const setHorizontalOffset = useSetRecoilState(horizontalOffsetState);
  const setIsConnectingToPrinter = useSetRecoilState(isConnectingToPrinterState);

  function refreshPrinter(printerId: string): void {
    const ctx = logStart({ fn: "refreshPrinter", printerId });

    const matchingPrinterIndex = printers.findIndex(({ ID }) => ID === printerId);
    if (matchingPrinterIndex === -1) {
      log(ctx, "missing printer");
      return;
    }

    log(ctx, "refreshing matching printer", { matchingPrinterIndex, printers });
    const updatedPrinter = window?.EB?.PrinterZebra.getPrinterByID(printerId);
    setPrinters((oldPrinters) => {
      const newPrinters = [...oldPrinters];
      newPrinters[matchingPrinterIndex] = updatedPrinter;
      return newPrinters;
    });
  }

  async function disconnectPrinter(printer: Printer): Promise<void> {
    const ctx = logStart({ fn: "disconnectPrinter", printerId: printer.ID });
    setIsConnectingToPrinter(true);

    return new Promise((resolve) => {
      printer.disconnect((status) => {
        log({ ...ctx, status }, "printer disconnected");
        refreshPrinter(printer.ID);
        setIsConnectingToPrinter(false);
        resolve();
      });
    });
  }

  async function disconnectPrinters(): Promise<void> {
    const ctx = logStart({ fn: "disconnectPrinters", connectedPrinterExists: connectedPrinter !== undefined });

    const connectedPrinters = printers.filter((printer) => printer.isConnected);
    for (const printer of connectedPrinters) {
      log({ ...ctx, printerId: printer.ID, printerName: printer.deviceName }, "disconnecting printer");
      await disconnectPrinter(printer);
    }

    setConnectedPrinter(undefined);
  }

  function setConnectedPrinterState(printer: Printer): void {
    setConnectedPrinter(printer);
    setLastConnectedPrinterId(printer.ID);
    setLastConnectedPrinterAddress(printer.deviceAddress);
    setIsConnectingToPrinter(false);
  }

  async function connectToPrinter(printer: Printer): Promise<void> {
    const connectCtx = { fn: "connectToPrinter", printerId: printer.ID, printer };
    setIsConnectingToPrinter(true);

    return new Promise((resolve) => {
      if (printer.isConnected) {
        log(connectCtx, "already connected to printer");
        setConnectedPrinterState(printer);
        resolve();
      } else {
        printer.connect((status) => {
          log({ ...connectCtx, status }, "connected to printer");
          setConnectedPrinterState(printer);
          resolve();
        });
      }
    });
  }

  function searchPrinters(
    options: PrinterSearchOptions,
    onFoundPrinter: (printer: Printer) => void,
    onComplete?: () => void
  ): void {
    const ctx = logStart({ fn: "searchPrinters", options });
    setIsSearchingPrinters(true);

    window?.EB?.PrinterZebra.searchPrinters(options, async (cb: any) => {
      if (cb.status !== PrinterStatus.SUCCESS) {
        logError(ctx, new Error(cb.status));
      }

      // printerID defined while still searching for printers and found a new printer
      // printerID undefined when search is finished
      if (isUndefined(cb.printerID)) {
        log(ctx, "finished searching printers");
        setIsSearchingPrinters(false);
        onComplete?.();
      } else {
        log(ctx, "found printer", cb);
        onFoundPrinter(window?.EB?.PrinterZebra.getPrinterByID(cb.printerID));
      }
    });
  }

  // quick initial search to reconnect to last printer if still available, followed by a full network search
  const searchLastConnectedPrinter = (lastConnectedPrinterAddress: string, lastConnectedPrinterId: string) =>
    searchPrinters(
      getSearchAtAddressPrinterSearchOptions(lastConnectedPrinterAddress, printerSearchOptions),
      async (printer: Printer) => {
        // when search by address, found printer won't necessarily have same ID
        // if it's a different printer than expected
        if (lastConnectedPrinterId === printer.ID) {
          log(
            { fn: "searchLastConnectedPrinter", lastConnectedPrinterAddress, lastConnectedPrinterId },
            "last connected printer found, reconnecting"
          );
          await connectToPrinter(printer);
          setPrinters((oldPrinters) => [...oldPrinters, printer]);
        }
      },
      () => searchAllPrinters([lastConnectedPrinterId])
    );

  // exclude certain printers to prevent duplicate results
  const searchAllPrinters = (excludablePrinterIds: string[] = []) =>
    searchPrinters(printerSearchOptions, async (printer: Printer) => {
      const isExcludedPrinter = excludablePrinterIds.includes(printer.ID);

      if (!isExcludedPrinter) {
        if (printer.isConnected && !connectedPrinter) {
          await disconnectPrinter(printer);
        }
        setPrinters((oldPrinters) => [...oldPrinters, printer]);
      }
    });

  function initiatePrinterSearch() {
    const isFirstSearch = printers.length === 0;
    logStart({ fn: "initiatePrinterSearch", isFirstSearch });
    // keep connected in refresh results
    setPrinters(connectedPrinter ? [connectedPrinter] : []);

    const lastUsedDpi = isFirstSearch ? getLastUsedDpi() : undefined;
    if (lastUsedDpi) {
      setDpi(lastUsedDpi);
    }

    const lastUsedHorizontalOffset = isFirstSearch ? getLastUsedHorizontalOffset() : undefined;
    if (lastUsedHorizontalOffset) {
      setHorizontalOffset(lastUsedHorizontalOffset);
    }

    // prevent reconnect while refreshing printer list by using isFirstSearch
    const lastConnectedPrinterId = isFirstSearch ? getLastConnectedPrinterId() : undefined;
    const lastConnectedPrinterAddress = isFirstSearch ? getLastConnectedPrinterAddress() : undefined;
    if (lastConnectedPrinterAddress && lastConnectedPrinterId) {
      searchLastConnectedPrinter(lastConnectedPrinterAddress, lastConnectedPrinterId);
    } else {
      // prevent re-adding connected printer
      const excludablePrinterIds = connectedPrinter ? [connectedPrinter.ID] : [];
      searchAllPrinters(excludablePrinterIds);
    }
  }

  return {
    initiatePrinterSearch,
    disconnectPrinter,
    disconnectPrinters,
    connectToPrinter,
  };
}
