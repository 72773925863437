import React from "react";
import { DefaultTheme, Stack, Text, ThemeProps, BoxReset, Notification, Appearance, Anchor } from "@deliverr/ui";
import { useIntl } from "react-intl";
import { LinkCard } from "warehouse/common/components/LinkCard";
import { commonReceivingMessages, locationMessages } from "warehouse/receiving/content";
import styled from "@emotion/styled";
import { useReceiveTypeSelector } from "./useReceiveTypeSelector";
import { DefaultLocationToggle, DefaultLocationLink } from "../menu";
import { WarehousePaths } from "warehouse/common/components/AppBar/useAppBar";
import { useRouter } from "facility-commons/hooks";
import { WarehouseMessages } from "facility-commons";

export const StyledStack = styled(Stack)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  // styling warehouseCards based on position to make them stacked.
  a {
    margin: 0;
    div {
      border-radius: 0;
    }
    :first-of-type div {
      border-radius: ${theme.border.radius.R5} ${theme.border.radius.R5}  0 0;
    }
    :last-of-type div {
      border-bottom-left-radius:${theme.border.radius.R5};
      border-bottom-right-radius: ${theme.border.radius.R5};
    }
    :not(:last-of-type) div {
      border-bottom: 0;
    }
  }
`
);

const StyledText = styled(Text)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  color:${theme.colors.NEUTRAL[400]};
  margin-bottom:${theme.spacing.S2}
`
);
const AnalyticsContainer = styled.div<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2}
  `
);
const LanguageNotification = styled.div<ThemeProps, DefaultTheme>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledAnchor = styled(Anchor)<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F3};
    cursor: pointer;
  `
);

export const ReceiveTypeSelector: React.FC = () => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();

  const {
    boxReceive,
    palletReceive,
    consolidation,
    returnReceive,
    analytics,
    palletsAnalytics,
    isUserTrackingAllowed,
    isConsolidationAllowed,
    isPalletReceivingAllowed,
    isReturnReceiveAllowed,
    shouldShowPalletDashboard,
    shouldShowAnalytics,
    shouldShowPalletAnalyticsV2Link,
    palletAnalyticsV2,
  } = useReceiveTypeSelector();

  return (
    <>
      <BoxReset marginBottom="S4">
        <Notification
          appearance={Appearance.LAUNCH}
          content={{
            description: (
              <LanguageNotification>
                <div>
                  <Text>NEW! Select your preferred language.</Text>
                  <Text>NUEVO! ¡Seleccione su idioma preferido.</Text>
                </div>

                <StyledAnchor onClick={() => push(WarehousePaths.more)}>
                  {formatMessage(WarehouseMessages.view)}
                </StyledAnchor>
              </LanguageNotification>
            ),
          }}
        />
      </BoxReset>
      <StyledStack>
        <StyledText bold size="bodyLarge">
          {formatMessage(commonReceivingMessages.startReceiving)}
        </StyledText>
        <LinkCard
          description={boxReceive.description}
          title={boxReceive.title}
          to={boxReceive.link}
          onClick={boxReceive.onClick}
          data-testid="box-receive-selection"
        />
        {isPalletReceivingAllowed && (
          <LinkCard
            description={palletReceive.description}
            title={palletReceive.title}
            to={palletReceive.link}
            onClick={palletReceive.onClick}
            data-testid="pallet-receive-selection"
          />
        )}
        {isConsolidationAllowed && (
          <LinkCard
            description={consolidation.description}
            title={consolidation.title}
            to={consolidation.link}
            onClick={consolidation.onClick}
            data-testid="consolidation-receive-selection"
          />
        )}
        {isReturnReceiveAllowed && (
          <LinkCard
            description={returnReceive.description}
            title={returnReceive.title}
            to={returnReceive.link}
            onClick={returnReceive.onClick}
            data-testid="return-receive-selection"
          />
        )}
      </StyledStack>
      <BoxReset marginTop="S4">
        <div>
          <StyledText bold size="bodyLarge">
            {formatMessage(locationMessages.defaultStow)}
          </StyledText>
          <DefaultLocationToggle />
          <DefaultLocationLink />
        </div>
      </BoxReset>
      {shouldShowAnalytics && (
        <AnalyticsContainer>
          <StyledStack>
            <StyledText bold size="bodyLarge">
              {formatMessage(commonReceivingMessages.analytics)}
            </StyledText>
            {
              // Pallet Analytics V2
              shouldShowPalletAnalyticsV2Link && (
                <LinkCard
                  description={palletAnalyticsV2.description}
                  title={palletAnalyticsV2.title}
                  to={palletAnalyticsV2.link}
                />
              )
            }
            {
              // Pallet Dashboard
              shouldShowPalletDashboard && (
                <LinkCard
                  description={palletsAnalytics.description}
                  title={palletsAnalytics.title}
                  to={palletsAnalytics.link}
                />
              )
            }
            {
              // Receiving App Dashboard
              isUserTrackingAllowed && (
                <LinkCard
                  loading={analytics.link.state.loading}
                  description={analytics.description}
                  title={analytics.title}
                  to={analytics.link}
                />
              )
            }
          </StyledStack>
        </AnalyticsContainer>
      )}
    </>
  );
};
