import logger, { initLogStream } from "../../logger";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { InitConfiguration } from "@datadog/browser-core";

declare const VERSION: string; // This value is set via webpack.config.js

export const getIsValidDataDogEnv = () => window.location.href.startsWith("https://warehouseportal.deliverr.com");

interface EventOverrides {
  view?: {
    url?: string;
    referrer?: string;
  };
}

export function initDataDog() {
  if (!getIsValidDataDogEnv()) {
    return;
  }

  // Once upon a time there was a push to move this Datadog and the Logger file into @deliverr/ui-facility
  // a member of the Prep team had begun pulling those utilities into this repo, but this lead to clashes in versions
  // of datadog libraries that were being initialized and set to the log stream in a competing fashion.
  // This broke our datadog logging. This makes it so that when this version of initDatadog is called, we ensure the correct
  // datadog logger is applied to the log stream.
  if (typeof window !== "undefined") {
    // @ts-ignore
    window.DD_LOGS = datadogLogs;
    initLogStream();
  }

  const baseConfig: Pick<InitConfiguration, "clientToken" | "site" | "service" | "env" | "version"> = {
    clientToken: "pubc41185b08d62e8a0a338496c2bedd987",
    site: "datadoghq.com",
    service: "warehouseportal-prod",
    env: "production",
    version: `${VERSION}`,
  };

  // Initialize Browser Logger
  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  logger.info({ fn: "initDataDog" }, "Initializing DataDog!");
  // User monitoring / session replay
  datadogRum.init({
    ...baseConfig,
    applicationId: "9408376c-a548-48a7-ba14-0ea7390dfa39",
    // do not sample (collect 100% of events)
    sessionSampleRate: 100,
    // do not sample (collect 100% of session replays)
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    // Masks all data in forms
    defaultPrivacyLevel: "allow",
    // This requires server-side changes to permit DataDog headers
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    // allowedTracingOrigins: [/https:\/\/fulfillment\.(prod|staging)\.deliverr\.com/],
    beforeSend: (event) => {
      // allow overriding event attributes, used for ensuring timing for actions with redirects reflect the initiating view
      const overrides = event.context?.overrides as EventOverrides;
      const viewOverrides = overrides?.view;
      if (viewOverrides) {
        Object.keys(viewOverrides).forEach((k) => {
          event.view[k] = viewOverrides[k];
        });
      }
      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
}

interface DataDogIdentityUpdate {
  user?: {
    id: string;
    warehouseId: string;
    userName: string;
    email: string;
    isManager: boolean;
    isAdmin: boolean;
    isHub: boolean;
    isCrossdock: boolean;
  };
}

export function updateDataDogIdentity({ user }: DataDogIdentityUpdate) {
  if (!getIsValidDataDogEnv() || !user) {
    return;
  }
  const ctx = { fn: "updateDataDogIdentity", user: user?.email };
  logger.info(ctx, "Updating DataDog user");
  datadogRum.setUser({
    id: user.id,
    email: user.email,
    warehouseId: user.warehouseId,
    userName: user.userName,
    isManager: user.isManager,
    isAdmin: user.isAdmin,
    isHub: user.isHub,
    isCrossdock: user.isCrossdock,
  });
}
