import { isEmpty } from "lodash";
import React from "react";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { DashboardDetailExpansionBlock } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { DashboardDetailInfoList } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList";
import { TicketCardThumbnails } from "warehouse/ticket-center/common/components/TicketCardThumbnails";
import { CenteredSpinner } from "../CenteredSpinner";
import { TicketCenterOverviewNotesSection } from "../TicketCenterOverviewNotesSection";
import { OverviewCardActionSection } from "../OverviewCardActionSection";
import { useLotFefoOverViewCard } from "./useLotFefoOverViewCard";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks";
import { TicketCenterInvalidLotExpiryCaseResponse } from "@deliverr/commons-clients";

export const LotFefoOverviewCard = () => {
  const { ticketDetails, loading, showDeleteModal, handleThumbnailClick } = useTicketDetails<
    TicketCenterInvalidLotExpiryCaseResponse
  >();

  const { initialInfoList, quantityList, quantityTitle } = useLotFefoOverViewCard({
    ticketDetails,
  });

  if (loading || !ticketDetails) {
    return <CenteredSpinner loading />;
  }
  return (
    <div>
      <DashboardDetailCard createdAt={new Date(ticketDetails.createdAt)}>
        <DashboardDetailInfoList addDivider items={initialInfoList} />
        <DashboardDetailExpansionBlock addDivider disableExpansion title={quantityTitle}>
          <DashboardDetailInfoList items={quantityList} />
        </DashboardDetailExpansionBlock>
        <TicketCardThumbnails
          addDivider={!isEmpty(ticketDetails.notes)}
          addBottomPadding
          onThumbnailClick={handleThumbnailClick}
          thumbnails={ticketDetails.pictures.map((pic) => `${pic}`)}
        />
        <TicketCenterOverviewNotesSection notes={ticketDetails.notes} addDivider />
      </DashboardDetailCard>
      <OverviewCardActionSection status={ticketDetails.status} showDeleteModal={showDeleteModal} />
    </div>
  );
};
