import { FACILITY_THEME } from "facility-commons/theme";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import React from "react";
import { FlowCardsStateProps } from "./types/FlowState";

const BlockOrderedList = styled.ol`
  width: 100%;
  max-width: 30rem;
`;

const FlowListItem = styled.li`
  margin-bottom: ${FACILITY_THEME.spacing.S4};
`;

export const FlowVisibleCards: React.FC<FlowCardsStateProps> = ({ cardsState, cardMap }) => {
  const flowCards = useRecoilValue(cardsState);

  return (
    <BlockOrderedList>
      {flowCards.map(({ type, props }, i) => {
        const FlowCard = cardMap[type];
        const disabled = flowCards.length > 1 && i < flowCards.length - 1;

        return (
          <FlowListItem key={type}>
            <FlowCard disabled={disabled} {...props} newest={i === flowCards.length - 1} />
          </FlowListItem>
        );
      })}
    </BlockOrderedList>
  );
};
