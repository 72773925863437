import { useCallback, useContext, useState } from "react";
import { configContext } from "../lib/configContext";
import { FileMetaDataDTO } from "@deliverr/wot-client";

export function useWotFileUploader(props: { fileMetaData: FileMetaDataDTO }) {
  const [uploading, setIsUploading] = useState<boolean>(false);
  const config = useContext(configContext);

  const onInputFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (props.fileMetaData.uploadUrl.length && files?.length) {
      setIsUploading(true);
      const fileToUpload = files[0];
      await uploadFile(props.fileMetaData.uploadUrl, fileToUpload);
      setIsUploading(false);
      if (config?.sendCallback) {
        config.sendCallback({ payload: `File uploaded successfully! url:${props.fileMetaData.downloadUrl}` });
      }
    }
  }, []);

  return {
    onInputFileChange,
    uploading,
  };
}

const uploadFile = async (url: string, file: File) => {
  const headers = new Headers();
  headers.append("Content-Type", file.type);
  return await fetch(url, {
    method: "PUT",
    headers,
    body: file,
    mode: "cors",
  });
};
