import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

export interface StorageArrivalScanState {
  asnId?: string | null;
  arrivedAt?: Date | number;
}

export const storageInboundStateAtom = atom<StorageArrivalScanState>({
  key: WarehouseStateKey.STORAGE_ARRIVAL_SCAN,
  default: {},
});
