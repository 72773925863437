import { PurchaseLabelDTO } from "@deliverr/wholesale-client";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { BoxSizeToDimsMap } from "../SelectBoxSizeCard/BoxSizeToDimsMap";
import { log, logStart, printPdf } from "facility-commons";
import { useAsync } from "react-use";
import { convertBase64StringToBlob } from "facility-commons/utils/convertBase64StringToBlob";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingShipmentSummary } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";

export const usePrintLabelCard = () => {
  const { wholesaleClient } = useClientsWithAuth();
  const { currentShipmentId, currentPackageDskuToQtyMap, selectedBoxSize } = useRecoilValue(rsOutboundsStateAtom);
  const { length, width, height } = selectedBoxSize
    ? BoxSizeToDimsMap[selectedBoxSize]
    : { length: 0, width: 0, height: 0 };
  const { errorResponse } = useCommonFlow();
  const ctx = logStart({ fn: "useShipmentCard.handleSubmit" });
  const updateState = useSetRecoilState(rsOutboundsStateAtom);
  const { transition } = useWarehouseFlow();

  const purchaseLabel: PurchaseLabelDTO = {
    shipmentId: currentShipmentId,
    length,
    width,
    height,
    weight: 0,
    skuMap: currentPackageDskuToQtyMap,
  };

  const { loading, value } = useAsync(async () => {
    try {
      const caseLabel = await wholesaleClient.generateCaseLabelPdf(purchaseLabel);
      if (caseLabel) {
        const pdfBlob = convertBase64StringToBlob(caseLabel.data.contents);
        printPdf(pdfBlob);
        return pdfBlob;
      }
    } catch (error) {
      log({ ...ctx }, "error when getting case label");
      errorResponse();
    }
  }, []);

  const onConfirmClick = async () => {
    try {
      const updatedShipmentDetails = await wholesaleClient.getShipmentAdminAccess(currentShipmentId);
      updateState((prevState) => ({
        ...prevState,
        shipmentDetails: updatedShipmentDetails.data,
        currentPackageDskuToQtyMap: {},
      }));
    } catch (error) {
      log({ ...ctx }, "error when getting updatedShipmentDetails");
      errorResponse();
    }
    transition({
      card: createOutboundsPackingShipmentSummary({}),
    });
  };

  return {
    isLoading: loading,
    pdfBlob: value,
    onConfirmClick,
  };
};
