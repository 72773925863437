import { warehouseDataState } from "warehouse/base/warehouseIdState";
import { WarehouseCapacity } from "@deliverr/commons-clients/lib/outbound-load-balancer/WarehouseCapacity";
import { userState } from "facility-commons/base/Auth/userState";

export const mockWarehouseDataState = {
  recoilState: warehouseDataState,
  initialValue: {
    outboundCapacityUnits: 100,
    outboundCapacityUnitsMonday: 100,
  },
};

export const mockUserState = {
  recoilState: userState,
  initialValue: {
    warehouseId: "ALP_JEF",
    isAdmin: true,
  },
};

const date = new Date(Date.now() + 60 * 1000);
const dateDeadline = new Date(date.setHours(17));
const nextDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
const nextDateDeadline = new Date(nextDate.setHours(17));

export const mockWarehouseCapacityResponse: WarehouseCapacity[] = [
  {
    capacityTrackingDayStart: dateDeadline,
    createdAt: date,
    isActive: true,
    maxReleaseTime: dateDeadline,
    sentShipmentQty: 0,
    sentUnitQty: 0,
    shipDate: dateDeadline,
    shipmentLimit: 50,
    originalUnitLimit: 100,
    unitLimit: 100,
    updatedAt: date,
    warehouseId: "ALP_JEF",
  },
  {
    capacityTrackingDayStart: nextDateDeadline,
    createdAt: nextDate,
    isActive: false,
    maxReleaseTime: nextDateDeadline,
    sentShipmentQty: 0,
    sentUnitQty: 0,
    shipDate: nextDateDeadline,
    shipmentLimit: 50,
    unitLimit: 200,
    originalUnitLimit: 200,
    updatedAt: nextDate,
    warehouseId: "ALP_JEF",
  },
];

export const useOutboundCapacityObject = {
  capacityData: mockWarehouseCapacityResponse[0],
  isLoading: false,
  isSaving: false,
  isError: false,
  initialCapacity: mockWarehouseCapacityResponse[0].unitLimit,
  cutoffTime: date.toLocaleString("en-US", { hour: "numeric", hour12: true }),
  sliderValue: mockWarehouseCapacityResponse[0].unitLimit,
  min: 50,
  max: 150,
  diff: 0,
};
