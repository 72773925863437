import { defineMessages } from "react-intl";

export const bulkErrorMessages = defineMessages({
  duplicateCdsku: {
    id: "warehouse.receiving.duplicateCdsku",
    defaultMessage: "This CDSKU has already been scanned. Scan a new box.",
  },
  invalidCdsku: {
    id: "warehouse.receiving.invalidCdsku",
    defaultMessage:
      "This CDSKU does not match what you are bulk receiving. Receive this box separately and update the bulk quantity.",
  },
});

export const bulkMessages = defineMessages({
  title: {
    id: "warehouse.receiving.box.bulkTitle",
    defaultMessage: "Receive Box",
  },
  units: {
    id: "units",
    defaultMessage: "units",
  },
});
