import { DefaultTheme, IconV2, IconV2Props, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useState } from "react";

import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";

interface Props {
  defaultOpen?: boolean;
  hiddenContent: React.ReactNode;
  primaryDate?: Date | string;
  secondaryDate?: any;
  primaryDateText?: Date | string;
  secondaryDateText?: string;
  title: string;
}

export type ExpansionCardProps = React.PropsWithChildren<Props>;

const ExpandableCard = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL["00"]};
  border: 1px solid ${theme.colors.NEUTRAL[100]};
  border-radius: 8px;
  color: ${theme.colors.NEUTRAL[400]};
  margin-bottom: ${theme.spacing.S4};
  margin-left: auto;
  margin-right: auto;
  max-width: ${MAX_CARD_WIDTH};
  padding: ${theme.spacing.S4};
  position: relative;
  width: 100%;
`
);

const ClickArea = styled.button`
  background: none;
  outline: none;
  text-align: start;
  width: 100%;

  :active {
    border: none;
  }
`;

const Carrot = styled(IconV2)<IconV2Props, DefaultTheme>`
  position: absolute;
  right: ${({ theme }) => theme.spacing.S4};
  top: ${({ theme }) => theme.spacing.S4};
  transform: rotate(90deg);
`;

export const CardTitle = styled(Title)<Record<string, unknown>, DefaultTheme>`
  color: ${({ theme }) => theme.colors.NEUTRAL[500]};
`;

// lighter colored text used for labels in first container
export const LabelText = styled.span<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: #a2a7b1;
  display: inline;
  font-family: ${theme.font.family.STANDARD};
  font-weight: ${theme.font.weight.REGULAR};
  font-size: ${theme.font.size.F1};
`
);

const DateText = styled(LabelText)<Record<string, unknown>, DefaultTheme>`
  color: ${({ theme }) => theme.colors.NEUTRAL[400]};
`;

export const ExpansionCard: React.FC<ExpansionCardProps> = (props) => {
  const { defaultOpen, hiddenContent, primaryDate, primaryDateText, secondaryDate, secondaryDateText, title } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const showTwoDates = secondaryDateText && secondaryDate && isOpen;

  const toggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setIsOpen((prevOpenState) => !prevOpenState);
  };

  return (
    <ExpandableCard>
      <ClickArea aria-expanded={isOpen} onClick={toggleOpen}>
        <CardTitle displayAs="h4" as="h4">
          {title}
        </CardTitle>
        <Carrot type={isOpen ? "angle-left" : "angle-right"} />
        <div>
          <LabelText style={{ marginRight: "8px" }}>{primaryDateText} </LabelText>
          <DateText>{primaryDate}</DateText>
        </div>

        {showTwoDates && (
          <div>
            <LabelText style={{ marginRight: "8px" }}>{secondaryDateText} </LabelText>
            <DateText>{secondaryDate}</DateText>
          </div>
        )}
      </ClickArea>

      {isOpen && hiddenContent}
    </ExpandableCard>
  );
};
