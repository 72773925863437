import { useState } from "react";
import { useInterval, useMount } from "react-use";
import checkNewVersionService from "./checkNewVersionService";
import { isDevEnv } from "@deliverr/ui/lib-utils";

const minutesToCheck = 1;
const checkIntervalTime = minutesToCheck * 60 * 1000; // minutes * 60 seconds

const onRefreshClick = () => window.location.reload();
const isDevelopmentEnv = isDevEnv(window.location.href);

export function useVersionCheck() {
  // If the this is a local env, don't use intervals / check for updates.
  const envIntervalTime = !isDevelopmentEnv ? checkIntervalTime : null;
  const [checkInterval, setCheckInterval] = useState<number | null>(envIntervalTime);
  const [newVersionAvailable, showNewVersionAvailable] = useState<boolean>(false);

  async function runCheckNewVersion() {
    // Retrieve version from server version.txt
    const newVersion = await checkNewVersionService();

    if (newVersion) {
      setCheckInterval(null);
      showNewVersionAvailable(true);
    } else {
      showNewVersionAvailable(false);
    }
  }

  // only create timers in remote servers
  useInterval(runCheckNewVersion, checkInterval);
  useMount(() => {
    if (!isDevelopmentEnv) {
      runCheckNewVersion();
    }
  });

  return {
    newVersionAvailable,
    onRefreshClick,
  };
}
