import { WholesaleShipmentDTO } from "@deliverr/wholesale-client";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { atom } from "recoil";
import { RSOutboundsBoxSize } from "./RSOutboundsBoxSize";
import { Product } from "@deliverr/commons-clients";
export interface RSOutboundsState {
  currentShipmentId: string;
  shipmentDetails?: WholesaleShipmentDTO;
  currentPackageDskuToQtyMap: { [key: string]: number };
  selectedBoxSize?: RSOutboundsBoxSize;
  shipmentItemsDetails?: { [dsku: string]: Product };
  unifiedProductDetails?: { [dsku: string]: Product };
  mapParentDskuToCasePackDsku?: Record<string, string>;
}

export const DEFAULT_RS_OUTBOUNDS_STATE: RSOutboundsState = {
  currentShipmentId: "",
  currentPackageDskuToQtyMap: {},
  selectedBoxSize: RSOutboundsBoxSize.SIZE_FOUR,
  shipmentItemsDetails: {},
  unifiedProductDetails: {},
  mapParentDskuToCasePackDsku: {},
};

export const rsOutboundsStateAtom = atom<RSOutboundsState>({
  key: WarehouseStateKey.RS_OUTBOUNDS,
  default: DEFAULT_RS_OUTBOUNDS_STATE,
});
