import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

export const CenteredWarehouseCard = styled(FacilityCard)`
  text-align: center;
`;

// used for svg illustration/textinputcard stack
export const IllustrationCard = styled(CenteredWarehouseCard)<ThemeProps>(
  ({ theme }) => `
  border-radius: ${theme.border.radius.R5} ${theme.border.radius.R5} 0px 0px;
  svg {
    margin: auto;
  }
`
);

export interface MarginedWarehouseCardProps {
  bottomMargin?: boolean;
  topMargin?: boolean;
}

export const MarginedWarehouseCard = styled(FacilityCard)<ThemeProps<MarginedWarehouseCardProps>>`
  ${({ bottomMargin, theme }) => (bottomMargin ? `margin-bottom: ${theme.spacing.S4}` : "")};
  ${({ topMargin, theme }) => (topMargin ? `margin-top: ${theme.spacing.S4}` : "")};
`;
