import React from "react";
import { shipmentSummaryCardLabels } from "./shipmentSummaryCardLabels";
import { composeFacilityCommonsButton } from "facility-commons/components/buttons/common/composeFacilityCommonsButton";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useRecoilValue } from "recoil";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingShipmentScan } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";

const ScanNextShipmentButton = composeFacilityCommonsButton(shipmentSummaryCardLabels.scanNextShipmentButton);

export const ShipmentSummaryScanNextShipmentButton: React.FC = () => {
  const { totalPendingItems } = useRecoilValue(shipmentItemsSelector);
  const { transition } = useWarehouseFlow();
  const { showModal, hideModal } = useWarehouseModal();
  const onCancelClick = () => hideModal(WarehouseModal.OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL);
  const onConfirmClick = () => {
    hideModal(WarehouseModal.OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL);
    transition({
      card: createOutboundsPackingShipmentScan({}),
    });
  };
  const onClick = () => {
    if (totalPendingItems > 0) {
      showModal(WarehouseModal.OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL, {
        onCancelClick,
        onConfirmClick,
        totalPendingItems,
      });
    } else {
      transition({
        card: createOutboundsPackingShipmentScan({}),
      });
    }
  };

  return (
    <ScanNextShipmentButton
      block
      data-testid="outbounds-order-packing-scan-next-shipment"
      loading={false}
      onClick={onClick}
    />
  );
};
