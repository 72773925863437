import { defineMessages } from "react-intl";

export const palletAnalyticsMessages = defineMessages({
  cdsku: {
    id: "warehouse.palletDashboard.cdsku",
    defaultMessage: "CDSKU",
  },
  dsku: {
    id: "warehouse.palletDashboard.dsku",
    defaultMessage: "DSKU",
  },
  expectedQuantity: {
    id: "warehouse.palletDashboard.expectedQuantity",
    defaultMessage: "Expected Quantity",
  },
  bulkEligibility: {
    id: "warehouse.palletDashboard.bulkEligibility",
    defaultMessage: "Bulk Eligibility",
  },
  multiSKUBoxes: {
    id: "warehouse.palletDashboard.multiSKUBoxes",
    defaultMessage: "Multi SKU Boxes",
  },
  singleSKUBoxes: {
    id: "warehouse.palletDashboard.singleSKUBoxes",
    defaultMessage: "Single SKU Boxes",
  },
  bulkEligibleBoxes: {
    id: "warehouse.palletDashboard.bulkEligibleBoxes",
    defaultMessage: "Bulk Box Receives",
  },
  palletAnalytics: {
    id: "warehouse.receiving.palletAnalytics",
    defaultMessage: "Pallet Analytics",
  },
  pidTitle: {
    id: "warehouse.receiving.pidTitle",
    defaultMessage: "Scan a Pallet ID",
  },
  pidPlaceholder: {
    id: "warehouse.receiving.pidPlaceholder",
    defaultMessage: "Pallet ID",
  },
  palletAnalyticsMessage: {
    id: "warehouse.receiving.palletAnalyticsMessage",
    defaultMessage: "Scan a PID to get details and analytics for this pallet ",
  },
});
