import React, { useCallback } from "react";
import { shipmentSummaryCardLabels } from "./shipmentSummaryCardLabels";
import { composeFacilityCommonsButton } from "facility-commons/components/buttons/common/composeFacilityCommonsButton";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingCreatePackage } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { useRecoilValue } from "recoil";
import { shipmentItemsSelector } from "warehouse/rs-outbounds/selectors/shipmentItemsSelector";

const CreatePackageButton = composeFacilityCommonsButton(shipmentSummaryCardLabels.createPackageButton);

export const ShipmentSummaryCreatePackageButton: React.FC = () => {
  const { transition } = useWarehouseFlow();
  const { totalPendingItems } = useRecoilValue(shipmentItemsSelector);
  const isDisabled = totalPendingItems === 0;
  const onClick = useCallback(() => {
    transition({
      card: createOutboundsPackingCreatePackage({}),
    });
  }, [transition]);
  return (
    <CreatePackageButton
      block
      data-testid="outbounds-order-packing-create-package"
      loading={false}
      onClick={onClick}
      disabled={isDisabled}
    />
  );
};
