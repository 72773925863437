import React from "react";
import { createPackageCardLabels } from "./createPackageCardLabels";
import { composeFacilityCommonsButton } from "facility-commons/components/buttons/common/composeFacilityCommonsButton";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingShipmentSummary } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useSetRecoilState } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";

const DiscardPackageButton = composeFacilityCommonsButton(createPackageCardLabels.discardPackageButton);

export const CreatePackageDiscardButton: React.FC = () => {
  const { transition } = useWarehouseFlow();
  const { showModal, hideModal } = useWarehouseModal();
  const updateState = useSetRecoilState(rsOutboundsStateAtom);
  const onCancelClick = () => hideModal(WarehouseModal.OUTBOUND_DISCARD_PACKAGE_MODAL);
  const onConfirmClick = () => {
    updateState((prevState) => ({
      ...prevState,
      currentPackageDskuToQtyMap: {},
    }));
    hideModal(WarehouseModal.OUTBOUND_DISCARD_PACKAGE_MODAL);
    transition({
      card: createOutboundsPackingShipmentSummary({}),
    });
  };
  const onClick = () => {
    showModal(WarehouseModal.OUTBOUND_DISCARD_PACKAGE_MODAL, { onCancelClick, onConfirmClick });
  };

  return <DiscardPackageButton block data-testid="outbounds-order-packing-discard-package" onClick={onClick} />;
};
