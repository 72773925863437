import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import React from "react";
import { MissingBarcodeOverviewCard } from "./TicketDetailCards/MissingBarcodeOverviewCard";
import { MissingBarcodeResolutionCard } from "./TicketDetailCards/MissingBarcodeResolutionCard";
import { OtherTicketOverviewCard } from "./TicketDetailCards/OtherTicketOverviewCard";
import { OtherTicketResolutionCard } from "./TicketDetailCards/OtherTicketResolutionCard";
import { UnknownBarcodeOverviewCard } from "./TicketDetailCards/UnknownBarcodeOverviewCard";
import { UnknownBarcodeResolutionCard } from "./TicketDetailCards/UnknownBarcodeResolutionCard";
import { TicketTabs } from "./TicketTabs";
import { LotFefoOverviewCard, LotFefoResolutionCard } from "./TicketDetailCards/LotFefoCards";

export const ticketsByType: Record<NonComplianceType, Record<TicketTabs, React.FC>> = {
  [NonComplianceType.UNKNOWN_BARCODE]: {
    [TicketTabs.OVERVIEW]: UnknownBarcodeOverviewCard,
    [TicketTabs.RESOLUTION]: UnknownBarcodeResolutionCard,
  },
  [NonComplianceType.MISSING_BARCODE]: {
    [TicketTabs.OVERVIEW]: MissingBarcodeOverviewCard,
    [TicketTabs.RESOLUTION]: MissingBarcodeResolutionCard,
  },
  [NonComplianceType.UNEXPECTED_SKU]: {
    [TicketTabs.OVERVIEW]: () => <></>,
    [TicketTabs.RESOLUTION]: () => <></>,
  },
  [NonComplianceType.UNEXPECTED_CDSKU]: {
    [TicketTabs.OVERVIEW]: () => <></>,
    [TicketTabs.RESOLUTION]: () => <></>,
  },
  [NonComplianceType.OTHER]: {
    [TicketTabs.OVERVIEW]: OtherTicketOverviewCard,
    [TicketTabs.RESOLUTION]: OtherTicketResolutionCard,
  },
  [NonComplianceType.INVALID_LOT_EXPIRY]: {
    [TicketTabs.OVERVIEW]: LotFefoOverviewCard,
    [TicketTabs.RESOLUTION]: LotFefoResolutionCard,
  },
  [NonComplianceType.DAMAGED_PRODUCT]: {
    [TicketTabs.OVERVIEW]: () => <></>,
    [TicketTabs.RESOLUTION]: () => <></>,
  },
  [NonComplianceType.EXPIRED_PRODUCT]: {
    [TicketTabs.OVERVIEW]: () => <></>,
    [TicketTabs.RESOLUTION]: () => <></>,
  },
};
