import React from "react";
import { useIntl } from "react-intl";
import { MAX_TEXTAREA_CHARS } from "warehouse/common/utils";
import { TextAreaPage } from "warehouse/ticket-center/components/TextAreaPage/TextAreaPage";
import { ADDITIONAL_DETAILS_LABELS } from "../../content/details";
import { UNSOLVABLE_DETAILS_PAGE_LABELS } from "./UnsolvableDetailsPage.labels";
import { useUnsolvableDetailsPage } from "./useUnsolvableDetailsPage";

export const UnsolvableDetailsPage: React.FC = () => {
  const { details, submitTicketToSupport, setDetails, isLoading } = useUnsolvableDetailsPage();
  const { formatMessage } = useIntl();

  return (
    <TextAreaPage
      textAreaValue={details}
      placeholder={formatMessage(UNSOLVABLE_DETAILS_PAGE_LABELS.placeholder)}
      continueLabel={formatMessage(UNSOLVABLE_DETAILS_PAGE_LABELS.button)}
      continueDisabled={!details}
      onChange={setDetails}
      continueOnClick={submitTicketToSupport}
      title={formatMessage({ ...ADDITIONAL_DETAILS_LABELS.provideDetailsTitle })}
      charLimit={MAX_TEXTAREA_CHARS}
      isLoading={isLoading}
    />
  );
};
