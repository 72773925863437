import React from "react";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import { IconV2, Text, Title, ThemeProps, Stack } from "@deliverr/ui";
import { CenteredWarehouseCard } from "warehouse/common/components";
import { Direction } from "@deliverr/ui/lib-esm/shared";
import { linkMapConfig } from "warehouse/ticket-center/common/types";

const CardContainer = styled(CenteredWarehouseCard)`
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 328px;
  margin-bottom: 0;
  margin-top: 0;
  cursor: pointer;
`;

const StyledTitle = styled(Title)<ThemeProps>`
  margin-bottom: ${({ theme }) => `${theme.spacing.S6}`};
`;

const StyledStack = styled(Stack)<ThemeProps>`
  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => `${theme.border.width.B1} solid ${theme.colors.NEUTRAL[60]}`};
    padding-bottom: ${({ theme }) => `${theme.spacing.S4}`};
  }
  justify-content: space-between;
`;

const CenteredIcon = styled(IconV2)`
  margin-bottom: auto;
  margin-top: auto;
`;

const StyledItemTitle = styled(Title)<ThemeProps>`
  font-family: ${({ theme }) => `${theme.font.family.STANDARD}`};
  font-size: ${({ theme }) => `${theme.font.size.F3}`};
`;

const StyledText = styled(Text)<ThemeProps>`
  margin-right: ${({ theme }) => `${theme.spacing.S4}`};
`;

export interface LinkCardProps {
  linkMap: linkMapConfig;
  title?: string;
}

export const StorageLinkCard: React.FC<LinkCardProps> = ({ linkMap, title }) => (
  <CardContainer>
    {title && (
      <StyledTitle as="h4" displayAs="h4">
        {title}
      </StyledTitle>
    )}
    <Stack direction={Direction.VERTICAL}>
      {linkMap.map((data) => (
        <StyledStack key={`${data.title}-${data.description}`} direction={Direction.HORIZONTAL} onClick={data.onClick}>
          <Stack direction={Direction.VERTICAL}>
            <StyledItemTitle as="h4" displayAs="h6">
              {data.title}
            </StyledItemTitle>
            {!isEmpty(data.description) && <StyledText appearance="INFO">{data.description}</StyledText>}
          </Stack>
          <CenteredIcon size="lg" type="angle-right" />
        </StyledStack>
      ))}
    </Stack>
  </CardContainer>
);
