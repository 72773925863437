import React from "react";
import { Button, ThemeProps, Input, BoxReset } from "@deliverr/ui";
import { TextInputCard } from "facility-commons/flow";
import { ModalTemplate, warningModalIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import styled from "@emotion/styled";
import { DateFormat } from "facility-commons/utils";
import { useConfirmLotExpiryModal } from "./useConfirmLotExpiryModal";
import { DateInput } from "facility-commons/components/Inputs";
import { SoundFx } from "@deliverr/ui-facility";

export interface ConfirmLotExpiryModalProps {
  onCancel: () => void;
  requireLotInput: boolean;
  requireExpiryInput: boolean;
  previousExpiryInput: string;
  previousLotInput: string;
  needsToReconfirm?: boolean;
}

const LotInputContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S4}
`
);

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%
  `
);

const ButtonsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
      display:flex;
      marging-top: ${theme.spacing.S5};
  `
);

export const ConfirmLotExpiryModal: React.FC<ConfirmLotExpiryModalProps> = (props) => {
  const {
    onCancel,
    requireLotInput,
    requireExpiryInput,
    previousExpiryInput,
    previousLotInput,
    needsToReconfirm,
  } = props;
  const {
    modalTitle,
    modalText,
    lotInputPlaceHolder,
    expiryInputPlaceHolder,
    expiryHelpText,
    lotInput,
    expiryInput,
    lotInputError,
    expiryInputError,
    onLotInputChange,
    onExpiryInputChange,
    backButtonText,
    confirmButtonText,
    disableConfirmButton,
    handleOnConfirmSubmission,
  } = useConfirmLotExpiryModal({
    requireLotInput,
    requireExpiryInput,
    previousExpiryInput,
    previousLotInput,
    needsToReconfirm,
  });
  return (
    <ModalTemplate title={modalTitle} text={modalText} iconProps={warningModalIcon} soundFx={SoundFx.INFO}>
      <Container>
        <BoxReset marginBottom={"S2"}>
          <>
            {requireLotInput && (
              <LotInputContainer>
                <TextInputCard
                  errorMessage={lotInputError}
                  onChange={onLotInputChange}
                  placeholder={lotInputPlaceHolder}
                  value={lotInput}
                  data-testid="confirm-lot-number-input"
                  embedded
                />
              </LotInputContainer>
            )}
            {requireExpiryInput && (
              <DateInput
                value={expiryInput}
                mask={DateFormat.LongDate}
                onChange={onExpiryInputChange}
                placeholderText={expiryInputPlaceHolder}
                errorMessage={expiryInputError}
                helpText={expiryHelpText}
                inputTestId="confirm-expiration-date-input"
              />
            )}
          </>
        </BoxReset>
        <ButtonsContainer>
          <WarehouseModalRightMarginButton
            block
            onClick={onCancel}
            secondary
            data-testid="cancel-confirm-lot-expiry-button"
          >
            {backButtonText}
          </WarehouseModalRightMarginButton>
          <Button
            block
            onClick={handleOnConfirmSubmission}
            disabled={disableConfirmButton}
            data-testid="confirm-lot-expiry-button"
          >
            {confirmButtonText}
          </Button>
        </ButtonsContainer>
      </Container>
    </ModalTemplate>
  );
};
