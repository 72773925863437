import React from "react";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { useStorageDamagedProductForm } from "./useStorageDamagedProductForm";
import { Appearance, Button, Input, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormProvider } from "react-hook-form";

import { LoadingSpinner, TooltipWrapper, UploadItem } from "facility-commons/components";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormInputGroup,
  WarehouseFormLabel,
  WarehouseFormLabelMandatory,
  WarehouseFormOtherDetails,
  WarehouseFormPhotoGridTitle,
  WarehouseFormRadioButton,
  WarehouseFormRadioButtonGroup,
  WarehouseFormRadioStack,
  WarehouseFormRadioTitle,
  WarehouseFormSubTitle,
  WarehouseFormText,
  WarehouseFormTextArea,
  WarehouseFormTooltip,
} from "facility-commons/components/emotion";
import { ticketFormContent } from "facility-commons/flow/forms/ticketFormContent";
import { DamagedProductField } from "facility-commons/flow/forms/DamagedProductForm/DamagedProductForm.types";
import { ErrorMessage } from "@hookform/error-message";

// Style the TextInputCard component to keep consistency with the WarehouseFormInput styling.
const StyledTextInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
    width: 100%;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `
);

export const StorageDamagedProductForm: React.FC = () => {
  const {
    formHandler,
    onFormSubmit,
    formatMessage,
    formGroupMap,
    imageGridOptionsMap,
    register,
    preventSubmit,
    warehouseEmailAsyncState,
  } = useStorageDamagedProductForm();

  return (
    <FacilityCard>
      <FormProvider {...formHandler}>
        <WarehouseForm onSubmit={onFormSubmit}>
          <WarehouseFormSubTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.subtitle)}
          </WarehouseFormSubTitle>

          {formGroupMap.map((groupData) => {
            switch (groupData.type) {
              case "input":
                return (
                  <WarehouseFormInputGroup key={`${groupData.labelContent}`}>
                    <WarehouseFormLabel {...groupData.labelProps}>
                      {groupData.labelContent}{" "}
                      {groupData.isMandatory && <WarehouseFormLabelMandatory>*</WarehouseFormLabelMandatory>}
                    </WarehouseFormLabel>
                    <StyledTextInput {...groupData.textInputProps} />
                  </WarehouseFormInputGroup>
                );
              case "radio":
                return (
                  <WarehouseFormRadioStack direction={groupData.direction} key={`${groupData.title}`}>
                    <WarehouseFormRadioTitle appearance={Appearance.INFO}>
                      {groupData.title}{" "}
                      {groupData.isMandatory && <WarehouseFormLabelMandatory>*</WarehouseFormLabelMandatory>}
                    </WarehouseFormRadioTitle>
                    <WarehouseFormRadioButtonGroup>
                      {groupData.options?.map((radioOption) => (
                        <React.Fragment key={`${groupData.title}-${radioOption.labelText}`}>
                          <WarehouseFormRadioButton {...radioOption.props} />
                          <WarehouseFormLabel {...radioOption.labelProps}>{radioOption.labelText}</WarehouseFormLabel>
                        </React.Fragment>
                      ))}
                    </WarehouseFormRadioButtonGroup>
                  </WarehouseFormRadioStack>
                );
              default:
                return null;
            }
          })}

          <WarehouseFormPhotoGridTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.photosSubtitle)}
          </WarehouseFormPhotoGridTitle>

          <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessage)}</WarehouseFormText>

          <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
            {imageGridOptionsMap.map((item) => (
              <TooltipWrapper key={item.uploadProps.id}>
                <UploadItem {...item.uploadProps} />
                <WarehouseFormTooltip {...item.tooltipProps}>
                  <ErrorMessage {...item.errorProps} />
                </WarehouseFormTooltip>
              </TooltipWrapper>
            ))}
          </WarehouseFormImageGrid>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.OTHER_DETAILS}>
              {formatMessage(ticketFormContent.damagedAdditionalDetails)}
            </WarehouseFormLabel>
            <WarehouseFormTextArea
              {...register[DamagedProductField.OTHER_DETAILS]}
              id={DamagedProductField.OTHER_DETAILS}
              placeholder={formatMessage(ticketFormContent.damagedAdditionalDetailsPlaceholder)}
            />
          </WarehouseFormOtherDetails>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.EMAIL}>
              {formatMessage(ticketFormContent.email)}
            </WarehouseFormLabel>
            <StyledTextInput
              id={DamagedProductField.EMAIL}
              {...register[DamagedProductField.EMAIL]}
              helpText={
                warehouseEmailAsyncState.loading
                  ? formatMessage(ticketFormContent.emailLoadingMessage)
                  : formatMessage(ticketFormContent.emailMessage, { warehouseEmail: warehouseEmailAsyncState.value })
              }
              disabled={warehouseEmailAsyncState.loading}
            />
          </WarehouseFormOtherDetails>

          <Button disabled={preventSubmit} block type="submit">
            <LoadingSpinner loading={preventSubmit} size="lg">
              {formatMessage(ticketFormContent.submit)}
            </LoadingSpinner>
          </Button>
        </WarehouseForm>
      </FormProvider>
    </FacilityCard>
  );
};
