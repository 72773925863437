import { defineMessages, MessageDescriptor } from "react-intl";

export enum EmptyPalletErrorMessageKey {
  EMPTY_PALLET = "EMPTY_PALLET",
}

export const EMPTY_PALLET_ERROR_BY_ERROR_CODE: Record<EmptyPalletErrorMessageKey, MessageDescriptor> = defineMessages({
  [EmptyPalletErrorMessageKey.EMPTY_PALLET]: {
    id: "warehouse.receiving.emptyPalletError",
    defaultMessage: "Cannot create an empty pallet.",
  },
});
