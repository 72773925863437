import React from "react";

import { ReactComponent as ScanPallet } from "facility-commons/common/assets/scanPallet.svg";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { usePalletCard } from "./usePalletCard";
import { commonPalletMessages } from "warehouse/receiving/content";

export const PalletCard: React.FC = () => {
  const {
    scannedValue,
    scannedValueError,
    formatMessage,
    handleAsnChange,
    loading,
    submitScannedValue,
  } = usePalletCard();

  return (
    <StackedInputCard
      errorMessage={scannedValueError}
      illustration={<ScanPallet />}
      illustrationTitle={formatMessage(commonPalletMessages.scanPallet)}
      loading={loading}
      message={formatMessage(commonPalletMessages.scanPalletInstructions)}
      onChange={handleAsnChange}
      onSubmit={submitScannedValue}
      placeholder={formatMessage(commonPalletMessages.scanPalletPlaceholder)}
      title={formatMessage(commonPalletMessages.scanPalletInput)}
      value={scannedValue}
    />
  );
};
