import { defineMessages } from "react-intl";

export const shipmentCardLabels = defineMessages({
  scanShipmentText: {
    id: "warehouse.rs-outbounds.scanShipmentText",
    defaultMessage: "Scan a Shipment",
  },
  shipmentTitleText: {
    id: "warehouse.rs-outbounds.shipmentTitleText",
    defaultMessage: "Scan Shipment",
  },
  shipmentInstructionsText: {
    id: "warehouse.rs-outbounds.shipmentInstructionsText",
    defaultMessage: "Scan or enter the Shipment ID",
  },
  shipmentID: {
    id: "warehouse.rs-outbounds.shipmentID",
    defaultMessage: "Shipment ID",
  },
  invalidShipment: {
    id: "warehouse.rs-outbounds.invalidShipment",
    defaultMessage: "Invalid Shipment",
  },
  invalidShipmentStatus: {
    id: "warehouse.rs-outbounds.invalidShipmentShipment",
    defaultMessage: "Shipment cannot be processed, status is {shipmentStatus}.",
  },
  invalidWarehouse: {
    id: "warehouse.rs-outbounds.invalidWarehouse",
    defaultMessage: "Shipment does not belong to this warehouse.",
  },
  shipmentScanSuccessNotificationTitle: {
    id: "warehouse.rs-outbounds.shipmentScanSuccess.title",
    defaultMessage: "Shipment scanned successfully",
  },
});
