import { ERRORS_LABELS } from "../components/cards/warehouse.labels";
import { MessageDescriptor } from "react-intl";
import { ValidationError } from "@deliverr/legacy-inbound-client";

type GetErrorMessageProps = {
  response: ValidationError;
};

export const formatValues = (payload: { [key: string]: any }) => {
  // in case the value is an array of string we need to format it first
  return Object.keys(payload).reduce((formatted, key) => {
    const value = payload[key];
    formatted[key] = Array.isArray(value) ? value.join(", ") : value.toString();
    return formatted;
  }, {});
};

export const getErrorMessageId = ({ response }: GetErrorMessageProps): MessageDescriptor | null => {
  const { code, subcode } = response;

  return ERRORS_LABELS[code]?.[subcode] ?? null;
};
