import { set, uniq } from "lodash/fp";
import produce from "immer";

export const removeHead = produce((list) => {
  list.shift();
});

export const addItem = <T>(item: T) =>
  produce((list) => {
    list.push(item);
  });

export const addToFixedLength = <T>(item: T, maxLength: number) =>
  produce((list) => {
    if (list.length >= maxLength) {
      list.shift();
    }
    list.push(item);
  });

export const removeAtIndex = (index: number) => <T>(list: T[]) =>
  list.slice(0, index).concat(list.slice(index + 1, list.length));

export const replaceAtIndex = <T>(index: number, item: T) =>
  produce((list) => {
    list[index] = item;
  });

export const setProp = <P extends string, V>(path: P, val: V) => produce((obj) => set(path, val, obj));

export const removeProp = <P>(prop: P) =>
  produce((obj) => {
    delete obj[prop];
  });

// lower index = more recent
export const addMostRecent = <T>(item: T) => (items: T[]) => uniq([item, ...items]);
