import React from "react";
import { FormattedMessage } from "react-intl";
import { MenuLinkConfig } from "facility-commons/components/LinksMenu";
import { MoreLinksNames } from "warehouse/more/MoreLinks";
import { MoreRoutesNames } from "warehouse/more/MoreRoutes";

export const defaultStorageToolLinks: MenuLinkConfig<MoreLinksNames>[] = [
  {
    text: <FormattedMessage id="storage.moreMenu.poWorkList" defaultMessage="Print PO worklist" />,
    route: MoreRoutesNames.PO_WORKLIST,
    id: MoreLinksNames.PO_WORKLIST,
  },
  {
    text: <FormattedMessage id="storage.options.printPalletLabel" defaultMessage="Print Pallet Labels" />,
    route: MoreRoutesNames.PRINT_PALLET_LABELS,
    id: MoreLinksNames.PRINT_PALLET_LABELS,
  },
  {
    text: <FormattedMessage id="storage.options.printSSCCLabel" defaultMessage="Print SSCC Labels" />,
    route: MoreRoutesNames.PRINT_SSCC_LABELS,
    id: MoreLinksNames.PRINT_SSCC_LABELS,
  },
  {
    text: (
      <FormattedMessage
        id="storage.options.WarehouseBOTDownloadSendInforShipments"
        defaultMessage="Warehouse BOT - Download/Send Info for Shipments"
      />
    ),
    route: MoreRoutesNames.STORAGE_WAREHOUSE_BOT,
    id: MoreLinksNames.STORAGE_WAREHOUSE_BOT,
  },
  {
    text: <FormattedMessage id="storage.options.BOLNumberLookup" defaultMessage="BOL Number Lookup" />,
    url: "https://metabase.deliverr.com/public/question/73c57a99-1f82-4653-8e53-5e1c4b2b9aff",
    id: MoreLinksNames.BOL_NUMBER_LOOKUP,
  },
];
