import { LABEL_DPI } from "@deliverr/legacy-inbound-client";
import { atom } from "recoil";
import { Printer } from "./ZebraTypes";
import { FacilityStateKey } from "facility-commons/base/FacilityStateKey";

export const printersState = atom<Printer[]>({
  key: FacilityStateKey.PRINTERS,
  default: [],
});

export const connectedPrinterState = atom<Printer | undefined>({
  key: FacilityStateKey.CONNECTED_PRINTER,
  default: undefined,
});

export const isSearchingPrintersState = atom<boolean>({
  key: FacilityStateKey.IS_SEARCHING_PRINTERS,
  default: false,
});

export const isConnectingToPrinterState = atom<boolean>({
  key: FacilityStateKey.IS_CONNECTING_TO_PRINTER,
  default: false,
});

export const dpiState = atom<LABEL_DPI>({
  key: FacilityStateKey.PRINTER_DPI,
  default: 203,
});

export const horizontalOffsetState = atom<number>({
  key: FacilityStateKey.PRINTER_HORIZONTAL_OFFSET,
  default: 0,
});
