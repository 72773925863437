import { Product, ProductCasePackData } from "@deliverr/commons-clients";
import { ATP } from "@deliverr/business-types";
import { BookingType, StorageInboundResponse } from "@deliverr/storage-client";
import { AsnReceiveAsnBarcodeValidationData } from "@deliverr/legacy-inbound-client";
import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

interface StorageSKUInformation extends Pick<Product, "isFefoEnabled" | "isLotTrackingEnabled"> {
  name?: string;
  dsku?: string;
  caseQty?: number;
  boxes?: number;
  qty?: number;
  LP?: string;
  location?: string;
  expectedQty?: number;
  receivedQty?: number;
  packOf?: string;
}

interface PONumDetails {
  palletCount?: number;
  numberOfSkus?: number;
  numberOfBoxes: number;
  searchTerms: { [dsku: string]: string[] };
  arrivedAt?: Date;
  arrivalExpectedAt?: Date;
  receivingStartPalletCount?: number;
  isPalletized?: boolean;
  bookingID?: string;
  bookingType?: BookingType;
  prepOption?: StorageInboundResponse["prepOption"];
  totalExpectedQuantity?: number;
  totalReceivedQuantity?: number;
}

export interface StorageInboundReceivingState {
  poNum?: string | null;
  poDetails: PONumDetails;
  currentSKU: string;
  dedupKey?: string;
  requestBatchId?: string;
  currentSkuDetails?: any;
  locationReceivedQty?: AsnReceiveAsnBarcodeValidationData;
  scannedSKUs?: number;
  dskusATP?: { [dsku: string]: ATP };
  skus?: { [sku: string]: StorageSKUInformation };
  expectedCaseQty?: number;
  isNewChildProductCreated?: boolean;
  sellerId: string;
  selectedPackConfig?: Partial<ProductCasePackData>;
}

export const DEFAULT_STORAGE_RECEIVE_STATE: StorageInboundReceivingState = {
  currentSKU: "",
  poDetails: {
    numberOfBoxes: 0,
    searchTerms: {},
  },
  sellerId: "",
};

export const storageInboundReceivingStateAtom = atom<StorageInboundReceivingState>({
  key: WarehouseStateKey.STORAGE_RECEIVE,
  default: DEFAULT_STORAGE_RECEIVE_STATE,
  dangerouslyAllowMutability: true,
});
