import { useAsync } from "react-use";
import { useSetRecoilState } from "recoil";

import { logError } from "facility-commons/utils";
import { warehouseDataState } from "warehouse/base/warehouseIdState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";

export function useGetWarehouseData(warehouseId: string): void {
  const ctx = { fn: "useGetWarehouseData" };
  const { warehousePublicClient } = useClientsWithAuth();

  const setWarehouseDataState = useSetRecoilState(warehouseDataState);

  useAsync(async () => {
    if (warehouseId) {
      try {
        const warehouseDataResponse = await warehousePublicClient.getWarehouse(warehouseId);
        setWarehouseDataState(warehouseDataResponse);
      } catch (err) {
        logError(ctx, err);
      }
    }
  }, [warehouseId]);
}
