import { defineMessages } from "react-intl";

export const UNSOLVABLE_TICKET_SECTION_LABELS = defineMessages({
  prompt: {
    id: "warehouse.ticketCenter.unsolvableTicketSection.prompt",
    defaultMessage: "Did this solve your problem?",
  },
  button: {
    id: "warehouse.ticketCenter.unsolvableTicketSection.button",
    defaultMessage: "No, create support ticket",
  },
});
