import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  greaterThanZero: { id: "warehouse.greaterThanZero", defaultMessage: "Quantity must be greater than 0" },
  unknownError: {
    id: "warehouse.unknownError",
    defaultMessage: "Please try again later or contact Deliverr.",
  },
  validEmail: { id: "warehouse.invalidEmailError", defaultMessage: "Please enter a valid email" },
  palletLabelNotFoundMessage: {
    id: "palletLabelNotFound",
    defaultMessage: `{palletLabel} could not be found. Please contact your operations manager about this pallet.`,
  },
  incapableUnitsOnPalletMessage: {
    id: "warehouse.incapableUnitsOnPalletMessage",
    defaultMessage: `{palletLabel} contains incapable units:\n {reasonsString} \n Please contact your operations manager about this pallet.`,
  },
  palletNoDestinationMessage: {
    id: "palletNoDestinationMessage",
    defaultMessage: `{palletLabel} has no destination. Please contact your operations manager about this pallet.`,
  },
  defaultPalletArrivalScanMessage: {
    id: "defaultPalletArrivalScanMessage",
    defaultMessage: `Failed to scan {palletLabel}. Please try again or contact your operations manager.`,
  },
});
