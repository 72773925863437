import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import { useLifecycles, useMount } from "react-use";
import { useResetRecoilState, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { NCRoutes } from "../base/routes";
import { useRouter } from "facility-commons/hooks";
import { commonTicketMessages } from "../content";
import { receivingMisMatchState } from "warehouse/receiving/ReceivingState/ReceivingMismatch";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { setProp } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { storageInboundReceivingStateAtom } from "warehouse/receiving/storage/StorageReceivingState";

export const useStorageNonCompliance = () => {
  const {
    match: { path },
    push,
    pathname,
  } = useRouter();
  const form = useForm({ mode: "onBlur" });
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const storageInboundState = useRecoilValue(storageInboundReceivingStateAtom);
  const { formatMessage } = useIntl();
  const isMismatch = useRecoilValue(receivingMisMatchState);
  const { isStorage } = useRecoilValue(userState);
  const [{ incidentId }, setCurrentProduct] = useRecoilState(currentProductState);

  const resetCurrentProduct = useResetRecoilState(currentProductState);

  useMount(() => {
    setWarehouseAppState(setProp("pageSubtitle", ""));
    if (!incidentId && !isMismatch) {
      setCurrentProduct(setProp("incidentId", uuid()));
      if (pathname.includes(NCRoutes.DETAILS)) {
        setCurrentProduct(setProp("trackingCode", storageInboundState.poNum));
      } else if (storageInboundState.poNum) {
        setCurrentProduct(setProp("trackingCode", storageInboundState.poNum));
        push(NCRoutes.BARCODE);
      } else {
        push(NCRoutes.PO_NUM);
      }
    }
  });

  useLifecycles(
    () => {
      setWarehouseAppState(setProp("pageTitle", formatMessage(commonTicketMessages.nonComplianceTicketTitle)));
    },
    () => {
      resetCurrentProduct();
      setWarehouseAppState(setProp("pageTitle", ""));
      setWarehouseAppState(setProp("pageSubtitle", ""));
    }
  );

  return { form, path, isMismatch, isStorage };
};
