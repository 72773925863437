import { Anchor } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

import { FacilityHeader } from "facility-commons/components/FacilityHeader";
import { FacilityHeaderCloseButton } from "facility-commons/components/FacilityHeader/FacilityHeaderCloseButton";
import { useIntl } from "react-intl";
import { WarehousePortalRoutes, WarehousePortalRoutesNames } from "warehouse/routes";
import { TicketDetailTabs, TicketListTabs } from "warehouse/ticket-center/components/TicketCenterTabs";
import { navbarText } from "./navBarText";
import { useWarehouseHeader } from "./useWarehouseHeader";
import { WAREHOUSE_PORTAL_ROOT_PAGES } from "./warehouseRootPages";

const StyledAnchor = styled(Anchor)`
  cursor: pointer;
`;

type WarehousePortalRoutePath = typeof WarehousePortalRoutes[keyof typeof WarehousePortalRoutes];

export const WarehouseHeader: React.FC = () => {
  const {
    showTicketDetailsTabs,
    showTicketListTabs,
    NavbarTitles,
    NavbarSubtitles,
    showChangeMode,
    onChangeMode,
    showCloseIcon,
    onExit,
    showCreateTicket,
    onCreateTicket,
    pageTitle,
    pageSubtitle,
    warehouseAppBackButtonOnClick,
    shouldShowReportIssue,
    onReportIssue,
  } = useWarehouseHeader();
  const { formatMessage } = useIntl();

  const rightActionBtn = shouldShowReportIssue ? (
    <StyledAnchor onClick={onReportIssue}>{formatMessage(navbarText.reportIssue)}</StyledAnchor>
  ) : showChangeMode ? (
    <StyledAnchor onClick={onChangeMode}>{formatMessage(navbarText.changeMode)}</StyledAnchor>
  ) : showCloseIcon ? (
    <FacilityHeaderCloseButton onClose={onExit} />
  ) : showCreateTicket ? (
    <StyledAnchor onClick={onCreateTicket}>{formatMessage(navbarText.createTicketAnchor)}</StyledAnchor>
  ) : null;

  const tabControl = showTicketListTabs ? <TicketListTabs /> : showTicketDetailsTabs ? <TicketDetailTabs /> : undefined;

  return (
    <FacilityHeader<WarehousePortalRoutesNames, WarehousePortalRoutePath>
      tabControl={tabControl}
      hideNavbarShadow={showTicketDetailsTabs}
      rightAction={rightActionBtn}
      rootPages={WAREHOUSE_PORTAL_ROOT_PAGES}
      routeTitles={NavbarTitles}
      routeSubtitles={NavbarSubtitles}
      title={pageTitle}
      subtitle={pageSubtitle}
      customOnBackButtonClick={warehouseAppBackButtonOnClick}
    />
  );
};
