import React from "react";
import { useIntl } from "react-intl";
import { ModalTemplate, infoModalIcon } from "facility-commons";
import { Button } from "@deliverr/ui";
import { modalsText } from "../../receiving/components/modals/modalsText";
import { BoxArrivalScanEntity, ShippingPlanDispersalMethod } from "@deliverr/legacy-inbound-client";
import { SoundFx } from "facility-commons/common/sfx";

interface SortBoxToFacilityAreaModalProps {
  label: string;
  dispersalMethod: ShippingPlanDispersalMethod;
  onConfirm: () => void;
}

export const SortBoxToFacilityAreaModal: React.FC<SortBoxToFacilityAreaModalProps> = ({
  onConfirm,
  dispersalMethod,
  label,
}) => {
  const { formatMessage } = useIntl();

  let title: string;
  let message: string;

  switch (dispersalMethod) {
    case ShippingPlanDispersalMethod.DISTRIBUTION:
    case ShippingPlanDispersalMethod.FORWARDING:
      title = formatMessage(modalsText.boxSortCrossdockTitle);
      message = formatMessage(modalsText.sortBoxToCDSorting, { label });
      break;
    case ShippingPlanDispersalMethod.DIRECT:
      title = formatMessage(modalsText.boxSortReceiveTitle);
      message = formatMessage(modalsText.sortBoxToFCReceiving, { label });
      break;
    default:
      title = formatMessage(modalsText.boxSortReceiveTitle);
      message = formatMessage(modalsText.boxSortReceiveMessage, { label });
  }

  return (
    <ModalTemplate buttonLayout="row" title={title!} text={message!} iconProps={infoModalIcon} soundFx={SoundFx.INFO}>
      <Button block data-testid="box-sort-confirm-button" onClick={onConfirm}>
        {formatMessage(modalsText.confirm)}
      </Button>
    </ModalTemplate>
  );
};
