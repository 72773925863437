import React from "react";
import { BoxReset, Button, Divider } from "@deliverr/ui";
import { FlowCard, TextInputCard } from "facility-commons/flow";
import { DateInput } from "facility-commons/components/Inputs";
import { DateFormat } from "facility-commons/utils";

import { useLotExpiryInput } from "./useLotExpiryInput";
import { PointerAnchor } from "facility-commons/components/emotion";

export const LotExpiryInput: React.FC = () => {
  const {
    messages,
    disableButton,
    isLotTrackingEnabled,
    isFefoEnabled,
    lotNumberInputValue,
    expirationDateInputValue,
    lotErrorMsg,
    expirationDateErrorMsg,
    onLotChange,
    onExpirationDateChange,
    onMissingLotOrExpirationDate,
    onConfirm,
  } = useLotExpiryInput();

  return (
    <>
      <FlowCard title={messages.title}>
        {isLotTrackingEnabled && (
          <TextInputCard
            errorMessage={lotErrorMsg}
            newest
            onChange={onLotChange}
            placeholder={messages.lotInputPlaceholder}
            value={lotNumberInputValue}
            embedded
            data-testid="lot-number-input"
          />
        )}
        {isFefoEnabled && (
          <DateInput
            value={expirationDateInputValue}
            mask={DateFormat.LongDate}
            onChange={onExpirationDateChange}
            placeholderText={messages.expirationDateInputPlaceholder}
            errorMessage={expirationDateErrorMsg}
            helpText={messages.expirationdateHelpText}
            inputTestId="expiration-date-input"
          />
        )}
        <Divider />
        <PointerAnchor onClick={onMissingLotOrExpirationDate}>{messages.missingLotExpiryText}</PointerAnchor>
      </FlowCard>
      <BoxReset marginTop="S4">
        <Button block onClick={onConfirm} disabled={disableButton} data-testid="lot-exp-confirm-btn">
          {messages.confirmButtonText}
        </Button>
      </BoxReset>
    </>
  );
};
