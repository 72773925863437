/* components used in transition pages (welcome screen, damage type screen, etc) */

import { Anchor, Card, DefaultTheme, Grid, Stack, StyledButton, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { ExternalLink } from "facility-commons/components";

export const StyledGrid = styled(Grid)`
  align-items: center;
  justify-items: center;
`;

export const StyledCard = styled(Card)`
  background: none;
  box-shadow: none;
  height: 100%;
  max-width: 32rem;
  text-align: center;
`;

export const StyledLoadingGrid = styled(Grid)`
  place-items: center;
`;

export const StyledTitle = styled(Title)`
  padding: 0 38px;
  margin: 0 auto 2rem auto;
  @media (max-width: 375px) {
    padding: 0 5px;
  }
`;

const PaddedButton = styled(StyledButton)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing.S5};
  }
  padding-bottom: ${theme.spacing.S3};
  padding-top: ${theme.spacing.S3};
`
);

// render a button as a Link component ('a') - for a11y/semantics
export const PaddedLinkButton = PaddedButton.withComponent(Link);
export const PaddedExternalLink = PaddedButton.withComponent(ExternalLink);

export const MenuSection = styled.div`
  &:not(:first-of-type) {
    margin-top: 50px;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
`;

export const FullStack = styled(Stack)`
  max-width: ${MAX_CARD_WIDTH};
  height: 100%;
  width: 100%;
`;

export const PointerAnchor = styled(Anchor)`
  cursor: pointer;
`;
