import { IconV2, IconV2Props, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

const StyledIcon = styled(IconV2)<ThemeProps<IconV2Props>>(
  ({ theme }) => `
  color: ${theme.colors.BLUE["300"]};
`
);

export interface BackArrowProps extends Pick<IconV2Props, "size"> {
  className?: string;
}

export const BackArrow: React.FC<BackArrowProps> = ({ className, size }) => {
  return <StyledIcon size={size ?? "md"} type="arrow-left" className={className} />;
};
