import { defineMessages } from "react-intl";

export const SUBMIT_CAPACITY_TOASTS = defineMessages({
  error: {
    id: "warehouse.outboundCapacity.submitCapacity.errorToast",
    defaultMessage: "There was an error. Please contact Deliverr support.",
  },
  successIncreased: {
    id: "warehouse.outboundCapacity.submitCapacity.successToast.increased",
    defaultMessage: "Outbound capacity increased by {difference}",
  },
  successDecreased: {
    id: "warehouse.outboundCapacity.submitCapacity.successToast.decreased",
    defaultMessage: "Outbound capacity decreased by {difference}",
  },
});
