// value should correspond exactly to what appears in splitio
export enum WarehouseFeature {
  SHOW_PALLET_RECEIVE = "show_pallet_receive",
  SHOW_CONSOLIDATION_RECEIVE = "show_transfer_receive",
  SHOW_PALLET_DASHBOARD = "show_pallet_dashboard",
  SHOW_PALLET_ANALYTICS_V2 = "show_pallet_analytics_v2",
  SHOW_RECOMMENDED_RECEIVE_ZONE = "show_recommended_receive_zone",
  SHOW_BOX_ARRIVAL_SCAN = "SHOW_BOX_ARRIVAL_SCAN",
  ENABLE_IMAGE_CAPTURE_FOR_RETURN_RECEIVE = "enable_image_capture_for_return_receive",
  SHOW_RS_OUTBOUNDS_APP = "SHOW_RS_OUTBOUNDS_APP",
}
