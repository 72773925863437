import { useAuth0 } from "@auth0/auth0-react";
import { setLogUser } from "facility-commons/utils/logger";
import * as Cookie from "js-cookie";
import { useRouteMatch } from "react-router";
import { useSetRecoilState } from "recoil";

import { userState } from "facility-commons/base/Auth/userState";
import { DEFAULT_LANGUAGE } from "facility-commons/common/DefaultLanguage";
import { AppName } from "facility-commons/types";
import { AUTH_LOCAL_STORAGE_KEYS, COOKIE_KEYS, ROLES } from "facility-commons/types/Auth";
import { updateDataDogIdentity } from "facility-commons/utils/thirdParty/DataDog/DataDog";
import { useCallback, useEffect } from "react";

export const DEFAULT_WAREHOUSE_ID = "TST_LOG";
export const DEFAULT_CROSSDOCK_WAREHOUSE_ID = "GPA_FON";

// Apps that should default to crossdock use
const CROSSDOCK_APPS: string[] = [AppName.CD, AppName.PR];

const DELIVERR_URL = "https://deliverr.com/";

export function useUserState(app: AppName) {
  const defaultWarehouse = CROSSDOCK_APPS.includes(app) ? DEFAULT_CROSSDOCK_WAREHOUSE_ID : DEFAULT_WAREHOUSE_ID;
  const setAuthUser = useSetRecoilState(userState);
  const match = useRouteMatch();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const updateUserInfo = useCallback(async () => {
    let isHub = false;
    let isCrossdcock = false;
    let newWarehouseId = "";
    if (user?.[DELIVERR_URL] && isAuthenticated && !isLoading) {
      const roles = user[DELIVERR_URL]?.roles || [];
      const userApps = [...(user[DELIVERR_URL]?.apps ?? []), ...roles];

      // Admins do not have preset warehouseIds in their user profile
      // so either grab the last one set in the browser, or use the default
      if (roles.includes(ROLES.WAREHOUSE_ADMIN_ROLE)) {
        newWarehouseId = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.WAREHOUSE_ID) ?? defaultWarehouse; // default warehouseID for admin user
        isHub = newWarehouseId.includes("HUB");
        isCrossdcock = true;
      } else {
        newWarehouseId = user[DELIVERR_URL]?.warehouseId;
        window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.WAREHOUSE_ID, newWarehouseId);
        window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.USERNAME, user.email);
      }

      const userData = {
        warehouseId: newWarehouseId,
        userName: user.nickname,
        email: user.email,
        isManager: roles.includes(ROLES.WAREHOUSE_MANAGER_ROLE),
        isAdmin: roles.includes(ROLES.WAREHOUSE_ADMIN_ROLE),
        isProblemSolver: roles.includes(ROLES.PROBLEM_SOLVER_ROLE),
        isHub: userApps.includes(AppName.HB) || isHub,
        isCrossdock: userApps.includes(AppName.CD) || isCrossdcock,
        language: DEFAULT_LANGUAGE,
      };

      setAuthUser((state) => ({ ...state, ...userData }));
      setLogUser({
        email: userData.email,
        warehouseId: userData.warehouseId,
      });
      updateDataDogIdentity({ user: { id: user.sub, ...userData } });

      Cookie.set(COOKIE_KEYS.WAREHOUSE_ID, newWarehouseId);
      Cookie.set(COOKIE_KEYS.EMAIL, user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, setAuthUser, user]);

  useEffect(() => {
    updateUserInfo();
  }, [updateUserInfo]);

  // Full Story
  if (window.FS) {
    window.FS.identify(user.email, user);
  }

  return {
    isAuthenticated,
    isLoading,
    match,
  };
}
