import { logStart } from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

export interface ConfirmCumulativeReceiveToSameLocationModalProps {
  inputtedQuantity: number;
  previouslyReceived: number;
  proceedWithSubmit: () => Promise<void>;
}

export const useConfirmCumulativeReceiveToSameLocationModal = (
  props: ConfirmCumulativeReceiveToSameLocationModalProps
) => {
  const { inputtedQuantity, proceedWithSubmit, previouslyReceived } = props;

  const { hideModal } = useWarehouseModal();

  const hideConfirmCumulativeReceiveToSameLocationModal = () =>
    hideModal(WarehouseModal.CONFIRM_CUMULATIVE_RECEIVE_TO_SAME_LOCATION);

  const handleModalClickContinue = async () => {
    const ctx = { fn: "ConfirmCumulativeReceiveToSameLocationModal.handleModalClickContinue", props };
    logStart(ctx);

    await proceedWithSubmit();
    hideConfirmCumulativeReceiveToSameLocationModal();
  };

  return {
    handleModalClickContinue,
    hideConfirmCumulativeReceiveToSameLocationModal,
    inputtedQuantity,
    previouslyReceived,
  };
};
