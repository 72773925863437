import React from "react";
import { Route } from "react-router-dom";
import { WarehousePortalRoutes } from "warehouse/routes";

import { DamagedTypeSelector } from "./DamagedTypeSelector";
import { OrderPickingForm } from "./OrderPickingForm";
import { WarehouseDamagedProductForm } from "./WarehouseDamagedProductForm";
import { StorageDamagedProductForm } from "./StorageDamagedProductForm";

export const DamagedProductRoutes = [
  <Route
    exact
    key={WarehousePortalRoutes.DAMAGED_PICKING_TICKET}
    path={WarehousePortalRoutes.DAMAGED_PICKING_TICKET}
    render={() => <OrderPickingForm />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.DAMAGED_RECEIVING_TICKET}
    path={WarehousePortalRoutes.DAMAGED_RECEIVING_TICKET}
    render={() => <WarehouseDamagedProductForm />}
  />,

  <Route
    exact
    key={WarehousePortalRoutes.DAMAGED_TICKET}
    path={WarehousePortalRoutes.DAMAGED_TICKET}
    render={() => <DamagedTypeSelector />}
  />,

  <Route
    key={WarehousePortalRoutes.STORAGE_DAMAGED_EXPIRED_CASE}
    path={WarehousePortalRoutes.STORAGE_DAMAGED_EXPIRED_CASE}
    render={() => <StorageDamagedProductForm />}
  />,
];
