import React from "react";
import { useIntl } from "react-intl";
import { PromptModal, warningModalIconLg } from "facility-commons";
import { outboundModalLabels } from "../outboundModalLabels";

export interface OutboundScanNextShipmentModalProps {
  onCancelClick: () => void;
  onConfirmClick: () => void;
  totalPendingItems: number;
}

export const OutboundScanNextShipmentModal: React.FC<OutboundScanNextShipmentModalProps> = ({
  onCancelClick,
  onConfirmClick,
  totalPendingItems,
}) => {
  const { formatMessage } = useIntl();

  return (
    <PromptModal
      title={formatMessage(outboundModalLabels.itemPendingTitle, { totalPendingItems })}
      text={formatMessage(outboundModalLabels.itemPendingDescription, { totalPendingItems })}
      iconProps={warningModalIconLg}
      cancelButtonLabel={formatMessage(outboundModalLabels.back)}
      onCancelClick={onCancelClick}
      confirmButtonLabel={formatMessage(outboundModalLabels.continue)}
      onConfirmClick={onConfirmClick}
    />
  );
};
