import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { configContext } from "./configContext";
import { TextInput } from "react-chatbot-ui";

const StyledInput = styled.input`
  padding: 0.5em;
`;

export const InputBox = ({ inputType }: { inputType: string }) => {
  const ref = useRef<HTMLInputElement>(null);
  const config = useContext(configContext);

  const [value, setValue] = useState("");

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (config?.sendCallback) {
        const userInput: TextInput = {
          payload: value,
        };
        config.sendCallback(userInput);
      }
      setValue("");
    }
  };

  useEffect(() => {
    ref?.current?.focus();
  });

  return (
    <StyledInput
      type={inputType ?? "text"}
      value={value}
      ref={ref}
      onKeyDown={onKeyDown}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};
