import React, { FC } from "react";
import { BoxReset, Button, LocalizedMessage, ThemeProps } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";

import { ModalTemplate, successModalIconLg } from "facility-commons";
import { SoundFx } from "facility-commons/common/sfx";

interface TicketSuccessModalProps {
  onViewTicketsClick?: () => void;
  onDoneClick: () => void;
  caseNumber: number;
  additionalText?: LocalizedMessage;
}

const CaseNumberBox = styled.div<ThemeProps>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL[60]};
    color: ${theme.colors.NEUTRAL[400]};
    padding: ${theme.spacing.S3} ${theme.spacing.S4};
  `
);

export const TicketSuccessModal: FC<TicketSuccessModalProps> = ({
  onViewTicketsClick,
  onDoneClick,
  caseNumber,
  additionalText,
}) => {
  const actionButtons =
    onViewTicketsClick !== undefined ? (
      <>
        <BoxReset marginBottom="S3">
          <Button block onClick={onViewTicketsClick}>
            <FormattedMessage id="facilityCommons.ticketSuccessModal.viewTicketsButton" defaultMessage="View tickets" />
          </Button>
        </BoxReset>
        <Button secondary block onClick={onDoneClick}>
          <FormattedMessage id="facilityCommons.ticketSuccessModal.doneButton" defaultMessage="Done" />
        </Button>
      </>
    ) : (
      <Button block onClick={onDoneClick}>
        <FormattedMessage id="facilityCommons.ticketSuccessModal.doneButton" defaultMessage="Done" />
      </Button>
    );

  return (
    <ModalTemplate
      soundFx={SoundFx.SUCCESS}
      title={<FormattedMessage id="facilityCommons.ticketSuccessModal.title" defaultMessage="Ticket created" />}
      text={additionalText}
      iconProps={successModalIconLg}
      buttonLayout="column"
      content={
        <CaseNumberBox>
          <FormattedMessage
            id="facilityCommons.ticketSuccessModal.caseNumber"
            defaultMessage="CASE # {caseNumber}"
            values={{ caseNumber }}
          />
        </CaseNumberBox>
      }
    >
      {actionButtons}
    </ModalTemplate>
  );
};
