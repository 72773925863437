import { receivingState } from "./ReceivingState";
import { currentSkuState } from "./CurrentSkuState";
import { isEmpty, isNil } from "lodash";
import { selector } from "recoil";
import { receiveFlowTypeState } from "./ReceiveFlowTypeState";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { isBoxReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";
import { receivingMisMatchState } from "./ReceivingMismatch";

export const getIsMidFlow = ({ get }) => {
  const flowType = get(receiveFlowTypeState);
  const isBoxReceive = isBoxReceiveFlow(flowType);
  const { asnId, consolidationLabel } = get(receivingState);
  const isMismatch = get(receivingMisMatchState);
  const cdsku = get(currentSkuState).cdsku;
  // if a mismatch happened, we route users to NC without showing the route guard modal
  return !isMismatch && ((isBoxReceive && !isEmpty(cdsku)) || !isNil(asnId) || !isNil(consolidationLabel));
};

export const isMidFlowState = selector({
  key: WarehouseStateKey.IS_MID_FLOW,
  get: getIsMidFlow,
});
