import { NonComplianceErrors } from "@deliverr/commons-clients";
import { ErrorOption } from "react-hook-form";

import { minASNLength, validCDSKU, maxCDSKULength, validEmailPattern } from "facility-commons/utils/config";
import { OtherNonComplianceField, OtherNonComplianceValidationErrorMessages } from "./OtherNonComplianceForm.types";

export const validationConfig = {
  [OtherNonComplianceField.TRACKING_CODE]: {
    minLength: {
      value: minASNLength,
      message: OtherNonComplianceValidationErrorMessages.VALIDATION_ERROR,
    },
  },
  [OtherNonComplianceField.CDSKU]: {
    minLength: {
      value: maxCDSKULength,
      message: OtherNonComplianceValidationErrorMessages.CDSKU_FORMAT,
    },
    maxLength: {
      value: maxCDSKULength,
      message: OtherNonComplianceValidationErrorMessages.CDSKU_FORMAT,
    },
    pattern: {
      value: validCDSKU,
      message: OtherNonComplianceValidationErrorMessages.CDSKU_FORMAT,
    },
  },
  [OtherNonComplianceField.QUANTITY]: {
    validate: (data) => {
      if (parseInt(data, 10) <= 0) {
        return OtherNonComplianceValidationErrorMessages.INVALID_QUANTITY_ERROR;
      }
    },
  },
  [OtherNonComplianceField.REASON]: {
    required: OtherNonComplianceValidationErrorMessages.EMPTY_FIELD_ERROR,
  },
  [OtherNonComplianceField.EMAIL]: {
    pattern: {
      value: validEmailPattern,
      message: OtherNonComplianceValidationErrorMessages.INVALID_EMAIL,
    },
  },
};

type SetErrorArgs = [string, ErrorOption];

// see function args in https://react-hook-form.com/api#setError
export const nonComplianceServiceValidationMap: { [error: string]: SetErrorArgs } = {
  [NonComplianceErrors.VALIDATION_ERROR]: [
    OtherNonComplianceField.TRACKING_CODE,
    { type: "string", message: OtherNonComplianceValidationErrorMessages[NonComplianceErrors.VALIDATION_ERROR] },
  ],
  [NonComplianceErrors.CDSKU_INVALID]: [
    OtherNonComplianceField.CDSKU,
    { type: "string", message: OtherNonComplianceValidationErrorMessages[NonComplianceErrors.CDSKU_INVALID] },
  ],
  [NonComplianceErrors.BARCODE_INVALID]: [
    OtherNonComplianceField.BARCODE,
    { type: "string", message: OtherNonComplianceValidationErrorMessages[NonComplianceErrors.BARCODE_INVALID] },
  ],
  unknown: [
    OtherNonComplianceField.BARCODE,
    { type: "string", message: OtherNonComplianceValidationErrorMessages.UNKNOWN },
  ],
};
