import React from "react";
import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { Box } from "@deliverr/ui";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { TicketCardPlaceholder } from "warehouse/ticket-center/common/components/TicketCardPlaceholder";
import { CenteredSpinner } from "../CenteredSpinner";
import { ZendeskCard } from "../ZendeskCard";
import { ResolutionCardActionSection } from "../ResolutionCardActionSection";
import { TicketCenterInvalidLotExpiryCaseResponse } from "@deliverr/commons-clients";

export const LotFefoResolutionCard: React.FC = () => {
  const { loading, ticketDetails, viewSupportTicket, markClosed, rollbackClose, downloadBarcodes } = useTicketDetails<
    TicketCenterInvalidLotExpiryCaseResponse
  >();

  const showNoDetailsPlaceholder =
    ticketDetails?.status === TicketCenterNonComplianceCaseStatus.CREATED || !ticketDetails?.ticketUrl;

  return loading || !ticketDetails ? (
    <CenteredSpinner loading />
  ) : (
    <Box height="100%">
      <DashboardDetailCard
        createdAt={new Date(ticketDetails.createdAt)}
        placeholder={showNoDetailsPlaceholder ? <TicketCardPlaceholder /> : undefined}
      >
        {ticketDetails.ticketUrl && <ZendeskCard zendeskUrl={ticketDetails.ticketUrl} />}
      </DashboardDetailCard>
      <ResolutionCardActionSection
        isTicketUrlEmpty={!ticketDetails.ticketUrl}
        viewSupportTicket={viewSupportTicket}
        shouldShowPrintBarcodesButton={false}
        downloadBarcodes={downloadBarcodes}
        markClosed={markClosed}
        rollbackClose={rollbackClose}
        ticketDetails={ticketDetails}
        canBeReopened
      />
    </Box>
  );
};
