import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReceivingDataStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "../../state";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useInspectionRouting, useSubmitInspectionResults } from "../../hooks";

export const useReturnBoxScan = () => {
  const { formatMessage } = useIntl();
  const { successResponse, errorResponse } = useCommonFlow();
  const { submitInspectionResults } = useSubmitInspectionResults();
  const { inspectNextItemOrStartNextOrder } = useInspectionRouting();
  const { showModal } = useWarehouseModal();
  const [boxBarcodeError, setBoxBarcodeError] = useState<string>("");
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const [itemInspectionData, setItemInspectionData] = useRecoilState(returnReceiveItemInspectionStateAtom);
  const returnReceiveData = useRecoilValue(returnReceiveReceivingDataStateAtom);
  const { dsku, itemName, barcode, returnsLocation } = itemInspectionData;

  const updateBoxBarcode = (boxBarcode: string) => {
    setBoxBarcodeError("");
    setItemInspectionData(setProp("returnsLocation", boxBarcode.length ? boxBarcode : null));
  };

  const [submitState, addItemToRTMBox] = useAsyncFn(
    async (boxBarcodeInput: string) => {
      setBoxBarcodeError("");
      if (!boxBarcodeInput.length) {
        setBoxBarcodeError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
        return;
      }

      const ctx = logStart({
        fn: "useReturnBoxScan.addItemToRTMBox",
        returnOrderData,
        itemInspectionData,
        returnReceiveData,
        returnsLocation: boxBarcodeInput,
      });

      try {
        await submitInspectionResults({ receiveLocation: null, returnsLocation: boxBarcodeInput });
        successResponse();
        inspectNextItemOrStartNextOrder();
      } catch (error: any) {
        log({ ...ctx, error }, "error adding item to RTM box");
        errorResponse();
        setBoxBarcodeError(String(error.message));
      }
    },
    [returnsLocation]
  );

  const openNewRTMBox = async () => {
    showModal(WarehouseModal.RETURN_RECEIVE_RTM_BOX_CREATION, {});
  };

  const handleChange = genericOnScannerInputChange(returnsLocation ?? "", updateBoxBarcode, addItemToRTMBox);

  const messages = {
    returnBoxScanTitle: formatMessage(commonReturnReceiveMessage.scanRTMBox),
    returnBoxScanInstructions: formatMessage(commonReturnReceiveMessage.addItemToRTMBox),
    returnBoxScanInputPlaceholder: formatMessage(commonReturnReceiveMessage.RTMBoxBarcode),
    createNewRtmBox: formatMessage(commonReturnReceiveMessage.createNewRTMBox),
  };

  return {
    barcode,
    dsku,
    itemName,
    messages,
    returnsLocation: returnsLocation ?? "",
    boxBarcodeError,
    loading: submitState.loading,
    handleChange,
    addItemToRTMBox,
    openNewRTMBox,
  };
};
