import { Stack } from "@deliverr/ui";
import { Direction, ThemeProps } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React from "react";
import { useIntl } from "react-intl";
import { DashboardDetailExpansionBlock } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

interface TicketCardThumbnailsProps {
  thumbnails: string[];
  addDivider?: boolean;
  addBottomPadding?: boolean;
  onThumbnailClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
  shouldBoldTitle?: boolean;
}

const Image = styled.img`
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
`;

const ImageStack = styled(Stack)`
  flex-wrap: nowrap;
`;

const ImageWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
    flex: 1;
    position: relative;
    max-width: calc(25% - ${theme.spacing.S2} / 4);

    // A bit of a hack to make height automatically
    // the same as whatever flexbox sets the width to
    // https://stackoverflow.com/questions/51447317/image-height-same-as-width

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  `
);

export const TicketCardThumbnails: React.FC<TicketCardThumbnailsProps> = ({
  thumbnails,
  addDivider,
  addBottomPadding,
  onThumbnailClick,
  shouldBoldTitle = false,
}) => {
  const { showModal } = useWarehouseModal();
  const handleThumbnailClick = (event: React.MouseEvent<HTMLImageElement>) =>
    onThumbnailClick ||
    showModal(WarehouseModal.TICKET_CENTER_THUMBNAIL, { link: event.currentTarget.getAttribute("src") });
  const { formatMessage } = useIntl();

  return (
    <DashboardDetailExpansionBlock
      addBottomPadding={addBottomPadding}
      addDivider={addDivider}
      disableExpansion
      defaultOpen
      title={formatMessage({ id: "warehouse.ticketCenter.ticketCardThumbnails", defaultMessage: "Photos" })}
      shouldBoldTitle
    >
      <ImageStack direction={Direction.HORIZONTAL} gap="S2">
        {thumbnails.map((thumbnail) => (
          <ImageWrapper key={thumbnail}>
            <Image src={thumbnail} onClick={handleThumbnailClick} />
          </ImageWrapper>
        ))}
      </ImageStack>
    </DashboardDetailExpansionBlock>
  );
};
