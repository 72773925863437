import React from "react";
import styled from "@emotion/styled";
import { TextInputCard } from "facility-commons/flow";
import { usePOWorklistPrint } from "./usePOWorklistPrint";
import { Box, Button, Stack } from "@deliverr/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { FACILITY_COMMONS_BUTTON_LABELS } from "facility-commons";
import { STORAGE_TOOL_PO_WORKLIST_MESSAGES } from "warehouse/common/messages/storage/StorageToolsMessages";

const StyledPage = styled(Box)`
  height: 100%;
`;

const StyledCard = styled(Stack)`
  width: 328px;
  justify-content: space-between;
  height: 100%;
`;

export const POWorklistCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const poScanTitle = formatMessage(STORAGE_TOOL_PO_WORKLIST_MESSAGES.boxTitle);
  const { poNumber, scanError, handleChange, handleSubmit, handleSubmitClick, loading } = usePOWorklistPrint();

  return (
    <StyledPage>
      <StyledCard>
        <TextInputCard
          errorMessage={scanError}
          message={formatMessage(STORAGE_TOOL_PO_WORKLIST_MESSAGES.boxDescription)}
          newest
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeholder={formatMessage(STORAGE_TOOL_PO_WORKLIST_MESSAGES.boxPlaceholder)}
          loading={loading}
          title={poScanTitle}
          value={poNumber ?? ""}
        />
        <Button block onClick={handleSubmitClick} type="button">
          <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.submitButton} />
        </Button>
      </StyledCard>
    </StyledPage>
  );
};
