import { Select, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { LanguageOption } from "facility-commons/types";

const StyledSelectMenuItem = styled.div<ThemeProps>(
  ({ theme }) => `
  // select component container
  label {
    display: flex;

    > div:last-of-type { // select dropdown component positioning
      margin: 0 ${theme.spacing.S4} 0 auto;
    }
  }

  // select label component
  span {
    color: ${theme.colors.NEUTRAL[500]};
    font-weight: ${theme.font.weight.REGULAR};
    line-height: ${theme.spacing.S8};
    font-size: ${theme.font.size.F3};
  }
`
);

const StyledSelect = styled(Select)<ThemeProps>(({ theme }) => {
  return `
    width: 8rem;

    & > div {
      min-height: auto;

      & > div:first-of-type {
        padding: ${theme.spacing.S1};
        padding-left: ${theme.spacing.S4};
        font-size: ${theme.font.size.F1};
        background-color: ${theme.colors.NEUTRAL["00"]};
        text-align: left;

        div {
          color: ${theme.colors.NEUTRAL["300"]}
        }
      }
    }

    & > div:nth-of-type(2) > div > div {
      padding-left: ${theme.border.width.B2};
    }
  `;
});

interface LanguageSelectorProps {
  label: JSX.Element;
  onSelection: (selectedLanguage: LanguageOption) => void;
  options: { value: string; label: string }[];
  value: { value: string; label: string };
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ label, onSelection, options, value }) => {
  return (
    <StyledSelectMenuItem>
      <StyledSelect label={label} options={options} onChange={onSelection} value={value} />
    </StyledSelectMenuItem>
  );
};
