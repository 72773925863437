import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { CdskuData } from "./Types";

export const INITIAL_CDSKU_STATE: CdskuData = {
  isHighRisk: false,
  expectedNumUnitsInBox: 0,
  receiveCount: 0,
};

export const cdskuState = atom<CdskuData>({
  key: WarehouseStateKey.CURRENT_CDSKU,
  default: INITIAL_CDSKU_STATE,
});
