import { defineMessages } from "react-intl";

export const STORAGE_PRINT_PALLET_LABELS_MESSAGES = defineMessages({
  numLabelsToPrint: {
    id: "storage.numLabelsToPrint",
    defaultMessage: "Number of labels",
  },
  printPalletLabels: {
    id: "storage.printPalletLabels",
    defaultMessage: "Print Pallet Labels",
  },
  numLabelsRangeMessage: {
    id: "storage.numLabelsRangeMessage",
    defaultMessage: "Please enter a number between {minLabelCount} to {maxLabelCount}",
  },
  maxLabelCountMessage: {
    id: "storage.maxLabelCountMessage",
    defaultMessage: "Label count cannot be more than {maxLabelCount}",
  },
  minLabelCountMessage: {
    id: "storage.minLabelCountMessage",
    defaultMessage: "Label count cannot be less than {minLabelCount}",
  },
  labelsDownloadingMessage: {
    id: "storage.labelsDownloadingMessage",
    defaultMessage: "Creating pallet labels...",
  },
  doNotNavigateAwayMessage: {
    id: "storage.doNotNavigateAwayMessage",
    defaultMessage: "Do not navigate away from this page.",
  },
  printPalletLabelsError: {
    id: "storage.printPalletLabelsError",
    defaultMessage: "Error while printing labels. Please try again.",
  },
});
