import { defineMessages, MessageDescriptor } from "react-intl";

export enum PendingOperationErrorMessageKey {
  PENDING_OPERATION = "PENDING_OPERATION",
}

export const PENDING_OPERATION_ERROR_BY_ERROR_CODE: Record<
  PendingOperationErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PendingOperationErrorMessageKey.PENDING_OPERATION]: {
    id: "warehouse.receiving.pendingOperations",
    defaultMessage: "Cannot receive this SKU in this box before the last receive has been processed.",
  },
});
