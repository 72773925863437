import { log } from "facility-commons/utils";
import { useState } from "react";

export const useCdskuList = () => {
  const [showAllCdskus, setShowAllCdskus] = useState(false);

  const toggleList = () => {
    const newValue = !showAllCdskus;
    log({ fn: "CdskuList.toggleList", toggled: newValue ? "on" : "off" }, "cdsku list toggled");
    setShowAllCdskus(newValue);
  };

  return {
    showAllCdskus,
    toggleList,
  };
};
