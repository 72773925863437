import { useIntl } from "react-intl";
import { BASE_RESOLUTION_CARD_LABELS } from "./BaseResolutionCard.labels";

export const useBarcodeCaseResolutionCardLabels = () => {
  const { formatMessage } = useIntl();

  return Object.entries(BASE_RESOLUTION_CARD_LABELS).reduce<Record<string, Record<string, string>>>(
    (groupedLabels, [groupKey, messageDescriptors]) => ({
      ...groupedLabels,
      [groupKey]: Object.entries(messageDescriptors).reduce(
        (localizedMessages, [messageKey, descriptor]) => ({
          ...localizedMessages,
          [messageKey]: formatMessage(descriptor),
        }),
        {} as Record<string, string>
      ),
    }),
    {} as Record<string, Record<string, string>>
  );
};
