import * as React from "react";
import { useVersionCheck } from "./useVersionCheck";
import { ToastContainer, Toast } from "facility-commons/components";

export const VersionCheck: React.FC = React.memo(() => {
  const { newVersionAvailable, onRefreshClick } = useVersionCheck();

  return newVersionAvailable ? (
    <ToastContainer position="bottom-left">
      <Toast
        position="bottom-left"
        message="A new version is available!"
        buttonLabel="Refresh"
        onButtonClick={onRefreshClick}
      />
    </ToastContainer>
  ) : null;
});
