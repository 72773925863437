import { useMount } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";

import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { allWarehouseIdsState, userState } from "facility-commons/base/Auth/userState";
import { AppName } from "facility-commons/types";
import { getWarehouseIdsByApp } from "./getWarehouseIdsByApp";

export interface WarehouseSelection {
  label: string;
  value: string;
}

export function useAdminWarehouseSelector(appName: AppName, onSelection: (selection: WarehouseSelection) => void) {
  const { warehousePublicClient } = useClientsWithAuth();
  const [allWarehouseIds, setAllWarehouseIds] = useRecoilState(allWarehouseIdsState);
  const { warehouseId } = useRecoilValue(userState);

  useMount(async () => {
    if (!allWarehouseIds.length) {
      setAllWarehouseIds(await getWarehouseIdsByApp(appName, warehousePublicClient));
    }
  });

  return {
    warehouseId,
    handleWarehouseSelection: onSelection,
  };
}
