import { MenuLinkConfig } from "facility-commons/components/LinksMenu";
import React from "react";
import { FormattedMessage } from "react-intl";

import { MoreRoutesNames } from "./MoreRoutes";

// Enum order determines More menu links order
export enum MoreLinksNames {
  DASHBOARD = "DASHBOARD",
  DHL = "DHL",
  LOOKUP = "LOOKUP",
  HAZMAT = "HAZMAT",
  OUTBOUND_CAPACITY = "OUTBOUND_CAPACITY",
  HEADCOUNT = "HEADCOUNT",
  DOCK = "DOCK",
  SOP = "SOP",
  HOLIDAY_SUBMISSION = "HOLIDAY_SUBMISSION",
  FEEDBACK_SURVEY = "FEEDBACK_SURVEY",
  PACKAGING_FEEDBACK = "PACKAGING_FEEDBACK",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  PRINT_PALLET_LABELS = "PRINT_PALLET_LABELS",
  PRINT_SSCC_LABELS = "PRINT_SSCC_LABELS",
  PO_WORKLIST = "PO_WORKLIST",
  REPORT_WEIGHTS_DIMS = "REPORT_WEIGHTS_DIMS",
  STORAGE_WAREHOUSE_BOT = "STORAGE_WAREHOUSE_BOT",
  BOL_NUMBER_LOOKUP = "BOL_NUMBER_LOOKUP",
  GET_PRODUCT_ZONE = "GET_PRODUCT_ZONE",
}

// Helper function orders links based on above enum as they become available
export const orderLinks = (menuLinks): MenuLinkConfig<MoreLinksNames>[] => {
  const linksOrder = Object.values(MoreLinksNames);
  return menuLinks.slice().sort((a, b) => linksOrder.indexOf(a.id) - linksOrder.indexOf(b.id));
};

export const defaultToolLinks: MenuLinkConfig<MoreLinksNames>[] = [
  {
    text: <FormattedMessage id="warehouse.moreMenu.poNumberLookup" defaultMessage="PO # Lookup" />,
    route: MoreRoutesNames.PO_NUMBER_LOOKUP,
    id: MoreLinksNames.LOOKUP,
  },
  {
    text: <FormattedMessage id="warehouse.moreMenu.outboundCapacity" defaultMessage="Outbound Capacity" />,
    route: MoreRoutesNames.OUTBOUND_CAPACITY,
    id: MoreLinksNames.OUTBOUND_CAPACITY,
  },
  {
    text: <FormattedMessage id="warehouse.moreMenu.getProductZone" defaultMessage="Get Product Zone" />,
    route: MoreRoutesNames.GET_PRODUCT_ZONE,
    id: MoreLinksNames.GET_PRODUCT_ZONE,
  },
];

export const defaultInformationLinks: MenuLinkConfig<MoreLinksNames>[] = [
  {
    text: <FormattedMessage id="warehouse.moreMenu.theDock" defaultMessage="The DOCK" />,
    url:
      "https://www.notion.so/The-DOCK-Deliverr-Ops-Centralized-Knowledge-for-Warehouse-Partners-b5973e8286d349f69e0f24c064c2ce26",
    id: MoreLinksNames.DOCK,
  },
];
