import { defineMessages } from "react-intl";
import { LanguageCode } from "../../types";

export const FACILITY_LANGUAGE_LABELS = defineMessages({
  [LanguageCode.ENGLISH]: {
    id: "facilityCommons.language.english",
    defaultMessage: "English",
  },
  [LanguageCode.SPANISH]: {
    id: "facilityCommons.language.espanol",
    defaultMessage: "Español",
  },
});

export const FACILITY_LANGUAGE_ABBREV_LABELS = defineMessages({
  [LanguageCode.ENGLISH]: {
    id: "facilityCommons.language.en",
    defaultMessage: "EN",
  },
  [LanguageCode.SPANISH]: {
    id: "facilityCommons.language.es",
    defaultMessage: "ES",
  },
});
