import { useIntl } from "react-intl";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { modalsText } from "../modalsText";
import { useRecoilValue } from "recoil";
import { returnReceiveReturnOrderStateAtom } from "warehouse/receiving/ReturnReceive/state";
import { useAsyncFn } from "react-use";
import { getProductBarcode } from "warehouse/receiving/ReturnReceive/utils";
import { useState } from "react";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useBarcodePrint } from "warehouse/receiving/ReturnReceive/hooks";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";

export const useReturnReceiveExpectedItemsModal = () => {
  const { formatMessage } = useIntl();
  const { hideModal } = useWarehouseModal();
  const { expectedItems, expectedItemsProductDetails } = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const { errorResponse } = useCommonFlow();
  const { printBarcode } = useBarcodePrint();
  const [barcodePrintError, setBarcodePrintError] = useState<string | null>(null);

  const closeModal = () => {
    hideModal(WarehouseModal.RETURN_RECEIVE_EXPECTED_ITEMS);
  };

  const [submitState, findBarcodeForDskuAndPrint] = useAsyncFn(
    async (dsku: string) => {
      setBarcodePrintError(null);
      try {
        const barcode =
          expectedItemsProductDetails && expectedItemsProductDetails[dsku]
            ? getProductBarcode(expectedItemsProductDetails[dsku])
            : undefined;

        if (!barcode) {
          errorResponse();
          setBarcodePrintError(formatMessage(commonReturnReceiveMessage.noBarcodeFoundForItem));
          return;
        }

        await printBarcode(barcode, expectedItemsProductDetails?.[dsku]?.name || "", true);
      } catch (error) {
        errorResponse();
        setBarcodePrintError(String(error));
      }
    },
    [expectedItems, expectedItemsProductDetails]
  );

  const modalTitle = formatMessage(modalsText.returnReceiveExpectedItemsModalTitle);
  const buttonText = { print: formatMessage(modalsText.print), close: formatMessage(modalsText.close) };
  return {
    expectedItems,
    expectedItemsProductDetails,
    modalTitle,
    buttonText,
    barcodePrintError,
    loading: submitState.loading,
    findBarcodeForDskuAndPrint,
    closeModal,
  };
};
