import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { useMount } from "react-use";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKUScan, createReportProblemSelection } from "../storageReceivingCardCreators";
import { linkMapConfig } from "warehouse/ticket-center/common/types";
import { POActionSelection } from "./POSelectionEnum";

export const useStorageReceivePOSelectionStep = () => {
  const { formatMessage } = useIntl();
  const [{ poDetails, skus }] = useRecoilState(storageInboundReceivingStateAtom);
  const { resetNotifications } = useCommonFlow();
  const { transition } = useWarehouseFlow();

  useMount(() => {
    setTimeout(() => {
      resetNotifications();
    }, 3000);
  });

  const selectReceivingState = (type) => {
    if (type === POActionSelection.SKU) {
      transition([{ card: createReceivingSKUScan({}) }]);
    } else if (type === POActionSelection.REPORT_PROBLEM) {
      transition([{ card: createReportProblemSelection({}) }]);
    }
  };

  const linkMap: linkMapConfig = [
    {
      title: formatMessage(STORAGE_RECEIVING_MESSAGES.poSelectionStepReceiveSku),
      onClick: () => selectReceivingState(POActionSelection.SKU),
    },
    {
      title: formatMessage(STORAGE_RECEIVING_MESSAGES.reportProblem),
      onClick: () => selectReceivingState(POActionSelection.REPORT_PROBLEM),
    },
  ];

  return {
    poDetails,
    skus,
    linkMap,
  };
};
