import React from "react";
import { Text, ThemeProps, IconV2 } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { useDefaultLocationLink } from "./useDefaultLocationLink";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { locationMessages } from "warehouse/receiving/content";

const CardContainer = styled(FacilityCard)`
  display: grid;
  grid-template-columns: 60% 35% 5%;
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
  color: ${theme.colors.NEUTRAL[500]};
`
);

const StyledRightAlignedText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
  color: ${theme.colors.NEUTRAL[500]};
  text-align: right;
`
);

export const DefaultLocationLink = () => {
  const { defaultLocation, path, handleOnLinkClick } = useDefaultLocationLink();
  return (
    <Link to={path} onClick={handleOnLinkClick}>
      <CardContainer data-testid={"set-default-location-link"}>
        <StyledText appearance="INFO">
          <FormattedMessage {...locationMessages.location} />
        </StyledText>
        <StyledRightAlignedText appearance="INFO">{defaultLocation}</StyledRightAlignedText>
        <IconV2 size="lg" type="angle-right" />
      </CardContainer>
    </Link>
  );
};
