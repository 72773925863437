import React from "react";

import { NcCardStack, SkipStep } from "../../components";
import { useBarcodeUpload } from "./useBarcodeUpload";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";
import { commonUploadMessages } from "facility-commons/labels";

export const BarcodeUpload: React.FC = () => {
  const { imageUploaded, formatMessage, onFileChange, proceed, register } = useBarcodeUpload();

  return (
    <NcCardStack
      continueDisabled={!imageUploaded}
      continueOnClick={proceed}
      title={formatMessage(commonUploadMessages.takeAPhotoBarcodeTitle)}
    >
      <NonComplianceUploadItem
        iconText={formatMessage(commonUploadMessages.takePhoto)}
        id="barcode-photo"
        {...register}
        onSuccessfulUpload={onFileChange}
      />
      <SkipStep message={formatMessage(commonUploadMessages.barcodeMissing)} onSkip={proceed} />
    </NcCardStack>
  );
};
