import { DeliverrError } from "@deliverr/commons-objects";
import { SplitFactory } from "@splitsoftware/splitio";

import { log, logStart } from "facility-commons/utils";
import { PrepFeature } from "prep/common/PrepFeature";
import { WarehouseFeature } from "warehouse/base/WarehouseFeature";

if (process.env.NODE_ENV !== "test" && !process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY) {
  throw new DeliverrError({
    code: "split auth key not defined",
  });
}

export let split: SplitIO.IClient | null;

export enum TrafficType {
  WAREHOUSE = "warehouseId",
  USER = "user",
}

export const destroySplit = async () => {
  const ctx = logStart({ fn: "destroySplit" });
  if (split?.destroy) {
    log(ctx, "split destroyed");
    const task = split.destroy();
    split = null;
    return task;
  }
};

export const initSplitClient = async (key: string, trafficType: TrafficType, readyTimeout = 60) => {
  const ctx = logStart({ fn: "initSplitClient" });
  await destroySplit();
  const factory: SplitIO.ISDK = SplitFactory({
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!,
      key,
      trafficType,
    },
    startup: {
      // avoid slow connections not loading features properly
      readyTimeout,
    },
    sync: {
      splitFilters: [
        {
          type: "byName",
          values: [...Object.values(PrepFeature), ...Object.values(WarehouseFeature)],
        },
      ],
    },
  });

  const client = factory.client();
  return new Promise<void>((resolve) => {
    if (client) {
      client.on(client.Event.SDK_READY, () => {
        log(ctx, "Split started", { key });
        split = client;
        resolve();
      });

      // If the client is not ready and the value for readyTimeout has been reached, this event will fire.
      client.once(client.Event.SDK_READY_TIMED_OUT, () => {
        log(ctx, "Split timed out", { key });
        // We will resolve the promise if the client is not ready because we still want the app to load.
        resolve();
      });
    }
  });
};

export const getFeatureTreatment = <AppFeature extends string = string>(
  featureName: AppFeature,
  attributes?: SplitIO.Attributes
): string | undefined => {
  if (!split) {
    return;
  }
  const treatment = split.getTreatment(featureName, attributes);
  return treatment;
};

export const isFeatureOn = <AppFeature extends string = string>(
  featureName: AppFeature,
  attributes?: SplitIO.Attributes
): boolean => {
  const treatment = getFeatureTreatment(featureName, attributes);
  return treatment === "on";
};
