import React from "react";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useStorageConfirmationModal = (modal) => {
  const { hideModal } = useWarehouseModal();

  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);

  const handleSuccess = async () => {
    try {
      setSubmitLoading(true);

      await modal?.onSubmit?.();
      hideModal(WarehouseModal.STORAGE_CONFIRMATION_MODAL);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setCancelLoading(true);

      await modal?.onCancel?.();
      hideModal(WarehouseModal.STORAGE_CONFIRMATION_MODAL);
    } finally {
      setCancelLoading(false);
    }
  };

  return { handleCancel, hideModal, submitLoading, cancelLoading, setSubmitLoading, setCancelLoading, handleSuccess };
};
