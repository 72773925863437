import { Input, Stack, Title } from "@deliverr/ui";
import React from "react";

import { commonMessages } from "facility-commons/labels";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { ContinueButton } from "warehouse/common/components";
import { errorMessages } from "warehouse/common/errorMessages";
import { emailMessages } from "../../content/email";
import { useEmailCard } from "./useEmailCard";

export const EmailCard: React.FC = () => {
  const { defaultEmail, email, formatMessage, hasError, proceed, registerEmail, updateEmail } = useEmailCard();

  return (
    <Stack>
      <FacilityCard>
        <Stack>
          <Title displayAs="h3">{formatMessage(emailMessages.emailTitle)}</Title>
          <Input
            {...registerEmail}
            hasError={hasError}
            errorMessage={formatMessage(errorMessages.validEmail)}
            helpText={defaultEmail ? formatMessage(emailMessages.emailHelpText, { defaultEmail }) : undefined}
            onChange={updateEmail}
            placeholder={formatMessage(commonMessages.email)}
            value={email}
          />
        </Stack>
      </FacilityCard>

      <ContinueButton block disabled={hasError} onClick={proceed} />
    </Stack>
  );
};
