import { defineMessages, MessageDescriptor } from "react-intl";

export enum RerouteCdskuErrorMessageKey {
  CDSKUS_NOT_REROUTED = "CDSKUS_NOT_REROUTED",
}

export const REROUTE_CDSKU_LABELS_BY_ERROR_CODE: Record<
  RerouteCdskuErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [RerouteCdskuErrorMessageKey.CDSKUS_NOT_REROUTED]: {
    id: "warehouse.receiving.rerouteCdskusError",
    defaultMessage: "Misrouted CDSKUs could not be rerouted. Please create a non-compliance ticket.",
  },
});
