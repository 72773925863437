import { format, addBusinessDays } from "date-fns";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { TicketSuccessModal } from "../TicketSuccessModal";
import { richText } from "facility-commons/intl/richText";
import { DateFormat } from "facility-commons/utils";
import { BoxType } from "facility-commons/flow/types/BoxType";

interface DamagedProductTicketSuccessModalProps {
  onDoneClick: () => void;
  caseNumber: number;
  shouldShowSaveCaseNumberMessage?: boolean;
  boxType?: BoxType;
}

const DISPOSE_AFTER_BUSINESS_DAYS = 14;

export const DamagedProductTicketSuccessModal: FC<DamagedProductTicketSuccessModalProps> = ({
  onDoneClick,
  caseNumber,
  shouldShowSaveCaseNumberMessage,
  boxType = BoxType.UNITS,
}) => {
  const disposeAfterDate = format(addBusinessDays(new Date(), DISPOSE_AFTER_BUSINESS_DAYS), DateFormat.MonthDayYear);

  return (
    <TicketSuccessModal
      onDoneClick={onDoneClick}
      caseNumber={caseNumber}
      additionalText={
        shouldShowSaveCaseNumberMessage ? (
          <FormattedMessage
            id="facilityCommons.damagedProductTicketSuccessModal.saveCaseNumberMessage"
            defaultMessage={
              "Save damaged or expired units until <boldtext>{disposeAfterDate}</boldtext>, if the units do not need to be disposed of immediately. Damage tickets don't appear in the Tickets page. Save case number for reference."
            }
            values={richText({ disposeAfterDate })}
          />
        ) : (
          <FormattedMessage
            id="facilityCommons.damagedProductTicketSuccessModal.defaultMessage"
            defaultMessage={
              "Save damaged or expired {boxType} until <boldtext>{disposeAfterDate}</boldtext>, if the {boxType} do not need to be disposed of immediately."
            }
            values={richText({ disposeAfterDate, boxType })}
          />
        )
      }
    />
  );
};
