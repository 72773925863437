import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useRouter } from "facility-commons/hooks";
import { WarehousePortalRoutes } from "warehouse/routes";
import { BoxType } from "facility-commons/flow/types/BoxType";

export const useWarehouseDamagedProductSuccessModal = () => {
  const { hideModal, showModal } = useWarehouseModal();
  const { history } = useRouter();

  const onDoneClick = () => {
    hideModal(WarehouseModal.DAMAGED_TICKET_CREATION_CONFIRMATION);
    history.push(WarehousePortalRoutes.NEW_TICKET);
  };

  const showSuccessModal = (caseNumber: number, shouldShowSaveCaseNumberMessage?: boolean, boxType?: BoxType) => {
    showModal(WarehouseModal.DAMAGED_TICKET_CREATION_CONFIRMATION, {
      onDoneClick,
      caseNumber,
      shouldShowSaveCaseNumberMessage,
      boxType,
    });
  };

  return {
    showSuccessModal,
  };
};
