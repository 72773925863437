import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, Pill, Text } from "@deliverr/ui";

import { FormattedMessage } from "react-intl";

export interface SkuPillProps {
  skuNumber: number;
  expectedSkus: number;
}

// font size is showing as 0.9331rem and letter spacing as 1px
const SmallFontPill = styled(Pill)`
  font-size: 12px;
  letter-spacing: inherit;
`;

const PillText = styled(Text)<Record<string, unknown>, DefaultTheme>`
  font-size: 12px;
`;

export const SkuPill: React.FC<SkuPillProps> = ({ skuNumber, expectedSkus }) => {
  const currentSku = Math.min(skuNumber, expectedSkus);

  return (
    <SmallFontPill color="GRAY" data-testid="SkuPill">
      <PillText appearance="INFO" bold>
        <FormattedMessage
          id="warehouse.receiving.skuPillMessage"
          defaultMessage="SKU {currentSku} OF {expectedSkus}"
          values={{ currentSku, expectedSkus }}
        />
      </PillText>
    </SmallFontPill>
  );
};
