import { Button, Grid, Text, Title } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import React from "react";
import { useIntl } from "react-intl";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { problemSolverFlowMessages } from "../../content";
import { useReportBoxIssue } from "./useReportBoxIssue";

export const ReportBoxIssue = () => {
  const { boxLabel, submittingIssue, confirmReportBoxIssue, onCancel } = useReportBoxIssue();
  const { formatMessage } = useIntl();

  return (
    <>
      <FacilityCard as="section">
        <Grid>
          <Title as="h2" displayAs="h4">
            {formatMessage(problemSolverFlowMessages.reportBoxIssueTitle)}
          </Title>
          <>
            <Text size="bodyLarge">{formatMessage(problemSolverFlowMessages.reportBoxIssueDescription)}:</Text>
            <Text bold>{boxLabel}</Text>

            <Text size="bodyLarge">{formatMessage(problemSolverFlowMessages.reportBoxIssueConfirmationMessage)}</Text>
            <Button block onClick={confirmReportBoxIssue} disabled={submittingIssue} loading={submittingIssue}>
              {formatMessage(commonReceivingMessages.confirm)}
            </Button>
            <Button block onClick={onCancel} secondary disabled={submittingIssue}>
              {formatMessage(commonReceivingMessages.cancel)}
            </Button>
          </>
        </Grid>
      </FacilityCard>
    </>
  );
};
