import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useRecoilValue } from "recoil";
import { composeUseDamagedProductForm } from "facility-commons/flow/forms/DamagedProductForm";
import { userState } from "facility-commons/base/Auth/userState";
import { useWarehouseDamagedProductSuccessModal } from "warehouse/ticket-center/common/hooks/useWarehouseDamagedProductSuccessModal";

export const useWarehouseDamagedProductForm = () => {
  const { warehouseId } = useRecoilValue(userState);
  const { nonComplianceClient, warehousePublicClient } = useClientsWithAuth();
  const { showSuccessModal } = useWarehouseDamagedProductSuccessModal();

  const onSubmitSuccess = (caseNumber: number) => {
    showSuccessModal(caseNumber, false);
  };

  const useDamagedProductForm = composeUseDamagedProductForm({
    warehouseId,
    warehousePublicClient,
    nonComplianceClient,
    onSubmitSuccess,
  });

  return {
    warehouseId,
    nonComplianceClient,
    warehousePublicClient,
    useDamagedProductForm,
  };
};
