import { useState } from "react";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";

import { userState } from "facility-commons/base/Auth/userState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { logStart, printPdf } from "facility-commons/utils";
import { useIntl } from "react-intl";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { STORAGE_ARRIVAL_SCAN_MESSAGES } from "warehouse/common/messages/storage";

export const usePOWorklistPrint = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const { warehouseId } = useRecoilValue(userState);
  const { storageClient } = useClientsWithAuth();

  const [poNumber, setPoNumber] = useState("");
  const [scanError, setScanError] = useState<string>("");

  const [submitState, handleSubmit] = useAsyncFn(async (poNumber) => {
    const handlePOWorklistSubmit = async () => {
      const ctx = { fn: "handlePOWorklistSubmit.poWorklist", poNum: poNumber };
      logStart(ctx);
      try {
        const response = await storageClient.getStoragePOWorklistLabel(poNumber, warehouseId);
        printPdf(new Blob([response], { type: "application/pdf" }));
      } catch (err: any) {
        if (err?.response) {
          const { data } = err.response;

          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: data?.message,
          });
        } else if (err?.error) {
          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: String(err.error?.message),
          });
        }
      }
    };

    return handlePOWorklistSubmit();
  });

  const handleSubmitClick = () => {
    setScanError("");
    handleSubmit(poNumber);
  };

  const handleChange = (value) => {
    setPoNumber(value);
  };

  return {
    scanError,
    handleChange,
    handleSubmit,
    handleSubmitClick,
    loading: submitState.loading,
    poNumber,
    setPoNumber,
  };
};
