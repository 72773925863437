import { defineMessages } from "react-intl";

export const STORAGE_RECEIVING_MESSAGES = defineMessages({
  poScanTitle: {
    id: "storage.receiving.poScan.boxTitle",
    defaultMessage: "Receive PO or Flex ID",
  },
  poScanDescription: {
    id: "storage.receiving.poScan.poScanDescription",
    defaultMessage: "Enter PO #, ASN, or FlexId to start receiving.",
  },
  poScanPlaceholder: {
    id: "storage.receiving.poScan.placeholder",
    defaultMessage: "Search for PO #, ASN, or FlexID",
  },
  arrivedTitle: {
    id: "storage.arrivalScan.success.arrivedTitle",
    defaultMessage: "Arrived",
  },
  expectedToArriveTitle: {
    id: "storage.arrivalScan.success.expectedToArriveTitle",
    defaultMessage: "Expected to arrive",
  },
  pendingArrivalTitle: {
    id: "storage.arrivalScan.success.pendingArrivalTitle",
    defaultMessage: "Pending Arrival",
  },
  arrivalUnits: {
    id: "storage.arrivalScan.success.arrivalUnits",
    defaultMessage: "{numberOfSkus} SKUs {numberOfBoxes, plural, =0 {} =1 {. # case} other {. # cases}} ",
  },
  receivedPalletCount: {
    id: "storage.receiving.poScan.receivedPalletCount",
    defaultMessage: "{receivingStartPalletCount, plural, =1 {# Pallet} other {# Pallets}} ",
  },
  requiredPalletCount: {
    id: "storage.receiving.poScan.requiredPalletCount",
    defaultMessage: "Pallet Count is required.",
  },
  checkIfContainerPalletized: {
    id: "storage.receiving.poScan.checkIfContainerPalletized",
    defaultMessage: "Is container palletized?",
  },
  poScanSuccessNotificationTitle: {
    id: "storage.receiving.poSuccess.title",
    defaultMessage: `PO Successfully Scanned`,
  },
  incorrectPO: {
    id: "storage.receiving.error.incorrectPO",
    defaultMessage: `Enter Correct PO#`,
  },
  flexIdTitle: {
    id: "storage.receiving.skuScan.flexIdTitle",
    defaultMessage: "{flexID} to {forwardingLocation} location",
  },
  poNumberDetailsTitle: {
    id: "storage.receiving.skuScan.poNumberDetailsTitle",
    defaultMessage: "PO# {poNum}",
  },
  poDetailsTitle: {
    id: "storage.receiving.skuScan.poDetailsTitle",
    defaultMessage: "{numOfSKU} SKUs",
  },
  poDetailsDescription: {
    id: "storage.receiving.skuScan.poDetailsDescription",
    defaultMessage: "Total SKUs: {totalSKUs}",
  },
  poDetailsReceivedDescription: {
    id: "storage.receiving.skuScan.poDetailsReceivedDescription",
    defaultMessage: "Received Units: {receivedUnits}",
  },
  poDetailsCurrentReceivingUnitsDescription: {
    id: "storage.receiving.skuScan.poDetailsCurrentReceivingUnitsDescription",
    defaultMessage: "Current Receiving Units: {currentReceivingUnits}",
  },
  receivingPalletCountTitle: {
    id: "storage.receiving.poScan.receivingPalletCountTitle",
    defaultMessage: "Enter Received Pallet Count",
  },
  receivingPalletCountPlaceholder: {
    id: "storage.receiving.poScan.receivingPalletCountPlaceholder",
    defaultMessage: "Pallet Count",
  },
  poDetailsPalletCount: {
    id: "storage.receiving.skuScan.poDetailsPalletCount",
    defaultMessage: "Received Pallets: {palletCount}",
  },
  skuScanTitle: {
    id: "storage.receiving.skuScan.boxTitle",
    defaultMessage: "Scan SKU Barcode",
  },
  lotInfoTitle: {
    id: "storage.receiving.lotInfo.boxTitle",
    defaultMessage: "Enter Lot Information",
  },
  skuScanDescription: {
    id: "storage.receiving.skuScan.description",
    defaultMessage: "Scan or enter a SKU barcode to start receiving a pallet",
  },
  skuScanPlaceholder: {
    id: "storage.receiving.skuScan.placeholder",
    defaultMessage: "SKU",
  },
  skuScanLPTitle: {
    id: "storage.receiving.skuScanLP.boxTitle",
    defaultMessage: "Scan LPN Barcode",
  },
  skuScanLPDescription: {
    id: "storage.receiving.skuScanLP.description",
    defaultMessage: "Scan or enter LPN of pallet",
  },
  skuScanLPPlaceholder: {
    id: "storage.receiving.skuScanLP.placeholder",
    defaultMessage: "LPN",
  },
  skuScanLPSuccess: {
    id: "storage.receiving.skuScanLP.success",
    defaultMessage: "LP Scanned",
  },
  skuScanLCTitle: {
    id: "storage.receiving.skuScanLC.boxTitle",
    defaultMessage: "Scan Location Barcode",
  },
  skuScanLCDescription: {
    id: "storage.receiving.skuScanLC.description",
    defaultMessage: "Scan Location of Pallet",
  },
  skuScanLCPlaceholder: {
    id: "storage.receiving.skuScanLC.placeholder",
    defaultMessage: "Location Code",
  },
  skuScanSuccessNotificationTitle: {
    id: "storage.receiving.skuScan.success.title",
    defaultMessage: `SKU details captured`,
  },
  skuQuantityTitle: {
    id: "storage.receiving.skuQuantity.title",
    defaultMessage: `Enter total boxes on pallet`,
  },
  skuQuantityDescription: {
    id: "storage.receiving.skuQuantity.description",
    defaultMessage: `Enter total boxes on pallet`,
  },
  skuQuantityInputUnitPlaceholder: {
    id: "storage.receiving.skuQuantity.unitPlaceholder",
    defaultMessage: `Units per box`,
  },
  skuQuantityInputBoxesPlaceholder: {
    id: "storage.receiving.skuQuantity.boxesPlaceholder",
    defaultMessage: `Boxes`,
  },
  successNotificationTitle: {
    id: "storage.receiving.palletSuccess.title",
    defaultMessage: `Pallet Received`,
  },
  scanErrorUnrecognisedTitle: {
    id: "storage.receiving.error.unrecognisedTitle",
    defaultMessage: "{type} not recognized",
  },
  contactSupportDescription: {
    id: "storage.receiving.error.contactSupportDescription",
    defaultMessage: "If the error persists, contact support.",
  },
  scanErrorUnrecognisedDescription: {
    id: "storage.receiving.error.unrecognisedDescription",
    defaultMessage: "Please try again.",
  },
  skuReceivedSuccessNotificationTitle: {
    id: "storage.receiving.skuSuccess.title",
    defaultMessage: `SKU Received`,
  },
  poSKUsScannedTitle: {
    id: "storage.receiving.success.poSKUsScanned.title",
    defaultMessage: `All SKUs Scanned`,
  },

  lpnContentLabelTitle: {
    id: "storage.receiving.lpnContentLabel.title",
    defaultMessage: `Print Content Label?`,
  },

  confirmationModalTitle: {
    id: "storage.receiving.confirmationModal.title",
    defaultMessage: `Are you sure?`,
  },

  confirmationModalText: {
    id: "storage.receiving.confirmationModal.text",
    defaultMessage: `Are you sure you want to perform this action?`,
  },

  lpnContentLabelText: {
    id: "storage.receiving.lpnContentLabel.text",
    defaultMessage: `Print for LP: {lp} Location: {location} PO: {po}`,
  },

  lpnContentLabelPrint: {
    id: "storage.receiving.lpnContentLabel.print",
    defaultMessage: `Print label`,
  },
  poSKUsScannedDescription: {
    id: "storage.receiving.success.poSKUsScanned.description",
    defaultMessage: `Would you like to mark this PO as completed?`,
  },
  markAsCompleted: {
    id: "storage.receiving.button.markAsCompleted",
    defaultMessage: "Mark PO as Completed",
  },
  markAsCompletedTitle: {
    id: "storage.receiving.button.markAsCompletedTitle",
    defaultMessage: "All units received",
  },
  markAsCompletedText: {
    id: "storage.receiving.button.markAsCompletedText",
    defaultMessage: "All expected units from this PO have been received.",
  },
  markPOCompletedWarningTitle: {
    id: "storage.receiving.button.markCompletedWarningTitle",
    defaultMessage: "Some units have not been received yet",
  },
  markPOCompletedWarningText: {
    id: "storage.receiving.button.markCompletedWarningText",
    defaultMessage: "{units} units are still expected.",
  },
  poCompletionEventReceived: {
    id: "storage.receiving.success.poCompletionEventReceived",
    defaultMessage: "PO Completion Event Received",
  },
  poCompletionEventFailed: {
    id: "storage.receiving.success.poCompletionEventFailed",
    defaultMessage: "PO Completion Failed. Try again.",
  },
  confirmSKUDetails: {
    id: "storage.receiving.confirm.skuDetails",
    defaultMessage: "Review Details",
  },
  continueEditingButton: {
    id: "storage.receiving.button.continueEditing",
    defaultMessage: "Edit",
  },
  unitAndBoxQuantitiesTitle: {
    id: "storage.receiving.skuAndBoxQuantityTitle",
    defaultMessage: "Count total boxes and units",
  },
  skuConfirmReceiveTitle: {
    id: "storage.receiving.confirm.title",
    defaultMessage: "Review and Confirm",
  },
  skuConfirmReceiveBoxes: {
    id: "storage.receiving.confirm.boxes",
    defaultMessage:
      "{boxesQty, plural, one {# box} other {# boxes}} of {caseQty, plural, one {# unit} other {# units}} per box",
  },
  damagedOrExpiredSKU: {
    id: "storage.receiving.nonCompliance.damagedOrExpired",
    defaultMessage: "Received damaged or expired products?",
  },
  createTicket: {
    id: "storage.receiving.nonCompliance.createTicket",
    defaultMessage: "Create a Ticket",
  },
  skuConfirmUnitsLabel: {
    id: "storage.receiving.confirm.unitsLabel",
    defaultMessage: "Units",
  },
  skuConfirmBoxesLabel: {
    id: "storage.receiving.confirm.boxesLabel",
    defaultMessage: "Boxes",
  },
  skuConfirmLPBarcodeLabel: {
    id: "storage.receiving.confirm.LPBarcodeLabel",
    defaultMessage: "LP Barcode",
  },
  skuConfirmLocationLabel: {
    id: "storage.receiving.confirm.LocationLabel",
    defaultMessage: "Receive Location",
  },
  caseDimsAndWeightTitle: {
    id: "storage.receiving.caseDimsAndWeightTitle",
    defaultMessage: "Enter case weight and dimensions",
  },
  caseDimsAndWeightLabel: {
    id: "storage.receiving.caseDimsAndWeightLabel",
    defaultMessage: "Look for this info displayed on the box.",
  },
  caseWeightInputUnitPlaceholder: {
    id: "storage.receiving.caseWeightInputUnitPlaceholder",
    defaultMessage: "Weight (lbs)",
  },
  caseDimensionsTitle: {
    id: "storage.receiving.caseDimensionsTitle",
    defaultMessage: "Dimensions (in)",
  },
  caseLengthInputUnitPlaceholder: {
    id: "storage.receiving.inputUnitPlaceholder.length",
    defaultMessage: "L",
  },
  caseHeightInputUnitPlaceholder: {
    id: "storage.receiving.inputUnitPlaceholder.height",
    defaultMessage: "H",
  },
  caseWidthInputUnitPlaceholder: {
    id: "storage.receiving.inputUnitPlaceholder.width",
    defaultMessage: "W",
  },
  asnNotFound: {
    id: "storage.receiving.submitError.asnNotFound",
    defaultMessage: "This PO # does not exist within our records. Please contact Deliverr.",
  },
  barcodeNotFound: {
    id: "storage.receiving.submitError.barcodeNotFound",
    defaultMessage: "{code} was not found in the WMS. Try again or create a non-compliance ticket.",
  },
  barcodeNotInASN: {
    id: "storage.receiving.submitError.barcodeNotInASN",
    defaultMessage: "{code} is not expected for this PO. Please create a non-compliance ticket.",
  },
  unusableLocation: {
    id: "storage.receiving.submitError.unusableLocation",
    defaultMessage: "{location} is not configured for use. Configure this location or use a valid location.",
  },
  missingLocation: {
    id: "storage.receiving.submitError.missingLocation",
    defaultMessage: "A valid location must be provided.",
  },
  incorrectQuantity: {
    id: "storage.receiving.submitError.incorrectQuantity",
    defaultMessage: "Quantity must be greater than 0.",
  },
  unexpctedCaseQuantity: {
    id: "storage.receiving.warning.unexpectedCaseQuantity",
    defaultMessage: "Unexpected. Make sure this is correct.",
  },
  productCasePackCreationFailed: {
    id: "storage.receiving.submitError.productCasePackFailed",
    defaultMessage: "Failed to create new Product Case Pack",
  },
  unrecognisedDescriptionText: {
    id: "storage.receiving.submitError.unrecognisedDescriptionText",
    defaultMessage: "{error}",
  },
  unexpectedSkuConfirmationModalText: {
    id: "storage.receiving.unexpectedSku.confirmationModal.text",
    defaultMessage:
      "<bold>SKU {sku}</bold> does belong to the merchant, but was not expected in <bold>PO {poNum}</bold>. Do you want to proceed?",
  },
  unexpectedSkuConfirmationModalTitle: {
    id: "storage.receiving.unexpectedSku.confirmationModal.title",
    defaultMessage: "Confirm unexpected SKU",
  },
  poStatusNotArrivedError: {
    id: "storage.receiving.error.poStatusNotArrived",
    defaultMessage: "PO Status Update Error",
  },
  poNotMarkedArrivedText: {
    id: "storage.receiving.error.poNotMarkedArrivedText",
    defaultMessage: "Receiving cannot be started until PO is marked as Arrived.",
  },
  poSelectionStepReceiveSku: {
    id: "storage.receiving.poSelectionStepReceiveSku",
    defaultMessage: "Start Receiving",
  },
  reportProblem: {
    id: "storage.receiving.reportProblem",
    defaultMessage: "Report a problem",
  },
  caseConfigSelectionTitle: {
    id: "storage.receiving.caseConfigSelection.title",
    defaultMessage: "Select Case Configuration",
  },
  incorrectDSKUScanned: {
    id: "storage.receiving.caseConfigSelection.error.incorrectDSKUScanned",
    defaultMessage: "Incorrect child DSKU entered. If the child DSKU is correct and error persists, contact support.",
  },
  scanAnotherSKU: {
    id: "storage.receiving.review.button.scanAnotherSKU",
    defaultMessage: "Scan Another SKU",
  },
  fbaInvalidForwardingLocationWarningModalTitle: {
    id: "storage.receiving.fbaInvalidForwardingLocationWarningModal.title",
    defaultMessage: "Invalid Forwarding Location",
  },
  fbaInvalidForwardingLocationWarningModalText: {
    id: "storage.receiving.fbaInvalidForwardingLocationWarningModal.text",
    defaultMessage:
      "This receive must be received at a forwarding location beginning with {forwardingStagingLocationPrefix}",
  },
  invalidPrepLocationWarningModalTitle: {
    id: "storage.receiving.invalidPrepLocationWarningModal.title",
    defaultMessage: "Invalid Prep Location",
  },
  invalidPrepLocationWarningModalText: {
    id: "storage.receiving.invalidPrepLocationWarningModal.text",
    defaultMessage: "This receive must be received at a prep location beginning with {prepLocationPrefix}.",
  },
});

export const STORAGE_LOT_INFO_MESSAGE = defineMessages({
  lotTitle: {
    id: "storage.receiving.scanLotNumber",
    defaultMessage: "Scan lot number",
  },
  lotInstructions: {
    id: "storage.receiving.lotInstruction",
    defaultMessage: "Scan or enter the lot number on any box.",
  },
  missingLotEntry: {
    id: "storage.receiving.lotInstructions",
    defaultMessage: "Missing lot number.",
  },
  invalidLotInput: {
    id: "storage.receiving.invalidLotInput",
    defaultMessage: "Lot value can only include numbers and letters.",
  },
  lotNumber: {
    id: "storage.receiving.lotNumber",
    defaultMessage: "Lot Number",
  },
  expirationDate: { id: "storage.receiving.expirationDate", defaultMessage: "Expiration Date" },
  missingExpirationDate: {
    id: "storage.receiving.missingExpirationDate",
    defaultMessage: "Missing expiration date.",
  },
  incorrectExpirationDateFormat: {
    id: "storage.receiving.incorrectExpirationDateFormat",
    defaultMessage: "Expiration date must follow the format of MM/DD/YYYY",
  },
  invalidMonth: {
    id: "storage.receiving.invalidMonth",
    defaultMessage: "The month must be between 01 and 12.",
  },
  invalidDay: {
    id: "storage.receiving.invalidDay",
    defaultMessage: "The day must be between 01 and {lastDateOfMonth} for the provided month and year.",
  },
  expirationDateInputHelpText: {
    id: "storage.receiving.expirationDateInputHelpText",
    defaultMessage: "MM/DD/YYYY. Use 01 for the day if the expiration date does not have a day value.",
  },
  exceededMaxCharacterCount: {
    id: "storage.receiving.exceedMaxCharacterCount",
    defaultMessage: "Input must be {characterLimit} or less characters.",
  },
  expiredProductsReceived: {
    id: "storage.receiving.expiredProductsReceived",
    defaultMessage: "Receiving expired products.",
  },
});
