/**
 * Goal here is to enable the Enterprise Browser Barcode API exactly once.
 * Previously, we were disabling and enabling every time a new text input card was created.
 * This resulted in the occasional failure for the Barcode API to be enabled,
 * resulting in the user pressing the scan button, but no laser coming out.
 */

export type OnZebraScan = (scanValue: string) => any;

let onZebraScan: OnZebraScan = () => {};
let scanIsProcessing = false;

async function onZebraScannerEnable(params: any) {
  // prevent double scans
  if (!scanIsProcessing) {
    try {
      scanIsProcessing = true;
      await onZebraScan(params.data);
    } finally {
      scanIsProcessing = false;
    }
  }
}

// restrict scannable formats and length to reduce scan time.
const barcodeProps = {
  code128: true,
  code128minLength: 5,
  code128maxLength: 40,
  qrCode: true,
};

export function enableZebraScanner() {
  window.EB.Barcode.enable(barcodeProps, onZebraScannerEnable);
}

export function updateOnZebraScan(newOnZebraScan: OnZebraScan) {
  onZebraScan = newOnZebraScan;
}
