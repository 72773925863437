import { defineMessages } from "react-intl";

export const FACILITY_COMMONS_BUTTON_LABELS = defineMessages({
  continueButton: {
    id: "facilityCommons.button.continue",
    defaultMessage: "Continue",
  },
  cancelButton: {
    id: "facilityCommons.button.cancel",
    defaultMessage: "Cancel",
  },
  doneButton: {
    id: "facilityCommons.button.done",
    defaultMessage: "Done",
  },
  doneScanningButton: {
    id: "facilityCommons.button.doneScanning",
    defaultMessage: "Done scanning",
  },
  updateButton: {
    id: "facilityCommons.button.update",
    defaultMessage: "Update",
  },
  understandButton: {
    id: "facilityCommons.button.understand",
    defaultMessage: "I understand",
  },
  yesButton: {
    id: "facilityCommons.button.yes",
    defaultMessage: "Yes",
  },
  noButton: {
    id: "facilityCommons.button.no",
    defaultMessage: "No",
  },
  reprintButton: {
    id: "facilityCommons.button.reprint",
    defaultMessage: "Reprint",
  },
  backButton: {
    id: "facilityCommons.button.back",
    defaultMessage: "Back",
  },
  exitButton: {
    id: "facilityCommons.button.exit",
    defaultMessage: "Exit",
  },
  confirmButton: {
    id: "facilityCommons.button.confirm",
    defaultMessage: "Confirm",
  },
  submitButton: {
    id: "facilityCommons.button.submit",
    defaultMessage: "Submit",
  },
});
