import { defineMessages } from "react-intl";

export const CONNECTED_PRINTER_CARD_LABELS = defineMessages({
  printerLabel: {
    id: "facilityCommons.components.card.connectedPrinterCard.printerLabel",
    defaultMessage: "Printer",
  },
  none: {
    id: "facilityCommons.components.card.connectedPrinterCard.none",
    defaultMessage: "None",
  },
});
