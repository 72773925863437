import { last } from "lodash";
import { ReadOnlySelectorFamilyOptions, ReadOnlySelectorOptions, RecoilState, atom } from "recoil";
import {
  FlowCardsState,
  FlowButtonsState,
  FlowTimerState,
  FlowNotificationsState,
  FlowFlashState,
} from "./types/FlowState";
import { FlowStateKey } from "./FlowStateKey";

export const getSameActiveFlowCardForState = <T extends string = string>(
  cardsState: RecoilState<FlowCardsState>
): ReadOnlySelectorFamilyOptions<boolean, T>["get"] => (type: T) => {
  return ({ get }) => {
    const cards = get(cardsState);
    const lastCard = last(cards);
    return lastCard ? lastCard.type === type : false;
  };
};

export const getFlowVisibleButtonsForState = <T extends string = string>(
  buttonsState: RecoilState<FlowButtonsState>
): ReadOnlySelectorOptions<T[]>["get"] => ({ get }) =>
  Object.entries(get(buttonsState))
    .filter(([_, visible]) => visible)
    .map(([buttonType]) => buttonType as T);

export const timerState = atom<FlowTimerState>({
  key: FlowStateKey.FLOW_TIMER,
  default: {
    visible: false,
    seconds: 0,
  },
});

export const notificationsState = atom<FlowNotificationsState>({
  key: FlowStateKey.FLOW_NOTIFICATIONS,
  default: [],
});

export const flashState = atom<FlowFlashState>({
  key: FlowStateKey.FLOW_FLASH,
  default: {
    type: undefined,
    icon: undefined,
    text: undefined,
  },
});
