import React from "react";
import { useIntl } from "react-intl";
import { ModalTemplate, infoModalIcon } from "facility-commons";
import { Button } from "@deliverr/ui";
import { modalsText } from "../../receiving/components/modals/modalsText";
import { BoxArrivalScanEntity } from "@deliverr/legacy-inbound-client";
import { SoundFx } from "facility-commons/common/sfx";

interface SortBoxToRackModalProps {
  label: string;
  packageType: Omit<BoxArrivalScanEntity, "PALLET_LABEL" | "ASN_LABEL" | "UNKNOWN">;
  onConfirm: () => void;
}

export const SortBoxToRackModal: React.FC<SortBoxToRackModalProps> = ({ onConfirm, packageType, label }) => {
  const { formatMessage } = useIntl();

  let title: string;
  let message: string;

  switch (packageType) {
    case BoxArrivalScanEntity.CDSKU:
    case BoxArrivalScanEntity.INBOUND_PACKAGE_TRACKING_CODE:
      title = formatMessage(modalsText.boxSortReceiveTitle);
      message = formatMessage(modalsText.boxSortReceiveMessage, { label });
      break;
    case BoxArrivalScanEntity.RETURNS_PACKAGE_TRACKING_CODE:
      title = formatMessage(modalsText.boxSortReturnsTitle);
      message = formatMessage(modalsText.boxSortReturnsMessage, { label });
      break;
    case BoxArrivalScanEntity.UNDELIVERABLE_PACKAGE_TRACKING_CODE:
      title = formatMessage(modalsText.boxSortUndeliveredTitle);
      message = formatMessage(modalsText.boxSortUndeliveredMessage, { label });
      break;
  }

  return (
    <ModalTemplate buttonLayout="row" title={title!} text={message!} iconProps={infoModalIcon} soundFx={SoundFx.INFO}>
      <Button block data-testid="box-sort-confirm-button" onClick={onConfirm}>
        {formatMessage(modalsText.confirm)}
      </Button>
    </ModalTemplate>
  );
};
