import { defineMessages } from "react-intl";

export const barcodeMessages = defineMessages({
  instructions: {
    id: "warehouse.nonCompliance.openBoxInstructions",
    defaultMessage: "Open the box and scan the barcode on the non-compliant product.",
  },
  missing: {
    id: "warehouse.nonCompliance.missingBarcode",
    defaultMessage: "Can't find the barcode on the SKU?",
  },
});
