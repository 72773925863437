import { useRecoilValue, useSetRecoilState } from "recoil";
import { useMount } from "react-use";
import { useIntl } from "react-intl";
import { setProp } from "facility-commons";
import { palletAnalyticsV2State } from "../../ReceivingState/PalletSummaryV2State";
import { BoxConfigSummary } from "warehouse/receiving/ReceivingState/Types";
import { palletAnalyticsV2Messages } from "./PalletAnalyticsV2Messages";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { ExpectedVsReceivedSkuQtyByBoxLabel, ExpectedVsReceivedSkuQtyBySku } from "@deliverr/legacy-inbound-client";

export interface GroupedAndFormattedBoxData {
  groupByLabel: string;
  groupDetails: string;
  receives: { label: string; receivedQty: number; expectedQty: number }[];
}

export const usePalletAnalyticsV2BoxSummary = (boxConfig: BoxConfigSummary) => {
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { palletLabel, data } = useRecoilValue(palletAnalyticsV2State);
  const { singleSkuBoxReceives, multiSkuBoxReceives, bulkBoxReceives } = data;

  useMount(() => {
    let pageTitle = "";
    switch (boxConfig) {
      case BoxConfigSummary.SINGLE_SKU:
        pageTitle = formatMessage(palletAnalyticsV2Messages.singleSKUBoxes);
        break;
      case BoxConfigSummary.MULTI_SKU:
        pageTitle = formatMessage(palletAnalyticsV2Messages.multiSKUBoxes);
        break;
      case BoxConfigSummary.BULK:
        pageTitle = formatMessage(palletAnalyticsV2Messages.bulkBoxes);
        break;
    }

    setWarehouseAppState(setProp("pageTitle", pageTitle));
    setWarehouseAppState(setProp("pageSubtitle", palletLabel));
  });

  const { formatMessage } = useIntl();

  const formatData = (): GroupedAndFormattedBoxData[] => {
    let receivedData: ExpectedVsReceivedSkuQtyByBoxLabel | ExpectedVsReceivedSkuQtyBySku;
    switch (boxConfig) {
      case BoxConfigSummary.SINGLE_SKU:
        receivedData = singleSkuBoxReceives;
        break;
      case BoxConfigSummary.MULTI_SKU:
        receivedData = multiSkuBoxReceives;
        break;
      case BoxConfigSummary.BULK:
        receivedData = bulkBoxReceives;
        break;
    }
    return Object.entries(receivedData).map(([groupByLabel, receivedDetails]) => {
      const receives = Object.entries(receivedDetails).map(([label, expectedAndReceivedQtys]) => {
        return {
          label,
          receivedQty: expectedAndReceivedQtys.receivedQty,
          expectedQty: expectedAndReceivedQtys.expectedQty,
        };
      });
      return {
        groupByLabel,
        receives,
        groupDetails:
          boxConfig === BoxConfigSummary.BULK
            ? formatMessage(palletAnalyticsV2Messages.numBoxAndUnitsPerBox, {
                numBoxes: receives.length,
                unitsPerBox: receives[0].expectedQty,
              })
            : formatMessage(palletAnalyticsV2Messages.SKUs, { numSkus: receives.length }),
      };
    });
  };

  const receivedVsExpectedHeader = formatMessage(palletAnalyticsV2Messages.receivedVsExpected);
  const cdskuHeader = formatMessage(palletAnalyticsV2Messages.CDSKU);
  const dskuHeader = formatMessage(palletAnalyticsV2Messages.DSKU);

  return {
    boxSummaries: formatData(),
    receivedVsExpectedHeader,
    cdskuHeader,
    dskuHeader,
  };
};
