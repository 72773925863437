import React from "react";

import { Flow } from "facility-commons/flow";
import { warehouseFlowCardsState } from "./warehouseFlowState";
import { warehouseFlowCardMap } from "./maps/warehouseFlowCardMap";
import { warehouseFlowButtonMap } from "./maps/warehouseFlowButtonMap";

export const WarehouseFlow: React.FC = () => {
  return (
    <Flow
      cardsState={warehouseFlowCardsState}
      cardMap={warehouseFlowCardMap}
      visibleButtons={[]} // useRecoilValue(flowVisibleButtonsValue)
      buttonMap={warehouseFlowButtonMap}
    />
  );
};
