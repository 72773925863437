import { atom } from "recoil";
import { FlowCardsState } from "facility-commons/flow/types/FlowState";
import { ReturnReceiveStateKey } from "./ReturnReceiveStateKey";
import { FlowButtonType, FlowButtonsState } from "facility-commons/flow";

export const returnReceiveFlowCardsState = atom<FlowCardsState>({
  key: ReturnReceiveStateKey.FLOW_CARDS,
  default: [],
});

export const returnReceiveFlowButtonsState = atom<FlowButtonsState<FlowButtonType>>({
  key: ReturnReceiveStateKey.FLOW_BUTTONS,
  default: {} as FlowButtonsState<FlowButtonType>,
});
