import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { NCRoutes } from "warehouse/ticket-center/new/non-compliance/base/routes";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { useRouter } from "facility-commons/hooks";

export const useLotExpiryUpload = () => {
  const formHandler = useFormContext();
  const currentProduct = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();

  const proceed = () => push(NCRoutes.PRODUCT_UPLOAD);
  const onFileChange = (fileName: string) => updateCurrentProduct("lotExpiryPicture", fileName);

  return {
    formatMessage,
    imageUploaded: !!currentProduct.lotExpiryPicture,
    onFileChange,
    proceed,
    register: formHandler.register("lotExpiryPicture"),
  };
};
