import { defineMessages } from "react-intl";

export const printLabelCardLabels = defineMessages({
  printingText: {
    id: "warehouse.rs-outbounds.printingText",
    defaultMessage: "Printing labels",
  },
  applyLabelText: {
    id: "warehouse.rs-outbounds.applyLabelText",
    defaultMessage: "Apply label to package",
  },
  doneText: {
    id: "warehouse.rs-outbounds.doneText",
    defaultMessage: "Done",
  },
  reprintLabelText: {
    id: "warehouse.rs-outbounds.reprintLabelText",
    defaultMessage: "Reprint label",
  },
});
