import { CharLimitTextArea, CharLimitTextAreaProps, useCharLimitValidator } from "facility-commons/components";
import React from "react";
import { NcCardStack, NcCardStackProps } from "../../new/non-compliance/components";

type TextAreaPageProps = NcCardStackProps & CharLimitTextAreaProps;

export const TextAreaPage: React.FC<TextAreaPageProps> = ({
  continueDisabled,
  continueOnClick,
  continueLabel,
  sibling,
  title,
  placeholder,
  textAreaValue,
  onChange,
  charLimit,
  isLoading,
}) => {
  const { getIsInputInvalid } = useCharLimitValidator({ charLimit });

  return (
    <NcCardStack
      continueDisabled={continueDisabled || getIsInputInvalid(textAreaValue)}
      continueOnClick={continueOnClick}
      continueLabel={continueLabel}
      sibling={sibling}
      title={title}
      isLoading={isLoading}
    >
      <CharLimitTextArea
        charLimit={charLimit}
        onChange={onChange}
        placeholder={placeholder}
        textAreaValue={textAreaValue}
      />
    </NcCardStack>
  );
};
