import React from "react";
import { IconV2 } from "@deliverr/ui";

import { BaseMenuLinkProps } from "./MenuLink";
import { ExternalLink } from "../ExternalLink";
import { StyledMenuLink } from "./StyledMenuLink";

export interface ExternalMenuLinkProps<LinkNames extends string = string> extends BaseMenuLinkProps<LinkNames> {
  url: string;
}

const StyledExternalLinkButton = StyledMenuLink.withComponent(ExternalLink);

export const ExternalMenuLink = ({ link }) => (
  <StyledExternalLinkButton href={link.url} target="_blank" rel="noopener noreferrer">
    {link.text}
    <IconV2 type="external-link" />
  </StyledExternalLinkButton>
);
