import React from "react";

import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { barcodeMessages } from "../../content/barcode";
import { useStorageBarcodeCard } from "./useStorageBarcodeCard";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { skuMessages } from "warehouse/receiving/content/sku";
import { SkipStep } from "../../components";
import { STORAGE_MESSAGES } from "warehouse/common/messages/storage";

export const StorageBarcodeCard: React.FC = () => {
  const {
    barcode,
    barcodeError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  } = useStorageBarcodeCard();

  return (
    <StackedInputCard
      errorMessage={barcodeError}
      illustration={<OpenBox />}
      loading={loading}
      message={formatMessage(STORAGE_MESSAGES.storageInstructions)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(skuMessages.barcodePlaceholder)}
      secondary
      title={formatMessage(skuMessages.scanBarcodeTitle)}
      value={barcode}
    >
      <SkipStep message={formatMessage(barcodeMessages.missing)} onSkip={proceedToFollowUp} />
    </StackedInputCard>
  );
};
