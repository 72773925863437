import { Button } from "@deliverr/ui";
import React from "react";
import { FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { LoadingSpinner, TooltipWrapper } from "facility-commons/components";
import { maxASNLength, maxBarcodeLength, maxCDSKULength } from "facility-commons/utils/config";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormInput,
  WarehouseFormSubTitle,
  WarehouseFormTooltip,
  WarehouseFormLabel,
  WarehouseFormInputGroup,
  WarehouseFormPhotoGridTitle,
  WarehouseFormText,
  WarehouseFormOtherDetails,
} from "facility-commons/components/emotion";
import { BarcodeNotInWMSField } from "./BarcodeNotInWMSForm.types";
import { useBarcodeNotInWMSForm } from "./useBarcodeNotInWMSForm";
import { useIntl } from "react-intl";
import { ticketFormContent } from "../../ticketFormContent";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";

export const BarcodeNotInWMSForm: React.FC = () => {
  const {
    preScannedCdsku,
    asnTypeState,
    errors,
    formHandler,
    onFormSubmit,
    preventSubmit,
    register,
    showCDSKU,
    warehouseEmail,
  } = useBarcodeNotInWMSForm();

  const { formatMessage } = useIntl();

  return (
    <FormProvider {...formHandler}>
      <FacilityCard>
        <WarehouseForm onSubmit={onFormSubmit}>
          <WarehouseFormSubTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.subtitle)}
          </WarehouseFormSubTitle>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={BarcodeNotInWMSField.BARCODE}>
              {formatMessage(ticketFormContent.barcodeRequired)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[BarcodeNotInWMSField.BARCODE]}
              id={BarcodeNotInWMSField.BARCODE}
              maxLength={maxBarcodeLength}
              placeholder={formatMessage(ticketFormContent.barcodePlaceholder)}
              hasError={!!errors[BarcodeNotInWMSField.BARCODE]}
              errorMessage={<ErrorMessage errors={errors} name={BarcodeNotInWMSField.BARCODE} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={BarcodeNotInWMSField.ASN}>
              {formatMessage(ticketFormContent.poRequired)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[BarcodeNotInWMSField.ASN]}
              id={BarcodeNotInWMSField.ASN}
              maxLength={maxASNLength}
              numbersOnly
              placeholder={formatMessage(ticketFormContent.poPlaceholder)}
              hasError={!!errors[BarcodeNotInWMSField.ASN]}
              errorMessage={<ErrorMessage errors={errors} name={BarcodeNotInWMSField.ASN} />}
            />
          </WarehouseFormInputGroup>

          {showCDSKU && (
            <WarehouseFormInputGroup>
              <WarehouseFormLabel appearance="INFO" htmlFor={BarcodeNotInWMSField.CDSKU}>
                {formatMessage(ticketFormContent.cdsku)}
              </WarehouseFormLabel>
              <WarehouseFormInput
                {...(register[BarcodeNotInWMSField.CDSKU] || {})}
                id={BarcodeNotInWMSField.CDSKU}
                placeholder={formatMessage(ticketFormContent.cdskuPlaceholder)}
                maxLength={maxCDSKULength}
                hasError={!!errors[BarcodeNotInWMSField.CDSKU]}
                errorMessage={<ErrorMessage errors={errors} name={BarcodeNotInWMSField.CDSKU} />}
                readOnly={!!preScannedCdsku}
              />
            </WarehouseFormInputGroup>
          )}

          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={BarcodeNotInWMSField.QUANTITY}>
              {formatMessage(ticketFormContent.nonCompliantQuantityRequired)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[BarcodeNotInWMSField.QUANTITY]}
              id={BarcodeNotInWMSField.QUANTITY}
              numbersOnly
              placeholder={formatMessage(ticketFormContent.quantityPlaceholder)}
              hasError={!!errors[BarcodeNotInWMSField.QUANTITY]}
              errorMessage={<ErrorMessage errors={errors} name={BarcodeNotInWMSField.QUANTITY} />}
            />
          </WarehouseFormInputGroup>

          <WarehouseFormPhotoGridTitle as="h3" displayAs="h4">
            {formatMessage(ticketFormContent.photosSubtitle)}
          </WarehouseFormPhotoGridTitle>

          <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessage)}</WarehouseFormText>

          <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[BarcodeNotInWMSField.FRONT_PHOTO]}
                id={BarcodeNotInWMSField.FRONT_PHOTO}
                labelText={formatMessage(ticketFormContent.frontPhotoRequired)}
              />
              <WarehouseFormTooltip active={Boolean(errors[BarcodeNotInWMSField.FRONT_PHOTO])}>
                <ErrorMessage errors={errors} name={BarcodeNotInWMSField.FRONT_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[BarcodeNotInWMSField.BACK_PHOTO]}
                id={BarcodeNotInWMSField.BACK_PHOTO}
                labelText={formatMessage(ticketFormContent.backPhotoRequired)}
              />
              <WarehouseFormTooltip active={Boolean(errors[BarcodeNotInWMSField.BACK_PHOTO])}>
                <ErrorMessage errors={errors} name={BarcodeNotInWMSField.BACK_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[BarcodeNotInWMSField.BARCODE_PHOTO]}
                id={BarcodeNotInWMSField.BARCODE_PHOTO}
                labelText={formatMessage(ticketFormContent.barcodePhoto)}
              />
              <WarehouseFormTooltip active={Boolean(errors[BarcodeNotInWMSField.BARCODE_PHOTO])}>
                <ErrorMessage errors={errors} name={BarcodeNotInWMSField.BARCODE_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>

            <TooltipWrapper>
              <NonComplianceUploadItem
                {...register[BarcodeNotInWMSField.BOXLABEL_PHOTO]}
                id={BarcodeNotInWMSField.BOXLABEL_PHOTO}
                labelText={formatMessage(ticketFormContent.boxContentPhoto)}
              />
              <WarehouseFormTooltip active={Boolean(errors[BarcodeNotInWMSField.BOXLABEL_PHOTO])}>
                <ErrorMessage errors={errors} name={BarcodeNotInWMSField.BOXLABEL_PHOTO} />
              </WarehouseFormTooltip>
            </TooltipWrapper>
          </WarehouseFormImageGrid>

          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={BarcodeNotInWMSField.EMAIL}>
              {formatMessage(ticketFormContent.email)}
            </WarehouseFormLabel>
            <WarehouseFormInput
              {...register[BarcodeNotInWMSField.EMAIL]}
              id={BarcodeNotInWMSField.EMAIL}
              helpText={formatMessage(ticketFormContent.emailMessage, { warehouseEmail })}
              hasError={!!errors[BarcodeNotInWMSField.EMAIL]}
              errorMessage={<ErrorMessage errors={errors} name={BarcodeNotInWMSField.EMAIL} />}
            />
          </WarehouseFormOtherDetails>

          <Button block type="submit" disabled={asnTypeState.loading || preventSubmit}>
            <LoadingSpinner loading={preventSubmit} size="lg">
              {formatMessage(ticketFormContent.submit)}
            </LoadingSpinner>
          </Button>
        </WarehouseForm>
      </FacilityCard>
    </FormProvider>
  );
};
