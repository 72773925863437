import { defineMessages } from "react-intl";

export const OTHER_TICKET_OVERVIEW_CARD_LABELS = defineMessages({
  poTracking: {
    id: "warehouse.ticketCenter.ticketDetails.other.overview.poTracking",
    defaultMessage: "PO / Tracking #",
  },
  unknownNumber: {
    id: "warehouse.ticketCenter.ticketDetails.other.overview.unknownNumber",
    defaultMessage: "-",
  },
});
