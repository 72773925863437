import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { PalletAnalyticsV2State } from "./Types";

export const INITIAL_PALLET_ANALYTICS_V2_DATA: PalletAnalyticsV2State = {
  palletLabel: "",
  boxConfigSummarySelected: undefined,
  data: {
    numBoxesReceived: 0,
    expectedNumBoxes: 0,
    areSingleSkuBoxesFullyReceived: true,
    areMultiSkuBoxesFullyReceived: true,
    areBulkBoxesFullyReceived: true,
    overReceivedUnitsOnSingleSkuBoxes: false,
    overReceivedUnitsOnMultiSkuBoxes: false,
    overReceivedUnitsOnBulkBoxes: false,
    singleSkuBoxReceives: {},
    multiSkuBoxReceives: {},
    bulkBoxReceives: {},
  },
};
export const palletAnalyticsV2State = atom<PalletAnalyticsV2State>({
  key: WarehouseStateKey.PALLET_ANALYTICS_V2,
  default: INITIAL_PALLET_ANALYTICS_V2_DATA,
});
