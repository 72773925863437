import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { createDangerNotification, logger, logStart } from "facility-commons/utils";
import { useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { useTicketDetailPage } from "warehouse/ticket-center/components/TicketDetails/useTicketDetailPage";
import { UNSOLVABLE_DETAILS_PAGE_LABELS } from "./UnsolvableDetailsPage.labels";

export const useUnsolvableDetailsPage = () => {
  const { goBack, match } = useRouter<{ ticketType: NonComplianceType; ticketId: string }>();
  const { warehouseId } = useRecoilValue(userState);
  const { nonComplianceClient } = useClientsWithAuth();
  const { addNotification, resetNotifications } = useCommonFlow();
  const { formatMessage } = useIntl();

  const [details, setDetails] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Display the appropriate ticket title
  useTicketDetailPage();

  const showErrorNotification = (errMsgDescriptor: MessageDescriptor) => {
    addNotification({
      ...createDangerNotification(formatMessage(errMsgDescriptor)),
      onClose: resetNotifications,
    });
  };

  const submitTicketToSupport = async () => {
    const { ticketType, ticketId } = match?.params;
    const ctx = logStart({
      fn: "useUnsolvableDetailsPage#submitTicketToSupport",
      ticketType,
      ticketId,
      warehouseId,
      details,
    });

    if (!ticketId || !ticketType || Number.isNaN(+ticketId)) {
      logger.warn(ctx, "Invalid ticket id or type");
      showErrorNotification(UNSOLVABLE_DETAILS_PAGE_LABELS.invalidTicketError);
      return;
    }

    if (!details) {
      logger.warn(ctx, "Empty details");
      showErrorNotification(UNSOLVABLE_DETAILS_PAGE_LABELS.emptyDetailsError);
      return;
    }

    try {
      setIsLoading(true);
      const { ticketUrl } = await nonComplianceClient.createFollowupZendeskTicket(
        ticketType,
        warehouseId,
        +ticketId,
        details
      );
      logger.info({ ...ctx, ticketUrl }, "Successfully created a new ticket");

      // return to the details page
      goBack();
    } catch (err) {
      logger.error(ctx, err);
      showErrorNotification(UNSOLVABLE_DETAILS_PAGE_LABELS.submitError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    details,
    submitTicketToSupport,
    setDetails,
  };
};
