import { MetabaseWarehouseDashboard } from "@deliverr/legacy-inbound-client/lib/MetabaseWarehouseDashboard";
import { inboundClient } from "facility-commons/base/Clients";
import { isNil } from "lodash";
import { useState } from "react";
import { useAsyncFn } from "react-use";

export const useReceivingDashboard = (warehouseId?: string) => {
  const [error, setError] = useState(null);
  const [{ value: embeddedUrl, loading }, getEmbeddedUrl] = useAsyncFn(async () => {
    if (isNil(warehouseId)) {
      return Promise.resolve(null);
    }

    try {
      return await inboundClient.getDashboardUrl(MetabaseWarehouseDashboard.RECEIVING, warehouseId);
    } catch (err: any) {
      setError(err);
    }
  }, [warehouseId]);

  return {
    getEmbeddedUrl,
    embeddedDashboardUrl: embeddedUrl,
    embeddedDashboardLoading: loading,
    embeddedDashboardError: error,
  };
};
