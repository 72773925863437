import React, { useRef } from "react";
import Slider, { SliderTooltip, Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "@emotion/styled";
import { defaultTheme } from "@deliverr/ui";
import { useSlider } from "./useSlider";

const SliderContainer = styled.div`
  margin: ${defaultTheme.spacing.S6} 0;
`;

// Wrapper component needed to add styles to
// position tooltip and remove tooltip arrow
const HideTooltipArrow = styled.div`
  .rc-slider-tooltip-arrow {
    display: none;
  }

  .rc-slider-tooltip {
    top: -${defaultTheme.spacing.S6} !important;
  }
`;

export interface SliderComponentProps {
  onSliderChange: (value: any) => void;
  min: number;
  max: number;
  startValue: number;
  middleValue: number; // used to calculate percentages needed for track highlighting styles
  step: number | null;
  trackHighlighting: boolean; // allows for red/green color for slider value and track between slider handle and the center value
}

export const SliderComponent: React.FC<SliderComponentProps> = ({
  onSliderChange,
  min,
  max,
  startValue,
  middleValue,
  step,
  trackHighlighting,
}) => {
  const { diff, negativeSliderPercentage, positiveSliderPercentage, sliderValue, onChange } = useSlider(
    startValue,
    middleValue,
    onSliderChange,
    min,
    max
  );
  // Needed to get access to tooltip handle and apply custom styles
  const divRef = useRef(null);
  const getTooltipContainer = () => (divRef.current as unknown) as HTMLInputElement;
  const handle = (props) => {
    return (
      <HideTooltipArrow ref={divRef}>
        <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlay={sliderValue}
          visible
          placement="top"
          key={"tooltip"}
          overlayInnerStyle={{
            background: "none",
            border: "none",
            boxShadow: "none",
            color: `${
              !trackHighlighting || diff === 0
                ? "black"
                : diff > 0
                ? defaultTheme.colors.GREEN[400]
                : defaultTheme.colors.RED[400]
            }`,
          }}
          getTooltipContainer={getTooltipContainer}
        >
          <Handle value={sliderValue} {...props} dragging="false" />
        </SliderTooltip>
      </HideTooltipArrow>
    );
  };

  return (
    <SliderContainer>
      <Slider
        marks={{
          [min]: min,
          [max]: max,
        }}
        dots
        dotStyle={{
          borderColor: `${defaultTheme.colors.NEUTRAL[80]}`,
          backgroundColor: `${defaultTheme.colors.NEUTRAL[80]}`,
          height: 10,
          width: 1,
          borderWidth: 2,
          top: -2,
        }}
        activeDotStyle={{
          borderColor: `${defaultTheme.colors.NEUTRAL[80]}`,
          backgroundColor: `${defaultTheme.colors.NEUTRAL[80]}`,
          height: 10,
          width: 1,
          borderWidth: 2,
          top: -2,
        }}
        min={min}
        max={max}
        step={step}
        defaultValue={startValue}
        value={sliderValue}
        trackStyle={{
          background: `${
            trackHighlighting
              ? // Highlights portion of slider green between starting value and current handle position when
                // current position is greater than starting value (positive adjustment)
                `linear-gradient(to right, ${defaultTheme.colors.NEUTRAL[60]} 0 ${100 - positiveSliderPercentage}%, ${
                  defaultTheme.colors.GREEN[300]
                } ${100 - positiveSliderPercentage}%)`
              : ""
          }`,
          height: 6,
          borderRadius: 0,
        }}
        railStyle={{
          background: `${
            trackHighlighting
              ? // Highlights portion of slider red between starting value and current handle position when
                // current position is less than starting value (negative adjustment)
                `linear-gradient(to right, ${defaultTheme.colors.RED[300]} 0 ${negativeSliderPercentage}%, ${defaultTheme.colors.NEUTRAL[60]} ${negativeSliderPercentage}%)`
              : ""
          }`,
          height: 6,
        }}
        onChange={onChange}
        handleStyle={{
          borderColor: "white",
          height: 24,
          width: 24,
          marginTop: -12,
          backgroundColor: "white",
          boxShadow: `${defaultTheme.shadow.S3}`,
        }}
        handle={handle}
      />
    </SliderContainer>
  );
};

SliderComponent.defaultProps = {
  onSliderChange: (value: any) => ({}),
  min: 0,
  max: 100,
  startValue: 25,
  middleValue: 50,
  step: 10,
  trackHighlighting: true,
};
