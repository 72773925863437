import React from "react";
import { usePalletAnalytics } from "./usePalletAnalytics";
import { Box, Table, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { TextInputCard } from "facility-commons/flow";
import { ExpansionCard } from "warehouse/common/components";

const StyledTable = styled(Table)<ThemeProps>(
  ({ theme }) => `
    table {    
      width: 100%;
      table-layout:fixed;
    }

    th {
      background-color: ${theme.colors.BLUE["300"]};
      color: ${theme.colors.NEUTRAL["00"]};
      font-size: ${theme.font.size.F2};
      padding: ${theme.spacing.S1};
      text-align: center;  
    }

    td {
      text-align:center;
      font-size: ${theme.font.size.F1};
      padding-left: 0px;
      padding-right: 0px;
      word-break: break-all;

    }
  `
);

export const PalletAnalytics: React.FC = () => {
  const {
    palletAnalyticsDetails,
    palletIdTitle,
    pid,
    pidError,
    singleSKUBoxes,
    multiSKUsBoxes,
    loading,
    palletIdPlaceholder,
    bulkBoxReceives,
    handleChange,
    tableHeaders,
    tableColumnWidths,
    bulkEligibleHeader,
    multiSKUHeader,
    singleSKUHeader,
    handleSubmit,
  } = usePalletAnalytics();

  return (
    <div>
      <Box marginBottom="S4">
        <TextInputCard
          title={palletIdTitle}
          message={palletAnalyticsDetails}
          value={pid}
          placeholder={palletIdPlaceholder}
          loading={loading}
          onChange={handleChange}
          onSubmit={handleSubmit}
          errorMessage={pidError}
          accentColor="BLUE"
        />
      </Box>

      <div>
        {!!bulkBoxReceives.length && (
          <ExpansionCard
            hiddenContent={
              <Box marginBottom="S4">
                {bulkBoxReceives.map((group, index) => (
                  <StyledTable
                    key={index}
                    headings={tableHeaders}
                    rows={group.map((pkg) => Object.values(pkg))}
                    caption={bulkEligibleHeader}
                    size="SM"
                    widths={tableColumnWidths}
                  />
                ))}
              </Box>
            }
            title={bulkEligibleHeader}
          />
        )}
        {!!multiSKUsBoxes.length && (
          <ExpansionCard
            hiddenContent={
              <Box marginBottom="S4">
                {multiSKUsBoxes.map((group, index) => (
                  <StyledTable
                    key={index}
                    headings={tableHeaders}
                    rows={group.map((pkg) => Object.values(pkg))}
                    caption={multiSKUHeader}
                    size="SM"
                    widths={tableColumnWidths}
                  />
                ))}
              </Box>
            }
            title={multiSKUHeader}
          />
        )}
        {!!singleSKUBoxes.length && (
          <ExpansionCard
            hiddenContent={
              <Box marginBottom="S4">
                <StyledTable
                  headings={tableHeaders}
                  rows={singleSKUBoxes.map((pkg) => Object.values(pkg))}
                  caption={singleSKUHeader}
                  size="SM"
                  widths={tableColumnWidths}
                />
              </Box>
            }
            title={singleSKUHeader}
          />
        )}
      </div>
    </div>
  );
};
