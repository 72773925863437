import React from "react";

import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { barcodeMessages } from "../../content/barcode";
import { useBarcodeCard } from "./useBarcodeCard";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { skuMessages } from "warehouse/receiving/content/sku";
import { SkipStep } from "../../components";

export const BarcodeCard: React.FC = () => {
  const {
    barcode,
    barcodeError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  } = useBarcodeCard();

  return (
    <StackedInputCard
      errorMessage={barcodeError}
      illustration={<OpenBox />}
      illustrationTitle={formatMessage(skuMessages.openThisBox)}
      loading={loading}
      message={formatMessage(barcodeMessages.instructions)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(skuMessages.barcodePlaceholder)}
      secondary
      title={formatMessage(skuMessages.scanBarcodeTitle)}
      value={barcode}
    >
      <SkipStep
        message={formatMessage(barcodeMessages.missing)}
        onSkip={() => proceedToFollowUp({ isLotEnabled: false, isFefoEnabled: false })}
      />
    </StackedInputCard>
  );
};
