import { TicketCenterOtherNonComplianceResponse } from "@deliverr/commons-clients";
import React from "react";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { DashboardDetailExpansionBlock } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { DashboardDetailInfoList } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList";
import { TicketCardThumbnails } from "warehouse/ticket-center/common/components/TicketCardThumbnails";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { CenteredSpinner } from "../CenteredSpinner";
import { OverviewCardActionSection } from "../OverviewCardActionSection";
import { TicketCenterOverviewNotesSection } from "../TicketCenterOverviewNotesSection";
import { useOtherTicketOverviewCard } from "./useOtherTicketOverviewCard";

export const OtherTicketOverviewCard: React.FC = () => {
  const { ticketDetails, loading, showDeleteModal, handleThumbnailClick } = useTicketDetails<
    TicketCenterOtherNonComplianceResponse
  >();
  const { initialInfoList, quantityTitle, quantityList, otherDetailsList } = useOtherTicketOverviewCard({
    ticketDetails,
  });

  if (loading || !ticketDetails) {
    return <CenteredSpinner loading />;
  }

  return (
    <div>
      <DashboardDetailCard createdAt={new Date(ticketDetails.createdAt)}>
        <DashboardDetailInfoList addDivider items={initialInfoList} />
        <DashboardDetailExpansionBlock addDivider disableExpansion title={quantityTitle} shouldBoldTitle>
          <DashboardDetailInfoList items={quantityList} />
        </DashboardDetailExpansionBlock>
        {ticketDetails.pictures.length > 0 && (
          <TicketCardThumbnails
            addDivider
            addBottomPadding
            onThumbnailClick={handleThumbnailClick}
            thumbnails={ticketDetails.pictures.map((pic) => `${pic}`)}
          />
        )}
        <TicketCenterOverviewNotesSection notes={ticketDetails.notes} addDivider />
        <DashboardDetailInfoList items={otherDetailsList} addDivider removeLastDivider />
      </DashboardDetailCard>
      <OverviewCardActionSection status={ticketDetails.status} showDeleteModal={showDeleteModal} />
    </div>
  );
};
