import { useState } from "react";
import { useAsyncFn } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { genericOnScannerInputChange, log, logError, logStart } from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { isInvalidCdsku } from "warehouse/receiving/utils";
import { OtherNonComplianceValidationErrorMessages } from "warehouse/ticket-center/new/forms/OtherNonCompliance/OtherNonComplianceForm.types";
import { SoundFx } from "facility-commons/common/sfx";
import { crossdockClient } from "facility-commons/base/Clients";
import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";
import { asnResponseState } from "./AsnResponseState";
import { MessageDescriptor, useIntl } from "react-intl";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { userState } from "facility-commons/base/Auth/userState";

export const useCdskuScan = (
  modalMessage: (asn: ReceivingAsn) => MessageDescriptor,
  errorMessage?: string,
  handleSubmitCdskuScanSuccess?: (asn: ReceivingAsn) => void
) => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const [cdskuError, setCdskuError] = useState<string>("");
  const [cdsku, setCdsku] = useState<string>("");
  const { playSfx, emitFlash, errorResponse } = useCommonFlow();
  const { warehouseId } = useRecoilValue(userState);
  const setAsnResponseState = useSetRecoilState(asnResponseState);

  const [submitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      setAsnResponseState(undefined);
      logStart({ fn: "useNonComplianceCdskuScan.handleSubmit", cdsku, warehouseId, cdskuError });
      return handleSubmitCdskuScan(value, warehouseId);
    },
    [warehouseId]
  );

  const handleSubmitCdskuScan = async (cdsku: string, warehouseId: string) => {
    const ctx = { fn: "useCdskuScan.handleSubmitCdskuScan" };
    setCdskuError("");

    if (!cdsku.length || isInvalidCdsku(cdsku)) {
      errorResponse();
      setCdskuError(OtherNonComplianceValidationErrorMessages.CDSKU_FORMAT);
      setCdsku("");
      return;
    }

    try {
      log(ctx, "submitting cdsku", { cdsku });
      const scanResponse = await crossdockClient.getReceivingAsn(cdsku, warehouseId);
      setAsnResponseState(scanResponse);
      switch (scanResponse?.asnType) {
        case "TRANSFER":
        case "NONE":
          setAsnResponseState(scanResponse);
          if (handleSubmitCdskuScanSuccess) {
            handleSubmitCdskuScanSuccess(scanResponse);
          }
          playSfx(SoundFx.SUCCESS);
          emitFlash("SUCCESS");
          break;
        case "MISROUTE":
          setAsnResponseState(scanResponse);
          playSfx(SoundFx.INFO);
          emitFlash("SUCCESS");
          showModal(WarehouseModal.MISROUTED_CDSKU, { asn: scanResponse, modalMessage });
          break;
        default:
          setCdskuError(
            formatMessage(
              {
                id: "warehouse.cdskuScan.invalidScanError",
                defaultMessage: `{cdsku} is invalid. {errorMessage}`,
              },
              { cdsku, errorMessage: errorMessage ?? "" }
            )
          );
          logError({ ...ctx }, new Error("Unknown ASN type"));
          errorResponse();
          break;
      }
    } catch (error) {
      logError({ ...ctx, error }, new Error("Unknown error"));
      errorResponse();
      setCdskuError(
        formatMessage(
          {
            id: "warehouse.cdskuScan.invalidScanError",
            defaultMessage: `{cdsku} is invalid. {errorMessage}`,
          },
          { cdsku, errorMessage: errorMessage ?? "" }
        )
      );
    } finally {
      setCdsku("");
    }
  };

  const handleChange = genericOnScannerInputChange(cdsku, setCdsku, handleSubmit, "upper");

  return { cdsku, cdskuError, handleChange, handleSubmit, loading: submitState.loading };
};
