import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";

interface CountBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  count?: number;
}

export const CountBadge = styled.div<CountBadgeProps, DefaultTheme>(({ theme }) => {
  return `
    background-color: ${theme.colors.NEUTRAL[300]};
    border-radius: 24px;
    color: ${theme.colors.NEUTRAL["00"]};
    font-size: ${theme.font.size.F1};
    font-weight: ${theme.font.weight.REGULAR};
    line-height: ${theme.font.lineHeight.LH1};
    margin-left: 4px;
    padding: 0 8px;
    text-align: center;
`;
});
