import { useRef, useState } from "react";
import { isNaN } from "lodash";
import { setProp } from "facility-commons/utils";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  currentSkuState,
  receiveFlowTypeState,
  receivingState,
  bulkReceiveState,
  isMidBulkReceiveFlow,
  numOfBoxesExpectedState,
} from "warehouse/receiving/ReceivingState";
import { MAX_BOXES_TO_RECEIVE, MAX_UNITS_TO_RECEIVE, QTY_INPUT_LENGTH_LIMIT } from "warehouse/receiving/utils";
import {
  createBulkBoxScanNotification,
  createOverReceiveNotification,
  createUnderReceiveNotification,
  useReceivingFlow,
} from "warehouse/receiving/base";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { modalsText } from "../modalsText";
import { useIntl } from "react-intl";
import { quantityMessages } from "warehouse/receiving/content";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useRouter } from "facility-commons/hooks";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { ReceivingData, ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { SoundFx } from "facility-commons/common/sfx";
import {
  isPalletReceiveFlow,
  isConsolidationFlow,
  isBoxReceiveFlow,
} from "warehouse/receiving/utils/receivingFlowType";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { COMMON_LABELS } from "../../cards/warehouse.labels";
import { isElevatedUser } from "warehouse/receiving/ReceivingState/isElevatedUser";

export enum ConfirmUnitsModalTextConditions {
  UNEXPECTED_SKU = "UNEXPECTED_SKU",
  BOXES_INCORRECT = "BOXES_INCORRECT",
  BOXES_INCORRECT_AND_NEEDS_RECONFIRM = "BOXES_INCORRECT_AND_NEEDS_RECONFIRM",
  UNITS_INCORRECT = "UNITS_INCORRECT",
  UNITS_INCORRECT_AND_NEEDS_RECONFIRM = "UNITS_INCORRECT_AND_NEEDS_RECONFIRM",
  BOXES_AND_UNITS_INCORRECT = "BOXES_AND_UNITS_INCORRECT",
  BOXES_AND_UNITS_INCORRECT_AND_NEEDS_RECONFIRM = "BOXES_AND_UNITS_INCORRECT_AND_NEEDS_RECONFIRM",
}

export const useConfirmUnitsCount = ({
  incorrectBoxCount,
  incorrectUnitCount,
  initialUnitCount,
  initialBoxCount,
  prevUnitCount,
  prevBoxCount,
  needsToReconfirm,
  isUnexpectedSku,
}) => {
  const [boxesQtyInput, setBoxesQtyInput] = useState<undefined | number>(undefined);
  const [unitsQtyInput, setUnitsQtyInput] = useState<undefined | number>(undefined);
  const [boxQuantityError, setBoxQuantityError] = useState<string>("");
  const { expeditedBulkBoxLabels, isExpeditedBulkEligible } = useRecoilValue(bulkReceiveState);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [receivingFlowType, setReceivingFlowType] = useRecoilState(receiveFlowTypeState);
  const resetBulkReceiveState = useResetRecoilState(bulkReceiveState);

  const currentSku = useRecoilValue(currentSkuState);
  const initializeReceivingData = useInitializeReceivingData();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);

  const boxQuantityRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { successResponse, errorResponse, addNotification, playSfx } = useCommonFlow();
  const {
    cdsku,
    expectedQuantity: expectedUnitsQty,
    quantity: unitsQty,
    previouslyReceivedQuantity,
    isSkuOnBox,
  } = currentSku;
  const { expectedNumOfBoxes, identicalBoxes, receivingError, requireElevatedRoleToUnderReceive } = receivingData;
  const { updateCurrentSku } = useReceivingFlow();
  const { hideAllModals, showModal } = useWarehouseModal();
  const isMidBulkReceive = useRecoilValue(isMidBulkReceiveFlow);
  const numOfBoxesExpected = useRecoilValue(numOfBoxesExpectedState);
  const isConsolidation = isConsolidationFlow(receivingFlowType);
  const isBoxReceive = isBoxReceiveFlow(receivingFlowType);

  const isElevatedUserReceiving = useRecoilValue(isElevatedUser);

  const justBoxesIncorrect = incorrectBoxCount && !incorrectUnitCount;
  const boxesAndUnitsIncorrect = incorrectBoxCount && incorrectUnitCount;
  const expectedUnitsQtyAdjustedForPreviouslyReceived = expectedUnitsQty - previouslyReceivedQuantity;

  const overReceive = unitsQty > expectedUnitsQtyAdjustedForPreviouslyReceived;
  const underReceive = requireElevatedRoleToUnderReceive && unitsQty < expectedUnitsQtyAdjustedForPreviouslyReceived;

  const modalTitle = needsToReconfirm
    ? formatMessage(
        boxesAndUnitsIncorrect
          ? modalsText.reconfirmUnitAndBoxCountTitle
          : incorrectUnitCount
          ? modalsText.reconfirmUnitTitle
          : modalsText.reconfirmBoxesTitle
      )
    : formatMessage(
        boxesAndUnitsIncorrect
          ? modalsText.incorrectUnitAndFewerBoxesCountTitle
          : incorrectUnitCount
          ? modalsText.incorrectUnitTitle
          : modalsText.fewerBoxesTitle
      );

  const createOverOrUnderReceiveNotification = () => {
    if (overReceive) {
      addNotification(createOverReceiveNotification());
      return;
    }
    if (underReceive) {
      addNotification(createUnderReceiveNotification());
    }
  };

  const getModalTextCondition = (): ConfirmUnitsModalTextConditions => {
    if (needsToReconfirm) {
      if (boxesAndUnitsIncorrect) {
        return ConfirmUnitsModalTextConditions.BOXES_AND_UNITS_INCORRECT_AND_NEEDS_RECONFIRM;
      } else if (justBoxesIncorrect) {
        return ConfirmUnitsModalTextConditions.BOXES_INCORRECT_AND_NEEDS_RECONFIRM;
      } else {
        return ConfirmUnitsModalTextConditions.UNITS_INCORRECT_AND_NEEDS_RECONFIRM;
      }
    } else if (isUnexpectedSku || !isSkuOnBox) {
      return ConfirmUnitsModalTextConditions.UNEXPECTED_SKU;
    } else {
      if (boxesAndUnitsIncorrect) {
        return ConfirmUnitsModalTextConditions.BOXES_AND_UNITS_INCORRECT;
      } else if (justBoxesIncorrect) {
        return ConfirmUnitsModalTextConditions.BOXES_INCORRECT;
      } else {
        return ConfirmUnitsModalTextConditions.UNITS_INCORRECT;
      }
    }
  };

  const unitQtyPlaceholder = isConsolidation
    ? formatMessage(quantityMessages.quantityOfUnits)
    : formatMessage(quantityMessages.quantityPlaceholder);

  const boxQtyPlaceholder = formatMessage(modalsText.boxesQtyPlaceholder);
  const backButtonText = formatMessage(modalsText.back);
  const confirmButtonText = formatMessage(modalsText.confirm);

  const unitsQtyRequired = incorrectUnitCount && !unitsQtyInput;
  const boxesQtyRequired = incorrectBoxCount && !boxesQtyInput;

  const isPalletReceive = isPalletReceiveFlow(receivingFlowType);

  const handleBoxQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = Number(e.target.value);
    if (eventValue.toString().length > QTY_INPUT_LENGTH_LIMIT) {
      return setBoxQuantityError(formatMessage(COMMON_LABELS.TOO_MANY_UNITS_ERROR));
    }

    if (!isNaN(eventValue)) {
      setBoxesQtyInput(eventValue);
      setReceivingData(setProp("identicalBoxes", Math.abs(eventValue)));
      setBoxQuantityError("");
    }
  };

  const handleUnitsQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (value.toString().length > QTY_INPUT_LENGTH_LIMIT) {
      return setReceivingData(setProp("receivingError", formatMessage(COMMON_LABELS.TOO_MANY_UNITS_ERROR)));
    }
    if (!isNaN(value)) {
      setUnitsQtyInput(value);
      updateCurrentSku("quantity", Math.abs(value));
      setReceivingData(setProp("receivingError", ""));
    }
  };
  const handleCloseErrModal = () => {
    hideAllModals();
    updateCurrentSku("quantity", initialUnitCount);
    setReceivingData(setProp("identicalBoxes", initialBoxCount));
  };
  const handleRouteToBoxReceiving = () => {
    router.push(ReceivingPath.CDSKU);
    setReceivingFlowType(ReceivingFlowType.BOX_RECEIVING);
    initializeReceivingData();
    setWarehouseAppState(setProp("pageSubtitle", ""));
    handleCloseErrModal();
  };

  const isInputsDoubleConfirmedOrMatchesExpected = (): boolean => {
    const unitQtyIsDoubleConfirmedOrMatchesExpected =
      unitsQtyInput === prevUnitCount || unitsQtyInput === expectedUnitsQtyAdjustedForPreviouslyReceived;

    const boxesQtyIsDoubleConfirmedOrMatchesExpected =
      boxesQtyInput === prevBoxCount || boxesQtyInput === numOfBoxesExpected;

    if (boxesAndUnitsIncorrect) {
      return unitQtyIsDoubleConfirmedOrMatchesExpected && boxesQtyIsDoubleConfirmedOrMatchesExpected;
    } else if (incorrectUnitCount || isUnexpectedSku) {
      return unitQtyIsDoubleConfirmedOrMatchesExpected;
    } else if (incorrectBoxCount) {
      return boxesQtyIsDoubleConfirmedOrMatchesExpected;
    } else {
      return true;
    }
  };
  const submitQty = () => {
    const moreBoxes = boxesQtyInput && expectedNumOfBoxes < boxesQtyInput;
    const palletWithMoreBoxes = moreBoxes && isPalletReceive;

    if (palletWithMoreBoxes) {
      playSfx(SoundFx.UNEXPECTED_QUANTITY);

      showModal(WarehouseModal.INCORRECT_BOX_QTY, {
        onCancel: handleCloseErrModal,
        onConfirm: handleRouteToBoxReceiving,
        boxesQty: boxesQtyInput,
        fewerBoxes: false,
      });
    } else if (isInputsDoubleConfirmedOrMatchesExpected()) {
      // the follow block of code is for handling unexpected,over, and under-receives
      // isSkuOnBox is an indicator of an unexpected/expected sku
      // if non-problem solver receiving unexpected sku, they shouldn't even get to this step
      // if problem solver receiving unexpected sku, images for unexpected skus would have already been taken in a previous step
      // but images for over/under-receives have not been taken and will be taken on the next step
      const requireImages = overReceive || underReceive;
      if (isBoxReceive && isSkuOnBox && requireImages) {
        if (isElevatedUserReceiving) {
          if (isMidBulkReceive) {
            // can't do bulk receive if units per box over expected
            hideAllModals();
            return showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
              onConfirm: () => {
                resetBulkReceiveState();
                setReceivingData(setProp("identicalBoxes", 1));
                hideAllModals();
                createOverOrUnderReceiveNotification();
                router.push(ReceivingPath.IMAGES);
              },
              onCancel: () => {
                hideAllModals();
              },
              title: formatMessage(modalsText.overReceive),
              text: formatMessage(modalsText.bulkReceiveIneligible, { cdsku }),
            });
          } else {
            // if single sku single box over/under-receive, we can continue on with taking images
            createOverOrUnderReceiveNotification();
            return router.push(ReceivingPath.IMAGES);
          }
        } else {
          // not elevated user, block receive.
          return showModal(WarehouseModal.RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL, {});
        }
      }
      successResponse();
      if (isMidBulkReceive) {
        setReceivingData((state: ReceivingData) => ({
          ...state,
          cdskusToReceive: [cdsku],
        }));
        router.push(ReceivingPath.BULK);
        addNotification(createBulkBoxScanNotification(cdsku));
      } else {
        router.push(ReceivingPath.LOCATION);
      }
    } else {
      errorResponse();
      const isBoxCountReconfirmed = incorrectBoxCount
        ? boxesQtyInput === prevBoxCount || boxesQtyInput === numOfBoxesExpected
        : true;

      const isUnitCountReconfirmed = incorrectUnitCount
        ? unitsQtyInput === prevUnitCount || unitsQtyInput === expectedUnitsQtyAdjustedForPreviouslyReceived
        : true;

      showModal(WarehouseModal.CONFIRM_UNITS_COUNT, {
        onCancel: handleCloseErrModal,
        incorrectBoxCount: !isBoxCountReconfirmed,
        incorrectUnitCount: !isUnitCountReconfirmed,
        fewerUnits: unitsQty < expectedUnitsQtyAdjustedForPreviouslyReceived,
        fewerBoxes: identicalBoxes < numOfBoxesExpected,
        boxesDelta: Math.abs(identicalBoxes - numOfBoxesExpected),
        initialBoxCount,
        initialUnitCount,
        prevBoxCount: boxesQtyInput,
        prevUnitCount: unitsQtyInput,
        needsToReconfirm: true,
        isUnexpectedSku,
      });
      setBoxesQtyInput(undefined);
      setUnitsQtyInput(undefined);
    }
  };

  const handleOnConfirmSubmission = () => {
    if (unitsQtyInput && unitsQtyInput > MAX_UNITS_TO_RECEIVE) {
      return setReceivingData(setProp("receivingError", formatMessage(COMMON_LABELS.TOO_MANY_UNITS_ERROR)));
    }
    if (boxesQtyInput && boxesQtyInput > MAX_BOXES_TO_RECEIVE) {
      return setBoxQuantityError(formatMessage(COMMON_LABELS.TOO_MANY_BOXES_ERROR));
    }
    hideAllModals();
    submitQty();
  };

  return {
    boxQuantityError,
    boxQuantityRef,
    boxesQtyInput,
    unitsQtyInput,
    receivingError,
    disableContinueBtn: unitsQtyRequired || boxesQtyRequired,
    formatMessage,
    modalTitle,
    getModalTextCondition,
    unitQtyPlaceholder,
    boxQtyPlaceholder,
    backButtonText,
    confirmButtonText,
    handleBoxQuantityChange,
    handleUnitsQuantityChange,
    handleOnConfirmSubmission,
    expeditedBulkBoxLabels,
    isExpeditedBulkEligible,
  };
};
