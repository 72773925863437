import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { NCRoutes } from "../../base/routes";
import { useRouter } from "facility-commons/hooks";
import { validEmailPattern } from "facility-commons/utils/config";
import { userState } from "facility-commons/base/Auth/userState";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const useEmailCard = () => {
  const { formatMessage } = useIntl();
  const { warehouseContactEmail } = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();

  const { email: defaultEmail, isStorage } = useRecoilValue(userState);
  const { push } = useRouter();
  const { formState, register } = useFormContext();

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateCurrentProduct("warehouseContactEmail", event.target.value);
  const proceed = () => push(isStorage ? NCRoutes.STORAGE_REVIEW : NCRoutes.REVIEW);

  return {
    defaultEmail,
    email: warehouseContactEmail,
    formatMessage,
    hasError: !!formState.errors.warehouseContactEmail,
    proceed,
    registerEmail: register("warehouseContactEmail", { pattern: validEmailPattern, value: warehouseContactEmail }),
    updateEmail,
  };
};
