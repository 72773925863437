import React from "react";
import { FlatTopInputCard } from "warehouse/common/components/StackedInputCard";
import { WarehouseMessages } from "facility-commons/utils";
import { boxMessages } from "warehouse/ticket-center/new/non-compliance/content";

import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";
import { usePOCard } from "./usePOCard";
import { commonWarehouseMessages } from "warehouse/common/messages";

export const POCard = () => {
  const { routeToNonCompliance, asnId, handleSubmit, handleChange, asnIdError, loading, formatMessage } = usePOCard();

  return (
    <FlatTopInputCard
      newest
      value={asnId}
      errorMessage={asnIdError}
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(WarehouseMessages.po)}
      loading={loading}
      title={formatMessage(WarehouseMessages.scanPO)}
    >
      <SkipStep
        hideSeparator
        onSkip={routeToNonCompliance}
        message={formatMessage(boxMessages.poUnknown)}
        skipText={formatMessage(commonWarehouseMessages.createNonComplianceTicket)}
      ></SkipStep>
    </FlatTopInputCard>
  );
};
