import React from "react";
import { commonConsolidationMessages, commonReceivingMessages } from "warehouse/receiving/content";
import styled from "@emotion/styled";
import { TextInputCard } from "facility-commons/flow";
import { useConsolidationCard } from "./useConsolidationCard";
import { ThemeProps } from "@deliverr/ui";

const FlatTopInputCard = styled(TextInputCard)<ThemeProps>(
  ({ theme }) => `
  border-radius: ${theme.border.radius.R5};
`
);

export const ConsolidationCard = () => {
  const {
    handleChange,
    submitScanInput,
    scanInput,
    formatMessage,
    consolidationLabelErr,
    loading,
  } = useConsolidationCard();

  return (
    <FlatTopInputCard
      newest
      errorMessage={consolidationLabelErr}
      loading={loading}
      message={formatMessage(commonConsolidationMessages.consolidationDescription)}
      onChange={handleChange}
      onSubmit={submitScanInput}
      placeholder={formatMessage(commonConsolidationMessages.consolidationID)}
      title={formatMessage(commonReceivingMessages.scanConsolidationID)}
      value={scanInput}
      helpText={formatMessage(commonConsolidationMessages.consolidationHelperText)}
    />
  );
};
