import React from "react";

import { WarehouseMessages } from "facility-commons/utils";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { boxMessages } from "../../content";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { useNcCdskuCard } from "./useNcCdskuCard";
import { SkipStep } from "../../components";

export const NcCdskuCard: React.FC = () => {
  const {
    cdsku,
    cdskuError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    routeToTrackingStep,
  } = useNcCdskuCard();

  return (
    <StackedInputCard
      errorMessage={cdskuError}
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
      illustration={<ScanBox />}
      illustrationTitle={formatMessage(WarehouseMessages.boxScan)}
      loading={loading}
      message={formatMessage(WarehouseMessages.boxInstructionsText)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(WarehouseMessages.cdsku)}
      title={formatMessage(WarehouseMessages.scanCdsku)}
      value={cdsku}
    >
      <SkipStep message={formatMessage(boxMessages.cdskuUnknown)} onSkip={routeToTrackingStep} />
    </StackedInputCard>
  );
};
