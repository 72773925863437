import { FlowCardMap } from "facility-commons/flow/types/FlowMaps";
import { WarehouseFlowCardType } from "../types/WarehouseFlowCardType";
import { ArrivalScannerCard, PalletArrivalScannerErrorCard } from "warehouse/scan-arrival/cards/pallet";
import { ArrivalScannerSuccessCard } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerSuccessCard";
import { ArrivalScannerFailureCard } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerFailureCard";
import { ArrivalScannerInvalidInputCard } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerInvalidInputCard";
import { BoxArrivalScannerCard, BoxTypeIdentificationCard } from "warehouse/scan-arrival/cards/box";
import { ResponseCard } from "facility-commons/flow";

export const warehouseFlowCardMap: FlowCardMap = {
  // Pallet Arrival Scan
  [WarehouseFlowCardType.ARRIVAL_SCANNER]: ArrivalScannerCard,
  [WarehouseFlowCardType.ARRIVAL_SCANNER_SUCCESS]: ArrivalScannerSuccessCard,
  [WarehouseFlowCardType.ARRIVAL_SCANNER_FAILURE]: ArrivalScannerFailureCard,
  [WarehouseFlowCardType.ARRIVAL_SCANNER_INVALID_INPUT]: ArrivalScannerInvalidInputCard,
  [WarehouseFlowCardType.PALLET_ARRIVAL_SCANNER_ERROR]: PalletArrivalScannerErrorCard,
  // Box Arrival Scan
  [WarehouseFlowCardType.BOX_ARRIVAL_SCANNER]: BoxArrivalScannerCard,
  [WarehouseFlowCardType.BOX_IDENTIFICATION]: BoxTypeIdentificationCard,
  [WarehouseFlowCardType.BOX_ARRIVAL_SCAN_NOTIFICATION]: ResponseCard,
};
