import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletSummaryNoAsnFoundErrorMessageKey {
  NO_ASN_FOUND = "NO_ASN_FOUND",
}

export const PALLET_SUMMARY_NO_ASN_FOUND_ERROR_BY_ERROR_CODE: Record<
  PalletSummaryNoAsnFoundErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletSummaryNoAsnFoundErrorMessageKey.NO_ASN_FOUND]: {
    id: "warehouse.receiving.PalletSummaryNoAsnFound",
    defaultMessage: "ASN not found for pallet {palletLabelId}",
  },
});
