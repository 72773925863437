import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  barcode: { id: "facilityCommons.barcode", defaultMessage: "Barcode" },
  boxInformation: { id: "facilityCommons.boxInformation", defaultMessage: "Box information" },
  continue: { id: "facilityCommons.continue", defaultMessage: "Continue" },
  email: { id: "facilityCommons.email", defaultMessage: "Email" },
  no: { id: "facilityCommons.no", defaultMessage: "No" },
  photos: { id: "facilityCommons.photos", defaultMessage: "Photos" },
  quantities: { id: "facilityCommons.quantities", defaultMessage: "Quantities" },
  totalUnits: { id: "facilityCommons.totalUnits", defaultMessage: "Total units" },
  unknown: { id: "facilityCommons.unknown", defaultMessage: "Unknown" },
  yes: { id: "facilityCommons.yes", defaultMessage: "Yes" },
  save: { id: "facilityCommons.save", defaultMessage: "Save" },
  showAll: { id: "facilityCommons.all", defaultMessage: "Show All" },
  skipStep: {
    id: "facilityCommons.skipThisStep",
    defaultMessage: "Skip this step",
  },
  box: {
    id: "facilityCommons.boxUnit",
    defaultMessage: `{boxesQty, plural,
                one {# box}
                other {# boxes}
              }`,
  },
  unit: {
    id: "facilityCommons.unit",
    defaultMessage: `{unitsQty, plural,
                one {# unit}
                other {# units}
              }`,
  },
});

export const commonUploadMessages = defineMessages({
  backOfSku: {
    id: "facilityCommons.upload.backOfSku",
    defaultMessage: "Back of SKU",
  },
  barcodeMissing: {
    id: "facilityCommons.upload.barcodeMissing",
    defaultMessage: "Can't find the barcode on the SKU?",
  },
  bclMissing: {
    id: "facilityCommons.upload.bclMissing",
    defaultMessage: "Can't find the box contents label?",
  },
  frontOfSku: {
    id: "facilityCommons.upload.frontOfSku",
    defaultMessage: "Front of SKU",
  },
  identifyProductTitle: {
    id: "facilityCommons.upload.identifyProduct.title",
    defaultMessage: "Take photos to identify the product",
  },
  takePhoto: {
    id: "facilityCommons.upload.takePhoto",
    defaultMessage: "Take or upload photo",
  },
  takeAPhotoBarcodeTitle: {
    id: "facilityCommons.upload.barcode.title",
    defaultMessage: "Take a photo of the SKU barcode",
  },
  takeAPhotoBclTitle: {
    id: "facilityCommons.upload.bcl.title",
    defaultMessage: "Take a photo of the box contents label",
  },
  takeAPhotoLotExpiryTitle: {
    id: "facilityCommons.upload.lotExpiry.title",
    defaultMessage: "Take a photo of the lot number and expiration date",
  },
});
