import { createFlowCard } from "facility-commons/flow";
import { StorageFlowCardType } from "warehouse/common/flow/types/StorageFlowCardType";

export const createReceivingPOScan = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_PO_SCAN);
export const createReceivingPalletCount = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_PALLET_COUNT);
export const createReceivingPOSelection = createFlowCard(StorageFlowCardType.STORAGE_INBOUND_PO_SELECTION_STEP);
export const createReportProblemSelection = createFlowCard(StorageFlowCardType.STORAGE_REPORT_PROBLEM_SELECTION);
export const createReceivingSKUScan = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU);
export const createReceivingSKUQuantity = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU_QUANTITY);
export const createReceivingSKUCaseInfo = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU_CASE_INFO);
export const createReceivingSKUCasePackSelection = createFlowCard(
  StorageFlowCardType.STORAGE_RECEIVING_SKU_CASE_PACK_SELECTION
);
export const createReceivingLotInfo = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_LOT_DETAILS);
export const createReceivingSKULP = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU_LP);
export const createReceivingSKULC = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU_LC);
export const createReceivingSKUConfirm = createFlowCard(StorageFlowCardType.STORAGE_RECEIVING_SKU_CONFIRMATION);
