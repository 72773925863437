import React from "react";
import { ResponseCard } from "facility-commons/flow/cards/ResponseCard";
import { FormattedMessage } from "react-intl";

export interface ArrivalScannerFailureCardProps {
  scanInput: string;
}

export const ArrivalScannerFailureCard: React.FC<ArrivalScannerFailureCardProps> = ({ scanInput }) => (
  <ResponseCard
    message={
      <FormattedMessage
        id="crossdock.arrivalScan.scannerFailure"
        defaultMessage={`Failed to scan ${scanInput}. Please try again or contact Deliverr.`}
      />
    }
    accentColor="RED"
    icon="exclamation-circle"
  />
);
