import React from "react";
import { Route } from "react-router-dom";
import { Hazmat } from "warehouse/hazmat";

import { WarehousePortalRoutes } from "warehouse/routes";
import { WarehouseEmbeddedDashboard } from "../common/components/EmbeddedDashboard";
import { DownloadDhlManifest } from "./dhl";
import { More } from "./More";
import { OutboundCapacity } from "./OutboundCapacity";
import { PONumberLookup } from "./PONumberLookup";
import { StoragePrintPalletLabels } from "./storage/StoragePrintPalletLabels/StoragePrintPalletLabels";
import { POWorklistCard } from "./storage/poWorkList/POWorklistCard";
import { ChatbotCard } from "./storage/wot";
import { GetProductZone } from "./GetProductZone";
import { StoragePrintSSCCLabels } from "./storage/StoragePrintSSCCLabels/StoragePrintSSCCLabels";

export enum MoreRoutesNames {
  DOWNLOAD_DHL_MANIFEST = "more/dhl",
  PO_NUMBER_LOOKUP = "more/po-lookup",
  HAZMAT = "more/hazmat",
  OUTBOUND_CAPACITY = "more/capacity",
  DAILY_OPS_DASHBOARD = "more/daily-ops-dashboard",
  OPS_METRICS_DASHBOARD = "more/ops-metrics-dashboard",
  GET_PRODUCT_ZONE = "more/get-product-zone",
  STORAGE_OPS_METRICS_DASHBOARD = "more/storage-ops-metrics-dashboard",
  STORAGE_OPS_EXPECTED_VS_RECEIVED_QUANTITIES_DASHBOARD = "more/storage-ops-expected-vs-received-quantities-dashboard",
  FORECASTING_PLANNING_DASHBOARD = "more/forecasting-planning-dashboard",
  PRINT_PALLET_LABELS = "more/print-pallet-labels",
  PRINT_SSCC_LABELS = "more/print-sscc-labels",
  PO_WORKLIST = "more/po-worklist",
  STORAGE_WAREHOUSE_BOT = "more/storage-warehouse-bot",
}

export const MoreRoutes = [
  <Route
    key={WarehousePortalRoutes.PO_NUMBER_LOOKUP}
    path={WarehousePortalRoutes.PO_NUMBER_LOOKUP}
    render={() => <PONumberLookup />}
  />,
  <Route
    key={WarehousePortalRoutes.DOWNLOAD_DHL_MANIFEST}
    path={WarehousePortalRoutes.DOWNLOAD_DHL_MANIFEST}
    render={() => <DownloadDhlManifest />}
  />,
  <Route key={WarehousePortalRoutes.HAZMAT} path={WarehousePortalRoutes.HAZMAT} render={() => <Hazmat />} />,
  <Route
    key={WarehousePortalRoutes.OUTBOUND_CAPACITY}
    path={WarehousePortalRoutes.OUTBOUND_CAPACITY}
    render={() => <OutboundCapacity />}
  />,
  <Route
    key={WarehousePortalRoutes.DAILY_OPS_DASHBOARD}
    path={WarehousePortalRoutes.DAILY_OPS_DASHBOARD}
    render={() => <WarehouseEmbeddedDashboard />}
  />,
  <Route
    key={WarehousePortalRoutes.OPS_METRICS_DASHBOARD}
    path={WarehousePortalRoutes.OPS_METRICS_DASHBOARD}
    render={() => <WarehouseEmbeddedDashboard />}
  />,
  <Route
    key={WarehousePortalRoutes.STORAGE_OPS_METRICS_DASHBOARD}
    path={WarehousePortalRoutes.STORAGE_OPS_METRICS_DASHBOARD}
    render={() => <WarehouseEmbeddedDashboard />}
  />,
  <Route
    key={WarehousePortalRoutes.STORAGE_OPS_EXPECTED_VS_RECEIVED_QUANTITIES_DASHBOARD}
    path={WarehousePortalRoutes.STORAGE_OPS_EXPECTED_VS_RECEIVED_QUANTITIES_DASHBOARD}
    render={() => <WarehouseEmbeddedDashboard />}
  />,
  <Route
    key={WarehousePortalRoutes.FORECASTING_PLANNING_DASHBOARD}
    path={WarehousePortalRoutes.FORECASTING_PLANNING_DASHBOARD}
    render={() => <WarehouseEmbeddedDashboard />}
  />,
  <Route
    key={WarehousePortalRoutes.PRINT_PALLET_LABELS}
    path={WarehousePortalRoutes.PRINT_PALLET_LABELS}
    render={() => <StoragePrintPalletLabels />}
  />,
  <Route
    key={WarehousePortalRoutes.PRINT_SSCC_LABELS}
    path={WarehousePortalRoutes.PRINT_SSCC_LABELS}
    render={() => <StoragePrintSSCCLabels />}
  />,
  <Route
    key={WarehousePortalRoutes.PO_WORKLIST}
    path={WarehousePortalRoutes.PO_WORKLIST}
    render={() => <POWorklistCard />}
  />,
  <Route
    key={WarehousePortalRoutes.STORAGE_WAREHOUSE_BOT}
    path={WarehousePortalRoutes.STORAGE_WAREHOUSE_BOT}
    render={() => <ChatbotCard />}
  />,
  <Route
    key={WarehousePortalRoutes.GET_PRODUCT_ZONE}
    path={WarehousePortalRoutes.GET_PRODUCT_ZONE}
    render={() => <GetProductZone />}
  />,
  <Route key={WarehousePortalRoutes.MORE} path={WarehousePortalRoutes.MORE} render={() => <More />} />,
];
