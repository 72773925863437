import { castArray, isEmpty } from "lodash/fp";
import { addToFixedLength, setProp } from "facility-commons/utils/immutabilityUtils";
import { useResetRecoilState, useSetRecoilState, RecoilState } from "recoil";
import { FlowCardData, FlowCardsState, FlowButtonsState } from "facility-commons/flow/types";
import { useCommonFlow } from "./useCommonFlow";
import { FlowTransition } from "./FlowTransition";
import { FlowError } from "./flowErrors";

export const MAX_VISIBLE_FLOW_CARDS = 2;

interface UseFlowProps<TPortalButton extends string = string> {
  cardsState: RecoilState<FlowCardsState>;
  buttonsState: RecoilState<FlowButtonsState<TPortalButton>>;
}

export function composeUseFlow<TPortalButton extends string = string>({
  cardsState,
  buttonsState,
}: UseFlowProps<TPortalButton>) {
  return function () {
    const {
      showTimer,
      hideTimer,
      addNotification,
      addAutoCloseNotification,
      clearNotification,
      resetNotifications,
      handleUnknownError,
      playSfx,
      maybePlaySfx,
      emitFlash,
      successResponse,
      infoResponse,
      errorResponse,
    } = useCommonFlow();

    const setFlowCards = useSetRecoilState(cardsState);
    const resetFlowCards = useResetRecoilState(cardsState);
    const setButtonsVisible = useSetRecoilState(buttonsState);
    const resetButtonsVisible = useResetRecoilState(buttonsState);

    function addFlowCard<P extends Record<string, any>>(card: FlowCardData<P>): void {
      maybePlaySfx(card.sfx);
      setFlowCards(addToFixedLength(card, MAX_VISIBLE_FLOW_CARDS));
    }

    function nextFlowCard<P extends Record<string, any>>(card: FlowCardData<P>): void {
      maybePlaySfx(card.sfx);
      resetFlowCards();
      setFlowCards(addToFixedLength(card, 1));
    }

    function showFlowButton(action: TPortalButton): void {
      setButtonsVisible(setProp(action, true));
    }

    function hideFlowButton(action: TPortalButton): void {
      setButtonsVisible(setProp(action, false));
    }

    function hideAllFlowButtons(): void {
      resetButtonsVisible();
    }

    function showFlowButtons(...actions: TPortalButton[]): void {
      hideAllFlowButtons();
      actions.forEach(showFlowButton);
    }

    function resetFlow() {
      resetFlowCards();
      hideAllFlowButtons();
      resetNotifications();
    }

    // FlowV2 only: shows a card for n seconds while displaying a timer
    function transition(transitionsData: FlowTransition | FlowTransition[]): void {
      const transitions = castArray(transitionsData);

      if (isEmpty(transitions)) {
        throw new Error(FlowError.NO_TRANSITIONS_SET);
      }

      const runTransition = (transitionIndex: number): void => {
        const {
          duration,
          card,
          notification,
          onTransition,
          postTransition,
          sfx,
          flash,
          shouldClearButtons,
          shouldClearNotifications,
        } = transitions[transitionIndex];

        maybePlaySfx(sfx);

        if (flash) {
          emitFlash(flash);
        }

        addFlowCard(card);

        if (shouldClearButtons) {
          hideAllFlowButtons();
        }

        if (shouldClearNotifications) {
          resetNotifications();
        }

        if (notification) {
          addNotification(notification);
        }

        onTransition?.();

        if (duration) {
          const seconds = duration;
          showTimer(seconds);

          let currentSeconds = seconds;
          const intervalId = setInterval(() => {
            currentSeconds--;

            if (currentSeconds !== 0) {
              return showTimer(currentSeconds);
            }

            clearInterval(intervalId);
            hideTimer();

            (postTransition ? postTransition() : Promise.resolve()).then(() => {
              const nextIndex = transitionIndex + 1;
              if (transitions.length > nextIndex) {
                runTransition(nextIndex);
              }
            });
          }, 1000);
        }
      };

      runTransition(0);
    }

    return {
      addFlowCard,
      nextFlowCard,
      resetFlowCards,
      showFlowButton,
      showFlowButtons,
      hideFlowButton,
      hideAllFlowButtons,
      resetFlow,
      transition,
      addNotification,
      addAutoCloseNotification,
      clearNotification,
      resetNotifications,
      handleUnknownError,
      playSfx,
      emitFlash,
      successResponse,
      infoResponse,
      errorResponse,
    };
  };
}
