import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIntl } from "react-intl";

import { ProductCasePackData } from "@deliverr/commons-clients";
import { CreatePackInProductAndInboundResponse } from "@deliverr/storage-client";
import { DeliverrError } from "@deliverr/commons-objects";
import { createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import {
  STORAGE_ARRIVAL_SCAN_MESSAGES,
  STORAGE_RECEIVING_MESSAGES,
  StorageErrorCodes,
} from "warehouse/common/messages/storage";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKULP, createReceivingSKUQuantity } from "../storageReceivingCardCreators";
import { storageClient } from "facility-commons/base/Clients";
import { userState } from "facility-commons/base/Auth/userState";

export const useStorageReceivingSKUCasePackSelectionFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const { warehouseId } = useRecoilValue(userState);
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);

  const { addFlowCard, hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { showModal } = useWarehouseModal();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_PACK_SELECTION_BACK_BUTTON);
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_PACK_SELECTION_CONTINUE_BUTTON);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [{ loading: isBackLoading }, handleBackClick] = useAsyncFn(() => {
    const handleBackReceiveSKUQtyScan = async () => {
      setScanState((state) => ({
        ...state,
        currentScreen: 1,
      }));
      addFlowCard(createReceivingSKUQuantity({}));
    };
    return handleBackReceiveSKUQtyScan();
  });

  const [{ loading }, handleSubmit] = useAsyncFn(async (packConfig: ProductCasePackData) => {
    const ctx = {
      fn: "useStorageReceivingSKUCasePackSelectionFlow.handleSubmitSKUCasePackSelection",
      poNum: scanState.poNum,
    };
    logStart(ctx);
    const { length, height, width, packName, lengthUnit } = packConfig;
    const updatedPackName = `${packName} (${length}*${width}*${height} ${lengthUnit}), Pack of ${scanState.currentSkuDetails.receivedCaseQty} units`;

    try {
      const response = await storageClient.createPackInProductAndInbound(warehouseId, {
        ...packConfig,
        packName: updatedPackName,
      });
      const productCasePack = ((response as unknown) as CreatePackInProductAndInboundResponse)?.data;
      const isSuccessful = ((response as unknown) as CreatePackInProductAndInboundResponse)?.success;
      if (!isSuccessful || !productCasePack) {
        throw new DeliverrError({
          code: "UNKNOWN_ERROR",
          message: formatMessage(STORAGE_RECEIVING_MESSAGES.productCasePackCreationFailed),
        });
      }
      const currentSKUDetails = {
        ...scanState.currentSkuDetails,
        dsku: productCasePack.dsku,
        caseQty: productCasePack.quantity,
        width: productCasePack.width,
        height: productCasePack.height,
        length: productCasePack.length,
        lengthUnit: productCasePack.lengthUnit,
        weight: productCasePack.weight,
        weightUnit: productCasePack.weightUnit,
        barcodes: [...scanState.currentSkuDetails.barcodes, productCasePack.dsku],
        packOf: productCasePack.packOf,
        isNewChildProductCreated: true,
      };
      setScanState((state) => ({
        ...state,
        currentSKU: productCasePack.dsku,
        currentSkuDetails: currentSKUDetails,
        skus: { ...state.skus, [productCasePack.dsku]: currentSKUDetails },
      }));
      transition([
        {
          card: createReceivingSKULP({}),
          notification: createSuccessNotification(
            formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanSuccessNotificationTitle)
          ),
          sfx: SoundFx.SUCCESS,
          flash: "SUCCESS",
          onTransition: () => {
            hideAllFlowButtons();
            showFlowButton(StorageFlowButtonType.STORAGE_SKU_LP_RECEIVING_BACK_BUTTON);
          },
        },
      ]);
    } catch (err: any) {
      if (err?.response) {
        const { code, error } = err.response?.data;
        let text = "";
        switch (code) {
          case StorageErrorCodes.PRODUCT_DETAIL_FETCH_FAILED:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.incorrectDSKUScanned);
            break;
          default:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.unrecognisedDescriptionText, {
              error: String(error),
            });
            break;
        }
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text,
        });
      } else {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err?.error ? err.error?.message : err),
        });
      }
    }
  });

  const handleSelectionChange = (selectedPackConfig: Partial<ProductCasePackData>) => {
    setScanState((state) => ({ ...state, selectedPackConfig }));
  };

  const handleContinueClick = () => {
    handleSubmit({
      ...scanState.selectedPackConfig,
      quantity: scanState.currentSkuDetails?.receivedCaseQty,
      packOf: scanState.currentSkuDetails?.packOf || scanState.currentSKU,
    });
  };

  return {
    scanState,
    handleSelectionChange,
    handleSubmit,
    handleBackClick,
    handleContinueClick,
    loading,
    isBackLoading,
    isContinueButtonDisabled: !scanState.selectedPackConfig,
  };
};
