import { WarehousePublic } from "@deliverr/business-types";
import { atom } from "recoil";
import { WarehouseStateKey } from "./WarehouseStateKey";

interface WarehouseIdState {
  warehouseId: string;
  userName: string;
}

export const warehouseIdState = atom<WarehouseIdState>({
  key: WarehouseStateKey.WAREHOUSE_ID,
  default: {
    warehouseId: "",
    userName: "",
  },
});

export const warehouseDataState = atom<WarehousePublic | undefined>({
  key: WarehouseStateKey.WAREHOUSE_DATA,
  default: undefined,
});
