import { FC } from "react";

import { DeleteTicketModal } from "warehouse/ticket-center/components/TicketDetails";
import { MisroutedBox } from "warehouse/ticket-center/new/non-compliance/modals/MisroutedBox";
import { CdskuRerouteModal } from "warehouse/common/components/CdskuScan/CdskuRerouteModal";
import { StoragePromptModal, TicketCenterThumbnailModal, UnexpectedPalletWarningModal } from "./components";

import {
  DirectPalletModal,
  ExitReceivingModal,
  MixedPalletModal,
  QuantityWarningModal,
  SkusWarningModal,
  UpdateBulkQuantityModal,
  IncorrectBoxQtyModal,
  IncorrectBoxAndUnitQtyModal,
  PartiallyReceivedPallet,
  UnexpectedSkus,
  ConsolidationUnexpectedSku,
  ClosedPallet,
  ClosedPalletAsn,
  ConfirmReportProblemModal,
  ConsolidationPOModal,
  ContactDeliverrModal,
  ConfirmCumulativeReceiveToSameLocationModal,
  ConfirmUnitsCount,
  FailedReceiveModal,
  InvalidLocation,
  UnexpectedFefoLotModal,
  NoLotFefoSupportModal,
  ConfirmExpiredProductReceiveModal,
  FilterModal,
  ConfirmLotExpiryModal,
  SingleSkuSIOCModal,
} from "warehouse/receiving/components/modals";
import { UnexpectedSku } from "warehouse/ticket-center/new/non-compliance/modals/UnexpectedSku";
import { GenericWarningConfirmationModal } from "warehouse/ticket-center/new/non-compliance/modals/GenericWarningConfirmationModal";
import { DamagedProductTicketSuccessModal } from "facility-commons/components/modals/DamagedProductTicketSuccessModal";
import { StorageArrivalExistingShipmentWarnModal } from "warehouse/scan-arrival/storage/StorageArrivalExistingShipmentWarnModal";
import { StorageReceivingLPNContentLabel } from "warehouse/receiving/storage/StorageReceivingLPNContentLabel";
import { StorageConfirmationModal } from "warehouse/receiving/storage/StorageConfirmationModal";
import { StoragePOCompletionModal } from "warehouse/receiving/storage/StoragePOCompletionModal";
import {
  SortBoxToRackModal,
  RouteToPalletArrivalScanModal,
  BoxTypeIdentificationModal,
  SortBoxToFacilityAreaModal,
} from "warehouse/scan-arrival/modals";
import { ReturnReceiveUnclaimedReturnWarningModal } from "warehouse/receiving/components/modals/ReturnReceiveUnclaimedReturnWarningModal";
import { ReturnReceiveExpectedItemsModal } from "warehouse/receiving/components/modals/ReturnReceiveExpectedItemsModal";
import { ReturnReceiveInspectionNotesModal } from "warehouse/receiving/components/modals/ReturnReceiveInspectionNotesModal";
import { ReturnReceiveItemInspectionStandardsModal } from "warehouse/receiving/components/modals/ReturnReceiveItemInspectionStandardsModal/ReturnReceiveItemInspectionStandardsModal";
import { ReturnReceivePrintBarcodeErrorModal } from "warehouse/receiving/components/modals/ReturnReceivePrintBarcodeErrorModal";
import { ReturnReceiveCreateRTMBoxModal } from "warehouse/receiving/components/modals/ReturnReceiveCreateRTMBoxModal";
import { ReturnReceiveCloseReturnOrderModal } from "warehouse/receiving/components/modals/ReturnReceiveCloseReturnOrderModal";
import { ReturnReceiveCaptureItemImageModal } from "warehouse/receiving/components/modals/ReturnReceiveCaptureItemImageModal";
import { UnexpectedReceiveAttemptModal } from "warehouse/ticket-center/new/non-compliance/modals/UnexpectedReceiveAttemptModal";
import { OutboundScanNextShipmentModal } from "warehouse/rs-outbounds/modals/OutboundScanNextShipmentModal";
import { OutboundDiscardPackageModal } from "warehouse/rs-outbounds/modals/OutboundDiscardPackageModal";

export enum WarehouseModal {
  RECEIVING_EXIT_APP_WARNING = "RECEIVING_EXIT_APP_WARNING",
  RECEIVING_MIXED_PALLET = "RECEIVING_MIXED_PALLET",
  RECEIVING_NOT_CD_PALLET = "RECEIVING_NOT_CD_PALLET",
  PO_IS_FOR_CONSOLIDATION = "PO_IS_FOR_CONSOLIDATION",
  INVALID_LOCATION = "INVALID_LOCATION",
  INCORRECT_UNIT_QTY = "INCORRECT_UNIT_QTY",
  INCORRECT_BOX_QTY = "INCORRECT_BOX_QTY",
  INCORRECT_BOX_AND_UNIT_QTY = "INCORRECT_BOX_AND_UNIT_QTY",
  RECEIVING_UNEXPECTED_SKUS = "RECEIVING_UNEXPECTED_SKUS",
  RECEIVING_UPDATE_BULK_QTY = "RECEIVING_UPDATE_BULK_QTY",
  TICKET_CENTER_THUMBNAIL = "TICKET_CENTER_THUMBNAIL",
  UNEXPECTED_PALLET_WARNING = "UNEXPECTED_PALLET_WARNING",
  MISROUTED_CDSKU = "MISROUTED_CDSKU",
  NC_MISROUTED_CDSKU = "NC_MISROUTED_CDSKU",
  NC_UNEXPECTED_SKU = "NC_UNEXPECTED_SKU",
  DELETE_TICKET = "DELETE_TICKET",
  PARTIALLY_RECEIVED_PALLET = "PARTIALLY_RECEIVED_PALLET",
  UNEXPECTED_SKUS = "UNEXPECTED_SKUS",
  CONSOLIDATION_UNEXPECTED_SKUS = "CONSOLIDATION_UNEXPECTED_SKUS",
  CLOSED_PALLET = "CLOSED_PALLET",
  CLOSED_PALLET_ASN = "CLOSED_PALLET_ASN",
  CONFIRM_REPORT_PROBLEM = "CONFIRM_REPORT_PROBLEM",
  CONTACT_DELIVERR = "CONTACT_DELIVERR",
  DAMAGED_TICKET_CREATION_CONFIRMATION = "DAMAGED_TICKET_CREATION_CONFIRMATION",
  CONFIRM_CUMULATIVE_RECEIVE_TO_SAME_LOCATION = "CONFIRM_CUMULATIVE_RECEIVE_TO_SAME_LOCATION",
  CONFIRM_UNITS_COUNT = "CONFIRM_UNITS_COUNT",
  FAILED_RECEIVE = "FAILED_RECEIVE",
  UNEXPECTED_FEFO_LOT = "UNEXPECTED_FEFO_LOT",
  NO_LOT_FEFO_SUPPORT = "NO_LOT_FEFO_SUPPORT",
  CONFIRM_EXPIRED_PRODUCT_RECEIVE = "CONFIRM_EXPIRED_PRODUCT_RECEIVE",
  STORAGE_PROMPT_MODAL = "STORAGE_PROMPT_MODAL",
  STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL = "STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL",
  FILTER_MENU = "FILTER_MENU",
  STORAGE_RECEIVING_LPN_CONTENT_LABEL = "STORAGE_RECEIVING_LPN_CONTENT_LABEL",
  STORAGE_CONFIRMATION_MODAL = "STORAGE_CONFIRMATION_MODAL",
  GENERIC_WARNING_CONFIRMATION_MODAL = "GENERIC_WARNING_CONFIRMATION_MODAL",
  RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL = "RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL",
  STORAGE_RECEIVING_MARK_PO_COMPLETE = "STORAGE_RECEIVING_MARK_PO_COMPLETE",
  SORT_BOX_TO_RACK = "SORT_BOX_TO_RACK",
  SORT_BOX_TO_FACILITY_AREA = "SORT_BOX_TO_FACILITY_AREA",
  ROUTE_TO_PALLET_ARRIVAL_SCAN = "ROUTE_PALLET_ARRIVAL_SCAN",
  BOX_TYPE_IDENTIFICATION = "BOX_TYPE_IDENTIFICATION",
  CONFIRM_LOT_EXPIRY = "CONFIRM_LOT_EXPIRY",
  RETURN_RECEIVE_UNCLAIMED_RETURN_WARNING = "RETURN_RECEIVE_UNCLAIMED_RETURN_WARNING",
  RETURN_RECEIVE_EXPECTED_ITEMS = "RETURN_RECEIVE_EXPECTED_ITEMS",
  RETURN_RECEIVE_INSPECTION_NOTES = "RETURN_RECEIVE_INSPECTION_NOTES",
  RETURN_RECEIVE_ITEM_INSPECTION_STANDARDS = "RETURN_RECEIVE_ITEM_INSPECTION_STANDARDS",
  RETURN_RECEIVE_RTM_BOX_CREATION = "RETURN_RECEIVE_RTM_BOX_CREATION",
  RETURN_RECEIVE_RTM_PRINT_BARCODE_ERROR = "RETURN_RECEIVE_RTM_PRINT_BARCODE_ERROR",
  RETURN_RECEIVE_CLOSE_RETURN_ORDER = "RETURN_RECEIVE_CLOSE_RETURN_ORDER",
  RETURN_RECEIVE_CAPTURE_ITEM_IMAGE = "RETURN_RECEIVE_CAPTURE_ITEM_IMAGE",
  OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL = "OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL",
  OUTBOUND_DISCARD_PACKAGE_MODAL = "OUTBOUND_DISCARD_PACKAGE_MODAL",
  SINGLE_SKU_SIOC = "SINGLE_SKU_SIOC",
}

export const warehouseModalMap: Record<WarehouseModal, FC<any>> = {
  [WarehouseModal.CONTACT_DELIVERR]: ContactDeliverrModal,
  [WarehouseModal.PO_IS_FOR_CONSOLIDATION]: ConsolidationPOModal,
  [WarehouseModal.RECEIVING_EXIT_APP_WARNING]: ExitReceivingModal,
  [WarehouseModal.RECEIVING_MIXED_PALLET]: MixedPalletModal,
  [WarehouseModal.RECEIVING_NOT_CD_PALLET]: DirectPalletModal,
  [WarehouseModal.FAILED_RECEIVE]: FailedReceiveModal,
  [WarehouseModal.INVALID_LOCATION]: InvalidLocation,
  [WarehouseModal.INCORRECT_UNIT_QTY]: QuantityWarningModal,
  [WarehouseModal.INCORRECT_BOX_QTY]: IncorrectBoxQtyModal,
  [WarehouseModal.INCORRECT_BOX_AND_UNIT_QTY]: IncorrectBoxAndUnitQtyModal,
  [WarehouseModal.CONFIRM_UNITS_COUNT]: ConfirmUnitsCount,
  [WarehouseModal.RECEIVING_UNEXPECTED_SKUS]: SkusWarningModal,
  [WarehouseModal.RECEIVING_UPDATE_BULK_QTY]: UpdateBulkQuantityModal,
  [WarehouseModal.TICKET_CENTER_THUMBNAIL]: TicketCenterThumbnailModal,
  [WarehouseModal.UNEXPECTED_PALLET_WARNING]: UnexpectedPalletWarningModal,
  [WarehouseModal.DELETE_TICKET]: DeleteTicketModal,
  [WarehouseModal.MISROUTED_CDSKU]: CdskuRerouteModal,
  [WarehouseModal.NC_MISROUTED_CDSKU]: MisroutedBox,
  [WarehouseModal.PARTIALLY_RECEIVED_PALLET]: PartiallyReceivedPallet,
  [WarehouseModal.NC_UNEXPECTED_SKU]: UnexpectedSku,
  [WarehouseModal.UNEXPECTED_SKUS]: UnexpectedSkus,
  [WarehouseModal.CONSOLIDATION_UNEXPECTED_SKUS]: ConsolidationUnexpectedSku,
  [WarehouseModal.CLOSED_PALLET]: ClosedPallet,
  [WarehouseModal.CLOSED_PALLET_ASN]: ClosedPalletAsn,
  [WarehouseModal.CONFIRM_REPORT_PROBLEM]: ConfirmReportProblemModal,
  [WarehouseModal.DAMAGED_TICKET_CREATION_CONFIRMATION]: DamagedProductTicketSuccessModal,
  [WarehouseModal.CONFIRM_CUMULATIVE_RECEIVE_TO_SAME_LOCATION]: ConfirmCumulativeReceiveToSameLocationModal,
  [WarehouseModal.UNEXPECTED_FEFO_LOT]: UnexpectedFefoLotModal,
  [WarehouseModal.NO_LOT_FEFO_SUPPORT]: NoLotFefoSupportModal,
  [WarehouseModal.CONFIRM_EXPIRED_PRODUCT_RECEIVE]: ConfirmExpiredProductReceiveModal,
  [WarehouseModal.STORAGE_PROMPT_MODAL]: StoragePromptModal,
  [WarehouseModal.STORAGE_ARRIVAL_SCAN_EXISTING_SHIPMENT_PROMPT_MODAL]: StorageArrivalExistingShipmentWarnModal,
  [WarehouseModal.FILTER_MENU]: FilterModal,
  [WarehouseModal.STORAGE_RECEIVING_LPN_CONTENT_LABEL]: StorageReceivingLPNContentLabel,
  [WarehouseModal.STORAGE_CONFIRMATION_MODAL]: StorageConfirmationModal,
  [WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL]: GenericWarningConfirmationModal,
  [WarehouseModal.RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL]: UnexpectedReceiveAttemptModal,
  [WarehouseModal.STORAGE_RECEIVING_MARK_PO_COMPLETE]: StoragePOCompletionModal,
  [WarehouseModal.SORT_BOX_TO_RACK]: SortBoxToRackModal,
  [WarehouseModal.SORT_BOX_TO_FACILITY_AREA]: SortBoxToFacilityAreaModal,
  [WarehouseModal.ROUTE_TO_PALLET_ARRIVAL_SCAN]: RouteToPalletArrivalScanModal,
  [WarehouseModal.BOX_TYPE_IDENTIFICATION]: BoxTypeIdentificationModal,
  [WarehouseModal.CONFIRM_LOT_EXPIRY]: ConfirmLotExpiryModal,
  [WarehouseModal.RETURN_RECEIVE_UNCLAIMED_RETURN_WARNING]: ReturnReceiveUnclaimedReturnWarningModal,
  [WarehouseModal.RETURN_RECEIVE_EXPECTED_ITEMS]: ReturnReceiveExpectedItemsModal,
  [WarehouseModal.RETURN_RECEIVE_INSPECTION_NOTES]: ReturnReceiveInspectionNotesModal,
  [WarehouseModal.RETURN_RECEIVE_ITEM_INSPECTION_STANDARDS]: ReturnReceiveItemInspectionStandardsModal,
  [WarehouseModal.RETURN_RECEIVE_RTM_BOX_CREATION]: ReturnReceiveCreateRTMBoxModal,
  [WarehouseModal.RETURN_RECEIVE_RTM_PRINT_BARCODE_ERROR]: ReturnReceivePrintBarcodeErrorModal,
  [WarehouseModal.RETURN_RECEIVE_CLOSE_RETURN_ORDER]: ReturnReceiveCloseReturnOrderModal,
  [WarehouseModal.RETURN_RECEIVE_CAPTURE_ITEM_IMAGE]: ReturnReceiveCaptureItemImageModal,
  [WarehouseModal.OUTBOUND_SCAN_NEXT_SHIPMENT_MODAL]: OutboundScanNextShipmentModal,
  [WarehouseModal.OUTBOUND_DISCARD_PACKAGE_MODAL]: OutboundDiscardPackageModal,
  [WarehouseModal.SINGLE_SKU_SIOC]: SingleSkuSIOCModal,
};
