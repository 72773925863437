import { defineMessages } from "react-intl";

export const modalsText = defineMessages({
  singleSkuSiocModalTitle: {
    id: "warehouse.receiving.singleSkuSiocModalTitle",
    defaultMessage: "Single SKU SIOC",
  },
  singleSkuSiocModalText: {
    id: "warehouse.receiving.singleSkuSiocModalText",
    defaultMessage: "This is a single SKU ship in own container (SIOC) unit. Do not unbox inventory.",
  },
  exitReceivingTitle: {
    id: "warehouse.modal.exitReceivingTitle",
    defaultMessage: "Exit receiving?",
  },
  exitReceivingText: {
    id: "warehouse.modal.exitReceivingText",
    defaultMessage: "Leaving session will discard any changes. Would you like to exit now?",
  },
  createTicketText: {
    id: "warehouse.modal.createTick",
    defaultMessage: "Create ticket",
  },
  fewerBoxesTitle: {
    id: "warehouse.palletReceive.fewerBoxesTitle",
    defaultMessage: "Confirm box count",
  },
  reconfirmBoxesTitle: {
    id: "warehouse.receiving.reconfirmBoxesTitle",
    defaultMessage: "Reconfirm box count",
  },
  moreBoxesTitle: {
    id: "warehouse.palletReceive.moreBoxesTitle",
    defaultMessage: "Unexpected box count",
  },
  back: {
    id: "back",
    defaultMessage: "Back",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
  },
  remove: {
    id: "remove",
    defaultMessage: "Remove",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "confirm",
    defaultMessage: "Confirm",
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  exit: {
    id: "exit",
    defaultMessage: "Exit",
  },
  continue: {
    id: "continue",
    defaultMessage: `Continue`,
  },
  print: {
    id: "print",
    defaultMessage: "Print",
  },
  receiveBoxes: {
    id: "warehouse.receiving.bulkTitle",
    defaultMessage: "Receive boxes",
  },
  receiveConsolidation: {
    id: "warehouse.receiving.receiveConsolidaiton",
    defaultMessage: "Receive consolidation",
  },
  fewerBoxesText: {
    id: "warehouse.palletReceive.fewerBoxes",
    defaultMessage: `The quantities you’ve entered are not what were expected. Continue with {box}?`,
  },
  moreBoxesText: {
    id: "warehouse.palletReceive.moreBoxes",
    defaultMessage: "You have too many boxes for this pallet. Break pallet and use Box Receive.",
  },
  incorrectUnitAndFewerBoxesCountTitle: {
    id: "warehouse.palletReceive.incorrectUnitAndFewerBoxesTitle",
    defaultMessage: "Confirm unit and box count",
  },
  reconfirmUnitAndBoxCountTitle: {
    id: "warehouse.receiving.reconfirmUnitAndBoxCountTitle",
    defaultMessage: "Reconfirm unit and box count",
  },
  incorrectUnitTitle: {
    id: "warehouse.receiving.confirmUnitcount",
    defaultMessage: "Confirm unit count",
  },
  reconfirmUnitTitle: {
    id: "warehouse.receiving.reconfirmUnitTitle",
    defaultMessage: "Reconfirm unit count",
  },
  incorrectUnitText: {
    id: "warehouse.receiving.incorrectUnitCount",
    defaultMessage: "The quantity you entered is not what was expected.  Continue with {unit}?",
  },
  cumulativeReceiveTitle: {
    id: "warehouse.receiving.confirmCumulativeReceiveTitle",
    defaultMessage: "Receive more units",
  },
  cumulativeReceiveText: {
    id: "warehouse.receiving.confirmCumulativeReceiveText",
    defaultMessage: "{previouslyReceived} units have previously been received. Receive {inputtedQuantity} more units?",
  },
  incorrectUnitAndMoreBoxesCountTitle: {
    id: "warehouse.palletReceive.incorrectUnitAndMoreBoxesTitle",
    defaultMessage: "Unexpected unit and box count",
  },
  incorrectUnitAndFewerBoxesCountText: {
    id: "warehouse.palletReceive.incorrectUnitsAndFewerBoxes",
    defaultMessage: `The quantities you’ve entered are not what were expected. Continue with {unit} and {box}?`,
  },
  notCDPalletTitle: {
    id: "warehouse.receiving.notCrossdockPallet",
    defaultMessage: "Not a CD pallet",
  },
  notCDPalletText: {
    id: "warehouse.receiving.unexpectedBoxOnPallet",
    defaultMessage: "Only CD pallets (yellow label) can be pallet received. Break pallet and receive boxes.",
  },
  mixedPalletTitle: { id: "warehouse.receiving.mixedPallet", defaultMessage: "Mixed Pallet" },
  mixedPalletText: {
    id: "warehouse.receiving.breakPalletAndReceiveBoxes",
    defaultMessage:
      "This pallet cannot be received because it has multiple SKUs or box configurations. Break pallet and receive boxes.",
  },
  skusMissingTitle: {
    id: "warehouse.receiving.skusMissingTitle",
    defaultMessage: "Missing {skusMissing, plural, =1 {# SKU} other {# SKUs}}",
  },
  skusMissingText: {
    id: "warehouse.receiving.skusMissingMessage",
    defaultMessage:
      "This box should have {expectedSkus, plural, =1 {# SKU} other {# SKUs}}. Do you want to only receive {receivedSkus, plural, =1 {# SKU} other {# SKUs}}?",
  },
  unexpectedBoxTitle: { id: "warehouse.receiving.unexpectedBox", defaultMessage: "Unexpected box" },
  unexpectedBoxText: {
    id: "warehouse.receiving.unexpectedBoxText",
    defaultMessage: "This CDSKU does not belong in this pallet. To receive this box, use Box Receive.",
  },
  consolidationPOTitle: { id: "warehouse.receiving.consolidationPOTitle", defaultMessage: "Consolidation PO #" },
  consolidationPOText: {
    id: "warehouse.receiving.consolidationPOMessage",
    defaultMessage: "Receive this box as part of a consolidation.",
  },
  confirmExpiredProductReceiveTitle: {
    id: "warehouse.receiving.confirmExpiredProductReceiveTitle",
    defaultMessage: "Receiving expired product",
  },
  confirmExpiredProductReceiveText: {
    id: "warehouse.receiving.confirmExpiredProductReceiveText",
    defaultMessage: "The SKU you are receiving has expired. Create a non-compliance ticket after completing receive.",
  },
  asnNotFoundTitle: { id: "warehouse.receiving.asnNotFoundTitle", defaultMessage: "PO # Not Found" },
  asnNotFoundText: {
    id: "warehouse.receiving.asnNotFoundText",
    defaultMessage: "This PO # does not exist within our records. Please contact Deliverr.",
  },
  asnIsCrossDockTitle: { id: "warehouse.receiving.asnIsCrossDockTitle", defaultMessage: "PO # at Incorrect Warehouse" },
  asnIsCrossDockText: {
    id: "warehouse.receiving.asnIsCrossDockText",
    defaultMessage: "This PO # cannot be received at this warehouse. Please contact Deliverr.",
  },
  asnWarehouseMismatchTitle: {
    id: "warehouse.receiving.asnWarehouseMismatchTitle",
    defaultMessage: "PO # at Incorrect Warehouse",
  },
  asnWarehouseMismatchText: {
    id: "warehouse.receiving.asnWarehouseMismatchText",
    defaultMessage: "This PO # is to be received at another warehouse. Please contact Deliverr.",
  },
  identicalBoxCount: {
    id: "warehouse.receiving.boxCount",
    defaultMessage: "Number of identical boxes",
  },
  boxesQtyPlaceholder: { id: "warehouse.receiving.boxesQtyPlaceholder", defaultMessage: "Number of identical boxes" },
  countBoxes: {
    id: "warehouse.receiving.countBoxes",
    defaultMessage: "Count Identical Boxes",
  },
  partiallyReceivedPalletTitle: {
    id: "warehouse.receiving.partiallyReceivedPalletTitle",
    defaultMessage: "Partially Received Pallet",
  },
  partiallyReceivedPalletText: {
    id: "warehouse.receiving.partiallyReceivedPalletText",
    defaultMessage: "Boxes on this pallet have already been received. Break pallet and receive boxes.",
  },
  receiveAnotherSku: {
    id: "warehouse.receiving.receiveAnotherSku",
    defaultMessage: "Receive another SKU",
  },
  unexpectedSkuTitle: {
    id: "warehouse.receiving.unexpectedSkuTitle",
    defaultMessage: "Unexpected SKU",
  },
  closedPalletTitle: {
    id: "warehouse.receiving.closedPalletTitle",
    defaultMessage: "PO # {asnId} Closed",
  },
  closedPalletAsnTitle: {
    id: "warehouse.receiving.closedPalletAsnTitle",
    defaultMessage: "PO # {asnLabel} Closed",
  },
  closedPalletText: {
    id: "warehouse.receiving.closedPalletText",
    defaultMessage: "You cannot receive on a closed PO #. Please re-open the PO # in you WMS to continue receiving.",
  },
  exitAndReport: {
    id: "warehouse.receiving.exitAndReport",
    defaultMessage: "Exit & report problem",
  },
  failedReceiveText: {
    id: "warehouse.receiving.failedReceiveText",
    defaultMessage:
      "Lot/expiry date receiving is not currently enabled. Please receive these items directly in Logiwa.",
  },
  receiveInLogiwa: {
    id: "warehouse.receiving.receiveOnLogiwa",
    defaultMessage: "Receive in Logiwa",
  },
  canNotProceed: {
    id: "warehouse.receiving.canNotProceed",
    defaultMessage: "Can't proceed",
  },
  invalidLoc: {
    id: "warehouse.receiving.invalidLoc",
    defaultMessage: "Invalid stow location",
  },
  stowDiffExp: {
    id: "warehouse.receiving.stowDiffExp",
    defaultMessage: "A stow location cannot contain SKUs with different expiration dates.",
  },
  stowDiffLot: {
    id: "warehouse.receiving.stowDiffLot",
    defaultMessage: "A stow location cannot contain SKUs with different Lot numbers.",
  },
  tryAnotherLoc: {
    id: "warehouse.receiving.tryAnotherLoc",
    defaultMessage: "Try another location",
  },
  unexpectedLot: {
    id: "warehouse.receiving.unexpectedLot",
    defaultMessage: "Unexpected lot number. Report the problem.",
  },
  unexpectedDateAndLot: {
    id: "warehouse.receiving.unexpectedDateAndLot",
    defaultMessage: "The expiration date and lot number do not match what is expected. Report the problem.",
  },
  expirationLotMismatchTitle: {
    id: "warehouse.receiving.expirationLotMismatchTitle",
    defaultMessage: "Mismatch",
  },
  expirationLotMismatchText: {
    id: "warehouse.receiving.expirationLotMismatchText",
    defaultMessage:
      "The expiration date and LOT combination chosen does not match what is expected. Create a non-compliance ticket?",
  },
  noLotSupportTitle: {
    id: "warehouse.receiving.noLotSupportTitle",
    defaultMessage: "Warehouse does not support LOT numbers",
  },
  noLotSupportText: {
    id: "warehouse.receiving.noLotSupportText",
    defaultMessage:
      "This product has a LOT number but your warehouse does not support LOT numbers. Create a non-compliance ticket?",
  },
  noExpirySupportTitle: {
    id: "warehouse.receiving.noExpirySupportTitle",
    defaultMessage: "Warehouse does not support expiration dates",
  },
  noExpirySupportText: {
    id: "warehouse.receiving.noExpirySupportText",
    defaultMessage:
      "This product has an expiration date but your warehouse does not support expiration dates. Create a non-compliance ticket?",
  },
  noLotExpirySupportTitle: {
    id: "warehouse.receiving.noLotExpirySupportTitle",
    defaultMessage: "Warehouse does not support LOT numbers or expiration dates",
  },
  noLotExpirySupportText: {
    id: "warehouse.receiving.noLotExpirySupportText",
    defaultMessage:
      "This product has an expiration date, a LOT number, or both. Your warehouse does not support expiration dates or LOT numbers. Create a non-compliance ticket?",
  },
  showTicketBtn: {
    id: "warehouse.NC.showTicketBtn",
    defaultMessage: "Show {ticketsCount} {ticketsCount, plural, one {ticket} other {tickets}}",
  },
  filterMenuTitle: {
    id: "warehouse.NC.filterMenuTitle",
    defaultMessage: "filter",
  },
  filterMenuHeader: {
    id: "warehouse.NC.filterMenuHeader",
    defaultMessage: "Ticket Type",
  },
  boxSortCrossdockTitle: {
    id: "warehouse.arrivalScan.boxSortCrossdockTitle",
    defaultMessage: "Crossdock Box",
  },
  sortBoxToFCReceiving: {
    id: "warehouse.arrivalScan.sortBoxToFCReceiving",
    defaultMessage: "Sort box with label {label} to the fulfillment area for FC receiving.",
  },
  sortBoxToCDSorting: {
    id: "warehouse.arrivalScan.sortBoxToCDSorting",
    defaultMessage: "Sort box with label {label} to the crossdock area for Panda scanning.",
  },
  boxSortReceiveTitle: {
    id: "warehouse.arrivalScan.boxSortReceiveTitle",
    defaultMessage: "Receive Box",
  },
  boxSortReceiveMessage: {
    id: "warehouse.arrivalScan.boxSortReceiveMessage",
    defaultMessage: "Sort box with label {label} to a rack for receiving",
  },
  boxSortReturnsTitle: {
    id: "warehouse.arrivalScan.boxSortReturnsTitle",
    defaultMessage: "Returns Order Box",
  },
  boxSortReturnsMessage: {
    id: "warehouse.arrivalScan.boxSortReturnsMessage",
    defaultMessage: "Sort box with label {label} to a rack for processing returns",
  },
  boxSortUndeliveredTitle: {
    id: "warehouse.arrivalScan.boxSortUndeliveredTitle",
    defaultMessage: "Undelivered Box",
  },
  boxSortUndeliveredMessage: {
    id: "warehouse.arrivalScan.boxSortUndeliveredMessage",
    defaultMessage: "Sort box with label {label} to a rack for processing failed deliveries",
  },
  boxSortDefaultTitle: {
    id: "warehouse.arrivalScan.boxSortDefaultTitle",
    defaultMessage: "Unknown Box Type",
  },
  boxSortDefaultMessage: {
    id: "warehouse.arrivalScan.boxSortDefaultMessage",
    defaultMessage: "Re-try box scan",
  },
  routeToPalletArrivalScanTitle: {
    id: "warehouse.arrivalScan.routeToPalletArrivalScanTitle",
    defaultMessage: "{palletLabel} is a pallet label",
  },
  routeToPalletArrivalScanMessage: {
    id: "warehouse.arrivalScan.routeToPalletArrivalScanMessage",
    defaultMessage: "Re-direct to pallet arrival scan?",
  },
  confirmLotAndExpiryTitle: {
    id: "warehouse.receiving.confirmLotAndExpiryTitle",
    defaultMessage: "Confirm LOT and Expiration Date",
  },
  confirmLotTitle: {
    id: "warehouse.receiving.confirmLot",
    defaultMessage: "Confirm LOT",
  },
  confirmExpiryTitle: {
    id: "warehouse.receiving.confirmExpiry",
    defaultMessage: "Confirm Expiration Date",
  },
  reconfirmLotAndExpiryTitle: {
    id: "warehouse.receiving.reconfirmLotAndExpiryTitle",
    defaultMessage: "Reconfirm LOT and Expiration Date",
  },
  reconfirmLotAndExpiryText: {
    id: "warehouse.receiving.reconfirmLotAndExpiryText",
    defaultMessage: "The LOT and expiration date you entered do not match your last inputs. Please reconfirm.",
  },
  reconfirmLotTitle: {
    id: "warehouse.receiving.reconfirmLot",
    defaultMessage: "Reconfirm LOT",
  },
  reconfirmLotText: {
    id: "warehouse.receiving.reconfirmLotText",
    defaultMessage: "The LOT you entered does not match your last input. Please reconfirm.",
  },
  reconfirmExpiryTitle: {
    id: "warehouse.receiving.reconfirmExpiry",
    defaultMessage: "Reconfirm Expiration Date",
  },
  reconfirmExpiryText: {
    id: "warehouse.receiving.reconfirmExpiryText",
    defaultMessage: "The expiration date you entered does not match your last input. Please reconfirm.",
  },
  unclaimedReturnOrderTitle: {
    id: "warehouse.return.receiving.unclaimedReturnOrderTitle",
    defaultMessage: "Unclaimed Return Order",
  },
  unclaimedReturnOrderText: {
    id: "warehouse.return.receiving.unclaimedReturnOrderText",
    defaultMessage:
      "This return order has no associated seller. The first item scanned will be used to identify seller.",
  },
  returnReceiveExpectedItemsModalTitle: {
    id: "warehouse.return.receiving.returnReceiveExpectedItemsModalTitle",
    defaultMessage: "Return Order Details",
  },
  returnReceiveInspectionNotesModalTitle: {
    id: "warehouse.return.receiving.returnReceiveInspectionNotesModalTitle",
    defaultMessage: "Inspection notes",
  },
  returnReceiveOrderInspectionNotesInputPlaceholder: {
    id: "warehouse.return.receiving.returnReceiveOrderInspectionNotesInputPlaceholder",
    defaultMessage: "Add optional notes to the return order",
  },
  returnReceiveItemInspectionNotesInputPlaceholder: {
    id: "warehouse.return.receiving.returnReceiveItemInspectionNotesInputPlaceholder",
    defaultMessage: "Add optional notes for the inspected item",
  },
  returnReceiveNotesExceededCharacterLimitError: {
    id: "warehouse.return.receiving.returnReceiveNotesExceededCharacterLimitError",
    defaultMessage: "Notes cannot exceed {maxCharacterLimit} characters",
  },
  returnReceiveNotesSubmissionError: {
    id: "warehouse.return.receiving.returnReceiveNotesSubmissionError",
    defaultMessage: "Failed to save inspection notes. {error}",
  },
  returnReceiveSellableStandards: {
    id: "warehouse.return.receiving.returnReceiveSellableStandards",
    defaultMessage: "Sellable Standards",
  },
  returnReceivePrintBarcodeErrorTitle: {
    id: "warehouse.return.receiving.returnReceivePrintBarcodeErrorTitle",
    defaultMessage: "Failed to print barcode",
  },
  returnReceiveCloseReturnOrderTitle: {
    id: "warehouse.return.receiving.returnReceiveCloseReturnOrderTitle",
    defaultMessage: "Close Return Order?",
  },
  returnReceiveTrackingCode: {
    id: "warehouse.return.receiving.returnReceiveTrackingCode",
    defaultMessage: "Tracking code: {trackingCode}",
  },
  moreUnitsExpected: {
    id: "warehouse.return.receiving.moreUnitsExpected",
    defaultMessage: "More units expected",
  },
  returnReceiveNotAllExpectedItemsInspected: {
    id: "warehouse.return.receiving.returnReceiveNotAllExpectedItemsInspected",
    defaultMessage: "This package should have more units. Do you want to close return order: {trackingCode}?",
  },
  moveToProblemSolverArea: {
    id: "warehouse.receiving.moveToProblemSolverArea",
    defaultMessage: "Move box to the problem solver area.",
  },
  overReceive: {
    id: "warehouse.receiving.overReceive",
    defaultMessage: "Over Receive",
  },
  underReceive: {
    id: "warehouse.receiving.underReceive",
    defaultMessage: "Under Receive",
  },
  bulkReceiveIneligible: {
    id: "warehouse.receiving.bulkReceiveIneligible",
    defaultMessage: "Bulk receive ineligible. Receive {cdsku} individually.",
  },
});
