import { setProp } from "facility-commons/utils";
import { TabConfig, Tabs } from "facility-commons";
import * as React from "react";
import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";
import { TicketTabs } from "../../TicketDetails/TicketTabs";
import { TICKET_DETAIL_TABS_LABELS } from "./TicketDetailTabs.labels";

export const TicketDetailTabs: React.FC = () => {
  const { formatMessage } = useIntl();
  const [{ ticketTab }, setTicketCenterState] = useRecoilState(ticketCenterState);

  const ticketDetailTabs: TabConfig<TicketTabs>[] = Object.entries(TICKET_DETAIL_TABS_LABELS).map(
    ([tabKey, tabDescriptor]) => {
      return {
        value: tabKey as TicketTabs,
        label: formatMessage(tabDescriptor),
      };
    }
  );

  return (
    <Tabs<TicketTabs>
      activeTab={ticketTab}
      onClick={(newTab: TicketTabs) => setTicketCenterState(setProp("ticketTab", newTab))}
      tabs={ticketDetailTabs}
    />
  );
};
