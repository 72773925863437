import { defineMessages } from "react-intl";

export const PAGE_NOT_FOUND_LABELS = defineMessages({
  title: {
    id: "facilityCommons.pageNotFound.title",
    defaultMessage: "Page Not Found",
  },
  description: {
    id: "facilityCommons.pageNotFound.description",
    defaultMessage: "The page you are looking for does not exist.",
  },
  linkText: {
    id: "facilityCommons.pageNotFound.linkText",
    defaultMessage: "Go back to the homepage",
  },
});
