import React from "react";
import { Text, ThemeProps, Switch } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useDefaultLocationToggle } from "./useDefaultLocationToggle";
import { locationMessages } from "warehouse/receiving/content";
import { FormattedMessage } from "react-intl";
import { IllustrationCard } from "warehouse/common/components";

const CardContainer = styled(IllustrationCard)`
  display: grid;
  grid-template-columns: 92% 8%;
  align-items: center;
  margin: 0;
  border-bottom: 0;
`;

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
  color: ${theme.colors.NEUTRAL[500]};
`
);

export const DefaultLocationToggle: React.FC = () => {
  const { isDefaultLocationEnabled, onToggle } = useDefaultLocationToggle();
  return (
    <CardContainer>
      <StyledText appearance="INFO">
        <FormattedMessage {...locationMessages.defaultLocation} />
      </StyledText>
      <Switch data-testid={"default-location-switch"} checked={isDefaultLocationEnabled} onChange={onToggle}></Switch>
    </CardContainer>
  );
};
