import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIntl } from "react-intl";
import { Product } from "@deliverr/commons-clients";
import { v4 as uuid } from "uuid";

import { SoundFx } from "facility-commons/common/sfx";
import { userState } from "facility-commons/base/Auth/userState";
import { createDangerNotification, createSuccessNotification } from "facility-commons/utils/notificationUtils";
import { logStart, printPdf } from "facility-commons/utils";
import { StorageErrorCodes } from "warehouse/common/messages/storage/StorageErrorCodes";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingPOScan, createReceivingSKULC, createReceivingSKUScan } from "../storageReceivingCardCreators";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { markPOComplete } from "warehouse/common/modes/markPOComplete";
import { successModalIconLg } from "facility-commons";
import { isFBABookingType } from "warehouse/receiving/utils";

export const useReceivingSKUConfirmFlow = () => {
  const { formatMessage } = useIntl();
  const { warehouseId } = useRecoilValue(userState);
  const { resetNotifications, addAutoCloseNotification } = useCommonFlow();
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const { addFlowCard, hideAllFlowButtons, showFlowButton, resetFlowCards, transition } = useWarehouseFlow();
  const { inboundClient, productClient, storageClient } = useClientsWithAuth();
  const { showModal, hideAllModals } = useWarehouseModal();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_CONFIRM_RECEIVING_BACK_BUTTON);
      showFlowButton(StorageFlowButtonType.STORAGE_CONFIRM_RECEIVE);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const handleTransitionToSKUScan = () => {
    resetFlowCards();
    const skuDetails = {};
    setScanState((state) => ({
      ...state,
      poNum: scanState.poNum!,
      currentSKU: "",
      poDetails: {
        ...state.poDetails,
        totalReceivedQuantity: state.poDetails.totalReceivedQuantity + (state.currentSkuDetails?.qty ?? 0),
      },
      currentSkuDetails: skuDetails,
      scannedSKUs: (scanState.scannedSKUs ?? 0) + 1,
      dedupKey: uuid(),
    }));
    transition([
      {
        card: createReceivingSKUScan({}),
        notification: createSuccessNotification(
          formatMessage(STORAGE_RECEIVING_MESSAGES.skuReceivedSuccessNotificationTitle)
        ),
        sfx: SoundFx.SUCCESS,
        flash: "SUCCESS",
      },
    ]);
  };

  const updateProductCaseDims = async () => {
    const currentSkuDetails = scanState.currentSkuDetails;
    const { caseWeight, caseHeight, caseLength, caseWidth } = currentSkuDetails;
    if (caseWeight && caseHeight && caseLength && caseWidth) {
      const dsku = currentSkuDetails.dsku;
      const receivedCaseQty = currentSkuDetails.receivedCaseQty;
      const productCasePack = (currentSkuDetails as Product).productCasePacks?.find(
        (casePack) => casePack?.quantity === receivedCaseQty
      );
      const childDsku = productCasePack?.dsku;
      await productClient.updateProductCasePackByDsku(childDsku ?? dsku, {
        height: caseHeight,
        length: caseLength,
        width: caseWidth,
        weight: caseWeight,
        weightUnit: "lb",
        lengthUnit: "in",
      });
    }
  };

  const handlePOCompletion = async (poNum) => {
    try {
      await markPOComplete(poNum, warehouseId);
      transition([
        {
          card: createReceivingPOScan({}),
          notification: createSuccessNotification(formatMessage(STORAGE_RECEIVING_MESSAGES.poCompletionEventReceived)),
          sfx: SoundFx.SUCCESS,
          flash: "SUCCESS",
        },
      ]);
      hideAllModals();
    } catch (err) {
      addAutoCloseNotification(
        createDangerNotification(formatMessage(STORAGE_RECEIVING_MESSAGES.poCompletionEventFailed))
      );
    }
  };

  const handleSKUScan = () => {
    hideAllModals();
  };

  const handleNextState = async (currentSkuDetails) => {
    await updateProductCaseDims();
    const poDetails = scanState.poDetails;
    if (
      isFBABookingType(poDetails.bookingType) &&
      (poDetails?.totalReceivedQuantity ?? 0) + (currentSkuDetails.qty ?? 0) >= (poDetails?.totalExpectedQuantity ?? 0)
    ) {
      showModal(WarehouseModal.STORAGE_RECEIVING_MARK_PO_COMPLETE, {
        soundFx: SoundFx.INFO,
        iconProps: successModalIconLg,
        onSubmit: () => handlePOCompletion(scanState.poNum!),
        onCancel: () => handleSKUScan(),
      });
    } else {
      handleTransitionToSKUScan();
    }
  };

  const [{ loading }, handleSubmit] = useAsyncFn(async () => {
    const ctx = { fn: "useReceivingSKUConfirmFlow.handleConfirmSKUConfirm", poNum: scanState.poNum };
    logStart(ctx);
    try {
      const currentSkuDetails = scanState.currentSkuDetails;
      const response = await inboundClient.asnReceiveAndCommitMultiLocationV3(
        warehouseId,
        scanState.dedupKey!,
        scanState.poNum!.toString(),
        scanState.currentSKU!,
        [
          {
            location: currentSkuDetails?.location,
            sscc: currentSkuDetails?.LP,
            receivedDelta: currentSkuDetails.qty ?? 1,
          },
        ],
        scanState.requestBatchId!,
        scanState.currentSkuDetails?.formattedExpirationDate || null,
        scanState.currentSkuDetails?.lotNumber || null
      );
      if (response.error) {
        throw response;
      }
      // handle loading
      showModal(WarehouseModal.STORAGE_RECEIVING_LPN_CONTENT_LABEL, {
        text: formatMessage(STORAGE_RECEIVING_MESSAGES.lpnContentLabelText, {
          po: scanState.poNum!,
          location: scanState.currentSkuDetails.location,
          lp: scanState.currentSkuDetails.LP,
        }),
        onSubmit: () => handlePrint(scanState.poNum!, scanState.currentSkuDetails),
        onCompletion: () => handleNextState(currentSkuDetails),
      });
    } catch (err: any) {
      let text = "";
      if (err?.response) {
        const { subcode } = err.response;
        switch (subcode) {
          case StorageErrorCodes.ASN_NOT_FOUND:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.asnNotFound);
            break;
          case StorageErrorCodes.BARCODE_NOT_FOUND:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.barcodeNotFound, {
              code: scanState.currentSKU,
            });
            break;
          case StorageErrorCodes.UNUSABLE_LOCATION:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.unusableLocation, {
              location: scanState.currentSkuDetails.location,
            });
            break;
          case StorageErrorCodes.POSITIVE_VALUE:
            text = formatMessage(STORAGE_RECEIVING_MESSAGES.incorrectQuantity);
            break;
          default: {
            text = formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.contactSupportDescription);
            break;
          }
        }
      } else if (err?.error) {
        text = formatMessage(STORAGE_RECEIVING_MESSAGES.unrecognisedDescriptionText, {
          error: String(err.error?.message),
        });
      }
      showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
        title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
        text,
      });
    }
  });

  const [{ loading: printLoading }, handlePrint] = useAsyncFn(async (poNum: string, currentSkuDetails) => {
    const ctx = { fn: "useReceivingSKUConfirmFlow.handlePOPrint", poNum };
    logStart(ctx);
    try {
      const response = await storageClient.getStorageLPNContentLabel(
        poNum!.toString(),
        warehouseId,
        currentSkuDetails?.LP,
        currentSkuDetails?.location
      );
      printPdf(new Blob([response], { type: "application/pdf" }));
    } catch (err: any) {
      if (err?.response) {
        const { code, data } = err.response;
        switch (code) {
          case StorageErrorCodes.DATABASE_ERROR:
          case StorageErrorCodes.NOT_FOUND:
          default: {
            showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
              title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
              text: data?.message,
            });
            return;
          }
        }
      } else if (err?.error) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err.error?.message),
        });
      } else {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err),
        });
      }
    }
  });

  const [{ loading: backLoading }, handleBackClick] = useAsyncFn(async () => {
    addFlowCard(createReceivingSKULC({}));
  });

  return {
    scanState,
    handleSubmit,
    handleBackClick,
    loading,
    backLoading,
    handlePrint,
    printLoading,
  };
};
