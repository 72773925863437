import { receivingState } from "./ReceivingState";
import { selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";

export const getIsMultiSku = ({ get }) => {
  const { expectedSkus, receivedSkus } = get(receivingState);

  return expectedSkus > receivedSkus.length && receivedSkus.length > 0;
};
export const isMultiSkuState = selector({
  key: WarehouseStateKey.IS_MULTI_SKU,
  get: getIsMultiSku,
});
