import React, { useCallback } from "react";
import { useResetRecoilState } from "recoil";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { rsOutboundsStateAtom } from "./base/RSOutboundsState";
import { RSOutboundsFlowV2 } from "warehouse/common/flow/RSOutboundsFlowV2";
import { createOutboundsPackingShipmentScan } from "./rsOutboundsPackingCardCreators";

export const RSOutboundsPackingFlow: React.FC = () => {
  const { resetFlow } = useWarehouseFlow();
  const resetState = useResetRecoilState(rsOutboundsStateAtom);

  const onUnmount = useCallback(() => {
    resetState();
    resetFlow();
  }, [resetState, resetFlow]);

  return <RSOutboundsFlowV2 onMountCardData={createOutboundsPackingShipmentScan({})} onUnmount={onUnmount} />;
};
