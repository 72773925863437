import { IconV2Types, LocalizedMessage } from "@deliverr/ui";
import { FlashType } from "@deliverr/ui-facility/lib-facility/components/Flash";
import { atom } from "recoil";
import { PrepStateKey } from "./PrepStateKey";

export interface PrepFlashState {
  type: FlashType;
  icon?: IconV2Types;
  text?: LocalizedMessage;
}

const DEFAULT_FLASH_STATE: PrepFlashState = {
  type: undefined,
  icon: undefined,
  text: undefined,
};

export const prepFlashState = atom<PrepFlashState>({
  key: PrepStateKey.PREP_FLASH_STATE,
  default: DEFAULT_FLASH_STATE,
});
