import { useState } from "react";
import { useIntl } from "react-intl";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useInputErrorMessage } from "facility-commons/hooks/useInputErrorMessage";
import { log, logStart, printPdf } from "facility-commons/utils";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_PRINT_PALLET_LABELS_MESSAGES } from "warehouse/common/messages/storage";
import {
  DEFAULT_PALLET_LABEL_COUNT,
  MAX_PALLET_LABEL_COUNT,
  MIN_PALLET_LABEL_COUNT,
} from "warehouse/common/messages/storage/PrintPalletLabels.const";

export function useStoragePrintPalletLabels() {
  const { successResponse, handleUnknownError } = useWarehouseFlow();
  const { storageClient } = useClientsWithAuth();
  const { formatMessage } = useIntl();
  const [palletCount, setPalletCount] = useState<number>(DEFAULT_PALLET_LABEL_COUNT);
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const updatePalletCount = (eventValue: React.ChangeEvent<HTMLInputElement>) => {
    setPalletCount(Number(eventValue.target.value));
  };

  const requestPalletLabels = async (): Promise<void> => {
    const ctx = logStart({ fn: "requestPalletLabels", palletCount });

    const palletCountNum = Number(palletCount);

    if (palletCountNum > MAX_PALLET_LABEL_COUNT) {
      inputError(
        formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.maxLabelCountMessage, {
          maxLabelCount: MAX_PALLET_LABEL_COUNT,
        })
      );
      return;
    }

    if (palletCountNum < MIN_PALLET_LABEL_COUNT) {
      inputError(
        formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.minLabelCountMessage, {
          minLabelCount: MIN_PALLET_LABEL_COUNT,
        })
      );
      return;
    }

    try {
      setLoading(true);
      const pdf = await storageClient.printStoragePalletLabelsPdf(palletCountNum);
      printPdf(pdf);
      log(ctx, "generated pallet labels PDF");
      successResponse();
      setPalletCount(DEFAULT_PALLET_LABEL_COUNT);
    } catch (error) {
      handleUnknownError(ctx, error);
      inputError(formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.printPalletLabelsError));
    } finally {
      setLoading(false);
    }
  };
  const printDisabled = loading || !palletCount || Number.isNaN(palletCount) || palletCount < MIN_PALLET_LABEL_COUNT;

  return {
    requestPalletLabels: resetErrorOnExecution(requestPalletLabels),
    value: palletCount,
    updatePalletCount,
    loading,
    printDisabled,
    inputErrorMessage,
  };
}
