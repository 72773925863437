import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletReceiveNotFoundErrorMessageKey {
  PALLET_RECEIVE_NOT_FOUND = "PALLET_RECEIVE_NOT_FOUND",
}

export const PALLET_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  PalletReceiveNotFoundErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletReceiveNotFoundErrorMessageKey.PALLET_RECEIVE_NOT_FOUND]: {
    id: "warehouse.receiving.palletItemNotFound",
    defaultMessage: "Could not find pallet receive information for requestId {requestId}",
  },
});
