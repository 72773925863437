import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { Appearance } from "@deliverr/ui";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { OVERVIEW_CARD_LABELS } from "./OverviewCard.labels";
import { DashboardDetailActionSection, DashboardDetailActionSectionProps } from "facility-commons";

export interface OverviewCardActionSectionProps extends Pick<DashboardDetailActionSectionProps, "status"> {
  showDeleteModal: () => void;
}

export const OverviewCardActionSection: React.FC<OverviewCardActionSectionProps> = ({ status, showDeleteModal }) => {
  return (
    <DashboardDetailActionSection
      status={status}
      actions={[
        {
          statusRequired: TicketCenterNonComplianceCaseStatus.CREATED,
          label: <FormattedMessage {...OVERVIEW_CARD_LABELS.actions.deleteTicket} />,
          onClick: showDeleteModal,
          appearance: Appearance.DANGER,
          secondary: true,
        },
      ]}
    />
  );
};
