import React from "react";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";

export const useStorageReceivingLPNContentLabel = (modal) => {
  const { hideModal } = useWarehouseModal();

  const [loading, setLoading] = React.useState(false);

  const handleSuccess = async () => {
    try {
      setLoading(true);

      await modal?.onSubmit?.();
      hideModal(WarehouseModal.STORAGE_RECEIVING_LPN_CONTENT_LABEL);
      modal?.onCompletion?.();
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    modal?.onCancel?.();
    hideModal(WarehouseModal.STORAGE_RECEIVING_LPN_CONTENT_LABEL);
    modal?.onCompletion?.();
  };

  return { handleCancel, hideModal, loading, setLoading, handleSuccess };
};
