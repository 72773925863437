import React from "react";

import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";

import { FlowVisibleNotifications } from "facility-commons/flow/FlowVisibleNotifications";
import { notificationsState } from "facility-commons/flow/flowState";
import { FullStack } from "facility-commons/components/emotion";
import { StorageLinkCard } from "warehouse/receiving/components/cards/StorageLinkCard";
import { useStorageTicketCenter } from "./useStorageTicketCenter";

const CardContainer = styled.section<ThemeProps>`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
`;

export const StorageTicketCenter: React.FC = () => {
  const { title, linkMap } = useStorageTicketCenter();
  const notifications = useRecoilValue(notificationsState);

  return (
    <FullStack center>
      <CardContainer>
        <StorageLinkCard linkMap={linkMap} title={title} />
      </CardContainer>
      <FlowVisibleNotifications notifications={notifications.slice(-1)} />
    </FullStack>
  );
};
