import { logStart } from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useRouter } from "facility-commons/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { userState } from "facility-commons/base/Auth/userState";
import { useRecoilValue } from "recoil";
import { currentSkuState, receivingState } from "warehouse/receiving/ReceivingState";

export const useProceedToReviewButton = () => {
  const { expectedSkus, receivedSkus, requestBatchId } = useRecoilValue(receivingState);
  const { warehouseId } = useRecoilValue(userState);
  const { showModal } = useWarehouseModal();
  const router = useRouter();

  const { cdsku } = useRecoilValue(currentSkuState);
  // blocks navigation to review if not enough skus have been scanned
  const proceedToReview = () => {
    logStart({
      fn: "useReceivingFlow.proceedToReview",
      warehouseId,
      requestBatchId,
      cdsku,
      expectedSkus,
      receivedSkus,
    });
    const proceedBasedOnFlow = () => router.push(ReceivingPath.CDSKU);
    const numSkusReceived = receivedSkus.length;
    const notEnoughSkusReceived = expectedSkus !== numSkusReceived;

    if (notEnoughSkusReceived) {
      showModal(WarehouseModal.RECEIVING_UNEXPECTED_SKUS, {
        expectedSkus,
        receivedSkus: numSkusReceived,
        proceedToReview: proceedBasedOnFlow,
      });
    } else {
      proceedBasedOnFlow();
    }
  };

  return {
    proceedToReview,
    receivedSkus,
  };
};
