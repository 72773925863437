import { Appearance, Button, Stack, Text, ThemeProps } from "@deliverr/ui";
import { groupBy, map, sumBy } from "lodash";

import { ModalTemplate } from "facility-commons";
import { ProductMinimal } from "@deliverr/returns-client";
import React from "react";
import styled from "@emotion/styled";
import { useReturnReceiveExpectedItemsModal } from "./useReturnReceiveExpectedItemsModal";

const QtyListItem = styled.li<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  padding: ${theme.spacing.S5} ${theme.spacing.S4};

  &:last-child {
    border-bottom: none;
  }
`
);

const ListItem = styled.li<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const StackFull = styled(Stack)`
  width: 100%;
  overflow: auto;
`;

const StyledPrintButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  margin: auto ${theme.spacing.S2} auto 0;
`
);

export interface ReturnReceiveSkuLineItemProps {
  barcode: string;
  name?: string;
  quantity?: number;
  dsku?: string;
}

export const ReturnReceiveSkuLineItem: React.FC<ReturnReceiveSkuLineItemProps> = ({
  barcode,
  name,
  dsku,
  quantity,
}) => {
  return (
    <Stack gap="S1">
      <Text bold>{barcode}</Text>
      {dsku && (
        <Text appearance="INFO" size="caption">
          {dsku}
        </Text>
      )}
      {name && (
        <Text appearance="INFO" size="caption">
          {name}
        </Text>
      )}
      {quantity && (
        <Text appearance="INFO" size="caption">
          {`Qty: ${quantity}`}
        </Text>
      )}
    </Stack>
  );
};

export const ReturnReceiveExpectedItemsModal: React.FC = () => {
  const {
    expectedItems,
    expectedItemsProductDetails,
    modalTitle,
    buttonText,
    barcodePrintError,
    loading,
    findBarcodeForDskuAndPrint,
    closeModal,
  } = useReturnReceiveExpectedItemsModal();

  return (
    <ModalTemplate
      title={modalTitle}
      onExitClick={closeModal}
      isContentFullWidth
      content={
        <StackFull gap="S4">
          {map(
            groupBy(expectedItems, (item) => item.dsku),
            (value, dsku) => {
              const product = expectedItemsProductDetails[dsku] || ({} as ProductMinimal);
              const barcodes = product.barcodes?.join(", ") || "";
              const key = `return-receive-list-item-${dsku}`;
              return (
                <ListItem>
                  <QtyListItem key={key} data-testid={key}>
                    <ReturnReceiveSkuLineItem
                      barcode={barcodes}
                      dsku={dsku}
                      quantity={sumBy(value, (item) => item.quantity)}
                      name={product.name}
                    />
                  </QtyListItem>
                  <StyledPrintButton
                    loading={loading}
                    onClick={() => {
                      findBarcodeForDskuAndPrint(dsku);
                    }}
                    secondary
                  >
                    {buttonText.print}
                  </StyledPrintButton>
                </ListItem>
              );
            }
          )}
        </StackFull>
      }
    >
      <Stack gap="S4">
        <Text appearance={Appearance.DANGER} size={"label"}>
          {barcodePrintError}
        </Text>
        <Button block data-testid="return-receive-expected-items-modal-close-button" onClick={closeModal} secondary>
          {buttonText.close}
        </Button>
      </Stack>
    </ModalTemplate>
  );
};
