import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { NonComplianceTypeMessages } from "warehouse/ticket-center/components/ListViewCard/NonComplianceTypeMessages";
import { useIntl } from "react-intl";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";
import { NcFilterCategoriesState } from "warehouse/ticket-center/common/getFilterCategoriesList";
import { useWarehouseModal } from "warehouse/modal";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { ListViewCardProps } from "warehouse/ticket-center/components";
import { setProp } from "facility-commons";
import { commonMessages } from "facility-commons/labels";

export const useFilterModal = () => {
  const { formatMessage } = useIntl();
  const DEFAULT = { value: "ALL", label: formatMessage(commonMessages.showAll) };

  const [{ ticketList, filter, filteredTicketList }, setTicketCenterState] = useRecoilState(ticketCenterState);

  const [filteredList, setFilteredList] = useState<ListViewCardProps[]>(filteredTicketList);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const { hideAllModals } = useWarehouseModal();

  const filterMenuOptions = useRecoilValue(NcFilterCategoriesState);

  const options = filterMenuOptions.map((v) => ({
    value: `${v}`,
    label: formatMessage(NonComplianceTypeMessages[v]),
  }));
  const closeMenu = () => hideAllModals();

  const handleFilterChange = (e) => {
    const filterValue = e.target.value;
    setCurrentFilter(filterValue);

    if (filterValue in NonComplianceType) {
      setFilteredList(ticketList.filter((item) => item.type === filterValue));
    } else {
      setFilteredList(ticketList);
    }
  };

  const onSubmit = () => {
    setTicketCenterState(setProp("filter", currentFilter));
    setTicketCenterState(setProp("filteredTicketList", filteredList));

    closeMenu();
  };

  return {
    formatMessage,
    options: [DEFAULT, ...options],
    filter: currentFilter,
    ticketsCount: filteredList.length || ticketList.length,
    handleFilterChange,
    closeMenu,
    onSubmit,
  };
};
