import { Card, CardProps, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

const LinkCard = styled(Card)<ThemeProps<CardProps>>(
  ({ theme }) => `
  border-radius: ${theme.border.radius.R5};
  box-shadow: none;
  background-color: ${theme.colors.NEUTRAL["00"]};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[100]};
  display: flex;
  margin-bottom: ${theme.spacing.S4};
  margin-left: auto;
  margin-right: auto;
  padding: ${theme.spacing.S3};
  width: 100%;
`
).withComponent(Link);

export interface DashboardListItemContainerProps extends Pick<LinkProps, "to"> {
  onClick?: () => void | Promise<void>;
}

export const DashboardListItemContainer: React.FC<React.PropsWithChildren<DashboardListItemContainerProps>> = ({
  to,
  onClick,
  children,
}) => {
  return (
    <LinkCard to={to} onClick={onClick}>
      {children}
    </LinkCard>
  );
};
