import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";

import { trackingMessages } from "../../content/tracking";
import { useRouter } from "facility-commons/hooks";
import { genericOnScannerInputChange } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { NCRoutes } from "../../base/routes";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const useBoxInformationCard = () => {
  const [trackingCodeError, setTrackingCodeError] = useState<string>("");
  const [trackingInfoSubmitted, setTrackingInfoSubmitted] = useState<boolean>(false);
  const { trackingCode = "" } = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();

  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const { errorResponse, successResponse } = useCommonFlow();

  useEffect(() => {
    if (trackingInfoSubmitted) {
      push(NCRoutes.BARCODE);
    }
  }, [push, trackingInfoSubmitted]);

  const updateTrackingInfo = (newTracking: string) => {
    setTrackingCodeError("");
    updateCurrentProduct("trackingCode", newTracking);
  };

  const [{ loading }, handleSubmit] = useAsyncFn(async (newTracking: string) => {
    if (!newTracking) {
      errorResponse();
      setTrackingCodeError(formatMessage(trackingMessages.required));
    } else {
      successResponse();
      setTrackingInfoSubmitted(true);
    }
  });

  const handleChange = genericOnScannerInputChange(trackingCode, updateTrackingInfo, handleSubmit, "upper");

  return {
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToBarcodeStep: () => push(NCRoutes.BARCODE),
    trackingCodeError,
    trackingCode,
  };
};
