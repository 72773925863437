import { useState } from "react";
import { useAsyncFn } from "react-use";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";

import { createSuccessNotification, logError, logStart } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { SoundFx } from "facility-commons/common/sfx";
import { useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKUScan } from "../storageReceivingCardCreators";
import { fetchStoragePODetails } from "warehouse/common/modes/fetchStoragePODetails";

export const useReceivingPOScanFlow = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const { warehouseId } = useRecoilValue(userState);
  const [scanError, setScanError] = useState<string>("");
  const [poNumber, setPoNumber] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const { transition } = useWarehouseFlow();

  const handleChange = (poNum) => {
    setPoNumber(poNum);
  };

  const [{ loading }, handleSubmit] = useAsyncFn(async (poNumParam: string) => {
    const poNum = poNumParam.trim();
    const handleSubmitReceivePOScan = async () => {
      const ctx = { fn: "useReceivingPOScanFlow.handleSubmitReceivePOScan", poNum };
      logStart(ctx);
      try {
        const {
          asnId,
          palletCount,
          itemsCount,
          searchTerms,
          productAtps,
          productDetails,
          arrivedAt,
          arrivalExpectedAt,
          receivingStartPalletCount,
          sellerId,
          bookingID,
          bookingType,
          prepOption,
          totalExpectedQuantity,
          totalReceivedQuantity,
        } = await fetchStoragePODetails(
          warehouseId,
          poNum,
          scanState?.requestBatchId ?? "",
          showModal,
          formatMessage,
          true
        );
        if (!productDetails) {
          return;
        } else if (productDetails.error) {
          throw productDetails;
        }
        setScanState((state) => {
          const updatedState = { ...state };
          updatedState.poNum = asnId;
          updatedState.poDetails = {
            palletCount,
            numberOfSkus: itemsCount,
            numberOfBoxes: 0,
            searchTerms,
            arrivedAt,
            arrivalExpectedAt,
            receivingStartPalletCount,
            bookingID,
            bookingType,
            prepOption,
            totalExpectedQuantity,
            totalReceivedQuantity,
          };
          updatedState.skus = productDetails || {};
          updatedState.scannedSKUs = 0;
          updatedState.dskusATP = productAtps;
          updatedState.sellerId = sellerId;
          return updatedState;
        });
        transition([
          {
            card: createReceivingSKUScan({}),
            notification: createSuccessNotification(
              formatMessage(STORAGE_RECEIVING_MESSAGES.poScanSuccessNotificationTitle)
            ),
            sfx: SoundFx.SUCCESS,
            flash: "SUCCESS",
          },
        ]);
      } catch (err: any) {
        logError(ctx, err);
        handleChange("");
      }
    };
    return handleSubmitReceivePOScan();
  });

  const handleClick = () => {
    setScanError("");
    handleSubmit(poNumber ?? "");
  };

  return { poNumber, scanError, handleChange, handleSubmit, handleClick, loading };
};
