import { LogContext } from "@deliverr/ui-logging";
import { uniqBy } from "lodash";
import { useAsyncFn } from "react-use";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { createDangerNotification, logError, logStart, log, setProp } from "facility-commons/utils";
import { isPalletReceiveFlow } from "warehouse/receiving/utils/receivingFlowType";
import { userState } from "facility-commons/base/Auth/userState";
import {
  bulkReceiveState,
  currentSkuState,
  isBulkReceiveState,
  isExpeditedFlowEligibleState,
  palletReceiveState,
  receiveFlowTypeState,
  receivingState,
  receivingMisMatchState,
  cdskuState,
} from "warehouse/receiving/ReceivingState";
import {
  ReceiveError,
  ReceivingData,
  ReceivingFlowType,
  SkuReceivingDetails,
} from "warehouse/receiving/ReceivingState/Types";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useClearSkuData, useInitializeReceivingData } from "../hooks";
import { useRouter } from "facility-commons/hooks";
import { createCommitSuccessNotification } from "warehouse/receiving/base";
import { createBulkBoxScanCompletionNotification } from "./receivingNotifications";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { LocationReceivedQuantity, ReceiveByLocation } from "@deliverr/legacy-inbound-client";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { currentProductLotFefoDetailsState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { WarehousePortalRoutes } from "warehouse/routes";
import { COMMON_LABELS } from "../components/cards/warehouse.labels";
import { useIntl } from "react-intl";

/**
 * Helper hook used to provide some of the commonly used functions to Receiving.
 * Also provides some derived values from receiving state - similar to a selector
 */

export const useReceivingFlow = () => {
  const { addAutoCloseNotification, addNotification, errorResponse, playSfx } = useCommonFlow();
  const { inboundClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const isPalletReceive = isPalletReceiveFlow(receivingFlow);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [palletReceiveData] = useRecoilState(palletReceiveState);
  const [currentSku, setCurrentSku] = useRecoilState(currentSkuState);
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const router = useRouter();
  const { isBulkEligible } = useRecoilValue(bulkReceiveState);
  const {
    asnId,
    expectedSkus,
    identicalBoxes,
    receivedSkus,
    requestBatchId,
    consolidationLabel,
    lotNumber,
    cdskusToReceive,
    expirationDate,
    isSubmittingReceiveRequest,
    isReceiveWithoutCdsku,
    dedupKey,
    receiveMoreUnitsFromBox,
    previousReceives,
    wasReceived,
    images,
  } = receivingData;
  const [cdskuData, setCdskuData] = useRecoilState(cdskuState);
  const { expectedNumUnitsInBox, receiveCount, isHighRisk: isHighRiskBox } = cdskuData;
  const { palletLabel, isBulkPalletReceiveEligible } = palletReceiveData;
  const { showModal, hideAllModals } = useWarehouseModal();
  const { formatMessage } = useIntl();

  const { barcode, cdsku, location, quantity, dsku, previouslyReceivedQuantity } = currentSku;
  const initializeReceivingData = useInitializeReceivingData(consolidationLabel);
  const clearSkuData = useClearSkuData(true);

  const totalUnits = identicalBoxes * quantity;
  const receivedUnits = receivedSkus.reduce((total, currentItem) => total + (currentItem.quantity || 0), 0);
  const isExpeditedBulkFlowEligible = useRecoilValue(isExpeditedFlowEligibleState);

  const isBulkReceive = useRecoilValue(isBulkReceiveState);

  const isMismatch = useRecoilValue(receivingMisMatchState);
  const { isFefoEnabled, isLotEnabled } = useRecoilValue(currentProductLotFefoDetailsState);

  const hideModalAndResetLocation = () => {
    updateCurrentSku("location", "");
    setReceivingData(setProp("receivingError", ""));
    router.push(ReceivingPath.LOCATION);
    hideAllModals();
  };

  // the typing of value arg will dynamically change based on the key name passed in as the prop arg
  const updateCurrentSku = <K extends keyof SkuReceivingDetails>(prop: K, value: SkuReceivingDetails[K]) =>
    setCurrentSku(setProp(prop, value));

  const handleUnknownError = (ctx: LogContext, error: any): void => {
    logError(ctx, error);
    errorResponse();
    addAutoCloseNotification(createDangerNotification(formatMessage(COMMON_LABELS.UNEXPECTED_ERROR)));
    setReceivingData((state: ReceivingData) => ({
      ...state,
      isSubmittingReceiveRequest: false,
    }));
  };

  const useRerouteToLotFefoNC = () => {
    useEffect(() => {
      // isMismatch indicates there was a problem with a lot/exp receive and
      // receiver will be redirected to create a NC ticket
      if (isMismatch) {
        // if user is on the ConfirmationCard then by proxy we know that the product is lot, fefo, or lot/fefo enabled
        // the only way to know specifically which is enabled is via a call to product service in the useSkuCard
        // however, users are able to continue with receiving even if this call fails and thus
        // isLotEnabled and isFefoEnabled may potentially never get set correctly. In this case, we have no choice but to
        // start the ticket creation process from the start
        if (isLotEnabled) {
          router.push(WarehousePortalRoutes.NC_LOT_INPUT);
        } else if (isFefoEnabled) {
          router.push(WarehousePortalRoutes.NC_EXPIRATION_INPUT);
        } else {
          router.push(WarehousePortalRoutes.NC_CDSKU);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMismatch]);
  };

  const [receiveUnitsState, receiveUnits] = useAsyncFn(async () => {
    const ctx = logStart({
      fn: "useReceivingFlow.receiveUnits",
      warehouseId,
      cdsku,
      barcode,
      quantity,
      location,
      requestBatchId,
      asnId,
      identicalBoxes,
      isSubmittingReceiveRequest,
      dedupKey,
      cdskuData,
      receiveCount,
    });

    if (isSubmittingReceiveRequest) {
      log(ctx, "extra receive submission prevented");
      return;
    }

    // We log if this happens so we can alert on it, but we won't block requests for it currently
    // since the endpoints are backwards compatible to before this was introduced
    if (!dedupKey) {
      log(ctx, "missing dedup key");
    }

    // this should never happen
    if (isPalletReceive && !asnId) {
      log({ ...ctx }, "asnId not found in pallet receive flow");
    }

    // captures location from scan event b/c useState doesnt update currentSku before this point
    const SkuToReceive = { ...currentSku, location };
    const newReceivedSkus = uniqBy([...receivedSkus, SkuToReceive], "barcode");
    const isMultiSku = expectedSkus > newReceivedSkus.length;

    // we keep track of the units received within each returns box receive flow in order to know
    // if we should continue receiving or end the flow
    // each receive request should only be 1 unit
    const newReceiveCount = receiveCount + quantity;

    const onCommitSuccess = (response) => {
      log({ ...ctx, response }, "successful response from receive api");
      playSfx(SoundFx.SUCCESS);

      setCdskuData(setProp("receiveCount", newReceiveCount));

      addNotification(
        isBulkReceive
          ? createBulkBoxScanCompletionNotification({ boxCount: identicalBoxes, totalUnits, received: true })
          : createCommitSuccessNotification({
              barcode,
              dsku,
              quantity:
                isPalletReceive || isExpeditedBulkFlowEligible
                  ? totalUnits
                  : isHighRiskBox
                  ? newReceiveCount
                  : quantity,
              completeMultiSku: expectedSkus > 1 && newReceivedSkus.length === expectedSkus,
              boxCount: isPalletReceive || isExpeditedBulkFlowEligible ? identicalBoxes : undefined,
              loc: location,
              expirationDate,
              lotNumber,
            })
      );
      if (!receiveMoreUnitsFromBox) {
        setWarehouseAppState(setProp("pageSubtitle", ""));
      }

      const shouldPersistState = (isMultiSku && !isReceiveWithoutCdsku) || receiveMoreUnitsFromBox || isHighRiskBox;
      // we normally get previousReceives from the validateBarcode endpoint, but we need to manually update previousReceives
      // on the FE when receiving a multi-fefo/lot box since we only hit that endpoint once even if we are doing multiple receives.
      const receiveAgg = previousReceives
        ? [...previousReceives, { location, receivedQty: quantity }]
        : [{ location, receivedQty: quantity }];

      const updatedPreviousReceives: LocationReceivedQuantity[] | null = Object.values(
        receiveAgg.reduce((acc, receive: LocationReceivedQuantity | ReceiveByLocation) => {
          const location = receive.location;
          const receivedQty =
            (receive as LocationReceivedQuantity).receivedQty || (receive as ReceiveByLocation).receivedDelta;
          acc[location] = {
            location,
            receivedQty: acc[location] ? acc[location].receivedQty + receivedQty : receivedQty,
          };
          return acc;
        }, {})
      );

      const updatedPreviousReceiveQuantity = updatedPreviousReceives.reduce((acc, receive) => {
        return acc + receive.receivedQty;
      }, 0);

      if (shouldPersistState) {
        setReceivingData((state: ReceivingData) => ({
          ...state,
          receivedSkus: newReceivedSkus,
          isSubmittingReceiveRequest: false,
          previousReceives: receiveMoreUnitsFromBox ? updatedPreviousReceives : [],
          lotNumber: undefined,
          expirationDate: undefined,
          isNewReceive: false,
        }));
        // for multi-fefo boxes we need to manually update previouslyReceivedQuantity since we don't scan sku again
        updateCurrentSku(
          "previouslyReceivedQuantity",
          previouslyReceivedQuantity
            ? previouslyReceivedQuantity + updatedPreviousReceiveQuantity
            : updatedPreviousReceiveQuantity
        );
        updateCurrentSku("location", "");
        updateCurrentSku("quantity", 0);
      } else {
        clearSkuData();
        initializeReceivingData();
      }
    };

    const handleError = (err) => {
      log({ ...ctx, err }, "error response from receive api");
      errorResponse();
      // in case of error, the receive did not go through and we want to allow the user to try again
      setReceivingData(setProp("isSubmittingReceiveRequest", false));
      if (err.subcode === ReceiveError.LOT_EXP_CONFLICT) {
        setReceivingData(setProp("receivingError", res.error.message));
        return showModal(WarehouseModal.INVALID_LOCATION, {
          onConfirm: hideModalAndResetLocation,
        });
      }
    };

    const logIfDuplicateReceiveRequest = (res, receiveType: ReceivingFlowType, ctx: LogContext) => {
      const duplicateReceiveRequest = res.error && res.error.subcode === ReceiveError.DUPLICATE_RECEIVE;
      if (duplicateReceiveRequest) {
        let logMessage = "duplicate receive";
        switch (receiveType) {
          case ReceivingFlowType.BOX_RECEIVING:
            logMessage = "duplicate box receive";
            break;
          case ReceivingFlowType.PALLET_RECEIVING:
            logMessage = "duplicate pallet receive";
            break;
          case ReceivingFlowType.CONSOLIDATION_RECEIVING:
            logMessage = "duplicate consolidation receive";
            break;
        }
        log({ ctx, ...res }, logMessage);
      }
    };

    let res;
    try {
      setReceivingData((state: ReceivingData) => ({
        ...state,
        isSubmittingReceiveRequest: true,
      }));

      switch (receivingFlow) {
        case ReceivingFlowType.BOX_RECEIVING:
          if (isBulkReceive || isExpeditedBulkFlowEligible) {
            res = await inboundClient.bulkReceiveAndCommitMultiLocation(
              requestBatchId,
              dedupKey,
              warehouseId,
              cdskusToReceive.length ? cdskusToReceive : [cdsku],
              dsku,
              [{ location, receivedDelta: totalUnits }],
              identicalBoxes,
              quantity,
              expirationDate || null,
              lotNumber || null
            );
          } else if (isReceiveWithoutCdsku) {
            res = await inboundClient.asnReceiveAndCommitMultiLocation(
              warehouseId,
              dedupKey,
              asnId!.toString(),
              dsku,
              [{ location, receivedDelta: totalUnits }],
              requestBatchId,
              expirationDate,
              lotNumber
            );
          } else {
            res = await inboundClient.boxReceiveAndCommitMultiLocation(
              requestBatchId,
              dedupKey,
              warehouseId,
              cdsku,
              dsku,
              [{ location, receivedDelta: totalUnits }],
              images,
              expirationDate || null,
              lotNumber || null
            );
          }

          // There are multiple reasons for duplicate requests
          // 1. User spams the submit button (this has been fixed #1396)
          // 2. User submits a receive and the clicks back and submits again (this has been fixed #1413)
          // 3. User submits a receive, a network error occurs but the receive is handled on the BE
          //    the FE shows an "unknown error" msg and user submits receive again because they are still on the location card
          // 4. User submits a receive and the app for unknown reason submits two API requests.
          //    The cause and verification of this is still undetermined, though this case may just actually just be #1 or #2
          // For case 3, 4 we want to the UX to be such that the receive flow remains unblocked.
          // So we will move forward in the flow as if the receive was successful (b/c it was at one point)
          // Setting the correct receive counts to state was a concern since we don't want to double aggregate
          // but should theoretically not be an issue b / c
          // in case 3, the new state hasn't been set yet even though the receive was handled in the B/E.
          // in case 4, the two API calls most likely happen under the same instance of "receiveUnits"
          // and so we would just be setting the state twice to the same values.
          const duplicateBoxReceiveRequest = res.error && res.error.subcode === ReceiveError.DUPLICATE_RECEIVE;

          if ((res && !res.error) || duplicateBoxReceiveRequest) {
            logIfDuplicateReceiveRequest(res, ReceivingFlowType.BOX_RECEIVING, ctx);
            onCommitSuccess(res);

            const receivedAllExpectedUnitsInUnitByUnitFlow = isHighRiskBox && newReceiveCount >= expectedNumUnitsInBox;
            const receivingPreviouslyReceivedBoxInUnitByUnitFlow = isHighRiskBox && wasReceived;
            if (
              receiveMoreUnitsFromBox || // for multi-fefo/lot flow
              receivedAllExpectedUnitsInUnitByUnitFlow ||
              receivingPreviouslyReceivedBoxInUnitByUnitFlow
            ) {
              router.push(ReceivingPath.RECEIVE_MORE_UNITS_CONFIRMATION);
            } else if (isMultiSku || (isHighRiskBox && newReceiveCount < expectedNumUnitsInBox)) {
              router.push(ReceivingPath.SKU);
            } else {
              router.push(ReceivingPath.CDSKU);
            }
            return;
          }
          break;

        case ReceivingFlowType.PALLET_RECEIVING:
          if (isBulkPalletReceiveEligible && palletLabel) {
            res = await inboundClient.receivingAppBulkPalletReceiveAndCommit(
              requestBatchId,
              dedupKey,
              warehouseId,
              palletLabel,
              dsku,
              [{ location, receivedDelta: totalUnits }],
              identicalBoxes,
              quantity,
              lotNumber || null,
              expirationDate || null
            );
          } else {
            res = await inboundClient.palletReceiveAndCommitMultiLocation(
              requestBatchId,
              dedupKey,
              warehouseId,
              asnId!,
              dsku,
              [{ location, receivedDelta: totalUnits }],
              lotNumber || null,
              identicalBoxes,
              quantity
            );
          }

          const duplicatePalletReceiveRequest = res.error && res.error.subcode === ReceiveError.DUPLICATE_RECEIVE;

          if ((res && !res.error) || duplicatePalletReceiveRequest) {
            logIfDuplicateReceiveRequest(res, ReceivingFlowType.PALLET_RECEIVING, ctx);
            onCommitSuccess(res);
            router.push(ReceivingPath.PALLET);
            return;
          }

          break;

        case ReceivingFlowType.CONSOLIDATION_RECEIVING:
          res = await inboundClient.consolidationReceiveAndCommitMultiLocationV2(
            requestBatchId,
            dedupKey,
            consolidationLabel!,
            warehouseId,
            dsku,
            [{ location, receivedDelta: totalUnits }],
            expirationDate || null,
            lotNumber || null
          );

          const duplicateConsolidationReceiveRequest =
            res.error && res.error.subcode === ReceiveError.DUPLICATE_RECEIVE;

          if ((res && !res.error) || duplicateConsolidationReceiveRequest) {
            logIfDuplicateReceiveRequest(res, ReceivingFlowType.CONSOLIDATION_RECEIVING, ctx);
            onCommitSuccess(res);
            // routing them back to SKU page while keeping the scanned CID in place so they can continue receiving against it
            router.push(ReceivingPath.SKU);
            setWarehouseAppState(setProp("pageSubtitle", consolidationLabel!));
            return;
          }

          break;

        default:
          throw new Error("Unknown receive type");
      }
      if (res && res.error) {
        handleError(res.error);
      }
    } catch (e) {
      handleUnknownError(ctx, e);
    }

    setReceivingData((state: ReceivingData) => ({
      ...state,
      isSubmittingReceiveRequest: false,
    }));

    // let wherever this is called handle the response
    return res;
  }, [
    warehouseId,
    cdsku,
    barcode,
    quantity,
    requestBatchId,
    receivingFlow,
    receivingData,
    isSubmittingReceiveRequest,
    currentSku,
    cdskuData,
    receiveCount,
    receiveMoreUnitsFromBox,
    wasReceived,
  ]);
  return {
    handleUnknownError,
    // multisku boxes, pallets, and boxes that have already been received cant be bulk received
    isBulkEligible,
    // pallets cannot be bulked received - an asnId should only ever be present in a pallet receive
    isBulkReceive,
    receivedUnits,
    receiveUnits,
    receiveUnitsState,
    updateCurrentSku,
    useRerouteToLotFefoNC,
  };
};
