import { defineMessages, MessageDescriptor } from "react-intl";

export enum BulkReceiveNotFoundErrorErrorMessageKey {
  BULK_RECEIVE_NOT_FOUND = "BULK_RECEIVE_NOT_FOUND",
}

export const BULK_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  BulkReceiveNotFoundErrorErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [BulkReceiveNotFoundErrorErrorMessageKey.BULK_RECEIVE_NOT_FOUND]: {
    id: "warehouse.receiving.bulkReceiveNotFoundError",
    defaultMessage: "Could not find bulk receive information for requestId {requestId}.",
  },
});
