import React from "react";
import { Input } from "@deliverr/ui";
import styled from "@emotion/styled";
import { isNaN, noop } from "lodash";
import { useIntl, MessageDescriptor } from "react-intl";

import { FlowCard, FlowCardProps } from "./FlowCard";
import { LoadingSpinner } from "facility-commons/components";

export interface NumericInputCardProps extends FlowCardProps {
  disabled?: boolean;
  errorMessage?: string; // populate to show a validation error
  helpText?: string | JSX.Element;
  label?: string; // displayed on top border of input when value inputted
  loading?: boolean;
  newest?: boolean;
  onChange?: (newValue: number) => any;
  onSubmit?: (newValue: number) => any; // allow us to capture submissions from keyboard
  placeholder?: string | MessageDescriptor;
  positiveOnly?: boolean;
  required?: boolean;
  fieldTitle?: string | JSX.Element;
  value: number;
}

// Placeholder moves to label after a value is entered, causing a visual jump.
// This allows us to avoid that visual jump.
const InputContainer = styled.div<{ applyLabelMargin: boolean }>(
  ({ applyLabelMargin }) => `
  ${applyLabelMargin ? "margin-top: 17px;" : ""}
`
);

/*
  Simpler version of the TextInputCard, but for numeric values and without
  the scan listening logic. Since input will always be manual, there's no need for tracking value len difference
*/
export const NumericInputCard: React.FC<NumericInputCardProps> = (props) => {
  const {
    fieldTitle,
    value,
    loading,
    errorMessage,
    label,
    helpText,
    disabled = false,
    onChange = noop,
    placeholder = "",
    onSubmit,
    positiveOnly,
    required,
    ...flowCardProps
  } = props;

  const { formatMessage } = useIntl();

  const formattedPlaceholder = typeof placeholder === "string" ? placeholder : formatMessage(placeholder);

  const prevDefaultOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit?.(value);
  };

  const preventLettersOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = Number(e.target.value);
    if (!isNaN(eventValue)) {
      // invert negative num to positive
      // accounts for edge case of pasting in a negative number
      onChange?.(positiveOnly ? Math.abs(eventValue) : eventValue);
    }
  };

  // we're hiding the starting zero value by assigning an empty string
  const isZeroValue = value === 0 || value === ("" as any);

  return (
    <FlowCard {...flowCardProps}>
      <form onSubmit={prevDefaultOnSubmit}>
        {!loading ? (
          <InputContainer applyLabelMargin={Boolean((label ?? placeholder) && isZeroValue)}>
            <Input
              autoFocus
              disabled={disabled}
              errorMessage={errorMessage}
              hasError={Boolean(errorMessage)}
              helpText={errorMessage ? undefined : helpText}
              inputMode="numeric" // type="number" not used b/c of up/down arrows
              label={isZeroValue ? undefined : label || formattedPlaceholder}
              onChange={preventLettersOnChange}
              placeholder={formattedPlaceholder}
              required={required}
              value={value}
            />
          </InputContainer>
        ) : (
          <LoadingSpinner loading={loading} size="2x" />
        )}
        {props.children}
      </form>
    </FlowCard>
  );
};
