import { Checkbox, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ADDITIONAL_DETAILS_LABELS } from "../../content";

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S5};
  margin-top: ${theme.spacing.S2};
  `
);

export interface RestrictedProductCardProps {
  isRestricted?: boolean;
  toggleRestricted: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RestrictedProductCard: React.FC<RestrictedProductCardProps> = ({ isRestricted, toggleRestricted }) => (
  <FacilityCard>
    <Checkbox
      checked={isRestricted}
      label={<FormattedMessage {...ADDITIONAL_DETAILS_LABELS.restrictedProduct} />}
      onChange={toggleRestricted}
    />
    <StyledText appearance="INFO">
      <FormattedMessage {...ADDITIONAL_DETAILS_LABELS.restrictedProductHelpText} />
    </StyledText>
  </FacilityCard>
);
