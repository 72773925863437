import React from "react";
import { useIntl } from "react-intl";
import { useShipmentSummaryCard } from "./useShipmentSummaryCard";
import { shipmentSummaryCardLabels } from "./shipmentSummaryCardLabels";
import { StyledStack } from "../StyledStack";
import { Divider, ButtonIcon, Text } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ShipmentSummaryCard = () => {
  const {
    numOfPackagesCreated,
    pendingItems,
    totalItems,
    showPendingItemsArrow,
    shipmentType,
    shipmentMethod,
    retailer,
    viewPendingItems,
  } = useShipmentSummaryCard();
  const { formatMessage } = useIntl();

  return (
    <>
      {retailer || shipmentMethod || shipmentType ? (
        <FacilityCard as="section" style={{ height: "100%" }}>
          <StyledStack>
            {retailer ? (
              <Text data-testid="retailer-text">
                {formatMessage(shipmentSummaryCardLabels.retailer, {
                  retailer: <b>{retailer}</b>,
                })}
              </Text>
            ) : null}
            {shipmentMethod ? (
              <Text data-testid="shipment-method-text">
                {formatMessage(shipmentSummaryCardLabels.shipmentMethod, {
                  shipmentMethod: <b>{shipmentMethod}</b>,
                })}
              </Text>
            ) : null}
            {shipmentType ? (
              <Text data-testid="shipment-type-text">
                {formatMessage(shipmentSummaryCardLabels.shipmentType, {
                  shipmentType: <b>{shipmentType}</b>,
                })}
              </Text>
            ) : null}
          </StyledStack>
        </FacilityCard>
      ) : null}
      <FacilityCard as="section">
        <StyledStack>
          <Text appearance="INFO" bold data-testid="total-items-text">
            {formatMessage(shipmentSummaryCardLabels.totalItems, { totalItems })}
          </Text>
          <Divider />
          <StyledContainer>
            <Text bold data-testid="pending-items-text">
              {formatMessage(shipmentSummaryCardLabels.pendingItems, { pendingItems })}
            </Text>
            {showPendingItemsArrow ? (
              <ButtonIcon
                appearance="INFO"
                transparent
                iconType="arrow-right"
                onClick={() => viewPendingItems()}
                aria-label={"button-view-pending-items"}
              />
            ) : null}
          </StyledContainer>
          <Divider />
          <Text appearance="INFO" bold data-testid="packages-created-text">
            {formatMessage(shipmentSummaryCardLabels.packagesCreated, { numOfPackagesCreated })}
          </Text>
        </StyledStack>
      </FacilityCard>
    </>
  );
};
