import React from "react";
import styled from "@emotion/styled";
import { ThemeProps, Checkbox } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { Text, BoxReset, Button, Title } from "@deliverr/ui";
import { useConfirmationCard } from "./useConfirmationCard";
import { TextInputCard } from "facility-commons/flow";
import { DateInput } from "facility-commons/components/Inputs";
import { DateFormat } from "facility-commons/utils";
import { FACILITY_THEME } from "@deliverr/ui-facility";

// Styling wasn't being applied via styled(CheckBox), so had to use inline styles
const MissingInputLabelStyle = `font-size: ${FACILITY_THEME.font.size.F3};`;

export const ConfirmationCard = () => {
  const {
    lotNumberInputValue,
    lotErrorMsg,
    expirationDateInputValue,
    expirationDateErrorMsg,
    disableButton,
    requireLotInput,
    requireExpiryInput,
    isProductMissingLot,
    isProductMissingExpirationDate,
    isConsolidation,
    messages,
    onLotChange,
    onExpirationDateChange,
    onConfirm,
    handleReportProblem,
    onMissingLotToggle,
    onMissingExpiryToggle,
  } = useConfirmationCard();

  return (
    <>
      <FacilityCard as="section">
        <BoxReset marginBottom="S2">
          <Title as="h2" displayAs="h4">
            {messages.fefoLotInputMsg}
          </Title>
        </BoxReset>

        {requireLotInput && (
          <>
            <TextInputCard
              errorMessage={lotErrorMsg}
              newest
              onChange={onLotChange}
              onSubmit={onConfirm}
              placeholder={messages.lotNumber}
              value={lotNumberInputValue}
              disabled={isProductMissingLot}
              embedded
              data-testid="lot-number-input"
            />
            {isConsolidation && (
              <Checkbox
                onChange={onMissingLotToggle}
                checked={isProductMissingLot}
                label={messages.missingLotLabel}
                labelStyles={MissingInputLabelStyle}
              ></Checkbox>
            )}
          </>
        )}

        {requireExpiryInput && (
          <>
            <DateInput
              value={expirationDateInputValue}
              mask={DateFormat.LongDate}
              onChange={onExpirationDateChange}
              placeholderText={messages.expirationDate}
              errorMessage={expirationDateErrorMsg}
              helpText={messages.expirationDateInputHelpText}
              disabled={isProductMissingExpirationDate}
              data-testid="expiration-date-input"
            />
            {isConsolidation && (
              <Checkbox
                onChange={onMissingExpiryToggle}
                checked={isProductMissingExpirationDate}
                label={messages.missingExpiryLabel}
                labelStyles={MissingInputLabelStyle}
              ></Checkbox>
            )}
          </>
        )}
      </FacilityCard>
      <BoxReset marginTop="S4">
        <Button block onClick={onConfirm} disabled={disableButton} data-testid="lot-exp-confirm-btn">
          {messages.confirm}
        </Button>
      </BoxReset>
      {!isConsolidation && (
        <BoxReset marginTop="S4">
          <Button block secondary onClick={handleReportProblem} data-testid="report-problem-btn">
            {messages.reportProblem}
          </Button>
        </BoxReset>
      )}
    </>
  );
};
