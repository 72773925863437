import { defineMessages, MessageDescriptor } from "react-intl";

export enum InvalidCdskuErrorMessageKey {
  PACKAGE_NOT_FOUND = "PACKAGE_NOT_FOUND",
  WAREHOUSE_MISMATCH = "WAREHOUSE_MISMATCH",
  NO_ASN_FOUND = "NO_ASN_FOUND",
  PACKAGES_NOT_FOUND = "PACKAGES_NOT_FOUND",
}

export const INVALID_CDSKU_ERROR_LABELS_BY_ERROR_CODE: Record<
  InvalidCdskuErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [InvalidCdskuErrorMessageKey.PACKAGE_NOT_FOUND]: {
    id: "warehouse.cdsku.packageNotFound",
    defaultMessage: "{cdsku} not found. Please scan a valid CDSKU.",
  },
  [InvalidCdskuErrorMessageKey.WAREHOUSE_MISMATCH]: {
    id: "warehouse.cdsku.warehouseMismatch",
    defaultMessage: "Misrouted CDSKU. Please create a non-compliance ticket.",
  },
  [InvalidCdskuErrorMessageKey.NO_ASN_FOUND]: {
    id: "warehouse.cdsku.noASNFound",
    defaultMessage: "{cdsku} is not associated with a PO #. Please create a non-compliance ticket.",
  },

  [InvalidCdskuErrorMessageKey.PACKAGES_NOT_FOUND]: {
    id: "warehouse.bulk.packagesNotFound",
    defaultMessage: `The following CDSKUs were not found: {cdskus}. Please scan valid CDSKUs or contact Deliverr support.`,
  },
});
