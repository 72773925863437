// https://techdocs.zebra.com/enterprise-browser/2-5/api/printingzebra/#constants

export enum PrinterType {
  ANY = "PRINTER_TYPE_ANY",
  NATIVE = "PRINTER_TYPE_NATIVE",
  ZEBRA = "PRINTER_TYPE_ZEBRA",
  EPSON = "PRINTER_TYPE_EPSON",
  APD = "PRINTER_TYPE_APD",
}

export enum PrinterConnectionType {
  ANY = "CONNECTION_TYPE_ANY",
  ON_BOARD = "CONNECTION_TYPE_ON_BOARD",
  USB = "CONNECTION_TYPE_USB",
  BLUETOOTH = "CONNECTION_TYPE_BLUETOOTH",
  TCP = "CONNECTION_TYPE_TCP",
}

export enum PrinterStatus {
  SUCCESS = "PRINTER_STATUS_SUCCESS",
  ERROR = "PRINTER_STATUS_ERROR",
  ERR_IO = "PRINTER_STATUS_ERR_IO",
  ERR_MEMORY = "PRINTER_STATUS_ERR_MEMORY",
  ERR_NETWORK = "PRINTER_STATUS_ERR_NETWORK",
  ERR_NOT_CONNECTED = "PRINTER_STATUS_ERR_NOT_CONNECTED",
  ERR_NOT_FOUND = "PRINTER_STATUS_ERR_NOT_FOUND",
  ERR_PARAM = "PRINTER_STATUS_ERR_PARAM",
  ERR_PROCESSING = "PRINTER_STATUS_ERR_PROCESSING",
  ERR_RESPONSE = "PRINTER_STATUS_ERR_RESPONSE",
  ERR_TIMEOUT = "PRINTER_STATUS_ERR_TIMEOUT",
  ERR_UNSUPPORTED = "PRINTER_STATUS_ERR_UNSUPPORTED",
}

export interface Printer {
  // https://techdocs.zebra.com/enterprise-browser/2-5/api/printingzebra/#properties
  ID: string;
  connectionType: PrinterConnectionType;
  deviceAddress: string;
  deviceName: string;
  devicePort: number;
  isConnected: boolean;
  printerType: PrinterType;

  // https://techdocs.zebra.com/enterprise-browser/2-5/api/printingzebra/#connect
  connect: (cb: (status: string) => void) => void;

  // https://techdocs.zebra.com/enterprise-browser/2-5/api/printingzebra/#disconnect
  disconnect: (cb: (status: string) => void) => void;

  // https://techdocs.zebra.com/enterprise-browser/2-5/api/printingzebra/#printrawstringspanclasstextinfostringspancommandspanclasstextinfohashspanoptions
  // options is an unused parameter
  printRawString: (zpl: string, options: Record<string, unknown>, cb: () => void) => void;
}

export interface PrinterSearchOptions {
  printerType?: PrinterType; // default any
  connectionType?: PrinterConnectionType; // default any
  timeout?: number; // default 30000
  deviceAddress?: string;
  devicePort?: number; // default 6101
}
