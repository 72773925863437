import React from "react";
import { DefaultTheme, Text, ThemeProps } from "@deliverr/ui";
import { useIntl } from "react-intl";
import { LinkCard } from "warehouse/common/components/LinkCard";
import styled from "@emotion/styled";
import { useOutboundOptions } from "./useOutboundOptions";
import { commonOutboundMessages } from "warehouse/rs-outbounds/content/commonOutboundsMessages";
import { StyledStack } from "../StyledStack";

const StyledText = styled(Text)<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  color:${theme.colors.NEUTRAL[400]};
  margin-bottom:${theme.spacing.S2}
`
);

export const OutboundOptions: React.FC = () => {
  const { formatMessage } = useIntl();

  const { orderPackaging } = useOutboundOptions();

  return (
    <>
      <StyledStack>
        <StyledText bold size="bodyLarge">
          {formatMessage(commonOutboundMessages.orderOperations)}
        </StyledText>
        <LinkCard
          description={orderPackaging.description}
          title={orderPackaging.title}
          to={orderPackaging.link}
          data-testid="outbounds-order-packaging"
        />
      </StyledStack>
    </>
  );
};
