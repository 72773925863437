import { IntlFormatters } from "react-intl";
import { composeGetIsOverDimensions } from "./composeGetIsOverDimensions";
import { DimensionsAndWeight } from "./DimensionsAndWeightType";
import { hasDimensionsAndWeight } from "./hasDimensionsAndWeight";
import { hasValidDimensionsAndWeight } from "./hasValidDimensionsAndWeight";
import {
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
} from "warehouse/common/messages/storage/StorageCaseDimensions.const";
import { CASE_DIMENSIONS_ERROR_LABELS } from "warehouse/common/messages/storage/StorageDimensionErrorMessage";

export const getCaseDimensionsError = (
  dimsAndWeight: Partial<DimensionsAndWeight>,
  formatMessage: IntlFormatters["formatMessage"]
): string | undefined => {
  const isProductOverSized = composeGetIsOverDimensions({
    maxLongestSideInches: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
    maxMiddleSideInches: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
    maxShortestSideInches: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  })({ ...dimsAndWeight });
  const isProductOverWeight = (dimsAndWeight?.weight ?? 0) > STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS;

  if (isProductOverSized && isProductOverWeight) {
    return formatMessage(CASE_DIMENSIONS_ERROR_LABELS.overDimsAndWeightError, {
      maxLength: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
      maxWidth: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
      maxHeight: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
      maxWeight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
    });
  } else if (isProductOverSized) {
    return formatMessage(CASE_DIMENSIONS_ERROR_LABELS.oversizedDimsError, {
      maxLength: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
      maxWidth: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
      maxHeight: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
    });
  } else if (isProductOverWeight) {
    return formatMessage(CASE_DIMENSIONS_ERROR_LABELS.overWeightError, {
      maxWeight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
    });
  } else if (!hasDimensionsAndWeight(dimsAndWeight)) {
    return formatMessage(CASE_DIMENSIONS_ERROR_LABELS.incompleteDimsError);
  } else if (!hasValidDimensionsAndWeight(dimsAndWeight)) {
    return formatMessage(CASE_DIMENSIONS_ERROR_LABELS.invalidDimensionsError);
  }

  return;
};
