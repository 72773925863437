import RouteParser from "facility-commons/RouteParser";
import { NCRoutes } from "warehouse/ticket-center/new/non-compliance/base/routes";
import { ReceivingPath } from "./receiving/routes";

export enum WarehousePortalRoutesNames {
  BARCODE = "barcode",
  CDSKU = "cdsku",
  DAMAGED_PRODUCT = "damaged",
  DAMAGE_PICKING = "damage-picking",
  DAMAGE_RECEIVING = "damage-receiving",
  HAZMAT = "hazmat",
  NEW_TICKET = "new",
  NON_COMPLIANCE = "non-compliance",
  NON_COMPLIANCE_TYPE = "type",
  NON_COMPLIANCE_SCAN = "scan",
  STORAGE_NON_COMPLIANCE_FLOW = "storage-non-compliance",
  OTHER = "other",
  RECEIVING = "receiving",
  OUTBOUNDS = "outbounds",
  SCANNER = "scan-arrival",
  SUCCESS = "success",
  TICKET = "view/:ticketType/:id",
  TICKET_CENTER = "tickets",
  TICKET_CENTER_UNSOLVABLE_DETAILS = "unsolvable-details",
  MORE = "more",
  DOWNLOAD_DHL_MANIFEST = "dhl",
  OUTBOUND_CAPACITY = "capacity",
  DASHBOARD = "3pl-dashboard",
  RECEIVING_DASHBOARD = "dashboard",
  PO_NUMBER_LOOKUP = "po-lookup",
  DAILY_OPS_DASHBOARD = "daily-ops-dashboard",
  OPS_METRICS_DASHBOARD = "ops-metrics-dashboard",
  STORAGE_OPS_METRICS_DASHBOARD = "storage-ops-metrics-dashboard",
  STORAGE_OPS_EXPECTED_AND_RECEIVED_QUANTITIES_DASHBOARD = "storage-ops-expected-vs-received-quantities-dashboard",
  FORECASTING_PLANNING_DASHBOARD = "forecasting-planning-dashboard",
  PRINT_PALLET_LABELS = "print-pallet-labels",
  PRINT_SSCC_LABELS = "print-sscc-labels",
  PO_WORKLIST = "po-worklist",
  STORAGE_WAREHOUSE_BOT = "storage-warehouse-bot",
  GET_PRODUCT_ZONE = "get-product-zone",
}

export enum ArrivalScannerRoutes {
  PALLET = "pallet",
  BOX = "box",
}

const basePath = "/warehouse";
const nonCompliance = "tickets/new/non-compliance";
const nonComplianceForms = "tickets/new/non-compliance-form";
const moreBasePath = `${basePath}/more`;

export const WarehousePortalRoutes = {
  // TICKET FORMS
  NC_BARCODE_TICKET: `${basePath}/${nonComplianceForms}/barcode`,
  NC_OTHER_TICKET: `${basePath}/${nonComplianceForms}/other`,
  NON_COMPLIANCE_TYPE: `${basePath}/${nonComplianceForms}/type`,
  NON_COMPLIANCE_SCAN: `${basePath}/${nonComplianceForms}/scan`,
  DAMAGED_TICKET: `${basePath}/tickets/new/damaged`,
  DAMAGED_RECEIVING_TICKET: `${basePath}/tickets/new/damaged/damage-receiving`,
  DAMAGED_PICKING_TICKET: `${basePath}/tickets/new/damaged/damage-picking`,
  STORAGE_DAMAGED_EXPIRED_CASE: `${basePath}/tickets/new/storage/damaged`,
  SUCCESS: `${basePath}/tickets/new/*/success/:caseNumber`,

  // Non compliance scan flow
  NON_COMPLIANCE: `${basePath}/${nonCompliance}`,
  NC_CDSKU: `${basePath}/${nonCompliance}/${NCRoutes.CDSKU}`,
  NC_BARCODE: `${basePath}/${nonCompliance}/${NCRoutes.BARCODE}`,
  NC_BOX_CONTENT_LABEL: `${basePath}/${nonCompliance}/box-contents-upload`,
  NC_LOT_INPUT: `${basePath}/${nonCompliance}/${NCRoutes.LOT}`,
  NC_EXPIRATION_INPUT: `${basePath}/${nonCompliance}/${NCRoutes.EXPIRATION_DATE}`,
  STORAGE_NON_COMPLIANCE_FLOW: `${basePath}/${nonCompliance}/storage`,
  STORAGE_NON_COMPLIANCE_PO: `${basePath}/${nonCompliance}/storage/${NCRoutes.PO_NUM}`,
  STORAGE_REPORT_PROBLEM: `${basePath}/${nonCompliance}/storage/report-problem`,

  // Ticket Center
  NEW_TICKET: `${basePath}/tickets/new`,
  TICKET_BASE: `${basePath}/tickets/view`,
  TICKET: `${basePath}/tickets/view/:ticketType/:ticketId`,
  TICKET_UNSOLVABLE_DETAILS: `${basePath}/tickets/view/:ticketType/:ticketId/${WarehousePortalRoutesNames.TICKET_CENTER_UNSOLVABLE_DETAILS}`,
  TICKET_CENTER: `${basePath}/tickets`,

  // Box and Pallet Receiving
  RECEIVING: `${basePath}/receiving`,
  RECEIVING_CDSKU: `${basePath}/receiving/cdsku`,
  RECEIVING_SKU: `${basePath}/receiving/sku`,
  RECEIVING_LOT: `${basePath}/receiving/lot`,
  RECEIVING_PALLET: `${basePath}/receiving/pallet`,
  CONSOLIDATION_PALLET: `${basePath}/receiving/consolidation`,
  BULK: `${basePath}/receiving/bulk`,
  LOCATION: `${basePath}/receiving/location`,
  REPORT_ISSUE: `${basePath}/receiving/${ReceivingPath.REPORT_ISSUE}`,

  // Return Receiving
  RECEIVING_RETURN_ORDER: `${basePath}/receiving/return-receive`,

  // Arrival Scan
  SCANNER: `${basePath}/scan-arrival`,

  // Storage Outbounds
  OUTBOUNDS: `${basePath}/outbounds`,

  // More tools page
  MORE: moreBasePath,
  HAZMAT: `${moreBasePath}/hazmat`,
  DOWNLOAD_DHL_MANIFEST: `${moreBasePath}/dhl`,
  OUTBOUND_CAPACITY: `${moreBasePath}/capacity`,
  DASHBOARD: `${moreBasePath}/3pl-dashboard`,
  PO_NUMBER_LOOKUP: `${moreBasePath}/po-lookup`,
  DAILY_OPS_DASHBOARD: `${moreBasePath}/${WarehousePortalRoutesNames.DAILY_OPS_DASHBOARD}`,
  OPS_METRICS_DASHBOARD: `${moreBasePath}/${WarehousePortalRoutesNames.OPS_METRICS_DASHBOARD}`,
  STORAGE_OPS_METRICS_DASHBOARD: `${moreBasePath}/${WarehousePortalRoutesNames.STORAGE_OPS_METRICS_DASHBOARD}`,
  STORAGE_OPS_EXPECTED_VS_RECEIVED_QUANTITIES_DASHBOARD: `${moreBasePath}/${WarehousePortalRoutesNames.STORAGE_OPS_EXPECTED_AND_RECEIVED_QUANTITIES_DASHBOARD}`,
  FORECASTING_PLANNING_DASHBOARD: `${moreBasePath}/${WarehousePortalRoutesNames.FORECASTING_PLANNING_DASHBOARD}`,
  PRINT_PALLET_LABELS: `${moreBasePath}/${WarehousePortalRoutesNames.PRINT_PALLET_LABELS}`,
  PRINT_SSCC_LABELS: `${moreBasePath}/${WarehousePortalRoutesNames.PRINT_SSCC_LABELS}`,
  PO_WORKLIST: `${moreBasePath}/${WarehousePortalRoutesNames.PO_WORKLIST}`,
  STORAGE_WAREHOUSE_BOT: `${moreBasePath}/${WarehousePortalRoutesNames.STORAGE_WAREHOUSE_BOT}`,
  GET_PRODUCT_ZONE: `${moreBasePath}/${WarehousePortalRoutesNames.GET_PRODUCT_ZONE}`,
} as const;

enum WarehouseRoutes {
  BARCODE_TICKET = "BARCODE_TICKET",
  DAMAGED_TICKET = "DAMAGED_TICKET",
  NEW_TICKET = "NEW_TICKET",
  RECEIVING = "RECEIVING",
  OUTBOUNDS = "OUTBOUNDS",
  SCANNER = "SCANNER",
  TICKET = "TICKET",
  TICKET_UNSOLVABLE_DETAILS = "TICKET_UNSOLVABLE_DETAILS",
  TICKET_CENTER = "TICKET_CENTER",
  MORE = "MORE",
}

export const WarehouseDynamicRoutes: Record<WarehouseRoutes, RouteParser> = {
  BARCODE_TICKET: new RouteParser(WarehousePortalRoutes.NC_BARCODE_TICKET),
  DAMAGED_TICKET: new RouteParser(WarehousePortalRoutes.DAMAGED_TICKET),
  NEW_TICKET: new RouteParser(WarehousePortalRoutes.NEW_TICKET),
  RECEIVING: new RouteParser(WarehousePortalRoutes.RECEIVING),
  OUTBOUNDS: new RouteParser(WarehousePortalRoutes.OUTBOUNDS),
  SCANNER: new RouteParser(WarehousePortalRoutes.SCANNER),
  TICKET: new RouteParser(WarehousePortalRoutes.TICKET),
  TICKET_UNSOLVABLE_DETAILS: new RouteParser(WarehousePortalRoutes.TICKET_UNSOLVABLE_DETAILS),
  TICKET_CENTER: new RouteParser(WarehousePortalRoutes.TICKET_CENTER),
  MORE: new RouteParser(WarehousePortalRoutes.MORE),
};
