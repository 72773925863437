import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";

interface ButtonCustomProps {
  className?: string;
  secondary?: boolean;
}

const StyledLink = styled(Link)<{ secondary?: boolean; theme: DefaultTheme }>(
  ({ secondary, theme }) => `
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.BLUE[300]};
  border-radius: ${theme.border.radius.R2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${
    secondary
      ? `
      background-color: ${theme.colors.NEUTRAL["00"]};
      color: ${theme.colors.BLUE[300]};
    `
      : `
      background-color: ${theme.colors.BLUE[300]};
      color: ${theme.colors.NEUTRAL["00"]};
    `
  }
`
);

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  ButtonCustomProps;

export type LinkButtonProps = LinkProps & ButtonCustomProps;

const defaultProps: ButtonCustomProps = {
  secondary: false,
};

export const LinkButton: React.FC<LinkButtonProps> = ({ children, className, secondary, ...otherProps }) => {
  return (
    <StyledLink secondary={secondary} className={className} {...otherProps}>
      {React.Children.toArray(children)}
    </StyledLink>
  );
};

LinkButton.defaultProps = defaultProps;
