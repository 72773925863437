import { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router";
import { useForm, FieldValues } from "react-hook-form";
import { useAsyncFn } from "react-use";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  NonComplianceReason,
  OtherNonComplianceData,
} from "@deliverr/commons-clients/lib/non-compliance/OtherNonCompliance";

import { hasLowerCase } from "facility-commons/utils/config";
import { validationConfig, nonComplianceServiceValidationMap } from "./OtherNonComplianceFormConfig";
import { OtherNonComplianceField } from "./OtherNonComplianceForm.types";
import { WarehousePortalRoutesNames } from "warehouse/routes";
import { removeProp } from "facility-commons/utils";
import { warehouseEmailState } from "facility-commons/base/warehouseEmailState";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { nonComplianceCdskuState } from "../../non-compliance/nonComplianceCdskuState";
import { userState } from "facility-commons/base/Auth/userState";

export function parseServiceErrors(error: Partial<Error> & Partial<{ subcode: string }>) {
  if (error.subcode && nonComplianceServiceValidationMap[error.subcode]) {
    return nonComplianceServiceValidationMap[error.subcode];
  }

  return nonComplianceServiceValidationMap.unknown;
}

export const nonComplianceReasonLabels: Record<string, MessageDescriptor> = defineMessages({
  [NonComplianceReason.ASN_ID_MISSING]: {
    id: "warehouse.otherNonCompliance.poMissing",
    defaultMessage: "PO # is missing",
  },
  [NonComplianceReason.ASN_NOT_IN_SYSTEM]: {
    id: "warehouse.otherNonCompliance.poNotInWMS",
    defaultMessage: "PO # not in WMS / doesn't exist",
  },
  [NonComplianceReason.BARCODE_NOT_IN_SYSTEM]: {
    id: "warehouse.otherNonCompliance.barcodeNotInWMS",
    defaultMessage: "Barcode not in WMS",
  },
  [NonComplianceReason.BARCODE_UNSCANNABLE]: {
    id: "warehouse.otherNonCompliance.barcodeCannotScan",
    defaultMessage: "Barcode missing or cannot scan",
  },
  [NonComplianceReason.DESCRIPTION_MISMATCH]: {
    id: "warehouse.otherNonCompliance.productDescriptionsConflict",
    defaultMessage: "Product descriptions conflict",
  },
  [NonComplianceReason.OTHER]: {
    id: "other",
    defaultMessage: "Other",
  },
  [NonComplianceReason.QUANTITY_INCORRECT]: {
    id: "warehouse.otherNonCompliance.incorrectQuantity",
    defaultMessage: "Incorrect quantity",
  },
  [NonComplianceReason.RESTRICTED_PRODUCT]: {
    id: "warehouse.otherNonCompliance.restrictedProduct",
    defaultMessage: "Restricted product",
  },
});

export function useOtherNonComplianceForm() {
  const { nonComplianceClient } = useClientsWithAuth();
  const match = useRouteMatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { warehouseId } = useRecoilValue(userState);
  const preScannedCdsku = useRecoilValue(nonComplianceCdskuState);
  const resetPreScannedCdsku = useResetRecoilState(nonComplianceCdskuState);

  const formHandler = useForm({
    mode: "onBlur",
  });
  const watchTrackingCode: string = formHandler.watch(OtherNonComplianceField.TRACKING_CODE);
  const watchBarcode: string = formHandler.watch(OtherNonComplianceField.BARCODE);
  const watchCDSKU: string = formHandler.watch(OtherNonComplianceField.CDSKU);

  const [submitState, submitData] = useAsyncFn((data: OtherNonComplianceData) => {
    return nonComplianceClient.submitOtherFormNonCompliance(data);
  });

  useEffect(() => {
    if (watchTrackingCode && hasLowerCase.test(watchTrackingCode)) {
      formHandler.setValue(OtherNonComplianceField.TRACKING_CODE, watchTrackingCode.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTrackingCode]);

  useEffect(() => {
    if (watchBarcode && hasLowerCase.test(watchBarcode)) {
      formHandler.setValue(OtherNonComplianceField.BARCODE, watchBarcode.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBarcode]);

  useEffect(() => {
    if (preScannedCdsku) {
      formHandler.setValue(OtherNonComplianceField.CDSKU, preScannedCdsku.toUpperCase());
    } else if (watchCDSKU && hasLowerCase.test(watchCDSKU)) {
      formHandler.setValue(OtherNonComplianceField.CDSKU, watchCDSKU.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCDSKU, preScannedCdsku]);

  useEffect(() => {
    if (!submitState.loading && submitState?.value?.id) {
      history.push(`${match.url}/${WarehousePortalRoutesNames.SUCCESS}/${submitState.value.id}`);
    }

    if (submitState.error) {
      formHandler.setError(...parseServiceErrors(submitState.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitState, history, match.url, formHandler.setError]);

  const onFormValues = async (values: FieldValues) => {
    let strippedValues = values;
    for (const key in strippedValues) {
      if (strippedValues[key] === "") {
        strippedValues = removeProp(key)(strippedValues);
      }
    }
    const requestData = {
      ...strippedValues,
      warehouseId,
      // react-select has values in the form of { label, value } so we need to destructure it here
      [OtherNonComplianceField.REASON]: values[OtherNonComplianceField.REASON].value,
    } as OtherNonComplianceData;

    if (typeof requestData.quantity === "string") {
      requestData.quantity = parseInt(requestData.quantity, 10);
    }

    submitData(requestData);
    resetPreScannedCdsku();
  };

  const onFormSubmit = formHandler.handleSubmit(onFormValues);

  const nonComplianceReasonOptions = Object.keys(NonComplianceReason).map((key) => ({
    value: key,
    label: formatMessage(nonComplianceReasonLabels[key]),
  }));

  const warehouseEmail = useRecoilValue(warehouseEmailState).warehouseEmail!;

  return {
    preScannedCdsku,
    errors: formHandler.formState.errors,
    formHandler,
    onFormSubmit,
    nonComplianceReasonOptions,
    preventSubmit: submitState.loading,
    warehouseEmail,
    register: {
      [OtherNonComplianceField.TRACKING_CODE]: formHandler.register(
        OtherNonComplianceField.TRACKING_CODE,
        validationConfig[OtherNonComplianceField.TRACKING_CODE]
      ),
      [OtherNonComplianceField.BARCODE]: formHandler.register(
        OtherNonComplianceField.BARCODE,
        validationConfig[OtherNonComplianceField.BARCODE]
      ),
      [OtherNonComplianceField.CDSKU]: formHandler.register(
        OtherNonComplianceField.CDSKU,
        preScannedCdsku ? validationConfig[OtherNonComplianceField.CDSKU] : {}
      ),
      [OtherNonComplianceField.QUANTITY]: formHandler.register(
        OtherNonComplianceField.QUANTITY,
        validationConfig[OtherNonComplianceField.QUANTITY]
      ),
      [OtherNonComplianceField.REASON]: formHandler.register(
        OtherNonComplianceField.REASON,
        validationConfig[OtherNonComplianceField.REASON]
      ),
      [OtherNonComplianceField.ADDITIONAL_DETAILS]: formHandler.register(
        OtherNonComplianceField.ADDITIONAL_DETAILS,
        validationConfig[OtherNonComplianceField.ADDITIONAL_DETAILS]
      ),
      [OtherNonComplianceField.BARCODE_PHOTO]: formHandler.register(
        OtherNonComplianceField.BARCODE_PHOTO,
        validationConfig[OtherNonComplianceField.BARCODE_PHOTO]
      ),
      [OtherNonComplianceField.BOXLABEL_PHOTO]: formHandler.register(
        OtherNonComplianceField.BOXLABEL_PHOTO,
        validationConfig[OtherNonComplianceField.BOXLABEL_PHOTO]
      ),
      [OtherNonComplianceField.FRONT_PHOTO]: formHandler.register(
        OtherNonComplianceField.FRONT_PHOTO,
        validationConfig[OtherNonComplianceField.FRONT_PHOTO]
      ),
      [OtherNonComplianceField.BACK_PHOTO]: formHandler.register(
        OtherNonComplianceField.BACK_PHOTO,
        validationConfig[OtherNonComplianceField.BACK_PHOTO]
      ),
      [OtherNonComplianceField.EMAIL]: formHandler.register(
        OtherNonComplianceField.EMAIL,
        validationConfig[OtherNonComplianceField.EMAIL]
      ),
    },
  };
}
