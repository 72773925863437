import { defineMessages } from "react-intl";

export const problemSolverFlowMessages = defineMessages({
  reportBoxIssueTitle: {
    id: "warehouse.receiving.reportBoxIssueTitle",
    defaultMessage: "Report issue receiving a box",
  },
  reportBoxIssueDescription: {
    id: "warehouse.receiving.reportBoxIssueDescription",
    defaultMessage: "You are about to flag the box with the following label as having an issue",
  },
  reportBoxIssuePageTitle: {
    id: "warehouse.receiving.reportBoxIssuePageTitle",
    defaultMessage: "Report Box Issue",
  },
  reportBoxIssueMissingContainerLabelError: {
    id: "warehouse.receiving.reportBoxIssueMissingContainerLabelError",
    defaultMessage: "Please enter a box label",
  },
  reportBoxIssueMissingContainerTypeError: {
    id: "warehouse.receiving.reportBoxIssueMissingContainerTypeError",
    defaultMessage: "Please select a box type",
  },
  reportBoxIssueSuccessMessage: {
    id: "warehouse.receiving.reportBoxIssueSuccessMessage",
    defaultMessage: "Box issue reported successfully",
  },
  reportBoxIssueConfirmationMessage: {
    id: "warehouse.receiving.reportBoxIssueConfirmationMessage",
    defaultMessage: "Would you like to proceed?",
  },
});
