import * as React from "react";
import { useLifecycles } from "react-use";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { WarehouseFlow } from "warehouse/common/flow/WarehouseFlow";
import { createArrivalScannerCard } from "warehouse/common/flow/warehouseFlowCardCreators";

export const PalletArrivalScan: React.FC = () => {
  const { addFlowCard, resetFlow } = useWarehouseFlow();

  useLifecycles(
    () => {
      addFlowCard(createArrivalScannerCard({}));
    },
    () => {
      resetFlow();
    }
  );

  return <WarehouseFlow />;
};
