import { useIntl } from "react-intl";
import { useEffect } from "react";
import {
  commonBoxMessages,
  commonConsolidationMessages,
  commonPalletMessages,
  commonReceivingMessages,
  commonReturnReceiveMessage,
} from "warehouse/receiving/content";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useRouter } from "warehouse/common/hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { receiveFlowTypeState } from "warehouse/receiving/ReceivingState/ReceiveFlowTypeState";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { setProp } from "facility-commons/utils";
import { isFeatureOn } from "facility-commons";
import { useReceivingDashboard } from "warehouse/more/ReceivingDashboard/useReceivingDashboard";
import { useMount } from "react-use";
import { userState } from "facility-commons/base/Auth/userState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehouseFeature } from "warehouse/base/WarehouseFeature";
import { useLanguage } from "warehouse/base/useLanguage";
import { useWarehouseModal } from "warehouse/modal";

export const useReceiveTypeSelector = () => {
  const { formatMessage } = useIntl();
  const { match } = useRouter();
  const initializeReceive = useInitializeReceivingData();
  const setReceivingFlow = useSetRecoilState(receiveFlowTypeState);
  const [warehouseAppStateData, setWarehouseAppState] = useRecoilState(warehouseAppState);
  const { hideAllModals } = useWarehouseModal();
  const { warehouseId, isManager } = useRecoilValue(userState);
  const { language } = useLanguage();

  const {
    embeddedDashboardUrl,
    embeddedDashboardLoading,
    embeddedDashboardError,
    getEmbeddedUrl,
  } = useReceivingDashboard(warehouseId!);
  const { resetNotifications } = useCommonFlow();

  const isUserTrackingAllowed = isManager;
  const isPalletReceivingAllowed = isFeatureOn(WarehouseFeature.SHOW_PALLET_RECEIVE);
  const isConsolidationAllowed = isFeatureOn(WarehouseFeature.SHOW_CONSOLIDATION_RECEIVE);
  const isReturnReceiveAllowed = !!warehouseAppStateData.returnsEnabled;
  const shouldShowPalletDashboard = isFeatureOn(WarehouseFeature.SHOW_PALLET_DASHBOARD);
  const shouldShowPalletAnalyticsV2 = isFeatureOn(WarehouseFeature.SHOW_PALLET_ANALYTICS_V2);

  useEffect(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonReceivingMessages.receivingModes)));
  }, [language]);

  useMount(() => {
    hideAllModals();
    setWarehouseAppState(setProp("pageSubtitle", ""));
    initializeReceive();
    setReceivingFlow(null);
    getEmbeddedUrl();
    resetNotifications();
  });

  const boxReceive = {
    description: formatMessage(commonBoxMessages.boxReceiveDescription),
    title: formatMessage(commonBoxMessages.boxReceiving),
    link: `${match.path}/${ReceivingPath.CDSKU}`,
    onClick: () => setReceivingFlow(ReceivingFlowType.BOX_RECEIVING),
  };
  const palletReceive = {
    description: formatMessage(commonPalletMessages.palletReceiveInstructions),
    title: formatMessage(commonPalletMessages.palletReceiving),
    link: `${match.path}/${ReceivingPath.PALLET}`,
    onClick: () => setReceivingFlow(ReceivingFlowType.PALLET_RECEIVING),
  };
  const consolidation = {
    description: formatMessage(commonConsolidationMessages.consolidationPalletsInstructions),
    title: formatMessage(commonConsolidationMessages.consolidationPallets),
    link: `${match.path}/${ReceivingPath.CONSOLIDATION}`,
    onClick: () => setReceivingFlow(ReceivingFlowType.CONSOLIDATION_RECEIVING),
  };

  const returnReceive = {
    description: formatMessage(commonReturnReceiveMessage.returnReceiveInstructions),
    title: formatMessage(commonReturnReceiveMessage.returnReceive),
    link: `${match.path}/${ReceivingPath.RETURN_RECEIVE}`,
    onClick: () => setReceivingFlow(ReceivingFlowType.RETURN_RECEIVING),
  };

  const analytics = {
    description: formatMessage(commonReceivingMessages.receivingDashboardDescription),
    title: formatMessage(commonReceivingMessages.receivingDashboard),
    link: {
      pathname: `${match.path}/${ReceivingPath.RECEIVING_DASHBOARD}`,
      state: {
        embeddedDashboardUrl,
        loading: embeddedDashboardLoading,
        error: embeddedDashboardError,
      },
    },
  };
  const palletsAnalytics = {
    description: formatMessage(commonReceivingMessages.palletsDashboardDescription),
    title: formatMessage(commonReceivingMessages.palletsDashboard),
    link: `${match.path}/${ReceivingPath.PALLETS_DASHBOARD}`,
  };

  const palletAnalyticsV2 = {
    description: formatMessage(commonReceivingMessages.palletsDashboardDescription),
    title: formatMessage(commonReceivingMessages.palletsAnalytics),
    link: `${match.path}/${ReceivingPath.PALLET_ANALYTICS}`,
  };

  const shouldShowAnalytics = isUserTrackingAllowed || shouldShowPalletDashboard || shouldShowPalletAnalyticsV2;
  const shouldShowPalletAnalyticsV2Link = shouldShowPalletAnalyticsV2;

  return {
    boxReceive,
    palletReceive,
    consolidation,
    returnReceive,
    analytics,
    palletsAnalytics,
    isUserTrackingAllowed,
    isConsolidationAllowed,
    isPalletReceivingAllowed,
    isReturnReceiveAllowed,
    shouldShowPalletDashboard,
    shouldShowAnalytics,
    shouldShowPalletAnalyticsV2Link,
    palletAnalyticsV2,
  };
};
