import { defineMessages, MessageDescriptor } from "react-intl";
import * as ErrorLabels from "warehouse/receiving/ErrorLabels";

// types of errors as defined by the BE
export enum ErrorType {
  InvalidCdskuError = "InvalidCdskuError",
  RerouteCdskusError = "RerouteCdskusError",
  AsnDefinedError = "AsnDefinedError",
  PalletItemsNotFound = "PalletItemsNotFound",
  PalletNotFound = "PalletNotFound",
  PalletSummaryNoAsnFound = "PalletSummaryNoAsnFound",
  InvalidBarcodeError = "InvalidBarcodeError",
  BulkReceiveError = "BulkReceiveError",
  ConsolidationError = "ConsolidationError",
  InvalidQuantityError = "InvalidQuantityError",
  AsnReceiveError = "AsnReceiveError",
  InvalidLocationError = "InvalidLocationError",
  BulkReceiveIneligibleError = "BulkReceiveIneligibleError",
  BulkReceiveNotFoundError = "BulkReceiveNotFoundError",
  PackageItemReceiveNotFoundError = "PackageItemReceiveNotFoundError",
  PalletReceiveNotFoundError = "PalletReceiveNotFoundError",
  PendingOperationError = "PendingOperationError",
  PalletReceiveError = "PalletReceiveError",
  DifferentSkusError = "DifferentSkusError",
  LotTrackingFefoError = "LotTrackingFefoError",
  UniqueConstraintViolation = "UniqueConstraintViolation",
  ExistingAssociationError = "ExistingAssociationError",
  RetryLimitExceededError = "RetryLimitExceededError",
  UnreservedLabelError = "UnreservedLabelError",
  WarehouseNotFoundError = "WarehouseNotFoundError",
  EmptyPalletError = "EmptyPalletError",
  PalletAsnBoxMismatch = "PalletAsnBoxMismatch",
  PalletCreatedError = "PalletCreatedError",
  InvalidIdentifierError = "InvalidIdentifierError",
  LabelLimitError = "LabelLimitError",
  MissingInputError = "MissingInputError",
  OurReceiveAsnErrorCode = "OurReceiveAsnErrorCode",
  ProductNotInNetworkError = "ProductNotInNetworkError",
}

export const ERRORS_LABELS: Record<string, Record<string, MessageDescriptor>> = {
  [ErrorType.InvalidCdskuError]: ErrorLabels.INVALID_CDSKU_ERROR_LABELS_BY_ERROR_CODE,
  [ErrorType.RerouteCdskusError]: ErrorLabels.REROUTE_CDSKU_LABELS_BY_ERROR_CODE,
  [ErrorType.AsnDefinedError]: ErrorLabels.ASN_DEFINED_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletItemsNotFound]: ErrorLabels.PALLET_ITEMS_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletNotFound]: ErrorLabels.PALLET_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletSummaryNoAsnFound]: ErrorLabels.PALLET_SUMMARY_NO_ASN_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.InvalidBarcodeError]: ErrorLabels.INVALID_BARCODE_ERROR_LABELS_BY_ERROR_CODE,
  [ErrorType.BulkReceiveError]: ErrorLabels.BULK_RECEIVE_ERROR_BY_ERROR_CODE,
  [ErrorType.ConsolidationError]: ErrorLabels.CONSOLIDATION_ERROR_BY_ERROR_CODE,
  [ErrorType.InvalidQuantityError]: ErrorLabels.INVALID_QUANTITY_ERROR_BY_ERROR_CODE,
  [ErrorType.AsnReceiveError]: ErrorLabels.ASN_RECEIVE_ERROR_BY_ERROR_CODE,
  [ErrorType.InvalidLocationError]: ErrorLabels.INVALID_LOCATION_ERROR_BY_ERROR_CODE,
  [ErrorType.BulkReceiveIneligibleError]: ErrorLabels.BULK_RECEIVE_INELIGIBLE_ERROR_BY_ERROR_CODE,
  [ErrorType.BulkReceiveNotFoundError]: ErrorLabels.BULK_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.PackageItemReceiveNotFoundError]: ErrorLabels.PACKAGE_ITEM_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletReceiveNotFoundError]: ErrorLabels.PALLET_RECEIVE_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.PendingOperationError]: ErrorLabels.PENDING_OPERATION_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletReceiveError]: ErrorLabels.PALLET_RECEIVE_ERROR_BY_ERROR_CODE,
  [ErrorType.DifferentSkusError]: ErrorLabels.DIFFERENT_SKU_ERROR_BY_ERROR_CODE,
  [ErrorType.LotTrackingFefoError]: ErrorLabels.LOT_TRACKING_FEFO_ERROR_BY_ERROR_CODE,
  [ErrorType.UniqueConstraintViolation]: ErrorLabels.UNIQUE_CONSTRAINT_VIOLATION_ERROR_BY_ERROR_CODE,
  [ErrorType.ExistingAssociationError]: ErrorLabels.EXISTING_ASSOCIATION_ERROR_BY_ERROR_CODE,
  [ErrorType.RetryLimitExceededError]: ErrorLabels.RETRY_LIMIT_ERROR_BY_ERROR_CODE,
  [ErrorType.UnreservedLabelError]: ErrorLabels.UNRESERVED_LABEL_ERROR_BY_ERROR_CODE,
  [ErrorType.WarehouseNotFoundError]: ErrorLabels.WAREHOUSE_NOT_FOUND_ERROR_BY_ERROR_CODE,
  [ErrorType.EmptyPalletError]: ErrorLabels.EMPTY_PALLET_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletAsnBoxMismatch]: ErrorLabels.PALLET_ASN_BOX_MISMATCH_ERROR_BY_ERROR_CODE,
  [ErrorType.PalletCreatedError]: ErrorLabels.PALLET_CREATED_ERROR_BY_ERROR_CODE,
  [ErrorType.InvalidIdentifierError]: ErrorLabels.INVALID_IDENTIFIER_ERROR_BY_ERROR_CODE,
  [ErrorType.LabelLimitError]: ErrorLabels.LABEL_LIMIT_ERROR_BY_ERROR_CODE,
  [ErrorType.MissingInputError]: ErrorLabels.MISSING_INPUT_ERROR_BY_ERROR_CODE,
  [ErrorType.OurReceiveAsnErrorCode]: ErrorLabels.RECEIVING_WMS_ERROR_BY_ERROR_CODE,
  [ErrorType.ProductNotInNetworkError]: ErrorLabels.PRODUCT_NOT_IN_NETWORK_ERROR_BY_ERROR_CODE,
};

export enum CommonErrorMessageKey {
  EMPTY_FIELD_ERROR = "EMPTY_FIELD_ERROR",
  INVALID_CDSKU_ERROR = "INVALID_CDSKU_ERROR",
  INVALID_QUANTITY_ERROR = "INVALID_QUANTITY_ERROR",
  TOO_MANY_UNITS_ERROR = "TOO_MANY_UNITS_ERROR",
  TOO_MANY_BOXES_ERROR = "TOO_MANY_BOXES_ERROR",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  EMPTY_LOCATIONS = "EMPTY_LOCATIONS",
  INVALID_INPUT = "INVALID_INPUT",
}

export const COMMON_LABELS: Record<CommonErrorMessageKey, MessageDescriptor> = defineMessages({
  [CommonErrorMessageKey.EMPTY_FIELD_ERROR]: {
    id: "warehouse.cdsku.requiredField",
    defaultMessage: "This field is required.",
  },
  [CommonErrorMessageKey.INVALID_CDSKU_ERROR]: {
    id: "warehouse.receiving.invalidCDSKU",
    defaultMessage: "Please scan a valid CDSKU.",
  },
  [CommonErrorMessageKey.INVALID_QUANTITY_ERROR]: {
    id: "warehouse.receiving.invalidQty",
    defaultMessage: "Quantity must be greater than 0.",
  },
  [CommonErrorMessageKey.TOO_MANY_UNITS_ERROR]: {
    id: "warehouse.receiving.maxUnitsQtyErr",
    defaultMessage: "Quantity must be less than 6,000.",
  },
  [CommonErrorMessageKey.TOO_MANY_BOXES_ERROR]: {
    id: "warehouse.receiving.maxBoxesQtyErr",
    defaultMessage: "Quantity must be less than 1,000.",
  },
  [CommonErrorMessageKey.UNEXPECTED_ERROR]: {
    id: "warehouse.receiving.unexpectedError",
    defaultMessage: "Unexpected error",
  },
  [CommonErrorMessageKey.EMPTY_LOCATIONS]: {
    id: "warehouse.receiving.emptyLoc",
    defaultMessage: "Locations can't be empty. Please allocate units in each location.",
  },
  [CommonErrorMessageKey.INVALID_INPUT]: {
    id: "warehouse.receiving.invalidInput",
    defaultMessage: "Invalid Input",
  },
});
