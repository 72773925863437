import { FIELD_ORIGIN_ZPL } from "./fieldOriginZpl";

const INCHES_TO_ZPL_OFFSET_FACTOR = 200; // 200 units = about 1"
export const inchesToOffsetUnits = (inches: number): number => Math.floor(inches * INCHES_TO_ZPL_OFFSET_FACTOR);

// shifts label horizontally
export function insertLabelOffset(zpl: string, horizontalOffset: number): string {
  const offsetZpl = `^LS${inchesToOffsetUnits(horizontalOffset)}`;
  return zpl.replace(FIELD_ORIGIN_ZPL, FIELD_ORIGIN_ZPL + offsetZpl);
}
