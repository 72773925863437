import React from "react";
import { FormProvider } from "react-hook-form";
import { Redirect, Route, Switch } from "react-router";

import { NCRoutes } from "../base/routes";
import { AdditionalCaseDetailsPage, BarcodeUpload, BclUpload, EmailCard, NcQuantityCard } from "../cards";
import { useStorageNonCompliance } from "./useStorageNonCompliance";
import { StorageBarcodeCard } from "../cards/StorageBarcode";
import { StoragePOCard } from "../cards/StoragePOCard";
import { StorageLocationCard } from "../cards/StorageLocation";
import { StorageCaseUploadCard } from "../cards/StorageCaseUpload";
import { StorageNcReviewCard } from "../cards/StorageNcReviewCard";

export const StorageNonCompliance: React.FC = () => {
  const { form, path } = useStorageNonCompliance();

  return (
    <FormProvider {...form}>
      <Switch>
        <Route exact path={`${path}/${NCRoutes.PO_NUM}`} render={() => <StoragePOCard />} />
        <Route exact path={`${path}/${NCRoutes.BARCODE}`} render={() => <StorageBarcodeCard />} />
        <Route exact path={`${path}/${NCRoutes.BARCODE_UPLOAD}`} render={() => <BarcodeUpload />} />
        <Route exact path={`${path}/${NCRoutes.CASE_UPLOAD}`} render={() => <StorageCaseUploadCard />} />
        <Route exact path={`${path}/${NCRoutes.BCL_UPLOAD}`} render={() => <BclUpload />} />
        <Route exact path={`${path}/${NCRoutes.QUANTITY}`} render={() => <NcQuantityCard />} />
        <Route exact path={`${path}/${NCRoutes.STORAGE_LOCATION}`} render={() => <StorageLocationCard />} />
        <Route exact path={`${path}/${NCRoutes.DETAILS}`} render={() => <AdditionalCaseDetailsPage />} />
        <Route exact path={`${path}/${NCRoutes.EMAIL}`} render={() => <EmailCard />} />
        <Route exact path={`${path}/${NCRoutes.STORAGE_REVIEW}`} render={() => <StorageNcReviewCard />} />

        <Redirect push to={`${path}/${NCRoutes.PO_NUM}`} />
      </Switch>
    </FormProvider>
  );
};
