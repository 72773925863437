import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { Box, BoxReset, Button } from "@deliverr/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TicketDetailResponse } from "warehouse/ticket-center/common/types";
import { RESOLUTION_CARD_ACTION_SECTION } from "./ResolutionCardActionSection.labels";

export interface ResolutionCardActions {
  viewSupportTicket: () => void;
  markClosed: () => Promise<void>;
  rollbackClose: () => Promise<void>;
  downloadBarcodes?: () => Promise<void>;
  shouldShowPrintBarcodesButton?: boolean;
}

export interface ResolutionCardActionSectionProps extends ResolutionCardActions {
  ticketDetails: TicketDetailResponse;
  isTicketUrlEmpty: boolean;
  canBeReopened?: boolean;
}

export const ResolutionCardActionSection = ({
  viewSupportTicket,
  markClosed,
  rollbackClose,
  shouldShowPrintBarcodesButton,
  downloadBarcodes,
  ticketDetails,
  isTicketUrlEmpty,
  canBeReopened,
}: ResolutionCardActionSectionProps) => (
  <Box marginTop={"S2"} as="section">
    {!isTicketUrlEmpty && (
      <BoxReset width="100%" marginBottom="S4">
        <Button onClick={viewSupportTicket}>
          {<FormattedMessage {...RESOLUTION_CARD_ACTION_SECTION.viewSupportTicket} />}
        </Button>
      </BoxReset>
    )}
    {ticketDetails.status !== TicketCenterNonComplianceCaseStatus.CREATED && shouldShowPrintBarcodesButton && (
      <BoxReset width="100%" marginBottom="S4">
        <Button onClick={downloadBarcodes}>
          {<FormattedMessage {...RESOLUTION_CARD_ACTION_SECTION.printBarcodes} />}
        </Button>
      </BoxReset>
    )}
    {ticketDetails.status === TicketCenterNonComplianceCaseStatus.TODO && (
      <BoxReset width="100%">
        <Button secondary onClick={markClosed}>
          {<FormattedMessage {...RESOLUTION_CARD_ACTION_SECTION.closeTicket} />}
        </Button>
      </BoxReset>
    )}
    {ticketDetails.status === TicketCenterNonComplianceCaseStatus.CLOSED && canBeReopened && (
      <BoxReset width="100%">
        <Button secondary onClick={rollbackClose}>
          {<FormattedMessage {...RESOLUTION_CARD_ACTION_SECTION.reopenTicket} />}
        </Button>
      </BoxReset>
    )}
  </Box>
);
