import { defineMessages, MessageDescriptor } from "react-intl";

export enum ReceivingWMSErrorMessageKey {
  INVALID_LOCATION = "INVALID_LOCATION",
  LOT_FEFO_LOCATION = "LOT_FEFO_LOCATION",
  PRODUCT_CONFIG_EXPIRATION_REQUIRED = "PRODUCT_CONFIG_EXPIRATION_REQUIRED",
  PRODUCT_CONFIG_LOT_REQUIRED = "PRODUCT_CONFIG_LOT_REQUIRED",
  ASN_CLOSED = "ASN_CLOSED",
  LOCKED_LOCATION = "LOCKED_LOCATION",
  ASN_NOT_FOUND = "ASN_NOT_FOUND",
  ITEM_NOT_FOUND = "ITEM_NOT_FOUND",
  TIME_OUT = "TIME_OUT",
  NETWORK_ERROR = "NETWORK_ERROR",
}

export const RECEIVING_WMS_ERROR_BY_ERROR_CODE: Record<ReceivingWMSErrorMessageKey, MessageDescriptor> = defineMessages(
  {
    [ReceivingWMSErrorMessageKey.INVALID_LOCATION]: {
      id: "warehouse.location.invalidLoc",
      defaultMessage: "{location} is not configured for use. Configure this location or use a valid location.",
    },
    [ReceivingWMSErrorMessageKey.LOT_FEFO_LOCATION]: {
      id: "warehouse.location.lotFefoLoc",
      defaultMessage:
        "There is a product with different expire date/Lot Number in location {location}. Please enter a different location",
    },
    [ReceivingWMSErrorMessageKey.PRODUCT_CONFIG_EXPIRATION_REQUIRED]: {
      id: "warehouse.receiving.expRequiredWMS",
      defaultMessage:
        "{barcode} is configured in the WMS to require an expiration date for receiving. This product may need to be re-synced, please contact Deliverr.",
    },
    [ReceivingWMSErrorMessageKey.PRODUCT_CONFIG_LOT_REQUIRED]: {
      id: "warehouse.receiving.LOTRequiredWMS",
      defaultMessage:
        "{barcode} is configured in the WMS to require a LOT number for receiving. This product may need to be re-synced, please contact Deliverr",
    },
    [ReceivingWMSErrorMessageKey.ASN_CLOSED]: {
      id: "warehouse.receiving.asnClosed",
      defaultMessage:
        "You can't receive on a closed PO# {asn}. Please re-open the PO# in your WMS to continue receiving.",
    },
    [ReceivingWMSErrorMessageKey.LOCKED_LOCATION]: {
      id: "warehouse.location.lockedLoc",
      defaultMessage: "{location} is locked. Please unlock this location in the WMS or choose another location.",
    },
    [ReceivingWMSErrorMessageKey.ASN_NOT_FOUND]: {
      id: "warehouse.receiving.asnNotFound",
      defaultMessage: "{asn}not found in WMS.",
    },
    [ReceivingWMSErrorMessageKey.ITEM_NOT_FOUND]: {
      id: "warehouse.receiving.itemNotFound",
      defaultMessage: "Inventory item {barcode} not found in WMS.",
    },
    [ReceivingWMSErrorMessageKey.TIME_OUT]: {
      id: "warehouse.receiving.timeout",
      defaultMessage: "Request timed out. Please try again",
    },
    [ReceivingWMSErrorMessageKey.NETWORK_ERROR]: {
      id: "warehouse.receiving.networkError",
      defaultMessage:
        "Receive failed due to a network error. Please try receive again or contact Deliverr if the problem persists.",
    },
  }
);
