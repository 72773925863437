import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { defineMessages, MessageDescriptor } from "react-intl";

export const TicketDetailsHeaders: Record<NonComplianceType, MessageDescriptor> = defineMessages({
  [NonComplianceType.UNKNOWN_BARCODE]: {
    id: "warehouse.ticketDetailsHeader.unknownBarcode",
    defaultMessage: "Unknown barcode #{id}",
  },
  [NonComplianceType.MISSING_BARCODE]: {
    id: "warehouse.ticketDetailsHeader.missingBarcode",
    defaultMessage: "Missing barcode #{id}",
  },
  [NonComplianceType.UNEXPECTED_SKU]: {
    id: "warehouse.ticketDetailsHeader.unexpectedSku",
    defaultMessage: "Unexpected SKU #{id}",
  },
  [NonComplianceType.OTHER]: {
    id: "warehouse.ticketDetailsHeader.other",
    defaultMessage: "Uncategorized #{id}",
  },
  // 02/08/23 - Added by Justin Griffin temporarily to fix the build. Should be corrected by someone knowledgeable.
  [NonComplianceType.UNEXPECTED_CDSKU]: {
    id: "warehouse.ticketDetailsHeader.unexpectedCdsku",
    defaultMessage: "Unexpected CDSKU #{id}",
  },
  [NonComplianceType.INVALID_LOT_EXPIRY]: {
    id: "warehouse.ticketDetailsHeader.invalidLotFefo",
    defaultMessage: "Invalid LOT/FEFO #{id}",
  },
  [NonComplianceType.DAMAGED_PRODUCT]: {
    id: "warehouse.ticketDetailsHeader.damagedProduct",
    defaultMessage: "Damages #{id}",
  },
  [NonComplianceType.EXPIRED_PRODUCT]: {
    id: "warehouse.ticketDetailsHeader.expiredProduct",
    defaultMessage: "Expired #{id}",
  },
});
