import React from "react";
import { IconV2, Text, Title, ThemeProps, IconV2Types, Appearance } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";
import { isEmpty, noop } from "lodash";
import { LoadingSpinner } from "facility-commons/components";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

const CardContainer = styled(FacilityCard)`
  display: grid;
  grid-template-columns: 95% 5%;
  min-height: 124px;
  align-items: center;
`;

const CenteredIcon = styled(IconV2)<ThemeProps>`
  margin-bottom: auto;
  margin-top: auto;
`;

const CenteredIconWithLeftMargin = styled(IconV2)<ThemeProps>`
  margin-bottom: auto;
  margin-top: auto;
  margin-left: ${({ theme }) => `${theme.spacing.S1}`};
`;

const StyledTextWithLeftMargin = styled(Text)<ThemeProps>`
  margin-left: ${({ theme }) => `${theme.spacing.S1}`};
`;

const StyledText = styled(Text)<ThemeProps>`
  margin-right: ${({ theme }) => `${theme.spacing.S4}`};
`;

const StyledTitleContainer = styled.div`
  display: flex;
`;

export interface LinkCardProps extends LinkProps {
  description?: string;
  title: string;
  subtitle?: {
    text: string;
    appearance: Appearance;
  };
  secondarySubtitle?: {
    text: string;
    appearance: Appearance;
  };
  icon?: {
    type: IconV2Types;
    color: string;
  };
  secondaryIcon?: {
    type: IconV2Types;
    color: string;
  };
  loading?: boolean;
  to: LinkProps["to"];
  onClick?: () => void;
}

export const LinkCard: React.FC<LinkCardProps> = ({
  description,
  title,
  subtitle,
  icon,
  secondarySubtitle,
  secondaryIcon,
  onClick,
  loading,
  to,
  ...linkProps
}) => {
  return (
    <Link onClick={loading ? noop : onClick} to={loading ? "#" : to} {...linkProps}>
      <CardContainer>
        <LoadingSpinner loading={loading ?? false}>
          <div>
            <StyledTitleContainer>
              <Title as="h5" displayAs="h5">
                {title}
              </Title>
              {!isEmpty(icon) && <CenteredIconWithLeftMargin type={icon!.type} color={icon!.color} size="medium" />}
              {!isEmpty(subtitle) && (
                <StyledTextWithLeftMargin appearance={subtitle!.appearance}>{subtitle!.text}</StyledTextWithLeftMargin>
              )}
              {!isEmpty(secondaryIcon) && (
                <CenteredIconWithLeftMargin type={secondaryIcon!.type} color={secondaryIcon!.color} size="medium" />
              )}
              {!isEmpty(secondarySubtitle) && (
                <StyledTextWithLeftMargin appearance={secondarySubtitle!.appearance}>
                  {secondarySubtitle!.text}
                </StyledTextWithLeftMargin>
              )}
            </StyledTitleContainer>
            {!isEmpty(description) && <StyledText appearance="INFO">{description}</StyledText>}
          </div>
          <CenteredIcon size="lg" type="angle-right" />
        </LoadingSpinner>
      </CardContainer>
    </Link>
  );
};
