import { defineMessages } from "react-intl";

export const ADDITIONAL_DETAILS_LABELS = defineMessages({
  additionalDetails: {
    id: "warehouse.nonCompliance.additionalDetails",
    defaultMessage: "Additional details",
  },
  provideDetailsTitle: {
    id: "warehouse.nonCompliance.provideDetails",
    defaultMessage: "Provide additional details",
  },
  detailsPlaceholder: {
    id: "warehouse.nonCompliance.detailsPlaceholder",
    defaultMessage:
      "Why is this non-compliant? Are there additional boxes or SKUs with this problem? If restricted, why is it restricted?",
  },
  restrictedProduct: {
    id: "warehouse.nonCompliance.restrictedProductError",
    defaultMessage: "This is a restricted product",
  },
  restrictedProductHelpText: {
    id: "warehouse.nonCompliance.restrictedProductHelpText",
    defaultMessage: "Restricted products are either against policy or unsafe to handle.",
  },
});
