import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, mobileTheme } from "@deliverr/ui";

interface UploadProgressBarProps {
  className?: string;
  progress?: number;
  height?: number;
}

const Container = styled.span<Record<string, unknown>, DefaultTheme>`
  display: inline-block;
  width: calc(100% * 2 / 3);
  background-color: ${({ theme }) => theme.colors.NEUTRAL[60]};
`;

export const UploadProgressBar: React.FC<UploadProgressBarProps> = ({ className, progress = 0, height = 10 }) => {
  const elementHeight = height.toString();

  return (
    <Container className={className} style={{ height: `${elementHeight}px` }}>
      <svg width="100%" height={elementHeight}>
        <rect
          x="0"
          y="0"
          width={progress && `${progress}%`}
          height={elementHeight}
          fill={mobileTheme.colors.BLUE[300]}
        />
      </svg>
    </Container>
  );
};
