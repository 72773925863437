import styled from "@emotion/styled";

import { Tooltip } from "facility-commons/components";
import { Grid, Input, Stack, Text, Textarea, Select, Title, ThemeProps, TextProps } from "@deliverr/ui";

export const WarehouseForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const WarehouseFormInput = styled(Input)<ThemeProps<any>>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
);

export const WarehouseFormLabel = styled(Text)<ThemeProps<TextProps>>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
`
).withComponent("label");

export const WarehouseFormImageGrid = styled(Grid)<ThemeProps>`
  margin-bottom: ${({ theme }) => theme.spacing.S5};
  width: 100%;
`;

export const WarehouseFormInputGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  min-height: 110px;
  width: 100%;
  margin-bottom: ${theme.spacing.S3};

  svg {
    top: 1.25rem;
  }
`
);

export const WarehouseFormLabelMandatory = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.RED[300]};
`
);

// container for text area and its label
export const WarehouseFormOtherDetails = styled.div<ThemeProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.S5};
  width: 100%;

  // FormGroup Label element
  & > label {
    width: 100%;
  }
`;

export const WarehouseFormRadioButton = styled.input<ThemeProps>`
  margin-right: ${({ theme }) => theme.spacing.S1};
`;

export const WarehouseFormRadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const RadioGroup = Stack.withComponent("fieldset");
export const WarehouseFormRadioStack = styled(RadioGroup)<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: ${theme.spacing.S6};
`
);

export const WarehouseFormRadioTitle = styled
  .p<ThemeProps>(
    ({ theme }) => `
  margin-top: 0;
  margin-bottom: ${theme.spacing.S3};
`
  )
  .withComponent(Text);

export const WarehouseFormSubTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  margin-bottom: ${theme.spacing.S5};
`
);

export const WarehouseFormPhotoGridTitle = styled(WarehouseFormSubTitle)<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  margin-bottom: ${theme.spacing.S3};
`
);

export const WarehouseFormText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  margin-bottom: ${theme.spacing.S5};
`
);

export const WarehouseFormTooltip = styled(Tooltip)<ThemeProps>`
  margin-top: ${({ theme }) => theme.spacing.S1};
`;

export const WarehouseFormTextArea = styled(Textarea)<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  min-height: 120px;
  width: 100%;
`
);

export const WarehouseFormSelect = styled(Select)<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  width: 100%;
`
);
