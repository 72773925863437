import React from "react";
import { warehouseModalMap } from "./WarehouseModalMap";
import { useRecoilValue } from "recoil";
import { visibleWarehouseModalValue } from "./WarehouseModalState";

export const WarehouseModals: React.FC = () => {
  const visibleModal = useRecoilValue(visibleWarehouseModalValue);
  if (!visibleModal) {
    return null;
  }
  const Modal = warehouseModalMap[visibleModal.type];
  return <Modal {...visibleModal.props} />;
};
