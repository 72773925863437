import { logError } from "facility-commons";
import { inventoryClient, productClient, storageClient } from "facility-commons/base/Clients";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES, StorageErrorCodes } from "../messages/storage";
import { WarehouseModal } from "warehouse/modal";
import { chunk } from "lodash/fp";

export async function fetchDskusDetail(dskuList: string[]): Promise<any> {
  const productDetails = await productClient.getUnifiedProducts(dskuList, {
    includeProductCasePacks: true,
  });
  const childDSKUPacks: string[] = Object.keys(productDetails).filter((val) => !!productDetails[val].packOf);

  // the getATP endpoint only accepts 100 dskus at a time, so we need to chunk the dskuList
  // and assemble the results into a single object
  const dskuListChunked = chunk(100, dskuList);
  const productAtpsResults = await Promise.all(
    dskuListChunked.map((dskuChunk) => inventoryClient.getATP(dskuChunk, undefined, "STORAGE"))
  );
  const productAtps = productAtpsResults.reduce((acc, val) => ({ ...acc, ...val }), {});

  const childDSKUDetails = await productClient.getProductCasePacks(childDSKUPacks, {
    includeProductInformation: true,
  });
  const searchTerms = {};
  Object.entries(productDetails).forEach(([key, value]) => {
    searchTerms[key] = [...(value.barcodes ?? []), value.msku, value.dsku, value.upc, value.asin];
    if (childDSKUPacks.includes(key)) {
      searchTerms[key] = [...(childDSKUDetails[key].productDetails?.barcodes ?? []), ...searchTerms[key]];
    }
    const defaultCasePack = productDetails[key].productCasePacks?.find(
      (productCasePack) => productCasePack?.isDefaultPack === true
    );
    if (defaultCasePack) {
      productDetails[key] = {
        ...productDetails[key],
        caseQty: defaultCasePack.quantity,
      };
    }

    if (!!value.packOf) {
      productDetails[key] = {
        ...productDetails[key],
        name: childDSKUDetails[key].productDetails?.name || "",
        barcodes: childDSKUDetails[key]?.barcodes,
        isFefoEnabled: childDSKUDetails[key]?.productDetails?.isFefoEnabled,
        isLotTrackingEnabled: childDSKUDetails[key]?.productDetails?.isLotTrackingEnabled,
      };
    }
  });
  return {
    searchTerms,
    productAtps,
    productDetails,
  };
}

export async function fetchStoragePODetails(
  warehouseId: string,
  poNum: string,
  requestBatchId: string,
  showModal,
  formatMessage,
  mandatoryArrival?: boolean
): Promise<any> {
  const ctx = { fn: "fetchStoragePODetails", warehouseId, poNum };
  try {
    const {
      asnId,
      asnItems,
      palletCount,
      sellerId,
      arrivedAt,
      arrivalExpectedAt,
      receivingStartPalletCount,
      shipmentType,
      bookingID,
      bookingType,
      prepOption,
    } = await storageClient.getStorageInboundByAsnId(poNum, warehouseId, requestBatchId);
    if (mandatoryArrival && !arrivedAt) {
      showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
        title: formatMessage(STORAGE_RECEIVING_MESSAGES.poStatusNotArrivedError),
        text: formatMessage(STORAGE_RECEIVING_MESSAGES.poNotMarkedArrivedText),
      });
      return {};
    }
    const dskuList = asnItems.map(({ dsku }) => dsku);
    const totalExpectedQuantity = asnItems.reduce((total, item) => total + item.expectedQty, 0);
    const totalReceivedQuantity = asnItems.reduce((total, item) => total + item.receivedQty, 0);
    const { searchTerms, productAtps, productDetails } = await fetchDskusDetail(dskuList);
    return {
      asnId,
      palletCount,
      sellerId,
      itemsCount: asnItems.length,
      searchTerms,
      productAtps,
      productDetails,
      arrivedAt,
      arrivalExpectedAt,
      receivingStartPalletCount,
      shipmentType,
      totalExpectedQuantity,
      totalReceivedQuantity,
      bookingID,
      bookingType,
      prepOption,
    };
  } catch (err: any) {
    logError(ctx, err);
    if (err?.response) {
      const { data } = err.response;
      const code = data?.error?.code;
      const message = data?.error?.message;
      switch (code) {
        case StorageErrorCodes.NOT_FOUND:
        case StorageErrorCodes.DATABASE_ERROR:
        default: {
          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: message,
          });
          return;
        }
      }
    } else if (err?.message || err?.error) {
      showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
        title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
        text: String(err?.message || err.error?.message),
      });
    }
  }
}
