import { useIntl } from "react-intl";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import { modalsText } from "../modalsText";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "warehouse/receiving/ReturnReceive/state";
import { useRef, useState } from "react";
import {
  MAX_IMAGE_COUNT,
  PHOTO_CAPTURE_HEIGHT_PX,
  PHOTO_CAPTURE_WIDTH_PX,
} from "warehouse/receiving/ReturnReceive/constants";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { setProp } from "facility-commons";
import { compact } from "lodash";
import { useAsyncFn, useMount } from "react-use";

export const useReturnReceiveCaptureItemImageModal = () => {
  const { formatMessage } = useIntl();
  const { hideModal } = useWarehouseModal();
  const { successResponse, errorResponse } = useCommonFlow();
  const { returnsClient } = useClientsWithAuth();
  const [itemInspectionData, setItemInspectionData] = useRecoilState(returnReceiveItemInspectionStateAtom);
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const { images } = itemInspectionData;
  const [isGettingVideo, setIsGettingVideo] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>("");

  useMount(async () => {
    try {
      await getVideo();
    } catch (error: any) {
      errorResponse();
      setErrorMsg(formatMessage(commonReturnReceiveMessage.unableToConnectToCamera));
    }
  });

  const photoRef = useRef(null);
  const videoRef = useRef(null);

  const getVideo = async () => {
    setIsGettingVideo(true);

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { width: PHOTO_CAPTURE_WIDTH_PX, height: PHOTO_CAPTURE_HEIGHT_PX },
    });
    const video = videoRef.current as any;
    video.srcObject = stream;

    setIsGettingVideo(false);
  };

  const closeCamera = async () => {
    const activeCamera = (videoRef.current as any).srcObject;
    if (activeCamera) {
      activeCamera.getTracks()?.forEach((track) => track.stop());
    }

    hideModal(WarehouseModal.RETURN_RECEIVE_CAPTURE_ITEM_IMAGE);
  };

  const [uploadState, takePhotoAndUpload] = useAsyncFn(async () => {
    if (compact(images).length >= MAX_IMAGE_COUNT) {
      errorResponse();
      setErrorMsg(formatMessage(commonReturnReceiveMessage.maxImageCountExceeded));
      return;
    }

    try {
      const video = videoRef.current;
      const photo = photoRef.current as any;
      const ctx = photo.getContext("2d");
      ctx.drawImage(video, 0, 0, PHOTO_CAPTURE_WIDTH_PX, PHOTO_CAPTURE_HEIGHT_PX);

      const prefix = `returns/${returnOrderData.orderId}/qcitem/image`;
      const response = await returnsClient.saveImageBase64({ image: photo.toDataURL("image/jpeg") as string, prefix });
      const image = response.value;
      // Add the image to the first available slot in the images array
      const currentImages = [...images];
      for (let i = 0; i < currentImages.length; i++) {
        if (!currentImages[i]) {
          currentImages[i] = image;
          setItemInspectionData(setProp("images", currentImages));
          successResponse();
          break;
        }
      }
      ctx.reset();
      await closeCamera();
    } catch (error: any) {
      errorResponse();
      setErrorMsg(String(error.message));
    }
  }, [images]);

  const messages = {
    takePhoto: formatMessage(commonReturnReceiveMessage.takePhoto),
    close: formatMessage(modalsText.close),
  };

  return {
    messages,
    videoRef,
    photoRef,
    errorMsg,
    isTakePhotoButtonDisabled: isGettingVideo || uploadState.loading,
    loading: uploadState.loading,
    getVideo,
    closeCamera,
    takePhotoAndUpload,
  };
};
