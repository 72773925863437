import { useEffect } from "react";
import { MessageFormatElement } from "react-intl";
import { useRecoilState } from "recoil";

import { userState } from "facility-commons/base/Auth/userState";
import { log, setProp } from "facility-commons/utils";
import { LanguageCode, LanguageOption } from "facility-commons/types";
import englishMessages from "../i18n/compiled-lang/en.json";
import spanishMessages from "../i18n/compiled-lang/es.json";
import { COOKIE_KEYS } from "facility-commons/types/Auth";

export const useLanguage = () => {
  const [user, setUser] = useRecoilState(userState);
  const localStorageLanguageMap = JSON.parse(window.localStorage.getItem(COOKIE_KEYS.LANGUAGE) || "{}");
  const language: LanguageCode = localStorageLanguageMap[user.email] || user.language;

  const MESSAGES: Record<LanguageCode, Record<string, string> | Record<string, MessageFormatElement[]>> = {
    [LanguageCode.ENGLISH]: englishMessages,
    [LanguageCode.SPANISH]: spanishMessages,
  };

  useEffect(() => {
    if (language !== user.language) {
      setUser(setProp("language", language));
    }
  }, [language, setUser, user.language]);

  const setLanguageSelection = (selectedLanguage: LanguageOption) => {
    setUser(setProp("language", selectedLanguage.value));
    log({ fn: "setLanguageSelection", selectedLanguage, user }, "new language selected");

    localStorageLanguageMap[user.email] = selectedLanguage.value;
    window.localStorage.setItem(COOKIE_KEYS.LANGUAGE, JSON.stringify(localStorageLanguageMap));
  };

  return {
    language,
    messages: MESSAGES[language],
    setLanguageSelection,
  };
};
