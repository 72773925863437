import { useLifecycles } from "react-use";
import React from "react";
import { Flow } from "facility-commons/flow";
import { FlowCardData } from "facility-commons/flow/types/FlowCardData";
import { useReturnReceiveFlow } from "./useReturnReceiveFlow";
import { returnReceiveFlowCardsState } from "../state";
import { returnReceiveFlowCardMap } from "../map/returnReceiveFlowCardMap";

export interface ReturnReceiveFlowProps {
  onMountCardData?: FlowCardData<any>;
  onMount?: () => void;
  onUnmount?: () => void;
  stopResetOnUnmount?: boolean;
}

export const ReturnReceiveFlow: React.FC<ReturnReceiveFlowProps> = ({
  onMountCardData,
  onMount,
  onUnmount,
  stopResetOnUnmount,
}) => {
  const { addFlowCard, resetFlow, hideAllFlowButtons } = useReturnReceiveFlow();

  useLifecycles(
    () => {
      if (onMountCardData) {
        hideAllFlowButtons();
        addFlowCard(onMountCardData);
      }
      onMount?.();
    },
    () => {
      if (!stopResetOnUnmount) {
        resetFlow();
      }
      hideAllFlowButtons();
      onUnmount?.();
    }
  );

  return (
    <Flow
      cardsState={returnReceiveFlowCardsState}
      cardMap={returnReceiveFlowCardMap}
      visibleButtons={[]}
      buttonMap={{}}
      showNotifications={false}
    />
  );
};
