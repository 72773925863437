import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FACILITY_THEME } from "../../theme";

// modal overlay (dimmed view) for custom modals
export const ModalOverlay = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: ${theme.spacing.S5};
    background: ${FACILITY_THEME.colorVars.dimmedBackground};
    z-index: 200;
  `
);
