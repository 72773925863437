import { v4 as uuid } from "uuid";
import { useSetRecoilState } from "recoil";
import {
  currentSkuState,
  INITIAL_RECEIVING_STATE,
  INITIAL_SKU_STATE,
  receivingMisMatchState,
  receivingState,
  bulkReceiveState,
  INITIAL_BULK_RECEIVE_DATA,
  cdskuState,
  INITIAL_CDSKU_STATE,
} from "warehouse/receiving/ReceivingState";
import {
  currentProductLotFefoDetailsState,
  LOT_FEFO_DETAILS_INITIAL_STATE,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

// used for starting a new box receive or exiting receiving flow
// for ConsolidationFlow, we don't want to reset the CID when we reset the state.
// we might receive thousands of units on same CID
export const useInitializeReceivingData = (consolidationLabel: string | undefined = undefined) => {
  const setCurrentSku = useSetRecoilState(currentSkuState);
  const setReceivingData = useSetRecoilState(receivingState);
  const setMismatch = useSetRecoilState(receivingMisMatchState);
  const setLotFefoDetailsState = useSetRecoilState(currentProductLotFefoDetailsState);
  const setBulkReceive = useSetRecoilState(bulkReceiveState);
  const setCdskuData = useSetRecoilState(cdskuState);

  return () => {
    setReceivingData({
      ...INITIAL_RECEIVING_STATE,
      requestBatchId: uuid(),
      dedupKey: uuid(),
      consolidationLabel,
    });
    setCurrentSku({ ...INITIAL_SKU_STATE });
    setCdskuData({ ...INITIAL_CDSKU_STATE });
    setBulkReceive({ ...INITIAL_BULK_RECEIVE_DATA });
    setMismatch(false);
    setLotFefoDetailsState({ ...LOT_FEFO_DETAILS_INITIAL_STATE });
  };
};
