import { ProductClient } from "@deliverr/commons-clients";
import { log, logStart } from "@deliverr/ui-facility";

export const fetchWholesaleShipmentDetails = async (dskuArray: string[], productClient: ProductClient) => {
  const ctx = logStart({ fn: "fetchWholesaleShipmentDetails" });

  const shipmentItemsDetails = await productClient.getUnifiedProducts(dskuArray, {
    includeProductCasePacks: true,
  });
  if (!shipmentItemsDetails) {
    log({ ...ctx }, "error when fetching shipmentItemsDetails");
  } else {
    log({ ...ctx }, `successfully fetched shipment shipmentItemsDetails`);
  }
  const mapParentDskuToCasePackDsku: Record<string, string> = {};
  Object.keys(shipmentItemsDetails).forEach((item) => {
    const packOf = shipmentItemsDetails[item].packOf;
    if (packOf) {
      mapParentDskuToCasePackDsku[packOf] = shipmentItemsDetails[item].dsku;
    }
  });
  const packOfArray = Object.values(shipmentItemsDetails)
    .map((item) => item.packOf ?? "")
    .filter((x) => x);
  let productDetails;
  if (packOfArray.length > 0) {
    productDetails = await productClient.getUnifiedProducts(packOfArray, {
      includeProductCasePacks: true,
    });
    if (!productDetails) {
      log({ ...ctx }, "error when fetching productDetails");
    } else {
      log({ ...ctx }, `successfully fetched shipment productDetails`);
    }
  }
  const unifiedProductDetails = { ...shipmentItemsDetails, ...productDetails };

  return {
    shipmentItemsDetails,
    unifiedProductDetails,
    mapParentDskuToCasePackDsku,
  };
};
