import { Disposition, ImageMinimal } from "@deliverr/returns-client";

import { MAX_IMAGE_COUNT } from "../constants";
import { ReturnReceiveStateKey } from "./ReturnReceiveStateKey";
import { atom } from "recoil";

export interface ReturnReceiveItemInspectionState {
  barcode: string;
  dsku: string;
  sellerId: string;
  itemName: string;
  isLotTrackingEnabled: boolean;
  isFefoEnabled: boolean;
  isUnexpectedItem: boolean;
  inspectionSOPName: string;
  inspectionSOPDescription: string;
  inspectionSteps: string[];
  inspectionRules: {
    [condition: string]: {
      disposition: Disposition;
      availableVas: string[];
    };
  };
  condition: string | null;
  disposition: Disposition | null;
  images: ImageMinimal[];
  itemInspectionNotes: string;
  returnsLocation: string | null;
}

const RETURN_RECEIVE_ITEM_INSPECTION_INITIAL_STATE: ReturnReceiveItemInspectionState = {
  barcode: "",
  dsku: "",
  sellerId: "",
  itemName: "",
  isLotTrackingEnabled: false,
  isFefoEnabled: false,
  isUnexpectedItem: false,
  inspectionSOPName: "",
  inspectionSOPDescription: "",
  inspectionSteps: [],
  inspectionRules: {},
  condition: null,
  disposition: null,
  images: [...Array(MAX_IMAGE_COUNT)], // this fixed length array is for managing image upload/capture
  itemInspectionNotes: "",
  returnsLocation: null,
};
export const returnReceiveItemInspectionStateAtom = atom<ReturnReceiveItemInspectionState>({
  key: ReturnReceiveStateKey.ITEM_INSPECTION_STATE,
  default: RETURN_RECEIVE_ITEM_INSPECTION_INITIAL_STATE,
});
