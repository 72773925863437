import React from "react";
import { FlowCard } from "facility-commons/flow";
import { useSortToLocationCard } from "./useSortToLocationCard";
import { Appearance, BoxReset, Button, Stack, Text } from "@deliverr/ui";
import { InspectionImageUpload } from "../InspectionImageUpload";
import styled from "@emotion/styled";
import { MAX_IMAGE_COUNT } from "../../constants";

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SortToLocationCard: React.FC = () => {
  const {
    messages,
    submissionError,
    imageCaptureEnabled,
    loading,
    openCamera,
    completeItemInspection,
    onImageUploadError,
  } = useSortToLocationCard();
  return (
    <>
      <FlowCard title={messages.itemDisposition}>
        {imageCaptureEnabled && (
          <>
            <Text as="header">{messages.uploadPhotosOfItem}</Text>
            <StyledDiv>
              {[...Array(MAX_IMAGE_COUNT)].map((_, index) => (
                <InspectionImageUpload key={index} index={index} onFailureCallback={onImageUploadError} />
              ))}
            </StyledDiv>
          </>
        )}
        <Text as="header">{messages.sortToLocation}</Text>
        <Text appearance={Appearance.DANGER} size={"label"}>
          {submissionError}
        </Text>
      </FlowCard>
      <BoxReset marginTop="S4">
        <Stack>
          {imageCaptureEnabled && (
            <Button
              block
              secondary
              loading={loading}
              onClick={openCamera}
              data-testid="return-receive-open-camera-button"
            >
              {messages.takePhoto}
            </Button>
          )}
          <Button
            block
            loading={loading}
            onClick={completeItemInspection}
            data-testid="return-receive-sort-to-location-confirm-btn"
          >
            {messages.confirmButtonText}
          </Button>
        </Stack>
      </BoxReset>
    </>
  );
};
