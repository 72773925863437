import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Text } from "@deliverr/ui";
import { commonMessages } from "facility-commons/labels";
import { Thumbnail, ThumbnailContainer } from "facility-commons/components/emotion/cards";

const generatePhotoThumbnails = (photos: (string | undefined)[]) =>
  photos.map((imageLink, i) =>
    imageLink ? (
      <ThumbnailContainer key={`thumbnail-photo-${i}`}>
        <Thumbnail alt={`thumbnail-photo-${i}`} src={`${imageLink}`} />
      </ThumbnailContainer>
    ) : undefined
  );

interface PhotosSectionProps {
  photos: Array<string>;
}

export const PhotosSection: React.FC<PhotosSectionProps> = ({ photos }) => (
  <>
    <Text bold>
      <FormattedMessage {...commonMessages.photos} />
    </Text>
    <Grid columns="repeat(4,1fr)" gap="S2">
      {generatePhotoThumbnails(photos)}
    </Grid>
  </>
);
