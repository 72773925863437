import { useRouter } from "facility-commons/hooks";
import RouteParser from "facility-commons/RouteParser";
import { useRecoilValue } from "recoil";
import { ticketCenterState } from "warehouse/ticket-center/common/ticketCenterState";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useUserCanAccessProblemSolverFlow } from "warehouse/receiving/hooks/useUserCanAccessProblemSolverFlow";
import { isFeatureOn } from "facility-commons";
import { WarehouseFeature } from "warehouse/base/WarehouseFeature";
import { useRouteToProblemSolverPortal } from "warehouse/common/hooks/useRouteToProblemSolverPortal";
import { ProblemSolverContext } from "@deliverr/ui-facility";

export enum WarehousePaths {
  scanner = "scanner",
  receiving = "receiving",
  outbounds = "outbounds",
  createTicket = "createTicket",
  ticketCenter = "ticketCenter",
  more = "more",
  hazmat = "hazmat",
  issueCenter = "issueCenter",
}

const pathsToBeActive: Record<WarehousePaths, string> = {
  [WarehousePaths.scanner]: "/scan-arrival",
  [WarehousePaths.receiving]: "/receiving",
  [WarehousePaths.outbounds]: "/outbounds",
  [WarehousePaths.createTicket]: "/tickets/new",
  [WarehousePaths.ticketCenter]: "/tickets",
  [WarehousePaths.issueCenter]: "/issues",
  [WarehousePaths.more]: "/more",
  [WarehousePaths.hazmat]: "/hazmat",
};

export function useAppBar() {
  const { todoCount } = useRecoilValue(ticketCenterState);
  const router = useRouter();
  const { isStorage } = useRecoilValue(userState);
  const userCanAccessProblemSolverFlow = useUserCanAccessProblemSolverFlow();
  const { problemSolverFlowEnabled } = useRecoilValue(warehouseAppState);
  const { routeToIssueDashboard, routeToNewIssue } = useRouteToProblemSolverPortal(
    isStorage
      ? [ProblemSolverContext.RESERVE_STORAGE_OUTBOUNDS]
      : [ProblemSolverContext.RECEIVING, ProblemSolverContext.INVENTORY]
  );

  const isRSOutboundsAppEnabled = isFeatureOn(WarehouseFeature.SHOW_RS_OUTBOUNDS_APP) && isStorage;
  /*
    does the current path match the button that directs to it?
    /tickets === "Tickets" button, /scan-arrival === "Scanner" button

    also accounts for nested paths: /tickets/new keeps "Create Ticket" active
  */
  const isSamePath = (target: WarehousePaths) => window.location.pathname.includes(pathsToBeActive[target]);

  const navigateWithButton = (parser: RouteParser) => {
    router.push(parser.parse({ ...router.match.params }));
  };

  // It's possible for a site to be enabled for problemSolverFlow but the user doesn't have access
  // For both RS and FC warehouses, we show the create ticket button if problemSolverFlow is enabled and user has access
  // If problemSolverFlow is not enabled, we still show button at FC but NOT at RS. RS creates non-problemSolver tickets within the old ticket center.
  const shouldShowCreateTicket = userCanAccessProblemSolverFlow || (!problemSolverFlowEnabled && !isStorage);
  // The new ticket center is exclusively for problem solvers.
  const shouldShowNewTicketCenter = userCanAccessProblemSolverFlow;
  // Show old ticket center if
  // 1. Problem solver flow is enabled and user is a problem solver. Only problem solvers should be able to create issues or tickets.
  // 2. Problem solver flow is not enabled for warehouse, by default the old ticket center is enabled for all warehouses
  const shouldShowOldTicketCenter = userCanAccessProblemSolverFlow || !problemSolverFlowEnabled;

  return {
    isStorage,
    isSamePath,
    navigateWithButton,
    todoCount,
    shouldShowCreateTicket,
    shouldShowNewTicketCenter,
    shouldShowOldTicketCenter,
    userCanAccessProblemSolverFlow,
    problemSolverFlowEnabled,
    isRSOutboundsAppEnabled,
    routeToNewIssue,
    routeToIssueDashboard,
  };
}
