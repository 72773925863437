import {
  TicketCenterMissingBarcodeCaseResponse,
  TicketCenterUnknownBarcodeCaseResponse,
} from "@deliverr/commons-clients";
import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { WarehouseMessages } from "facility-commons/utils";
import { isEmpty } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { DashboardDetailCard } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailCard";
import { DashboardDetailExpansionBlock } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailExpansionBlock";
import { DashboardDetailInfoList } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList";
import { DashboardDetailInfoListItem } from "facility-commons/components/Dashboard/DashboardDetail/DashboardDetailInfoList/DashboardDetailInfoListItem";
import { TicketCardThumbnails } from "warehouse/ticket-center/common/components/TicketCardThumbnails";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { CenteredSpinner } from "./CenteredSpinner";
import { OVERVIEW_CARD_LABELS } from "./OverviewCard.labels";
import { OverviewCardActionSection } from "./OverviewCardActionSection";
import { TicketCenterOverviewNotesSection } from "./TicketCenterOverviewNotesSection";

/**
 * For now, UnknownBarcode and MissingBarcode have nearly
 * identical overview cards. To keep the code a bit cleaner,
 * we can just extract it all to a shared overview card for now. These
 * cards are not inherently linked thouhgh, so this could very well
 * change in the future. In this case, shared components should be refactored
 * further.
 **/
export const BarcodeCaseOverviewCard = () => {
  const { ticketDetails, loading, showDeleteModal, handleThumbnailClick } = useTicketDetails<
    TicketCenterUnknownBarcodeCaseResponse | TicketCenterMissingBarcodeCaseResponse
  >();
  const { formatMessage } = useIntl();

  // Yes, ticketDetails will always be empty when we're loading.
  // This is definitely repetitive. But, this tells vscode that ticketDetails
  // will always be defined below, so we don't have to worry about the undefined
  // option.
  if (loading || !ticketDetails) {
    return <CenteredSpinner loading />;
  }

  const initialInfoList: DashboardDetailInfoListItem[] = [
    {
      id: "Receiving PO #",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.po),
      value: ticketDetails.asnId,
    },
    {
      id: "CDSKU",
      displayValue: formatMessage(WarehouseMessages.cdsku),
      value: ticketDetails.cdsku ?? formatMessage(WarehouseMessages.unknown),
    },
    {
      id: "Barcode",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.barcode),
      value:
        ticketDetails.type === NonComplianceType.MISSING_BARCODE
          ? formatMessage(WarehouseMessages.missing)
          : formatMessage(WarehouseMessages.unknown),
    },
  ];

  const quantityList: DashboardDetailInfoListItem[] = [
    {
      id: "Number of units per box",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.unitsPerBox),
      value: ticketDetails.quantity / ticketDetails.numberOfBoxes,
    },
    {
      id: "Number of non-compliant boxes",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.nonCompliantBoxes),
      value: ticketDetails.numberOfBoxes,
    },
    {
      id: "Total units",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.totalUnits),
      value: ticketDetails?.quantity ?? 0,
    },
  ];

  return (
    <div>
      <DashboardDetailCard createdAt={new Date(ticketDetails.createdAt)}>
        <DashboardDetailInfoList addDivider items={initialInfoList} />
        <DashboardDetailExpansionBlock
          addDivider
          disableExpansion
          title={formatMessage(OVERVIEW_CARD_LABELS.sectionHeaders.quantities)}
        >
          <DashboardDetailInfoList items={quantityList} />
        </DashboardDetailExpansionBlock>
        <TicketCardThumbnails
          addDivider={!isEmpty(ticketDetails.notes)}
          addBottomPadding
          onThumbnailClick={handleThumbnailClick}
          thumbnails={ticketDetails.pictures.map((pic) => `${pic}`)}
        />
        <TicketCenterOverviewNotesSection notes={ticketDetails.notes} />
      </DashboardDetailCard>
      <OverviewCardActionSection status={ticketDetails.status} showDeleteModal={showDeleteModal} />
    </div>
  );
};
