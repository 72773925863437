export function printPdf(pdf: Blob): void {
  const url = URL.createObjectURL(pdf);
  try {
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow?.print();
      }, 0);
    };
    iframe.src = url;
  } finally {
    URL.revokeObjectURL(url);
  }
}
