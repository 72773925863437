import { Dispatch, SetStateAction } from "react";
import { isInvalidCdsku } from "warehouse/receiving/utils";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { MessageDescriptor } from "react-intl";

// validates and handles local state setting for invalid cdskus
export const handleCdskuValidation = (
  cdsku: string,
  formatMessage: (descriptor: MessageDescriptor, values?) => string,
  errorDispatcher: Dispatch<SetStateAction<string>>,
  onValidationFail?: () => void
) => {
  if (!cdsku.length || isInvalidCdsku(cdsku)) {
    onValidationFail?.();
    errorDispatcher(
      !cdsku.length ? formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR) : formatMessage(COMMON_LABELS.INVALID_CDSKU_ERROR)
    );
    return true;
  }

  return false;
};
