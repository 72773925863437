export enum AppName {
  WP = "Warehouse App",
  CD = "Scan App",
  HB = "Hub App",
  PR = "Prep App",
}

export const appNameToRouteMap: Record<AppName, string> = {
  [AppName.WP]: "warehouse",
  [AppName.CD]: "scan",
  [AppName.HB]: "scan",
  [AppName.PR]: "prep",
};

export const routeToAppNameMap: Record<string, AppName> = {
  warehouse: AppName.WP,
  scan: AppName.CD,
  prep: AppName.PR,
};
