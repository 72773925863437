import { DefaultTheme, IconV2, mobileTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { useInspectionImageUpload } from "./useInspectionImageUpload";

const UploadContainer = styled.label<ThemeProps, DefaultTheme>(
  ({ theme }) => `
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid 1px ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R4};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: center;
  width: 130px;
  margin: ${theme.spacing.S1};
`
);

const HiddenInput = styled.input`
  display: none;
`;

type InspectionImageProps = {
  index: number;
  onSuccessCallback?: () => void;
  onFailureCallback?: (errorMessage: string) => void;
};

export const InspectionImageUpload: React.FC<InspectionImageProps> = ({
  index,
  onSuccessCallback,
  onFailureCallback,
}) => {
  const { imageUrl, uploading, onInputFileChange } = useInspectionImageUpload({
    index,
    onSuccessCallback,
    onFailureCallback,
  });
  return (
    <>
      <UploadContainer
        style={{
          backgroundImage: imageUrl && !uploading ? `url(${imageUrl})` : "",
        }}
      >
        {!uploading && !imageUrl && (
          <>
            <IconV2 color={mobileTheme.colors.BLUE[300]} size={"lg"} type={"camera"} />
          </>
        )}
        <HiddenInput accept="image/*" capture="environment" onChange={onInputFileChange} type="file" />
      </UploadContainer>
    </>
  );
};
