import React from "react";
import { DefaultTheme, LocalizedMessage, Title, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

import { TextInputCard } from "facility-commons/flow";
import { TextInputCardProps } from "facility-commons/flow/cards/TextInputCard/TextInputCardProps";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { CenteredWarehouseCard } from "../StyledWarehouseCard";

export const FlatTopInputCard = styled(TextInputCard)<ThemeProps>(
  ({ theme }) => `
  border-radius: 0px 0px ${theme.border.radius.R5} ${theme.border.radius.R5} ;
  border-color:  ${theme.colors.NEUTRAL[80]};
`
);

const IllustrationCard = styled(CenteredWarehouseCard)<{ secondary?: boolean }, DefaultTheme>(
  ({ secondary, theme }) => `
  border-radius:${theme.border.radius.R5} ${theme.border.radius.R5} 0px 0px;
  border-bottom: 0;
  border-color: ${theme.colors.NEUTRAL[80]};

  ${
    secondary
      ? `
    background-color: ${theme.colors.NEUTRAL[400]};
  `
      : ` background-color: ${theme.colors.NEUTRAL[20]};`
  }

  svg {
    margin: auto;
  }
`
);

const StyledTitle = styled(Title)<ThemeProps<{ secondary: boolean }>>(({ secondary, theme }) =>
  secondary
    ? `
    color: ${theme.colors.NEUTRAL["00"]};
  `
    : ""
);

interface StackedInputCardProps extends TextInputCardProps {
  illustration: JSX.Element;
  illustrationTitle?: LocalizedMessage;
  secondary?: boolean;
}

export const StackedInputCard: React.FC<StackedInputCardProps> = ({
  children,
  errorMessage,
  helpText,
  illustration,
  illustrationTitle,
  loading,
  message,
  onChange,
  onSubmit,
  placeholder,
  secondary,
  title,
  pillProps,
  value,
  name,
}) => {
  return (
    <div>
      <IllustrationCard secondary={secondary}>
        {illustration}
        <StyledTitle as="h3" displayAs="h3" secondary={secondary ?? false} textAlign={TextAlign.center}>
          {illustrationTitle}
        </StyledTitle>
      </IllustrationCard>
      <FlatTopInputCard
        errorMessage={errorMessage}
        helpText={helpText}
        message={message}
        newest
        onChange={onChange}
        onSubmit={onSubmit}
        placeholder={placeholder}
        pillProps={pillProps}
        loading={loading}
        title={title}
        value={value}
        name={name}
      >
        {children}
      </FlatTopInputCard>
    </div>
  );
};
