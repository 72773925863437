import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface ClosedPalletAsnModalProps {
  onCancel: () => void;
  asnLabel: string;
}

export const ClosedPalletAsn: React.FC<ClosedPalletAsnModalProps> = ({ onCancel, asnLabel }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={formatMessage(modalsText.closedPalletAsnTitle, { asnLabel })}
      text={formatMessage(modalsText.closedPalletText)}
      iconProps={dangerModalIconXl}
    >
      <Grid fullWidth>
        <Button block onClick={onCancel}>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
