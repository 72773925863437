import { shipmentCardLabels } from "./shipmentCardLabels";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { createSuccessNotification, genericOnScannerInputChange, log, logStart, setProp } from "facility-commons/utils";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useMount } from "react-use";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { commonOutboundMessages } from "warehouse/rs-outbounds/content/commonOutboundsMessages";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { WholesaleShipmentDTO, WholesaleShipmentStatus } from "@deliverr/wholesale-client";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createOutboundsPackingShipmentSummary } from "warehouse/rs-outbounds/rsOutboundsPackingCardCreators";
import { SoundFx } from "facility-commons/common/sfx";
import { userState } from "facility-commons/base/Auth/userState";

const VALID_SHIPMENT_STATUSES: string[] = [
  WholesaleShipmentStatus.SUBMITTED,
  WholesaleShipmentStatus.PICKING_STARTED,
  WholesaleShipmentStatus.READY_TO_SHIP,
  WholesaleShipmentStatus.FREIGHT_ORDERED,
];
export const useShipmentCard = () => {
  const [shipmentError, setShipmentError] = useState("");
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const setScanState = useSetRecoilState(rsOutboundsStateAtom);
  const resetState = useResetRecoilState(rsOutboundsStateAtom);
  const { wholesaleClient } = useClientsWithAuth();
  const { errorResponse } = useCommonFlow();
  const { transition } = useWarehouseFlow();
  const { formatMessage } = useIntl();
  const [shipmentId, setShipmentId] = useState("");
  const { warehouseId } = useRecoilValue(userState);

  useMount(() => {
    resetState();
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonOutboundMessages.orderPackaging)));
    setWarehouseAppState(setProp("pageSubtitle", ""));
  });

  const [submitState, handleSubmit] = useAsyncFn(async (value: string) => {
    const ctx = logStart({ fn: "useShipmentCard.handleSubmit", value, shipmentError });

    setShipmentError("");

    if (!value?.length) {
      setShipmentError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      errorResponse();
      return;
    }

    const onError = () => {
      log({ ...ctx }, "error when getting shipment");

      setShipmentError(formatMessage(shipmentCardLabels.invalidShipment));
      errorResponse();
    };

    const isInvalidShipment = (shipment: WholesaleShipmentDTO) => {
      const isInvalidWarehouse = shipment?.originWarehouseId?.toUpperCase() !== warehouseId.toUpperCase();
      if (isInvalidWarehouse) {
        setShipmentError(formatMessage(shipmentCardLabels.invalidWarehouse));
        errorResponse();
        return true;
      }
      const isInvalidStatus = !VALID_SHIPMENT_STATUSES.includes(shipment.status);
      if (isInvalidStatus) {
        setShipmentError(
          formatMessage(shipmentCardLabels.invalidShipmentStatus, {
            shipmentStatus: shipment.status,
          })
        );
        errorResponse();
        return true;
      }
      return false;
    };

    const onSuccess = (shipment: WholesaleShipmentDTO) => {
      log({ ...ctx, ...shipment }, "successfully fetched shipment");
      if (isInvalidShipment(shipment)) {
        return;
      }
      setScanState((state) => ({
        ...state,
        shipmentDetails: shipment,
      }));
      transition([
        {
          card: createOutboundsPackingShipmentSummary({}),
          notification: createSuccessNotification(
            formatMessage(shipmentCardLabels.shipmentScanSuccessNotificationTitle)
          ),
          sfx: SoundFx.SUCCESS,
          flash: "SUCCESS",
        },
      ]);
    };

    try {
      setScanState((state) => ({
        ...state,
        currentShipmentId: value,
      }));
      const response = await wholesaleClient.getShipmentAdminAccess(value);
      if (!response) {
        onError();
      } else {
        onSuccess(response.data);
      }
    } catch (error) {
      log({ ...ctx, error }, "error when getting shipment");
      onError();
    }
  }, []);

  const updateShipmentId = (value: string) => setShipmentId(value);

  const handleChange = genericOnScannerInputChange(shipmentId, updateShipmentId, handleSubmit, "upper");

  return {
    shipmentId,
    shipmentError,
    handleChange,
    handleSubmit,
    loading: submitState.loading,
  };
};
