import { Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { userState } from "facility-commons/base/Auth/userState";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";

import { MenuSection, PaddedLinkButton, StyledButtonContainer } from "facility-commons/components/emotion";
import { CenteredWarehouseCard } from "warehouse/common/components/StyledWarehouseCard";
import { WarehousePortalRoutes } from "../../routes";

const StyledTitle = styled(Title)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
`
);

export const CreateTicketTypeSelector: React.FC = () => {
  const { isStorage } = useRecoilValue(userState);

  return (
    <CenteredWarehouseCard>
      <MenuSection>
        <StyledTitle as="h3" displayAs="h3">
          <FormattedMessage
            id="warehouse.createTicketTypeSelector.title"
            defaultMessage="What type of ticket are you submitting?"
          />
        </StyledTitle>
        <StyledButtonContainer>
          <PaddedLinkButton
            data-testid="non-compliance-btn"
            to={isStorage ? WarehousePortalRoutes.STORAGE_NON_COMPLIANCE_PO : WarehousePortalRoutes.NC_CDSKU}
          >
            <FormattedMessage
              id="warehouse.createTicketTypeSelector.nonComplianceButton"
              defaultMessage="Non-Compliance"
            />
          </PaddedLinkButton>
          <PaddedLinkButton
            to={isStorage ? WarehousePortalRoutes.STORAGE_DAMAGED_EXPIRED_CASE : WarehousePortalRoutes.DAMAGED_TICKET}
          >
            <FormattedMessage id="warehouse.createTicketTypeSelector.damagedItemButton" defaultMessage="Damaged Item" />
          </PaddedLinkButton>
        </StyledButtonContainer>
      </MenuSection>
    </CenteredWarehouseCard>
  );
};
