import { atom, selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { receivingState } from "./ReceivingState";
import { isEmpty } from "lodash";
import { getDefaultLocationFromLocalStorage } from "warehouse/receiving/utils";
/** the default location value is saved in local storage */
interface DefaultLocationData {
  isSettingDefaultLocation: boolean /** when true, the location card is used for setting a default location instead of submitting a receive */;
  isDefaultLocationEnabled: boolean /** enable default location feature */;
}

export const INITIAL_DEFAULT_LOCATION_STATE: DefaultLocationData = {
  isSettingDefaultLocation: false,
  isDefaultLocationEnabled: false,
};

export const defaultLocationState = atom<DefaultLocationData>({
  key: WarehouseStateKey.DEFAULT_LOCATION_DATA,
  default: INITIAL_DEFAULT_LOCATION_STATE,
});

const getIsDefaultLocationReceive = ({ get }): boolean => {
  const defaultLocation = getDefaultLocationFromLocalStorage();
  const { expirationDate, lotNumber } = get(receivingState);
  const { isSettingDefaultLocation, isDefaultLocationEnabled } = get(defaultLocationState);
  const isFefoLot = !isEmpty(expirationDate || lotNumber);

  return !!defaultLocation && isDefaultLocationEnabled && !isFefoLot && !isSettingDefaultLocation;
};

export const isDefaultLocationReceiveState = selector<boolean>({
  key: WarehouseStateKey.IS_DEFAULT_LOCATION_RECEIVE,
  get: getIsDefaultLocationReceive,
});
