import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { DefaultTheme } from "@deliverr/ui";
import { NumericInput } from "facility-commons/components";
import { FlowCard } from "facility-commons/flow";
import { LoadingButton } from "facility-commons";
import { STORAGE_PRINT_PALLET_LABELS_MESSAGES } from "warehouse/common/messages/storage";
import {
  MAX_PALLET_LABEL_COUNT,
  MIN_PALLET_LABEL_COUNT,
} from "warehouse/common/messages/storage/PrintPalletLabels.const";
import { useStoragePrintPalletLabels } from "./useStoragePrintPalletLabels";

const CenteredDiv = styled.div<Record<string, unknown>, DefaultTheme>`
  white-space: pre-line;
`;

export const StoragePrintPalletLabels: React.FC = () => {
  const {
    requestPalletLabels,
    value,
    updatePalletCount,
    inputErrorMessage,
    loading,
    printDisabled,
  } = useStoragePrintPalletLabels();
  const { formatMessage } = useIntl();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestPalletLabels({});
  };

  return (
    <FlowCard
      title={formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.printPalletLabels)}
      icon="print"
      accentColor="BLUE"
    >
      <form onSubmit={onSubmit}>
        <CenteredDiv>
          {formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.numLabelsRangeMessage, {
            minLabelCount: MIN_PALLET_LABEL_COUNT,
            maxLabelCount: MAX_PALLET_LABEL_COUNT,
          })}
        </CenteredDiv>
        <NumericInput
          autoFocus
          value={value}
          placeholder={formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.numLabelsToPrint)}
          onChange={updatePalletCount}
          errorMessage={inputErrorMessage}
          hasError={Boolean(inputErrorMessage)}
          disabled={loading}
          data-testid="num-pallet-labels-input"
        />
      </form>

      <LoadingButton
        type="button"
        onClick={requestPalletLabels}
        disabled={printDisabled}
        data-testid="print-pallet-labels-button"
      >
        {formatMessage(STORAGE_PRINT_PALLET_LABELS_MESSAGES.printPalletLabels)}
      </LoadingButton>
    </FlowCard>
  );
};
