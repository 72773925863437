import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReceivingDataStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "../state";
import { userState } from "facility-commons/base/Auth/userState";
import { setProp } from "facility-commons";
import { compact } from "lodash";

export const useSubmitInspectionResults = () => {
  const { returnsClient } = useClientsWithAuth();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const [returnOrderData, setReturnOrderData] = useRecoilState(returnReceiveReturnOrderStateAtom);
  const itemInspectionData = useRecoilValue(returnReceiveItemInspectionStateAtom);
  const returnReceiveData = useRecoilValue(returnReceiveReceivingDataStateAtom);
  const { orderId } = returnOrderData;
  const { dsku, barcode, condition, disposition, itemInspectionNotes, images } = itemInspectionData;
  const { dedupKey, requestBatchId, quantity, expirationDate, lotNumber } = returnReceiveData;

  // the receiveLocation and returnsLocation are submitted in the terminal cards of the return receive flow
  // if they are entered in via a scan, then they may not be updated in state before we submit the inspection results
  // so we explicity pass them in here
  const submitInspectionResults = async ({
    receiveLocation,
    returnsLocation,
  }: {
    receiveLocation: string | null;
    returnsLocation: string | null;
  }) => {
    const { value } = await returnsClient.finishReturnItemInspection(orderId!, {
      facilityId,
      dsku,
      barcode,
      quantity,
      disposition: disposition!,
      condition: condition!,
      vasApplied: [], // we are turning off VAS until further notice, just passing empty array here to keep TS happy
      images: compact(images), // images can get upload in any slot, so we compact to remove empty slots
      notes: itemInspectionNotes,
      returnsLocation: returnsLocation ?? undefined,
      receiveBatchId: requestBatchId,
      dedupKey,
      receiveLocation: receiveLocation ?? undefined,
      receiveLotNumber: lotNumber ?? undefined,
      receiveExpirationDate: expirationDate ? new Date(expirationDate) : undefined,
    });

    const { returnOrder } = value;
    setReturnOrderData(setProp("inspectedItems", returnOrder.inspectedItems));
    setReturnOrderData(setProp("orderInspectionNotes", returnOrder.inspectionNotes ?? ""));
  };

  return {
    submitInspectionResults,
  };
};
