import { composeFacilityCommonsButton } from "./composeFacilityCommonsButton";
import { FACILITY_COMMONS_BUTTON_LABELS } from "./FacilityCommonsButton.labels";

export const ContinueButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.continueButton);
export const CancelButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.cancelButton);
export const DoneButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.doneButton);
export const DoneScanningButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.doneScanningButton);
export const UpdateButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.updateButton);
export const UnderstandButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.understandButton);
export const NoButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.noButton);
export const YesButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.yesButton);
export const BackButton = composeFacilityCommonsButton(FACILITY_COMMONS_BUTTON_LABELS.backButton);
