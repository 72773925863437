import React from "react";
import { ModalTemplate, questionModalIconLg } from "facility-commons";
import { Appearance, Button, Grid, Text } from "@deliverr/ui";
import { useReturnReceiveCreateRTMBoxModal } from "./useReturnReceiveCreateRTMBoxModal";

export const ReturnReceiveCreateRTMBoxModal: React.FC = () => {
  const {
    messages,
    rtmBoxBarcode,
    boxCreationError,
    loading,
    createRTMBox,
    reprintBarcode,
    onCancel,
  } = useReturnReceiveCreateRTMBoxModal();
  return (
    <ModalTemplate
      buttonLayout="column"
      title={rtmBoxBarcode ? messages.boxCreatedTitle : messages.createNewBoxTitle}
      iconProps={questionModalIconLg}
      text={rtmBoxBarcode}
    >
      <Grid gap="S2" fullWidth>
        <Text appearance={Appearance.DANGER} size={"label"}>
          {boxCreationError}
        </Text>
        {rtmBoxBarcode && (
          <Button block onClick={reprintBarcode} loading={loading}>
            {messages.reprintBoxBarcode}
          </Button>
        )}
        <Button block onClick={createRTMBox} loading={loading}>
          {rtmBoxBarcode ? messages.createAnotherBox : messages.confirm}
        </Button>
        <Button block onClick={onCancel} loading={loading} secondary>
          {messages.cancel}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
