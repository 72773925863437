import { defineMessages, MessageDescriptor } from "react-intl";

export enum UnreservedLabelErrorMessageKey {
  UNRESERVED_LABEL = "UNRESERVED_LABEL",
}

export const UNRESERVED_LABEL_ERROR_BY_ERROR_CODE: Record<
  UnreservedLabelErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [UnreservedLabelErrorMessageKey.UNRESERVED_LABEL]: {
    id: "warehouse.receiving.unreservedLabelError",
    defaultMessage: "Label {label} has not been reserved and cannot be used.",
  },
});
