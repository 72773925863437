import { useState } from "react";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";

/** Util hook to quickly set and reset input error messages. */
export function useInputErrorMessage() {
  const { errorResponse } = useCommonFlow();
  const [inputErrorMessage, setInputErrorMessage] = useState<string>("");

  const resetError = () => {
    setInputErrorMessage("");
  };

  const resetErrorOnExecution = <T extends (arg?: any) => Promise<any>>(fn: T) => {
    return async (arg) => {
      resetError();
      await fn(arg);
    };
  };

  const handleInputError = (errorMessage: string): void => {
    setInputErrorMessage(errorMessage);
    errorResponse();
  };

  return { inputErrorMessage, resetError, resetErrorOnExecution, inputError: handleInputError };
}
