import { Appearance, InputProps, LocalizedMessage } from "@deliverr/ui";
import { Direction } from "@deliverr/ui/lib-esm/shared";
import { DamagedProductField } from "facility-commons/flow/forms/DamagedProductForm/DamagedProductForm.types";
import { FieldErrors, FieldValues } from "react-hook-form";

export enum FormInputTypes {
  INPUT = "input",
  RADIO = "radio",
}

interface FormGroupItem {
  type: FormInputTypes;
  labelProps?: {
    appearance: Appearance;
    htmlFor: DamagedProductField;
  };
  direction?: Direction;
  title?: LocalizedMessage;
  options?: any;
  labelContent?: LocalizedMessage;
  textInputProps?: InputProps;
  isMandatory: boolean;
}

export type FormGroupMapConfig = Array<FormGroupItem>;

interface ImageGridItem {
  errorProps: {
    errors: FieldErrors<FieldValues>;
    name: DamagedProductField;
  };
  tooltipProps: {
    active: boolean;
  };
  uploadProps: {
    id: DamagedProductField;
    labelText: LocalizedMessage;
    isMandatory?: boolean;
  };
}

export type ImageGridOptionsMapConfig = Array<ImageGridItem>;
