import { UserRoleType } from "warehouse/common/types";
// Min and max limits to set outbound capacity determined by user role
// i.e. a Deliverr "admin" has a range of 50% +/- the incoming limit
// and a regular FC user has a range of 25%

type OutBoundCapacityLimitsConfig = {
  [key in UserRoleType]: {
    decrease: number;
    increase: number;
  };
};
export const outboundCapacityLimitsConfig: OutBoundCapacityLimitsConfig = {
  [UserRoleType.ADMIN]: {
    decrease: 0.5,
    increase: 0.5,
  },
  [UserRoleType.FC]: {
    decrease: 0.1,
    increase: 0.2,
  },
};
