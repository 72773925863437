import * as React from "react";
import { Button } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";
import { useReceivingSKUConfirmFlow } from "./useReceivingSKUConfirmFlow";

export const StorageConfirmReceiveButton: React.FC = () => {
  const { handleSubmit, loading } = useReceivingSKUConfirmFlow();

  return (
    <Button block data-testid="storage-receiving-confirmReceive" loading={loading} onClick={handleSubmit}>
      <FormattedMessage id="storage.receiving.confirmReceive" defaultMessage="Confirm receive" />
    </Button>
  );
};
