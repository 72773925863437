export enum PrepStateKey {
  PREP_DASHBOARD_STATE = "PREP_DASHBOARD_STATE",
  PREP_DETAIL_STATE = "PREP_DETAIL_STATE",
  PREP_DETAIL_LOADERS_STATE = "PREP_DETAIL_LOADERS_STATE",
  PREP_DETAIL_LOADERS_COUNT = "PREP_DETAIL_LOADERS_COUNT",
  PREP_MODALS = "PREP_MODALS",
  PREP_VISIBLE_MODAL = "PREP_VISIBLE_MODAL",
  PREP_CURRENT_BCL_PRODUCTS_STATE = "PREP_CURRENT_BCL_PRODUCTS_STATE",
  PREP_FEATURE_CLIENT_STATE = "PREP_FEATURE_CLIENT_STATE",
  PREP_HEADER_STATE = "PREP_HEADER_STATE",
  PREP_FLASH_STATE = "PREP_FLASH_STATE",
  PREP_UNIT_LABEL_PRINT_JOBS = "PREP_UNIT_LABEL_PRINT_JOBS",
  PREP_BARCODE_TRANSLATION_STATE = "PREP_BARCODE_TRANSLATION_STATE",
  PREP_CURRENT_UNIT_LABEL_PRINT_JOB = "PREP_CURRENT_UNIT_LABEL_PRINT_JOB",
  PREP_FLOW_STATE = "PREP_FLOW_STATE",
}
