import { FlowCardType } from "facility-commons/flow/types/FlowCardType";

export enum WarehouseFlowCardType {
  // Pallet Arrival Scan
  ARRIVAL_SCANNER = "ARRIVAL_SCANNER",
  ARRIVAL_SCANNER_SUCCESS = "ARRIVAL_SCANNER_SUCCESS",
  ARRIVAL_SCANNER_FAILURE = "ARRIVAL_SCANNER_FAILURE",
  ARRIVAL_SCANNER_INVALID_INPUT = "ARRIVAL_SCANNER_INVALID_INPUT",
  PALLET_ARRIVAL_SCANNER_ERROR = "PALLET_ARRIVAL_SCANNER_ERROR",

  // Box Arrival Scan
  BOX_ARRIVAL_SCANNER = "BOX_ARRIVAL_SCANNER",
  BOX_IDENTIFICATION = "BOX_IDENTIFICATION",
  BOX_ARRIVAL_SCAN_NOTIFICATION = "BOX_ARRIVAL_SCAN_NOTIFICATION",
}

export type WarehouseCardType = WarehouseFlowCardType | FlowCardType;
