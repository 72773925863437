import { useAsyncFn, useMount } from "react-use";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { warehouseAppState } from "../../../base/warehouseAppDataState";
import { useIntl } from "react-intl";
import { useState } from "react";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useReceivingFlow } from "../../base";
import { BulkEligibility, PalletBoxSummary } from "@deliverr/legacy-inbound-client";
import { palletAnalyticsMessages } from "./PalletAnalyticsMessages";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { COMMON_LABELS } from "../cards/warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";

interface FlattenedPalletBoxSummary {
  boxLabel: string;
  dsku: string;
  qty: number;
  bulkEligibility: BulkEligibility;
}

const flattenPalletBoxSummary = (packages: PalletBoxSummary[]): FlattenedPalletBoxSummary[] => {
  return packages.flatMap((packageObj) => {
    return packageObj.expectedQty.map((expectedQty) => {
      return {
        boxLabel: packageObj.boxLabel,
        dsku: expectedQty.dsku,
        qty: expectedQty.qty,
        bulkEligibility: packageObj.bulkEligibility,
      };
    });
  });
};

const groupByDSKU = (pkgs): FlattenedPalletBoxSummary[][] => {
  const result: FlattenedPalletBoxSummary[][] = [];

  const uniqueDSKUs = [...new Set(pkgs.map((item) => item.dsku))];
  uniqueDSKUs.forEach((dsku) => {
    const tempArr: FlattenedPalletBoxSummary[] = pkgs.filter((item) => item.dsku === dsku);
    result.push(tempArr);
  });
  return result;
};

export const usePalletAnalytics = () => {
  const [pid, setPid] = useState<string>("");
  const [pidError, setPIDError] = useState<string>("");
  const [bulkBoxReceives, setBulkBoxReceives] = useState<FlattenedPalletBoxSummary[][]>([]);
  const [multiSKUsBoxes, setMultiSKUsBoxes] = useState<FlattenedPalletBoxSummary[][]>([]);
  const [singleSKUBoxes, setSingleSkuBoxes] = useState<FlattenedPalletBoxSummary[]>([]);

  const { warehouseId } = useRecoilValue(userState);
  const { inboundClient } = useClientsWithAuth();

  const { handleUnknownError } = useReceivingFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { errorResponse } = useCommonFlow();
  const { formatMessage } = useIntl();

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(palletAnalyticsMessages.palletAnalytics)));
  });

  const getGroupedPackages = (packages: PalletBoxSummary[]) => {
    const flattenedSingleSKUBoxes = flattenPalletBoxSummary(packages.filter((p) => p.expectedQty.length <= 1));
    const multiSkuBoxes = packages.filter((p) => p.expectedQty.length > 1).map((p) => flattenPalletBoxSummary([p]));

    setBulkBoxReceives(
      groupByDSKU(flattenedSingleSKUBoxes.filter((group) => group.bulkEligibility !== BulkEligibility.NONE))
    );
    setMultiSKUsBoxes(multiSkuBoxes);
    setSingleSkuBoxes(flattenedSingleSKUBoxes.filter((item) => item.bulkEligibility === BulkEligibility.NONE));
  };

  const [{ loading }, handleSubmit] = useAsyncFn(async (value: string) => {
    const ctx = logStart({ fn: "usePalletAnalytics.handleSubmit", value, warehouseId });
    setPIDError("");

    if (!value.length) {
      errorResponse();
      return setPIDError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
    }
    const onError = (response) => {
      const { message, payload } = response.error;

      log({ ...ctx, response }, "error while getting pallet details");
      errorResponse(() => setPid(""));
      const messageId = getErrorMessageId({ response: response.error });
      const formattedValues = payload && formatValues(payload);
      return setPIDError(messageId ? formatMessage(messageId, formattedValues) : message);
    };
    const onSuccess = (response) => {
      log({ ...ctx, ...response }, "pallet analytics details");
      getGroupedPackages(response.data);
    };

    try {
      const response = await inboundClient.getPalletSummary(warehouseId, value);

      if (response.error) {
        onError(response);
      } else {
        onSuccess(response);
      }
    } catch (error) {
      setPid("");
      handleUnknownError(ctx, error);
    }
  });

  const updatePID = (value: string) => {
    setPid(value);
    setPIDError("");
  };

  const handleChange = genericOnScannerInputChange(pid, updatePID, handleSubmit, "upper");

  const palletIdPlaceholder = formatMessage(palletAnalyticsMessages.pidPlaceholder);
  const palletIdTitle = formatMessage(palletAnalyticsMessages.pidTitle);
  const palletAnalyticsDetails = formatMessage(palletAnalyticsMessages.palletAnalyticsMessage);

  const bulkEligibleHeader = formatMessage(palletAnalyticsMessages.bulkEligibleBoxes);
  const multiSKUHeader = formatMessage(palletAnalyticsMessages.multiSKUBoxes);
  const singleSKUHeader = formatMessage(palletAnalyticsMessages.singleSKUBoxes);

  const tableHeaders = [
    formatMessage(palletAnalyticsMessages.cdsku),
    formatMessage(palletAnalyticsMessages.dsku),
    formatMessage(palletAnalyticsMessages.expectedQuantity),
    formatMessage(palletAnalyticsMessages.bulkEligibility),
  ];

  const tableColumnWidths = ["35%", "35%", "10%", "15%"];

  return {
    palletIdTitle,
    palletAnalyticsDetails,
    pid,
    palletIdPlaceholder,
    loading,
    pidError,
    handleChange,
    bulkBoxReceives,
    multiSKUsBoxes,
    singleSKUBoxes,
    tableHeaders,
    tableColumnWidths,
    bulkEligibleHeader,
    multiSKUHeader,
    singleSKUHeader,
    handleSubmit,
  };
};
