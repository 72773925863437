import { BoxReset, Stack, Text } from "@deliverr/ui";
import { FacilityCard } from "facility-commons";
import React from "react";
import { useIntl } from "react-intl";
import { commonReceivingMessages } from "warehouse/receiving/content";

export interface ItemDetailsProps {
  scannedBarcode: string;
  dsku: string;
  itemName: string;
}

export const ItemDetails: React.FC<ItemDetailsProps> = ({ dsku, scannedBarcode, itemName }) => {
  const { formatMessage } = useIntl();
  const barcode = scannedBarcode.length ? scannedBarcode : formatMessage(commonReceivingMessages.missingBarcodeLabel);
  return (
    <BoxReset marginBottom={"S2"}>
      <FacilityCard>
        <Stack gap="S1">
          <Text bold>
            {formatMessage(commonReceivingMessages.barcodeLabel, {
              barcode,
            })}
          </Text>
          <Text>
            {formatMessage(commonReceivingMessages.dskuLabel, {
              dsku,
            })}
          </Text>
          <Text appearance="INFO" size="caption">
            {itemName}
          </Text>
        </Stack>
      </FacilityCard>
    </BoxReset>
  );
};
