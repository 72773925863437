import { defineMessages } from "react-intl";

export const UNSOLVABLE_DETAILS_PAGE_LABELS = defineMessages({
  button: {
    id: "warehouse.unsolvableDetailsPage.button",
    defaultMessage: "Submit support ticket",
  },
  placeholder: {
    id: "warehouse.unsolvableDetailsPage.textArea.placeholder",
    defaultMessage: "What additional help do you need to solve this problem?",
  },
  invalidTicketError: {
    id: "warehouse.unsolvableDetailsPage.invalidTicketId",
    defaultMessage: "No valid ticket id or ticket type found",
  },
  emptyDetailsError: {
    id: "warehouse.unsolvableDetailsPage.emptyDetailsError",
    defaultMessage: "Can't submit a ticket without details",
  },
  submitError: {
    id: "warehouse.unsolvableDetailsPage.submitError",
    defaultMessage: "Failed to submit the ticket. Please try again.",
  },
});
