import { defineMessages, MessageDescriptor } from "react-intl";

export enum ConsolidationErrorMessageKey {
  INVALID_LABEL = "INVALID_LABEL",
  NOT_FOUND = "NOT_FOUND",
  NOT_RECEIVABLE = "NOT_RECEIVABLE",
  WAREHOUSE_MISMATCH = "WAREHOUSE_MISMATCH",
}

export const CONSOLIDATION_ERROR_BY_ERROR_CODE: Record<
  ConsolidationErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [ConsolidationErrorMessageKey.INVALID_LABEL]: {
    id: "warehouse.consolidation.invalidLabel",
    defaultMessage: "Consolidation label is invalid. Contact Deliverr to initiate the consolidation.",
  },
  [ConsolidationErrorMessageKey.NOT_FOUND]: {
    id: "warehouse.consolidation.notFound",
    defaultMessage: "Consolidation could not be found.",
  },
  [ConsolidationErrorMessageKey.NOT_RECEIVABLE]: {
    id: "warehouse.consolidation.notReceivable",
    defaultMessage: "Consolidation has been closed. Please contact Deliverr to re-open the consolidation.",
  },
  [ConsolidationErrorMessageKey.WAREHOUSE_MISMATCH]: {
    id: "warehouse.consolidation.warehouseMismatch",
    defaultMessage: "Consolidation is not intended for this warehouse. Please contact Deliverr.",
  },
});
