import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletItemNotFoundErrorMessageKey {
  PALLET_ITEMS_NOT_FOUND = "PALLET_ITEMS_NOT_FOUND",
}

export const PALLET_ITEMS_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  PalletItemNotFoundErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletItemNotFoundErrorMessageKey.PALLET_ITEMS_NOT_FOUND]: {
    id: "warehouse.receiving.palletItemsNotFound",
    defaultMessage: "No packages found for pallet {palletLabelId}.",
  },
});
