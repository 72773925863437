import { useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAsyncFn, useLifecycles } from "react-use";

import { createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { createReceivingPOSelection } from "../storageReceivingCardCreators";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { userState } from "facility-commons/base/Auth/userState";

export const useReceivingPalletCountFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const [scanError, setScanError] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const [palletCount, setPalletCount] = useState<number>(0);
  const { warehouseId } = useRecoilValue(userState);
  const { hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { storageClient } = useClientsWithAuth();
  const { showModal } = useWarehouseModal();

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_CONTINUE_PALLET_COUNT_RECEIVING);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [{ loading }, handleSubmit] = useAsyncFn(
    async (e): Promise<void> => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      const receivingPalletCount = scanState.poDetails.receivingStartPalletCount ?? 0;
      const isPalletized = scanState.poDetails.isPalletized;
      if (isPalletized && !receivingPalletCount) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.errorEmptyValue),
        });
        setScanError(formatMessage(STORAGE_RECEIVING_MESSAGES.requiredPalletCount));
        return;
      }
      const ctx = {
        fn: "useReceivingPalletCountFlow.handleSubmitPalletCount",
        poNum: scanState.poNum,
        receivingPalletCount,
      };
      logStart(ctx);
      try {
        if (isPalletized) {
          await storageClient.updatePalletInfo(receivingPalletCount, scanState.poNum!.toString(), warehouseId);
        }
        transition([
          {
            card: createReceivingPOSelection({}),
            notification: createSuccessNotification(
              formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanSuccessNotificationTitle)
            ),
            sfx: SoundFx.SUCCESS,
            flash: "SUCCESS",
          },
        ]);
      } catch (err: any) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err),
        });
      }
      return;
    },
    [scanState, warehouseId, storageClient]
  );

  const handlePalletCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const receivingStartPalletCount = parseInt(e.target.value, 10);
    setPalletCount(Number(receivingStartPalletCount));
    setScanState((state) => ({
      ...state,
      poDetails: {
        ...state.poDetails,
        receivingStartPalletCount,
      },
      currentScreen: 2,
    }));
  };

  const handleChange = () => {
    const isPalletized = !scanState.poDetails.isPalletized;
    setScanState((state) => ({
      ...state,
      poDetails: {
        ...state.poDetails,
        isPalletized,
      },
    }));
  };

  return {
    isPalletized: !!scanState.poDetails.isPalletized,
    palletCount,
    scanError,
    handlePalletCountChange,
    handleSubmit,
    handleChange,
    loading,
  };
};
