import { LengthUnit, WeightUnit } from "@deliverr/freight-client";

// TODO: The values in this file must be filled by an API via DB. This is temporary workaround to enable receiving for Heydude.
// https://shopifylogistics.atlassian.net/browse/B2BI-960

export enum BLIND_INBOUND_SELLERID {
  HEYDUDE = "stevenlybinccom",
  TESTING = "kislaysrgmailcom",
}

export const BLIND_INBOUND_PACK_CONFIGURATION = {
  [BLIND_INBOUND_SELLERID.HEYDUDE]: [
    {
      packName: "Box A",
      length: 20,
      width: 18,
      height: 14,
      weight: 14.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box B",
      length: 24,
      width: 19,
      height: 14,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box C",
      length: 23,
      width: 22,
      height: 15,
      weight: 16.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box D",
      length: 21,
      width: 11,
      height: 10,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box E",
      length: 21,
      width: 13,
      height: 11,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: 'Oversized Box (> 25")',
      length: 24,
      width: 20,
      height: 16,
      weight: 16.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
  ],
  [BLIND_INBOUND_SELLERID.TESTING]: [
    {
      packName: "Box A",
      length: 20,
      width: 18,
      height: 14,
      weight: 14.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box B",
      length: 24,
      width: 19,
      height: 14,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box C",
      length: 23,
      width: 22,
      height: 15,
      weight: 16.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box D",
      length: 21,
      width: 11,
      height: 10,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: "Box E",
      length: 21,
      width: 13,
      height: 11,
      weight: 16,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
    {
      packName: 'Oversized Box (> 25")',
      length: 24,
      width: 20,
      height: 16,
      weight: 16.5,
      weightUnit: WeightUnit.Lb,
      lengthUnit: LengthUnit.In,
    },
  ],
};
