import { atom, selector, ReadOnlySelectorOptions } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { ModalInstance, ModalsState } from "facility-commons";

export const visibleWarehouseModalsState = atom<ModalsState>({
  key: WarehouseStateKey.MODALS,
  default: {},
});

export const getVisibleModal: ReadOnlySelectorOptions<ModalInstance | undefined>["get"] = ({ get }) => {
  const visibleModals = get(visibleWarehouseModalsState);
  const modalInstance = Object.entries(visibleModals).find(([modalType, props]) => !!props);

  if (!modalInstance) {
    return;
  }
  return {
    type: modalInstance[0],
    props: modalInstance[1],
  };
};

export const visibleWarehouseModalValue = selector<ModalInstance | undefined>({
  key: WarehouseStateKey.VISIBLE_MODAL,
  get: getVisibleModal,
});
