import { defineMessages } from "react-intl";

export const locationMessages = defineMessages({
  invalidLocation: {
    id: "warehouse.receiving.invalidLocation",
    defaultMessage: "Please enter a valid location.",
  },
  location: {
    id: "warehouse.receiving.location",
    defaultMessage: "Location",
  },
  defaultStow: {
    id: "warehouse.receiving.defaultStow",
    defaultMessage: "Default stow",
  },
  defaultLocation: {
    id: "warehouse.receiving.defaultLocation",
    defaultMessage: "Default Location",
  },
  setDefaultLocation: {
    id: "warehouse.receiving.setDefaultLocation",
    defaultMessage: "Set Default Location",
  },
});
