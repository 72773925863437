import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { Box, BoxReset, ThemeProps } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { CardLabelText, DarkerCardLabelText } from "facility-commons/components/emotion/cards";
import { NumericInput } from "facility-commons/components/Inputs/NumericInput";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import {
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
  STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN,
  STORAGE_INBOUND_MIN_CASE_WEIGHT_LBS,
} from "warehouse/common/messages/storage/StorageCaseDimensions.const";
import { StorageReceivingPODetails } from "../StorageReceivingPODetails";
import { useReceivingSKUCaseInfoScanFlow } from "./useReceivingSKUCaseInfoScanFlow";

const DimensionContainer = styled.div<ThemeProps>(
  ({ theme }) => `
      margin-left: ${theme.spacing.S2};
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
  `
);

export const StorageReceivingSKUCaseInfo: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    caseHeight,
    caseLength,
    caseWeight,
    caseWidth,
    boxesRef,
    handleCaseWeightChange,
    handleCaseHeightChange,
    handleCaseLengthChange,
    handleCaseWidthChange,
    handleClick,
  } = useReceivingSKUCaseInfoScanFlow();

  return (
    <>
      <StorageReceivingPODetails />
      <form onSubmit={handleClick}>
        <FacilityCard as="section">
          <Box paddingBottom={"S3"}>
            <DarkerCardLabelText>
              {formatMessage(STORAGE_RECEIVING_MESSAGES.caseDimsAndWeightTitle)}
            </DarkerCardLabelText>
          </Box>
          <CardLabelText>{formatMessage(STORAGE_RECEIVING_MESSAGES.caseDimsAndWeightLabel)}</CardLabelText>
          <NumericInput
            autoFocus
            value={caseWeight}
            placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.caseWeightInputUnitPlaceholder)}
            onChange={handleCaseWeightChange}
            min={STORAGE_INBOUND_MIN_CASE_WEIGHT_LBS}
            max={STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS}
          />

          <CardLabelText>{formatMessage(STORAGE_RECEIVING_MESSAGES.caseDimensionsTitle)}</CardLabelText>
          <DimensionContainer>
            <BoxReset width="80%">
              <NumericInput
                onChange={handleCaseWidthChange}
                placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.caseWidthInputUnitPlaceholder)}
                ref={boxesRef}
                value={caseWidth}
                max={STORAGE_INBOUND_MAX_CASE_LONGEST_IN}
                min={STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN}
              />
            </BoxReset>
            <BoxReset width="80%">
              <NumericInput
                onChange={handleCaseHeightChange}
                placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.caseHeightInputUnitPlaceholder)}
                ref={boxesRef}
                value={caseHeight}
                max={STORAGE_INBOUND_MAX_CASE_LONGEST_IN}
                min={STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN}
              />
            </BoxReset>

            <BoxReset width="80%">
              <NumericInput
                onChange={handleCaseLengthChange}
                placeholder={formatMessage(STORAGE_RECEIVING_MESSAGES.caseLengthInputUnitPlaceholder)}
                ref={boxesRef}
                value={caseLength}
                max={STORAGE_INBOUND_MAX_CASE_LONGEST_IN}
                min={STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN}
              />
            </BoxReset>
          </DimensionContainer>
        </FacilityCard>
      </form>
    </>
  );
};
