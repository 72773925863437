import React from "react";
import styled from "@emotion/styled";
import { Text } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";
import { ADDITIONAL_DETAILS_LABELS } from "../../../content";

const DetailsContainer = styled.div`
  overflow-wrap: break-word;
`;

interface AdditionalDetailsSectionProps {
  additionalDetails: string;
}

export const AdditionalDetailsSection: React.FC<AdditionalDetailsSectionProps> = ({ additionalDetails }) => (
  <DetailsContainer>
    <Text bold>
      <FormattedMessage {...ADDITIONAL_DETAILS_LABELS.additionalDetails} />
    </Text>
    <Text>{additionalDetails}</Text>
  </DetailsContainer>
);
