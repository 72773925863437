import { LABEL_DPI } from "@deliverr/legacy-inbound-client";
import store from "store";

enum PrinterZebraStorageKey {
  LAST_SELECTED_PRINTER_KEY = "LAST_SELECTED_PRINTER_KEY",
  LAST_USED_PRINTER_ADDRESS = "LAST_USED_PRINTER_ADDRESS",
  LAST_USED_DPI_KEY = "LAST_USED_DPI_KEY",
  LAST_USED_HORIZONTAL_OFFSET = "LAST_USED_HORIZONTAL_OFFSET",
}

const createSetStorageFn = <T>(key: PrinterZebraStorageKey) => (val: T): void => {
  return store.set(key, val);
};

const createGetStorageFn = <T>(key: PrinterZebraStorageKey) => (): T | undefined => {
  return store.get(key);
};

const createStorageFns = <T>(key: PrinterZebraStorageKey) => ({
  get: createGetStorageFn<T>(key),
  set: createSetStorageFn<T>(key),
});

export const { get: getLastConnectedPrinterId, set: setLastConnectedPrinterId } = createStorageFns<string>(
  PrinterZebraStorageKey.LAST_SELECTED_PRINTER_KEY
);
export const { get: getLastConnectedPrinterAddress, set: setLastConnectedPrinterAddress } = createStorageFns<string>(
  PrinterZebraStorageKey.LAST_USED_PRINTER_ADDRESS
);
export const { get: getLastUsedDpi, set: setLastUsedDpi } = createStorageFns<LABEL_DPI>(
  PrinterZebraStorageKey.LAST_USED_DPI_KEY
);
export const { get: getLastUsedHorizontalOffset, set: setLastUsedHorizontalOffset } = createStorageFns<number>(
  PrinterZebraStorageKey.LAST_USED_HORIZONTAL_OFFSET
);
