import React from "react";
import styled from "@emotion/styled";
import { TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { Title, Text } from "@deliverr/ui";
import { MessageDescriptor, useIntl } from "react-intl";

import ticketTodo from "facility-commons/common/assets/ticket-todo.png";
import ticketCreated from "facility-commons/common/assets/ticket-created.png";
import ticketClosed from "facility-commons/common/assets/ticket-closed.png";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

interface TicketListEmptyCardProps {
  currentStatus: TicketCenterNonComplianceCaseStatus;
}

interface TicketListEmptyCardContent {
  title: MessageDescriptor;
  body: MessageDescriptor;
  image: string;
}

const TicketListCard = styled(FacilityCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TicketBody = styled(Text)(
  ({ theme }) => `
  text-align: center;
  min-height: 50px;
  margin: ${theme.spacing.S4} 0;
`
);

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

export const emptyCardContent: Record<TicketCenterNonComplianceCaseStatus, TicketListEmptyCardContent> = {
  [TicketCenterNonComplianceCaseStatus.TODO]: {
    title: {
      id: "warehouse.ticketListEmpty.todoTitle",
      defaultMessage: "Hooray!",
    },
    body: {
      id: "warehouse.ticketListEmpty.todoMessage",
      defaultMessage: "You don’t have any tickets to resolve.",
    },
    image: ticketTodo,
  },
  [TicketCenterNonComplianceCaseStatus.CREATED]: {
    title: {
      id: "warehouse.ticketListEmpty.createdTitle",
      defaultMessage: "You have no tickets",
    },
    body: {
      id: "warehouse.ticketListEmpty.createdMessage",
      defaultMessage: "Select Create Ticket if you have a non-compliance or damage to report.",
    },
    image: ticketCreated,
  },
  [TicketCenterNonComplianceCaseStatus.CLOSED]: {
    title: {
      id: "warehouse.ticketListEmpty.closedTitle",
      defaultMessage: "You have no closed tickets",
    },
    body: {
      id: "warehouse.ticketListEmpty.closedMessage",
      defaultMessage: "Click Close in the ticket details if your tickets are completed.",
    },
    image: ticketClosed,
  },
};

export const TicketListEmptyCard: React.FC<TicketListEmptyCardProps> = ({ currentStatus }) => {
  const { formatMessage } = useIntl();
  const { title, body, image } = emptyCardContent[currentStatus];

  return (
    <TicketListCard>
      <Title as="h3" displayAs="h4">
        {formatMessage(title)}
      </Title>
      <TicketBody>{formatMessage(body)}</TicketBody>
      <Image src={image} aria-hidden="true" />
    </TicketListCard>
  );
};
