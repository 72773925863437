import React from "react";
import { FormattedMessage } from "react-intl";
import { Text } from "@deliverr/ui";
import { CardColumn, LeftAlignedContainer } from "facility-commons/components/emotion/cards";
import { commonMessages } from "facility-commons/labels";
import { commonLotMessages } from "warehouse/receiving/content";

interface FefoSectionProps {
  isFefoEnabled: boolean;
  reportedExpiry?: string;
  isLotEnabled: boolean;
  reportedLot?: string;
}

export const FefoSection: React.FC<FefoSectionProps> = ({
  isFefoEnabled,
  reportedExpiry,
  isLotEnabled,
  reportedLot,
}) => (
  <CardColumn>
    <LeftAlignedContainer>
      {isFefoEnabled && (
        <CardColumn>
          <Text bold>
            <FormattedMessage id="expDate" defaultMessage="Expiration date" />
          </Text>
          {reportedExpiry || <FormattedMessage {...commonMessages.unknown} />}
        </CardColumn>
      )}
      {isLotEnabled && (
        <CardColumn>
          <Text bold>
            <FormattedMessage {...commonLotMessages.lotNumber} />
          </Text>
          {reportedLot || <FormattedMessage {...commonMessages.unknown} />}
        </CardColumn>
      )}
    </LeftAlignedContainer>
  </CardColumn>
);
