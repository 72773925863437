import { format } from "date-fns";

const DHL_MANIFEST_DATE = "MM_dd_yyyy_h:mm_a";

export const formatManifestDownloadUrl = (
  response: string = "",
  warehouseId: string
): { url: string; fileName: string } => {
  const url = `data:application/pdf;base64,${response}`;
  const date = format(new Date(), DHL_MANIFEST_DATE);
  const fileName = `DHL_Manifest_${warehouseId}_${date}`;

  return {
    url,
    fileName,
  };
};
