import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletAsnBoxMismatchMessageKey {
  PALLET_ASN_BOX_MISMATCH = "PALLET_ASN_BOX_MISMATCH",
}

export const PALLET_ASN_BOX_MISMATCH_ERROR_BY_ERROR_CODE: Record<
  PalletAsnBoxMismatchMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletAsnBoxMismatchMessageKey.PALLET_ASN_BOX_MISMATCH]: {
    id: "warehouse.receiving.palletAsnBoxMismatch",
    defaultMessage: "Packages and box labels do not match for pallet {palletLabelId}.",
  },
});
