import { useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { useRouter } from "facility-commons/hooks";
import { NCRoutes } from "../../base/routes";
import { errorMessages } from "warehouse/common/errorMessages";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import {
  currentProductLotFefoDetailsState,
  currentProductState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { userState } from "facility-commons/base/Auth/userState";

export const useNcQuantityCard = () => {
  const [boxesError, setBoxesError] = useState<string>("");
  const [unitsError, setUnitsError] = useState<string>("");

  const { formatMessage } = useIntl();
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { numberOfBoxes, quantityPerBox } = useRecoilValue(currentProductState);
  const { isFefoEnabled, isLotEnabled } = useRecoilValue(currentProductLotFefoDetailsState);
  const { push } = useRouter();
  const { isStorage } = useRecoilValue(userState);

  const progress = () => {
    if (numberOfBoxes <= 0) {
      return setBoxesError(formatMessage(errorMessages.greaterThanZero));
    }

    if (quantityPerBox <= 0) {
      return setUnitsError(formatMessage(errorMessages.greaterThanZero));
    }

    push(isStorage ? NCRoutes.STORAGE_LOCATION : NCRoutes.DETAILS);
  };

  const updateNumberOfBoxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBoxesError("");
    updateCurrentProduct("numberOfBoxes", +event.target.value);
  };

  const updateQuantityPerBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitsError("");
    updateCurrentProduct("quantityPerBox", +event.target.value);
  };
  const showNumberOfBoxesField = !isFefoEnabled && !isLotEnabled;

  return {
    numberOfBoxes,
    boxesError,
    formatMessage,
    hasNegativeValues: numberOfBoxes < 0 || quantityPerBox < 0,
    progress,
    quantityPerBox,
    unitsError,
    showNumberOfBoxesField,
    updateNumberOfBoxes,
    updateQuantityPerBox,
  };
};
