import { useSetRecoilState } from "recoil";
import { defaultLocationState } from "warehouse/receiving/ReceivingState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { setProp } from "facility-commons/utils";
import { useRouter } from "facility-commons/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useMount } from "react-use";
import { locationMessages } from "warehouse/receiving/content";
import { useIntl } from "react-intl";
import { getDefaultLocationFromLocalStorage } from "warehouse/receiving/utils";

export const useDefaultLocationLink = () => {
  const setDefaultLocationData = useSetRecoilState(defaultLocationState);
  const { match } = useRouter();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);

  const { formatMessage } = useIntl();
  const defaultLocation = getDefaultLocationFromLocalStorage();

  // after the user sets a default location or backs out of the location input screen
  // we set isSettingLocation to false so the location card can be used to submit a receive
  useMount(() => {
    setDefaultLocationData(setProp("isSettingDefaultLocation", false));
  });

  //
  const handleOnLinkClick = () => {
    setDefaultLocationData(setProp("isSettingDefaultLocation", true));
    setWarehouseAppState(setProp("pageTitle", formatMessage(locationMessages.setDefaultLocation)));
  };

  const path = `${match.path}/${ReceivingPath.LOCATION}`;

  return {
    handleOnLinkClick,
    defaultLocation,
    path,
  };
};
