import React from "react";
import { Stack, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { SkuCounterContentsItem, SkuCounterContentsItemProps } from "./SkuCounterContentsItem";
import { SKU_COUNTER_LABELS } from "./SkuCounter.labels";
import { useScanner } from "facility-commons/hooks/useScanner";

const FixedHeightStack = styled(Stack)`
  height: calc(100vh - 158px); // fix height for scrolling accounts for nav bar and Done Scanning button
  overflow: auto;
`;

export interface SkuCounterContentsProps {
  emptyState: JSX.Element;
  isLoading?: boolean;
  items: Omit<SkuCounterContentsItemProps, "onChange" | "maxUnitsPerPackage">[];
  maxUnitsPerPackage: number;
  onChange: (barcode: string, qty?: number) => void;
  onScan?: (barcode: string) => void;
}

const CenterText = styled(Text)`
  text-align: center;
`;

export const SkuCounterContents: React.FC<SkuCounterContentsProps> = ({
  emptyState,
  isLoading,
  items,
  maxUnitsPerPackage,
  onChange,
  onScan,
}) => {
  useScanner({ onChange: onScan ?? onChange });
  const { formatMessage } = useIntl();

  if (items?.length < 1) {
    return emptyState;
  }

  return (
    <FixedHeightStack gap="S2">
      {items.map((item, i) => (
        <SkuCounterContentsItem
          {...item}
          key={`sku-counter-item-${item.barcode}`}
          loading={isLoading}
          maxUnitsPerPackage={maxUnitsPerPackage}
          onChange={onChange}
        />
      ))}
      <CenterText appearance="INFO">{formatMessage(SKU_COUNTER_LABELS.title)}</CenterText>
    </FixedHeightStack>
  );
};
