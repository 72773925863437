import { PrinterConnectionType, PrinterType, PrinterSearchOptions } from "./ZebraTypes";

const SEARCH_BY_ADDRESS_TIMEOUT_MS = 10 * 1000;

// search for all zebra printers on a network (TCP)
export const NETWORK_PRINTERS_SEARCH_OPTIONS: PrinterSearchOptions = {
  connectionType: PrinterConnectionType.TCP,
  printerType: PrinterType.ZEBRA,
};

// search all zebra printer over any connection type (TCP, Bluetooth, USB, etc.)
export const ALL_PRINTERS_SEARCH_OPTIONS: PrinterSearchOptions = {
  connectionType: PrinterConnectionType.ANY,
  printerType: PrinterType.ZEBRA,
};

// search for a printer at a single address
export const getSearchAtAddressPrinterSearchOptions = (
  deviceAddress: string,
  printerSearchOptions: PrinterSearchOptions = NETWORK_PRINTERS_SEARCH_OPTIONS
): PrinterSearchOptions => ({
  ...printerSearchOptions,
  timeout: SEARCH_BY_ADDRESS_TIMEOUT_MS,
  deviceAddress,
});
