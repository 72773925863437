import React from "react";

import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { useStorageLocationCard } from "./useStorageLocationCard";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { STORAGE_MESSAGES } from "warehouse/common/messages/storage";

export const StorageLocationCard: React.FC = () => {
  const { location, locationError, formatMessage, handleChange, handleSubmit, loading } = useStorageLocationCard();

  return (
    <StackedInputCard
      errorMessage={locationError}
      illustration={<OpenBox />}
      loading={loading}
      message={formatMessage(STORAGE_MESSAGES.ncLocationInstruction)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(STORAGE_MESSAGES.ncLocationPlaceholder)}
      secondary
      title={formatMessage(STORAGE_MESSAGES.ncLocationTitle)}
      value={location}
    />
  );
};
