import React from "react";

import { commonUploadMessages } from "facility-commons/labels";
import { NcCardStack } from "../../components";
import { useProductUploadCard } from "./useProductUpload";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";

export const ProductUploadCard: React.FC = () => {
  const {
    allImagesUploaded,
    backPictureRegister,
    formatMessage,
    frontPictureRegister,
    onBackFileChange,
    onFrontFileChange,
    proceed,
  } = useProductUploadCard();

  return (
    <NcCardStack
      continueDisabled={!allImagesUploaded}
      continueOnClick={proceed}
      title={formatMessage(commonUploadMessages.identifyProductTitle)}
    >
      <NonComplianceUploadItem
        iconText={formatMessage(commonUploadMessages.frontOfSku)}
        id="front-product-photo"
        {...frontPictureRegister}
        onSuccessfulUpload={onFrontFileChange}
      />
      <NonComplianceUploadItem
        iconText={formatMessage(commonUploadMessages.backOfSku)}
        id="back-product-photo"
        {...backPictureRegister}
        onSuccessfulUpload={onBackFileChange}
      />
    </NcCardStack>
  );
};
