import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, IconV2 } from "@deliverr/ui";

interface Props {
  loading: boolean;
  text?: React.ReactNode;
  size?: FontAwesomeIconProps["size"];
  className?: string;
}

const Container = styled.div<Record<string, unknown>, DefaultTheme>`
  display: flex;
  justify-content: center;
  ${({ hasText }) =>
    !hasText
      ? ""
      : `
    flex-direction: column;
    align-items: center;
  `}
  color: ${({ theme }) => theme.colors.NEUTRAL[300]};
`;

const TextContainer = styled.div`
  margin-bottom: 0.75rem;
`;

export const LoadingSpinner: React.FC<Props> = ({ className, loading, size, text, children }) => {
  return loading ? (
    <Container hasText={Boolean(text)} className={className}>
      {text && <TextContainer>{text}</TextContainer>}
      <IconV2 type="loading" size={size ?? "5x"} spin />
    </Container>
  ) : (
    <>{children}</>
  );
};

export const CenterSpinner = styled(LoadingSpinner)`
  align-items: center;
  height: 100vh;
`;
