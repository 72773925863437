import React from "react";
import { Button } from "@deliverr/ui";
import { useUnexpectedPalletWarningModal } from "./useUnexpectedPalletWarningModal";
import { SoundFx } from "facility-commons/common/sfx/SoundFx";
import { ModalTemplate, warningModalIcon } from "facility-commons";
import { TransferPalletRerouteReason } from "@deliverr/legacy-inbound-client";

export interface UnexpectedPalletWarningModalProps {
  rerouteReason: TransferPalletRerouteReason;
}

export const UnexpectedPalletWarningModal: React.FC<UnexpectedPalletWarningModalProps> = ({ rerouteReason }) => {
  const { onConfirmClick, reroutedAsnId } = useUnexpectedPalletWarningModal();

  return (
    <ModalTemplate
      title={`${
        rerouteReason === TransferPalletRerouteReason.ARRIVED_AT_UNEXPECTED_FC ? "Misrouted" : "Rerouted"
      } Pallet!`}
      content={
        <>
          <p>
            Clearly label pallet with <strong>PO # {reroutedAsnId}</strong> to help your receiving team.
          </p>
          <br />
          <p>{`There is no need to contact Deliverr`}</p>
        </>
      }
      iconProps={warningModalIcon}
      soundFx={SoundFx.INFO}
    >
      <Button block onClick={onConfirmClick}>
        Done
      </Button>
    </ModalTemplate>
  );
};
