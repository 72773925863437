import { Card, IconV2, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Printer } from "facility-commons/printer/ZebraTypes";
import { CONNECTED_PRINTER_CARD_LABELS } from "./ConnectedPrinterCard.labels";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S4};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    &:hover {
      cursor: pointer;
    }
  `
);

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S1};
  `
);

const StyledPrinterText = styled.div<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[200]};
    display: flex;
    align-items: center;
  `
);

const ConnectedIndicator = styled.span<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.GREEN[300]};
    margin-right: ${theme.spacing.S1}
  `
);

interface ConnectedPrinterCardProps {
  connectedPrinter?: Printer;
  onClick: () => void;
}

export const ConnectedPrinterCard: React.FC<ConnectedPrinterCardProps> = ({ connectedPrinter, onClick }) => {
  return (
    <StyledCard onClick={onClick}>
      <StyledText bold>
        <FormattedMessage {...CONNECTED_PRINTER_CARD_LABELS.printerLabel} />
      </StyledText>
      <StyledPrinterText>
        {connectedPrinter ? (
          <div>
            <ConnectedIndicator>&#x2022;</ConnectedIndicator>
            {connectedPrinter.deviceName}
          </div>
        ) : (
          <FormattedMessage {...CONNECTED_PRINTER_CARD_LABELS.none} />
        )}
        <IconV2 type="angle-right" />
      </StyledPrinterText>
    </StyledCard>
  );
};
