import React from "react";
import { FlowCardMap } from "facility-commons/flow/types/FlowMaps";
import { RSOutboundsFlowCardType } from "../types/RSOutboundsFlowCardType";
import { ShipmentCard } from "warehouse/rs-outbounds/components/ShipmentCard";
import { ShipmentSummaryCard } from "warehouse/rs-outbounds/components/ShipmentSummaryCard";
import { CreatePackageCard } from "warehouse/rs-outbounds/components/CreatePackageCard";
import { SelectBoxSizeCard } from "warehouse/rs-outbounds/components/SelectBoxSizeCard";
import { PrintLabelCard } from "warehouse/rs-outbounds/components/PrintLabelCard";
import { PendingItemsCard } from "warehouse/rs-outbounds/components/PendingItemsCard";

export const rsOutboundsFlowCardMap: FlowCardMap = {
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SHIPMENT_SCAN]: ShipmentCard,
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SHIPMENT_SUMMARY]: ShipmentSummaryCard,
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_CREATE_PACKAGE]: CreatePackageCard,
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_SELECT_BOX_SIZE]: SelectBoxSizeCard,
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_PRINT_LABELS]: PrintLabelCard,
  [RSOutboundsFlowCardType.RS_OUTBOUNDS_PACKING_PENDING_ITEMS]: PendingItemsCard,
};
