import React from "react";
import { ThemeProps, LocalizedMessage, Stack, Text, Title } from "@deliverr/ui";
import styled from "@emotion/styled";

import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { ContinueButton } from "warehouse/common/components";

export interface NcCardStackProps {
  continueDisabled?: boolean;
  continueLabel?: LocalizedMessage;
  continueOnClick: () => void;
  isLoading?: boolean;
  instructions?: string;
  sibling?: JSX.Element; // component to be shown adjacent to the main card content
  title: LocalizedMessage;
}

const InstructionsText = styled(Text)<ThemeProps>`
  margin-bottom: ${({ theme }) => theme.spacing.S4};
`;

/**
 * Wrapper component used to replicate the common styling among all non-textinputcard Non Compliance components
 */
export const NcCardStack: React.FC<NcCardStackProps> = (props) => {
  const { children, continueDisabled, continueOnClick, continueLabel, instructions, sibling, title, isLoading } = props;

  return (
    <Stack>
      <FacilityCard>
        <Stack direction="VERTICAL" gap="S2">
          <Title as="h4" displayAs="h4">
            {title}
          </Title>
          {instructions && <InstructionsText>{instructions}</InstructionsText>}
          {children}
        </Stack>
      </FacilityCard>

      {!!sibling && sibling}

      <ContinueButton
        block
        disabled={continueDisabled}
        onClick={continueOnClick}
        label={continueLabel}
        loading={isLoading}
      />
    </Stack>
  );
};
