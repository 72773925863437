import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { BulkReceiveData } from "./Types";

/** bulk receive data */
export const INITIAL_BULK_RECEIVE_DATA: BulkReceiveData = {
  isBulkEligible: false,
  isExpeditedBulkEligible: false,
  numIdenticalBoxes: null,
  numUnitsPerBox: null,
  expeditedBulkBoxLabels: [],
};
export const bulkReceiveState = atom<BulkReceiveData>({
  key: WarehouseStateKey.BULK_RECEIVE,
  default: INITIAL_BULK_RECEIVE_DATA,
});
