import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  returnReceiveItemInspectionStateAtom,
  returnReceiveReceivingDataStateAtom,
  returnReceiveReturnOrderStateAtom,
} from "../../state";
import { createCommitSuccessNotification } from "warehouse/receiving/base";
import { useInspectionRouting, useSubmitInspectionResults } from "../../hooks";

export const useLocationScan = () => {
  const { formatMessage } = useIntl();
  const { addNotification, successResponse, errorResponse } = useCommonFlow();
  const { inspectNextItemOrStartNextOrder } = useInspectionRouting();
  const { submitInspectionResults } = useSubmitInspectionResults();
  const [submissionError, setSubmissionError] = useState<string>("");
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const itemInspectionData = useRecoilValue(returnReceiveItemInspectionStateAtom);
  const [returnReceiveData, setReturnReceiveData] = useRecoilState(returnReceiveReceivingDataStateAtom);
  const { dsku, barcode } = itemInspectionData;
  const { location, quantity, expirationDate, lotNumber } = returnReceiveData;

  const messages = {
    cardTitle: formatMessage(commonReturnReceiveMessage.enterOrScanRestockLocation),
    inputPlaceHolder: formatMessage(commonReturnReceiveMessage.restockLocation),
  };

  const onLocationInputChange = (value: string) => {
    setSubmissionError("");
    setReturnReceiveData(setProp("location", value));
  };

  const [submitState, submitReturnReceive] = useAsyncFn(
    async (locationInput: string) => {
      setSubmissionError("");
      if (!locationInput.length) {
        setSubmissionError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
        return;
      }

      const ctx = logStart({
        fn: "useLocationScan.submitReturnReceive",
        returnOrderData,
        itemInspectionData,
        returnReceiveData,
        location: locationInput,
      });

      try {
        await submitInspectionResults({ receiveLocation: locationInput, returnsLocation: null });
        successResponse();
        addNotification(
          createCommitSuccessNotification({
            barcode,
            dsku,
            quantity,
            loc: location,
            expirationDate: expirationDate ?? undefined,
            lotNumber: lotNumber ?? undefined,
          })
        );
        inspectNextItemOrStartNextOrder();
      } catch (error: any) {
        log({ ...ctx, error }, "error submitting return receive");
        errorResponse();
        setSubmissionError(String(error.message));
      }
    },
    [location]
  );

  const handleChange = genericOnScannerInputChange(location, onLocationInputChange, submitReturnReceive);

  return {
    messages,
    submissionError,
    location,
    disableInput: submitState.loading,
    handleChange,
    submitReturnReceive,
  };
};
