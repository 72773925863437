import { defineMessages } from "react-intl";

export const STORAGE_PRINT_SSCC_LABELS_MESSAGES = defineMessages({
  printSSCCLabels: {
    id: "storage.printSSCCLabels",
    defaultMessage: "Print SSCC Labels",
  },
  labelsDownloadingMessage: {
    id: "storage.labelsDownloadingMessage",
    defaultMessage: "Creating SSCC labels...",
  },
  doNotNavigateAwayMessage: {
    id: "storage.doNotNavigateAwayMessage",
    defaultMessage: "Do not navigate away from this page.",
  },
  printSSCCLabelsError: {
    id: "storage.printSSCCLabelsError",
    defaultMessage: "Error while printing labels. Please try again.",
  },
});
