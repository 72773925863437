import { useAuth0 } from "@auth0/auth0-react";

import { WAREHOUSE_AUTH_AUDIENCE } from "facility-commons/base/Auth";
import {
  ClientApi,
  setTokenFunctionInClients,
  crossdockClient,
  returnsClient,
  storageClient,
  inventoryClient,
  s3FileHandlerClient,
  prepClient,
  inboundClient,
  nonComplianceClient,
  productClient,
  wotClient,
  crossdockClientV2,
  nonComplianceClientV2,
  warehousePublicClient,
  wholesaleClient,
} from "facility-commons/base/Clients";
import { useMemo } from "react";

export const useClientsWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    const clientsArray = [
      nonComplianceClient,
      warehousePublicClient,
      inboundClient,
      productClient,
      crossdockClient,
      crossdockClientV2,
      returnsClient,
      s3FileHandlerClient,
      prepClient,
      storageClient,
      inventoryClient,
      wotClient,
      nonComplianceClientV2,
      wholesaleClient,
    ];
    const accessToken = getAccessTokenSilently({ audience: WAREHOUSE_AUTH_AUDIENCE });
    setTokenFunctionInClients(clientsArray as ClientApi[], accessToken);

    return {
      nonComplianceClient,
      warehousePublicClient,
      inboundClient,
      productClient,
      crossdockClient,
      crossdockClientV2,
      returnsClient,
      s3FileHandlerClient,
      prepClient,
      storageClient,
      inventoryClient,
      wotClient,
      nonComplianceClientV2,
      wholesaleClient,
    };
  }, [getAccessTokenSilently]);
};
