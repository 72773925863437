import React from "react";
import { Button, ButtonProps, LocalizedMessage } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";

import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { commonMessages } from "facility-commons/labels";

const MaxWidthButton = styled(Button)`
  max-width: ${MAX_CARD_WIDTH};
`;

export interface ContinueButtonProps extends ButtonProps {
  label?: LocalizedMessage;
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({ label, ...props }) => {
  const { formatMessage } = useIntl();
  return (
    <MaxWidthButton data-testid="continue" {...props}>
      {label ?? formatMessage(commonMessages.continue)}
    </MaxWidthButton>
  );
};
