import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { atom } from "recoil";

export const reroutedAsnIdState = atom<string>({
  key: WarehouseStateKey.ARRIVAL_SCAN_REROUTED_ASN,
  default: "",
});

export const arrivalInputState = atom<string>({
  key: WarehouseStateKey.ARRIVAL_SCAN_INPUT,
  default: "",
});
