import { SoundFx, appSfx } from "facility-commons/common/sfx";
import { useCallback } from "react";

export const useSoundEffect = () => {
  const playSfx = useCallback((sfx: SoundFx): void => {
    appSfx[sfx].play();
  }, []);

  const maybePlaySfx = useCallback(
    (sfx?: SoundFx): void => {
      if (sfx) {
        playSfx(sfx);
      }
    },
    [playSfx]
  );

  return {
    playSfx,
    maybePlaySfx,
  };
};
