import { validCDSKU } from "facility-commons/utils/config";
import { validateString } from "./stringUtils";

export const CDSKU_LENGTH = 11;
export const WISHID_LENGTH = 14;
export const MAX_BARCODE_LENGTH = Math.max(CDSKU_LENGTH, WISHID_LENGTH);

export const isValidCdsku = validateString(validCDSKU);

export function formatCdsku(rawCdsku: string): string {
  return rawCdsku.trim().toUpperCase();
}

// want this to include lengths greater than cdsku in case user inputs a value too large
export function meetsBarcodeLength(value: string): boolean {
  return (
    (value.startsWith("CD") && value.length >= CDSKU_LENGTH) ||
    (value.startsWith("WI") && value.length >= WISHID_LENGTH) ||
    value.length >= MAX_BARCODE_LENGTH
  );
}
