import { useIntl } from "react-intl";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { useRecoilValue } from "recoil";
import {
  currentProductLotFefoDetailsState,
  currentProductState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { NCRoutes } from "warehouse/ticket-center/new/non-compliance/base/routes";
import { useRouter } from "facility-commons/hooks";
import { isEmpty } from "lodash";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { lotNumberMessages } from "../../content";

export const useLotNumber = () => {
  const { formatMessage } = useIntl();
  const { reportedLot = "" } = useRecoilValue(currentProductState);
  // This isLotEnabled and isFefoEnabled is set from the barcode scan of
  // the NC ticket creation flow OR the receiving flow
  const { isFefoEnabled } = useRecoilValue(currentProductLotFefoDetailsState);
  const { updateCurrentProduct, useInitializeFefoNCProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { hideModal, showModal } = useWarehouseModal();

  useInitializeFefoNCProduct({ initializeDsku: true });

  const updateLotNumber = (e) => {
    const newLot = e.target.value;
    updateCurrentProduct("reportedLot", newLot || null);
  };

  const proceed = () => {
    if (isFefoEnabled) {
      push(NCRoutes.EXPIRATION_DATE);
    } else {
      push(NCRoutes.BCL_UPLOAD);
    }
  };

  const onSkip = () => {
    showModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
      onConfirm: () => {
        updateCurrentProduct("reportedLot", null);
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
        proceed();
      },
      onCancel: () => {
        hideModal(WarehouseModal.GENERIC_WARNING_CONFIRMATION_MODAL);
      },
      title: formatMessage(lotNumberMessages.skipLotInputConfirmationModalTitle),
    });
  };

  const disableButton = isEmpty(reportedLot);

  return {
    formatMessage,
    reportedLot,
    disableButton,
    updateLotNumber,
    proceed,
    onSkip,
  };
};
