import { BarcodeFormat, getBarcodeFormat } from "@deliverr/commons-utils";

import { ProductMinimal } from "@deliverr/returns-client";

export const getProductBarcode = (product: ProductMinimal): string => {
  let barcode: string | undefined;
  if (product.barcodes) {
    barcode = product.barcodes.find(
      (barcode) => getBarcodeFormat(barcode) === BarcodeFormat.UPC || getBarcodeFormat(barcode) === BarcodeFormat.EAN
    );

    if (!barcode) {
      barcode = product.barcodes.find((barcode) => getBarcodeFormat(barcode) === BarcodeFormat.FNSKU);
    }
  }

  // TODO: Currently ASIN is not a barcode format. To be handled later.

  if (!barcode) {
    return product.upc ?? product.fnsku ?? product.dsku;
  }

  return barcode;
};
