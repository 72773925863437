import React, { useMemo } from "react";
import styled from "styled-components";
import { MessageWithText } from "react-chatbot-ui/dist/shared/models";
import { MsgParser } from "./MessageParser";

interface Props {
  text: MessageWithText["text"];
}

const StyledText = styled.div`
  white-space: pre-wrap;
`;

export const Text = (props: Props) => {
  return useMemo(
    () => (
      <StyledText>
        <MsgParser string={props.text} />
      </StyledText>
    ),
    [props.text]
  );
};
