import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useUnmount } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { FlowVisibleNotifications } from "facility-commons/flow/FlowVisibleNotifications";
import { notificationsState } from "facility-commons/flow/flowState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { setProp } from "facility-commons/utils";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehousePortalRoutes } from "warehouse/routes";
import { receiveFlowTypeState } from "./ReceivingState/ReceiveFlowTypeState";
import { BoxConfigSummary } from "./ReceivingState/Types";
import {
  AdditionalUnitsConfirmationCard,
  CdskuCard,
  ConfirmationCard,
  ConsolidationCard,
  LocationCardV2,
  LotCard,
  MultiConfirmationCard,
  PalletAnalyticsV2,
  PalletAnalyticsV2BoxSummary,
  PalletAnalyticsV2SelectBoxConfiguration,
  POCard,
  ProductCardExpanded,
  QuantityCardV2,
  ReceivingButtonsContainer,
  SkuCard,
} from "./components";
import { PalletAnalytics } from "./components/PalletsAnalytics";
import { ReceiveTypeSelector } from "./components/ReceiveTypeSelector";
import { ReviewProducts } from "./components/ReviewProducts";
import { RouteGuard } from "./components/RouteGuard";
import { BulkScanCard } from "./components/cards/BulkScanCard";
import { PalletCard } from "./components/cards/PalletCard";
import { ReportBoxIssue } from "./problem-solver/components/ReportBoxIssue/ReportBoxIssue";
import { ReceivingPath } from "./routes";
import { ReturnReceiveCard } from "./ReturnReceive/components/ReturnReceiveCard";
import { ReceivingImageUploadCard } from "./components/ReceivingImageUploadCard";

export const ReceivingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: ${MAX_CARD_WIDTH};
  width: 100%;
  height: 100%;
`;

export const CardContainer = styled.section<Record<string, unknown>, DefaultTheme>`
  flex-grow: 1;
  width: 100%;

  // reduce space between cards and buttons container on larger devices
  @media (min-width: ${({ theme }) => theme.breakpoints.MD}) {
    flex-grow: 0;
    margin-bottom: 1rem;
  }
`;

export const Receiving: React.FC = () => {
  const notifications = useRecoilValue(notificationsState);

  const { path } = useRouteMatch();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { resetNotifications } = useCommonFlow();
  const containerRef = useRef<HTMLDivElement>(null);

  useUnmount(() => {
    setWarehouseAppState(setProp("pageTitle", ""));
    setWarehouseAppState(setProp("pageSubtitle", ""));
    resetNotifications();
  });

  const receivingFlowStarted = useRecoilValue(receiveFlowTypeState);
  const router = useRouter();

  useEffect(() => {
    if (!receivingFlowStarted) {
      router.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivingFlowStarted]);

  useEffect(() => {
    if (notifications.length > 0) {
      // I hate this as much as you do.
      // When notifications pop up, they ruin our scroll position
      // Design specifically asked for this scroll to top behavior, and I couldn't figure out a better way to do it
      // Without this tiny delay, the parent container won't actually scroll all the way to the top
      // We need to use the parent container because it's the one that has scrolling behavior
      // I tried just doing document.scroll or window.scroll, and it doesn't provide the behavior we want
      // because the only portion of this app that scrolls is the "middle" since the top and bottom are fixed
      setTimeout(() => containerRef?.current?.parentElement?.scrollTo(0, 0), 100);
    }
  }, [notifications.length]);

  const appendToPath = (subPath) => {
    return `${path}/${subPath}`;
  };

  return (
    <ReceivingContainer ref={containerRef}>
      <FlowVisibleNotifications notifications={notifications.slice(-1)} />
      <CardContainer>
        <RouteGuard /> {/* requires confirmation before exiting receiving */}
        <Switch>
          <Route path={appendToPath(ReceivingPath.REPORT_ISSUE)} component={ReportBoxIssue} />
          <Route path={appendToPath(ReceivingPath.PALLET)} component={PalletCard} />
          <Route path={appendToPath(ReceivingPath.CDSKU)} component={CdskuCard} />
          <Route path={appendToPath(ReceivingPath.PO)} component={POCard} />
          <Route path={appendToPath(ReceivingPath.LOT)} component={LotCard} />
          <Route path={appendToPath(ReceivingPath.SKU)} component={SkuCard} />
          <Route path={appendToPath(ReceivingPath.EXPIRATION_LOT_CONFIRMATION)} component={ConfirmationCard} />
          <Route
            path={appendToPath(ReceivingPath.MULTI_EXPIRATION_LOT_CONFIRMATION)}
            component={MultiConfirmationCard}
          />
          <Route
            path={appendToPath(ReceivingPath.RECEIVE_MORE_UNITS_CONFIRMATION)}
            component={AdditionalUnitsConfirmationCard}
          />
          <Route path={appendToPath(ReceivingPath.QUANTITY)} component={QuantityCardV2} />
          <Route path={appendToPath(ReceivingPath.IMAGES)} component={ReceivingImageUploadCard} />
          <Route path={appendToPath(ReceivingPath.BULK)} component={BulkScanCard} />
          <Route path={appendToPath(ReceivingPath.LOCATION)} component={LocationCardV2} />
          <Route path={appendToPath(ReceivingPath.REVIEW_PRODUCTS)} component={ReviewProducts} />
          <Route path={appendToPath(ReceivingPath.CONSOLIDATION)} component={ConsolidationCard} />
          <Route path={appendToPath(ReceivingPath.RETURN_RECEIVE)} component={ReturnReceiveCard} />
          <Route path={appendToPath(ReceivingPath.PRODUCT_DETAILS)} component={ProductCardExpanded} />
          <Route path={appendToPath(ReceivingPath.PALLETS_DASHBOARD)} component={PalletAnalytics} />
          <Route path={appendToPath(ReceivingPath.PALLET_ANALYTICS)} component={PalletAnalyticsV2} />
          <Route
            path={appendToPath(ReceivingPath.PALLET_ANALYTICS_SELECT_BOX_CONFIGURATION)}
            component={PalletAnalyticsV2SelectBoxConfiguration}
          />
          <Route
            path={appendToPath(ReceivingPath.PALLET_ANALYTICS_SINGLE_SKU_BOX_SUMMARY)}
            component={() => PalletAnalyticsV2BoxSummary({ boxConfig: BoxConfigSummary.SINGLE_SKU })}
          />
          <Route
            path={appendToPath(ReceivingPath.PALLET_ANALYTICS_MULTI_SKU_BOX_SUMMARY)}
            component={() => PalletAnalyticsV2BoxSummary({ boxConfig: BoxConfigSummary.MULTI_SKU })}
          />
          <Route
            path={appendToPath(ReceivingPath.PALLET_ANALYTICS_BULK_BOX_SUMMARY)}
            component={() => PalletAnalyticsV2BoxSummary({ boxConfig: BoxConfigSummary.BULK })}
          />

          <Route exact path={path} render={() => <ReceiveTypeSelector />} />
          <Redirect to={WarehousePortalRoutes.RECEIVING} />
        </Switch>
      </CardContainer>
      <ReceivingButtonsContainer />
    </ReceivingContainer>
  );
};
