import React from "react";
import { FormattedMessage } from "react-intl";

import { WarehouseMessages } from "facility-commons/utils";

export const cdskuMessages = {
  helpText: <FormattedMessage {...WarehouseMessages.boxHelpText} />,
  message: <FormattedMessage {...WarehouseMessages.boxInstructionsText} />,
  title: <FormattedMessage {...WarehouseMessages.boxTitleText} />,
};
