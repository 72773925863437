import { defineMessages } from "react-intl";

export const ticketFormContent = defineMessages({
  subtitle: {
    id: "warehouse.ticketFormSubtitle",
    defaultMessage: "Please fill in the form",
  },
  trackingCode: {
    id: "warehouse.trackingCode",
    defaultMessage: "Enter tracking code or PO #",
  },
  trackingCodePlaceholder: {
    id: "warehouse.trackingCodePlaceholder",
    defaultMessage: "Ex. 160776",
  },
  barcode: {
    id: "warehouse.barcodeInstructions",
    defaultMessage: "Scan or enter barcode on the SKU",
  },
  barcodeRequired: {
    id: "warehouse.barcodeRequired",
    defaultMessage: "Scan or enter barcode on the SKU *",
  },
  barcodePlaceholder: {
    id: "warehouse.barcodePlaceholder",
    defaultMessage: "Ex. 036000291451",
  },
  poRequired: {
    id: "warehouse.poRequired",
    defaultMessage: "Input PO # *",
  },
  poPlaceholder: {
    id: "warehouse.poPlaceholder",
    defaultMessage: "Ex. 160776",
  },
  cdsku: {
    id: "warehouse.cdsku",
    defaultMessage: "Scan or enter CDSKU",
  },
  cdskuPlaceholder: {
    id: "warehouse.cdskuPlaceholder",
    defaultMessage: "Ex. CDABCZYXW12",
  },
  nonCompliantQuantity: {
    id: "warehouse.nonCompliantQuantity",
    defaultMessage: "How many units are non-compliant?",
  },
  nonCompliantQuantityRequired: {
    id: "warehouse.nonCompliantQuantityRequired",
    defaultMessage: "How many units are non-compliant? *",
  },
  damagedQuantityRequired: {
    id: "warehouse.damagedQuantityRequired",
    defaultMessage: "How many units were damaged? *",
  },
  quantityPlaceholder: {
    id: "warehouse.quantityPlaceholder",
    defaultMessage: "Ex. 5",
  },
  disposedRequired: {
    id: "warehouse.disposedRequired",
    defaultMessage: "Will the units be disposed of? *",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "no",
    defaultMessage: "No",
  },
  reasonRequired: {
    id: "warehouse.reasonRequired",
    defaultMessage: "What is the reason for the ticket? *",
  },
  reasonPlaceholder: {
    id: "warehouse.reasonPlaceholder",
    defaultMessage: "Select reason",
  },
  ncAdditionalDetails: {
    id: "warehouse.ncAdditionalDetails",
    defaultMessage: "Additional details",
  },
  ncAdditionalDetailsPlaceholder: {
    id: "warehouse.ncAdditionalDetailsPlaceholder",
    defaultMessage: "Are there multiple cases of the same non-compliance? Anything else we should know?",
  },
  photosSubtitle: {
    id: "warehouse.ticketFormPhotosSubtitle",
    defaultMessage: "Upload all photos",
  },
  photosMessage: {
    id: "warehouse.ticketFormPhotosMessage",
    defaultMessage: "This will result in faster resolutions",
  },
  frontPhoto: {
    id: "warehouse.frontPhoto",
    defaultMessage: "Front of SKU",
  },
  frontPhotoRequired: {
    id: "warehouse.frontPhotoRequired",
    defaultMessage: "Front of SKU *",
  },
  backPhoto: {
    id: "warehouse.backPhoto",
    defaultMessage: "Back of SKU",
  },
  backPhotoRequired: {
    id: "warehouse.backPhotoRequired",
    defaultMessage: "Back of SKU *",
  },
  barcodePhoto: {
    id: "warehouse.barcodePhoto",
    defaultMessage: "Barcode on SKU",
  },
  boxContentPhoto: {
    id: "warehouse.boxContentPhoto",
    defaultMessage: "Box Contents Label",
  },
  damagedPhotoRequired: {
    id: "warehouse.damagedPhotoRequired",
    defaultMessage: "Damaged Item(s) *",
  },
  exteriorPhoto: {
    id: "warehouse.exteriorPhoto",
    defaultMessage: "Exterior Carton",
  },
  additionalPhoto: {
    id: "warehouse.additionalPhoto",
    defaultMessage: "Additional Photo",
  },
  damagedAdditionalDetails: {
    id: "warehouse.damagedAdditionalDetails",
    defaultMessage: "Additional details",
  },
  damagedAdditionalDetailsPlaceholder: {
    id: "warehouse.damagedAdditionalDetailsPlaceholder",
    defaultMessage: "How was this damaged? Ex. Arrived damaged, dropped in warehouse, etc.",
  },
  email: {
    id: "warehouse.email",
    defaultMessage: "Email for ticket updates",
  },
  emailMessage: {
    id: "warehouse.emailMessage",
    defaultMessage: "If left blank, updates will be automatically sent to {warehouseEmail}.",
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  specifyProblem: {
    id: "warehouse.specifyProblem",
    defaultMessage: "What is the problem?",
  },
  repalletizeMultiSKU: {
    id: "warehouse.repalletizeMultiSKU",
    defaultMessage: "Multi-SKU Pallet",
  },
  repalletizeOversized: {
    id: "warehouse.repalletizeOversized",
    defaultMessage: "Oversized Pallet",
  },
});
