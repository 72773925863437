import * as React from "react";
import { useLifecycles } from "react-use";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createBoxArrivalScannerCard } from "warehouse/common/flow/warehouseFlowCardCreators";
import { BoxArrivalScanFlow } from "warehouse/common/flow/BoxArrivalScanFlow";

export const BoxArrivalScan: React.FC = () => {
  const { addFlowCard, resetFlow } = useWarehouseFlow();

  useLifecycles(
    () => {
      addFlowCard(createBoxArrivalScannerCard({}));
    },
    () => {
      resetFlow();
    }
  );

  return <BoxArrivalScanFlow />;
};
