import { DefaultTheme, Select } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useAdminWarehouseSelector, WarehouseSelection } from "facility-commons/hooks/auth/useAdminWarehouseSelector";
import { useRecoilValue } from "recoil";
import { allWarehouseIdsState } from "facility-commons/base/Auth/userState";
import { AppName } from "facility-commons/types";

const StyledSelectMenuItem = styled.div<{ hasLabel: boolean }, DefaultTheme>(
  ({ theme, hasLabel }) => `
  // select component container
  label {
    display: flex;

    > div:last-of-type { // select dropdown component positioning
      margin: 0 ${theme.spacing.S4} ${hasLabel ? "0 auto" : ""};
      ${!hasLabel ? "width: 100%;" : ""}
    }
  }
  
  // select label component
  span {
    color: ${theme.colors.NEUTRAL[500]};
    font-weight: ${theme.font.weight.REGULAR};
    line-height: ${theme.spacing.S8};
    font-size: ${theme.font.size.F3};
  }
`
);

const StyledSelect = styled(Select)<{ hasLabel: boolean }, DefaultTheme>(({ theme, hasLabel }) => {
  return `
      width: ${hasLabel ? "8rem" : "100%"};
      
      & > div {
        ${hasLabel ? "min-height: auto;" : `border-color: ${theme.colors.NEUTRAL["300"]};`}

        & > div:first-of-type {
          padding: ${theme.spacing.S1};
          padding-left: ${theme.spacing.S4};
          font-size: ${hasLabel ? theme.font.size.F1 : theme.font.size.F2};
          background-color: ${theme.colors.NEUTRAL["00"]};
          text-align: left;
      
          div {
            ${hasLabel ? `color: ${theme.colors.NEUTRAL["300"]};` : ""}
          }
        } 
      }

      & > div:nth-of-type(2) > div > div {
        padding-left: ${theme.border.width.B2};
      }
    `;
});

interface AdminWarehouseSelectorProps {
  appName: AppName;
  label?: JSX.Element;
  onSelection: (selection: WarehouseSelection) => void;
}

export const AdminWarehouseSelector: React.FC<AdminWarehouseSelectorProps> = ({ appName, label, onSelection }) => {
  const { warehouseId, handleWarehouseSelection } = useAdminWarehouseSelector(appName, onSelection);
  const allWarehouseIds = useRecoilValue(allWarehouseIdsState);
  return (
    <StyledSelectMenuItem hasLabel={!!label}>
      <StyledSelect
        label={label}
        hasLabel={!!label}
        placeholder={
          <FormattedMessage
            id="facilityCommons.adminWarehouseSelectoer.accountPlaceholder"
            defaultMessage="Select a warehouse"
          />
        }
        options={allWarehouseIds?.map((id) => ({
          value: id,
          label: id,
        }))}
        onChange={handleWarehouseSelection}
        value={{ value: warehouseId, label: warehouseId }}
      />
    </StyledSelectMenuItem>
  );
};
