import React from "react";
import { LocalizedMessage, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { commonMessages } from "facility-commons/labels";
import { PaddedDivider, PointerAnchor } from "facility-commons/components/emotion";
import { useIntl } from "react-intl";

export interface SkipStepProps {
  message?: LocalizedMessage;
  onSkip: () => void;
  skipText?: LocalizedMessage;
  hideSeparator?: boolean;
}

const ConfirmationMessage = styled.p<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  color: ${theme.colors.NEUTRAL[300]};
  margin-bottom:${theme.spacing.S1};
`
);

const StyledPointerAnchor = styled(PointerAnchor)`
  margin-top: 0;
`;

export const SkipStep: React.FC<SkipStepProps> = (props) => {
  const { formatMessage } = useIntl();
  const { message, onSkip, skipText, hideSeparator } = props;

  return (
    <>
      {!hideSeparator && <PaddedDivider />}
      {message && <ConfirmationMessage>{message}</ConfirmationMessage>}
      <StyledPointerAnchor onClick={onSkip} data-testid="skip-step-button">
        {skipText ?? formatMessage(commonMessages.skipStep)}
      </StyledPointerAnchor>
    </>
  );
};
