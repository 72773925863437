import { NonComplianceErrors } from "@deliverr/commons-clients";
import { ErrorOption } from "react-hook-form";

import {
  maxBarcodeLength,
  minBarcodeLength,
  validBarcodePattern,
  validEmailPattern,
} from "facility-commons/utils/config";
import { OrderPickingField, OrderPickingValidationErrorMessage } from "./OrderPickingForm.types";

export const orderPickingValidationConfig = {
  [OrderPickingField.BARCODE]: {
    required: OrderPickingValidationErrorMessage.BARCODE_REQUIRED,
    minLength: {
      value: minBarcodeLength,
      message: OrderPickingValidationErrorMessage.BARCODE_FORMAT,
    },
    maxLength: {
      value: maxBarcodeLength,
      message: OrderPickingValidationErrorMessage.BARCODE_FORMAT,
    },
    pattern: {
      value: validBarcodePattern,
      message: OrderPickingValidationErrorMessage.BARCODE_FORMAT,
    },
  },
  [OrderPickingField.QUANTITY]: {
    required: OrderPickingValidationErrorMessage.QTY_REQUIRED,
  },
  [OrderPickingField.DAMAGED_PRODUCT_PHOTO]: {
    required: OrderPickingValidationErrorMessage.DAMAGED_PRODUCT_PHOTO_REQUIRED,
  },
  [OrderPickingField.EMAIL]: {
    pattern: {
      value: validEmailPattern,
      message: OrderPickingValidationErrorMessage.EMAIL_INVALID,
    },
  },
};

type SetErrorArgs = [string, ErrorOption];

// see function args in https://react-hook-form.com/api#setError
export const orderPickingNcServiceErrorMap: { [error: string]: SetErrorArgs } = {
  [NonComplianceErrors.BARCODE_INVALID]: [
    OrderPickingField.BARCODE,
    { type: "string", message: OrderPickingValidationErrorMessage[NonComplianceErrors.BARCODE_INVALID] },
  ],
  unknown: [OrderPickingField.BARCODE, { type: "string", message: OrderPickingValidationErrorMessage.UNKNOWN }],
};
