import { v4 as uuid } from "uuid";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { returnReceiveItemInspectionStateAtom, returnReceiveReturnOrderStateAtom } from "../state";
import {
  returnReceiveReceivingDataStateAtom,
  RETURN_RECEIVE_RECEIVING_DATA_INITIAL_STATE,
} from "../state/ReturnReceiveReceivingDataState";

export const useInitializeReturnReceiveStates = () => {
  const resetReturnOrderState = useResetRecoilState(returnReceiveReturnOrderStateAtom);
  const resetItemInspectionState = useResetRecoilState(returnReceiveItemInspectionStateAtom);
  const setReceivingDataState = useSetRecoilState(returnReceiveReceivingDataStateAtom);

  return {
    initializeAllReturnReceiveStates: () => {
      resetReturnOrderState();
      resetItemInspectionState();
      setReceivingDataState({
        ...RETURN_RECEIVE_RECEIVING_DATA_INITIAL_STATE,
        dedupKey: uuid(),
        requestBatchId: uuid(),
      });
    },
    initializeItemInspectionState: () => {
      resetItemInspectionState();
      setReceivingDataState((prev) => ({
        ...RETURN_RECEIVE_RECEIVING_DATA_INITIAL_STATE,
        requestBatchId: prev.requestBatchId,
        dedupKey: uuid(),
      }));
    },
  };
};
