import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useIntl } from "react-intl";
import { modalsText } from "../modalsText";
import { returnReceiveItemInspectionStateAtom } from "warehouse/receiving/ReturnReceive/state";
import { useRecoilValue } from "recoil";
export const useReturnReceiveItemInspectionStandardsModal = () => {
  const { formatMessage } = useIntl();
  const { hideModal } = useWarehouseModal();
  const itemInspectionData = useRecoilValue(returnReceiveItemInspectionStateAtom);

  const { inspectionSOPName, inspectionSteps } = itemInspectionData;

  const onClose = () => {
    hideModal(WarehouseModal.RETURN_RECEIVE_ITEM_INSPECTION_STANDARDS);
  };

  const messages = {
    title: formatMessage(modalsText.returnReceiveSellableStandards),
    close: formatMessage(modalsText.close),
  };
  return {
    inspectionSOPName,
    inspectionSteps,
    messages,
    onClose,
  };
};
