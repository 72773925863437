import React from "react";
import { usePalletAnalyticsV2 } from "./usePalletAnalyticsV2";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { ReactComponent as ScanPallet } from "facility-commons/common/assets/scanPallet.svg";

export const PalletAnalyticsV2: React.FC = () => {
  const {
    palletAnalyticsInstructions,
    palletIdTitle,
    pid,
    pidError,
    illustrationTitle,
    loading,
    palletIdPlaceholder,
    handleChange,
    handleSubmit,
  } = usePalletAnalyticsV2();

  return (
    <>
      <StackedInputCard
        errorMessage={pidError}
        illustration={<ScanPallet />}
        illustrationTitle={illustrationTitle}
        message={palletAnalyticsInstructions}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={palletIdPlaceholder}
        loading={loading}
        title={palletIdTitle}
        value={pid}
        data-testid="pallet-analytics-v2-card-input"
      ></StackedInputCard>
    </>
  );
};
