import { useState } from "react";
import { useAsync } from "react-use";
import { inboundClient } from "facility-commons/base/Clients";
import { MetabaseWarehouseDashboard } from "@deliverr/legacy-inbound-client/lib/MetabaseWarehouseDashboard";

export function use3PLEmbeddedDashboard(warehouseId: string, warehouseDashboard: MetabaseWarehouseDashboard) {
  const [error, setError] = useState(null);

  const { loading, value } = useAsync(async () => {
    try {
      return await inboundClient.getDashboardUrl(warehouseDashboard, warehouseId);
    } catch (err: any) {
      setError(err);
    }
  }, [warehouseId]);

  return {
    embeddedDashboardUrl: value,
    embeddedDashboardLoading: loading,
    embeddedDashboardError: error,
  };
}
