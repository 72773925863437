import { Navbar, NavbarProps, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

import { BackArrow } from "facility-commons/components";
import { FacilityHeaderProps, useFacilityHeader } from "./useFacilityHeader";
import { FACILITY_HEADER_ICON_BUTTON_SIZE } from "./FacilityHeader.constants";

const NavButton = styled.button<ThemeProps>`
  margin: ${({ theme }) => theme.spacing.S2};
`;

const StyledNavbar = styled(Navbar)<ThemeProps<NavbarProps & { hideNavbarShadow?: boolean }>>(
  ({ hideNavbarShadow }) => `
    height: 80px;
    & > div:first-of-type {
      margin-right: 0;
    }
    h4 {
      text-transform: capitalize;
    }

    ${hideNavbarShadow ? "box-shadow : none" : ""};
  `
);

/**
 * Header / navbar with optional tab control for general usage in facility portals.
 *
 * See WarehouseHeader.tsx for an example.
 */
export function FacilityHeader<RouteEnum extends string, BaseRouteEnum extends string = RouteEnum>(
  props: FacilityHeaderProps<RouteEnum, BaseRouteEnum>
) {
  const { tabControl: TabControl, leftAction, customOnBackButtonClick, ...navbarProps } = props;
  const { shouldShowBackButton, handleClickBackButton, displayedSubtitle, displayedTitle } = useFacilityHeader<
    RouteEnum,
    BaseRouteEnum
  >(props);

  const leftActionButton =
    leftAction ??
    (shouldShowBackButton ? (
      <NavButton data-testid="back-button" onClick={customOnBackButtonClick ?? handleClickBackButton}>
        <BackArrow size={FACILITY_HEADER_ICON_BUTTON_SIZE} />
      </NavButton>
    ) : null);

  return (
    <>
      <StyledNavbar
        {...navbarProps}
        leftAction={leftActionButton}
        title={displayedTitle}
        subtitle={displayedSubtitle}
      />
      {TabControl}
    </>
  );
}
