import React from "react";
import { DamagedProductForm } from "facility-commons/flow/forms/DamagedProductForm";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { useWarehouseDamagedProductForm } from "./useWarehouseDamagedProductForm";

export const WarehouseDamagedProductForm: React.FC = () => {
  const { useDamagedProductForm } = useWarehouseDamagedProductForm();
  return (
    <FacilityCard>
      <DamagedProductForm useDamagedProductForm={useDamagedProductForm} />
    </FacilityCard>
  );
};
