import React from "react";
import { FormattedMessage } from "react-intl";
import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import {
  ConfirmCumulativeReceiveToSameLocationModalProps,
  useConfirmCumulativeReceiveToSameLocationModal,
} from "./useConfirmCumulativeReceiveToSameLocationModal";
import { modalsText } from "../modalsText";
import { Button } from "@deliverr/ui";

export const ConfirmCumulativeReceiveToSameLocationModal: React.FC<ConfirmCumulativeReceiveToSameLocationModalProps> = (
  props
) => {
  const {
    handleModalClickContinue,
    hideConfirmCumulativeReceiveToSameLocationModal,
    inputtedQuantity,
    previouslyReceived,
  } = useConfirmCumulativeReceiveToSameLocationModal(props);

  return (
    <ModalTemplate
      title={<FormattedMessage {...modalsText.cumulativeReceiveTitle} />}
      text={
        <FormattedMessage {...modalsText.cumulativeReceiveText} values={{ inputtedQuantity, previouslyReceived }} />
      }
      iconProps={warningModalIconLg}
    >
      <WarehouseModalRightMarginButton block onClick={hideConfirmCumulativeReceiveToSameLocationModal} secondary>
        <FormattedMessage {...modalsText.back} />
      </WarehouseModalRightMarginButton>
      <Button block onClick={handleModalClickContinue}>
        <FormattedMessage
          id="warehouse.receiving.confirmCumulativeReceiveToSameLocation.submit"
          defaultMessage="Add {inputtedQuantity} units"
          values={{ inputtedQuantity }}
        />
      </Button>
    </ModalTemplate>
  );
};
