import { defineMessages, MessageDescriptor } from "react-intl";

export enum UniqueConstraintViolationErrorMessageKey {
  UNIQUE_CONSTRAINT_VIOLATION = "UNIQUE_CONSTRAINT_VIOLATION",
}

export const UNIQUE_CONSTRAINT_VIOLATION_ERROR_BY_ERROR_CODE: Record<
  UniqueConstraintViolationErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [UniqueConstraintViolationErrorMessageKey.UNIQUE_CONSTRAINT_VIOLATION]: {
    id: "warehouse.receiving.uniqueConstraintViolation",
    defaultMessage: "A {modelName} with the same identity has already been created.",
  },
});
