import React from "react";
import { useArrivalScannerTypeSelectorCard } from "./useArrivalScannerTypeSelectorCard";
import { StyledStack } from "warehouse/receiving/components/ReceiveTypeSelector";
import { LinkCard } from "warehouse/common/components/LinkCard";
import { ReceivingContainer } from "warehouse/receiving";
import { CardContainer } from "warehouse/receiving";

export const ArrivalScannerTypeSelectorCard: React.FC = () => {
  const { palletArrivalScan, boxArrivalScan } = useArrivalScannerTypeSelectorCard();

  return (
    <ReceivingContainer>
      <CardContainer>
        <StyledStack>
          <LinkCard
            title={palletArrivalScan.title}
            description={palletArrivalScan.description}
            to={palletArrivalScan.link}
            data-testid="pallet-arrival-scan-selection"
          />
          <LinkCard
            title={boxArrivalScan.title}
            description={boxArrivalScan.description}
            to={boxArrivalScan.link}
            data-testid="box-arrival-scan-selection"
          />
        </StyledStack>
      </CardContainer>
    </ReceivingContainer>
  );
};
