import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { AuthProvider, EmotionCache, ProtectedRoute } from "facility-commons/base";
import { CenterSpinner } from "facility-commons/components/LoadingSpinner";
import { Warehouse } from "./Warehouse";
import { IntlProvider } from "react-intl";
import { useRouter } from "facility-commons/hooks";
import { AppName } from "facility-commons/types";
import { PageNotFound } from "facility-commons/components/PageNotFound";

const RedirectToScannerPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.push("/warehouse/scan-arrival");
  }, [router]);

  return null;
};

export const WarehouseApp: React.FC = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<CenterSpinner loading />}>
        <EmotionCache>
          <IntlProvider locale="en">
            <Router>
              <AuthProvider>
                <Route path="/" exact component={RedirectToScannerPage} />
                <Route path="/storage/scan" exact component={RedirectToScannerPage} />
                <Route path="/storage/*" component={PageNotFound} />
                <ProtectedRoute path="/warehouse" apps={[AppName.WP]} component={Warehouse} />
              </AuthProvider>
            </Router>
          </IntlProvider>
        </EmotionCache>
      </Suspense>
    </RecoilRoot>
  );
};
