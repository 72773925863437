import { useRouter } from "facility-commons/hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useMount } from "react-use";
import {
  receivingMisMatchState,
  receivingState,
  receiveFlowTypeState,
  isRerouteFromReceivingState,
} from "warehouse/receiving/ReceivingState";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useState } from "react";
import { modalsText } from "../../modals/modalsText";
import { useIntl } from "react-intl";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { isEmpty, isNull } from "lodash";
import { setProp } from "facility-commons/utils";
import { commonLotMessages, commonReceivingMessages } from "warehouse/receiving/content";
import { commonWarehouseMessages } from "warehouse/common/messages";
import {
  MAX_LOT_VALUE_INPUT_LENGTH,
  MINIMUM_EXPIRE_YEAR,
  MAXIMUM_EXPIRE_YEAR,
  DEFAULT_MISSING_LOT_INPUT,
  DEFAULT_MISSING_EXPIRY_INPUT,
} from "warehouse/receiving/utils";
import { useReceivingFlow } from "warehouse/receiving/base";
import { isConsolidationFlow } from "warehouse/receiving/utils/receivingFlowType";
import { MonthDayYearRegex } from "facility-commons/utils";
import { validLotInput } from "facility-commons/utils/config";
import {
  getLotInputValidationResults,
  getExpiryInputValidationResults,
  LotInputValidationResult,
  ExpiryInputValidationResult,
} from "warehouse/receiving/utils";

export interface DateInput {
  target: { value: string };
}

export const useConfirmationCard = () => {
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const {
    expectedLot,
    expectedExpirationDate,
    requireLotInput,
    requireExpiryInput,
    isProductMissingLot,
    isProductMissingExpirationDate,
  } = receivingData;
  const [lotErrorMsg, setLotErrorMsg] = useState<string>("");
  const [expirationDateErrorMsg, setExpirationDateErrorMsg] = useState<string>("");
  const [isMismatch, setIsMismatch] = useRecoilState(receivingMisMatchState);
  const setIsRerouteFromReceiving = useSetRecoilState(isRerouteFromReceivingState);
  const { hideModal, showModal } = useWarehouseModal();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { errorResponse, successResponse } = useCommonFlow();
  const { useRerouteToLotFefoNC } = useReceivingFlow();
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const isConsolidation = isConsolidationFlow(receivingFlow);

  const [expirationDateInputValue, setExpirationDateInputValue] = useState<string>("");
  const [lotNumberInputValue, setLotNumberInputValue] = useState<string>("");

  // we only allow receiving of items missing lot/expiry in consolidation receive
  useMount(() => {
    if (isConsolidation) {
      resetMissingLotState();
      resetMissingExpiryState();
    }
  });

  // reroutes to NC ticket creation if lot/expiry problem
  useRerouteToLotFefoNC();

  const resetMissingLotState = () => {
    setReceivingData(setProp("isProductMissingLot", false));
    setLotNumberInputValue("");
  };

  const resetMissingExpiryState = () => {
    setReceivingData(setProp("isProductMissingExpirationDate", false));
    setExpirationDateInputValue("");
  };

  const onMissingLotToggle = () => {
    if (!isProductMissingLot) {
      setReceivingData(setProp("isProductMissingLot", true));
      setLotNumberInputValue(DEFAULT_MISSING_LOT_INPUT);
    } else {
      resetMissingLotState();
    }
  };

  const onMissingExpiryToggle = () => {
    if (!isProductMissingExpirationDate) {
      setReceivingData(setProp("isProductMissingExpirationDate", true));
      setExpirationDateInputValue(DEFAULT_MISSING_EXPIRY_INPUT);
    } else {
      resetMissingExpiryState();
    }
  };

  const onLotChange = (value) => {
    // allow only alphanumeric entries
    if (!validLotInput.test(value)) {
      return;
    }
    setLotErrorMsg("");
    setLotNumberInputValue(value);
  };

  const onLotInputError = () => {
    errorResponse();
    setLotNumberInputValue("");
  };

  const onExpiryInputError = () => {
    errorResponse();
    setExpirationDateInputValue("");
  };

  const onExpirationDateChange = ({ target: { value } }: DateInput) => {
    if (!value) {
      return setExpirationDateInputValue("");
    }
    setExpirationDateErrorMsg("");
    setExpirationDateInputValue(value);
  };

  const onProblemConfirm = () => {
    setIsMismatch(true);
    setIsRerouteFromReceiving(true);
    hideModal(WarehouseModal.CONFIRM_REPORT_PROBLEM);
  };

  const handleReportProblem = () => {
    showModal(WarehouseModal.CONFIRM_REPORT_PROBLEM, {
      onConfirm: onProblemConfirm,
      onCancel: () => hideModal(WarehouseModal.CONFIRM_REPORT_PROBLEM),
    });
  };

  const onConfirm = () => {
    // If a sku requires an expiration date or lot input from the receiver then we need to validate those inputs
    // It is possible for a sku to require an expiration date input, a LOT input, or BOTH
    // lot input validations
    let lotRequiresDoubleConfirmation = false;
    if (requireLotInput) {
      const lotValidationResults = getLotInputValidationResults(lotNumberInputValue, MAX_LOT_VALUE_INPUT_LENGTH);
      switch (lotValidationResults) {
        case LotInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setLotErrorMsg(formatMessage(commonLotMessages.missingLotInput));
        case LotInputValidationResult.EXCEEDED_MAX_CHARACTER_COUNT:
          onLotInputError();
          return setLotErrorMsg(
            formatMessage(commonWarehouseMessages.exceededMaxCharacterCount, {
              characterLimit: MAX_LOT_VALUE_INPUT_LENGTH,
            })
          );
        case LotInputValidationResult.INVALID_INPUT:
          onLotInputError();
          return setLotErrorMsg(formatMessage(commonLotMessages.invalidLotInput));
        case LotInputValidationResult.VALID:
          const lotMismatch =
            !isProductMissingLot && expectedLot && expectedLot.toLowerCase() !== lotNumberInputValue.toLowerCase();
          const noExpectedLot = !isProductMissingLot && !expectedLot;
          if (lotMismatch || noExpectedLot) {
            lotRequiresDoubleConfirmation = true;
          } else {
            setReceivingData(setProp("lotNumber", lotNumberInputValue));
          }
          break;
      }
    }

    // expiration date input validations
    let expiryRequiresDoubleConfirmation = false;
    if (requireExpiryInput) {
      const { result: expiryValidationResults, formattedDate, lastDateOfMonth } = getExpiryInputValidationResults(
        expirationDateInputValue,
        MINIMUM_EXPIRE_YEAR,
        MAXIMUM_EXPIRE_YEAR
      );
      switch (expiryValidationResults) {
        case ExpiryInputValidationResult.MISSING_INPUT:
          errorResponse();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.missingExpirationDateInput));
        case ExpiryInputValidationResult.INVALID_DATE:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.incorrectExpirationDateFormat));
        case ExpiryInputValidationResult.INVALID_YEAR:
          onExpiryInputError();
          return setExpirationDateErrorMsg(
            formatMessage(commonReceivingMessages.invalidYear, {
              minYear: MINIMUM_EXPIRE_YEAR,
              maxYear: MAXIMUM_EXPIRE_YEAR,
            })
          );
        case ExpiryInputValidationResult.INVALID_MONTH:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidMonth));
        case ExpiryInputValidationResult.INVALID_DAY:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.invalidDay, { lastDateOfMonth }));
        case ExpiryInputValidationResult.EXPIRED_PRODUCT:
          onExpiryInputError();
          return setExpirationDateErrorMsg(formatMessage(commonReceivingMessages.expiredProduct));
        case ExpiryInputValidationResult.VALID:
          const expiryMismatch =
            !isProductMissingExpirationDate && expectedExpirationDate && expectedExpirationDate !== formattedDate;
          const noExpectedExpiry = !isProductMissingExpirationDate && !expectedExpirationDate;
          if (expiryMismatch || noExpectedExpiry) {
            expiryRequiresDoubleConfirmation = true;
          } else {
            setReceivingData(setProp("expirationDate", formattedDate));
          }
          break;
      }
    }

    if (lotRequiresDoubleConfirmation || expiryRequiresDoubleConfirmation) {
      showModal(WarehouseModal.CONFIRM_LOT_EXPIRY, {
        requireLotInput: lotRequiresDoubleConfirmation,
        requireExpiryInput: expiryRequiresDoubleConfirmation,
        previousLotInput: lotNumberInputValue,
        previousExpiryInput: expirationDateInputValue,
        onCancel: () => {
          resetMissingLotState();
          resetMissingExpiryState();
          hideModal(WarehouseModal.CONFIRM_LOT_EXPIRY);
        },
      });
      // clear the input fields so user must look at physical product for lot/expiry info
      setLotNumberInputValue("");
      setExpirationDateInputValue("");
      return;
    }

    // passed all input validations
    successResponse();
    return push(ReceivingPath.QUANTITY);
  };
  const disableButton =
    !isEmpty(lotErrorMsg) ||
    !isEmpty(expirationDateErrorMsg) ||
    (requireExpiryInput && !MonthDayYearRegex.test(expirationDateInputValue)) ||
    (requireLotInput && isEmpty(lotNumberInputValue));

  const messages = {
    fefoLotInputMsg: formatMessage(commonReceivingMessages.inputProductDetails),
    missingLotLabel: formatMessage(commonLotMessages.lotIsMissing),
    missingExpiryLabel: formatMessage(commonReceivingMessages.expirationDateIsMissing),
    lotNumber: formatMessage(commonLotMessages.lotNumber),
    expirationDate: formatMessage(commonReceivingMessages.expirationDate),
    expirationDateInputHelpText: formatMessage(commonReceivingMessages.expirationDateInputHelpText),
    confirm: formatMessage(commonReceivingMessages.confirm),
    reportProblem: formatMessage(commonReceivingMessages.reportProblem),
  };

  return {
    lotNumberInputValue,
    lotErrorMsg,
    expirationDateInputValue,
    expirationDateErrorMsg,
    disableButton,
    requireLotInput,
    requireExpiryInput,
    isProductMissingLot,
    isProductMissingExpirationDate,
    isConsolidation,
    messages,
    onConfirm,
    handleReportProblem,
    onLotChange,
    onExpirationDateChange,
    onMissingLotToggle,
    onMissingExpiryToggle,
  };
};
