import { Anchor, BoxReset, Button, Input, ThemeProps, Text } from "@deliverr/ui";
import React from "react";
import styled from "@emotion/styled";
import { FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useIntl } from "react-intl";
import { DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";

import { LoadingSpinner, TooltipWrapper, UploadItem } from "facility-commons/components";
import { maxBarcodeLength, maxCDSKULength } from "facility-commons/utils/config";
import {
  WarehouseForm,
  WarehouseFormImageGrid,
  WarehouseFormInputGroup,
  WarehouseFormLabel,
  WarehouseFormOtherDetails,
  WarehouseFormPhotoGridTitle,
  WarehouseFormRadioButton,
  WarehouseFormRadioButtonGroup,
  WarehouseFormRadioStack,
  WarehouseFormRadioTitle,
  WarehouseFormSubTitle,
  WarehouseFormText,
  WarehouseFormTextArea,
  WarehouseFormTooltip,
} from "facility-commons/components/emotion";
import { DamagedProductField } from "./DamagedProductForm.types";
import { ticketFormContent } from "../ticketFormContent";
import { LogContext } from "@deliverr/ui-logging";
import { formErrorToInputProps } from "./formErrorToInputProps";
import { composeUseDamagedProductForm } from "./composeUseDamagedProductForm";

// Style the TextInputCard component to keep consistency with the WarehouseFormInput styling.
const StyledTextInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
    width: 100%;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `
);

const InlineAnchor = styled(Anchor)<ThemeProps>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S1};
    display: inline-block;
    cursor: pointer;
  `
);

export interface DamagedProductFormProps {
  useDamagedProductForm: ReturnType<typeof composeUseDamagedProductForm>;
  onFailure?: (ctx: LogContext, err: any) => void;
  isCrossdock?: boolean;
}

export const DamagedProductForm: React.FC<DamagedProductFormProps> = ({
  useDamagedProductForm,
  onFailure = undefined,
  isCrossdock = false,
}: DamagedProductFormProps) => {
  const {
    errors,
    formHandler,
    onFormSubmit,
    register,
    preventSubmit,
    warehouseEmailAsyncState,
    shouldShowBoxDamagedField,
    shouldShowPOField,
    toggleShouldShowPOField,
  } = useDamagedProductForm({ onFailure });
  const { formatMessage } = useIntl();

  return (
    <FormProvider {...formHandler}>
      <WarehouseForm onSubmit={onFormSubmit}>
        <WarehouseFormSubTitle as="h3" displayAs="h4">
          {formatMessage(ticketFormContent.subtitle)}
        </WarehouseFormSubTitle>

        <WarehouseFormInputGroup>
          <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.BARCODE}>
            {formatMessage(ticketFormContent.barcodeRequired)}
          </WarehouseFormLabel>
          <StyledTextInput
            {...register[DamagedProductField.BARCODE]}
            id={DamagedProductField.BARCODE}
            maxLength={maxBarcodeLength}
            placeholder={formatMessage(ticketFormContent.barcodePlaceholder)}
            {...formErrorToInputProps(errors, DamagedProductField.BARCODE)}
          />
        </WarehouseFormInputGroup>

        {shouldShowPOField ? (
          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.ASN}>
              {formatMessage(ticketFormContent.poRequired)}
            </WarehouseFormLabel>
            <StyledTextInput
              {...register[DamagedProductField.ASN]}
              id={DamagedProductField.ASN}
              placeholder={formatMessage(ticketFormContent.poPlaceholder)}
              {...formErrorToInputProps(errors, DamagedProductField.ASN)}
            />
            <BoxReset marginTop="S1" marginBottom="S5">
              <Text appearance="INFO">
                {formatMessage(ticketFormContent.switchToCdskuMessage)}
                <InlineAnchor onClick={toggleShouldShowPOField}>
                  {formatMessage(ticketFormContent.switchToCdskuButton)}
                </InlineAnchor>
              </Text>
            </BoxReset>
          </WarehouseFormInputGroup>
        ) : (
          <WarehouseFormInputGroup>
            <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.CDSKU}>
              {formatMessage(ticketFormContent.cdsku)}
            </WarehouseFormLabel>
            <StyledTextInput
              {...register[DamagedProductField.CDSKU]}
              id={DamagedProductField.CDSKU}
              placeholder={formatMessage(ticketFormContent.cdskuPlaceholder)}
              maxLength={maxCDSKULength}
              {...formErrorToInputProps(errors, DamagedProductField.CDSKU)}
            />
            <BoxReset marginTop="S1" marginBottom="S5">
              <Text appearance="INFO">
                {formatMessage(ticketFormContent.noCdskuMessage)}
                <InlineAnchor onClick={toggleShouldShowPOField}>
                  {formatMessage(ticketFormContent.noCdskuButton)}
                </InlineAnchor>
              </Text>
            </BoxReset>
          </WarehouseFormInputGroup>
        )}

        <WarehouseFormRadioStack direction="VERTICAL">
          <WarehouseFormRadioTitle appearance="INFO">
            {formatMessage(ticketFormContent.damageType)}
          </WarehouseFormRadioTitle>
          <WarehouseFormRadioButtonGroup>
            <WarehouseFormRadioButton
              {...register[DamagedProductField.DAMAGE_TYPE]}
              id={`${DamagedProductField.DAMAGE_TYPE}-damaged`}
              data-testid="damaged-radio"
              type="radio"
              value={DamageType.DAMAGED}
              required
            />
            <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.DAMAGE_TYPE}-damaged`}>
              {formatMessage(ticketFormContent.productDamaged)}
            </WarehouseFormLabel>

            <WarehouseFormRadioButton
              {...register[DamagedProductField.DAMAGE_TYPE]}
              id={`${DamagedProductField.DAMAGE_TYPE}-expired`}
              data-testid="expired-radio"
              type="radio"
              value={DamageType.EXPIRED}
            />
            <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.DAMAGE_TYPE}-expired`}>
              {formatMessage(ticketFormContent.productExpired)}
            </WarehouseFormLabel>
          </WarehouseFormRadioButtonGroup>
        </WarehouseFormRadioStack>

        <WarehouseFormInputGroup>
          <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.QUANTITY}>
            {formatMessage(ticketFormContent.damagedQuantityRequired)}
          </WarehouseFormLabel>
          <StyledTextInput
            {...register[DamagedProductField.QUANTITY]}
            id={DamagedProductField.QUANTITY}
            placeholder={formatMessage(ticketFormContent.quantityPlaceholder)}
            {...formErrorToInputProps(errors, DamagedProductField.QUANTITY)}
          />
        </WarehouseFormInputGroup>

        {shouldShowBoxDamagedField && (
          <WarehouseFormRadioStack direction="VERTICAL">
            <WarehouseFormRadioTitle appearance="INFO">
              {formatMessage(ticketFormContent.boxDamaged)}
            </WarehouseFormRadioTitle>
            <WarehouseFormRadioButtonGroup>
              <WarehouseFormRadioButton
                {...register[DamagedProductField.BOX_DAMAGED]}
                id={`${DamagedProductField.BOX_DAMAGED}-yes`}
                data-testid="box-damaged-yes-radio"
                type="radio"
                value="1"
                required
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.BOX_DAMAGED}-yes`}>
                {formatMessage(ticketFormContent.yes)}
              </WarehouseFormLabel>

              <WarehouseFormRadioButton
                {...register[DamagedProductField.BOX_DAMAGED]}
                id={`${DamagedProductField.BOX_DAMAGED}-no`}
                data-testid="box-damaged-no-radio"
                type="radio"
                value="0"
              />
              <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.BOX_DAMAGED}-no`}>
                {formatMessage(ticketFormContent.no)}
              </WarehouseFormLabel>
            </WarehouseFormRadioButtonGroup>
          </WarehouseFormRadioStack>
        )}

        <WarehouseFormRadioStack direction="VERTICAL">
          <WarehouseFormRadioTitle appearance="INFO">
            {formatMessage(ticketFormContent.disposedRequired)}
          </WarehouseFormRadioTitle>
          <WarehouseFormRadioButtonGroup>
            <WarehouseFormRadioButton
              {...register[DamagedProductField.DISPOSED]}
              id={`${DamagedProductField.DISPOSED}-yes`}
              data-testid="disposed-yes-radio"
              type="radio"
              value="1"
              required
            />
            <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.DISPOSED}-yes`}>
              {formatMessage(ticketFormContent.yes)}
            </WarehouseFormLabel>

            <WarehouseFormRadioButton
              {...register[DamagedProductField.DISPOSED]}
              id={`${DamagedProductField.DISPOSED}-no`}
              data-testid="disposed-no-radio"
              type="radio"
              value="0"
            />
            <WarehouseFormLabel appearance="INFO" htmlFor={`${DamagedProductField.DISPOSED}-no`}>
              {formatMessage(ticketFormContent.no)}
            </WarehouseFormLabel>
          </WarehouseFormRadioButtonGroup>
        </WarehouseFormRadioStack>

        <WarehouseFormPhotoGridTitle as="h3" displayAs="h4">
          {formatMessage(ticketFormContent.photosSubtitle)}
        </WarehouseFormPhotoGridTitle>

        <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessage)}</WarehouseFormText>
        <WarehouseFormText appearance="INFO">{formatMessage(ticketFormContent.photosMessageExpired)}</WarehouseFormText>

        <WarehouseFormImageGrid columns={"repeat(2, minmax(0, 1fr))"} gap={"S3"}>
          <TooltipWrapper>
            <UploadItem
              {...register[DamagedProductField.DAMAGED_PRODUCT_PHOTO]}
              id={DamagedProductField.DAMAGED_PRODUCT_PHOTO}
              labelText={formatMessage(ticketFormContent.damagedPhotoRequired)}
            />
            <WarehouseFormTooltip active={!!errors[DamagedProductField.DAMAGED_PRODUCT_PHOTO]}>
              <ErrorMessage errors={errors} name={DamagedProductField.DAMAGED_PRODUCT_PHOTO} />
            </WarehouseFormTooltip>
          </TooltipWrapper>

          <TooltipWrapper>
            <UploadItem
              {...register[DamagedProductField.DAMAGED_EXTERIOR_PHOTO]}
              id={DamagedProductField.DAMAGED_EXTERIOR_PHOTO}
              labelText={formatMessage(ticketFormContent.exteriorPhoto)}
            />
            <WarehouseFormTooltip active={!!errors[DamagedProductField.DAMAGED_EXTERIOR_PHOTO]}>
              <ErrorMessage errors={errors} name={DamagedProductField.DAMAGED_EXTERIOR_PHOTO} />
            </WarehouseFormTooltip>
          </TooltipWrapper>

          <TooltipWrapper>
            <UploadItem
              {...register[DamagedProductField.ADDITIONAL_PHOTO1]}
              id={DamagedProductField.ADDITIONAL_PHOTO1}
              labelText={formatMessage(ticketFormContent.additionalPhoto)}
            />
            <WarehouseFormTooltip active={Boolean(errors[DamagedProductField.ADDITIONAL_PHOTO1])}>
              <ErrorMessage errors={errors} name={DamagedProductField.ADDITIONAL_PHOTO1} />
            </WarehouseFormTooltip>
          </TooltipWrapper>

          <TooltipWrapper>
            <UploadItem
              {...register[DamagedProductField.ADDITIONAL_PHOTO2]}
              id={DamagedProductField.ADDITIONAL_PHOTO2}
              labelText={formatMessage(ticketFormContent.additionalPhoto)}
            />
            <WarehouseFormTooltip active={Boolean(errors[DamagedProductField.ADDITIONAL_PHOTO2])}>
              <ErrorMessage errors={errors} name={DamagedProductField.ADDITIONAL_PHOTO2} />
            </WarehouseFormTooltip>
          </TooltipWrapper>
        </WarehouseFormImageGrid>

        <WarehouseFormOtherDetails>
          <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.OTHER_DETAILS}>
            {formatMessage(ticketFormContent.damagedAdditionalDetails)}
          </WarehouseFormLabel>
          <WarehouseFormTextArea
            {...register[DamagedProductField.OTHER_DETAILS]}
            id={DamagedProductField.OTHER_DETAILS}
            placeholder={formatMessage(ticketFormContent.damagedAdditionalDetailsPlaceholder)}
          />
        </WarehouseFormOtherDetails>

        {!isCrossdock && (
          <WarehouseFormOtherDetails>
            <WarehouseFormLabel appearance="INFO" htmlFor={DamagedProductField.EMAIL}>
              {formatMessage(ticketFormContent.email)}
            </WarehouseFormLabel>
            <StyledTextInput
              id={DamagedProductField.EMAIL}
              {...register[DamagedProductField.EMAIL]}
              helpText={
                warehouseEmailAsyncState.loading
                  ? formatMessage(ticketFormContent.emailLoadingMessage)
                  : formatMessage(ticketFormContent.emailMessage, { warehouseEmail: warehouseEmailAsyncState.value })
              }
              disabled={warehouseEmailAsyncState.loading}
            />
          </WarehouseFormOtherDetails>
        )}

        <Button disabled={preventSubmit} block type="submit">
          <LoadingSpinner loading={preventSubmit} size="lg">
            {formatMessage(ticketFormContent.submit)}
          </LoadingSpinner>
        </Button>
      </WarehouseForm>
    </FormProvider>
  );
};
