import { defineMessages } from "react-intl";

export const ticketFormContent = defineMessages({
  subtitle: {
    id: "facilityCommons.damagedProductForm.ticketFormSubtitle",
    defaultMessage: "Please fill in the form",
  },
  barcodeRequired: {
    id: "facilityCommons.damagedProductForm.barcodeRequired",
    defaultMessage: "Scan or enter barcode on the SKU",
  },
  barcodePlaceholder: {
    id: "facilityCommons.damagedProductForm.barcodePlaceholder",
    defaultMessage: "Ex. 036000291451",
  },
  poRequired: {
    id: "facilityCommons.damagedProductForm.poRequired",
    defaultMessage: "Enter PO #",
  },
  poPlaceholder: {
    id: "facilityCommons.damagedProductForm.poPlaceholder",
    defaultMessage: "Ex. 160776",
  },
  cdsku: {
    id: "facilityCommons.damagedProductForm.cdsku",
    defaultMessage: "Scan or enter box ID",
  },
  cdskuPlaceholder: {
    id: "facilityCommons.damagedProductForm.cdskuPlaceholder",
    defaultMessage: "Ex. CDABCZYXW12",
  },
  noCdskuMessage: {
    id: "facilityCommons.damagedProductForm.button.noCdskuMessage",
    defaultMessage: "Can't find the box ID?",
  },
  noCdskuButton: {
    id: "facilityCommons.damagedProductForm.button.noCdskuButton",
    defaultMessage: "Switch to PO #",
  },
  switchToCdskuMessage: {
    id: "facilityCommons.damagedProductForm.button.switchToCdskuMessage",
    defaultMessage: "Found the box ID?",
  },
  switchToCdskuButton: {
    id: "facilityCommons.damagedProductForm.button.switchToCdskuButton",
    defaultMessage: "Switch to Box ID",
  },
  damageType: {
    id: "facilityCommons.damagedProductForm.damageType",
    defaultMessage: "Is the product damaged or expired?",
  },
  productDamaged: {
    id: "facilityCommons.damagedProductForm.productDamaged",
    defaultMessage: "Damaged",
  },
  productExpired: {
    id: "facilityCommons.damagedProductForm.productExpired",
    defaultMessage: "Expired",
  },
  damagedQuantityRequired: {
    id: "facilityCommons.damagedProductForm.damagedQuantityRequired",
    defaultMessage: "How many units were damaged?",
  },
  caseDamagedQuantityRequired: {
    id: "facilityCommons.damagedProductForm.caseDamagedQuantityRequired",
    defaultMessage: "How many cases were damaged?",
  },
  caseExpirededQuantityRequired: {
    id: "facilityCommons.damagedProductForm.caseExpirededQuantityRequired",
    defaultMessage: "How many cases were expired?",
  },
  unitsPerCaseDamagedQuantityRequired: {
    id: "facilityCommons.damagedProductForm.unitsPerCaseDamagedQuantityRequired",
    defaultMessage: "Enter units per box",
  },
  quantityPlaceholder: {
    id: "facilityCommons.damagedProductForm.quantityPlaceholder",
    defaultMessage: "Ex. 5",
  },
  unitsPerCasePlaceholder: {
    id: "facilityCommons.damagedProductForm.unitsPerCasePlaceholder",
    defaultMessage: "Ex. 10",
  },
  boxDamaged: {
    id: "facilityCommons.damagedProductForm.boxDamaged",
    defaultMessage: "Is the shipping box damaged?",
  },
  disposedRequired: {
    id: "facilityCommons.damagedProductForm.disposedRequired",
    defaultMessage: "Will you dispose of damaged units?",
  },
  caseDisposedRequired: {
    id: "facilityCommons.damagedProductForm.caseDisposedRequired",
    defaultMessage: "Will you dispose of {type} cases?",
  },
  locationRequired: {
    id: "facilityCommons.damagedProductForm.location",
    defaultMessage: "Enter Location",
  },
  locationPlaceholder: {
    id: "facilityCommons.damagedProductForm.locationPlaceholder",
    defaultMessage: "E.g. DMG",
  },
  yes: {
    id: "facilityCommons.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "facilityCommons.no",
    defaultMessage: "No",
  },
  photosSubtitle: {
    id: "facilityCommons.damagedProductForm.ticketFormPhotosSubtitle",
    defaultMessage: "Upload all photos",
  },
  photosMessage: {
    id: "facilityCommons.damagedProductForm.ticketFormPhotosMessage",
    defaultMessage: "This will result in faster resolutions",
  },
  photosMessageExpired: {
    id: "facilityCommons.damagedProductForm.ticketFormPhotosMessageExpired",
    defaultMessage: "If expired, you must take a clear picture of the expiration date on the items",
  },
  damagedPhotoRequired: {
    id: "facilityCommons.damagedProductForm.damagedPhotoRequired",
    defaultMessage: "Damaged Item(s)",
  },
  damagedCasePhotoRequired: {
    id: "facilityCommons.damagedProductForm.damagedCasePhotoRequired",
    defaultMessage: "Damaged Case(s)",
  },
  exteriorPhoto: {
    id: "facilityCommons.damagedProductForm.exteriorPhoto",
    defaultMessage: "Exterior Carton",
  },
  caseBoxLabelPhoto: {
    id: "facilityCommons.damagedProductForm.caseBoxLabelPhoto",
    defaultMessage: "Box Content Label",
  },
  additionalPhoto: {
    id: "facilityCommons.damagedProductForm.additionalPhoto",
    defaultMessage: "Additional Photo",
  },
  damagedAdditionalDetails: {
    id: "facilityCommons.damagedProductForm.damagedAdditionalDetails",
    defaultMessage: "Additional details",
  },
  damagedAdditionalDetailsPlaceholder: {
    id: "facilityCommons.damagedProductForm.damagedAdditionalDetailsPlaceholder",
    defaultMessage: "How was this damaged? Ex. Arrived damaged, dropped in warehouse, etc.",
  },
  email: {
    id: "facilityCommons.damagedProductForm.email",
    defaultMessage: "Email for ticket updates",
  },
  emailLoadingMessage: {
    id: "facilityCommons.damagedProductForm.emailLoadingMessage",
    defaultMessage: "Fetching default email address...",
  },
  emailMessage: {
    id: "facilityCommons.damagedProductForm.emailMessage",
    defaultMessage: "If left blank, updates will be automatically sent to {warehouseEmail}.",
  },
  submit: {
    id: "facilityCommons.submit",
    defaultMessage: "Submit",
  },
});
