import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface ClosedPalletModalProps {
  onCancel: () => void;
  asnId: number;
}

export const ClosedPallet: React.FC<ClosedPalletModalProps> = ({ onCancel, asnId }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={formatMessage(modalsText.closedPalletTitle, { asnId })}
      text={formatMessage(modalsText.closedPalletText)}
      iconProps={dangerModalIconXl}
    >
      <Grid fullWidth>
        <Button block onClick={onCancel}>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
