import React from "react";
import { ResponseCard } from "facility-commons/flow/cards/ResponseCard";
import { FormattedMessage } from "react-intl";

export interface PalletArrivalScannerErrorCardProps {
  message: string;
}

export const PalletArrivalScannerErrorCard: React.FC<PalletArrivalScannerErrorCardProps> = ({ message }) => (
  <ResponseCard
    message={<FormattedMessage id="inbound.palletArrivalScan.scannerError" defaultMessage={message} />}
    accentColor="RED"
    icon="exclamation-circle"
  />
);
