import { SoundFx } from "facility-commons/common/sfx";
import { FlowCardData } from "./types/FlowCardData";

export function createFlowCard<P extends Record<string, any>>(type: string, sfx?: SoundFx) {
  return (props: P): FlowCardData<P> => {
    return {
      type,
      props,
      ...(sfx ? { sfx } : {}),
    };
  };
}
