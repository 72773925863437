import React from "react";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { Text, BoxReset, Button, Title, Stack, Radio, defaultTheme } from "@deliverr/ui";
import { commonLotMessages, commonReceivingMessages } from "warehouse/receiving/content";
import { useMultiConfirmationCard } from "./useMultiConfirmationCard";
import { ProductSummaryCard } from "../ProductCard";
import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";
import { commonWarehouseMessages } from "warehouse/common/messages";

export const MultiConfirmationCard = () => {
  const {
    barcode,
    description,
    expirationDateOptions,
    lotValueOptions,
    disableButton,
    onExpirationDateChange,
    onLotChange,
    onConfirm,
    onCreateNCTicket,
    formatMessage,
    getExpirationDateLabel,
    getExpirationDateValue,
    getLotLabel,
    getLotValue,
  } = useMultiConfirmationCard();

  const radioLabelStyle = `font-size: ${defaultTheme.font.size.F3};`;
  return (
    <>
      <BoxReset marginBottom="S4">
        <FacilityCard as="section">
          <ProductSummaryCard barcode={barcode} description={description} />
        </FacilityCard>
      </BoxReset>
      <FacilityCard as="section">
        <BoxReset marginBottom="S4">
          <Title as="h2" displayAs="h4">
            {formatMessage(commonReceivingMessages.confirmDetailsMsg)}
          </Title>
        </BoxReset>

        {
          <>
            <BoxReset marginBottom="S4">
              <Stack>
                <Text appearance="INFO" size="bodyLarge">
                  {formatMessage(commonReceivingMessages.expirationDate)}
                </Text>
                {expirationDateOptions.map((date, i) => (
                  <Radio
                    key={`${i}-${getExpirationDateValue(date)}`}
                    label={getExpirationDateLabel(date)}
                    labelStyles={radioLabelStyle}
                    name="expirationDate"
                    value={getExpirationDateValue(date)}
                    onChange={onExpirationDateChange}
                  ></Radio>
                ))}
              </Stack>
            </BoxReset>
            <Stack>
              <Text appearance="INFO" size="bodyLarge">
                {formatMessage(commonLotMessages.lotNumber)}
              </Text>
              {lotValueOptions.map((lotValue, i) => (
                <Radio
                  key={`${i}-${getLotValue(lotValue)}`}
                  label={getLotLabel(lotValue)}
                  labelStyles={radioLabelStyle}
                  name="lotValue"
                  value={getLotValue(lotValue)}
                  onChange={onLotChange}
                ></Radio>
              ))}
            </Stack>
          </>
        }
        <SkipStep
          onSkip={onCreateNCTicket}
          message={formatMessage(commonReceivingMessages.noDateLotMatch)}
          skipText={formatMessage(commonWarehouseMessages.createNonComplianceTicket)}
        ></SkipStep>
      </FacilityCard>
      <BoxReset marginTop="S4" marginBottom="S4">
        <Button block onClick={onConfirm} disabled={disableButton}>
          {formatMessage(commonReceivingMessages.continue)}
        </Button>
      </BoxReset>
    </>
  );
};
