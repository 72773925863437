import { atom, ReadOnlySelectorFamilyOptions, selector } from "recoil";
import { FlowCardType } from "facility-commons/flow/types";
import { FlowCardsState, FlowButtonsState } from "facility-commons/flow/types/FlowState";
import { getSameActiveFlowCardForState, getFlowVisibleButtonsForState } from "facility-commons/flow/flowState";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { WarehouseButtonType } from "./types/WarehouseFlowButtonType";
import { StorageFlowCardType } from "./types/StorageFlowCardType";

export const warehouseFlowCardsState = atom<FlowCardsState>({
  key: WarehouseStateKey.FLOW_CARDS,
  default: [],
});

export const getSameActiveFlowCard: ReadOnlySelectorFamilyOptions<
  boolean,
  FlowCardType | StorageFlowCardType
>["get"] = getSameActiveFlowCardForState(warehouseFlowCardsState);

export const warehouseFlowButtonsState = atom<FlowButtonsState>({
  key: WarehouseStateKey.FLOW_BUTTONS,
  default: {},
});

export const getFlowVisibleButtons = getFlowVisibleButtonsForState<WarehouseButtonType>(warehouseFlowButtonsState);
export const flowVisibleButtonsValue = selector<WarehouseButtonType[]>({
  key: WarehouseStateKey.FLOW_BUTTONS_VISIBLE,
  get: getFlowVisibleButtons,
});
