import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { useRouter } from "facility-commons/hooks";
import { NCRoutes } from "../../base/routes";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import {
  currentProductLotFefoDetailsState,
  currentProductState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { userState } from "facility-commons/base/Auth/userState";

export const useBarcodeUpload = () => {
  const formHandler = useFormContext();
  const currentProduct = useRecoilValue(currentProductState);
  const { isStorage } = useRecoilValue(userState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { isFefoEnabled, isLotEnabled } = useRecoilValue(currentProductLotFefoDetailsState);

  const proceed = () => {
    if (isLotEnabled || isFefoEnabled) {
      push(NCRoutes.LOT_EXPIRY_UPLOAD);
    } else {
      push(isStorage ? NCRoutes.QUANTITY : NCRoutes.PRODUCT_UPLOAD);
    }
  };
  const onFileChange = (fileName: string) => updateCurrentProduct("barcodePicture", fileName);

  return {
    formatMessage,
    imageUploaded: !!currentProduct.barcodePicture,
    onFileChange,
    register: formHandler.register("barcodePicture"),
    proceed,
  };
};
