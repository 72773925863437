import { atom } from "recoil";
import { PrepStateKey } from "./PrepStateKey";
import { PrepFlow } from "../PrepFlow";

export interface PrepFlowState<TFlow = PrepFlow> {
  flow?: TFlow;
}

const DEFAULT_FLOW_STATE: PrepFlowState = {
  flow: undefined,
};

export const prepFlowState = atom<PrepFlowState>({
  key: PrepStateKey.PREP_FLOW_STATE,
  default: DEFAULT_FLOW_STATE,
});
