import { Button, ButtonProps } from "@deliverr/ui";
import React from "react";
import { LoadingSpinner } from "facility-commons/components/LoadingSpinner";
import { useLoadingButton } from "./useLoadingButton";

export const LoadingButton: React.FC<ButtonProps> = ({ children, onClick, ...buttonProps }) => {
  const { loading, onClickWithLoading } = useLoadingButton(onClick as React.MouseEventHandler<HTMLButtonElement>);

  return (
    <Button {...buttonProps} onClick={onClickWithLoading} disabled={loading || buttonProps.disabled}>
      <LoadingSpinner loading={loading} size="lg">
        {children}
      </LoadingSpinner>
    </Button>
  );
};
