import { defineMessages } from "react-intl";

export const CASE_DIMENSIONS_ERROR_LABELS = defineMessages({
  incompleteDimsError: {
    id: "storage.inbounds.dimensionsAndWeights.incompleteError",
    defaultMessage: "Incomplete case measurements cannot be submitted.",
  },
  oversizedDimsError: {
    id: "storage.inbounds.dimensionsAndWeights.oversizedError",
    defaultMessage: 'Please enter dimensions less than {maxLength}" x {maxWidth}" x {maxHeight}" .',
  },
  overWeightError: {
    id: "storage.inbounds.dimensionsAndWeights.overweightError",
    defaultMessage: "Please enter weight less than {maxWeight} lbs .",
  },
  overDimsAndWeightError: {
    id: "storage.inbounds.dimensionsAndWeights.oversizedAndOverweightError",
    defaultMessage:
      'Please enter dimensions less than {maxLength}" x {maxWidth}" x {maxHeight}" and weight less than {maxWeight} lbs .',
  },
  invalidDimensionsError: {
    id: "storage.inbounds.dimensionsAndWeights.invalidDimensionsAndWeight",
    defaultMessage: "Please enter correct box dimensions to avoid issues during shipping from reserve storage.",
  },
});
