import { MessageDescriptor, defineMessages } from "react-intl";
import { RSOutboundsBoxSize } from "warehouse/rs-outbounds/base/RSOutboundsBoxSize";

export const selectBoxSizeCardLabels = defineMessages({
  totalQtyInPackage: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.totalQtyInPackage",
    defaultMessage: "Total quantity in package",
  },
  totalUnitsDisplay: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.totalUnitsDisplay",
    defaultMessage: "{itemsAdded} units",
  },
  selectBoxSizeTitle: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.selectBoxSizeTitle",
    defaultMessage: "Select box size",
  },
});

export const boxSizeLabelsMap: Record<RSOutboundsBoxSize, MessageDescriptor> = {
  [RSOutboundsBoxSize.SIZE_ONE]: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.boxSize.sizeOne",
    defaultMessage: "1 pair ({length} in x {width} in x {height} in)",
  },
  [RSOutboundsBoxSize.SIZE_TWO]: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.boxSize.sizeTwo",
    defaultMessage: "2 - 5 pair ({length} in x {width} in x {height} in)",
  },
  [RSOutboundsBoxSize.SIZE_THREE]: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.boxSize.sizeThree",
    defaultMessage: "6 - 10 pair ({length} in x {width} in x {height} in)",
  },
  [RSOutboundsBoxSize.SIZE_FOUR]: {
    id: "warehouse.rs-outbounds.selectBoxSizeCard.boxSize.sizeFour",
    defaultMessage: "10+ pair ({length} in x {width} in x {height} in)",
  },
};
