import React from "react";
import { Text } from "@deliverr/ui";
import { commonMessages } from "facility-commons/labels";
import { FormattedMessage } from "react-intl";

interface EmailSectionProps {
  email: string;
}

export const EmailSection: React.FC<EmailSectionProps> = ({ email }) => (
  <>
    <Text bold>
      <FormattedMessage {...commonMessages.email} />
    </Text>
    <Text>{email}</Text>
  </>
);
