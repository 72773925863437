import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { setProp } from "facility-commons/utils";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useLifecycles } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { ticketCenterState } from "../../common/ticketCenterState";
import { TicketDetailsHeaders } from "./TicketDetailsHeaders";
import { ticketsByType } from "./ticketsByType";

export const useTicketDetailPage = () => {
  const { ticketType, ticketId } = useParams<{ ticketType: NonComplianceType; ticketId: string }>();
  const { ticketTab } = useRecoilValue(ticketCenterState);
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();

  useLifecycles(
    () => {
      setWarehouseAppState(setProp("pageTitle", formatMessage(TicketDetailsHeaders[ticketType], { id: ticketId })));
    },
    () => {
      setWarehouseAppState(setProp("pageTitle", undefined));
    }
  );

  const TicketComponent = ticketsByType[ticketType][ticketTab];

  return {
    TicketComponent,
  };
};
