import { defineMessages } from "react-intl";

export const commonReceivingMessages = defineMessages({
  receivingModes: { id: "warehouse.receiving.receivingModes", defaultMessage: "Receiving Modes" },
  receivingDashboard: {
    id: "warehouse.receiving.receivingDashboard",
    defaultMessage: "Receiving dashboard",
  },
  receivingDashboardDescription: {
    id: "warehouse.receiving.receivingDashboardDescription",
    defaultMessage: "All pending and completed receives",
  },
  palletsDashboardDescription: {
    id: "warehouse.receiving.palletsDashboardDescription",
    defaultMessage: "Pallet Receiving insights",
  },
  palletsDashboard: {
    id: "warehouse.receiving.palletsDashboard",
    defaultMessage: "Pallets dashboard",
  },
  palletsAnalytics: {
    id: "warehouse.receiving.palletsAnalytics",
    defaultMessage: "Pallets analytics",
  },
  startReceiving: {
    id: "warehouse.startReceiving",
    defaultMessage: "Start Receiving",
  },
  receivedSkus: {
    id: "warehouse.receiving.receivedSkusTitle",
    defaultMessage: "Units already received:",
  },
  analytics: {
    id: "warehouse.receiving.analytics",
    defaultMessage: "Analytics",
  },
  poNumber: {
    id: "po",
    defaultMessage: "PO #",
  },
  palletLabel: {
    id: "palletLabel",
    defaultMessage: "Pallet Label",
  },
  scanConsolidationID: {
    id: "warehouse.receiving.scanLabel",
    defaultMessage: "Scan Consolidation ID or PO #",
  },
  stowSKU: {
    id: "warehouse.receive.stowSKU",
    defaultMessage: "Stow SKU",
  },
  confirm: {
    id: "confirm",
    defaultMessage: "Confirm",
  },
  confirmReceive: {
    id: "warehouse.receiving.confirmReceive",
    defaultMessage: "Confirm receive",
  },
  continue: {
    id: "continue",
    defaultMessage: "Continue",
  },
  none: {
    id: "none",
    defaultMessage: "None",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  required: {
    id: "required",
    defaultMessage: "Required",
  },
  barcodeLabel: {
    id: "warehouse.receiving.barcodeLabel",
    defaultMessage: "Barcode: {barcode}",
  },
  dskuLabel: {
    id: "warehouse.receiving.dskuLabel",
    defaultMessage: "DSKU: {dsku}",
  },
  reportProblem: { id: "warehouse.receiving.reportProblem", defaultMessage: "Report problem" },
  expirationDate: { id: "warehouse.receiving.expirationDate", defaultMessage: "Expiration Date" },
  missingExpirationDateInput: {
    id: "warehouse.receiving.missingExpirationDateInput",
    defaultMessage: "Missing expiration date input.",
  },
  expirationDateIsMissing: {
    id: "warehouse.receiving.expirationDateIsMissing",
    defaultMessage: "Expiration date is missing",
  },
  incorrectExpirationDateFormat: {
    id: "warehouse.receiving.incorrectExpirationDateFormat",
    defaultMessage: "Expiration date must follow the format of MM/DD/YYYY",
  },
  invalidMonth: {
    id: "warehouse.receiving.invalidMonth",
    defaultMessage: "The month must be between 01 and 12.",
  },
  invalidDay: {
    id: "warehouse.receiving.invalidDay",
    defaultMessage: "The day must be between 01 and {lastDateOfMonth} for the provided month and year.",
  },
  invalidYear: {
    id: "warehouse.receiving.invalidYear",
    defaultMessage: "The year must be between {minYear} and {maxYear}.",
  },
  noDateLotMatch: { id: "warehouse.receiving.noDateLotMatch", defaultMessage: "Can't find a matching number or date?" },
  expiredProduct: {
    id: "warehouse.receiving.expiredProduct",
    defaultMessage: "Can not receive expired product. Create a non-compliance ticket.",
  },
  countUnitsDateLot: {
    id: "warehouse.receiving.countUnitsDateLot",
    defaultMessage: "Count units with lot {lotNumber} that expire on {expirationDate}",
  },
  countUnitsDate: {
    id: "warehouse.receiving.countUnitsDate",
    defaultMessage: "Count units that expire on {expirationDate}",
  },
  confirmDetailsMsg: {
    id: "warehouse.receiving.confirmDetailsMsg",
    defaultMessage: "Confirm details match what's on the product",
  },
  confirmOrEditDetailsMsg: {
    id: "warehouse.receiving.confirmOrEditDetailsMsg",
    defaultMessage: "Confirm details match what's on the product or edit them below",
  },
  receiveMoreUnits: {
    id: "warehouse.receiving.receiveMoreUnits",
    defaultMessage: "Do you want to receive more units from this box?",
  },
  inputProductDetails: {
    id: "warehouse.receiving.inputProductDetails",
    defaultMessage: "Input product details",
  },
  expirationDateInputHelpText: {
    id: "warehouse.receiving.expirationDateInputHelpText",
    defaultMessage: "MM/DD/YYYY. Use 01 for the day if the expiration date does not have a day value.",
  },
  zoneRecommendationMsg: {
    id: "warehouse.receiving.zoneRecommendationMsg",
    defaultMessage: "Recommend receiving to Zone {zone}.",
  },
  noZoneRecommendationMsg: {
    id: "warehouse.receiving.noZoneRecommendationMsg",
    defaultMessage: "No recommended receiving zone available.",
  },
  doNotCopyAndPaste: {
    id: "warehouse.receiving.doNotCopyAndPaste",
    defaultMessage: "Do not copy and paste.",
  },
  missingBarcodePrompt: {
    id: "warehouse.receiving.missingBarcodePrompt",
    defaultMessage: "Missing barcode?",
  },
  missingBarcodeLabel: {
    id: "warehouse.receiving.missingBarcodeLabel",
    defaultMessage: "MISSING BARCODE",
  },
  uploadImages: {
    id: "warehouse.receiving.uploadImages",
    defaultMessage: "Upload Images",
  },
});

export const commonPalletMessages = defineMessages({
  receivePalletHeader: { id: "warehouse.receiving.receivePallet", defaultMessage: "Receive Pallet" },
  palletReceiving: { id: "warehouse.receiving.receivePallets", defaultMessage: "Pallets" },
  palletReceiveInstructions: {
    id: "warehouse.receiving.palletReceiveInstructions",
    defaultMessage: "Receive single SKU CD (yellow) pallets to one location",
  },
  scanPallet: { id: "scanPallet", defaultMessage: "Scan pallet" },
  scanPalletInstructions: {
    id: "warehouse.receiving.scanPalletInstructions",
    defaultMessage: "Scan or enter the PO # on the pallet label",
  },
  palletStowPageTitle: { id: "warehouse.receiving.palletStowTitle", defaultMessage: "Stow Pallet" },
  receivePalletContent: { id: "warehouse.receiving.receivePalletContent", defaultMessage: "Receive Pallet Contents" },
  scanPalletPlaceholder: {
    id: "warehouse.receiving.scanPalletPlaceholder",
    defaultMessage: "PO # or pallet label",
  },
  scanPalletInput: {
    id: "warehouse.receiving.scanPalletInput",
    defaultMessage: "Scan PO # or pallet label",
  },
});

export const commonConsolidationMessages = defineMessages({
  receiveConsolidation: {
    id: "warehouse.receive.receiveConsolidation",
    defaultMessage: "Receive Consolidation",
  },
  consolidationID: {
    id: "warehouse.receiving.consolidationID",
    defaultMessage: "CID or PO #",
  },
  consolidationPallets: { id: "warehouse.receiving.consolidation", defaultMessage: "Consolidations" },
  consolidationHeader: { id: "warehouse.receiving.receiveConsolidation", defaultMessage: "Receive Consolidation" },
  consolidationPalletsInstructions: {
    id: "warehouse.receiving.consolidationPalletsInstructions",
    defaultMessage: "Receive inventory from other facilities",
  },
  consolidationDescription: {
    id: "warehouse.receiving.scanConsolidationInstructions",
    defaultMessage: "Scan or enter the ID or PO # for the consolidation",
  },
  consolidationHelperText: {
    id: "warehouse.receiving.consolidationHelperText",
    defaultMessage: "Consolidation ID starts with 'CID'",
  },
  invalidConsolidationLabelFormat: {
    id: "receiving.invalidConsolidationLabel",
    defaultMessage: "Invalid consolidation ID.",
  },
});

export const commonReturnReceiveMessage = defineMessages({
  returnReceive: { id: "warehouse.receiving.returnReceive", defaultMessage: "Return Orders" },
  receiveReturnOrder: { id: "warehouse.receiving.receiveReturnOrder", defaultMessage: "Receive Return Order" },
  returnReceiveInstructions: {
    id: "warehouse.receiving.returnReceiveInstructions",
    defaultMessage: "Receive return order package items",
  },
  packageScanIllustrationTitle: {
    id: "warehouse.receiving.returnReceive.packageScanIllustrationTitle",
    defaultMessage: "Scan a return package",
  },
  scanOrEnterTrackingCode: {
    id: "warehouse.receiving.returnReceive.scanOrEnterTrackingCode",
    defaultMessage: "Scan or enter the tracking code",
  },
  scanTrackingCode: {
    id: "warehouse.receiving.returnReceive.scanTrackingCode",
    defaultMessage: "Scan tracking code",
  },
  trackingCode: {
    id: "warehouse.receiving.returnReceive.trackingCode",
    defaultMessage: "Tracking code",
  },
  unableToFindReturnOrder: {
    id: "warehouse.receiving.returnReceive.unableToFindReturnOrder",
    defaultMessage: "Unable to find return order",
  },
  barcodeScanIllustrationTitle: {
    id: "warehouse.receiving.returnReceive.barcodeScanIllustrationTitle",
    defaultMessage: "Open this package",
  },
  barcodeScanMessage: {
    id: "warehouse.receiving.returnReceive.barcodeScanMessage",
    defaultMessage: "Open the package and scan or enter a barcode.",
  },
  barcodeScanTitle: {
    id: "warehouse.receiving.returnReceive.barcodeScanTitle",
    defaultMessage: "Scan a barcode",
  },
  barcodeScanPlaceholder: {
    id: "warehouse.receiving.returnReceive.barcodeScanPlaceholder",
    defaultMessage: "Barcode",
  },
  numItemsInspected: {
    id: "warehouse.receiving.returnReceive.numItemsInspected",
    defaultMessage: "{numItemsInspected, plural, one {# item inspected} other {# items inspected}}",
  },
  numItemsLeftToInspect: {
    id: "warehouse.receiving.returnReceive.numItemsLeftToInspect",
    defaultMessage: "{numItemsLeftToInspect, plural, one {# item left} other {# items left}}",
  },
  addReturnOrderNote: {
    id: "warehouse.receiving.returnReceive.addReturnOrderNote",
    defaultMessage: "Add order inspection note",
  },
  addItemInspectionNote: {
    id: "warehouse.receiving.returnReceive.addItemInspectionNote",
    defaultMessage: "Add item inspection note",
  },
  inspectionCardTitle: {
    id: "warehouse.receiving.returnReceive.inspectionCardTitle",
    defaultMessage: "Select item condition",
  },
  viewInspectionStandards: {
    id: "warehouse.receiving.returnReceive.inspectionStandards",
    defaultMessage: "View Inspection Standards",
  },
  valueAddedServices: {
    id: "warehouse.receiving.returnReceive.valueAddedServices",
    defaultMessage: "Value Added Services",
  },
  missingLot: {
    id: "warehouse.receiving.returnReceive.missingLot",
    defaultMessage: "Can't find the lot number?",
  },
  missingExpirationDate: {
    id: "warehouse.receiving.returnReceive.missingExpiration",
    defaultMessage: "Can't find the expiration date?",
  },
  missingLotOrExpiration: {
    id: "warehouse.receiving.returnReceive.missingLotOrExpiration",
    defaultMessage: "Can't find the lot or expiration date?",
  },
  cannotRestockItemWithOutAllProductDetails: {
    id: "warehouse.receiving.returnReceive.changeItemCondition",
    defaultMessage:
      "This item cannot be restocked if we don't have all the product details. You must select another condition for this item.",
  },
  sortTolocation: {
    id: "warehouse.receiving.returnReceive.sortTolocation",
    defaultMessage: "Sort item to appropriate pile.",
  },
  itemDisposition: {
    id: "warehouse.receiving.returnReceive.itemDisposition",
    defaultMessage: "Item disposition: {disposition}",
  },
  scanRTMBox: {
    id: "warehouse.receiving.returnReceive.scanRTMBox",
    defaultMessage: "Scan RTM box",
  },
  RTMBoxBarcode: {
    id: "warehouse.receiving.returnReceive.RTMBoxBarcode",
    defaultMessage: "RTM Box Barcode",
  },
  addItemToRTMBox: {
    id: "warehouse.receiving.returnReceive.addItemToRTMBox",
    defaultMessage: "Add item to box and scan the box barcode",
  },
  createNewRTMBox: {
    id: "warehouse.receiving.returnReceive.createNewRTMBox",
    defaultMessage: "Create a new RTM box?",
  },
  boxCreated: {
    id: "warehouse.receiving.returnReceive.boxCreated",
    defaultMessage: "Box created",
  },
  createAnotherBox: {
    id: "warehouse.receiving.returnReceive.openAnotherBox",
    defaultMessage: "Create another box",
  },
  unableToCreateRTMBoxBarcode: {
    id: "warehouse.receiving.returnReceive.unableToCreateRTMBoxBarcode",
    defaultMessage: "Unable to create RTM box barcode",
  },
  noRTMBoxBarcode: {
    id: "warehouse.receiving.returnReceive.noRTMBoxBarcode",
    defaultMessage: "No RTM box barcode found, please create a new RTM box first.",
  },
  reprintBoxBarcode: {
    id: "warehouse.receiving.returnReceive.reprintRTMBoxBarcode",
    defaultMessage: "Reprint Box Barcode",
  },
  closeReturnOrder: {
    id: "warehouse.receiving.returnReceive.closeReturnOrder",
    defaultMessage: "Close Return Order",
  },
  unexpectedItemWarningTitle: {
    id: "warehouse.receiving.returnReceive.unexpectedItemWarningTitle",
    defaultMessage: "Unexpected Item",
  },
  unexpectedItemWarningText: {
    id: "warehouse.receiving.returnReceive.unexpectedItemWarningText",
    defaultMessage:
      "{barcode} is not expected on this return order. Are you sure you want to continue to item inspection?",
  },
  unknownItemTitle: {
    id: "warehouse.receiving.returnReceive.unknownItemTitle",
    defaultMessage: "Unknown Item",
  },
  unknownItemText: {
    id: "warehouse.receiving.returnReceive.unknownItemText",
    defaultMessage: "{barcode} is not a known item. Are you sure you want to continue to item inspection?",
  },
  enterOrScanRestockLocation: {
    id: "warehouse.receiving.returnReceive.enterOrScanRestockLocation",
    defaultMessage: "Scan or enter restock location",
  },
  restockLocation: {
    id: "warehouse.receiving.returnReceive.restockLocation",
    defaultMessage: "Restock location",
  },
  uploadPhotosOfItem: {
    id: "warehouse.receiving.returnReceive.uploadPhotosOfItem",
    defaultMessage: "Upload photos of the item",
  },
  takePhoto: {
    id: "warehouse.receiving.returnReceive.takePhoto",
    defaultMessage: "Take photo",
  },
  maxImageCountExceeded: {
    id: "warehouse.receiving.returnReceive.maxImageCountExceeded",
    defaultMessage: "Maximum number of images reached",
  },
  unableToConnectToCamera: {
    id: "warehouse.receiving.returnReceive.unableToConnectToCamera",
    defaultMessage: "Unable to connect to camera",
  },
  noBarcodeFoundForItem: {
    id: "warehouse.receiving.returnReceive.noBarcodeFoundForItem",
    defaultMessage: "No barcode found for this item",
  },
});

export const commonBoxMessages = defineMessages({
  boxReceiving: { id: "warehouse.receiving.receiveBoxes", defaultMessage: "Boxes" },
  boxReceiveDescription: {
    id: "warehouse.receiving.boxReceiveDescription",
    defaultMessage: "Receive individual or multiple boxes",
  },
  missingCdksu: {
    id: "warehouse.receiving.missingCdsku",
    defaultMessage: "Missing CDSKU",
  },
  previouslyReceived: {
    id: "warehouse.receiving.previousReceivesWarning",
    defaultMessage: "Some units in this box have been previously received.",
  },
  expectedQtyVsReceivedQty: {
    id: "warehouse.receiving.expectedQtyVsReceivedQty",
    defaultMessage:
      "Expected {expectedQty, plural, =1 {# unit} other {# units}}. Received {receivedQty, plural, =1 {# unit} other {# units}}.",
  },
  highRiskBox: {
    id: "warehouse.receiving.highRiskBox",
    defaultMessage: "This is a high risk box. Receive one unit at a time.",
  },
  cdskuLabel: {
    id: "warehouse.receiving.cdskuLabel",
    defaultMessage: "CDSKU Label",
  },
  skuBarcode: {
    id: "warehouse.receiving.skuBarcode",
    defaultMessage: "SKU Barcode",
  },
  allUnits: {
    id: "warehouse.receiving.allUnits",
    defaultMessage: "All Units",
  },
});
export const commonLotMessages = defineMessages({
  lotTitle: {
    id: "warehouse.receiving.scanLotNumber",
    defaultMessage: "Scan lot number",
  },
  lotInstructions: {
    id: "warehouse.receiving.lotInstruction",
    defaultMessage: "Scan or enter the lot number on any box.",
  },
  missingLotInput: {
    id: "warehouse.receiving.missingLotInput",
    defaultMessage: "Missing lot input.",
  },
  lotIsMissing: {
    id: "warehouse.receiving.lotIsMissing",
    defaultMessage: "Lot is missing",
  },
  invalidLotInput: {
    id: "warehouse.receiving.invalidLotInput",
    defaultMessage: "Lot value can only include numbers and letters.",
  },
  lotNumber: {
    id: "warehouse.receiving.lotNumber",
    defaultMessage: "Lot Number",
  },
  countUnitsLot: {
    id: "warehouse.receiving.countUnitsLot",
    defaultMessage: "Count units with lot {lotNumber}",
  },
});
