import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletNotFoundErrorMessageKey {
  PALLET_NOT_FOUND = "PALLET_NOT_FOUND",
}

export const PALLET_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  PalletNotFoundErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletNotFoundErrorMessageKey.PALLET_NOT_FOUND]: {
    id: "warehouse.receiving.palletNotFound",
    defaultMessage: "No pallet was found with label {palletLabelId}.",
  },
});
