import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface NoLotFefoSupportModalProps {
  onCancel: () => void;
  onContinue: () => void;
  title: string;
  message: string;
}

export const NoLotFefoSupportModal: React.FC<NoLotFefoSupportModalProps> = ({
  onCancel,
  onContinue,
  title,
  message,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate buttonLayout="row" title={title} text={message} iconProps={dangerModalIconXl}>
      <Grid gap="S2" fullWidth>
        <Button block onClick={onContinue}>
          {formatMessage(modalsText.createTicketText)}
        </Button>
        <Button block onClick={onCancel} secondary>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
