import { Message } from "react-chatbot-ui";
import { MessageButton } from "react-chatbot-ui/dist/shared/models/Message";
import { MessageWithButton } from "react-chatbot-ui/dist/shared/models";

export function createMsgWithText(type: "left" | "right", text: string): Message {
  let url;
  if (!text.includes("url:")) {
    return { mid: Date.now(), type, text };
  }
  const splitted = text.split("url:");
  text = splitted[0];
  url = splitted[1];
  return { mid: Date.now(), type, text, image: url };
}

export function createMsgWithButton(buttons: Array<MessageButton>): MessageWithButton {
  return { mid: Date.now() + 1, button: buttons, type: "right" };
}
