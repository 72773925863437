import { useWarehouseModal } from "../../useWarehouseModal";
import { WarehouseModal } from "../../WarehouseModalMap";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { reroutedAsnIdState, arrivalInputState } from "warehouse/scan-arrival/ScanArrivalState";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { createArrivalScannerCard } from "warehouse/common/flow/warehouseFlowCardCreators";

export function useUnexpectedPalletWarningModal() {
  const reroutedAsnId = useRecoilValue(reroutedAsnIdState);
  const { hideModal } = useWarehouseModal();
  const { addFlowCard, resetFlowCards } = useWarehouseFlow();
  const resetArrivalScanInput = useResetRecoilState(arrivalInputState);

  const onConfirmClick = () => {
    resetFlowCards();
    resetArrivalScanInput();
    hideModal(WarehouseModal.UNEXPECTED_PALLET_WARNING);
    addFlowCard(createArrivalScannerCard({}));
  };

  return {
    onConfirmClick,
    reroutedAsnId,
  };
}
