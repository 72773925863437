import { useRef } from "react";
import { useAsyncFn, useLifecycles } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIntl } from "react-intl";
import { some } from "lodash/fp";

import { createDangerNotification, createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import { createReceivingSKULP, createReceivingSKUQuantity } from "../storageReceivingCardCreators";
import { DimensionsAndWeight } from "../../utils/DimensionsAndWeightType";
import { getCaseDimensionsError } from "../../utils/getCaseDimsAndWeightError";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { userState } from "facility-commons/base/Auth/userState";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { DeliverrError } from "@deliverr/commons-objects";
import { CreatePackInProductAndInboundResponse } from "@deliverr/storage-client";

export const useReceivingSKUCaseInfoScanFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const { caseWeight = 0, caseLength = 0, caseHeight = 0, caseWidth = 0 } = scanState.currentSkuDetails ?? {};

  const { addFlowCard, hideAllFlowButtons, showFlowButton, transition, addAutoCloseNotification } = useWarehouseFlow();
  const { showModal } = useWarehouseModal();
  const boxesRef = useRef<HTMLInputElement>(null);
  const { storageClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);

  const currentSKU = scanState.currentSKU!;

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_INFO_RECEIVING_BACK_BUTTON);
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_CASE_INFO_RECEIVING_CONTINUE_BUTTON);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [{ loading: isBackLoading }, handleBackClick] = useAsyncFn(() => {
    const handleBackReceiveSKUQtyScan = async () => {
      setScanState((state) => ({
        ...state,
        currentSkuDetails: {
          ...state.currentSkuDetails,
          isNewChildProductCreated: false,
        },
      }));
      addFlowCard(createReceivingSKUQuantity({}));
    };
    return handleBackReceiveSKUQtyScan();
  });

  const [{ loading }, handleSubmit] = useAsyncFn(
    async ({ warehouseId, ...dimsAndWeight }: { warehouseId: string } & DimensionsAndWeight) => {
      const ctx = { fn: "useReceivingSKUCaseInfoScanFlow.handleSubmitSKUCaseInfo", poNum: scanState.poNum };
      logStart(ctx);
      const errorMsg = getCaseDimensionsError(dimsAndWeight, formatMessage);
      if (errorMsg) {
        addAutoCloseNotification(createDangerNotification(errorMsg));
        return;
      }

      try {
        const response = await storageClient.createPackInProductAndInbound(warehouseId, {
          ...dimsAndWeight,
          packOf: scanState.currentSkuDetails.packOf || scanState.currentSKU,
          weightUnit: "lb",
          lengthUnit: "in",
          quantity: scanState.currentSkuDetails.receivedCaseQty,
        });
        const productCasePack = ((response as unknown) as CreatePackInProductAndInboundResponse)?.data;
        if (!productCasePack) {
          throw new DeliverrError({
            code: "UNKNOWN_ERROR",
            message: formatMessage(STORAGE_RECEIVING_MESSAGES.productCasePackCreationFailed),
          });
        }
        setScanState((state) => ({
          ...state,
          currentSKU: productCasePack.dsku,
          currentSkuDetails: {
            ...state.currentSkuDetails,
            dsku: productCasePack.dsku,
            caseQty: productCasePack.quantity,
            width: productCasePack.width,
            height: productCasePack.height,
            length: productCasePack.length,
            lengthUnit: productCasePack.lengthUnit,
            weight: productCasePack.weight,
            weightUnit: productCasePack.weightUnit,
            barcodes: [...state.currentSkuDetails.barcodes, productCasePack.dsku],
            isNewChildProductCreated: true,
          },
        }));
        transition([
          {
            card: createReceivingSKULP({}),
            notification: createSuccessNotification(
              formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanSuccessNotificationTitle)
            ),
            sfx: SoundFx.SUCCESS,
            flash: "SUCCESS",
            onTransition: () => {
              hideAllFlowButtons();
              showFlowButton(StorageFlowButtonType.STORAGE_SKU_LP_RECEIVING_BACK_BUTTON);
            },
          },
        ]);
      } catch (err: any) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err.message),
        });
      }
    }
  );

  const handleClick = () => {
    handleSubmit({ warehouseId, weight: caseWeight, height: caseHeight, width: caseWidth, length: caseLength });
  };

  const handleCaseWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseWeightValue = parseInt(e.target.value, 10) || 0;
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        caseWeight: caseWeightValue,
      },
    }));
  };

  const handleCaseHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseHeightValue = parseInt(e.target.value, 10) || 0;
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        caseHeight: caseHeightValue,
      },
    }));
  };

  const handleCaseLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseLengthValue = parseInt(e.target.value, 10) || 0;
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        caseLength: caseLengthValue,
      },
    }));
  };

  const handleCaseWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseWidthValue = parseInt(e.target.value, 10) || 0;
    setScanState((state) => ({
      ...state,
      currentSkuDetails: {
        ...state.currentSkuDetails,
        caseWidth: caseWidthValue,
      },
    }));
  };

  const isContinueButtonDisabled = some((value) => (value ?? 0) <= 1, [caseHeight, caseLength, caseWeight, caseWidth]);

  return {
    scanState,
    caseWeight,
    caseLength,
    caseWidth,
    caseHeight,
    boxesRef,
    currentSKU,
    handleCaseHeightChange,
    handleCaseLengthChange,
    handleCaseWeightChange,
    handleCaseWidthChange,
    handleSubmit,
    handleClick,
    handleBackClick,
    loading,
    isBackLoading,
    isContinueButtonDisabled,
  };
};
