export const MAX_UNITS_TO_RECEIVE = 6000;
export const MAX_PO_INPUT_LENGTH = 15;
export const MAX_LOT_VALUE_INPUT_LENGTH = 20;
export const MAX_BOXES_TO_RECEIVE = 1000;
export const QTY_INPUT_LENGTH_LIMIT = 10;
export const SUCCESS_TOAST_AUTO_CLOSE = 6000;
export const MINIMUM_EXPIRE_YEAR = 2000;
export const MAXIMUM_EXPIRE_YEAR = 2100;
export const DEFAULT_MISSING_LOT_INPUT = "MISSING";
export const DEFAULT_MISSING_EXPIRY_INPUT = "01/01/2040";
export const DEFAULT_MISSING_LOT_EXPIRY_LOCATION = "RELOT";
