import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { PalletReceiveData } from "./Types";

/** bulk receive data */
export const INITIAL_PALLET_RECEIVE_DATA: PalletReceiveData = {
  palletLabel: "",
  isBulkPalletReceiveEligible: false,
};

export const palletReceiveState = atom<PalletReceiveData>({
  key: WarehouseStateKey.PALLET_BULK_RECEIVE,
  default: INITIAL_PALLET_RECEIVE_DATA,
});
