import React, { FC, useContext } from "react";
import { configContext } from "./configContext";
import { ButtonInput, MessageWithButton } from "react-chatbot-ui/dist/shared/models";
import { Select } from "@deliverr/ui";
import styled from "@emotion/styled";

interface Props {
  button: MessageWithButton["button"];
}

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledSelect = styled(Select)`
  width: 20rem;
  margin: 0.5em;
`;

export const Dropdown: FC<Props> = (props: Props) => {
  const config = useContext(configContext);
  const buttons = props.button;
  const options = buttons.map((button) => ({ value: button, label: button.name }));

  const onClick = (option) => {
    if (config?.buttonCallback) {
      const buttonInput: ButtonInput = {
        payload: option.value,
      };
      config.buttonCallback(buttonInput);
    }
  };

  return (
    <SelectContainer>
      <StyledSelect options={options} onChange={onClick} />
    </SelectContainer>
  );
};
