import React from "react";
import { useIntl } from "react-intl";
import { ContinueButton, ModalTemplate, warningModalIcon } from "facility-commons";
import { modalsText } from "../modalsText";

export const SingleSkuSIOCModal: React.FC<{
  onConfirm: () => void;
}> = ({ onConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(modalsText.singleSkuSiocModalTitle)}
      text={formatMessage(modalsText.singleSkuSiocModalText)}
      iconProps={warningModalIcon}
    >
      <ContinueButton data-testid="SingleSkuSIOCModal.confirm" block onClick={onConfirm} />
    </ModalTemplate>
  );
};
