import React from "react";
import { useIntl } from "react-intl";
import { useSelectBoxSizeCard } from "./useSelectBoxSizeCard";
import { selectBoxSizeCardLabels } from "./selectBoxSizeCardLabels";
import { Radio, Stack, Text } from "@deliverr/ui";
import { FacilityCard } from "facility-commons/components";

export const SelectBoxSizeCard = () => {
  const { itemsAdded, selectedBoxSize, boxSizeOptions, onBoxSizeChange } = useSelectBoxSizeCard();
  const { formatMessage } = useIntl();

  return (
    <>
      <FacilityCard>
        <Text bold>{formatMessage(selectBoxSizeCardLabels.totalQtyInPackage)}</Text>
        <Text>{formatMessage(selectBoxSizeCardLabels.totalUnitsDisplay, { itemsAdded })} </Text>
      </FacilityCard>
      <FacilityCard as="section">
        <Stack gap={"S2"}>
          <Text bold>{formatMessage(selectBoxSizeCardLabels.selectBoxSizeTitle)}</Text>
          {boxSizeOptions.map((option) => (
            <Radio
              key={option.value}
              label={option.label}
              value={option.value}
              onChange={onBoxSizeChange}
              checked={selectedBoxSize === option.value}
            />
          ))}
        </Stack>
      </FacilityCard>
    </>
  );
};
