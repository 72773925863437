import { returnReceiveItemInspectionStateAtom, returnReceiveReturnOrderStateAtom } from "../../state";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ImageMinimal } from "@deliverr/returns-client";
import { setProp } from "facility-commons";
import { useClientsWithAuth } from "facility-commons/hooks/auth";

type useInspectionImageProps = {
  index: number;
  onSuccessCallback?: () => void;
  onFailureCallback?: (errorMessage: string) => void;
};

export const useInspectionImageUpload = ({ index, onSuccessCallback, onFailureCallback }: useInspectionImageProps) => {
  const { returnsClient } = useClientsWithAuth();
  const [itemInspectionData, setItemInspectionData] = useRecoilState(returnReceiveItemInspectionStateAtom);
  const returnOrderData = useRecoilValue(returnReceiveReturnOrderStateAtom);
  const { images } = itemInspectionData;
  const [uploading, setIsUploading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    setImageUrl(images[index]?.url ?? "");
  }, [images]);

  const onSuccessfulUpload = (image: ImageMinimal) => {
    setImageUrl(image.url);
    const currentImages = [...images];
    currentImages[index] = image;
    setItemInspectionData(setProp("images", currentImages));
    onSuccessCallback?.();
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onInputFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    const prefix = `returns/${returnOrderData.orderId}/qcitem/image`;

    if (files?.length) {
      try {
        setIsUploading(true);

        const fileToUpload = files[0];
        const base64File = await toBase64(fileToUpload);
        const response = await returnsClient.saveImageBase64({
          image: base64File as string,
          prefix,
        });
        if (response) {
          onSuccessfulUpload(response.value);
        }
      } catch (error: any) {
        onFailureCallback?.(String(error.message));
      } finally {
        setIsUploading(false);
      }
    }
  };

  return { images, uploading, imageUrl, onInputFileChange, onSuccessfulUpload };
};
