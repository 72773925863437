import { WarehousePublicClient } from "@deliverr/warehouse-client";

import { logError } from "./logger";

export async function getWarehouseEmail(
  warehousePublicClient: WarehousePublicClient,
  warehouseId: string
): Promise<string | undefined> {
  try {
    const warehouse = await warehousePublicClient.getWarehouse(warehouseId);
    if (warehouse) {
      return warehouse.inboundContactEmail;
    }
  } catch (error: any) {
    logError({ fn: "getWarehouseEmail" }, error);
  }
}
