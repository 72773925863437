import { useAuth0 } from "@auth0/auth0-react";
import { Button, CardProps, DefaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";

import { userState } from "facility-commons/base/Auth/userState";
import { GroupHeader } from "facility-commons/components/GroupHeader";
import { LinksMenu, MenuLinkConfig } from "facility-commons/components/LinksMenu";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { AppName } from "facility-commons/types";
import { warehouseDataState } from "warehouse/base/warehouseIdState";
import { useDhlManifestAccount } from "warehouse/common/hooks/useDhlManifestAccount";
import { defaultInformationLinks } from ".";
import { AccountMenu } from "./AccountMenu/AccountMenu";
import { MoreLinksNames, defaultToolLinks } from "./MoreLinks";
import { MoreRoutesNames } from "./MoreRoutes";
import { defaultStorageToolLinks } from "./storage/StoragePrintPalletLabels/StorageToolLinks";
import { useMoreStorageDashboards } from "./storage/useMoreStorageDashboards";
import { useStorageExpectedVsReceivedDasboardTool } from "./storage/useStorageExpectedVsReceivedDasboardTool";
import { useMoreDashboards } from "./useMoreDashboards";

const StyledCard = styled(FacilityCard)<CardProps, DefaultTheme>(
  ({ theme }) => `
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  margin-bottom: ${theme.spacing.S6};

  & > div:last-child {
    background-color: white;
    border-radius: ${theme.border.radius.R5};
  }
`
);

// Had to specify a min-height to prevent the squishing seen on https://app.asana.com/0/1199675883471197/1202052719508674/f
// For some reason, the overflow:auto on the parent container prevents this button from taking up the entire space it should
const StyledButton = styled(Button)<ThemeProps>`
  width: 100%;
  max-width: ${MAX_CARD_WIDTH};
  min-height: ${({ theme }) => theme.input.height};
`;

const compareText = (textA: MenuLinkConfig, textB: MenuLinkConfig) => {
  if ((textA.text as JSX.Element).props.defaultMessage === (textB.text as JSX.Element).props.defaultMessage) {
    return 0;
  }

  return (textA.text as JSX.Element).props.defaultMessage > (textB.text as JSX.Element).props.defaultMessage ? 1 : -1;
};

export const More: React.FC = () => {
  const { logout } = useAuth0();
  const { warehouseId, userName, isAdmin, isManager, isStorage } = useRecoilValue(userState);
  const isHazmat = !!useRecoilValue(warehouseDataState)?.hazmatIds?.length;
  const { dhlManifestAccountNumber } = useDhlManifestAccount();
  const { dashboardLinks } = useMoreDashboards(warehouseId);
  const { storageDashboardLinks } = useMoreStorageDashboards(warehouseId);
  const { storageToolDashboardLinks } = useStorageExpectedVsReceivedDasboardTool(warehouseId);

  const toolLinks = useMemo(() => {
    const links = [...defaultToolLinks];
    if (isHazmat) {
      links.push({
        text: <FormattedMessage id="warehouse.moreMenu.hazmat" defaultMessage="HAZMAT Lookup" />,
        route: MoreRoutesNames.HAZMAT,
        id: MoreLinksNames.HAZMAT,
      });
    }

    if (dhlManifestAccountNumber) {
      links.push({
        text: <FormattedMessage id="warehouse.moreMenu.dhlManifest" defaultMessage="DHL Manifest" />,
        route: MoreRoutesNames.DOWNLOAD_DHL_MANIFEST,
        id: MoreLinksNames.DHL,
      });
    }

    if (isManager) {
      links.push({
        text: <FormattedMessage id="warehouse.moreMenu.userManagement" defaultMessage="User Management" />,
        url: "https://warehouseportal.us.webtask.run/auth0-delegated-admin/en/",
        id: MoreLinksNames.USER_MANAGEMENT,
      });
    }

    return links.sort(compareText);
  }, [dhlManifestAccountNumber, isHazmat, isManager]);

  return (
    <>
      <StyledCard
        header={
          <GroupHeader>
            <FormattedMessage defaultMessage="Account" id="warehouse.morePage.accountHeader" />
          </GroupHeader>
        }
      >
        <AccountMenu appName={AppName.WP} userName={userName} warehouseId={warehouseId} isAdmin={isAdmin} />
      </StyledCard>
      <StyledCard
        header={
          <GroupHeader>
            <FormattedMessage defaultMessage="Tools" id="warehouse.morePage.toolHeader" />
          </GroupHeader>
        }
      >
        <LinksMenu<MoreLinksNames>
          links={isStorage ? defaultStorageToolLinks.concat(storageToolDashboardLinks).sort(compareText) : toolLinks}
        />
      </StyledCard>
      <StyledCard
        header={
          <GroupHeader>
            <FormattedMessage defaultMessage="Operations dashboards" id="warehouse.morePage.dashboardsHeader" />
          </GroupHeader>
        }
      >
        <LinksMenu<MoreLinksNames> links={isStorage ? storageDashboardLinks : dashboardLinks} />
      </StyledCard>
      {!isStorage && (
        <>
          <StyledCard
            header={
              <GroupHeader>
                <FormattedMessage defaultMessage="Information" id="warehouse.morePage.informationHeader" />
              </GroupHeader>
            }
          >
            <LinksMenu<MoreLinksNames> links={defaultInformationLinks} />
          </StyledCard>
        </>
      )}
      <StyledButton secondary onClick={() => logout({ returnTo: window.location.origin })}>
        <FormattedMessage id="warehouse.morePage.logoutButton" defaultMessage="Logout" />
      </StyledButton>
    </>
  );
};
