import React from "react";
import { ResponseCard } from "facility-commons/flow/cards/ResponseCard";
import { FormattedMessage } from "react-intl";

export interface ArrivalScannerSuccessCardProps {
  scanInput: string;
}

export const ArrivalScannerSuccessCard: React.FC<ArrivalScannerSuccessCardProps> = ({ scanInput }) => (
  <ResponseCard
    message={<FormattedMessage id="crossdock.arrivalScan.scannerSuccess" defaultMessage={`Scanned ${scanInput}`} />}
    accentColor="GREEN"
    icon="check-circle"
  />
);
