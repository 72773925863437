import React from "react";

import { NcCardStack, SkipStep } from "../../components";
import { useBclUpload } from "./useBclUpload";
import { NonComplianceUploadItem } from "warehouse/ticket-center/common/components/NonComplianceUploadItem";
import { commonUploadMessages } from "facility-commons/labels";

export const BclUpload: React.FC = () => {
  const { formatMessage, imageUploaded, onFileChange, proceed, register } = useBclUpload();
  return (
    <NcCardStack
      continueDisabled={!imageUploaded}
      continueOnClick={proceed}
      title={formatMessage(commonUploadMessages.takeAPhotoBclTitle)}
    >
      <NonComplianceUploadItem
        iconText={formatMessage(commonUploadMessages.takePhoto)}
        id="bcl-photo"
        {...register}
        onSuccessfulUpload={onFileChange}
      />
      <SkipStep message={formatMessage(commonUploadMessages.bclMissing)} onSkip={proceed} />
    </NcCardStack>
  );
};
