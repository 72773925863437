import { Button, ThemeProps, LocalizedMessage, Stack } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FACILITY_COMMONS_BUTTON_LABELS } from "facility-commons/components/buttons/common/FacilityCommonsButton.labels";
import { ModalTemplateProps, ModalTemplate } from "./ModalTemplate";

const BlockStack = styled(Stack)`
  width: 100%;
`;
const ButtonBox = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: row;
  button:not(:first-of-type) {
    margin-left: ${theme.spacing.S4};
  }
`
);

interface PromptModalProps extends ModalTemplateProps {
  onConfirmClick: () => any;
  onCancelClick: () => any;
  vertical?: boolean;
  confirmButtonLabel?: LocalizedMessage;
  cancelButtonLabel?: LocalizedMessage;
}

export const PromptModal: React.FC<PromptModalProps> = (props) => {
  const { onConfirmClick, onCancelClick, confirmButtonLabel, cancelButtonLabel, vertical } = props;

  const Wrapper = vertical ? BlockStack : ButtonBox;

  return (
    <ModalTemplate {...props}>
      <Wrapper>
        <Button block secondary onClick={onCancelClick}>
          {cancelButtonLabel ?? <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.cancelButton} />}
        </Button>
        <Button block onClick={onConfirmClick}>
          {confirmButtonLabel ?? <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.confirmButton} />}
        </Button>
      </Wrapper>
    </ModalTemplate>
  );
};
