import { atom } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { ReceivingData } from "./Types";

export const INITIAL_RECEIVING_STATE: ReceivingData = {
  requestBatchId: "",
  dedupKey: "",
  expectedNumOfBoxes: 0,
  numIdenticalNonReceivedBoxes: 0,
  expectedSkus: 0,
  receivedSkus: [],
  receivingButtons: [],
  identicalBoxes: 1,
  cdskusToReceive: [],
  wasReceived: false,
  asnId: undefined,
  previousReceives: [],
  isUnexpectedSku: false,
  isSubmittingReceiveRequest: false,
  isReceiveWithoutCdsku: false,
  receiveMoreUnitsFromBox: false,
  requireLotInput: false,
  requireExpiryInput: false,
  expectedLot: null,
  expectedExpirationDate: null,
  recommendedReceivingZone: "",
  isNewReceive: false,
  isProductMissingLot: false,
  isProductMissingExpirationDate: false,
  requireElevatedRoleToUnderReceive: false,
  images: [],
  expectedFefoLotPairs: [],
};

export const receivingState = atom<ReceivingData>({
  key: WarehouseStateKey.RECEIVING_DATA,
  default: INITIAL_RECEIVING_STATE,
});
