import { ButtonProps, DefaultTheme, StyledButton } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FormattedMessage } from "react-intl";

import { MAX_CARD_WIDTH } from "facility-commons/components/components.const";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { useDhlManifestAccount } from "warehouse/common/hooks/useDhlManifestAccount";
import { LoadingSpinner } from "facility-commons/components";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { setProp } from "facility-commons/utils";

const CenteredDiv = styled.div<Record<string, unknown>, DefaultTheme>`
  text-align: center;
  white-space: pre-line;
`;

const CardWidthButton = styled(StyledButton)<ButtonProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  width: 100%;
  max-width: ${MAX_CARD_WIDTH};
  `
);

const StyledLoadingSpinner = styled(LoadingSpinner)<{}, DefaultTheme>(
  ({ theme }) => `
  height: auto;
  margin-bottom: ${theme.spacing.S4};
`
);

export const DownloadDhlManifest: React.FC = () => {
  const { downloadDhlManifestPdf, dhlManifestAccountNumber, isLoading } = useDhlManifestAccount();
  const { warehouseId } = useRecoilValue(userState);

  const setWarehouseAppState = useSetRecoilState(warehouseAppState);

  useEffect(() => {
    setWarehouseAppState(setProp("pageSubtitle", `Account #${dhlManifestAccountNumber!}`));
    return () => setWarehouseAppState(setProp("pageSubtitle", ""));
  }, [dhlManifestAccountNumber, setWarehouseAppState]);

  return (
    <>
      <FacilityCard>
        {!isLoading ? (
          <FormattedMessage
            id="warehouse.downloadManifest.downloadInstructions"
            defaultMessage="Create and download a DHL manifest that contains your most updated shipment data."
          />
        ) : (
          <>
            <StyledLoadingSpinner loading />
            <CenteredDiv>
              <FormattedMessage
                id="warehouse.downloadManifest.waitInstructions"
                defaultMessage={"The manifest is downloading."}
              />
            </CenteredDiv>
            <CenteredDiv>
              <FormattedMessage
                id="warehouse.downloadManifest.waitInstructions2"
                defaultMessage={"Do not navigate away from this page."}
              />
            </CenteredDiv>
          </>
        )}
      </FacilityCard>
      {!isLoading && (
        <CardWidthButton onClick={() => downloadDhlManifestPdf(warehouseId)}>
          <FormattedMessage id="warehouse.downloadManifest.buttonText" defaultMessage="Download PDF" />
        </CardWidthButton>
      )}
    </>
  );
};
