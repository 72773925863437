import { useState } from "react";
import { IntlFormatters, useIntl } from "react-intl";
import { useRecoilValue, SetterOrUpdater, useSetRecoilState, useRecoilState } from "recoil";
import { ArrivalScanCorrection } from "@deliverr/commons-clients";
import { PalletArrivalScanError } from "@deliverr/legacy-inbound-client";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { log, logError } from "facility-commons/utils";
import { isScanInput } from "facility-commons/utils/scanUtils";
import { isValidCdsku } from "facility-commons/utils/cdskuUtils";
import { crossdockClient } from "facility-commons/base/Clients";
import {
  createArrivalScannerSuccessCard,
  createArrivalScannerCard,
  createArrivalScannerFailureCard,
  createArrivalScannerInvalidInputCard,
  createPalletArrivalScannerErrorCard,
} from "warehouse/common/flow/warehouseFlowCardCreators";
import { FlowCardData } from "facility-commons/flow";
import { useWarehouseModal } from "warehouse/modal/useWarehouseModal";
import { WarehouseModal } from "warehouse/modal";
import { reroutedAsnIdState, arrivalInputState } from "./ScanArrivalState";
import { ModalGenericProps } from "facility-commons";
import { userState } from "facility-commons/base/Auth/userState";
import { MAX_REASONS_TO_SHOW } from "warehouse/common/utils";
import { errorMessages } from "warehouse/common/errorMessages";

export function composeSubmitArrivalScan(
  warehouseId: string,
  setScanInput: SetterOrUpdater<string>,
  addFlowCard: (card: FlowCardData<any>) => void,
  hideAllFlowButtons: () => void,
  setReroutedAsnId: SetterOrUpdater<string>,
  showModal: (modalType: string, props: ModalGenericProps) => void,
  setLoading: (loading: boolean) => void,
  formatMessage: IntlFormatters["formatMessage"]
) {
  const getDisplayMessageForError = (errorCode: PalletArrivalScanError, palletLabel: string, payload: any): string => {
    let message: string;
    switch (errorCode) {
      case PalletArrivalScanError.LABEL_ID_NOT_FOUND:
        message = formatMessage(errorMessages.palletLabelNotFoundMessage, { palletLabel });
        break;

      case PalletArrivalScanError.ARRIVED_AT_INCAPABLE_FC:
        const reasons = payload.reasons.slice(0, MAX_REASONS_TO_SHOW);
        const reasonsString = reasons.map((reason) => reason.dsku + ": " + reason.type).join("\n");
        message = formatMessage(errorMessages.incapableUnitsOnPalletMessage, { palletLabel, reasonsString });
        break;

      case PalletArrivalScanError.PALLET_NO_DESTINATION:
        message = formatMessage(errorMessages.palletNoDestinationMessage, { palletLabel });
        break;

      default: {
        message = formatMessage(errorMessages.defaultPalletArrivalScanMessage, { palletLabel });
      }
    }
    return message;
  };

  return async (formattedInput: string, errorMessage?: string) => {
    const ctx = { fn: "submitArrivalScan", formattedInput, warehouseId };

    // Prevent empty input
    if (!formattedInput) {
      return;
    }

    hideAllFlowButtons();

    if (errorMessage) {
      setScanInput("");

      addFlowCard(
        createArrivalScannerInvalidInputCard({
          message: errorMessage,
        })
      );
      addFlowCard(createArrivalScannerCard({}));
      return;
    }

    try {
      setLoading(true);
      // Casting to ArrivalScanCorrection so the following code can check if the correction field is present.
      const scanResponse = (await crossdockClient.scanArrival(warehouseId!, formattedInput)) as ArrivalScanCorrection;

      if (scanResponse?.correction?.asnId) {
        log(ctx, "encountered misrouted pallet", { scanResponse });
        setReroutedAsnId(String(scanResponse?.correction?.asnId));
        showModal(WarehouseModal.UNEXPECTED_PALLET_WARNING, { rerouteReason: scanResponse?.correction?.rerouteReason });
        return; // escape early to avoid card issues
      } else if (scanResponse) {
        log(ctx, "successful scan", { scanResponse });
        addFlowCard(createArrivalScannerSuccessCard({ scanInput: formattedInput }));
      } else {
        addFlowCard(createArrivalScannerFailureCard({ scanInput: formattedInput }));
        logError(ctx, new Error("Arrival Scan failed."));
      }
    } catch (err: any) {
      // pallet arrival scan for receiving FCs and storage sites
      const errorCode = err.code;
      const payload = err.payload;
      const palletLabel = formattedInput;
      const message = getDisplayMessageForError(errorCode, palletLabel, payload);
      addFlowCard(createPalletArrivalScannerErrorCard({ message }));
      logError(ctx, err);
    } finally {
      setLoading(false);
    }

    setScanInput("");
    addFlowCard(createArrivalScannerCard({}));
  };
}

export function useArrivalScanner() {
  const { showModal } = useWarehouseModal();
  const { addFlowCard, hideAllFlowButtons } = useWarehouseFlow();
  const { warehouseId } = useRecoilValue(userState);
  const setReroutedAsnId = useSetRecoilState(reroutedAsnIdState);
  const [loading, setLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [scanInput, setScanInput] = useRecoilState(arrivalInputState);

  const validateInput = (formattedInput: string): string | undefined => {
    const inputIsCdskuMessage = {
      id: "crossdock.arrivalScan.inputIsCdsku",
      defaultMessage:
        "{scannedValue} is a CDSKU. Scan a pallet barcode, or click Receiving if you are trying to receive inventory.",
    };
    // Removing palletLabel input validation for now because we expect some pallet labels to not start with PID
    const isCdsku = isValidCdsku(formattedInput);

    return !isCdsku
      ? undefined
      : formatMessage(inputIsCdskuMessage, {
          scannedValue: formattedInput,
        });
  };

  const submitArrivalScan = composeSubmitArrivalScan(
    warehouseId!,
    setScanInput,
    addFlowCard,
    hideAllFlowButtons,
    setReroutedAsnId,
    showModal,
    setLoading,
    formatMessage
  );

  const handleInputChange = async (newInput: string): Promise<void> => {
    setScanInput(newInput.toUpperCase()); // Uppercase PID as user types

    if (isScanInput(newInput, scanInput)) {
      const formattedInput = newInput.toUpperCase();
      const errorMessage = validateInput(formattedInput);
      await submitArrivalScan(formattedInput, errorMessage);
    }
  };

  return {
    scanInput,
    errorMessage: validateInput(scanInput),
    handleInputChange,
    submitArrivalScan,
    loading,
  };
}
