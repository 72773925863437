export enum ReceivingPath {
  // entry point to app - App Bar uses route parser to navigate, which requires "/"" prefix
  ENTRY_CDSKU = "/cdsku",
  SELECT = "select",
  PALLET = "pallet", // used to navigate within receiving - no parser used
  CONSOLIDATION = "consolidation", // used to navigate within receiving - no parser used
  CDSKU = "cdsku", // used to navigate within receiving - no parser used
  PO = "PO", // used for missing cdsku receives
  RETURN_RECEIVE = "return-receive",
  LOT = "lot",
  BULK = "bulk",
  SKU = "sku",
  QUANTITY = "quantity",
  IMAGES = "images",
  REVIEW = "review",
  REVIEW_PRODUCTS = "review-products",
  LOCATION = "location",
  RECEIVING_DASHBOARD = "dashboard",
  PALLETS_DASHBOARD = "pallets-dashboard",
  PALLET_ANALYTICS = "pallet-analytics",
  PALLET_ANALYTICS_SELECT_BOX_CONFIGURATION = "pallet-analytics-select-box-configuration",
  PALLET_ANALYTICS_BULK_BOX_SUMMARY = "pallet-analytics-bulk-box-summary",
  PALLET_ANALYTICS_SINGLE_SKU_BOX_SUMMARY = "pallet-analytics-single-sku-box-summary",
  PALLET_ANALYTICS_MULTI_SKU_BOX_SUMMARY = "pallet-analytics-multi-sku-box-summary",
  EXPIRATION_LOT_CONFIRMATION = "confirmation",
  MULTI_EXPIRATION_LOT_CONFIRMATION = "multi-fefo-lot-confirmation", // used when a dsku in a box has multiple expiration/lot dates
  RECEIVE_MORE_UNITS_CONFIRMATION = "receive-more-units-confirmation", // receive additional units from box
  PRODUCT_DETAILS = "details",
  REPORT_ISSUE = "report-issue",
}
