import React from "react";
import { ErrorTextCard } from "./ErrorTextCard";

export interface UnknownInputCardProps {
  value: string;
  message?: string;
  newest?: boolean;
}

export const UnknownInputCard: React.FC<UnknownInputCardProps> = ({ value, message, newest }) => (
  <ErrorTextCard title="Unknown input" message={message} value={value} newest={newest} />
);
