import React from "react";
import { useLocation } from "react-router";

import { WarehousePortalRoutesNames } from "warehouse/routes";
import { PaddedLinkButton, StyledButtonContainer, StyledTitle } from "facility-commons/components/emotion";
import { CenteredWarehouseCard } from "warehouse/common/components";

export const DamagedTypeSelector: React.FC = () => {
  const location = useLocation();

  return (
    <CenteredWarehouseCard>
      <StyledTitle displayAs="h3">When was this damaged item identified?</StyledTitle>
      <StyledButtonContainer>
        <PaddedLinkButton to={`${location.pathname}/${WarehousePortalRoutesNames.DAMAGE_RECEIVING}`}>
          Receiving
        </PaddedLinkButton>
        <PaddedLinkButton to={`${location.pathname}/${WarehousePortalRoutesNames.DAMAGE_PICKING}`}>
          Order Picking
        </PaddedLinkButton>
      </StyledButtonContainer>
    </CenteredWarehouseCard>
  );
};
