import { useRecoilValue } from "recoil";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { composeUseDamagedProductForm } from "facility-commons/flow/forms/DamagedProductForm";
import { userState } from "facility-commons/base/Auth/userState";
import { SoundFx } from "facility-commons/common/sfx";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { useMount } from "react-use";
import { storageInboundReceivingStateAtom } from "warehouse/receiving/storage/StorageReceivingState";
import { DamagedProductField } from "facility-commons/flow/forms/DamagedProductForm/DamagedProductForm.types";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useRouter } from "facility-commons/hooks";
import { BoxType } from "facility-commons/flow/types/BoxType";
import { useIntl } from "react-intl";
import { ticketFormContent } from "facility-commons/flow/forms/ticketFormContent";
import { formErrorToInputProps } from "facility-commons/flow/forms/DamagedProductForm/formErrorToInputProps";
import { maxBarcodeLength } from "facility-commons/utils/config";
import { Appearance } from "@deliverr/ui";
import { DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { Direction } from "@deliverr/ui/lib-esm/shared";
import { FormGroupMapConfig, FormInputTypes, ImageGridOptionsMapConfig } from "./DamageProductForm";

export const useStorageDamagedProductForm = () => {
  const { warehouseId } = useRecoilValue(userState);
  const { currentSKU, poNum } = useRecoilValue(storageInboundReceivingStateAtom);
  const { playSfx, emitFlash, handleUnknownError } = useWarehouseFlow();
  const { nonComplianceClient, warehousePublicClient } = useClientsWithAuth();
  const { hideModal, showModal } = useWarehouseModal();
  const { goBack } = useRouter();
  const { formatMessage } = useIntl();

  const onSubmitSuccess = (caseNumber: number) => {
    const onDoneClick = () => {
      hideModal(WarehouseModal.DAMAGED_TICKET_CREATION_CONFIRMATION);
      goBack();
    };

    showModal(WarehouseModal.DAMAGED_TICKET_CREATION_CONFIRMATION, {
      onDoneClick,
      caseNumber,
      shouldShowSaveCaseNumberMessage: false,
      boxType: BoxType.CASES,
    });
  };

  const onFailure = (ctx, err) => {
    playSfx(SoundFx.ERROR);
    emitFlash("DANGER");
    handleUnknownError(ctx, err);
  };

  const useDamagedProductForm = composeUseDamagedProductForm({
    warehouseId,
    warehousePublicClient,
    nonComplianceClient,
    defaultShouldShowPOField: true,
    onSubmitSuccess,
    isCaseEnabled: true,
  });

  const {
    errors,
    formHandler,
    onFormSubmit,
    register,
    preventSubmit,
    warehouseEmailAsyncState,
  } = useDamagedProductForm({ onFailure });

  const { damageType } = formHandler.getValues();

  const formGroupMap: FormGroupMapConfig = [
    {
      type: FormInputTypes.INPUT,
      labelProps: { appearance: Appearance.INFO, htmlFor: DamagedProductField.BARCODE },
      labelContent: formatMessage(ticketFormContent.barcodeRequired),
      textInputProps: {
        ...register[DamagedProductField.BARCODE],
        id: DamagedProductField.BARCODE,
        maxLength: maxBarcodeLength,
        placeholder: formatMessage(ticketFormContent.barcodePlaceholder),
        ...formErrorToInputProps(errors, DamagedProductField.BARCODE),
      },
      isMandatory: true,
    },
    {
      type: FormInputTypes.INPUT,
      labelProps: { appearance: Appearance.INFO, htmlFor: DamagedProductField.ASN },
      labelContent: formatMessage(ticketFormContent.poRequired),
      textInputProps: {
        ...register[DamagedProductField.ASN],
        id: DamagedProductField.ASN,
        placeholder: formatMessage(ticketFormContent.poPlaceholder),
        ...formErrorToInputProps(errors, DamagedProductField.ASN),
      },
      isMandatory: true,
    },
    {
      type: FormInputTypes.RADIO,
      direction: Direction.VERTICAL,
      title: formatMessage(ticketFormContent.damageType),
      options: [
        {
          props: {
            ...register[DamagedProductField.DAMAGE_TYPE],
            id: `${DamagedProductField.DAMAGE_TYPE}-${DamageType.DAMAGED}`,
            "data-testid": "damaged-radio",
            type: "radio",
            value: DamageType.DAMAGED,
          },
          labelProps: {
            appearance: Appearance.INFO,
            htmlFor: `${DamagedProductField.DAMAGE_TYPE}-${DamageType.DAMAGED}`,
          },
          labelText: formatMessage(ticketFormContent.productDamaged),
        },
        {
          props: {
            ...register[DamagedProductField.DAMAGE_TYPE],
            id: `${DamagedProductField.DAMAGE_TYPE}-${DamageType.EXPIRED}`,
            "data-testid": "expired-radio",
            type: "radio",
            value: DamageType.EXPIRED,
          },
          labelProps: {
            appearance: Appearance.INFO,
            htmlFor: `${DamagedProductField.DAMAGE_TYPE}-${DamageType.EXPIRED}`,
          },
          labelText: formatMessage(ticketFormContent.productExpired),
        },
      ],
      isMandatory: true,
    },
    {
      type: FormInputTypes.INPUT,
      labelProps: { appearance: Appearance.INFO, htmlFor: DamagedProductField.CASE_QUANTITY },
      labelContent: formatMessage(
        damageType === DamageType.EXPIRED
          ? ticketFormContent.caseExpirededQuantityRequired
          : ticketFormContent.caseDamagedQuantityRequired
      ),
      textInputProps: {
        ...register[DamagedProductField.CASE_QUANTITY],
        id: DamagedProductField.CASE_QUANTITY,
        placeholder: formatMessage(ticketFormContent.quantityPlaceholder),
        ...formErrorToInputProps(errors, DamagedProductField.CASE_QUANTITY),
      },
      isMandatory: true,
    },
    {
      type: FormInputTypes.INPUT,
      labelProps: { appearance: Appearance.INFO, htmlFor: DamagedProductField.UNITS_PER_CASE },
      labelContent: formatMessage(ticketFormContent.unitsPerCaseDamagedQuantityRequired),
      textInputProps: {
        ...register[DamagedProductField.UNITS_PER_CASE],
        id: DamagedProductField.UNITS_PER_CASE,
        placeholder: formatMessage(ticketFormContent.unitsPerCasePlaceholder),
        ...formErrorToInputProps(errors, DamagedProductField.UNITS_PER_CASE),
      },
      isMandatory: true,
    },
    {
      type: FormInputTypes.INPUT,
      labelProps: { appearance: Appearance.INFO, htmlFor: DamagedProductField.STORAGE_LOCATION },
      labelContent: formatMessage(ticketFormContent.locationRequired),
      textInputProps: {
        ...register[DamagedProductField.STORAGE_LOCATION],
        id: DamagedProductField.STORAGE_LOCATION,
        placeholder: formatMessage(ticketFormContent.locationPlaceholder),
        ...formErrorToInputProps(errors, DamagedProductField.STORAGE_LOCATION),
      },
      isMandatory: false,
    },
  ];

  const imageGridOptionsMap: ImageGridOptionsMapConfig = [
    {
      uploadProps: {
        ...register[DamagedProductField.DAMAGED_PRODUCT_PHOTO],
        id: DamagedProductField.DAMAGED_PRODUCT_PHOTO,
        labelText: formatMessage(ticketFormContent.damagedCasePhotoRequired),
        isMandatory: true,
      },
      tooltipProps: { active: !!errors[DamagedProductField.DAMAGED_PRODUCT_PHOTO] },
      errorProps: { errors, name: DamagedProductField.DAMAGED_PRODUCT_PHOTO },
    },
    {
      uploadProps: {
        ...register[DamagedProductField.DAMAGED_EXTERIOR_PHOTO],
        id: DamagedProductField.DAMAGED_EXTERIOR_PHOTO,
        labelText: formatMessage(ticketFormContent.caseBoxLabelPhoto),
        isMandatory: true,
      },
      tooltipProps: { active: !!errors[DamagedProductField.DAMAGED_EXTERIOR_PHOTO] },
      errorProps: { errors, name: DamagedProductField.DAMAGED_EXTERIOR_PHOTO },
    },
    {
      uploadProps: {
        ...register[DamagedProductField.ADDITIONAL_PHOTO1],
        id: DamagedProductField.ADDITIONAL_PHOTO1,
        labelText: formatMessage(ticketFormContent.additionalPhoto),
      },
      tooltipProps: { active: Boolean(errors[DamagedProductField.ADDITIONAL_PHOTO1]) },
      errorProps: { errors, name: DamagedProductField.ADDITIONAL_PHOTO1 },
    },
    {
      uploadProps: {
        ...register[DamagedProductField.ADDITIONAL_PHOTO2],
        id: DamagedProductField.ADDITIONAL_PHOTO2,
        labelText: formatMessage(ticketFormContent.additionalPhoto),
      },
      tooltipProps: { active: Boolean(errors[DamagedProductField.ADDITIONAL_PHOTO2]) },
      errorProps: { errors, name: DamagedProductField.ADDITIONAL_PHOTO2 },
    },
  ];

  useMount(() => {
    formHandler.setValue(DamagedProductField.BARCODE, currentSKU);
    formHandler.setValue(DamagedProductField.ASN, poNum);
  });

  return {
    errors,
    formHandler,
    onFormSubmit,
    formatMessage,
    formGroupMap,
    imageGridOptionsMap,
    register,
    preventSubmit,
    warehouseEmailAsyncState,
    onFailure,
    useDamagedProductForm,
  };
};
