import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { modalsText } from "../modalsText";
import { Button } from "@deliverr/ui";

export interface ExitReceivingModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ExitReceivingModal: React.FC<ExitReceivingModalProps> = ({ onCancel, onConfirm }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalTemplate
      title={formatMessage(modalsText.exitReceivingTitle)}
      text={formatMessage(modalsText.exitReceivingText)}
      iconProps={warningModalIcon}
    >
      <WarehouseModalRightMarginButton block onClick={onCancel} secondary>
        {formatMessage(modalsText.back)}
      </WarehouseModalRightMarginButton>
      <Button block onClick={onConfirm}>
        {formatMessage(modalsText.exit)}
      </Button>
    </ModalTemplate>
  );
};
