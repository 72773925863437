import { defineMessages, MessageDescriptor } from "react-intl";

export enum WarehouseNotFoundErrorMessageKey {
  WAREHOUSE_NOT_FOUND = "WAREHOUSE_NOT_FOUND",
}

export const WAREHOUSE_NOT_FOUND_ERROR_BY_ERROR_CODE: Record<
  WarehouseNotFoundErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [WarehouseNotFoundErrorMessageKey.WAREHOUSE_NOT_FOUND]: {
    id: "warehouse.receiving.warehouseNotFoundError",
    defaultMessage: "Warehouse {warehouseId} not found.",
  },
});
