import { TicketCenterInvalidLotExpiryCaseResponse } from "@deliverr/commons-clients";
import { DashboardDetailInfoListItem, WarehouseMessages } from "facility-commons";
import { OVERVIEW_CARD_LABELS } from "../OverviewCard.labels";
import { useIntl } from "react-intl";

export interface useLotFefoOverViewCardCardProps {
  ticketDetails?: TicketCenterInvalidLotExpiryCaseResponse;
}

export const useLotFefoOverViewCard = ({ ticketDetails }: useLotFefoOverViewCardCardProps) => {
  const { formatMessage } = useIntl();
  const quantityTitle = formatMessage(OVERVIEW_CARD_LABELS.sectionHeaders.quantities);

  if (!ticketDetails) {
    return {
      quantityTitle,
      initialInfoList: [],
      quantityList: [],
      otherDetailsList: [],
    };
  }
  const unitsQty = ticketDetails.quantity ?? 0;
  const boxesQty = ticketDetails.numberOfBoxes ?? 1;

  const quantityList: DashboardDetailInfoListItem[] = [
    {
      id: "Number of units per box",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.unitsPerBox),
      value: unitsQty / boxesQty,
    },
    {
      id: "Number of non-compliant boxes",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.nonCompliantBoxes),
      value: boxesQty,
    },
    {
      id: "Total units",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.quantityList.totalUnits),
      value: unitsQty,
    },
  ];

  const initialInfoList: DashboardDetailInfoListItem[] = [
    {
      id: "Receiving PO #",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.po),
      value: ticketDetails.asnId ?? formatMessage(WarehouseMessages.unknown),
      isEnabled: true,
    },
    {
      id: "CDSKU",
      displayValue: formatMessage(WarehouseMessages.cdsku),
      value: ticketDetails.cdsku ?? formatMessage(WarehouseMessages.unknown),
      isEnabled: true,
    },
    {
      id: "Barcode",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.barcode),
      value: ticketDetails.barcode ?? formatMessage(WarehouseMessages.unknown),
      isEnabled: true,
    },
    {
      id: "LotNumber",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.lotNumber),
      value: ticketDetails.reportedLot ?? formatMessage(WarehouseMessages.unknown),
      isEnabled: ticketDetails.metadata?.productLotEnabled,
    },
    {
      id: "ExpirationDate",
      displayValue: formatMessage(OVERVIEW_CARD_LABELS.listItems.expirationDate),
      value: ticketDetails.reportedExpiry?.toString() ?? formatMessage(WarehouseMessages.unknown),
      isEnabled: ticketDetails.metadata?.productExpiryEnabled,
    },
  ].filter((item) => item.isEnabled);

  return {
    ticketDetails,
    initialInfoList,
    quantityList,
    quantityTitle,
  };
};
