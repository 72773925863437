import { defineMessages, MessageDescriptor } from "react-intl";

export enum BulkReceiveIneligibleErrorMessageKey {
  INELIGIBLE = "INELIGIBLE",
}

export const BULK_RECEIVE_INELIGIBLE_ERROR_BY_ERROR_CODE: Record<
  BulkReceiveIneligibleErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [BulkReceiveIneligibleErrorMessageKey.INELIGIBLE]: {
    id: "warehouse.receiving.ineligibleBulk",
    defaultMessage:
      "This receive is not eligible to be received in bulk. Please contact Deliverr support with identifier: {requestBatchId}.",
  },
});
