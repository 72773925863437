import React from "react";
import { Link } from "react-router-dom";
import { Text, IconV2, defaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { ReceivingPath } from "warehouse/receiving/routes";
import { richText } from "facility-commons/intl/richText";

interface ProductSummaryCardProps {
  barcode: string;
  description: string;
}
const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  width: calc(100% - ${theme.spacing.S5});
`
);
const TruncatedText = styled(Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = Text.withComponent(Link);

export const ProductSummaryCard: React.FC<ProductSummaryCardProps> = ({ barcode, description }) => {
  return (
    <Link to={ReceivingPath.PRODUCT_DETAILS}>
      <Container>
        <StyledDiv>
          <Text data-testid="productSummaryCard.message">
            <FormattedMessage
              id="warehouse.receiving.productDetailsSummary"
              defaultMessage="<boldtext>{barcode} Scanned</boldtext>"
              values={richText({ barcode })}
            />
          </Text>
          <TruncatedText>{description}</TruncatedText>
        </StyledDiv>
        <StyledLink
          appearance="DEFAULT"
          bold
          to={ReceivingPath.PRODUCT_DETAILS}
          data-testid="productSummaryCard.details"
        >
          <IconV2 type="angle-right" size={"2x"} color={defaultTheme.colors.NEUTRAL["100"]} />
        </StyledLink>
      </Container>
    </Link>
  );
};
