import { sortBy } from "lodash/fp";
import { DimensionsAndWeight } from "./DimensionsAndWeightType";

interface MaxDimensions {
  maxShortestSideInches: number;
  maxMiddleSideInches: number;
  maxLongestSideInches: number;
}

export const composeGetIsOverDimensions = ({
  maxShortestSideInches,
  maxMiddleSideInches,
  maxLongestSideInches,
}: MaxDimensions) => ({ length, width, height }: Exclude<Partial<DimensionsAndWeight>, "weight">) => {
  const sortedDims = sortBy<number>((value) => value, [Number(width ?? 0), Number(height ?? 0), Number(length ?? 0)]);
  return (
    sortedDims[0] > maxShortestSideInches || sortedDims[1] > maxMiddleSideInches || sortedDims[2] > maxLongestSideInches
  );
};
