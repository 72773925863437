import { defineMessages } from "react-intl";

export const trackingMessages = defineMessages({
  boxInfoTitle: {
    id: "warehouse.nonCompliance.inputBoxInfo",
    defaultMessage: "Input box information",
  },
  boxInfoInstructions: {
    id: "warehouse.nonCompliance.scanOrEnterTracking",
    defaultMessage: "Scan or enter ASN ID, tracking codes or PO #.",
  },
  boxInfoUnknown: {
    id: "warehouse.nonCompliance.boxInfoUnknown",
    defaultMessage: "Can't find the box information?",
  },
  noCdskuSubtitle: {
    id: "warehouse.nonCompliance.noCdsku",
    defaultMessage: "No CDSKU",
  },
  required: {
    id: "warehouse.nonCompliance.trackingRequired",
    defaultMessage: "PO # or Tracking # invalid",
  },
  scanShippingLabel: {
    id: "warehouse.nonCompliance.scanShippingLabel",
    defaultMessage: "Scan shipping label",
  },
});
