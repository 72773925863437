import { useRouter } from "facility-commons/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useClearSkuData, useInitializeReceivingData } from "warehouse/receiving/hooks";
import { isNull } from "lodash";
import { SoundFx } from "facility-commons/common/sfx";
import { v4 as uuid } from "uuid";
import { setProp } from "facility-commons/utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { receivingState, cdskuState } from "warehouse/receiving/ReceivingState";
import { useMount } from "react-use";

enum ReceiveMoreUnits {
  NO = "NO",
  YES = "YES",
}

export const useAdditionalUnitsConfirmationCard = () => {
  const [receiveMoreUnits, setReceiveMoreUnits] = useState<Boolean | null>(null);
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const initializeReceivingData = useInitializeReceivingData();
  const clearSkuData = useClearSkuData(true);
  const { resetNotifications, playSfx } = useCommonFlow();
  const setReceivingData = useSetRecoilState(receivingState);
  const { isHighRisk: isHighRiskBox } = useRecoilValue(cdskuState);

  useMount(() => {
    setReceivingData(setProp("isNewReceive", false));
  });

  const handleOptionChange = (e) => {
    const value = e.target.value;
    if (value === ReceiveMoreUnits.YES) {
      setReceiveMoreUnits(true);
      // isNewReceive is used to prevent users from re-submitting receives by navigating back to the
      // location page and pressing submit again without actually having scanned anything.
      // Typically isNewReceive is set to true on a new barcode scan.
      // For multi-lot/fefo boxes, we are receiving the same sku but of different lot/expiry without a new barcode scan.
      // For this case we will set isNewReceive to true when user confirms they want to receive more units.
      setReceivingData(setProp("isNewReceive", true));
    } else if (value === ReceiveMoreUnits.NO) {
      setReceiveMoreUnits(false);
    }
  };

  // this page is accessed in two flows:
  // 1. receiving a multi - fefo / lot configuration box
  // 2. receiving a high risk box one unit at a time
  const onConfirm = () => {
    if (receiveMoreUnits) {
      resetNotifications();
      setReceivingData(setProp("dedupKey", uuid()));
      playSfx(SoundFx.SUCCESS);
      // flow 2, we go back to sku page to receive the next unit
      if (isHighRiskBox) {
        return push(ReceivingPath.SKU);
      }
      // flow 1, we go back to multi expiration lot confirmation page to receive the next batch of expiration/lot of the same sku
      return push(ReceivingPath.MULTI_EXPIRATION_LOT_CONFIRMATION);
    } else {
      initializeReceivingData();
      clearSkuData();
      playSfx(SoundFx.SUCCESS);
      // we are done receiving the box, go back to the cdsku page to receive the next box
      return push(ReceivingPath.CDSKU);
    }
  };
  const disableButton = isNull(receiveMoreUnits);

  return {
    ReceiveMoreUnits,
    disableButton,
    onConfirm,
    formatMessage,
    handleOptionChange,
  };
};
