import React from "react";
import { Stack, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";

import { CardRow } from "facility-commons/components/CardRow";
import { MarginedWarehouseCard, MarginedWarehouseCardProps } from "warehouse/common/components";
import {
  CardColumn,
  CardLabelText,
  LeftAlignedContainer,
  PaddedDivider,
  SectionContainer,
} from "facility-commons/components/emotion";
import { WarehouseMessages } from "facility-commons/utils";
import { SkuReceivingDetails } from "warehouse/receiving/ReceivingState/Types";
import { commonLotMessages } from "warehouse/receiving/content";
import { getExpirationDateFormattedString } from "warehouse/receiving/utils/getExpirationDateFormattedString";

export interface ProductCardProps
  extends Omit<
    SkuReceivingDetails,
    "cdsku" | "expectedQuantity" | "previouslyReceivedQuantity" | "quantity" | "location" | "isSkuOnBox"
  > {
  embedded?: boolean;
  rightAction?: JSX.Element;
  title?: string;
  quantity?: number;
  cdsku?: string;
  lotNumber?: string;
  expirationDate?: string;
  asnId?: string;
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RelativeStack = styled(Stack)`
  position: relative;
`;

export const ProductCard: React.FC<ProductCardProps & MarginedWarehouseCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const {
    barcode,
    dsku,
    embedded,
    quantity,
    rightAction,
    description,
    expirationDate,
    title,
    cdsku,
    lotNumber,
    children,
    asnId,
    ...cardProps
  } = props;

  const cardContent = (
    <RelativeStack gap="S3">
      {title && (
        <TitleContainer>
          <Title as="h2" displayAs="h4" data-testid="product-card-title">
            {title}
          </Title>
          {rightAction && rightAction}
        </TitleContainer>
      )}
      {asnId && (
        <>
          <SectionContainer>
            <CardColumn>
              <CardLabelText>{formatMessage(WarehouseMessages.po)}</CardLabelText>
              {asnId}
            </CardColumn>
          </SectionContainer>
          <PaddedDivider />
        </>
      )}
      {cdsku && (
        <>
          <SectionContainer>
            <CardColumn data-testid="product-card-cdsku">
              <CardLabelText>{formatMessage(WarehouseMessages.cdsku)}</CardLabelText>
              {cdsku}
            </CardColumn>
          </SectionContainer>
          <PaddedDivider />
        </>
      )}

      <SectionContainer>
        <CardColumn data-testid="product-card-description">
          <CardLabelText>
            <FormattedMessage id="description" defaultMessage="Description" />
          </CardLabelText>
          {description}
        </CardColumn>
      </SectionContainer>

      <LeftAlignedContainer>
        <CardColumn data-testid="product-card-barcode">
          <CardLabelText>
            <FormattedMessage id="barcode" defaultMessage="Barcode" />
          </CardLabelText>
          {barcode}
        </CardColumn>
        <CardColumn data-testid="product-card-dsku">
          <CardLabelText>
            <FormattedMessage id="dsku" defaultMessage="DSKU" />
          </CardLabelText>
          {dsku}
        </CardColumn>
      </LeftAlignedContainer>

      {expirationDate && (
        <>
          <PaddedDivider />
          <LeftAlignedContainer>
            <CardColumn>
              <CardLabelText>
                <FormattedMessage id="expDate" defaultMessage="Expiration date" />
              </CardLabelText>
              {getExpirationDateFormattedString(expirationDate)}
            </CardColumn>
            {lotNumber ? (
              <CardColumn>
                <CardLabelText>{formatMessage(commonLotMessages.lotNumber)}</CardLabelText>
                {lotNumber}
              </CardColumn>
            ) : null}
          </LeftAlignedContainer>
        </>
      )}

      {lotNumber && !expirationDate && (
        <>
          <PaddedDivider />
          <SectionContainer>
            <CardColumn>
              <CardLabelText>{formatMessage(commonLotMessages.lotNumber)}</CardLabelText>
              {lotNumber}
            </CardColumn>
          </SectionContainer>
        </>
      )}

      {quantity ? (
        <CardRow
          label={<FormattedMessage id="warehouse.receiving.totalUnits" defaultMessage="Total units" />}
          value={quantity.toString()}
        />
      ) : null}

      {children}
    </RelativeStack>
  );

  return embedded ? (
    cardContent
  ) : (
    <MarginedWarehouseCard {...cardProps} as="section">
      {cardContent}
    </MarginedWarehouseCard>
  );
};
