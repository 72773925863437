import React from "react";
import { Appearance, Button, Text, ThemeProps } from "@deliverr/ui";
import { ModalTemplate } from "facility-commons";
import styled from "@emotion/styled";
import { useReturnReceiveCaptureItemImageModal } from "./useReturnReceiveCaptureItemImageModal";
import { PHOTO_CAPTURE_HEIGHT_PX, PHOTO_CAPTURE_WIDTH_PX } from "warehouse/receiving/ReturnReceive/constants";

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S2} 0;
`
);

const StyledModalTemplate = styled(ModalTemplate)<ThemeProps>`
  & > section {
    max-width: none !important;
  }
`;

const HiddenCanvas = styled.canvas`
  display: none;
`;

export const ReturnReceiveCaptureItemImageModal: React.FC = () => {
  const {
    messages,
    videoRef,
    photoRef,
    errorMsg,
    isTakePhotoButtonDisabled,
    loading,
    closeCamera,
    takePhotoAndUpload,
  } = useReturnReceiveCaptureItemImageModal();

  return (
    <StyledModalTemplate title="" buttonLayout="column">
      <video width={PHOTO_CAPTURE_WIDTH_PX} height={PHOTO_CAPTURE_HEIGHT_PX} autoPlay ref={videoRef} />
      <HiddenCanvas ref={photoRef} width={PHOTO_CAPTURE_WIDTH_PX} height={PHOTO_CAPTURE_HEIGHT_PX} />
      {errorMsg && (
        <Text appearance={Appearance.DANGER} size={"label"}>
          {errorMsg}
        </Text>
      )}
      <StyledButton
        onClick={takePhotoAndUpload}
        disabled={isTakePhotoButtonDisabled}
        loading={loading}
        block
        data-testid="return-receive-take-photo-button"
      >
        {messages.takePhoto}
      </StyledButton>
      <StyledButton secondary data-testid="return-receive-photo-close-button" block onClick={closeCamera} type="button">
        {messages.close}
      </StyledButton>
    </StyledModalTemplate>
  );
};
