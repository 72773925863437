import { isNil } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useMount } from "react-use";
import { useRecoilValue } from "recoil";
import { DashboardProps, WarehouseEmbeddedDashboard } from "warehouse/common/components/EmbeddedDashboard";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { useReceivingDashboard } from "./useReceivingDashboard";
import { userState } from "facility-commons/base/Auth/userState";

export const ReceivingDashboard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { warehouseId } = useRecoilValue(userState);
  const history = useHistory();
  const { getEmbeddedUrl } = useReceivingDashboard(warehouseId);

  useMount(() => {
    const state = history.location.state as DashboardProps;
    if (state && isNil(state.embeddedDashboardUrl)) {
      getEmbeddedUrl();
    }
  });

  return <WarehouseEmbeddedDashboard title={formatMessage(commonReceivingMessages.receivingDashboard)} />;
};
