import { MenuLinkConfig } from "facility-commons/components/LinksMenu";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { use3PLEmbeddedDashboard } from "../ThreePLEmbeddedDashboard";
import { MoreLinksNames } from "../MoreLinks";
import { MoreRoutesNames } from "../MoreRoutes";
import { MetabaseWarehouseDashboard } from "@deliverr/legacy-inbound-client/lib/MetabaseWarehouseDashboard";

export const useStorageExpectedVsReceivedDasboardTool = (warehouseId: string) => {
  const { formatMessage } = useIntl();
  const {
    embeddedDashboardUrl: expectedVsReceivedStorageDashboardUrl,
    embeddedDashboardLoading: expectedVsReceivedStorageDashboardLoading,
    embeddedDashboardError: expectedVsReceivedStorageDashboardError,
  } = use3PLEmbeddedDashboard(warehouseId!, MetabaseWarehouseDashboard.RESERVE_STORAGE_METRICS);

  const messageId = "warehouse.moreMenu.expectedVsReceivedStorageDashboard";
  const defaultMessage = "Expected vs Received Quantities";

  const storageToolDashboardLinks = useMemo(() => {
    const links: MenuLinkConfig<MoreLinksNames>[] = [];
    if (expectedVsReceivedStorageDashboardUrl) {
      links.push({
        text: <FormattedMessage id={messageId} defaultMessage={defaultMessage} />,
        route: MoreRoutesNames.STORAGE_OPS_EXPECTED_VS_RECEIVED_QUANTITIES_DASHBOARD,
        props: {
          embeddedDashboardUrl: expectedVsReceivedStorageDashboardUrl,
          dashboardTitle: formatMessage({
            id: messageId,
            defaultMessage,
          }),
          error: expectedVsReceivedStorageDashboardError,
          loading: expectedVsReceivedStorageDashboardLoading,
        },
        id: MoreLinksNames.DASHBOARD,
      });
    }
    return links;
  }, [
    expectedVsReceivedStorageDashboardUrl,
    expectedVsReceivedStorageDashboardLoading,
    expectedVsReceivedStorageDashboardError,
    formatMessage,
  ]);

  return {
    storageToolDashboardLinks,
  };
};
