import { defineMessages } from "react-intl";

export const navbarText = defineMessages({
  nonCompliance: {
    id: "warehouse.nonCompliance",
    defaultMessage: "Non-Compliance",
  },
  damagedItem: {
    id: "damagedItem",
    defaultMessage: "Damaged Item",
  },
  createTicket: {
    id: "createTicket",
    defaultMessage: "Create Ticket",
  },
  createIssue: {
    id: "createIssue",
    defaultMessage: "Create Issue",
  },
  issueCenter: {
    id: "issueCenter",
    defaultMessage: "Issues Dashboard",
  },

  hazmatLookup: {
    id: "hazmatLookup",
    defaultMessage: "HAZMAT Lookup",
  },
  receiving: {
    id: "receiving",
    defaultMessage: "Receiving",
  },
  outbounds: {
    id: "outbounds",
    defaultMessage: "Outbounds",
  },
  arrivalScan: {
    id: "arrivalScan",
    defaultMessage: "Arrival Scan",
  },
  tickets: {
    id: "tickets",
    defaultMessage: "Tickets",
  },
  moreOptions: {
    id: "moreOptions",
    defaultMessage: "More Options",
  },
  DHLManifest: {
    id: "DHLManifest",
    defaultMessage: "DHL Manifest",
  },
  outboundCapacity: {
    id: "outboundCapacity",
    defaultMessage: "Outbound Capacity",
  },
  PLDashboard: {
    id: "PLDashboard",
    defaultMessage: "3PL Dashboard",
  },
  poLookup: {
    id: "poLookup",
    defaultMessage: "PO # Lookup",
  },
  barcodeNotInWMS: {
    id: "warehouse.barcodeNotInWMS",
    defaultMessage: "Barcode Not in WMS",
  },
  other: {
    id: "other",
    defaultMessage: "Other",
  },
  orderPicking: {
    id: "warehouse.orderPicking",
    defaultMessage: "Order Picking",
  },
  changeMode: {
    id: "warehouse.changeMode",
    defaultMessage: "Change mode",
  },
  createTicketAnchor: {
    id: "createTicketAnchor",
    defaultMessage: "Create ticket",
  },
  dailyOpsDashboard: {
    id: "dailyOps",
    defaultMessage: "Daily Operations Dashboard",
  },
  opsMetricsDashboard: {
    id: "opsMetrics",
    defaultMessage: "Operating Metrics Dashboard",
  },
  storageOpsMetricsDashboard: {
    id: "storageOpsMetrics",
    defaultMessage: "Operating Metrics Dashboard",
  },
  forecastingPlanningDashboard: {
    id: "forecastingPlanning",
    defaultMessage: "Forecasting & Planning Dashboard",
  },
  viewTicketsAnchor: {
    id: "warehouse.warehouseHeader.viewTicketsAnchor",
    defaultMessage: "View tickets",
  },
  getProductZone: {
    id: "warehouse.warehouseHeader.getProductZone",
    defaultMessage: "Get Product Zone",
  },
  reportIssue: {
    id: "warehouse.warehouseHeader.reportIssue",
    defaultMessage: "Report Issue",
  },
});
