import { defineMessages, MessageDescriptor } from "react-intl";

export enum PalletCreatedErrorMessageKey {
  PALLET_CREATED = "PALLET_CREATED",
}

export const PALLET_CREATED_ERROR_BY_ERROR_CODE: Record<
  PalletCreatedErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [PalletCreatedErrorMessageKey.PALLET_CREATED]: {
    id: "warehouse.receiving.palletCreatedError",
    defaultMessage: "Pallet {label} has already been created.",
  },
});
