import { defineMessages, MessageDescriptor } from "react-intl";

export enum InvalidLocationErrorMessageKey {
  UNUSABLE_LOCATION = "UNUSABLE_LOCATION",
  LOT_EXP_CONFLICT = "LOT_EXP_CONFLICT",
  UNABLE_TO_VALIDATE = "UNABLE_TO_VALIDATE",
  MISSING_LOCATION = "MISSING_LOCATION",
}

export const INVALID_LOCATION_ERROR_BY_ERROR_CODE: Record<
  InvalidLocationErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [InvalidLocationErrorMessageKey.UNUSABLE_LOCATION]: {
    id: "warehouse.location.unusableLoc",
    defaultMessage: "{newLocation} is not configured for use. Configure this location or use a valid location.",
  },
  [InvalidLocationErrorMessageKey.LOT_EXP_CONFLICT]: {
    id: "warehouse.location.lotExpConflict",
    defaultMessage:
      "{newLocation} already contains this SKU with a different lot number or expiration. Use a different location.",
  },
  [InvalidLocationErrorMessageKey.UNABLE_TO_VALIDATE]: {
    id: "warehouse.location.unableToValidate",
    defaultMessage: "Unable to validate {newLocation}. Please contact Deliverr.",
  },
  [InvalidLocationErrorMessageKey.MISSING_LOCATION]: {
    id: "warehouse.location.missingLoc",
    defaultMessage: "A valid location must be provided.",
  },
});
