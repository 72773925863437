import { ReceivingAsn } from "@deliverr/commons-clients/lib/crossdock/models/ReceivingAsn";
import { Button, DefaultTheme, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { richText } from "facility-commons/intl/richText";

import { ModalTemplate, warningModalIcon } from "facility-commons";
import React from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";

interface CdskuRerouteModalProps {
  asn: ReceivingAsn;
  modalMessage: (asn: ReceivingAsn) => MessageDescriptor;
}

const MarginDiv = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S4};
  `
);

export const CdskuRerouteModal: React.FC<CdskuRerouteModalProps> = ({ asn, modalMessage }) => {
  const { hideModal } = useWarehouseModal();
  const { formatMessage } = useIntl();

  // Have to format the message text here so that it doesnt
  // render with an empty value

  return (
    <ModalTemplate
      iconProps={warningModalIcon}
      title={<FormattedMessage id="warehouse.cdskuRerouteModal.title" defaultMessage="Misrouted Box!" />}
      content={
        <>
          <MarginDiv>{formatMessage(modalMessage(asn), richText({ asnId: asn?.asnId, cdsku: asn?.cdsku }))}</MarginDiv>
          <Text>
            <FormattedMessage
              id="warehouse.cdskuRerouteModal.text2"
              defaultMessage="There is no need to contact Deliverr."
            />
          </Text>
        </>
      }
    >
      <Button block onClick={() => hideModal(WarehouseModal.MISROUTED_CDSKU)}>
        <FormattedMessage id="warehouse.cdskuRerouteModal.button" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
