import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Cookie from "js-cookie";
import { useRecoilState } from "recoil";

import { AccountMenuItem } from "./AccountMenuItem";
import { MenuContainer } from "facility-commons/components/LinksMenu/LinksMenu";
import { AdminWarehouseSelector } from "facility-commons/components/Admin/AdminWarehouseSelector";
import { userState } from "facility-commons/base/Auth/userState";
import { WarehouseSelection } from "facility-commons/hooks/auth/useAdminWarehouseSelector";
import { AppName, LanguageCode, LanguageOption } from "facility-commons/types";
import { AUTH_LOCAL_STORAGE_KEYS, COOKIE_KEYS } from "facility-commons/types/Auth";
import { FACILITY_LANGUAGE_LABELS } from "facility-commons/components/FacilityLanguageSelector/FacilityLanguage.labels";
import { useLanguage } from "../../base/useLanguage";
import { LanguageSelector } from "./LanguageSelector";

export const AccountMenu: React.FC<{ appName: AppName; userName: string; warehouseId: string; isAdmin: boolean }> = ({
  appName,
  userName,
  warehouseId,
  isAdmin,
}) => {
  const { formatMessage } = useIntl();
  const [userAuth, setUserAuth] = useRecoilState(userState);
  const { language, setLanguageSelection } = useLanguage();

  const LANGUAGE_OPTIONS: LanguageOption[] = [
    {
      label: formatMessage(FACILITY_LANGUAGE_LABELS[LanguageCode.ENGLISH]),
      value: LanguageCode.ENGLISH,
    },
    {
      label: formatMessage(FACILITY_LANGUAGE_LABELS[LanguageCode.SPANISH]),
      value: LanguageCode.SPANISH,
    },
  ];
  const onWarehouseSelection = async (selection: WarehouseSelection) => {
    window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.WAREHOUSE_ID, selection.value);
    setUserAuth({ ...userAuth, isHub: selection.value.includes("HUB"), warehouseId: selection.value });
    Cookie.set(COOKIE_KEYS.WAREHOUSE_ID, selection.value);
  };

  return (
    <MenuContainer>
      <AccountMenuItem
        label={formatMessage({
          id: "warehousePage.moreMenu.emailLabel",
          defaultMessage: "Account",
        })}
        value={userName}
      />
      {isAdmin ? (
        <li>
          <AdminWarehouseSelector
            appName={appName}
            onSelection={onWarehouseSelection}
            label={<FormattedMessage id="warehouse.more.accountLabel" defaultMessage="Warehouse ID" />}
          />
        </li>
      ) : (
        <AccountMenuItem
          label={formatMessage({
            id: "warehousePage.moreMenu.warehouseIdLabel",
            defaultMessage: "Warehouse ID",
          })}
          value={warehouseId}
        />
      )}
      {appName === AppName.WP && (
        <LanguageSelector
          label={<FormattedMessage id="warehouse.morePage.selectLanguage" defaultMessage="Language" />}
          options={LANGUAGE_OPTIONS}
          value={{ value: language, label: formatMessage(FACILITY_LANGUAGE_LABELS[language]) }}
          onSelection={setLanguageSelection}
        />
      )}
    </MenuContainer>
  );
};
