import { defineMessages } from "react-intl";

export const BASE_RESOLUTION_CARD_LABELS = {
  listItems: defineMessages({
    po: {
      id: "warehouse.ticketCenter.ticketDetails.po",
      defaultMessage: "Receiving PO #",
    },
    barcode: {
      id: "warehouse.ticketCenter.ticketDetails.barcode",
      defaultMessage: "Barcode",
    },
    quantity: {
      id: "warehouse.ticketCenter.ticketDetails.quantity",
      defaultMessage: "Quantity",
    },
  }),
  receiveDetails: defineMessages({
    receiveBy: {
      id: "warehouse.ticketCenter.ticketDetails.receiveBy",
      defaultMessage: "Receive by",
    },
    receivedOn: {
      id: "warehouse.ticketCenter.ticketDetails.receivedOn",
      defaultMessage: "Received on",
    },
  }),
  instructions: defineMessages({
    instructionsHeader: {
      id: "warehouse.ticketCenter.ticketDetails.instructionsHeader",
      defaultMessage: "Instructions",
    },
    printNewLabels: {
      id: "warehouse.ticketCenter.printNewLabels",
      defaultMessage: "Print new barcode labels.",
    },
    relabelProducts: {
      id: "warehouse.ticketCenter.relabelProducts",
      defaultMessage: "Relabel products.",
    },
    receiveSku: {
      id: "warehouse.ticketCenter.receiveSku",
      defaultMessage: "Receive SKU against the new PO # in your WMS.",
    },
  }),
  resolution: defineMessages({
    unknownBarcodeResolution: {
      id: "warehouse.ticketCenter.ticketDetails.unknownBarcodeResolution",
      defaultMessage: "Barcode has been added to WMS. Please try receiving the product again.",
    },
  }),
};
