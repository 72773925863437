import { useRecoilValue, useSetRecoilState } from "recoil";
import { useMount } from "react-use";
import { useIntl } from "react-intl";
import { setProp } from "facility-commons";
import { palletAnalyticsV2State } from "../../ReceivingState/PalletSummaryV2State";
import { palletAnalyticsV2Messages } from "./PalletAnalyticsV2Messages";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { ReceivingPath } from "warehouse/receiving/routes";
import { Appearance, IconV2Types } from "@deliverr/ui";
import { uniq } from "lodash";

export const usePalletAnalyticsV2SelectBoxConfiguration = () => {
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { palletLabel, data } = useRecoilValue(palletAnalyticsV2State);
  const {
    numBoxesReceived,
    expectedNumBoxes,
    areBulkBoxesFullyReceived,
    areMultiSkuBoxesFullyReceived,
    areSingleSkuBoxesFullyReceived,
    overReceivedUnitsOnSingleSkuBoxes,
    overReceivedUnitsOnMultiSkuBoxes,
    overReceivedUnitsOnBulkBoxes,
    singleSkuBoxReceives,
    multiSkuBoxReceives,
    bulkBoxReceives,
  } = data;

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(palletAnalyticsV2Messages.palletAnalyticsV2)));
    setWarehouseAppState(setProp("pageSubtitle", palletLabel));
  });

  const { formatMessage } = useIntl();

  const receivedPercentage = (numBoxesReceived / expectedNumBoxes) * 100;
  const numSingleSkuBoxes = Object.keys(singleSkuBoxReceives).length;
  const numMultiSkuBoxes = Object.keys(multiSkuBoxReceives).length;
  const numBulkBoxes = uniq(
    Object.values(bulkBoxReceives)
      .map((bulkBoxGroup) => Object.keys(bulkBoxGroup))
      .flat()
  ).length;

  const boxesReceivedMsg = formatMessage(palletAnalyticsV2Messages.boxesReceived);

  const missingUnitsMsg = formatMessage(palletAnalyticsV2Messages.missingUnits);
  const missingUnitsSubtitleAndIcon = {
    subtitle: {
      text: missingUnitsMsg,
      appearance: Appearance.DANGER,
    },
    icon: {
      type: "exclamation-circle" as IconV2Types,
      color: "red",
    },
  };

  const extraUnitsMsg = formatMessage(palletAnalyticsV2Messages.extraUnits);
  const extraUnitsSubtitleAndIcon = {
    subtitle: {
      text: extraUnitsMsg,
      appearance: Appearance.WARNING,
    },
    icon: {
      type: "plus-circle" as IconV2Types,
      color: "gold",
    },
  };

  const bulkBoxDetails = {
    title: formatMessage(palletAnalyticsV2Messages.bulkBoxes),
    missingUnitsSubtitleAndIcon: !areBulkBoxesFullyReceived ? missingUnitsSubtitleAndIcon : undefined,
    extraUnitsSubtitleAndIcon: overReceivedUnitsOnBulkBoxes ? extraUnitsSubtitleAndIcon : undefined,
    description: formatMessage(palletAnalyticsV2Messages.numBoxes, { numBoxes: numBulkBoxes }),
    link: ReceivingPath.PALLET_ANALYTICS_BULK_BOX_SUMMARY,
  };

  const multiSkuBoxDetails = {
    title: formatMessage(palletAnalyticsV2Messages.multiSKUBoxes),
    missingUnitsSubtitleAndIcon: !areMultiSkuBoxesFullyReceived ? missingUnitsSubtitleAndIcon : undefined,
    extraUnitsSubtitleAndIcon: overReceivedUnitsOnMultiSkuBoxes ? extraUnitsSubtitleAndIcon : undefined,
    description: formatMessage(palletAnalyticsV2Messages.numBoxes, { numBoxes: numMultiSkuBoxes }),
    link: ReceivingPath.PALLET_ANALYTICS_MULTI_SKU_BOX_SUMMARY,
  };

  const singleSkuBoxDetails = {
    title: formatMessage(palletAnalyticsV2Messages.singleSKUBoxes),
    missingUnitsSubtitleAndIcon: !areSingleSkuBoxesFullyReceived ? missingUnitsSubtitleAndIcon : undefined,
    extraUnitsSubtitleAndIcon: overReceivedUnitsOnSingleSkuBoxes ? extraUnitsSubtitleAndIcon : undefined,
    description: formatMessage(palletAnalyticsV2Messages.numBoxes, { numBoxes: numSingleSkuBoxes }),
    link: ReceivingPath.PALLET_ANALYTICS_SINGLE_SKU_BOX_SUMMARY,
  };

  // should theoretically not happen and should not be able to get to this page if this is true
  const noLabeledBoxedOnPalletMsg = formatMessage(palletAnalyticsV2Messages.noLabeledBoxesOnPallet);

  return {
    numBoxesReceived,
    expectedNumBoxes,
    receivedPercentage,
    boxesReceivedMsg,
    noLabeledBoxedOnPalletMsg,
    bulkBoxDetails,
    multiSkuBoxDetails,
    singleSkuBoxDetails,
    showBulkBoxLink: numBulkBoxes > 0,
    showMultiSkuBoxLink: numMultiSkuBoxes > 0,
    showSingleSkuBoxLink: numSingleSkuBoxes > 0,
  };
};
