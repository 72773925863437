import React from "react";
import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

import { StyledMenuLink, StyledMenuListItem } from "facility-commons/components/LinksMenu";

interface AccountMenuItemProps {
  label: string;
  value: string;
}

export const StyledAccountMenuItem = styled(StyledMenuLink)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.REGULAR};
  width: 100%;
  margin-left: auto;

  & > * {
    text-align: right;
    font-weight: ${theme.font.weight.REGULAR};
    color: ${theme.colors.NEUTRAL["300"]};
    margin-left: auto;
  }
`
).withComponent("div");

export const AccountMenuItem: React.FC<AccountMenuItemProps> = ({ label, value }) => (
  <StyledMenuListItem>
    <StyledAccountMenuItem>
      {label}
      <div>{value}</div>
    </StyledAccountMenuItem>
  </StyledMenuListItem>
);
