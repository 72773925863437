import { MenuLinkConfig } from "facility-commons/components/LinksMenu";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { use3PLEmbeddedDashboard } from "../ThreePLEmbeddedDashboard";
import { MoreLinksNames } from "../MoreLinks";
import { MoreRoutesNames } from "../MoreRoutes";
import { MetabaseWarehouseDashboard } from "@deliverr/legacy-inbound-client/lib/MetabaseWarehouseDashboard";

export const useMoreStorageDashboards = (warehouseId: string) => {
  const { formatMessage } = useIntl();
  const {
    embeddedDashboardUrl: storageOperatingMetricsDashboardUrl,
    embeddedDashboardLoading: storageOperatingMetricsDashboardLoading,
    embeddedDashboardError: storageOperatingMetricsDashboardError,
  } = use3PLEmbeddedDashboard(warehouseId!, MetabaseWarehouseDashboard.STORAGE_OPERATING_METRICS);

  const storageDashboardLinks = useMemo(() => {
    const links: MenuLinkConfig<MoreLinksNames>[] = [];
    if (storageOperatingMetricsDashboardUrl) {
      links.push({
        text: (
          <FormattedMessage
            id="warehouse.moreMenu.operatingMetricsDashboard"
            defaultMessage="Operating Metrics Dashboard"
          />
        ),
        route: MoreRoutesNames.STORAGE_OPS_METRICS_DASHBOARD,
        props: {
          embeddedDashboardUrl: storageOperatingMetricsDashboardUrl,
          dashboardTitle: formatMessage({
            id: "storage.moreMenu.operatingMetricsDashboard",
            defaultMessage: "Operating Metrics Dashboard",
          }),
          error: storageOperatingMetricsDashboardLoading,
          loading: storageOperatingMetricsDashboardError,
        },
        id: MoreLinksNames.DASHBOARD,
      });
    }
    return links;
  }, [
    storageOperatingMetricsDashboardUrl,
    storageOperatingMetricsDashboardLoading,
    storageOperatingMetricsDashboardError,
    formatMessage,
  ]);

  return {
    storageDashboardLinks,
  };
};
