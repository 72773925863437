import { useIntl } from "react-intl";
import { ReturnReceiveItemInspectionState, returnReceiveItemInspectionStateAtom } from "../../state";
import { useRecoilState } from "recoil";
import { commonReceivingMessages, commonReturnReceiveMessage } from "warehouse/receiving/content";
import { useState } from "react";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useMount } from "react-use";
import { useInspectionRouting } from "../../hooks";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";

export const useItemInspectionCard = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useWarehouseModal();
  const { successResponse } = useCommonFlow();
  const [itemInspectionState, setItemInspectionState] = useRecoilState<ReturnReceiveItemInspectionState>(
    returnReceiveItemInspectionStateAtom
  );
  const [selectedCondition, setSelectedCondition] = useState<string>("");

  const { routeAccordingToDisposition } = useInspectionRouting();

  const {
    barcode,
    dsku,
    itemName,
    isLotTrackingEnabled,
    isFefoEnabled,
    inspectionSOPName,
    inspectionSOPDescription,
    inspectionSteps,
    inspectionRules,
  } = itemInspectionState;

  // in case user can't find the lot/expiry they will get rerouted back to this page
  // because they need to select a condition that doesn't result in restock
  useMount(() => {
    setItemInspectionState((currentState) => ({ ...currentState, condition: null, disposition: null, vasApplied: [] }));
  });

  const onConditionSelect = (condition: string) => {
    setSelectedCondition(condition);
  };

  const onViewStandardsClick = () => {
    showModal(WarehouseModal.RETURN_RECEIVE_ITEM_INSPECTION_STANDARDS, {});
  };

  const onSubmit = () => {
    const mappedDisposition = inspectionRules[selectedCondition].disposition;
    setItemInspectionState((currentState) => ({
      ...currentState,
      condition: selectedCondition,
      disposition: mappedDisposition,
    }));
    successResponse();
    routeAccordingToDisposition(mappedDisposition, isLotTrackingEnabled || isFefoEnabled);
  };

  const messages = {
    inspectionCardTitle: formatMessage(commonReturnReceiveMessage.inspectionCardTitle),
    viewInspectionStandards: formatMessage(commonReturnReceiveMessage.viewInspectionStandards),
    valueAddedServices: formatMessage(commonReturnReceiveMessage.valueAddedServices),
    submitButtonText: formatMessage(commonReceivingMessages.continue),
  };
  return {
    barcode,
    dsku,
    itemName,
    inspectionSOPName,
    inspectionSOPDescription,
    inspectionSteps,
    inspectionRules,
    messages,
    selectedCondition,
    onConditionSelect,
    onViewStandardsClick,
    onSubmit,
  };
};
