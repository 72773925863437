import { ButtonProps } from "@deliverr/ui";
import { ProceedToReviewButton } from "../components/buttons";

export enum ReceivingButtonType {
  PROCEED_TO_REVIEW = "PROCEED_TO_REVIEW",
}

type ReceivingButtonMap = Record<ReceivingButtonType, React.FC<ButtonProps>>;

export const receivingButtonsMap: ReceivingButtonMap = {
  [ReceivingButtonType.PROCEED_TO_REVIEW]: ProceedToReviewButton,
};
