import React from "react";
import { Button } from "@deliverr/ui";
import { dangerModalIcon, ModalTemplate, warningModalIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { useIncorrectBoxQtyModal } from "./useIncorrectBoxQtyModal";
import { modalsText } from "../modalsText";

export interface IncorrectBoxQtyModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  boxesQty: number;
  fewerBoxes: boolean;
}

export const IncorrectBoxQtyModal: React.FC<IncorrectBoxQtyModalProps> = ({
  onCancel,
  onConfirm,
  boxesQty,
  fewerBoxes,
}) => {
  const { title, buttonText, text, formatMessage } = useIncorrectBoxQtyModal({ fewerBoxes, boxesQty });

  return (
    <ModalTemplate title={title} text={text} iconProps={fewerBoxes ? warningModalIcon : dangerModalIcon}>
      <WarehouseModalRightMarginButton block onClick={onCancel} secondary data-testid={"more-boxes-modal-cancel-btn"}>
        {formatMessage(modalsText.back)}
      </WarehouseModalRightMarginButton>
      <Button block onClick={onConfirm}>
        {buttonText}
      </Button>
    </ModalTemplate>
  );
};
