import { Button, Grid, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FACILITY_COMMONS_BUTTON_LABELS, ModalTemplate, questionModalIcon } from "facility-commons";
import { SoundFx } from "facility-commons/common/sfx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { useStorageConfirmationModal } from "./useStorageConfirmationModal";
import { WarehouseModalProps } from "warehouse/modal";

const ButtonContainer = styled(Grid)<ThemeProps>`
  width: 100%;
`;

export const StorageConfirmationModal: React.FC<WarehouseModalProps> = (modal) => {
  const { handleCancel, submitLoading, cancelLoading, handleSuccess } = useStorageConfirmationModal(modal);

  return (
    <ModalTemplate
      soundFx={modal.soundFx ?? SoundFx.SUCCESS}
      title={modal.title ?? <FormattedMessage {...STORAGE_RECEIVING_MESSAGES.confirmationModalTitle} />}
      text={modal.text ?? <FormattedMessage {...STORAGE_RECEIVING_MESSAGES.confirmationModalText} />}
      iconProps={modal.iconProps ?? questionModalIcon}
      buttonLayout="column"
    >
      <ButtonContainer columns="1fr 1fr" gap="S3">
        <Button block onClick={handleSuccess} type="button" loading={submitLoading} disabled={cancelLoading}>
          {modal.confirmButtonText ?? <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.confirmButton} />}
        </Button>
        <Button block secondary onClick={handleCancel} type="button" loading={cancelLoading} disabled={submitLoading}>
          {modal.cancelButtonText ?? <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.cancelButton} />}
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
