import React from "react";
import { useIntl } from "react-intl";
import { ContinueButton, ModalTemplate, warningModalCircleIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { QuantityWarningModalProps, useQuantityWarningModal } from "./useQuantityWarningModal";
import { modalsText } from "../modalsText";
import { commonMessages } from "facility-commons/labels";

export const QuantityWarningModal: React.FC<QuantityWarningModalProps> = (props) => {
  const { inputtedQuantity } = props;
  const { handleModalClickContinue, hideQuantityWarningModal } = useQuantityWarningModal(props);
  const { formatMessage } = useIntl();
  const unit = formatMessage(commonMessages.unit, {
    unitsQty: inputtedQuantity,
  });
  return (
    <ModalTemplate
      title={formatMessage(modalsText.incorrectUnitTitle)}
      text={formatMessage(modalsText.incorrectUnitText, { unit })}
      iconProps={warningModalCircleIcon}
    >
      <WarehouseModalRightMarginButton block onClick={hideQuantityWarningModal} secondary>
        {formatMessage(modalsText.back)}
      </WarehouseModalRightMarginButton>
      <ContinueButton block onClick={handleModalClickContinue} />
    </ModalTemplate>
  );
};
