import { ErrorOption } from "react-hook-form";

import { minBarcodeLength, maxBarcodeLength, validDSKU } from "facility-commons/utils/config";
import { HazmatFormField, HazmatFormValidationErrorMessages } from "./HazmatForm.types";

export const validationConfig = {
  [HazmatFormField.BARCODE]: {
    required: HazmatFormValidationErrorMessages.BARCODE_REQUIRED,
    minLength: {
      value: minBarcodeLength,
      message: HazmatFormValidationErrorMessages.BARCODE_FORMAT,
    },
    maxLength: {
      value: maxBarcodeLength,
      message: HazmatFormValidationErrorMessages.BARCODE_FORMAT,
    },
    pattern: {
      value: validDSKU,
      message: HazmatFormValidationErrorMessages.BARCODE_FORMAT,
    },
  },
};

type SetErrorArgs = [string, ErrorOption];

export const HazmatFormValidationMap: { [error: string]: SetErrorArgs } = {
  unknown: [HazmatFormField.BARCODE, { type: "string", message: HazmatFormValidationErrorMessages.UNKNOWN }],
};
