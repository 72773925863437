import * as React from "react";

import { Button, PillProps } from "@deliverr/ui";

import { InspectionNotesButton } from "../InspectionNotesButton";
import { InspectionNotesType } from "../../types/InspectionNotesType";
import { ReactComponent as OpenBox } from "facility-commons/common/assets/openBox.svg";
import { PointerAnchor } from "facility-commons/components/emotion";
import { ReturnType } from "@deliverr/returns-client";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { useBarcodeScan } from "./useBarcodeScan";

export const BarcodeScan: React.FC = () => {
  const {
    barcode,
    returnType,
    numItemsLeftToInspect,
    isSubmitting,
    barcodeError,
    messages,
    showCloseReturnOrderButton,
    showExpectedItemsModal,
    showCloseReturnOrderModal,
    onMissingBarcodeClick,
    handleChange,
    handleBarcodeSubmit,
  } = useBarcodeScan();

  const getPillProps = (): PillProps => {
    if (returnType === ReturnType.BLIND) {
      return {
        color: "GRAY",
        children: <span>{messages.numItemsInspected}</span>,
      };
    }

    return {
      color: numItemsLeftToInspect === 0 ? "RED" : "GRAY",
      onClick: showExpectedItemsModal,
      children: <span>{messages.numItemsLeftToInspect}</span>,
    };
  };

  return (
    <>
      <StackedInputCard
        illustration={<OpenBox />}
        illustrationTitle={messages.illustrationTitle}
        message={messages.message}
        newest
        onChange={handleChange}
        onSubmit={handleBarcodeSubmit}
        autoFocus
        secondary
        placeholder={messages.placeholder}
        loading={isSubmitting}
        title={messages.title}
        value={barcode}
        errorMessage={barcodeError}
        pillProps={getPillProps()}
      >
        <PointerAnchor onClick={onMissingBarcodeClick}>{messages.missingBarcodePrompt}</PointerAnchor>
      </StackedInputCard>
      <InspectionNotesButton noteType={InspectionNotesType.RETURN_ORDER} />
      {showCloseReturnOrderButton && (
        <Button block secondary onClick={showCloseReturnOrderModal}>
          {messages.closeReturnOrder}
        </Button>
      )}
    </>
  );
};
