import { PalletBoxSummaryV2 } from "@deliverr/legacy-inbound-client";

export enum BoxConfigSummary {
  SINGLE_SKU = "singleSkuBox",
  MULTI_SKU = "multiSkuBox",
  BULK = "bulkBox",
}
export interface PalletAnalyticsV2State {
  palletLabel: string;
  boxConfigSummarySelected?: BoxConfigSummary;
  data: PalletBoxSummaryV2;
}
