import React from "react";
import { ProgressBar } from "@deliverr/ui-facility";
import { FacilityCard } from "facility-commons";
import { BoxReset, Text } from "@deliverr/ui";
import { usePalletAnalyticsV2SelectBoxConfiguration } from "./usePalletAnalyticsV2SelectBoxConfiguration";
import { StyledStack } from "../ReceiveTypeSelector";
import { LinkCard } from "warehouse/common/components/LinkCard";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";

export const PalletAnalyticsV2SelectBoxConfiguration: React.FC = () => {
  const {
    numBoxesReceived,
    expectedNumBoxes,
    receivedPercentage,
    boxesReceivedMsg,
    noLabeledBoxedOnPalletMsg,
    bulkBoxDetails,
    multiSkuBoxDetails,
    singleSkuBoxDetails,
    showBulkBoxLink,
    showMultiSkuBoxLink,
    showSingleSkuBoxLink,
  } = usePalletAnalyticsV2SelectBoxConfiguration();
  return (
    <>
      <FacilityCard>
        <BoxReset marginBottom={"S2"}>
          <Text>
            <b>{boxesReceivedMsg}</b> {`${numBoxesReceived}/${expectedNumBoxes}`}
          </Text>
        </BoxReset>
        <ProgressBar progressPercent={receivedPercentage} barStyle="SOLID" />
      </FacilityCard>
      <BoxReset marginTop={"S2"}>
        {showBulkBoxLink || showMultiSkuBoxLink || showSingleSkuBoxLink ? (
          <StyledStack>
            {showBulkBoxLink && (
              <LinkCard
                title={bulkBoxDetails.title}
                subtitle={bulkBoxDetails.missingUnitsSubtitleAndIcon?.subtitle}
                icon={bulkBoxDetails.missingUnitsSubtitleAndIcon?.icon}
                secondarySubtitle={bulkBoxDetails.extraUnitsSubtitleAndIcon?.subtitle}
                secondaryIcon={bulkBoxDetails.extraUnitsSubtitleAndIcon?.icon}
                description={bulkBoxDetails.description}
                to={bulkBoxDetails.link}
                data-testid={"pallet-analytics-v2-bulk-box-link"}
              />
            )}
            {showMultiSkuBoxLink && (
              <LinkCard
                title={multiSkuBoxDetails.title}
                subtitle={multiSkuBoxDetails.missingUnitsSubtitleAndIcon?.subtitle}
                icon={multiSkuBoxDetails.missingUnitsSubtitleAndIcon?.icon}
                secondarySubtitle={multiSkuBoxDetails.extraUnitsSubtitleAndIcon?.subtitle}
                secondaryIcon={multiSkuBoxDetails.extraUnitsSubtitleAndIcon?.icon}
                description={multiSkuBoxDetails.description}
                to={multiSkuBoxDetails.link}
                data-testid={"pallet-analytics-v2-multi-sku-box-link"}
              />
            )}
            {showSingleSkuBoxLink && (
              <LinkCard
                title={singleSkuBoxDetails.title}
                subtitle={singleSkuBoxDetails.missingUnitsSubtitleAndIcon?.subtitle}
                icon={singleSkuBoxDetails.missingUnitsSubtitleAndIcon?.icon}
                secondarySubtitle={singleSkuBoxDetails.extraUnitsSubtitleAndIcon?.subtitle}
                secondaryIcon={singleSkuBoxDetails.extraUnitsSubtitleAndIcon?.icon}
                description={singleSkuBoxDetails.description}
                to={singleSkuBoxDetails.link}
                data-testid={"pallet-analytics-v2-single-sku-box-link"}
              />
            )}
          </StyledStack>
        ) : (
          <FacilityCard>
            <Text textAlign={TextAlign.center}>{noLabeledBoxedOnPalletMsg}</Text>
          </FacilityCard>
        )}
      </BoxReset>
    </>
  );
};
