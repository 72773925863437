import { isEmpty, noop } from "lodash";
import { useEffect, useRef } from "react";
import { isZebraScanner } from "facility-commons/utils";
import { updateOnZebraScan } from "facility-commons/flow/zebraScanner";
import { generate } from "shortid";

export interface UseScannerProps {
  onChange?: (newValue: string) => any;
  newest?: boolean;
}

export function useScanner({ onChange = noop, newest = true }: UseScannerProps) {
  // used to differentiate individual cards of the same type from one another
  const cardId = generate();
  const scannedValue = useRef<string>("");

  // Some scanners send key presses, we can add a listener to capture these, even if the
  // input box does not have focus.  We assume that the scanner is configured to send a carriage
  // return not all scanners do this.
  const handleKeyPressed = (keyMessage: any) => {
    if (!keyMessage || !keyMessage.target || ["text", "number"].includes(keyMessage.target.type)) {
      // do nothing
    } else if (keyMessage.key === "Enter") {
      // to fix an issue with some scanners (with outdated OS) where an extra scan with empty string is triggered
      // causing the state to update with empty data.
      if (!isEmpty(scannedValue.current)) {
        onChange(scannedValue.current);
      }
      scannedValue.current = "";
    } else if (keyMessage.key.length === 1) {
      scannedValue.current += keyMessage.key;
    }
  };

  useEffect(() => {
    if (!isZebraScanner) {
      window.addEventListener("keydown", handleKeyPressed);
      return () => {
        window.removeEventListener("keydown", handleKeyPressed);
      };
    }

    // enable Zebra API to scan item
    if (isZebraScanner && newest) {
      updateOnZebraScan(onChange);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);
}
