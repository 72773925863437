import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, dangerModalIconXl } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";

interface DirectPalletModalProps {
  onCancel: () => void;
  onContinue: () => void;
}

export const DirectPalletModal: React.FC<DirectPalletModalProps> = ({ onCancel, onContinue }) => {
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      buttonLayout="row"
      title={formatMessage(modalsText.notCDPalletTitle)}
      text={formatMessage(modalsText.notCDPalletText)}
      iconProps={dangerModalIconXl}
    >
      <Grid columns="1fr 1fr" gap="S2">
        <Button block onClick={onCancel} secondary>
          {formatMessage(modalsText.cancel)}
        </Button>
        <Button block onClick={onContinue}>
          {formatMessage(modalsText.receiveBoxes)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
