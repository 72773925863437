import { atom } from "recoil";
import { PrepStateKey } from "../../base/state";

export interface PrepFeatureClientState {
  isClientInitialized: boolean;
}

const INITIAL_STATE: PrepFeatureClientState = {
  isClientInitialized: false,
};

export const prepFeatureClientState = atom<PrepFeatureClientState>({
  key: PrepStateKey.PREP_FEATURE_CLIENT_STATE,
  default: INITIAL_STATE,
});
