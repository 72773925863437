import { ArrivalScannerCardProps, PalletArrivalScannerErrorCardProps } from "warehouse/scan-arrival/cards/pallet";
import { WarehouseFlowCardType } from "./types/WarehouseFlowCardType";
import { createFlowCard } from "facility-commons/flow/createFlowCard";
import { SoundFx } from "facility-commons/common/sfx";
import { ArrivalScannerSuccessCardProps } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerSuccessCard";
import { ArrivalScannerFailureCardProps } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerFailureCard";
import { ArrivalScannerInvalidInputCardProps } from "warehouse/scan-arrival/cards/pallet/ArrivalScannerInvalidInputCard";
import { BoxTypeIdentificationCardProps } from "warehouse/scan-arrival/cards/box";
import { ResponseCardProps } from "facility-commons/flow";

// Pallet Arrival Scan
export const createArrivalScannerCard = createFlowCard<ArrivalScannerCardProps>(WarehouseFlowCardType.ARRIVAL_SCANNER);
export const createArrivalScannerSuccessCard = createFlowCard<ArrivalScannerSuccessCardProps>(
  WarehouseFlowCardType.ARRIVAL_SCANNER_SUCCESS,
  SoundFx.SUCCESS
);

export const createArrivalScannerFailureCard = createFlowCard<ArrivalScannerFailureCardProps>(
  WarehouseFlowCardType.ARRIVAL_SCANNER_FAILURE,
  SoundFx.ERROR
);

export const createPalletArrivalScannerErrorCard = createFlowCard<PalletArrivalScannerErrorCardProps>(
  WarehouseFlowCardType.PALLET_ARRIVAL_SCANNER_ERROR,
  SoundFx.ERROR
);

export const createArrivalScannerInvalidInputCard = createFlowCard<ArrivalScannerInvalidInputCardProps>(
  WarehouseFlowCardType.ARRIVAL_SCANNER_INVALID_INPUT,
  SoundFx.ERROR
);

// Box Arrival Scan
export const createBoxArrivalScannerCard = createFlowCard(WarehouseFlowCardType.BOX_ARRIVAL_SCANNER);

export const createBoxTypeIdentificationCard = createFlowCard<BoxTypeIdentificationCardProps>(
  WarehouseFlowCardType.BOX_IDENTIFICATION
);

export const createBoxArrivalScanNotificationCard = createFlowCard<ResponseCardProps>(
  WarehouseFlowCardType.BOX_ARRIVAL_SCAN_NOTIFICATION
);
