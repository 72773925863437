import React from "react";

import { trackingMessages } from "../../content/tracking";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { useBoxInformationCard } from "./useBoxInformationCard";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { SkipStep } from "../../components";

export const BoxInformationCard: React.FC = () => {
  const {
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToBarcodeStep,
    trackingCodeError,
    trackingCode,
  } = useBoxInformationCard();

  return (
    <StackedInputCard
      errorMessage={trackingCodeError}
      illustration={<ScanBox />}
      illustrationTitle={formatMessage(trackingMessages.scanShippingLabel)}
      message={formatMessage(trackingMessages.boxInfoInstructions)}
      newest
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(commonWarehouseMessages.poOrTracking)}
      loading={loading}
      title={formatMessage(trackingMessages.boxInfoTitle)}
      value={trackingCode}
    >
      <SkipStep message={formatMessage(trackingMessages.boxInfoUnknown)} onSkip={proceedToBarcodeStep} />
    </StackedInputCard>
  );
};
