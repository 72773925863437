import { logError } from "facility-commons/utils/logger";
import { useRecoilState } from "recoil";

import { WarehouseCapability } from "@deliverr/warehouse-client";
import { useCallback, useEffect } from "react";
import { userState } from "facility-commons/base/Auth/userState";
import { useClientsWithAuth } from "./useClientsWithAuth";

export function useStorageUserState(warehouseId) {
  const { warehousePublicClient } = useClientsWithAuth();
  const [{ isStorage }, setAuthUser] = useRecoilState(userState);
  const updateUserInfo = useCallback(async () => {
    let storageWarehouseIds: string[] | undefined = [];
    try {
      storageWarehouseIds = await warehousePublicClient.getWarehousesByCapabilities([WarehouseCapability.DEEP_STORAGE]);
    } catch (error: any) {
      logError({ fn: "useUserState.getWarehousesByCapability" }, error);
    }
    setAuthUser((data) => ({ ...data, isStorage: storageWarehouseIds?.includes(warehouseId) ?? false }));
  }, [setAuthUser, warehousePublicClient, warehouseId]);

  useEffect(() => {
    updateUserInfo();
  }, [updateUserInfo]);

  return {
    isStorage,
  };
}
