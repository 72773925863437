import React from "react";
import { ThemeProps, Grid, Text } from "@deliverr/ui";
import styled from "@emotion/styled";

import { quantityMessages } from "../../content/quantity";
import { useNcQuantityCard } from "./useNcQuantityCard";
import { NumericInput } from "facility-commons/components";
import { commonMessages } from "facility-commons/labels";
import { NcCardStack } from "../../components";

const Container = styled(Grid)<ThemeProps>`
  padding-right: ${({ theme }) => theme.spacing.S4}; /* to match input padding */
`;

const FlexText = styled(Text)`
  flex-grow: 1;
`;

export const NcQuantityCard: React.FC = () => {
  const {
    boxesError,
    formatMessage,
    numberOfBoxes,
    progress,
    quantityPerBox,
    unitsError,
    showNumberOfBoxesField,
    updateNumberOfBoxes,
    updateQuantityPerBox,
  } = useNcQuantityCard();

  return (
    <NcCardStack
      continueDisabled={!numberOfBoxes || !quantityPerBox || !!unitsError || !!boxesError}
      continueOnClick={progress}
      instructions={formatMessage(quantityMessages.countUnitsInstructions)}
      title={formatMessage(quantityMessages.countUnitsTitle)}
    >
      <NumericInput
        errorMessage={unitsError}
        hasError={!!unitsError}
        onChange={updateQuantityPerBox}
        placeholder={formatMessage(quantityMessages.unitsPlaceholder)}
        value={quantityPerBox}
      />
      {showNumberOfBoxesField && (
        <NumericInput
          errorMessage={boxesError}
          hasError={!!boxesError}
          onChange={updateNumberOfBoxes}
          placeholder={formatMessage(quantityMessages.boxesPlaceholder)}
          value={numberOfBoxes}
        />
      )}

      <Container columns="1fr auto">
        <FlexText>{formatMessage(commonMessages.totalUnits)}</FlexText>
        <Text>{quantityPerBox * numberOfBoxes}</Text>
      </Container>
    </NcCardStack>
  );
};
