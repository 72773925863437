import { useAsyncFn, useMount } from "react-use";
import { WarehouseMessages, genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { warehouseAppState } from "../../../base/warehouseAppDataState";
import { useIntl } from "react-intl";
import { useState } from "react";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useReceivingFlow } from "../../base";
import { palletAnalyticsV2Messages } from "./PalletAnalyticsV2Messages";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { COMMON_LABELS } from "../cards/warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";
import { palletAnalyticsV2State } from "../../ReceivingState/PalletSummaryV2State";
import { useRouter } from "facility-commons/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";

export const usePalletAnalyticsV2 = () => {
  const [pid, setPid] = useState<string>("");
  const [pidError, setPIDError] = useState<string>("");

  const { warehouseId, email } = useRecoilValue(userState);
  const { inboundClient } = useClientsWithAuth();

  const { push } = useRouter();
  const { handleUnknownError } = useReceivingFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const setPalletSummaryV2State = useSetRecoilState(palletAnalyticsV2State);
  const resetPalletSummaryData = useResetRecoilState(palletAnalyticsV2State);
  const { errorResponse } = useCommonFlow();
  const { formatMessage } = useIntl();

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(palletAnalyticsV2Messages.palletAnalyticsV2)));
    setWarehouseAppState(setProp("pageSubtitle", ""));
    resetPalletSummaryData();
  });

  const [{ loading }, handleSubmit] = useAsyncFn(async (value: string) => {
    const ctx = logStart({ fn: "usePalletAnalyticsV2.handleSubmit", value, warehouseId, email });
    setPIDError("");

    if (!value.length) {
      errorResponse();
      return setPIDError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
    }
    const onError = (response) => {
      const { message, payload } = response.error;

      log({ ...ctx, response }, "error while getting pallet details");
      errorResponse(() => setPid(""));
      const messageId = getErrorMessageId({ response: response.error });
      const formattedValues = payload && formatValues(payload);
      return setPIDError(messageId ? formatMessage(messageId, formattedValues) : message);
    };
    const onSuccess = (response) => {
      log({ ...ctx, ...response }, "pallet analytics details");
      setPalletSummaryV2State({ palletLabel: value, data: response.data });
      push(ReceivingPath.PALLET_ANALYTICS_SELECT_BOX_CONFIGURATION);
    };

    try {
      const response = await inboundClient.getPalletSummaryV2(warehouseId, value);

      if (response.error) {
        onError(response);
      } else {
        onSuccess(response);
      }
    } catch (error) {
      setPid("");
      handleUnknownError(ctx, error);
    }
  });

  const updatePID = (value: string) => {
    setPid(value);
    setPIDError("");
  };

  const handleChange = genericOnScannerInputChange(pid, updatePID, handleSubmit, "upper");

  const illustrationTitle = formatMessage(WarehouseMessages.palletTitleText);
  const palletIdTitle = formatMessage(palletAnalyticsV2Messages.pidTitle);
  const palletIdPlaceholder = formatMessage(palletAnalyticsV2Messages.pidPlaceholder);
  const palletAnalyticsInstructions = formatMessage(palletAnalyticsV2Messages.palletAnalyticsV2Message);

  return {
    illustrationTitle,
    palletIdTitle,
    palletAnalyticsInstructions,
    pid,
    palletIdPlaceholder,
    loading,
    pidError,
    handleChange,
    handleSubmit,
  };
};
