import { ReturnReceiveReturnOrderState, returnReceiveReturnOrderStateAtom } from "../../state";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { genericOnScannerInputChange, log, logStart, setProp } from "facility-commons";
import { useAsyncFn, useMount } from "react-use";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { COMMON_LABELS } from "warehouse/receiving/components/cards/warehouse.labels";
import { commonReturnReceiveMessage } from "warehouse/receiving/content";
import { createReturnReceiveBarcodeScan } from "../returnReceiveCardCreators";
import { getTrackingCodeFromBarcode } from "@deliverr/commons-utils";
import { uniq } from "lodash";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useInitializeReturnReceiveStates } from "../../hooks";
import { useIntl } from "react-intl";
import { useReturnReceiveFlow } from "../useReturnReceiveFlow";
import { useState } from "react";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";

export const useTrackingCodeScan = () => {
  const { formatMessage } = useIntl();
  const { initializeAllReturnReceiveStates } = useInitializeReturnReceiveStates();
  const { nextFlowCard } = useReturnReceiveFlow();
  const { showModal, hideAllModals } = useWarehouseModal();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { errorResponse, successResponse, resetNotifications } = useCommonFlow();
  const { returnsClient } = useClientsWithAuth();
  const { warehouseId: facilityId } = useRecoilValue(userState);
  const [returnOrderData, setReturnOrderData] = useRecoilState<ReturnReceiveReturnOrderState>(
    returnReceiveReturnOrderStateAtom
  );
  const { trackingCode } = returnOrderData;
  const [trackingCodeError, setTrackingCodeError] = useState("");

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonReturnReceiveMessage.receiveReturnOrder)));
    setWarehouseAppState(setProp("pageSubtitle", ""));
    initializeAllReturnReceiveStates();
  });

  const [submitState, handleTrackingCodeSubmit] = useAsyncFn(
    async (trackingCodeInput: string) => {
      resetNotifications();
      setTrackingCodeError("");

      if (!trackingCodeInput.length) {
        errorResponse();
        return setTrackingCodeError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      }

      const ctx = logStart({
        fn: "useTrackingCodeScan.handleTrackingCodeSubmit",
        trackingCodeInput,
        facilityId,
      });

      try {
        const trimmedTrackingCode = getTrackingCodeFromBarcode(trackingCodeInput);
        const { value } = await returnsClient.startReturnInspection(trimmedTrackingCode, facilityId);

        const { id, returnType, deliverrSellerId, items, inspectedItems, inspectionNotes, status } = value.returnOrder;
        const products = await returnsClient.getProductsByDskus(uniq(items.map((item) => item.dsku)));

        setReturnOrderData((currentState) => ({
          ...currentState,
          trackingCode: trimmedTrackingCode,
          orderId: id,
          returnType: returnType ?? null,
          orderStatus: status,
          sellerId: deliverrSellerId,
          expectedItems: items,
          expectedItemsProductDetails: products,
          inspectedItems: inspectedItems ?? [],
          orderInspectionNotes: inspectionNotes ?? "",
        }));

        if (!deliverrSellerId) {
          return showModal(WarehouseModal.RETURN_RECEIVE_UNCLAIMED_RETURN_WARNING, {
            onConfirm: () => {
              hideAllModals();
              successResponse();
              nextFlowCard(createReturnReceiveBarcodeScan({}));
            },
            onCancel: () => {
              hideAllModals();
              initializeAllReturnReceiveStates();
            },
          });
        }
        successResponse();
        nextFlowCard(createReturnReceiveBarcodeScan({}));
      } catch (error: any) {
        log({ ...ctx, error }, "error fetching return order by tracking code");
        errorResponse();
        setReturnOrderData(setProp("trackingCode", ""));
        setTrackingCodeError(String(error.message));
      }
    },
    [facilityId]
  );

  const updateTrackingCode = (trackingCode: string) => {
    setTrackingCodeError("");
    setReturnOrderData(setProp("trackingCode", trackingCode));
  };

  const handleChange = genericOnScannerInputChange(trackingCode, updateTrackingCode, handleTrackingCodeSubmit);

  const messages = {
    illustrationTitle: formatMessage(commonReturnReceiveMessage.packageScanIllustrationTitle),
    message: formatMessage(commonReturnReceiveMessage.scanOrEnterTrackingCode),
    placeholder: formatMessage(commonReturnReceiveMessage.trackingCode),
    title: formatMessage(commonReturnReceiveMessage.scanTrackingCode),
  };

  return {
    trackingCode,
    isSubmitting: submitState.loading,
    trackingCodeError,
    messages,
    handleChange,
    handleTrackingCodeSubmit,
  };
};
