export function convertBase64StringToBlob(base64String: string): Blob {
  const binaryString = atob(base64String);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const pdfBlob = new Blob([bytes], { type: "application/pdf" });
  return pdfBlob;
}
