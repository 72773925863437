import { IconV2, Text, ThemeProps } from "@deliverr/ui";
import { Appearance, TextAlign } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React from "react";
import { useIntl } from "react-intl";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const IconContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    height: 64px;
    width: 64px;
    background-color: ${theme.colors.NEUTRAL["40"]};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.S5};
    border-radius: 100px;
  `
);

const Title = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
    font-size: ${theme.font.size.F5};
  `
);

const StyledIcon = styled(IconV2)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["200"]};
  `
);

export const TicketCardPlaceholder: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <InnerContainer>
        <IconContainer>
          <StyledIcon type="hourglass" size="large" />
        </IconContainer>
        <Title bold>
          {formatMessage({
            id: "warehouse.ticketCenter.ticketCardPlaceholderTitle",
            defaultMessage: "Nothing here yet",
          })}
        </Title>
        <Text appearance={Appearance.INFO} textAlign={TextAlign.center}>
          {formatMessage({
            id: "warehouse.ticketCenter.ticketCardPlaceholderSubtitle",
            defaultMessage:
              "Receiving instructions will show up here after the seller identifies the product and provides a barcode.",
          })}
        </Text>
      </InnerContainer>
    </Container>
  );
};
