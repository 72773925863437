import { Text } from "@deliverr/ui";
import { ReactComponent as ScanBox } from "facility-commons/common/assets/scanBox.svg";
import { WarehouseMessages } from "facility-commons/utils";
import React from "react";
import { useIntl } from "react-intl";
import { StackedInputCard } from "warehouse/common/components/StackedInputCard";
import { SkipStep } from "warehouse/ticket-center/new/non-compliance/components";
import { boxMessages } from "warehouse/ticket-center/new/non-compliance/content";
import { useCdskuCard } from "./useCdskuCard";

export const CdskuCard = () => {
  const {
    cdsku,
    cdskuError,
    handleChange,
    handleSubmit,
    loading,
    handleOnSkipStep,
    problemSolverFlowEnabled,
  } = useCdskuCard();
  const { formatMessage } = useIntl();

  return (
    <>
      <StackedInputCard
        errorMessage={cdskuError}
        helpText={formatMessage(WarehouseMessages.boxHelpText)}
        illustration={<ScanBox />}
        illustrationTitle={formatMessage(WarehouseMessages.boxScan)}
        message={formatMessage(WarehouseMessages.boxInstructionsText)}
        newest
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={formatMessage(WarehouseMessages.cdsku)}
        loading={loading}
        title={formatMessage(WarehouseMessages.scanCdsku)}
        value={cdsku}
        data-testid="cdsku-card-input"
      >
        {problemSolverFlowEnabled ? (
          <Text size="label" appearance="INFO">
            {formatMessage(boxMessages.cdskuUnknownProblemSolver)}
          </Text>
        ) : (
          <SkipStep message={formatMessage(boxMessages.cdskuUnknown)} onSkip={handleOnSkipStep} />
        )}
      </StackedInputCard>
    </>
  );
};
