import React from "react";
import { Card, NumberStepper, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { SKU_COUNTER_COMPONENT_WIDTH } from "facility-commons";
import { SkuLineItem, SkuLineItemProps } from "./SkuLineItem";

export interface SkuCounterContentsItemProps extends SkuLineItemProps {
  qty: number;
  loading?: boolean;
  maxUnitsPerPackage: number;
  onChange: (barcode: string, qty?: number) => void;
}

const ItemCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  width: ${SKU_COUNTER_COMPONENT_WIDTH};
  padding: ${theme.spacing.S5} ${theme.spacing.S4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${theme.border.radius.R5}
`
);

export const SkuCounterContentsItem: React.FC<SkuCounterContentsItemProps> = ({
  barcode,
  description,
  qty,
  loading,
  maxUnitsPerPackage,
  delimiter,
  onChange,
}) => (
  <ItemCard>
    <SkuLineItem barcode={barcode} description={description} delimiter={delimiter} />
    <NumberStepper
      value={qty}
      onChange={(newQty: number) => onChange(barcode, newQty)}
      increment={1}
      min={1}
      max={maxUnitsPerPackage}
      editableInput
      disabled={loading}
    />
  </ItemCard>
);
