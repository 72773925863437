import React from "react";
import { useIntl } from "react-intl";
import { PromptModal, loadingModalIconXl, successModalIconLg } from "@deliverr/ui-facility";
import { printLabelCardLabels } from "./printLabelCardLabels";
import { usePrintLabelCard } from "./usePrintLabelCard";
import { printPdf } from "facility-commons";

export const PrintLabelCard = () => {
  const { formatMessage } = useIntl();
  const { pdfBlob, isLoading, onConfirmClick } = usePrintLabelCard();
  const iconProps = isLoading ? loadingModalIconXl : successModalIconLg;

  return (
    <PromptModal
      title={formatMessage(printLabelCardLabels.printingText)}
      text={formatMessage(printLabelCardLabels.applyLabelText)}
      iconProps={iconProps}
      cancelButtonLabel={formatMessage(printLabelCardLabels.reprintLabelText)}
      onCancelClick={() => pdfBlob && printPdf(pdfBlob)}
      confirmButtonLabel={formatMessage(printLabelCardLabels.doneText)}
      onConfirmClick={onConfirmClick}
    />
  );
};
