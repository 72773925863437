import React, { ComponentType, FC, useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, useHistory } from "react-router-dom";
import { CenterSpinner } from "facility-commons/components";
import { intersection } from "lodash";
import { appNameToRouteMap } from "facility-commons/types";
import { formRedirectUrl } from "./AuthUtils";
import { ROLES } from "facility-commons/types/Auth";
import { log } from "facility-commons/utils";

export const ProtectedRoute = ({ component, apps, ...args }: React.PropsWithChildren<any>) => (
  <Route
    component={withAuthenticationRequired(withRoleBasedRedirect(component, { apps }), {
      onRedirecting: () => <CenterSpinner loading />,
    })}
    {...args}
  />
);

const DELIVERR_URL = "https://deliverr.com/";

const getClaimsFromLocalStorage = () =>
  JSON.parse(
    window.localStorage.getItem(
      `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::${process.env.REACT_APP_AUTH0_SCOPE}`
    ) ?? ""
  );

export interface WithRoleBasedRedirectOptions {
  apps: string[];
}

export const withRoleBasedRedirect = <P extends object>(
  Component: ComponentType<P>,
  options: WithRoleBasedRedirectOptions
): FC<P> => (props: P): JSX.Element => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const checkRole = async (): Promise<void> => {
    let claims;
    // get auth0 user info from local storage for e2e tests
    // @ts-ignore
    claims = window.Cypress
      ? getClaimsFromLocalStorage().body.decodedToken.user[DELIVERR_URL]
      : (await getIdTokenClaims())[DELIVERR_URL];
    const userApps = [...(claims.apps ?? []), ...claims.roles];
    const acceptedApps = [ROLES.WAREHOUSE_ADMIN_ROLE, ...options.apps];
    const canAccessApp = intersection(userApps, acceptedApps).length > 0;

    const ctx = {
      fn: "withRoleBasedRedirect",
      userApps,
      acceptedApps,
      canAccessApp,
      email: claims.email,
    };
    // if user is authenticated and has access to the app pass them through
    // else if user is authenticated but does not have access to the app, reroute them to appropriate app
    if (canAccessApp) {
      setIsAuthorized(true);
    } else {
      log(ctx, "User does not have access to this app, rerouting to appropriate app");
      const validRerouteUrl = [appNameToRouteMap[userApps[0]]].filter(Boolean);

      if (validRerouteUrl.length) {
        const reroute = formRedirectUrl(validRerouteUrl);
        window.location.assign(reroute);
      } else {
        log(ctx, "User does not have access to any apps");
      }
    }
  };

  useEffect(() => {
    checkRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdTokenClaims, history]);

  return isAuthorized ? (
    <Component {...props} />
  ) : (
    <div>
      You do not have access to any applications. Contact your operations manager or engineering to get this resolved
    </div>
  );
};
