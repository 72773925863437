import { useState } from "react";
import { useIntl } from "react-intl";
import { useRecoilState } from "recoil";
import { useAsyncFn, useLifecycles } from "react-use";

import { createSuccessNotification, logStart } from "facility-commons/utils";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { SoundFx } from "facility-commons/common/sfx";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { STORAGE_ARRIVAL_SCAN_MESSAGES, STORAGE_RECEIVING_MESSAGES } from "warehouse/common/messages/storage";
import { storageInboundReceivingStateAtom } from "../StorageReceivingState";
import {
  createReceivingSKUCaseInfo,
  createReceivingSKUCasePackSelection,
  createReceivingSKULC,
  createReceivingSKUQuantity,
} from "../storageReceivingCardCreators";
import { StorageFlowButtonType } from "warehouse/common/flow/types/StorageFlowButtonType";
import { BLIND_INBOUND_SELLERID } from "../blindInboundsData";

export const useReceivingSKULPScanFlow = () => {
  const { formatMessage } = useIntl();
  const { resetNotifications } = useCommonFlow();
  const [scanError, setScanError] = useState<string>("");
  const [scanState, setScanState] = useRecoilState(storageInboundReceivingStateAtom);
  const [licensePlate, setLicensePlate] = useState<string>(scanState.currentSkuDetails?.LP ?? "");
  const { addFlowCard, hideAllFlowButtons, showFlowButton, transition } = useWarehouseFlow();
  const { showModal } = useWarehouseModal();
  const acceptableSellerIdsForCasePackConfig: string[] = Object.values(BLIND_INBOUND_SELLERID);
  const isBlindInbound = acceptableSellerIdsForCasePackConfig.includes(scanState.sellerId);

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButton(StorageFlowButtonType.STORAGE_SKU_LP_RECEIVING_BACK_BUTTON);
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  const [{ loading }, handleSubmit] = useAsyncFn(async (lp) => {
    setScanError("");
    const handleSubmitLPScan = async (licensePlateValue) => {
      const ctx = { fn: "useReceivingSKULPScanFlow.handleSubmitLPScan", poNum: scanState.poNum };
      logStart(ctx);
      try {
        if (!licensePlateValue) {
          showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
            title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
            text: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.errorEmptyValue),
          });
          setScanError("LPN is required.");
        } else {
          setScanState((state) => {
            const updatedState = { ...state };
            updatedState.currentSkuDetails = { ...state.currentSkuDetails, LP: licensePlateValue };
            return updatedState;
          });
          transition([
            {
              card: createReceivingSKULC({}),
              notification: createSuccessNotification(formatMessage(STORAGE_RECEIVING_MESSAGES.skuScanLPSuccess)),
              sfx: SoundFx.SUCCESS,
              flash: "SUCCESS",
            },
          ]);
        }
      } catch (err: any) {
        showModal(WarehouseModal.STORAGE_PROMPT_MODAL, {
          title: formatMessage(STORAGE_ARRIVAL_SCAN_MESSAGES.scanErrorUnrecognisedDescription),
          text: String(err),
        });
      }
    };

    return handleSubmitLPScan(lp);
  });

  const [{ loading: backLoading }, handleBackClick] = useAsyncFn(async () => {
    const handleBackReceiveSKUQtyScan = async () => {
      if (isBlindInbound) {
        addFlowCard(createReceivingSKUCasePackSelection({}));
      } else if (scanState.currentSkuDetails.isNewChildProductCreated) {
        addFlowCard(createReceivingSKUCaseInfo({}));
      } else {
        addFlowCard(createReceivingSKUQuantity({}));
      }
    };

    return handleBackReceiveSKUQtyScan();
  });

  const handleClick = () => {
    setScanError("");
    handleSubmit(licensePlate ?? "");
  };

  return {
    licensePlate,
    scanError,
    setLicensePlate,
    handleSubmit,
    handleClick,
    handleBackClick,
    loading,
    backLoading,
  };
};
