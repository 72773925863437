import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { genericOnScannerInputChange, isValidCdsku, log, logError, logStart, setProp } from "facility-commons/utils";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useLifecycles, useMount } from "react-use";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { WarehouseModal, useWarehouseModal } from "warehouse/modal";
import {
  cdskuState,
  currentSkuState,
  isMultiSkuState,
  isRerouteFromReceivingState,
  palletReceiveState,
  receiveFlowTypeState,
  receivingMisMatchState,
  receivingState,
} from "warehouse/receiving/ReceivingState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import {
  createConsolidationUnexpectedSkuNotification,
  createUnexpectedSkuNotification,
  useReceivingFlow,
} from "warehouse/receiving/base";
import { commonBoxMessages } from "warehouse/receiving/content";
import { useClearSkuData, useReceivingButtons } from "warehouse/receiving/hooks";
import { ReceivingPath } from "warehouse/receiving/routes";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";
import { isBoxReceiveFlow, isConsolidationFlow } from "warehouse/receiving/utils/receivingFlowType";
import { WarehousePortalRoutes } from "warehouse/routes";
import { currentProductLotFefoDetailsState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";
import { modalsText } from "../../modals/modalsText";
import { COMMON_LABELS } from "../warehouse.labels";
import { isElevatedUser } from "warehouse/receiving/ReceivingState/isElevatedUser";

export const useSkuCard = () => {
  const { productClient, inboundClient } = useClientsWithAuth();
  const [skuError, setSkuError] = useState("");
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [palletReceiveData] = useRecoilState(palletReceiveState);
  const isElevatedUserReceiving = useRecoilValue(isElevatedUser);

  const router = useRouter();
  const { handleUnknownError, receivedUnits, updateCurrentSku } = useReceivingFlow();
  const {
    asnId,
    expectedSkus,
    receivedSkus,
    requestBatchId,
    consolidationLabel,
    isReceiveWithoutCdsku,
    wasReceived,
  } = receivingData;
  const { palletLabel, isBulkPalletReceiveEligible } = palletReceiveData;

  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();
  const { generateBarcodeScanButtons, resetReceivingButtons } = useReceivingButtons();
  const clearSkuData = useClearSkuData();
  const { warehouseId } = useRecoilValue(userState);
  const { errorResponse, successResponse, resetNotifications, addNotification } = useCommonFlow();
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const isCDSKUFlow = isBoxReceiveFlow(receivingFlow);
  const isConsolidation = isConsolidationFlow(receivingFlow);

  const { hideAllModals, showModal } = useWarehouseModal();
  const { barcode, cdsku } = useRecoilValue(currentSkuState);
  const isMultiSku = useRecoilValue(isMultiSkuState);
  const setLotFefoDetailsState = useSetRecoilState(currentProductLotFefoDetailsState);
  const [isMismatch, setIsMismatch] = useRecoilState(receivingMisMatchState);
  const setIsRerouteFromReceiving = useSetRecoilState(isRerouteFromReceivingState);
  const { receiveCount, expectedNumUnitsInBox, isHighRisk: isHighRiskBox } = useRecoilValue(cdskuState);

  enum ConsolidationReceiveError {
    UNEXPECTED_SKUS = "UnexpectedSkuOnAsnError",
  }

  enum LotFefoErrorCodes {
    // Error thrown when a warehouse cannot handle a receive with lot data
    LOTS_NOT_SUPPORTED = "LOTS_NOT_SUPPORTED",
    // Error thrown when a warehouse cannot handle a receive with expiration date
    EXPIRATION_NOT_SUPPORTED = "EXPIRATION_NOT_SUPPORTED",
    // Error thrown when a warehouse cannot handle a receive with expiration date AND lots
    LOTS_EXPIRATION_NOT_SUPPORTED = "LOTS_EXPIRATION_NOT_SUPPORTED",
  }

  const noLotFefoSupportModalTitleMapping: {
    [subcode in LotFefoErrorCodes]: { title: string; message: string };
  } = {
    [LotFefoErrorCodes.LOTS_NOT_SUPPORTED]: {
      title: formatMessage(modalsText.noLotSupportTitle),
      message: formatMessage(modalsText.noLotSupportText),
    },
    [LotFefoErrorCodes.EXPIRATION_NOT_SUPPORTED]: {
      title: formatMessage(modalsText.noExpirySupportTitle),
      message: formatMessage(modalsText.noExpirySupportText),
    },
    [LotFefoErrorCodes.LOTS_EXPIRATION_NOT_SUPPORTED]: {
      title: formatMessage(modalsText.noLotExpirySupportTitle),
      message: formatMessage(modalsText.noLotExpirySupportText),
    },
  };

  const warehouseDoesNotSupportLotFefoErrors = Object.keys(noLotFefoSupportModalTitleMapping);

  useMount(() => {
    clearSkuData();
    // this acts as a check so that users can only go forward in the receive flow but can not
    // go back to the location page using the browser's back button
    // only scanning a new barcode will allow for another receive submission
    setReceivingData(setProp("isNewReceive", false));
  });

  useEffect(() => {
    // isMismatch indicates there was a problem with a lot/exp receive and
    // receiver will be redirected to create a NC ticket
    if (isMismatch) {
      router.push(WarehousePortalRoutes.NC_BARCODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMismatch]);

  useEffect(() => {
    if (isReceiveWithoutCdsku) {
      setWarehouseAppState(setProp("pageSubtitle", formatMessage(commonBoxMessages.missingCdksu)));
    } else if (cdsku && isCDSKUFlow) {
      setWarehouseAppState(setProp("pageSubtitle", cdsku));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdsku, isReceiveWithoutCdsku, isCDSKUFlow]);

  const updateBarcode = (newBarcode: string) => updateCurrentSku("barcode", newBarcode);

  const resetSkuRelatedReceivingData = () => {
    setReceivingData(setProp("previousReceives", []));
    setReceivingData(setProp("isUnexpectedSku", false));
    setReceivingData(setProp("expectedLot", null));
    setReceivingData(setProp("expectedExpirationDate", null));
    setReceivingData(setProp("requireLotInput", false));
    setReceivingData(setProp("requireExpiryInput", false));
    setReceivingData(setProp("recommendedReceivingZone", ""));
    setReceivingData(setProp("dedupKey", ""));
    setReceivingData(setProp("isNewReceive", false));
  };

  // show then hide the buttons needed on review on mount/unmount
  useLifecycles(generateBarcodeScanButtons, resetReceivingButtons);

  // verify sku against box and warehouse, then get description using returned dsku
  const [submitState, handleSubmit] = useAsyncFn(
    // eslint-disable-next-line complexity
    async (scannedValue: string) => {
      const ctx = { fn: "SkuCard.handleSubmit", barcode, scannedValue, warehouseId, skuError };
      logStart(ctx);

      if (!scannedValue.length) {
        return setSkuError(formatMessage(COMMON_LABELS.EMPTY_FIELD_ERROR));
      } else if (isValidCdsku(scannedValue)) {
        updateBarcode("");
        return setSkuError(
          formatMessage(
            {
              id: "warehouse.receiving.inputIsCdsku",
              defaultMessage: "{scannedValue} is a CDSKU. Scan a product barcode, or start over to scan this CDSKU.",
            },
            { scannedValue }
          )
        );
      }
      let requireLotOrExpiryInput: boolean = false;
      const onSuccess = (response) => {
        log(ctx, "sku validated, setting new state", { response });
        const {
          expectedQty,
          receivedQty,
          locationReceivedQty,
          unexpectedSku,
          expectedFefoLotPairs = [],
          isProductLotTrackingEnabled,
          isProductExpiryTrackingEnabled,
          zoneName,
          isSkuOnBox,
          requireElevatedRoleToUnderReceive,
        } = response.data!;

        // For Receiving state
        requireLotOrExpiryInput = isProductLotTrackingEnabled || isProductExpiryTrackingEnabled;
        setReceivingData(setProp("requireLotInput", isProductLotTrackingEnabled));
        setReceivingData(setProp("requireExpiryInput", isProductExpiryTrackingEnabled));
        setReceivingData(setProp("expectedLot", expectedFefoLotPairs[0]?.lotNumber ?? null));
        setReceivingData(setProp("expectedExpirationDate", expectedFefoLotPairs[0]?.expirationDate ?? null));
        // For NC state
        setLotFefoDetailsState(setProp("isLotEnabled", isProductLotTrackingEnabled));
        setLotFefoDetailsState(setProp("isFefoEnabled", isProductExpiryTrackingEnabled));

        if (isCDSKUFlow || isConsolidation) {
          if (isCDSKUFlow) {
            updateCurrentSku("isSkuOnBox", isSkuOnBox);
            setReceivingData(setProp("requireElevatedRoleToUnderReceive", requireElevatedRoleToUnderReceive));
          }
          // used on quantity screen
          updateCurrentSku("previouslyReceivedQuantity", receivedQty);
          setReceivingData(setProp("isUnexpectedSku", unexpectedSku ?? false));
          setReceivingData(setProp("previousReceives", locationReceivedQty));
        }
        // common fields between cdsku and pallet barcode validation response
        updateCurrentSku("expectedQuantity", expectedQty);

        setReceivingData(setProp("recommendedReceivingZone", zoneName));
        setReceivingData(setProp("dedupKey", uuid()));
        setReceivingData(setProp("isNewReceive", true));
      };

      const onFail = (response) => {
        const { subcode, message, payload } = response.error;
        if (warehouseDoesNotSupportLotFefoErrors.includes(subcode)) {
          log({ ...ctx, response }, "warehouse does not support lot/fefo error");
          errorResponse();
          showModal(WarehouseModal.NO_LOT_FEFO_SUPPORT, {
            onCancel: () => {
              hideAllModals();
            },
            onContinue: () => {
              setIsMismatch(true);
              setIsRerouteFromReceiving(true);
              hideAllModals();
            },
            title: noLotFefoSupportModalTitleMapping[subcode].title,
            message: noLotFefoSupportModalTitleMapping[subcode].message,
          });
        } else {
          log({ ...ctx, response }, "Handled error in SKU validation");
          errorResponse();
          const messageId = getErrorMessageId({ response: response.error });
          const formattedValues = payload && formatValues(payload);
          setSkuError(messageId ? formatMessage(messageId, formattedValues) : message);
        }
      };

      try {
        let response;
        switch (receivingFlow) {
          case ReceivingFlowType.BOX_RECEIVING:
            if (isReceiveWithoutCdsku) {
              response = await inboundClient.asnReceiveValidateBarcodeWithRestrictions(
                warehouseId,
                asnId!.toString(),
                scannedValue,
                requestBatchId
              );
            } else {
              response = await inboundClient.receivingAppValidateBarcode(
                warehouseId,
                cdsku,
                scannedValue,
                requestBatchId
              );
            }
            if (response.error) {
              return onFail(response);
            } else {
              onSuccess(response);
            }

            break;
          case ReceivingFlowType.PALLET_RECEIVING:
            if (isBulkPalletReceiveEligible && palletLabel) {
              response = await inboundClient.receivingAppValidateBulkPalletBarcode(
                warehouseId,
                scannedValue,
                requestBatchId,
                palletLabel
              );
            } else {
              response = await inboundClient.receivingAppValidatePalletBarcode(
                warehouseId,
                scannedValue,
                requestBatchId,
                asnId!
              );
            }
            if (response.error) {
              return onFail(response);
            } else {
              onSuccess(response);
            }
            break;
          case ReceivingFlowType.CONSOLIDATION_RECEIVING:
            response = await inboundClient.consolidationValidateBarcodeV2({
              warehouseId,
              requestBatchId,
              consolidationLabel: consolidationLabel!,
              barcode: scannedValue,
            });
            if (response.error) {
              if (response.error.code === ConsolidationReceiveError.UNEXPECTED_SKUS) {
                log({ ...ctx, response }, "unexpected SKUs error");
                showModal(WarehouseModal.UNEXPECTED_SKUS, {
                  errorMsg: response.error.message,
                  onContinue: hideAllModals,
                });
                updateBarcode("");
                return errorResponse();
              } else {
                return onFail(response);
              }
            } else {
              onSuccess(response);
            }
            break;
          default:
            throw new Error("Unknown receive type");
        }
        const { dsku } = await response.data;

        updateCurrentSku("dsku", dsku);
        try {
          // once the barcode is validated we need to get the product description to show on the next page
          const res = await productClient.getProductAuth0(dsku, {
            includeCustomsInformation: false,
            includeProductAliases: false,
            includeHazmatInformation: false,
          });
          if (res?.name) {
            log(ctx, "description fetch success", { productResponse: res?.name, receivingResponse: response });
            updateCurrentSku("description", res.name);
          }
        } catch (err) {
          logError({ ...ctx, productError: err }, new Error("Error from product service"));
        }
        // don't stop the receiving flow b/c of a possible description error

        if (isCDSKUFlow && !isReceiveWithoutCdsku && !response?.data?.isSkuOnBox) {
          if (isElevatedUserReceiving) {
            addNotification(createUnexpectedSkuNotification());
            successResponse();
            return router.push(ReceivingPath.IMAGES);
          }

          return showModal(WarehouseModal.RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL, {});
        }

        if (response?.data?.unexpectedSku) {
          if (isCDSKUFlow) {
            addNotification(createUnexpectedSkuNotification());
          } else if (isConsolidation) {
            return showModal(WarehouseModal.CONSOLIDATION_UNEXPECTED_SKUS, {
              consolidationLabel,
              onExit: () => {
                resetSkuRelatedReceivingData();
                clearSkuData();
                hideAllModals();
              },
              onConfirm: () => {
                addNotification(createConsolidationUnexpectedSkuNotification());
                successResponse();
                hideAllModals();
                if (requireLotOrExpiryInput) {
                  return router.push(ReceivingPath.EXPIRATION_LOT_CONFIRMATION);
                }
                return router.push(ReceivingPath.QUANTITY);
              },
            });
          }
        } else {
          resetNotifications();
        }

        successResponse();

        if (requireLotOrExpiryInput) {
          return router.push(ReceivingPath.EXPIRATION_LOT_CONFIRMATION);
        } else {
          return router.push(ReceivingPath.QUANTITY);
        }
      } catch (error) {
        handleUnknownError(ctx, error);
      }
    },
    [warehouseId, skuError, requestBatchId, asnId, cdsku, receivingFlow, receivingData, isHighRiskBox]
  );

  const handleChange = genericOnScannerInputChange(barcode.toString(), updateBarcode, handleSubmit, "upper");

  return {
    asnId,
    barcode,
    expectedSkus,
    handleChange,
    handleSubmit,
    loading: submitState.loading,
    skusReceivedCount: receivedSkus.length,
    receivedUnits,
    skuError,
    cdsku,
    isConsolidation,
    isCDSKUFlow,
    isMultiSku,
    isHighRiskBox,
    isReceiveWithoutCdsku,
    wasReceived,
    receiveCount,
    expectedNumUnitsInBox,
    formatMessage,
  };
};
