import { defineMessages } from "react-intl";

export const expirationDateMessages = defineMessages({
  unreadableExpirationDate: {
    id: "warehouse.nonCompliance.unreadableExpirationDate",
    defaultMessage: "Can’t read expiration date?",
  },
  expirationDateTitle: {
    id: "warehouse.nonCompliance.expirationDateTitle",
    defaultMessage: "Input expiration date",
  },
  skipExpiryInputConfirmationModalTitle: {
    id: "warehouse.nonCompliance.skipExpiryInputConfirmationModalTitle",
    defaultMessage: "Skip expiration date input?",
  },
});
