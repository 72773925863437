import { defineMessages } from "react-intl";

export const quantityMessages = defineMessages({
  boxesPlaceholder: { id: "warehouse.receiving.boxesQuantityPlaceholder", defaultMessage: "Number of identical boxes" },
  quantityPlaceholder: { id: "warehouse.receiving.skuQuantityPlaceholder", defaultMessage: "Units per box" },
  quantityPlaceholderSingleBox: {
    id: "warehouse.receiving.skuQuantityPlaceholderNoCdsku",
    defaultMessage: "Units in box",
  },
  boxesOnPallet: { id: "warehouse.receiving.boxesPerPallet", defaultMessage: "Number of boxes on pallet" },
  quantityHelpText: {
    id: "warehouse.receiving.replaceQuantity",
    defaultMessage: "Count the total units to replace this value",
  },
  unitAndBoxQuantitiesTitle: {
    id: "warehouse.receiving.skuAndBoxQuantityTitle",
    defaultMessage: "Count total boxes and units",
  },
  unitQuantityTitle: { id: "warehouse.receiving.skuQuantityTitle", defaultMessage: "Count units in box" },
  quantityOfUnits: { id: "warehouse.receiving.numberOfUnits", defaultMessage: "Quantity of units" },
  quantitySubTitle: { id: "warehouse.receiving.receiveBoxContents", defaultMessage: "Receive Box Contents" },
  otherFefoLotUnitsHelpText: {
    id: "warehouse.receiving.otherFefoLotUnitsHelpText",
    defaultMessage: "Other units in this box have a different lot and expiration date.",
  },
});
