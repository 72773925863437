import React from "react";
import { TicketCenterMissingBarcodeCaseResponse } from "@deliverr/commons-clients";
import { useTicketDetails } from "warehouse/ticket-center/common/hooks/useTicketDetails";
import { BarcodeCaseResolutionCard } from "./BarcodeCaseResolutionCard";

export const MissingBarcodeResolutionCard: React.FC = () => {
  const props = useTicketDetails<TicketCenterMissingBarcodeCaseResponse>();

  return (
    <BarcodeCaseResolutionCard
      {...props}
      shouldShowPrintBarcodesButton={!props.ticketDetails?.receivingInfo?.ticketUrl}
      barcode={props.ticketDetails?.receivingInfo?.barcode}
    />
  );
};
