import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { NCRoutes } from "../../base/routes";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { genericOnScannerInputChange, isValidCdsku, log, logStart, setProp } from "facility-commons/utils";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { userState } from "facility-commons/base/Auth/userState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import {
  currentProductLotFefoDetailsState,
  currentProductState,
} from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

export const useBarcodeCard = () => {
  const [barcodeError, setBarcodeError] = useState<string>("");
  const { barcode = "", incidentId } = useRecoilValue(currentProductState);
  const { updateCurrentProduct, handleNonComplianceError, useInitializeFefoNCProduct } = useNonComplianceFlow();
  const { errorResponse, successResponse } = useCommonFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { nonComplianceClient } = useClientsWithAuth();
  const { warehouseId } = useRecoilValue(userState);

  useInitializeFefoNCProduct({ initializeDsku: false });

  const setLotFefoDetailsState = useSetRecoilState(currentProductLotFefoDetailsState);

  const updateBarcode = (newSku: string) => {
    setBarcodeError("");
    updateCurrentProduct("barcode", newSku);
  };

  const proceedToFollowUp = ({ isLotEnabled, isFefoEnabled }) => {
    if (isLotEnabled) {
      push(NCRoutes.LOT);
    } else if (isFefoEnabled) {
      push(NCRoutes.EXPIRATION_DATE);
    } else {
      push(NCRoutes.BCL_UPLOAD);
    }
  };

  const [{ loading }, handleSubmit] = useAsyncFn(
    async (newSku: string) => {
      const ctx = logStart({ fn: "useBarcodeCard.handleSubmit", newSku });
      if (!newSku.length) {
        errorResponse();
        return setBarcodeError(formatMessage(commonWarehouseMessages.fieldRequired));
      }

      // block submission if user scans a cdsku instead of a barcode
      if (isValidCdsku(newSku)) {
        errorResponse();
        return setBarcodeError(formatMessage(commonWarehouseMessages.cdskuScanned, { cdsku: newSku }));
      }

      try {
        // dont block the flow regardless of response
        const response = await nonComplianceClient.nonComplianceIncidentScanBarcode(incidentId, newSku, warehouseId);

        log({ ...ctx, response }, "Barcode scan response");
        successResponse();

        const productDetails = response?.metadata?.productDetails;
        const isLotEnabled = productDetails?.isLotEnabled;
        const isFefoEnabled = productDetails?.isFefoEnabled;

        setLotFefoDetailsState(setProp("isLotEnabled", isLotEnabled));
        setLotFefoDetailsState(setProp("isFefoEnabled", isFefoEnabled));

        proceedToFollowUp({ isLotEnabled, isFefoEnabled });
      } catch (err) {
        handleNonComplianceError(ctx, err);
      }
    },
    [incidentId]
  );

  const handleChange = genericOnScannerInputChange(barcode, updateBarcode, handleSubmit, "upper");

  return {
    barcode,
    barcodeError,
    formatMessage,
    handleChange,
    handleSubmit,
    loading,
    proceedToFollowUp,
  };
};
