import { FlowCardMap } from "facility-commons/flow/types/FlowMaps";
import { StorageReceivingPOScan } from "warehouse/receiving/storage/StorageReceivePOScan";
import { StorageReceivingSKULC } from "warehouse/receiving/storage/StorageReceivingSKULC";
import { StorageReceivingSKUScan } from "warehouse/receiving/storage/StorageReceiveSKUScan";
import { StorageReceivingSKUQuantity } from "warehouse/receiving/storage/StorageReceivingSKUQuantity";
import { StorageReceivingSKULP } from "warehouse/receiving/storage/StorageReceivingSKULPScan";
import { StorageReceivingSKUConfirmation } from "warehouse/receiving/storage/StorageReceiveConfirm";
import { StorageFlowCardType } from "../types/StorageFlowCardType";
import { StorageReceivingLotInfo } from "warehouse/receiving/storage/StorageReceivingLotInfo";
import { StorageReceivingSKUCaseInfo } from "warehouse/receiving/storage/StorageReceivingSKUCaseInfo";
import { StorageReceivePOSelectionStep } from "warehouse/receiving/storage/StorageReceivePOSelectionStep";
import { StorageReceivingTicketCenter } from "warehouse/receiving/storage/StorageReceivingTicketCenter";
import { StorageReceivingPalletCount } from "warehouse/receiving/storage/StorageReceivingPalletCount";
import { StorageReceivingSKUCasePackSelection } from "warehouse/receiving/storage/StorageReceivingSKUCasePackSelection";

export const storageFlowCardMap: FlowCardMap = {
  [StorageFlowCardType.STORAGE_RECEIVING_PO_SCAN]: StorageReceivingPOScan,
  [StorageFlowCardType.STORAGE_RECEIVING_PALLET_COUNT]: StorageReceivingPalletCount,
  [StorageFlowCardType.STORAGE_INBOUND_PO_SELECTION_STEP]: StorageReceivePOSelectionStep,
  [StorageFlowCardType.STORAGE_REPORT_PROBLEM_SELECTION]: StorageReceivingTicketCenter,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU]: StorageReceivingSKUScan,
  [StorageFlowCardType.STORAGE_RECEIVING_LOT_DETAILS]: StorageReceivingLotInfo,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_QUANTITY]: StorageReceivingSKUQuantity,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_CASE_INFO]: StorageReceivingSKUCaseInfo,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_CASE_PACK_SELECTION]: StorageReceivingSKUCasePackSelection,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_LP]: StorageReceivingSKULP,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_LC]: StorageReceivingSKULC,
  [StorageFlowCardType.STORAGE_RECEIVING_SKU_CONFIRMATION]: StorageReceivingSKUConfirmation,
};
