import { defineMessages, MessageDescriptor } from "react-intl";

export enum InvalidIdentifierErrorMessageKey {
  INVALID_IDENTIFIER = "INVALID_IDENTIFIER",
}

export const INVALID_IDENTIFIER_ERROR_BY_ERROR_CODE: Record<
  InvalidIdentifierErrorMessageKey,
  MessageDescriptor
> = defineMessages({
  [InvalidIdentifierErrorMessageKey.INVALID_IDENTIFIER]: {
    id: "warehouse.receiving.invalidIdentifierError",
    defaultMessage: `The following {idType} identifiers are invalid: {ids}.`,
  },
});
