import { useEffect, useState } from "react";
import { useAsyncFn, useMount } from "react-use";
import { useIntl } from "react-intl";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { genericOnScannerInputChange, logStart, log, setProp } from "facility-commons/utils";
import { useReceivingFlow, createReceiveRestartedNotification } from "warehouse/receiving/base";
import { locationMessages } from "warehouse/receiving/content/location.labels";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  currentSkuState,
  isExpeditedFlowEligibleState,
  receivingState,
  defaultLocationState,
  isDefaultLocationReceiveState,
  receiveFlowTypeState,
} from "warehouse/receiving/ReceivingState";
import { ReceivingFlowType, SkuReceivingDetails } from "warehouse/receiving/ReceivingState/Types";
import { commonMessages } from "facility-commons/labels";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useRouter } from "facility-commons/hooks";
import { WarehousePortalRoutesNames } from "warehouse/routes";
import { commonReceivingMessages } from "warehouse/receiving/content";
import {
  DEFAULT_MISSING_LOT_EXPIRY_LOCATION,
  getDefaultLocationFromLocalStorage,
  setDefaultLocationToLocalStorage,
} from "warehouse/receiving/utils";
import { isFeatureOn } from "facility-commons";
import { WarehouseFeature } from "warehouse/base/WarehouseFeature";
import { ReceivingPath } from "warehouse/receiving/routes";
import { isMissingLotOrExpiryConsolidationReceive } from "warehouse/receiving/ReceivingState";

// eslint-disable-next-line complexity
export const useLocationCardV2 = () => {
  const [defaultLocationData, setDefaultLocationData] = useRecoilState(defaultLocationState);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [defaultLocationInput, setDefaultLocationInput] = useState("");
  const currentSku = useRecoilValue(currentSkuState);
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const isDefaultLocationReceive = useRecoilValue(isDefaultLocationReceiveState);
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const shouldSetDefaultMissingLotOrExpiryLocation = useRecoilValue(isMissingLotOrExpiryConsolidationReceive);

  const { push } = useRouter();

  const { isSettingDefaultLocation } = defaultLocationData;

  const defaultLocation = getDefaultLocationFromLocalStorage();

  const { previouslyReceivedQuantity, quantity, location: receivingLocation } = currentSku;

  const { receivingError, identicalBoxes, previousReceives, recommendedReceivingZone, isNewReceive } = receivingData;

  const { errorResponse, successResponse, addNotification } = useCommonFlow();
  const { formatMessage } = useIntl();
  const isExpeditedBulkFlowEligible = useRecoilValue(isExpeditedFlowEligibleState);

  const { receiveUnits, updateCurrentSku } = useReceivingFlow();

  const showRecommendedReceiveZone = isFeatureOn(WarehouseFeature.SHOW_RECOMMENDED_RECEIVE_ZONE);

  useMount(() => {
    if (!isSettingDefaultLocation && !isNewReceive && receivingFlow) {
      addNotification(createReceiveRestartedNotification(receivingFlow));
      switch (receivingFlow) {
        case ReceivingFlowType.BOX_RECEIVING:
          return push(ReceivingPath.CDSKU);
        case ReceivingFlowType.PALLET_RECEIVING:
          return push(ReceivingPath.PALLET);
        case ReceivingFlowType.CONSOLIDATION_RECEIVING:
          return push(ReceivingPath.CONSOLIDATION);
      }
    }
    if (shouldSetDefaultMissingLotOrExpiryLocation) {
      updateCurrentSku("location", DEFAULT_MISSING_LOT_EXPIRY_LOCATION);
    } else if (isDefaultLocationReceive && !isSettingDefaultLocation) {
      // pre-populate input and set locationsWithQty if default location receiving feature is on
      updateCurrentSku("location", defaultLocation!);
    } else {
      // by default, when this page is loaded, since we load up the single location view
      // we need to empty locationsWithQty
      // This way, if someone clicks back out of this page but they had a location(s)
      // added they'll need to reallocate the new amount of units
      updateCurrentSku("location", "");
    }
  });

  useEffect(() => {
    if (isExpeditedBulkFlowEligible) {
      setWarehouseAppState(
        setProp(
          "pageSubtitle",
          ` ${formatMessage(commonMessages.box, {
            boxesQty: identicalBoxes,
          })}`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identicalBoxes, isExpeditedBulkFlowEligible]);

  const totalUnitsQty = quantity * identicalBoxes;

  const setDefaultLocation = async () => {
    if (!defaultLocationInput) {
      return setReceivingData(setProp("receivingError", formatMessage(locationMessages.invalidLocation)));
    }

    setDefaultLocationToLocalStorage(defaultLocationInput);
    setDefaultLocationData(setProp("isSettingDefaultLocation", false));
    successResponse();
    return push(WarehousePortalRoutesNames.RECEIVING);
  };

  const [submitState, submitReceive] = useAsyncFn(async () => {
    const ctx = { fn: "useLocationCardV2.submitReceive", location: receivingLocation, isDefaultLocationReceive };
    if (!receivingLocation) {
      return setReceivingData(setProp("receivingError", formatMessage(locationMessages.invalidLocation)));
    }

    log({ ...ctx }, `Default Location feature used: ${isDefaultLocationReceive}`);

    const response = await receiveUnits();
    if (response?.error) {
      const { message } = response.error;
      log({ ...ctx, response }, "Handled error in location validation");
      errorResponse(() => {
        if (!shouldSetDefaultMissingLotOrExpiryLocation) {
          updateCurrentSku("location", "");
        }
      });
      setReceivingData(setProp("receivingError", message));
    } else {
      log({ ...ctx }, "receive submitted");
    }
  }, [receivingLocation]);

  const updateReceivingLocation = (value: string) => {
    setReceivingData(setProp("receivingError", ""));

    if (!value.length) {
      updateCurrentSku("location", "");
    }

    updateCurrentSku("location", value);
  };

  const updateDefaultLocation = (value: string) => {
    if (!value.length) {
      setDefaultLocationInput("");
    }

    setDefaultLocationInput(value);
  };

  // The submit fn on scan is a no-op
  // 1. To avoid race condition of submitting receive before location is set to state
  // 2. To prevent the possibility of scanner double triggering submit
  // Users must manual click the submit button or press enter to submit the form
  const handleChange = isSettingDefaultLocation
    ? genericOnScannerInputChange(defaultLocationInput, updateDefaultLocation, async (input: string) => {})
    : genericOnScannerInputChange(receivingLocation, updateReceivingLocation, async (input: string) => {});

  const confirmReceive = formatMessage(commonReceivingMessages.confirmReceive);
  const title = formatMessage({
    id: "warehouse.receiving.enterOrScanLocation",
    defaultMessage: "Scan or enter location",
  });
  const enterLocation = formatMessage({ id: "warehouse.receiving.enterLocation", defaultMessage: "Enter Location" });

  const zoneRecommendationMsg = recommendedReceivingZone
    ? formatMessage(commonReceivingMessages.zoneRecommendationMsg, {
        zone: recommendedReceivingZone,
      })
    : formatMessage(commonReceivingMessages.noZoneRecommendationMsg);

  const save = formatMessage(commonMessages.save);

  // Disable if no locations were added
  const disableConfirmBtn = isSettingDefaultLocation ? !defaultLocationInput : !receivingLocation;

  const submitButtonText = isSettingDefaultLocation ? save : confirmReceive;
  return {
    formatMessage,
    handleChange,
    loading: submitState.loading,
    locationInput: isSettingDefaultLocation ? defaultLocationInput : receivingLocation,
    receivingError,
    previouslyReceivedQuantity,
    disableConfirmBtn,
    submitButtonText,
    title,
    enterLocation,
    totalUnitsQty,
    previousReceives,
    isSettingDefaultLocation,
    showRecommendedReceiveZone,
    zoneRecommendationMsg,
    disableLocationInput: shouldSetDefaultMissingLotOrExpiryLocation,
    submitReceive,
    setDefaultLocation,
  };
};
