import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useLifecycles } from "react-use";
import { useRecoilState } from "recoil";
import { rsOutboundsStateAtom } from "warehouse/rs-outbounds/base/RSOutboundsState";
import { useWarehouseFlow } from "warehouse/common/flow/useWarehouseFlow";
import { sum } from "lodash";
import { RSOutboundsFlowButtonType } from "warehouse/common/flow/types/RSOutboundsFlowButtonType";
import { boxSizeLabelsMap } from "./selectBoxSizeCardLabels";
import { useIntl } from "react-intl";
import { RSOutboundsBoxSize } from "warehouse/rs-outbounds/base/RSOutboundsBoxSize";
import { BoxSizeToDimsMap } from "./BoxSizeToDimsMap";

export const useSelectBoxSizeCard = () => {
  const [{ selectedBoxSize, currentPackageDskuToQtyMap }, setScanState] = useRecoilState(rsOutboundsStateAtom);
  const { resetNotifications } = useCommonFlow();
  const { hideAllFlowButtons, showFlowButtons } = useWarehouseFlow();
  const { formatMessage } = useIntl();
  const itemsAdded = sum(Object.values(currentPackageDskuToQtyMap ?? {}));

  const boxSizeOptions = Object.values(RSOutboundsBoxSize).map((boxSize) => ({
    value: boxSize,
    label: formatMessage(boxSizeLabelsMap[boxSize], { ...BoxSizeToDimsMap[boxSize] }),
  }));

  const onBoxSizeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value as RSOutboundsBoxSize;
    setScanState((prevState) => ({ ...prevState, selectedBoxSize: value }));
  };

  useLifecycles(
    () => {
      hideAllFlowButtons();
      showFlowButtons(
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_SELECT_BOX_SIZE_CONTINUE,
        RSOutboundsFlowButtonType.RS_OUTBOUNDS_SELECT_BOX_SIZE_BACK
      );
    },
    () => {
      resetNotifications();
      hideAllFlowButtons();
    }
  );

  return {
    itemsAdded,
    selectedBoxSize,
    boxSizeOptions,
    onBoxSizeChange,
  };
};
