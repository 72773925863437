import React from "react";
import { Button, ButtonProps, DefaultTheme, Text, ToastContainer } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ToastContainerProps } from "react-toastify";

import { MarginedWarehouseCard, SliderComponent } from "warehouse/common/components";
import { useOutboundCapacity } from "./useOutboundCapacity";
import { FormattedMessage } from "react-intl";
import { LoadingSpinner } from "facility-commons/components";

const Value = styled.span<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    display: block;
    margin: ${theme.spacing.S2} 0;
    font-size: ${theme.font.size.F7};
    color: ${theme.colors.NEUTRAL[500]};
    font-family: ${theme.font.family.TITLE};
    font-weight: ${theme.font.weight.BOLD};
  `
);

const AdjustmentsContainer = styled(Text)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.S3};
`;

interface AdjustmentUnitsProps {
  diff: number;
}

const AdjustmentUnits = styled.span<AdjustmentUnitsProps, DefaultTheme>(
  ({ diff, theme }) => `
    margin-left: auto;
    color: ${diff === 0 ? "black" : diff > 0 ? theme.colors.GREEN[400] : theme.colors.RED[400]};
`
);

const LiftedToast = styled(ToastContainer)<ToastContainerProps, DefaultTheme>(
  ({ theme }) => `
  > .Toastify__toast {
    margin: 0 ${theme.spacing.S4};
    min-width: calc(100% - ${theme.spacing.S6});
    bottom: 10rem;
    font-size: ${theme.font.size.F1};
    font-family: ${theme.font.family.STANDARD};
  }
`
);

const BottomButton = styled(Button)<ButtonProps, DefaultTheme>`
  margin-top: ${({ theme }) => theme.spacing.S6};
  max-width: 470px;
`;

export const OutboundCapacity: React.FC = () => {
  const {
    isLoading,
    isError,
    isSaving,
    initialCapacity,
    capacityData,
    cutoffTime,
    sliderValue,
    onSliderChange,
    handleSubmitCapacity,
    min,
    max,
    diff,
  } = useOutboundCapacity();

  if (isLoading) {
    return <LoadingSpinner loading />;
  }

  if (isError) {
    return (
      <Text>
        <FormattedMessage
          id="warehouse.outboundCapacity.capacityDataError"
          defaultMessage="A server error has occurred. Please try again."
        />
      </Text>
    );
  }

  if (capacityData && initialCapacity) {
    return (
      <>
        <MarginedWarehouseCard bottomMargin={true}>
          <Text appearance="INFO" size="label">
            <FormattedMessage
              id="warehouse.outboundCapacity.currentUnits"
              defaultMessage="Units you can handle today <value>{sliderValue}</value>(original value of {originalUnits}/day)"
              values={{
                value: (chunks) => <Value>{chunks}</Value>,
                sliderValue,
                originalUnits: initialCapacity,
              }}
            />
          </Text>
        </MarginedWarehouseCard>
        <MarginedWarehouseCard>
          <AdjustmentsContainer>
            <FormattedMessage
              id="warehouse.outboundCapacity.adjustmentUnits"
              defaultMessage="Adjust by<color>{diff} units</color>"
              values={{
                color: (chunks) => <AdjustmentUnits diff={diff}>{chunks}</AdjustmentUnits>,
                diff,
              }}
            />
          </AdjustmentsContainer>
          <Text appearance="INFO" size="caption">
            <FormattedMessage
              id="warehouse.outboundCapacity.info"
              defaultMessage="You can only adjust to a maximum of {max} and a minimum of {min} units. Adjustments reset at {cutoffTime} every day. Any adjustments made after {cutoffTime} will be applied to the next day."
              values={{
                min,
                max,
                cutoffTime,
              }}
            />
          </Text>
          <SliderComponent
            min={min}
            max={max}
            step={Math.floor((max - min) / 10)}
            startValue={capacityData!.unitLimit}
            middleValue={initialCapacity}
            onSliderChange={onSliderChange}
            trackHighlighting={true}
          />
        </MarginedWarehouseCard>
        <LiftedToast closeButton={false} />
        <BottomButton block onClick={handleSubmitCapacity} disabled={isSaving}>
          <FormattedMessage id="warehouse.outboundCapacity.button" defaultMessage="Save" />
        </BottomButton>
      </>
    );
  }

  return (
    <Text>
      <FormattedMessage
        id="warehouse.outboundCapacity.noCapacityDataError"
        defaultMessage="This feature is currently unavailable. Please try again later."
      />
    </Text>
  );
};
