import { FlowButtonMap } from "facility-commons/flow/types";
import { StorageSKULPReceivingBackButton } from "warehouse/receiving/storage/StorageReceivingSKULPScan";
import { StorageSKULCReceivingBackButton } from "warehouse/receiving/storage/StorageReceivingSKULC";
import {
  StorageReceivingSKUCaseInfoBackButton,
  StorageReceivingSKUCaseInfoContinueButton,
} from "warehouse/receiving/storage/StorageReceivingSKUCaseInfo";
import {
  StorageReceivingLotInfoBackButton,
  StorageReceivingLotInfoContinueButton,
} from "warehouse/receiving/storage/StorageReceivingLotInfo";
import {
  StorageReceivingSKUQtyContinueButton,
  StorageSKUQuantityReceivingBackButton,
} from "warehouse/receiving/storage/StorageReceivingSKUQuantity";
import {
  StorageConfirmReceiveButton,
  StorageConfirmBackButton,
} from "warehouse/receiving/storage/StorageReceiveConfirm";
import { StorageReceivingPalletCountContinueButton } from "warehouse/receiving/storage/StorageReceivingPalletCount";
import { StorageFlowButtonType } from "../types/StorageFlowButtonType";
import {
  StorageReceivingSKUCasePackSelectionBackButton,
  StorageReceivingSKUCasePackSelectionContinueButton,
} from "warehouse/receiving/storage/StorageReceivingSKUCasePackSelection";
import { StorageReceivingSKUScanDoneButton } from "warehouse/receiving/storage/StorageReceiveSKUScan/StorageReceivingSKUScanDoneButton";

export const storageFlowButtonMap: FlowButtonMap<StorageFlowButtonType> = {
  STORAGE_CONTINUE_PALLET_COUNT_RECEIVING: StorageReceivingPalletCountContinueButton,
  STORAGE_PO_DONE_RECEIVING: StorageReceivingSKUScanDoneButton,
  STORAGE_CONTINUE_SKU_QUANTITY_RECEIVING: StorageReceivingSKUQtyContinueButton,
  STORAGE_SKU_QUANTITY_RECEIVING_BACK_BUTTON: StorageSKUQuantityReceivingBackButton,
  STORAGE_SKU_CASE_INFO_RECEIVING_CONTINUE_BUTTON: StorageReceivingSKUCaseInfoContinueButton,
  STORAGE_SKU_CASE_INFO_RECEIVING_BACK_BUTTON: StorageReceivingSKUCaseInfoBackButton,
  STORAGE_SKU_CASE_PACK_SELECTION_CONTINUE_BUTTON: StorageReceivingSKUCasePackSelectionContinueButton,
  STORAGE_SKU_CASE_PACK_SELECTION_BACK_BUTTON: StorageReceivingSKUCasePackSelectionBackButton,
  STORAGE_LOT_INFO_RECEIVING_BACK_BUTTON: StorageReceivingLotInfoBackButton,
  STORAGE_LOT_INFO_RECEIVING_CONTINUE_BUTTON: StorageReceivingLotInfoContinueButton,
  STORAGE_SKU_LP_RECEIVING_BACK_BUTTON: StorageSKULPReceivingBackButton,
  STORAGE_SKU_LC_RECEIVING_BACK_BUTTON: StorageSKULCReceivingBackButton,
  STORAGE_CONFIRM_RECEIVE: StorageConfirmReceiveButton,
  STORAGE_SKU_CONFIRM_RECEIVING_BACK_BUTTON: StorageConfirmBackButton,
};
