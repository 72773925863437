import { ImageType } from "@deliverr/legacy-inbound-client";
import { setProp } from "facility-commons";
import { useRouter } from "facility-commons/hooks";
import { FieldValues, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentSkuState, receivingState } from "warehouse/receiving/ReceivingState";
import { commonBoxMessages, commonReceivingMessages } from "warehouse/receiving/content";
import { ReceivingPath } from "warehouse/receiving/routes";

export const useReceivingImageUploadCard = () => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const formMethods = useForm();
  const { register, handleSubmit, watch, formState } = formMethods;
  const errors = formState.errors;

  const { isSkuOnBox } = useRecoilValue(currentSkuState);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);

  const { requireLotInput, requireExpiryInput } = receivingData;

  const imagesToUpload = [ImageType.BOX_LABEL, ImageType.UNIT_BARCODE, ImageType.ALL_UNITS];

  const messages = {
    uploadImages: formatMessage(commonReceivingMessages.uploadImages),
    confirm: formatMessage(commonReceivingMessages.confirm),
    required: formatMessage(commonReceivingMessages.required),
    [ImageType.BOX_LABEL]: formatMessage(commonBoxMessages.cdskuLabel),
    [ImageType.UNIT_BARCODE]: formatMessage(commonBoxMessages.skuBarcode),
    [ImageType.ALL_UNITS]: formatMessage(commonBoxMessages.allUnits),
  };

  const saveImagesToState = (formValues: FieldValues) => {
    const images = imagesToUpload.map((imageType) => ({ imageType, s3Key: formValues[imageType] }));
    setReceivingData(setProp("images", images));

    // for unexpected sku's we may still need to capture lot/expiry before capturing quantity
    if (!isSkuOnBox) {
      if (requireLotInput || requireExpiryInput) {
        return router.push(ReceivingPath.EXPIRATION_LOT_CONFIRMATION);
      } else {
        return router.push(ReceivingPath.QUANTITY);
      }
    }

    // for over-receives and under-receives we can go directly to the location screen
    return router.push(ReceivingPath.LOCATION);
  };

  return {
    messages,
    imagesToUpload,
    errors,
    disableButton: !watch(ImageType.BOX_LABEL) || !watch(ImageType.UNIT_BARCODE) || !watch(ImageType.ALL_UNITS),
    formMethods,
    register,
    submitImages: handleSubmit(saveImagesToState),
  };
};
