import { useRouter } from "facility-commons/hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ReceivingPath } from "warehouse/receiving/routes";
import {
  receivingMisMatchState,
  receivingState,
  currentSkuState,
  isRerouteFromReceivingState,
} from "warehouse/receiving/ReceivingState";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { setProp } from "facility-commons/utils";
import { isNull, compact, uniq } from "lodash";
import { SoundFx } from "facility-commons/common/sfx";
import { commonReceivingMessages } from "warehouse/receiving/content";
import { format } from "date-fns";
import { DateFormat } from "facility-commons/utils";
import { getExpirationDateFormattedString } from "warehouse/receiving/utils/getExpirationDateFormattedString";
import { useReceivingFlow } from "warehouse/receiving/base";

export const useMultiConfirmationCard = () => {
  const [lotValueInput, setLotValueInput] = useState<string | null>(null);
  const [expirationDateInput, setExpirationDateInput] = useState<string | null>(null);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const { barcode, description } = useRecoilValue(currentSkuState);
  const [isMismatch, setIsMismatch] = useRecoilState(receivingMisMatchState);
  const setIsRerouteFromReceiving = useSetRecoilState(isRerouteFromReceivingState);
  const { hideModal, showModal } = useWarehouseModal();
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const { errorResponse, playSfx } = useCommonFlow();
  const { useRerouteToLotFefoNC } = useReceivingFlow();

  const { expectedFefoLotPairs } = receivingData;

  // reroutes to NC ticket creation if lot/expiry problem
  useRerouteToLotFefoNC();

  const expirationDateOptions = uniq(
    compact(
      expectedFefoLotPairs.map((pair) => {
        return pair.expirationDate;
      })
    )
  ).concat([formatMessage(commonReceivingMessages.none)]);

  const lotValueOptions = uniq(
    compact(
      expectedFefoLotPairs.map((pair) => {
        return pair.lotNumber;
      })
    )
  ).concat([formatMessage(commonReceivingMessages.none)]);

  const noneOption = formatMessage(commonReceivingMessages.none);

  const getExpirationDateLabel = (expirationDate) => {
    return expirationDate === noneOption
      ? noneOption
      : format(new Date(getExpirationDateFormattedString(expirationDate)), DateFormat.ShortMonthDayYear);
  };

  const getExpirationDateValue = (expirationDate) => {
    return expirationDate === noneOption ? "" : expirationDate;
  };

  const getLotLabel = (lotValue) => {
    return lotValue === noneOption ? noneOption : lotValue;
  };

  const getLotValue = (lotValue) => {
    return lotValue === noneOption ? "" : lotValue;
  };

  const onExpirationDateChange = (e) => {
    const value = e.target.value;
    setExpirationDateInput(value);
  };

  const onLotChange = (e) => {
    const value = e.target.value;
    setLotValueInput(value);
  };

  const onCreateNCTicket = () => {
    setIsMismatch(true);
    setIsRerouteFromReceiving(true);
  };

  const onProblemConfirm = () => {
    setIsMismatch(true);
    setIsRerouteFromReceiving(true);
    hideModal(WarehouseModal.UNEXPECTED_FEFO_LOT);
  };

  const handleReportProblem = () => {
    showModal(WarehouseModal.UNEXPECTED_FEFO_LOT, {
      onContinue: onProblemConfirm,
      onCancel: () => hideModal(WarehouseModal.UNEXPECTED_FEFO_LOT),
    });
  };

  const onConfirm = () => {
    // lot number is case insensitive
    const matchingPair = expectedFefoLotPairs.find((pair) => {
      const expectedExpirationDate = pair.expirationDate ?? "";
      const expectedLotValue = pair.lotNumber?.toLowerCase() ?? "";
      return expectedExpirationDate === expirationDateInput && expectedLotValue === lotValueInput!.toLowerCase();
    });
    if (!matchingPair) {
      errorResponse();
      return handleReportProblem();
    } else {
      setReceivingData(setProp("expirationDate", expirationDateInput));
      setReceivingData(setProp("lotNumber", lotValueInput));
      setReceivingData(setProp("receiveMoreUnitsFromBox", false));
      playSfx(SoundFx.SUCCESS);
      return push(ReceivingPath.QUANTITY);
    }
  };
  const disableButton = isNull(expirationDateInput) || isNull(lotValueInput);

  return {
    barcode,
    description,
    expirationDateOptions,
    lotValueOptions,
    disableButton,
    onConfirm,
    onExpirationDateChange,
    onLotChange,
    onCreateNCTicket,
    formatMessage,
    getExpirationDateLabel,
    getExpirationDateValue,
    getLotLabel,
    getLotValue,
  };
};
