import React from "react";

import { SkuPill } from "../SkuPill";
import { useRecoilValue } from "recoil";
import { receivingState } from "warehouse/receiving/ReceivingState";

export const CurrentSkuPill: React.FC = () => {
  const { expectedSkus, receivedSkus } = useRecoilValue(receivingState);

  const skuNumber = receivedSkus.length + 1;

  return <SkuPill skuNumber={skuNumber} expectedSkus={expectedSkus} />;
};
