import React from "react";
import styled from "styled-components";
import { Config, Message, MessageList } from "react-chatbot-ui";
import { InputBox } from "./InputBox";
import { configContext } from "./configContext";
import { ChatContainer } from "./ChatContainer";
import { Balloon } from "./Balloon";
import { WotUploadItem } from "../File/WotUploadItem";
import { FileMetaDataDTO } from "@deliverr/wot-client";
import { Dropdown } from "./Dropdown";

interface Props {
  messageList: MessageList;
  config?: Config;
  inputBox: boolean;
  inputType: string;
  file?: FileMetaDataDTO;
  onPrev: () => void;
  onNext: () => void;
  buttonsPage: number;
  isOptionsIds?: boolean;
}

const StyledApp = styled.main<Config>`
  width: ${(props) => `${props.width ?? "500px"}`};
  @media only screen and (max-width: 599px) {
    width: ${(props) => `${props.width ?? "400px"}`};
  }
  height: ${(props) => `${props.height ?? "600px"}`};
  display: flex;
  flex-direction: column;
`;

export const ChatBotUI = (props: Props) => {
  const lastMsg: Message = props.messageList[props.messageList.length - 1];
  let boldEndings = false;
  let hasMore = false;
  let lastMsgButtons = lastMsg?.button;
  if ((lastMsg?.button?.length ?? 0) > 10) {
    lastMsgButtons = [...lastMsg!.button!.slice(props.buttonsPage * 10, props.buttonsPage * 10 + 10)];
    boldEndings = true;
    hasMore = true;
  }

  return (
    <configContext.Provider value={props.config ?? null}>
      <StyledApp height={props.config?.height} width={props.config?.width}>
        <ChatContainer messageList={props.messageList.filter(({ button }) => !button)} />
        {props.file ? (
          <WotUploadItem fileMetaData={props.file} />
        ) : props.inputBox ? (
          <InputBox inputType={props.inputType} />
        ) : props.isOptionsIds ? (
          lastMsg?.button && <Dropdown button={lastMsg?.button} />
        ) : (
          lastMsgButtons && (
            <div style={{ backgroundColor: "white" }}>
              <Balloon
                key="virtualKeypadButtons"
                type={lastMsg.type}
                text={lastMsg.text}
                button={lastMsgButtons}
                boldEndings={boldEndings}
                hasMore={hasMore}
                onPrev={props.onPrev}
                onNext={props.onNext}
                onLoadHandler={() => {}}
                buttonsPage={props.buttonsPage}
                url={lastMsg.image}
              />
            </div>
          )
        )}
      </StyledApp>
    </configContext.Provider>
  );
};
