// Input field names, affects service call on backend
export enum OtherNonComplianceField {
  TRACKING_CODE = "trackingCode",
  BARCODE = "barcode",
  CDSKU = "cdsku",
  QUANTITY = "quantity",
  REASON = "reportedNonComplianceReason",
  ADDITIONAL_DETAILS = "notes",
  BARCODE_PHOTO = "barcodePicture",
  BOXLABEL_PHOTO = "boxContentPicture",
  FRONT_PHOTO = "frontPicture",
  BACK_PHOTO = "backPicture",
  EMAIL = "warehouseContactEmail",
}

export enum OtherNonComplianceValidationErrorMessages {
  VALIDATION_ERROR = "Tracking # must have at least 5 characters",
  UNKNOWN = "Unknown error submitting case, please try again later.",
  CDSKU_INVALID = "CDSKU is invalid",
  CDSKU_FORMAT = 'Please scan a valid CDSKU that starts with "CD"',
  BARCODE_INVALID = "Barcode is invalid", // used to re-map error message from commons-clients
  BARCODE_FORMAT = "Invalid barcode it must be DSKU, or UPC/EAN or FNSKU",
  EMPTY_FIELD_ERROR = "This field is required",
  INVALID_QUANTITY_ERROR = "Quantity must be greater than 0",
  INVALID_EMAIL = "Please enter a valid email address",
}
