import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import {
  compositeStream,
  conditionalStream,
  consoleStream,
  datadogErrorTrackingStream,
  datadogLoggerStream,
  LogContext,
  logger,
  minimumLevelStream,
  setGlobalContext,
  setStream,
} from "@deliverr/ui-logging";
import { isZebraScanner } from "facility-commons/utils/zebraUtils";
import { ebCategoryInfo, ebCategoryError } from "./config";
import { getIsValidDataDogEnv } from "./thirdParty/DataDog/DataDog";

export const setLogUser = (user: { email?: string; warehouseId?: string; clientId?: string }) => {
  setGlobalContext({
    user: {
      ...user,
      get fullStorySession() {
        // https://app.fullstory.com/ui/C8DBK/session/<logUser.fullStory>
        return (window?.FS?.getCurrentSession && encodeURIComponent(window.FS.getCurrentSession() || "")) || "";
      },
    },
  });
};

export function initLogStream() {
  setStream(
    compositeStream(
      conditionalStream(() => typeof window !== "undefined" && window.location.host.includes("localhost"))(
        minimumLevelStream("info")(consoleStream)
      ),
      conditionalStream(getIsValidDataDogEnv)(
        compositeStream(datadogLoggerStream(datadogLogs.logger), datadogErrorTrackingStream(datadogRum))
      )
    )
  );
}

initLogStream();

/**
 * Alternatives for the default logging methods
 */

export const logStart = (ctx: LogContext): LogContext => {
  const msg = `starting ${ctx.fn}`;
  logger.info(ctx, msg);
  if (isZebraScanner && window?.EB?.Log) {
    window?.EB?.Log.info(JSON.stringify({ ...ctx, msg }), ebCategoryInfo);
  }
  return ctx;
};

// set the err to any because most placed this is called the error is not
// explicitly typed and leaving it as Error threw a ton of errors when
// updating typescript version
export const logError = (ctx: LogContext, err: any) => {
  logger.error({ ...ctx, err }, `error during ${ctx.fn}`);
  if (isZebraScanner && window?.EB?.Log) {
    window?.EB?.Log.info(JSON.stringify({ ...ctx, err }), ebCategoryError);
  }
};

export const log = (ctx: LogContext, msg: string, payload?: Record<string, unknown>) => {
  logger.info({ ...ctx, ...payload }, msg);
  if (isZebraScanner && window?.EB?.Log) {
    window?.EB?.Log.info(JSON.stringify({ ...ctx, msg, ...payload }), ebCategoryInfo);
  }
};

export default logger;
