import { useState } from "react";
import { useAsyncFn, useMount } from "react-use";
import { useIntl } from "react-intl";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { useRouter } from "facility-commons/hooks";
import {
  createDangerNotification,
  genericOnScannerInputChange,
  isValidCdsku,
  log,
  logStart,
  setProp,
} from "facility-commons/utils";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { WarehousePortalRoutes } from "warehouse/routes";
import { ReceivingPath } from "warehouse/receiving/routes";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { commonPalletMessages, commonReceivingMessages } from "warehouse/receiving/content";
import { useReceivingFlow } from "warehouse/receiving/base";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { receiveFlowTypeState, receivingState, palletReceiveState } from "warehouse/receiving/ReceivingState";
import { ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { userState } from "facility-commons/base/Auth/userState";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { PalletValidationData } from "@deliverr/legacy-inbound-client";
import { COMMON_LABELS } from "../warehouse.labels";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";

enum PalletReceiveError {
  REPEATED_RECEIVES = "PALLET_RECEIVE_REPEATED_RECEIVES",
  ASN_NOT_FORWARDING = "ASN_NOT_FORWARDING",
}

export const usePalletCard = () => {
  const [scannedValueError, setScannedValueError] = useState("");
  const [scannedValue, setScannedValue] = useState("");
  const [receivingData, setReceivingData] = useRecoilState(receivingState);
  const [palletReceiveData, setPalletReceiveData] = useRecoilState(palletReceiveState);
  const setReceivingFlow = useSetRecoilState(receiveFlowTypeState);

  const { warehouseId } = useRecoilValue(userState);
  const { errorResponse, successResponse, addAutoCloseNotification } = useCommonFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const initializeReceivingData = useInitializeReceivingData();
  const { formatMessage } = useIntl();
  const { requestBatchId } = receivingData;
  const { push } = useRouter();
  const { hideAllModals, showModal } = useWarehouseModal();
  const { inboundClient } = useClientsWithAuth();
  const { handleUnknownError, updateCurrentSku } = useReceivingFlow();

  useMount(() => {
    setWarehouseAppState(setProp("pageTitle", formatMessage(commonPalletMessages.receivePalletHeader)));
    initializeReceivingData();
  });

  const updateScannedValue = (scannedValue: string) => {
    setScannedValue(scannedValue);
    setScannedValueError("");
  };
  const hideModalAndResetReceivingState = () => {
    hideAllModals();
    initializeReceivingData();
  };

  const modalProps = {
    onCancel: () => hideModalAndResetReceivingState(), // stay on page for possible rescan
    onContinue: () => {
      hideModalAndResetReceivingState();
      setReceivingFlow(ReceivingFlowType.BOX_RECEIVING);
      push(WarehousePortalRoutes.RECEIVING_CDSKU); // route user to first page of box receive
    },
  };

  const [{ loading }, submitScannedValue] = useAsyncFn(async (scannedValue: string) => {
    if (!scannedValue) {
      errorResponse();
      return setScannedValueError(formatMessage(commonWarehouseMessages.fieldRequired));
    }

    // block submission if user scans a cdsku instead of a barcode
    if (isValidCdsku(scannedValue)) {
      errorResponse();
      setReceivingData(setProp("asnId", undefined));
      return setScannedValueError(formatMessage(commonWarehouseMessages.cdskuScanned, { cdsku: scannedValue }));
    }

    const ctx = logStart({ fn: "usePalletCard.submitAsnId", scannedValue, warehouseId });

    try {
      // the scanned value could be a PO# OR a pallet label
      const response = await inboundClient.receivingAppValidatePallet(warehouseId, requestBatchId, scannedValue);
      if (response.data) {
        log({ ...ctx, response }, "Pallet scan successful");
        const {
          asnLabel,
          numSkus,
          expectedNumOfBoxes,
          numUnitsPerBox,
          isAsnCompleted,
          isBulkPalletReceiveEligible,
        }: PalletValidationData = response.data;

        if (numSkus > 1) {
          // only single box SKUs and forwarding pallets are currently allowed in pallet receiving
          log({ ...ctx, response }, "Pallet not valid for pallet receiving");
          errorResponse();

          // show modal with option to cancel and reset state + stay on page to rescan a pallet id
          // or return to receive type selector page (which also resets state)
          showModal(WarehouseModal.RECEIVING_MIXED_PALLET, modalProps);
          setReceivingData(setProp("asnId", undefined));
        } else if (isAsnCompleted) {
          errorResponse();
          log({ ...ctx, response }, "ASN for pallet has already been received");
          showModal(WarehouseModal.CLOSED_PALLET_ASN, {
            asnLabel,
            onCancel: hideModalAndResetReceivingState,
          });
          setReceivingData(setProp("asnId", undefined));
        } else {
          // success response = Pallet contains only 1 SKU -> push to next page (SKU Card)
          if (isBulkPalletReceiveEligible) {
            setWarehouseAppState(
              setProp("pageSubtitle", `${formatMessage(commonReceivingMessages.palletLabel)}:  ${scannedValue}`)
            );
          } else {
            setReceivingData(setProp("asnId", +scannedValue));
            setWarehouseAppState(
              setProp("pageSubtitle", `${formatMessage(commonReceivingMessages.poNumber)} ${scannedValue}`)
            );
          }
          successResponse();
          setReceivingData(setProp("expectedNumOfBoxes", expectedNumOfBoxes));
          setReceivingData(setProp("numUnitsPerBox", numUnitsPerBox));
          setPalletReceiveData(setProp("isBulkPalletReceiveEligible", isBulkPalletReceiveEligible));
          setPalletReceiveData(setProp("palletLabel", scannedValue));

          push(ReceivingPath.SKU);
        }
      } else if (response.error) {
        const { subcode, message, payload } = response.error;

        setReceivingData(setProp("asnId", undefined));
        // this should be changed  by BE - will be included in response instead of error
        if (subcode === PalletReceiveError.REPEATED_RECEIVES) {
          showModal(WarehouseModal.PARTIALLY_RECEIVED_PALLET, modalProps);
        } else if (subcode === PalletReceiveError.ASN_NOT_FORWARDING) {
          showModal(WarehouseModal.RECEIVING_NOT_CD_PALLET, modalProps);
        } else {
          const messageId = getErrorMessageId({ response: response.error });
          const formattedValues = payload && formatValues(payload);

          setScannedValueError(messageId ? formatMessage(messageId, formattedValues) : message);
        }
      } else {
        // the response should always have either error or data so this should never happen
        handleUnknownError({ ...ctx, response }, new Error("Unexpected error validating ASN scan"));
      }
    } catch (err: any) {
      if (err.response) {
        log({ ...ctx, error: err.response }, "Error validating ASN scan");

        const message = err.response.data?.error?.payload ?? formatMessage(COMMON_LABELS.UNEXPECTED_ERROR);
        errorResponse();
        addAutoCloseNotification(createDangerNotification(message));
        setReceivingData(setProp("asnId", undefined));
      } else {
        handleUnknownError({ ...ctx, err }, new Error("Error validating ASN scan"));
        setReceivingData(setProp("asnId", undefined));
      }
    }
  });

  const handleAsnChange = genericOnScannerInputChange(scannedValue, updateScannedValue, submitScannedValue, "upper");

  // asnId is a number in state but is captured and displayed as a string
  return { scannedValue, scannedValueError, formatMessage, handleAsnChange, loading, submitScannedValue };
};
