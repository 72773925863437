import { defineMessages } from "react-intl";

export const STORAGE_TOOL_PRINT_LPN_MESSAGES = defineMessages({
  boxTitle: {
    id: "storage.tools.printLpn.boxTitle",
    defaultMessage: "Print LPN in bulk",
  },
  boxDescription: {
    id: "storage.tools.printLpn.boxDescription",
    defaultMessage: "Enter count of lpn label to be printed in 2x1 format",
  },
  boxPlaceholder: {
    id: "storage.tools.printLpn.placeholder",
    defaultMessage: "Count of labels",
  },
});

export const STORAGE_TOOL_PO_WORKLIST_MESSAGES = defineMessages({
  boxTitle: {
    id: "storage.tools.poWorklist.boxTitle",
    defaultMessage: "Print Worklist",
  },
  boxDescription: {
    id: "storage.tools.poWorklist.boxDescription",
    defaultMessage: "Enter PO number or Flex ID",
  },
  boxPlaceholder: {
    id: "storage.tools.poWorklist.placeholder",
    defaultMessage: "Enter PO or Flex ID",
  },
});
