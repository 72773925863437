import { useRouter } from "facility-commons/hooks";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import { NCRoutes } from "../../base/routes";
import { useNonComplianceFlow } from "warehouse/ticket-center/new/non-compliance/base/useNonComplianceFlow";
import { currentProductState } from "warehouse/ticket-center/new/non-compliance/NonComplianceState";

import { userState } from "facility-commons/base/Auth/userState";

export const useBclUpload = () => {
  const formHandler = useFormContext();
  const currentProduct = useRecoilValue(currentProductState);
  const { updateCurrentProduct } = useNonComplianceFlow();
  const { push } = useRouter();
  const { formatMessage } = useIntl();

  const { isStorage } = useRecoilValue(userState);

  const proceed = () => push(isStorage ? NCRoutes.QUANTITY : NCRoutes.BARCODE_UPLOAD);
  const onFileChange = (fileName: string) => updateCurrentProduct("boxContentPicture", fileName);

  return {
    formatMessage,
    imageUploaded: !!currentProduct.boxContentPicture,
    onFileChange,
    proceed,
    register: formHandler.register("boxContentPicture"),
  };
};
