import { NonComplianceCaseType, TicketCenterNonComplianceCaseStatus } from "@deliverr/commons-clients";
import { Appearance, DefaultTheme, IconV2, Pill, PillColors, Text, ThemeProps, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useRouteMatch } from "react-router";

import { NonComplianceType } from "@deliverr/commons-clients/lib/non-compliance/NonComplianceIncident";
import { WarehouseMessages } from "facility-commons/utils";
import { useTheme } from "emotion-theming";
import { DashboardListItemContainer } from "facility-commons/components/Dashboard/DashboardListItemContainer/DashboardListItemContainer";
import { isNil } from "lodash";
import { useIntl } from "react-intl";
import { generateListViewDate, ListViewDates } from "warehouse/common/utils";
import { WarehouseDynamicRoutes } from "warehouse/routes";
import { LIST_VIEW_CARD_LABELS } from "./ListViewCard.labels";
import { NonComplianceTypeMessages } from "./NonComplianceTypeMessages";

const mapTypeToUI: Record<NonComplianceCaseType, string> = {
  [NonComplianceCaseType.BARCODE_NOT_IN_SYSTEM]: "Barcode",
};

export interface ListViewCardProps {
  asnId?: number;
  completedDate?: Date;
  createdDate: Date;
  dueDate?: Date;
  id: number;
  displayId: string;
  status: TicketCenterNonComplianceCaseStatus;
  thumbnail?: string;
  type: NonComplianceType;
}

const CardImageContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-right: ${theme.spacing.S3};
    width: 68px;
    height: 76px;
    margin-bottom: ${theme.spacing.S2};
    position: relative;
  `
);

const CardImagePlaceholder = styled.div<ThemeProps>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL["60"]};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
  `
);

const CardImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

const CardTextContainer = styled.div`
  display: grid;
  flex-grow: 1;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 2px;
`;

const InboundInfoText = styled(Text)<ThemeProps>`
  color: ${({ theme }) => theme.colors.NEUTRAL[300]};
`;

interface DateTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  late?: boolean;
}

export const DateText = styled.span<DateTextProps, DefaultTheme>`
  ${({ late, theme }) => {
    return `
    color: ${late ? theme.colors.RED[300] : theme.colors.NEUTRAL[300]}
  `;
  }};
`;

const TicketType = styled.span<Record<string, unknown>, DefaultTheme>`
  color: #a2a7b1;
  display: inline;
  text-align: right;
  font-size: ${({ theme }) => theme.font.size.F1};
`;

const StyledPill = styled(Pill)`
  width: fit-content;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const pillColorByType: Record<NonComplianceType, PillColors> = {
  [NonComplianceType.MISSING_BARCODE]: PillColors.RED,
  [NonComplianceType.UNKNOWN_BARCODE]: PillColors.ORANGE,
  [NonComplianceType.UNEXPECTED_SKU]: PillColors.GRAY, // TODO: change this when we support it. It should stick out as incorrect for now
  [NonComplianceType.UNEXPECTED_CDSKU]: PillColors.GRAY, // 02/08/23 - Added by Justin Griffin to fix the build. Should be corrected by someone knowledgeable
  [NonComplianceType.OTHER]: PillColors.PURPLE,
  [NonComplianceType.INVALID_LOT_EXPIRY]: PillColors.PURPLE,
  [NonComplianceType.DAMAGED_PRODUCT]: PillColors.PURPLE,
  [NonComplianceType.EXPIRED_PRODUCT]: PillColors.PURPLE,
};

export const ListViewCard: React.FC<ListViewCardProps> = ({
  asnId,
  completedDate,
  createdDate,
  dueDate,
  id,
  status,
  thumbnail,
  displayId,
  type,
}) => {
  const match = useRouteMatch();
  const [hasError, setHasError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const theme = useTheme<DefaultTheme>();
  const { formatMessage } = useIntl();

  const allListViewDates: ListViewDates = {
    createdDate,
    completedDate,
    dueDate,
  };

  const isLate = isNil(completedDate) && !isNil(dueDate) && new Date().getTime() > dueDate?.getTime();

  return (
    <DashboardListItemContainer
      to={WarehouseDynamicRoutes.TICKET.parse({
        ...match.params,
        ticketType: type,
        ticketId: id,
      })}
    >
      <CardImageContainer>
        {!hasError && (
          <CardImage
            alt={`thumbnail image for case ${id}`}
            loading="lazy"
            src={`${thumbnail}`}
            onError={() => setHasError(true)}
            onLoad={() => setIsReady(true)}
          />
        )}
        {(!isReady || hasError) && (
          <CardImagePlaceholder>
            <IconV2 type="exclamation-circle" color={theme.colors.NEUTRAL["300"]} />
          </CardImagePlaceholder>
        )}
      </CardImageContainer>
      <CardTextContainer>
        <TitleContainer>
          <Title as="h4" displayAs="h4">
            {formatMessage(LIST_VIEW_CARD_LABELS.displayId, { displayId })}{" "}
          </Title>
          <Text appearance={isLate ? Appearance.DANGER : Appearance.INFO}>
            {generateListViewDate(allListViewDates, status)}
          </Text>
        </TitleContainer>
        <InboundInfoText>
          {asnId
            ? formatMessage(WarehouseMessages.labeledPoNumber, { poNum: asnId })
            : formatMessage(WarehouseMessages.unknown)}
        </InboundInfoText>
        <StyledPill color={pillColorByType[type]}>{formatMessage(NonComplianceTypeMessages[type])}</StyledPill>
      </CardTextContainer>
      <TicketType>{mapTypeToUI[type]}</TicketType>
    </DashboardListItemContainer>
  );
};
