import React from "react";
import { LocalizedMessage } from "@deliverr/ui";
import { TextInputCard } from "./TextInputCard";

export interface ErrorTextCardProps {
  value: string;
  title: LocalizedMessage;
  message?: LocalizedMessage;
  newest?: boolean;
}

export const ErrorTextCard: React.FC<ErrorTextCardProps> = ({ value, title, message, newest }) => (
  <TextInputCard
    icon="exclamation-circle"
    accentColor="RED"
    // TODO this is a temporary fix because this icon specifically is not appearing as the same size as other icons
    // Follow-up task: https://app.asana.com/0/1201719380282170/1201868493516362
    size="lg"
    title={title}
    message={message}
    value={value}
    newest={newest}
    disabled
  />
);
