import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { useRouter } from "facility-commons/hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ReceivingData, ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { receivingState, receiveFlowTypeState } from "warehouse/receiving/ReceivingState";
import { WarehousePortalRoutes } from "warehouse/routes";
import { useState } from "react";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { useMount, useAsyncFn } from "react-use";
import { commonBoxMessages } from "warehouse/receiving/content";
import { useIntl } from "react-intl";
import { genericOnScannerInputChange, log, logStart, setProp, isValidCdsku } from "facility-commons/utils";
import { userState } from "facility-commons/base/Auth/userState";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import { isValidInputLength, MAX_PO_INPUT_LENGTH } from "warehouse/receiving/utils";
import { useReceivingFlow } from "warehouse/receiving/base";
import { useWarehouseModal, WarehouseModal } from "warehouse/modal";
import { ReceivingPath } from "warehouse/receiving/routes";
import { commonWarehouseMessages } from "warehouse/common/messages";
import { useClientsWithAuth } from "facility-commons/hooks/auth";
import { modalsText } from "../../modals/modalsText";
import { formatValues, getErrorMessageId } from "warehouse/receiving/utils/getErrorMsg";

export const usePOCard = () => {
  const [asnIdError, setAsnIdError] = useState("");
  const [receivingData, setReceivingData] = useRecoilState<ReceivingData>(receivingState);
  const { asnId, requestBatchId } = receivingData;
  const { warehouseId } = useRecoilValue(userState);
  const { handleUnknownError } = useReceivingFlow();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { errorResponse, successResponse, resetNotifications } = useCommonFlow();
  const initializeReceivingData = useInitializeReceivingData();
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const { hideModal, showModal } = useWarehouseModal();
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const { inboundClient } = useClientsWithAuth();

  useMount(() => {
    initializeReceivingData();
    setWarehouseAppState(setProp("pageSubtitle", formatMessage(commonBoxMessages.missingCdksu)));
    setReceivingData(setProp("isReceiveWithoutCdsku", true));
  });

  enum AsnReceiveErrorCode {
    // Error thrown when Asn does not exist within database
    ASN_NOT_FOUND = "ASN_NOT_FOUND",
    // Error thrown in the case that an Asn is a crossdock asn
    ASN_IS_CROSSDOCK = "ASN_IS_CROSSDOCK",
    // Error thrown in the case that an Asn is for a consolidation
    ASN_IS_FOR_CONSOLIDATION = "ASN_IS_FOR_CONSOLIDATION",
    // Error thrown in the case that an Asn is meant to be received by another warehouse
    ASN_WAREHOUSE_MISMATCH = "ASN_WAREHOUSE_MISMATCH",
  }

  const contactDeliverrModalTextMapping = {
    [AsnReceiveErrorCode.ASN_NOT_FOUND]: {
      title: formatMessage(modalsText.asnNotFoundTitle),
      message: formatMessage(modalsText.asnNotFoundText),
    },
    [AsnReceiveErrorCode.ASN_IS_CROSSDOCK]: {
      title: formatMessage(modalsText.asnIsCrossDockTitle),
      message: formatMessage(modalsText.asnIsCrossDockText),
    },
    [AsnReceiveErrorCode.ASN_WAREHOUSE_MISMATCH]: {
      title: formatMessage(modalsText.asnWarehouseMismatchTitle),
      message: formatMessage(modalsText.asnWarehouseMismatchText),
    },
  };

  const contactDeliverrErrors = Object.keys(contactDeliverrModalTextMapping);

  const [submitState, handleSubmit] = useAsyncFn(
    async (scannedAsnId: string) => {
      const ctx = logStart({ fn: "usePOCard.handleSubmit", scannedAsnId, warehouseId, asnIdError });
      setAsnIdError("");

      if (!scannedAsnId) {
        return setAsnIdError(formatMessage(commonWarehouseMessages.fieldRequired));
      }

      if (isValidCdsku(scannedAsnId)) {
        errorResponse();
        setReceivingData(setProp("asnId", undefined));
        return setAsnIdError(formatMessage(commonWarehouseMessages.cdskuScannedAsnReceive, { cdsku: scannedAsnId }));
      }

      if (isNaN(+scannedAsnId)) {
        setReceivingData(setProp("asnId", undefined));
        return;
      }

      // Checks if input is 15 characters or less
      if (!isValidInputLength(scannedAsnId, MAX_PO_INPUT_LENGTH)) {
        setReceivingData(setProp("asnId", +scannedAsnId.substring(0, MAX_PO_INPUT_LENGTH))); // necessary so we can listen for the re-scan
        return setAsnIdError(
          formatMessage(commonWarehouseMessages.exceededMaxCharacterCount, { characterLimit: MAX_PO_INPUT_LENGTH })
        );
      }

      const onError = (response) => {
        log({ ...ctx, response }, "error when validating PO");
        const { subcode, message, payload } = response.error;

        errorResponse(() => setReceivingData(setProp("asnId", undefined)));
        if (subcode === AsnReceiveErrorCode.ASN_IS_FOR_CONSOLIDATION) {
          showModal(WarehouseModal.PO_IS_FOR_CONSOLIDATION, {
            onCancel: () => {
              hideModal(WarehouseModal.PO_IS_FOR_CONSOLIDATION);
            },
            onContinue: () => {
              initializeReceivingData();
              resetNotifications();
              hideModal(WarehouseModal.PO_IS_FOR_CONSOLIDATION);
              push(ReceivingPath.CONSOLIDATION);
            },
          });
        } else if (contactDeliverrErrors.includes(subcode)) {
          showModal(WarehouseModal.CONTACT_DELIVERR, {
            onCancel: () => {
              hideModal(WarehouseModal.CONTACT_DELIVERR);
            },
            title: contactDeliverrModalTextMapping[subcode].title,
            message: contactDeliverrModalTextMapping[subcode].message,
          });
        } else {
          const messageId = getErrorMessageId({ response: response.error });
          const formattedValues = payload && formatValues(payload);

          return setAsnIdError(messageId ? formatMessage(messageId, formattedValues) : message);
        }
      };

      const onSuccess = (response) => {
        log({ ...ctx, ...response }, "validated PO");
        const { isAsnCompleted } = response.data;
        if (isAsnCompleted) {
          errorResponse();
          log({ ...ctx, response }, "ASN Id has already been received");
          setReceivingData(setProp("asnId", undefined));
          return showModal(WarehouseModal.CLOSED_PALLET, {
            asnId,
            onCancel: () => {
              hideModal(WarehouseModal.CLOSED_PALLET);
            },
          });
        }
        successResponse();
        push(ReceivingPath.SKU);
      };

      try {
        if (receivingFlow === ReceivingFlowType.BOX_RECEIVING) {
          const response = await inboundClient.asnReceiveValidateAsn(warehouseId, scannedAsnId, requestBatchId);

          if (response.error) {
            onError(response);
          } else {
            onSuccess(response);
          }
        } else {
          throw new Error("Unknown receive type");
        }
      } catch (error) {
        setReceivingData(setProp("asnId", undefined));
        handleUnknownError({ ...ctx, error }, new Error("Error validating ASN scan"));
      }
    },
    [warehouseId, requestBatchId, asnId, receivingFlow]
  );

  const updateAsnId = (newAsnId: string) => {
    // check for numeric string
    if (isNaN(+newAsnId)) {
      setReceivingData(setProp("asnId", undefined));
      return;
    }
    if (!isValidInputLength(newAsnId, MAX_PO_INPUT_LENGTH)) {
      setReceivingData(setProp("asnId", +newAsnId.substring(0, MAX_PO_INPUT_LENGTH))); // necessary so we can listen for the re-scan
      return setAsnIdError(
        formatMessage(commonWarehouseMessages.exceededMaxCharacterCount, { characterLimit: MAX_PO_INPUT_LENGTH })
      );
    }
    setReceivingData(setProp("asnId", +newAsnId));
    setAsnIdError("");
  };

  const handleChange = genericOnScannerInputChange(`${asnId || ""}`, updateAsnId, handleSubmit, "upper");

  const routeToNonCompliance = () => {
    initializeReceivingData();
    push(WarehousePortalRoutes.NON_COMPLIANCE);
  };

  return {
    routeToNonCompliance,
    asnId: `${asnId || ""}`,
    handleSubmit,
    handleChange,
    asnIdError,
    loading: submitState.loading,
    formatMessage,
  };
};
