import { receivingState } from "./ReceivingState";
import { selector } from "recoil";
import { WarehouseStateKey } from "warehouse/base/WarehouseStateKey";
import { isBulkReceiveState } from "./isExpeditedBulkFlowEligible";

export const getIsMidBulkReceiveFlow = ({ get }) => {
  const { identicalBoxes, cdskusToReceive } = get(receivingState);
  const isBulkReceive = get(isBulkReceiveState);

  return isBulkReceive && cdskusToReceive.length < identicalBoxes;
};
export const isMidBulkReceiveFlow = selector({
  key: WarehouseStateKey.IS_MID_BULK_RECEIVE_FLOW,
  get: getIsMidBulkReceiveFlow,
});
