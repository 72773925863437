import { CharLimitTextAreaProps } from "./CharLimitTextArea";

export const useCharLimitTextArea = ({ onChange }: Pick<CharLimitTextAreaProps, "onChange">) => {
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return {
    handleTextChange,
  };
};
