import React from "react";
import { Link } from "react-router-dom";
import { IconV2 } from "@deliverr/ui";

import { BaseMenuLinkProps } from "./MenuLink";
import { StyledMenuLink } from "./StyledMenuLink";

export interface InternalMenuLinkProps<LinkNames extends string = string> extends BaseMenuLinkProps<LinkNames> {
  route: string;
}

const StyledInternalLinkButton = StyledMenuLink.withComponent(Link);

export const InternalMenuLink = ({ link }) => (
  <StyledInternalLinkButton
    to={{
      pathname: link.route,
      ...(link.props && {
        state: { ...link.props },
      }),
    }}
  >
    {link.text}
    <IconV2 type="angle-right" />
  </StyledInternalLinkButton>
);
