import React from "react";
import { Card, Title } from "@deliverr/ui";
import styled from "@emotion/styled";

export const CenteredCard = styled(Card)`
  max-width: 32rem;
  text-align: center;
`;

export const StyledP = styled.p`
  color: #515969;
  margin-bottom: 2rem;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
`;

export const MissingWarehouseId: React.FC = () => {
  return (
    <CenteredCard size="SM">
      <StyledTitle as="h3" displayAs="h3">
        Warehouse ID not set
      </StyledTitle>
      <StyledP>Please navigate to the URL assigned to your warehouse</StyledP>
    </CenteredCard>
  );
};
