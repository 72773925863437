import React from "react";
import { Pill, PillProps, Stack, Title, DefaultTheme, IconV2Types, LocalizedMessage } from "@deliverr/ui";
import styled from "@emotion/styled";

import { FacilityCard } from "facility-commons/components/cards/FacilityCard";
import { FlowCardIcon, FlowCardIconProps } from "./FlowCardIcon";

export interface FlowCardProps extends FlowCardIconProps {
  icon?: IconV2Types;
  title?: LocalizedMessage;
  message?: LocalizedMessage;
  pillProps?: PillProps;
  topChildren?: React.ReactNode;
  centerAlign?: boolean;
  className?: string;
}

const FlowCardTopRow = styled.div<{ centerAlign?: boolean }>(
  ({ centerAlign }) => `
  display: flex;
  justify-content: ${centerAlign ? "center" : "space-between"};
  align-items: center;
`
);

export const FlowCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlowCardTitle = styled(Title)<Record<string, unknown>, DefaultTheme>`
  margin-left: ${({ icon, theme }) => (icon ? theme.spacing.S1 : "0")};
`;

interface MessageProps {
  centerAlign?: boolean;
}

const Message = styled.p<MessageProps, DefaultTheme>(
  ({ centerAlign }) => `
  text-align: ${centerAlign ? "center" : "left"};
`
);

export const FlowCard: React.FC<FlowCardProps> = ({
  icon,
  textIcon,
  title = "",
  accentColor,
  message,
  pillProps,
  topChildren,
  centerAlign,
  size,
  className,
  children,
}) => {
  return (
    <FacilityCard as="section" className={className}>
      <Stack gap="S3">
        {topChildren}
        <FlowCardTopRow centerAlign={centerAlign}>
          <FlowCardTitleContainer>
            {(icon || textIcon) && (
              <FlowCardIcon icon={icon} textIcon={textIcon} accentColor={accentColor} size={size} />
            )}
            <FlowCardTitle as="h2" displayAs="h4" icon={!!icon || !!textIcon}>
              {title}
            </FlowCardTitle>
          </FlowCardTitleContainer>
          {pillProps && <Pill {...pillProps} />}
        </FlowCardTopRow>
        {message && <Message centerAlign={centerAlign}>{message}</Message>}
        {children}
      </Stack>
    </FacilityCard>
  );
};
