// Adds "s" if value is not 1
export function plural(text: string, value: number, es?: boolean): string {
  const pluralCharacters = es ? "es" : "s";
  return text + (Math.abs(value) !== 1 ? pluralCharacters : "");
}

// creates a function to validate a string against a pattern
export function validateString(pattern: RegExp) {
  return (value?: string): boolean => !!value && pattern.test(value);
}
