import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate, warningModalIconLg } from "facility-commons";
import { Button, Grid } from "@deliverr/ui";
import { modalsText } from "../modalsText";
import { SoundFx } from "facility-commons/common/sfx";

interface ReturnReceiveUnclaimedReturnWarningModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ReturnReceiveUnclaimedReturnWarningModal: React.FC<ReturnReceiveUnclaimedReturnWarningModalProps> = ({
  onCancel,
  onConfirm,
}) => {
  const { formatMessage } = useIntl();

  const title = formatMessage(modalsText.unclaimedReturnOrderTitle);
  const message = formatMessage(modalsText.unclaimedReturnOrderText);

  return (
    <ModalTemplate
      buttonLayout="row"
      title={title}
      text={message}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
    >
      <Grid gap="S2" fullWidth>
        <Button block data-testid="unclaimed-return-confirm-btn" onClick={onConfirm}>
          {formatMessage(modalsText.confirm)}
        </Button>
        <Button block onClick={onCancel} secondary>
          {formatMessage(modalsText.back)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
