import { useEffect, useRef, useState } from "react";
import { isNaN, last } from "lodash";
import { useIntl } from "react-intl";
import { useMount } from "react-use";
import { useRouter } from "facility-commons/hooks";
import { useWarehouseModal } from "warehouse/modal";
import { WarehouseModal } from "warehouse/modal/WarehouseModalMap";
import { useReceivingFlow } from "warehouse/receiving/base";
import {
  commonReceivingMessages,
  commonLotMessages,
  commonBoxMessages,
  quantityMessages,
} from "warehouse/receiving/content";
import { ReceivingPath } from "warehouse/receiving/routes";
import { MAX_BOXES_TO_RECEIVE, MAX_UNITS_TO_RECEIVE, QTY_INPUT_LENGTH_LIMIT } from "warehouse/receiving/utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useInitializeReceivingData } from "warehouse/receiving/hooks";
import { useCommonFlow } from "facility-commons/flow/useCommonFlow";
import {
  isBoxReceiveFlow,
  isConsolidationFlow,
  isPalletReceiveFlow,
} from "warehouse/receiving/utils/receivingFlowType";
import {
  bulkReceiveState,
  currentSkuState,
  INITIAL_SKU_STATE,
  isBulkReceiveState,
  receiveFlowTypeState,
  receivingState,
  numOfBoxesExpectedState,
  palletReceiveState,
  cdskuState,
} from "warehouse/receiving/ReceivingState";
import { ReceivingData, ReceivingFlowType } from "warehouse/receiving/ReceivingState/Types";
import { DateFormat, log, setProp } from "facility-commons/utils";
import { SoundFx } from "facility-commons/common/sfx";
import { warehouseAppState } from "warehouse/base/warehouseAppDataState";
import { getExpirationDateFormattedString } from "warehouse/receiving/utils/getExpirationDateFormattedString";
import { format } from "date-fns";
import { COMMON_LABELS } from "../warehouse.labels";
import { isElevatedUser } from "warehouse/receiving/ReceivingState/isElevatedUser";

// eslint-disable-next-line complexity
export const useQuantityCardV2 = () => {
  const [boxQuantityError, setBoxQuantityError] = useState("");
  const [boxesQtyInput, setBoxesQtyInput] = useState<number>(1);
  const [unitsQtyInput, setUnitsQtyInput] = useState<number>(0);
  const [receivingFlowType, setReceivingFlowType] = useRecoilState(receiveFlowTypeState);
  const [currentSku, setCurrentSku] = useRecoilState(currentSkuState);
  const [receivingData, setReceivingData] = useRecoilState(receivingState);

  const boxQuantityRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const initializeReceivingData = useInitializeReceivingData();
  const isPalletReceive = isPalletReceiveFlow(receivingFlowType);
  const isBoxReceive = isBoxReceiveFlow(receivingFlowType);

  const { errorResponse, successResponse, playSfx } = useCommonFlow();

  const { hideModal, showModal } = useWarehouseModal();
  const setWarehouseAppState = useSetRecoilState(warehouseAppState);
  const { formatMessage } = useIntl();
  const router = useRouter();
  const {
    barcode,
    description,
    dsku,
    expectedQuantity,
    quantity: unitsQty,
    cdsku,
    previouslyReceivedQuantity,
    isSkuOnBox,
  } = currentSku;
  const {
    receiveUnitsState: { loading },
    receivedUnits,
    updateCurrentSku,
  } = useReceivingFlow();
  const { isBulkEligible } = useRecoilValue(bulkReceiveState);
  const {
    expectedSkus,
    identicalBoxes,
    receivedSkus,
    expectedNumOfBoxes,
    lotNumber,
    expirationDate,
    asnId,
    isReceiveWithoutCdsku,
    isUnexpectedSku,
    previousReceives,
    receivingError,
    expectedFefoLotPairs,
    receiveMoreUnitsFromBox,
    requireElevatedRoleToUnderReceive,
  } = receivingData;
  const { isBulkPalletReceiveEligible } = useRecoilValue(palletReceiveState);

  const numOfBoxesExpected = useRecoilValue(numOfBoxesExpectedState);
  const previouslyReceivedTotalUnits = previousReceives?.reduce((acc, curr) => acc + curr.receivedQty, 0) || 0;
  const receivingFlow = useRecoilValue(receiveFlowTypeState);
  const isCDSKUFlow = isBoxReceiveFlow(receivingFlow);
  const isConsolidation = isConsolidationFlow(receivingFlow);
  const isBulkFlowEligible = useRecoilValue(isBulkReceiveState);
  const { isHighRisk: isHighRiskBox } = useRecoilValue(cdskuState);
  const isMultiFefo = expectedFefoLotPairs && expectedFefoLotPairs.length > 1 && !isHighRiskBox;

  const isElevatedUserReceiving = useRecoilValue(isElevatedUser);

  // we need to show this box quantity input in order to know how many boxes to bulk scan, or how many boxes are on a pallet
  const showBoxQuantityInput =
    !isUnexpectedSku && isSkuOnBox && !isMultiFefo && !isHighRiskBox && (isBulkEligible || isPalletReceive);

  useMount(() => {
    if (isReceiveWithoutCdsku) {
      setWarehouseAppState(setProp("pageSubtitle", formatMessage(commonBoxMessages.missingCdksu)));
    } else if (cdsku && isCDSKUFlow) {
      setWarehouseAppState(setProp("pageSubtitle", cdsku));
    }
    if (isBoxReceive && !isReceiveWithoutCdsku) {
      setWarehouseAppState(setProp("pageTitle", formatMessage(quantityMessages.quantitySubTitle)));
    }
    setUnitsQtyInput(unitsQty);
    setBoxesQtyInput(identicalBoxes);
  });

  useEffect(() => {
    // updating the Qty values if an error happens in the CONFIRM_QTY modal
    if (receivingError) {
      setUnitsQtyInput(unitsQty);
      setBoxesQtyInput(identicalBoxes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivingError]);

  useEffect(() => {
    if (isHighRiskBox) {
      // we only allow receiving 1 unit at a time for returns packages because we want to be extra careful
      // they may contain a mix of SKUs and of different lot/expiry
      updateCurrentSku("quantity", 1);
      setUnitsQtyInput(1);
    } else if (!unitsQty) {
      setReceivingData(setProp("receivingError", ""));
      updateCurrentSku("quantity", 0);
      setUnitsQtyInput(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitsQty]);

  useEffect(() => {
    if (!barcode) {
      // if a user receives a sku then returns back to this page using browser back button
      // we repopulate the last received SKUs information back in to state - initialSkuState is a fallback
      setCurrentSku(last(receivedSkus) || INITIAL_SKU_STATE);
    }
  }, [barcode, receivedSkus, setCurrentSku]);

  const submitQty = () => {
    successResponse();
    if (isBulkFlowEligible) {
      setReceivingData((state: ReceivingData) => ({
        ...state,
        cdskusToReceive: [cdsku],
      }));
      router.push(ReceivingPath.BULK);
    } else {
      router.push(ReceivingPath.LOCATION);
    }
  };

  const handleCloseErrModal = () => {
    hideModal(WarehouseModal.INCORRECT_BOX_AND_UNIT_QTY);
    hideModal(WarehouseModal.INCORRECT_BOX_QTY);
  };

  const handleCancelQtyConfirmation = () => {
    hideModal(WarehouseModal.CONFIRM_UNITS_COUNT);
    // to revert the input back to what the user had before opening the modal
    updateCurrentSku("quantity", unitsQtyInput);
    setReceivingData(setProp("identicalBoxes", boxesQtyInput));
  };

  const handleOnConfirmSubmission = () => {
    submitQty();
    handleCloseErrModal();
  };

  const handleRouteToBoxReceiving = () => {
    router.push(ReceivingPath.CDSKU);
    setReceivingFlowType(ReceivingFlowType.BOX_RECEIVING);
    initializeReceivingData();
    setWarehouseAppState(setProp("pageSubtitle", ""));
    handleCloseErrModal();
  };

  // initial submit function responsible for input validation and possible modal display
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // in case we show a modal, we need to remove focus from the input because
    // scanners can still interact with the form while the modal is open
    boxQuantityRef.current?.blur();
    quantityRef.current?.blur();

    if (!unitsQty) {
      errorResponse();
      return setReceivingData(setProp("receivingError", formatMessage(COMMON_LABELS.INVALID_QUANTITY_ERROR)));
    }

    if (!identicalBoxes) {
      return setBoxQuantityError(formatMessage(COMMON_LABELS.INVALID_QUANTITY_ERROR));
    }
    if (unitsQty > MAX_UNITS_TO_RECEIVE) {
      return setReceivingData(setProp("receivingError", formatMessage(COMMON_LABELS.TOO_MANY_UNITS_ERROR)));
    }
    if (identicalBoxes > MAX_BOXES_TO_RECEIVE) {
      return setBoxQuantityError(formatMessage(COMMON_LABELS.TOO_MANY_BOXES_ERROR));
    }
    const moreUnits = expectedQuantity < unitsQty + previouslyReceivedQuantity;
    // Consolidations often contain a large quantity of the same sku
    // and are usually received across multiple associates and sessions
    // we exclude the fewerUnits check to prevent spam
    const fewerUnits = expectedQuantity > unitsQty + previouslyReceivedQuantity && !isConsolidation;
    const incorrectUnitCount = moreUnits || fewerUnits;

    const fewerBoxes = numOfBoxesExpected > identicalBoxes;
    const moreBoxes = numOfBoxesExpected < identicalBoxes;
    const incorrectBoxCount = showBoxQuantityInput && (fewerBoxes || moreBoxes);
    const palletWithMoreBoxes = moreBoxes && isPalletReceive;

    // Certain sellers like Tribal will send in an entire pallet with a single CDSKU
    // Users get confused about whether to enter "1 box with 72 units" (correct) or "72 boxes with 1 unit" (incorrect)
    // This is a temporary hack to allow these receives to go through as the user intends
    // The backend is set up to handle this gracefully here.
    // This should be removed as we move towards Consolidated Pallet Receiving https://github.com/deliverr/inbounds-inbound-service/pull/1806
    const isAccidentalSwapOfSingleBoxPalletReceive =
      !isBulkPalletReceiveEligible &&
      unitsQty === 1 &&
      expectedNumOfBoxes === 1 &&
      expectedQuantity === identicalBoxes &&
      !isHighRiskBox;

    if (isCDSKUFlow) {
      if (previouslyReceivedTotalUnits && receiveMoreUnitsFromBox && !isHighRiskBox) {
        return showModal(WarehouseModal.CONFIRM_CUMULATIVE_RECEIVE_TO_SAME_LOCATION, {
          inputtedQuantity: unitsQty,
          previouslyReceived: previouslyReceivedTotalUnits,
          proceedWithSubmit: handleOnConfirmSubmission,
        });
      }
      const blockUnderReceive = fewerUnits && requireElevatedRoleToUnderReceive && !isElevatedUserReceiving;
      const blockOverReceive = moreUnits && !isElevatedUserReceiving; //blocking over-receives is not dependent on a feature flag

      if (blockUnderReceive || blockOverReceive) {
        return showModal(WarehouseModal.RECORD_UNEXPECTED_RECEIVE_ATTEMPT_MODAL, {});
      }
    }

    // when we're auto-resolving the receive, we're ALWAYS expecting a quantity of zero
    // but we've already let the user know we're going to resolve this issue for them so we don't need additional confirmation of the received quantity here
    if (isAccidentalSwapOfSingleBoxPalletReceive || isHighRiskBox) {
      submitQty();
    } else if ((incorrectBoxCount || incorrectUnitCount || isUnexpectedSku) && !palletWithMoreBoxes) {
      log({ fn: "useQuantityCard", receivingData, currentSku }, "user needs to re-enter Qty");

      return showModal(WarehouseModal.CONFIRM_UNITS_COUNT, {
        onCancel: handleCancelQtyConfirmation,
        incorrectBoxCount,
        incorrectUnitCount,
        fewerUnits,
        fewerBoxes,
        boxesDelta: Math.abs(identicalBoxes - numOfBoxesExpected),
        initialBoxCount: boxesQtyInput,
        initialUnitCount: unitsQtyInput,
        prevBoxCount: boxesQtyInput,
        prevUnitCount: unitsQtyInput,
        isUnexpectedSku,
      });
    } else if (palletWithMoreBoxes) {
      playSfx(SoundFx.UNEXPECTED_QUANTITY);

      if (incorrectUnitCount) {
        showModal(WarehouseModal.INCORRECT_BOX_AND_UNIT_QTY, {
          onCancel: handleCloseErrModal,
          onConfirm: handleRouteToBoxReceiving,
          boxesQty: identicalBoxes,
          unitsQty,
          fewerBoxes,
        });
      } else {
        showModal(WarehouseModal.INCORRECT_BOX_QTY, {
          onCancel: handleCloseErrModal,
          onConfirm: handleRouteToBoxReceiving,
          boxesQty: identicalBoxes,
          fewerBoxes,
        });
      }
    } else {
      // proceed with submitting as is
      submitQty();
    }
  };

  const handleUnitsQuantityChange = (eventValue) => {
    const value = Number(eventValue.target.value);
    setReceivingData(setProp("receivingError", ""));

    if (value.toString().length > QTY_INPUT_LENGTH_LIMIT) {
      return setReceivingData(setProp("receivingError", formatMessage(COMMON_LABELS.TOO_MANY_UNITS_ERROR)));
    }
    if (!isNaN(value)) {
      setUnitsQtyInput(value);
      updateCurrentSku("quantity", Math.abs(value));
    }
  };

  const handleBoxQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = Number(e.target.value);
    setBoxQuantityError("");
    setReceivingData(setProp("receivingError", ""));

    if (eventValue.toString().length > QTY_INPUT_LENGTH_LIMIT) {
      return setBoxQuantityError(formatMessage(COMMON_LABELS.TOO_MANY_BOXES_ERROR));
    }
    if (!isNaN(eventValue)) {
      setBoxesQtyInput(eventValue);
      setReceivingData(setProp("identicalBoxes", Math.abs(eventValue)));
    }
  };

  let cardMessage;
  if (isMultiFefo) {
    if (expirationDate && lotNumber) {
      cardMessage = formatMessage(commonReceivingMessages.countUnitsDateLot, {
        expirationDate: format(
          new Date(getExpirationDateFormattedString(expirationDate)),
          DateFormat.ShortMonthDayYear
        ),
        lotNumber,
      });
    } else if (expirationDate && !lotNumber) {
      cardMessage = formatMessage(commonReceivingMessages.countUnitsDate, {
        expirationDate: format(
          new Date(getExpirationDateFormattedString(expirationDate)),
          DateFormat.ShortMonthDayYear
        ),
      });
    } else {
      // only LOT is set
      cardMessage = formatMessage(commonLotMessages.countUnitsLot, { lotNumber });
    }
  }

  const handleAdditionalFefoLotUnits = () => {
    setReceivingData(setProp("receiveMoreUnitsFromBox", !receiveMoreUnitsFromBox));
  };

  return {
    barcode,
    boxQuantityError,
    boxQuantityRef,
    disableContinue: loading || !unitsQty || !identicalBoxes,
    description,
    dsku,
    cdsku,
    expectedSkus,
    formatMessage,
    handleBoxQuantityChange,
    handleUnitsQuantityChange,
    handleSubmit,
    handleAdditionalFefoLotUnits,
    identicalBoxes,
    unitsQty,
    quantityRef,
    receivedSkus,
    receivedUnits,
    showBoxQuantityInput,
    isPalletReceive,
    isBoxReceive,
    lotNumber,
    receivingError,
    expirationDate,
    asnId,
    isReceiveWithoutCdsku,
    boxesQtyInput,
    unitsQtyInput,
    loading,
    previouslyReceivedQuantity,
    previousReceives,
    cardMessage,
    isMultiFefo,
    receiveMoreUnitsFromBox,
    isHighRiskBox,
    disableUnitQtyInput: isHighRiskBox,
  };
};
