import React from "react";
import { useIntl } from "react-intl";

import { ModalTemplate } from "facility-commons";
import { modalsText } from "../modalsText";
import { BoxReset, Button, Grid } from "@deliverr/ui";

export interface ConfirmReportProblemModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmReportProblemModal: React.FC<ConfirmReportProblemModalProps> = ({ onCancel, onConfirm }) => {
  const { formatMessage } = useIntl();
  return (
    <ModalTemplate title={formatMessage(modalsText.exitReceivingTitle)} buttonLayout="column">
      <Grid gap="S2" fullWidth>
        <BoxReset marginBottom="S2">
          <Button block onClick={onConfirm} data-testid="report-problem-btn">
            {formatMessage(modalsText.exitAndReport)}
          </Button>
        </BoxReset>
        <Button block onClick={onCancel} secondary data-testid="cancel-report-problem-btn">
          {formatMessage(modalsText.cancel)}
        </Button>
      </Grid>
    </ModalTemplate>
  );
};
