import React from "react";
import { DefaultTheme, Title, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FacilityCard } from "facility-commons/components/cards/FacilityCard";

export interface TransitionCardProps {
  title: string | JSX.Element;
  message?: string | JSX.Element;
  className?: string;
}

const Card = styled(FacilityCard)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TransitionTitle = styled(Title)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S3};
    margin-bottom: ${theme.spacing.S2};
  `
);

export const TransitionCard: React.FC<TransitionCardProps> = ({ title, message, children, className }) => (
  <Card as="section" className={className}>
    {children}
    <TransitionTitle as="h2" displayAs="h4">
      {title}
    </TransitionTitle>
    {message && <Text appearance="INFO">{message}</Text>}
  </Card>
);
