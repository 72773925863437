import { useLifecycles } from "react-use";
import React from "react";
import { FlowV2 } from "facility-commons/flow";
import { warehouseFlowCardsState, flowVisibleButtonsValue } from "./warehouseFlowState";
import { useRecoilValue } from "recoil";
import { FlowCardData } from "facility-commons/flow/types/FlowCardData";
import { useWarehouseFlow } from "./useWarehouseFlow";
import { rsOutboundsFlowCardMap } from "./maps/rsOutboundsFlowCardMap";
import { rsOutboundsFlowButtonMap } from "./maps/rsOutboundsFlowButtonMap";

export interface RSOutboundsFlowV2Props {
  onMountCardData?: FlowCardData<any>;
  onMount?: () => void;
  onUnmount?: () => void;
  stopResetOnUnmount?: boolean;
  showMultipleCards?: boolean;
}

export const RSOutboundsFlowV2: React.FC<RSOutboundsFlowV2Props> = ({
  onMountCardData,
  onMount,
  onUnmount,
  showMultipleCards,
  stopResetOnUnmount,
}) => {
  const { addFlowCard, resetFlow, hideAllFlowButtons } = useWarehouseFlow();

  useLifecycles(
    () => {
      if (onMountCardData) {
        hideAllFlowButtons();
        addFlowCard(onMountCardData);
      }
      onMount?.();
    },
    () => {
      if (!stopResetOnUnmount) {
        resetFlow();
      }
      hideAllFlowButtons();
      onUnmount?.();
    }
  );

  return (
    <FlowV2
      cardsState={warehouseFlowCardsState}
      cardMap={rsOutboundsFlowCardMap}
      visibleButtons={useRecoilValue(flowVisibleButtonsValue)}
      buttonMap={rsOutboundsFlowButtonMap}
      showMultipleCards={showMultipleCards}
    />
  );
};
