import { Anchor, IconV2, IconV2Props, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import * as React from "react";

const PaddedIcon = styled(IconV2)<ThemeProps<IconV2Props>>(
  ({ theme }) => `
  padding-left: ${theme.spacing.S1};
`
);

export interface ExternalLinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  shouldShowIcon?: boolean;
}

export const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ children, shouldShowIcon, ...props }) => (
  <Anchor target="_blank" rel="noopener noreferrer" {...props}>
    {children}
    {shouldShowIcon && <PaddedIcon type="external-link" />}
  </Anchor>
);
