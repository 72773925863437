import React from "react";
import { ResponseCard } from "facility-commons/flow/cards/ResponseCard";

export interface ArrivalScannerInvalidInputCardProps {
  message: string | JSX.Element;
}

export const ArrivalScannerInvalidInputCard: React.FC<ArrivalScannerInvalidInputCardProps> = ({ message }) => {
  return <ResponseCard message={message} accentColor="RED" icon="exclamation-circle" />;
};
