import React from "react";
import { useIntl } from "react-intl";

import { ContinueButton, ModalTemplate, warningModalIcon } from "facility-commons";
import { WarehouseModalRightMarginButton } from "facility-commons/components/emotion/modals";
import { SkusWarningModalProps, useSkusWarningModal } from "./useSkusWarningModal";
import { modalsText } from "../modalsText";

export const SkusWarningModal: React.FC<SkusWarningModalProps> = (props) => {
  const { expectedSkus, receivedSkus } = props;
  const { handleModalClickContinue, hideSkusWarningModal, skusMissing } = useSkusWarningModal(props);
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(modalsText.skusMissingTitle, { skusMissing })}
      text={formatMessage(modalsText.skusMissingText, { expectedSkus, receivedSkus })}
      iconProps={warningModalIcon}
    >
      <WarehouseModalRightMarginButton block onClick={hideSkusWarningModal} secondary>
        {formatMessage(modalsText.back)}
      </WarehouseModalRightMarginButton>
      <ContinueButton data-testid="SkusWarningModal.confirm" block onClick={handleModalClickContinue} />
    </ModalTemplate>
  );
};
